import React from 'react'
import {
  AddTicketIcon,
  PencilIcon,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TooltipOnHover
} from '@bonitour/components'
import { css } from '@emotion/core'
import { Link } from 'react-router-dom'

const iconButton = css`
  cursor: pointer;
  font-size: 18px;
`

export const BinamikCompaniesTable = ({ companies, loading }) => {
  return (
    <TableContainer style={{ marginTop: 16 }}>
      <TableHeader>
        <TableHeaderRow>
          <TableHeaderCell>Nome</TableHeaderCell>
          <TableHeaderCell>Plano</TableHeaderCell>
        </TableHeaderRow>
      </TableHeader>
      <TableBody loading={loading}>
        {companies?.map((company) => (
          <TableBodyRow key={`company-${company.id}`}>
            <TableBodyCell>{company.name}</TableBodyCell>
            <TableBodyCell style={{ display: 'flex' }}>
              <TooltipOnHover text='Ver Plano'>
                <Link to={`/binamik/companies/${company.id}/plan`} style={{ textDecoration: 'none', color: 'inherit' }}>
                  <PencilIcon css={iconButton} />
                </Link>
              </TooltipOnHover>
              <TooltipOnHover text='Criar Plano' style={{ marginLeft: 16 }}>
                <Link to={`/binamik/companies/${company.id}/plan/create`} style={{ textDecoration: 'none', color: 'inherit' }}>
                  <AddTicketIcon css={iconButton} />
                </Link>
              </TooltipOnHover>
            </TableBodyCell>
          </TableBodyRow>
        ))}
      </TableBody>
    </TableContainer>
  )
}

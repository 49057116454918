/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useEffect } from 'react'
import { useForm } from '@bonitour/app-functions'
import { deepGetOrElse } from 'deep-getter'
import { identity } from '@bonitour/common-functions'
import { Button, GhostButton, flexRow, Input, flexColumn, H3, RadioGroup, RadioInputGroup, Column, Row, InputFormGroup } from '@bonitour/components'
import { marginRight20, marginTop30, marginBottom20, inputGroup, groupContainer, alignLeft, radioStyle } from './InviteForm.style'
import { sellerInvitationSchema } from './InviteForm.schema'
import { width100 } from 'assets/styles/global'

export const SellerInviteForm = ({
  partnerInvitation: resetPartnerInvitation = {},
  onSuccess: emitSuccessEvent = identity,
  onValidationError: emitValidationError = identity,
  onCancel: emitCancelEvent = identity,
  groups = []
}) => {
  const { form, errors, onSubmit, changeFormValue, utils: { onInputBlur, onInputChange } } = useForm(resetPartnerInvitation, sellerInvitationSchema)
  const onClick = onSubmit(emitSuccessEvent, emitValidationError)
  const { email = '', group = '' } = form

  useEffect(() => {
    if (groups.length) {
      const groupId = deepGetOrElse(groups, '0.id', '')
      changeFormValue(groupId, 'group')
    }
  }, [groups, changeFormValue])

  return (
    <div css={marginTop30}>
      <H3 css={marginBottom20}>Contato</H3>
      <div css={[flexColumn, inputGroup]}>
        <InputFormGroup errorMessage={errors.email} label='E-mail'>
          <Input
            css={width100}
            id='email'
            type='email'
            value={email}
            error={errors.email}
            onChange={onInputChange('email')}
            onBlur={onInputBlur('email')}
          />
        </InputFormGroup>
      </div>

      <H3 css={[marginTop30, marginBottom20]}>Grupos</H3>

      <RadioGroup value={group} onChange={onInputChange('group')} css={groupContainer}>
        <Row>
          {groups.map(({ id, tradeAgreement: { name } }) => (
            <Column key={id} phone={12} desktop={6} css={alignLeft}>
              <RadioInputGroup name='group' id={id} value={id} css={radioStyle}>{name}</RadioInputGroup>
            </Column>
          ))}
        </Row>
      </RadioGroup>

      <div css={[flexRow, marginTop30]}>
        <GhostButton css={marginRight20} onClick={emitCancelEvent}>Cancelar</GhostButton>
        <Button onClick={onClick}>Convidar</Button>
      </div>
    </div>
  )
}

import { css } from '@emotion/core'
import { marginBottom, marginLeft } from 'assets/styles/global'

export const marginBottom20 = marginBottom(20)
export const marginLeft10 = marginLeft(10)

export const customTextArea = css`
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 40px;
`

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { TableHeaderCell, TableHeaderRow, TableHeader } from '@bonitour/components'

export const ReservationTableHeader = () => {
  return (
    <TableHeader>
      <TableHeaderRow>
        <TableHeaderCell>Operação</TableHeaderCell>
        <TableHeaderCell>Aut/Doc</TableHeaderCell>
        <TableHeaderCell>Data</TableHeaderCell>
        <TableHeaderCell>Hora</TableHeaderCell>
        <TableHeaderCell>Valor</TableHeaderCell>
        <TableHeaderCell>Forma de pagamento</TableHeaderCell>
        <TableHeaderCell>Status</TableHeaderCell>
      </TableHeaderRow>
    </TableHeader>
  )
}

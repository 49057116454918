/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { useState, useEffect, useCallback } from 'react'
import { CollapsableSection, useToast, HeaderPage } from '@bonitour/components'
import { SellersGroupsService } from 'services/SellersGroups/Service'
import { useHistory, useParams } from 'react-router-dom'
import { InvoicePolicy } from 'containers/PartnershipGroup/InvoicePolicy/InvoicePolicy'
import { TradeAgreement } from 'containers/PartnershipGroup/TradeAgreement/TradeAgreement'

const defaultCursor = css`
  cursor: default;
`

export const ViewPartnershipGroup = () => {
  const { groupId } = useParams()
  const { add: addToast } = useToast()
  const [group, setGroup] = useState({})
  const { push } = useHistory()

  const redirect = useCallback(() => push('/providers'), [push])

  useEffect(() => {
    SellersGroupsService.getById(groupId)
      .then(setGroup)
      .catch(() => {
        redirect()
        addToast('Grupo não encontrado')
      })
  }, [groupId, addToast, redirect])

  const {
    tradeAgreement = {},
    invoicePolicy = {},
    customComissions = []
  } = group

  return (
    <>
      <HeaderPage onBack={redirect} title='Grupo' />

      <CollapsableSection
        indicator={1}
        css={defaultCursor}
        title='Regras'
        isExpanded
        topics='Nome da categoria / Comissionamento'
      >
        <TradeAgreement
          tradeAgreement={tradeAgreement}
          savedCustomComissions={customComissions}
          isLoading={!tradeAgreement.name}
          isFormDisabled
        />
      </CollapsableSection>

      <CollapsableSection
        indicator={2}
        isExpanded
        css={defaultCursor}
        title='Cobrança'
        topics='Fatura / Cobrança antecipada'
      >
        <InvoicePolicy isFormDisabled invoicePolicy={invoicePolicy} />
      </CollapsableSection>
    </>
  )
}

import { getImageUrl } from 'services/Orb/Service'

export const SellersParser = {
  seller ({ image, ...rest }) {
    return { ...rest, image: getImageUrl(image) }
  },
  cards ({ meta = {}, sellers = [] }) {
    const { total_pages: totalPages = 0, total_entries: total = 0 } = meta
    return { sellers: sellers.map(SellersParser.seller), totalPages, total }
  },
  list ({ sellers = [] }) {
    return [...sellers.map(SellersParser.seller)]
  }
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Button, flexRow, GhostButton, useToast } from '@bonitour/components'
import { marginTop, marginRight } from 'assets/styles/global'
import { InvoicePolicy } from './InvoicePolicy'
import { identity } from '@bonitour/common-functions'
import { useForm } from '@bonitour/app-functions'
import { invoicePolicySchema } from './invoicePolicy.schema'

const marginTop50 = marginTop(50)
const marginRight20 = marginRight(20)

export const InvoicePolicyForm = ({
  invoicePolicy: resetInvoicePolicy = {},
  onSuccess: emitSuccessEvent = identity,
  onValidationError: emitValidationError = identity,
  onBackStep = identity,
  isGroupEditable = false
}) => {
  const {
    form,
    errors,
    onSubmit,
    utils: { onInputBlur, onInputChange }
  } = useForm(resetInvoicePolicy, invoicePolicySchema)
  const { add: addToast } = useToast()

  const validationError = () => {
    const { invoiced, deadline = 0, deadlineType = '' } = form

    if (!invoiced && deadline < 1) {
      return addToast('O deadline é obrigatório para pagamento antecipado')
    }

    if (deadline > 0 && !deadlineType) {
      return addToast('Informe o tipo de deadline')
    }

    emitValidationError()
  }

  const onClick = onSubmit(emitSuccessEvent, validationError)

  return (
    <>
      <InvoicePolicy
        invoicePolicy={form}
        invoicePolicyErrors={errors}
        onChange={onInputChange}
        onInputBlur={onInputBlur}
        isGroupEditable={isGroupEditable}
      />

      <div css={[flexRow, marginTop50]}>
        <GhostButton css={marginRight20} onClick={onBackStep}>Voltar</GhostButton>
        <Button onClick={onClick}>Continuar</Button>
      </div>
    </>
  )
}

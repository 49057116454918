import { Button, colors, useToast } from '@bonitour/components'
import React, { useCallback, useMemo } from 'react'
import { PaymentBatchService } from 'core/services/PaymentBatch'
import { css } from '@emotion/core'

export const reopenBatch = css`
  background-color: ${colors.yellow1};
`

export const closeBatch = css`
  background-color: ${colors.green2};
`

export const ChangeBatchStatusButton = ({
  fetchPaymentBatch,
  backToList,
  paymentBatchId,
  batchStatus,
  disabled,
  hasTickets = false
}) => {
  const { add: addToast } = useToast()

  const isAllowedToCloseBatch = useMemo(() => {
    return Boolean(batchStatus === 'open' && paymentBatchId)
  }, [batchStatus, paymentBatchId])

  const isAllowedToReopenBatch = useMemo(() => {
    return Boolean(batchStatus === 'to_be_paid' && paymentBatchId)
  }, [batchStatus, paymentBatchId])

  const onFetchPaymentBatch = useCallback(() => {
    fetchPaymentBatch(paymentBatchId).catch(() => {
      backToList()
      addToast('Houve um erro ao buscar as novas informações do lote', 'error')
    })
  }, [paymentBatchId, fetchPaymentBatch, backToList, addToast])

  const onReopenPaymentBatch = useCallback(() => {
    PaymentBatchService.changeStatus({
      id: paymentBatchId,
      status: 'open'
    }).then(() => {
      addToast('Lote reaberto com sucesso', 'success')
      onFetchPaymentBatch()
    }).catch(() => {
      addToast('Não foi possível reabrir o lote', 'error')
    })
  }, [paymentBatchId, onFetchPaymentBatch, addToast])

  const onCloseBatch = useCallback(() => {
    if (!hasTickets) {
      return addToast('Não é possível fechar um lote sem tickets', 'error')
    }

    PaymentBatchService.changeStatus({
      id: paymentBatchId,
      status: 'to_be_paid'
    }).then(() => {
      addToast('Lote fechado com sucesso', 'success')
      onFetchPaymentBatch()
    }).catch(() => {
      addToast('Não foi possível fechar o lote', 'error')
    })
  }, [paymentBatchId, onFetchPaymentBatch, addToast, hasTickets])

  const handleOnChangeStatus = useCallback(() => {
    if (isAllowedToCloseBatch) {
      return onCloseBatch()
    }

    return onReopenPaymentBatch()
  }, [isAllowedToCloseBatch, onCloseBatch, onReopenPaymentBatch])

  if (!isAllowedToCloseBatch && !isAllowedToReopenBatch) {
    return null
  }

  return (
    <Button
      onClick={handleOnChangeStatus}
      customCss={isAllowedToCloseBatch ? [closeBatch] : [reopenBatch]}
      disabled={disabled}
    >
      {isAllowedToCloseBatch ? 'Fechar lote' : 'Reabrir lote'}
    </Button>
  )
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { colors } from '@bonitour/components'
import { memo } from 'react'

const monthLabel = css`
  font-size: 12px;
  width: 85px;
  text-align: center;
  display: inline-block;
  background-color: ${colors.info};
  color: ${colors.white};
  border-radius: var(--small-round-element-radius, 12px);
  margin: 0;
  margin-bottom: 0.594rem;
  padding: 5px 10px;
`

export const MonthLabel = memo(
  ({ monthContent }) => {
    return <span css={monthLabel}>{monthContent}</span>
  }
)

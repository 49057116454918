/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Card, HeaderPage, PaginationBar, flex } from '@bonitour/components'
import { useHistory } from 'react-router-dom'
import { TouristRefundsFilter } from './TouristRefundsFilter'
import { useRef } from 'react'
import { TouristRefundsTable } from '../Table/TouristRefundsTable'
import { TouristRefundsResults } from './TouristRefundsResults'
import { useListRefundedTickets } from 'domains/CompanyReports/hooks/useListRefundedTickets'
import { ExportFile } from 'components/ExportFile/ExportFile'
import { useTouristPaymentRefundExport } from 'domains/CompanyReports/hooks/useTouristPaymentRefundExport'
import { flexEnd } from 'domains/PartnershipAccount/Edit/PartnershipTransactions.styles'
import { exportFormatsOptions } from 'constants/reports'

const PER_PAGE_OPTIONS = [25, 50, 100, 200]

export const TouristRefundsList = () => {
  const { push } = useHistory()
  const backToReports = () => push('/reports')

  const {
    changePagination,
    pagination,
    onFilterSubmit,
    isLoading,
    isTotalizerLoading,
    filters,
    meta,
    refundedTickets,
    totalRefundedValue
  } = useListRefundedTickets()

  const { current: initialFilters } = useRef(filters)

  const { isLoading: isLoadingTouristRefund, refundColumns, getRefundAttributes } = useTouristPaymentRefundExport()

  return (
    <>
      <HeaderPage onBack={backToReports} title="Reembolsos dos turistas" />

      <Card>
        <TouristRefundsFilter onFilterSubmit={onFilterSubmit} initialFilters={initialFilters} />

        <TouristRefundsResults
          totalRefundedValue={totalRefundedValue}
          meta={meta}
          isLoading={isTotalizerLoading || isLoading}
        />

        <div css={[flex, flexEnd]}>
          <ExportFile
            loading={isLoadingTouristRefund}
            multiSelectOptions={refundColumns}
            formatOptions={exportFormatsOptions}
            filters={filters}
            initialFilters={initialFilters}
            onSubmitExport={getRefundAttributes}
          />
        </div>

        <TouristRefundsTable touristRefunds={refundedTickets} loading={isLoading} />

        <PaginationBar
          totalPages={meta?.totalPages}
          perPage={pagination?.perPage}
          currentPage={meta?.page}
          totalElements={meta?.total}
          onPageChange={(page) => changePagination({ currentPage: page })}
          onPerPageChange={(perPage) => changePagination({ perPage, currentPage: 1 })}
          perPageOptions={PER_PAGE_OPTIONS}
        />
      </Card>
    </>
  )
}

/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from '@bonitour/app-functions'
import { Button, GhostButton, HeaderPage, useToast } from '@bonitour/components'
import { BankAccountForm } from '../Form/BankAccountForm'
import { BankAccountSchema } from '../Form/BankAccount.schema'
import { marginRight, marginTop } from 'assets/styles/global'
import { usePermissions } from 'hooks/usePermissions'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useEditBankAccount } from '../hooks/useEditBankAccount'

const marginTop20 = marginTop(20)
const marginRight10 = marginRight(10)

export const EditBankAccount = () => {
  const { push } = useHistory()
  const { bankAccountId } = useParams()
  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()
  const { bankAccount, updateBankAccount } = useEditBankAccount(bankAccountId)
  const { form, errors, onSubmit, utils: { onInputBlur, onInputChange } } = useForm(bankAccount, BankAccountSchema)

  const redirectToList = () => push('/bank-accounts')

  const onValidationSuccess = useCallback((data) => {
    updateBankAccount(data)
      .then(() => {
        addToast('Conta bancária editada com sucesso', 'success')
        redirectToList()
      })
      .catch((error) => {
        const hasPermission = handlePermission(error, 'Você não possui permissão de edição de conta bancária')
        hasPermission && addToast('Erro ao salvar conta bancária')
      })
    // eslint-disable-next-line
  }, [])

  const onValidationError = useCallback(() => addToast('Preencha corretamente o formulário'), [addToast])

  const onSaveClick = useMemo(() => onSubmit(onValidationSuccess, onValidationError), [onSubmit, onValidationSuccess, onValidationError])

  return (
    <>
      <HeaderPage onBack={redirectToList} title='Editar conta bancária' />
      <BankAccountForm form={form} errors={errors} onInputBlur={onInputBlur} onInputChange={onInputChange} />
      <div css={marginTop20}>
        <GhostButton onClick={redirectToList} css={marginRight10}>Cancelar</GhostButton>
        <Button onClick={onSaveClick}>Salvar</Button>
      </div>
    </>
  )
}

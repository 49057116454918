/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Card, colors, InputFormGroup, Input, InputWithPrefix, InputWithSuffix, CircleThumbnail, DatePicker, Column, Row, InfoSwatch, MaskedInput } from '@bonitour/components'
import { marginBottom } from 'assets/styles/global'
import { identity } from '@bonitour/common-functions'
import { invoiceStatusMap } from 'constants/invoiceStatus'
import moment from 'moment'
import { useMemo } from 'react'

const marginBottom30 = marginBottom(30)

const width240 = css`
  width: 240px;
`

const fullWidthInput = css`
  width: 100%;
`

const container = css`
  color: ${colors.gray3};
  background-color: ${colors.gray13};
  border: none;
  ${marginBottom30};
`

const infoTextColor = css`
  -webkit-text-fill-color: ${colors.white};
  color: ${colors.white};
`

const formatInvoiceNumber = (value) => value?.replace(/\D/g, '')

export const InvoiceInfo = ({
  invoiceInfo = {},
  invoiceInfoErrors = {},
  onChange: emitChangeEvent = identity,
  isReadOnly = false,
  disableInvoiceNumber = false,
  amountDue = 0
}) => {
  const {
    vendor = '',
    partner = '',
    periodRange = '',
    status = '',
    dueDate = '',
    discountPercentage = '',
    taxPercentage = '',
    invoiceNumber = '',
    generationDate = '',
    generationHour = '',
    endDate = '',
    endHour = ''
  } = invoiceInfo

  const [codeName, codeColor] = invoiceStatusMap[status] || invoiceStatusMap.open
  const isFieldBlocked = codeName === 'Aguardando envio' && amountDue === 0
  const tomorrow = useMemo(() => moment().add(1, 'days').toDate(), [])

  return (
    <Card css={container}>
      <Row>
        <Column phone={12} desktop={3}>
          <InputFormGroup id='vendor' label='Emissor'>
            <Input value={vendor} readOnly />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={3}>
          <InputFormGroup id='partner' label='Devedor'>
            <Input value={partner} readOnly />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={3}>
          <InputFormGroup id='periodRange' label='Competência'>
            <InputWithSuffix
              value={periodRange}
              readOnly
            >
              <InfoSwatch
                size={200}
                tooltip={`O período de competência desta fatura é de ${generationDate} às ${generationHour}h até ${endDate} às ${endHour}h.`}
                customTooltip={[infoTextColor]}
              />
            </InputWithSuffix>
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={3}>
          <InputFormGroup id='status' label='Status'>
            <InputWithPrefix readOnly value={codeName}>
              <CircleThumbnail size={10} style={{ backgroundColor: codeColor }} />
            </InputWithPrefix>
          </InputFormGroup>
        </Column>
      </Row>

      <Row>
        <Column phone={12} desktop={3}>
          <InputFormGroup css={width240} id='dueDate' errorMessage={invoiceInfoErrors.dueDate} label='Vencimento'>
            <DatePicker
              readOnly={isReadOnly}
              value={dueDate}
              onChange={emitChangeEvent('info.dueDate')}
              css={fullWidthInput}
              minDate={tomorrow}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={3}>
          <InputFormGroup id='discountPercentage' errorMessage={invoiceInfoErrors.discountPercentage} label='Desconto'>
            <InputWithSuffix
              min='0'
              type='number'
              readOnly={isFieldBlocked || isReadOnly}
              value={discountPercentage}
              onChange={emitChangeEvent('info.discountPercentage')}
            >
              %
            </InputWithSuffix>
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={3}>
          <InputFormGroup id='taxPercentage' errorMessage={invoiceInfoErrors.taxPercentage} label='Imposto a reter'>
            <InputWithSuffix
              min='0'
              type='number'
              readOnly={isFieldBlocked || isReadOnly}
              value={taxPercentage}
              onChange={emitChangeEvent('info.taxPercentage')}
            >
              %
            </InputWithSuffix>
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={3}>
          <InputFormGroup
            id='invoiceNumber'
            errorMessage={invoiceInfoErrors.invoiceNumber}
            label='Número da nota fiscal'
          >
            <MaskedInput
              formatterFunction={formatInvoiceNumber}
              readOnly={isReadOnly || disableInvoiceNumber}
              value={invoiceNumber}
              onChange={emitChangeEvent('info.invoiceNumber')}
            />
          </InputFormGroup>
        </Column>
      </Row>
    </Card>
  )
}

import { colors } from '@bonitour/components'
import { css } from '@emotion/core'
import { marginTop } from 'assets/styles/global'

export const marginTop30 = marginTop(30)

export const cardButton = css`
  width: 100px;
  padding: 10px 20px;
  &:last-of-type {
    margin-right: 0;
  }
`

export const cardIcon = css`
  width: 22px;
  height: 22px;
  padding: 5px;
  background-color: ${colors.primary};
  color: ${colors.white};
  border-radius: var(--round-element-radius, 100%);
`

export const labelCardButton = css`
  width: 100%;
  cursor: pointer;
`

import { useCallback, useEffect, useState } from 'react'
import { useToast } from '@bonitour/components'
import { usePermissions } from 'hooks/usePermissions'
import { ExternalProviderService } from 'core/services/ExternalProvider'

export const useExternalProviderOptions = (hasNullOption = false, nullOptionLabel = 'Todos') => {
  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()
  const [loading, setLoading] = useState(false)
  const [externalProvidersOptions, setExternalProvidersOptions] = useState([])

  const fetchAllExternalProviders = useCallback(() => {
    setLoading(true)
    return ExternalProviderService.options()
      .then((data) => setExternalProvidersOptions([
        ...(hasNullOption ? [{ label: nullOptionLabel, value: '' }] : []),
        ...data
      ]))
      .catch((error) => {
        const hasPermission = handlePermission(error, 'Você não possui permissão de acesso aos fornecedores externos')
        hasPermission && addToast('Ocorreu um erro ao realizar a busca')
      })
      .finally(() => setLoading(false))
  // eslint-disable-next-line
  }, [])

  useEffect(() => {
    fetchAllExternalProviders()
    // eslint-disable-next-line
  }, [])

  return {
    externalProvidersOptions,
    fetchAllExternalProviders,
    loading
  }
}

import React, { useMemo } from 'react'
import { TableRow, TableV2 } from '@bonitour/components'
import { offlineTableBreakpoint, tableCss, tableOfflineComplement } from './TicketSelector.style'
import { TicketStatus as getTicketStatus } from 'containers/Reservation/TicketStatus'
import {
  geTableRowClassName,
  getTicketLabelAction,
  getTicketSlot,
  getTicketName,
  getTicketReservationCodeLabel,
  getTicketValueAction
} from './TicketSelector.utils'
import { useMediaQuery } from '@bonitour/app-functions'
import { formatMoney } from '@bonitour/common-functions'

export const TicketOfflineTable = ({
  tickets = [],
  handleOnSelectRow,
  isEditing,
  selectedIds,
  isLoading,
  isBinded,
  isAllSelected,
  handleToggleAll
}) => {
  const mobileBreakpoint = 1270
  const isMobile = useMediaQuery(`(max-width: ${mobileBreakpoint}px)`)

  const labels = useMemo(
    () => [
      <>
        {getTicketLabelAction({
          isAllSelected,
          onClickCallBack: handleToggleAll,
          isMobile,
          isBinded
        })}
      </>,
      'Data do ticket',
      'Nome / Reserva',
      'Experiência',
      'Valor',
      'Valor Net',
      'Status'
    ],
    [handleToggleAll, isAllSelected, isBinded, isMobile]
  )

  const columnsWidths = ['2.5rem', '8rem', '4fr', '3fr', '9.15rem', '8.4rem', '12rem']

  return (
    <TableV2
      isLoading={isLoading}
      labels={labels}
      columnsWidths={columnsWidths}
      emptyMessage={`Nenhum item ${isBinded ? 'vinculado' : 'encontrado'}`}
      customCss={[tableCss, offlineTableBreakpoint, tableOfflineComplement]}
      mobileBreakpoint={mobileBreakpoint}
    >
      {[
        tickets?.map((ticket, idx) => (
          <TableRow
            className={geTableRowClassName({
              isBinded,
              selectedIds,
              ticket
            })}
            onClick={() => !isBinded && handleOnSelectRow(ticket?.ticketId)}
            key={ticket.ticketId || idx}
          >
            {[
              <>
                {getTicketValueAction({
                  isEditing,
                  selectedIds,
                  ticketId: ticket.ticketId,
                  onClickCallBack: handleOnSelectRow,
                  isMobile
                })}
              </>,
              <>{getTicketSlot(ticket?.slot || ticket?.ticketSlot)}</>,
              <>{getTicketReservationCodeLabel(ticket)}</>,
              <>{getTicketName(ticket)}</>,
              <>{formatMoney(ticket?.ticketPrice || ticket?.offlinePrice)}</>,
              <>{formatMoney(ticket?.commissionValue || ticket?.offlineNetPrice)}</>,
              <>{getTicketStatus(ticket.itemState || ticket.ticketState)}</>
            ]}
          </TableRow>
        ))
      ] || []}
    </TableV2>
  )
}

import { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import { useToast } from '@bonitour/components'
import { useParamsFilter } from 'hooks/useParamsFilter'
import { CashierService } from 'core/services/Cashier'
import { usePermissions } from 'hooks/usePermissions'

export const useListCashiers = () => {
  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()
  const [cashiersData, setCashiersData] = useState({ cashiers: undefined, meta: undefined })
  const [loading, setLoading] = useState(false)
  const [filters, setFilters] = useParamsFilter()
  const [pagination, setPagination] = useState({ currentPage: 1, perPage: 10 })

  const [initialFilters] = useState({
    initialDate: filters?.initialDate ? moment(filters?.initialDate, 'YYYY-MM-DD') : moment({ format: 'YYYY-MM-DD' }),
    finalDate: filters?.finalDate ? moment(filters?.finalDate, 'YYYY-MM-DD') : moment({ format: 'YYYY-MM-DD' })
  })

  const handleFiltersChange = useCallback((filters) => {
    const filterData = {}

    Object.keys(filters).forEach(filterKey => {
      if (filterKey === 'initialDate' || filterKey === 'finalDate') {
        filterData[filterKey] = filters[filterKey] && moment(filters[filterKey]).format('YYYY-MM-DD')
      } else {
        filterData[filterKey] = filters[filterKey]
      }
    })

    setFilters(filterData)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePagination = useCallback((currentPage, perPage) => {
    if (currentPage === pagination.currentPage && perPage === pagination.perPage) {
      return
    }

    setPagination({ currentPage, perPage })
  }, [pagination.currentPage, pagination.perPage])

  const fetchCashiers = useCallback(({ filters, pagination }) => {
    setLoading(true)

    return CashierService.list({ filters, pagination }).then((data) => setCashiersData(data))
      .catch((error) => {
        const hasPermission = handlePermission(error, 'Você não possui permissão de acesso a lista de caixas')
        hasPermission && addToast('Ocorreu um erro ao realizar a busca')
      })
      .finally(() => setLoading(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const hasFilters = Object.keys(filters).length > 0
    fetchCashiers({
      pagination,
      filters: hasFilters ? filters : initialFilters
    })
  }, [pagination, filters, fetchCashiers, initialFilters])

  return {
    filters,
    initialFilters,
    setFilters: handleFiltersChange,
    loading,
    cashiers: cashiersData?.cashiers,
    handlePagination,
    meta: cashiersData?.meta
  }
}

import React from 'react'
import { PlusIcon, TrashIcon } from '@bonitour/components'
import { formatDate } from '@bonitour/common-functions'
import { pointer } from './TicketSelector.style'

export const getTicketValueAction = ({
  isEditing = false,
  selectedIds = [],
  ticketId = '',
  isMobile = false,
  onClickCallBack = () => {}
}) => {
  if (!isEditing && isMobile) return 'Nenhuma ação.'
  if (!isEditing) return ''
  if (selectedIds.includes(ticketId)) {
    return <TrashIcon key="add-remove" onClick={() => onClickCallBack(ticketId)} css={pointer} />
  } else {
    return <PlusIcon key="add-remove" css={pointer} />
  }
}

export const getTicketLabelAction = ({
  isAllSelected = false,
  isMobile = false,
  isBinded = false,
  onClickCallBack = () => {}
}) => {
  if (isMobile) return 'Ações'
  if (!isMobile && isBinded) return ''
  if (isAllSelected) {
    return <TrashIcon key="global-toggle" onClick={onClickCallBack} css={pointer} />
  } else {
    return <PlusIcon key="global-toggle" onClick={onClickCallBack} css={pointer} />
  }
}

export const geTableRowClassName = ({ ticket = {}, isBinded = false, selectedIds = [] }) => {
  return isBinded
    ? 'table_row table_row__binded'
    : selectedIds.includes(ticket?.ticketId)
      ? 'table_row table_row__selected'
      : 'table_row'
}

export const getTicketName = (ticket) => {
  return ticket?.serviceName || '--'
}

export const getTicketSlot = (ticketSlot) => {
  if (ticketSlot) {
    return formatDate(ticketSlot)
  }
  return '--'
}

export const getTicketReservationCodeLabel = (ticket) => {
  if (ticket.reservationId) {
    return (
      <a
        target="_blank"
        href={`/reservation/${ticket.reservationId}`}
        key="name-reservation"
        rel="noreferrer"
        onClick={(event) => {
          event.stopPropagation()
        }}
      >
        {ticket.payerName} - {ticket.reservationCode}
      </a>
    )
  }
  return '--'
}

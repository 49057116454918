/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useMemo } from 'react'
import { marginTop, marginBottom, marginRight, marginLeft, required, margin } from 'assets/styles/global'
import { head, identity, tail } from '@bonitour/common-functions'
import { useForm } from '@bonitour/app-functions'
import { transportComplementaryInfoSchema } from 'core/schemas/transportComplementaryInfo'
import { H3, colors, Textarea, GhostButton, flexRow, Button, CheckboxInputGroup, flexColumn, ToggleInputGroup, flexHorizontallyCentered, InfoSwatch, InputWithSuffix, HorizontalInputFormGroup } from '@bonitour/components'

const marginTop50 = marginTop(50)
const marginBottom20 = marginBottom(20)
const marginRight20 = marginRight(20)
const marginRight10 = marginRight(10)
const marginLeft10 = marginLeft(10)
const marginVertical5 = margin(5, 0, 5, 0)

const container = css`
  color: ${colors.gray3};
`

const inputStyle = css`
  width: 120px;
  ${marginRight10};
  ${marginLeft10};
`

const checkboxContainer = css`
  display: grid;
  grid-template-columns: auto auto auto auto;
`

export const TransportComplementaryInfoForm = ({
  transportComplementaryInfo = {},
  paymentsTypeList,
  whatToTakeList,
  whatIsIncludedList,
  onBackClick: emitBackClickEvent = identity,
  onSuccess: emitSuccessEvent = identity,
  onValidationError: emitValidationError = identity
}) => {
  const {
    form,
    errors,
    onSubmit,
    utils: { onInputChange }
  } = useForm(transportComplementaryInfo, transportComplementaryInfoSchema)

  const {
    description,
    observation,
    isAllyVisible,
    sellingDeadline = 0,
    whatToTake = [],
    whatIsIncluded = [],
    paymentsTypes = []
  } = form

  const hasWhatToTakeList = useMemo(() => whatToTakeList.length > 0, [whatToTakeList])
  const hasWhatIsIncludedList = useMemo(() => whatIsIncludedList.length > 0, [whatIsIncludedList])
  const hasPaymentsTypeList = useMemo(() => paymentsTypeList.length > 0, [paymentsTypeList])

  const haveWhatToTake = value => whatToTake.includes(value)
  const haveWhatIsIncluded = value => whatIsIncluded.includes(value)
  const havePaymentsTypes = value => paymentsTypes.includes(value)

  const handleWhatToTake = value => () => {
    if (haveWhatToTake(value)) {
      onInputChange('whatToTake')(whatToTake.filter(item => item !== value))
    } else {
      onInputChange('whatToTake')([...whatToTake, value])
    }
  }

  const handleWhatIsIncluded = value => () => {
    if (haveWhatIsIncluded(value)) {
      onInputChange('whatIsIncluded')(whatIsIncluded.filter(item => item !== value))
    } else {
      onInputChange('whatIsIncluded')([...whatIsIncluded, value])
    }
  }

  const handlePaymentsTypes = value => () => {
    if (havePaymentsTypes(value)) {
      onInputChange('paymentsTypes')(paymentsTypes.filter(item => item !== value))
    } else {
      onInputChange('paymentsTypes')([...paymentsTypes, value])
    }
  }
  const onChangeAllyVisibility = () => onInputChange('isAllyVisible')(!isAllyVisible)

  const onClick = onSubmit(emitSuccessEvent, emitValidationError)

  return (
    <div css={container}>
      <H3 css={marginBottom20}>Descrição<span css={required}>(Obrigatório)</span></H3>
      <Textarea onChange={onInputChange('description')} value={description} />

      <H3 css={[marginTop50, marginBottom20]}>Observações</H3>
      <Textarea onChange={onInputChange('observation')} value={observation} />

      {hasWhatToTakeList &&
        <div css={flexColumn}>
          <H3 css={[marginTop50, marginBottom20]}>O que levar</H3>
          <div css={checkboxContainer}>
            {whatToTakeList.map(option => (
              <div css={marginVertical5} key={head(option)}>
                <CheckboxInputGroup
                  id={head(option)}
                  onChange={handleWhatToTake(head(option))}
                  checked={haveWhatToTake(head(option))}
                >
                  {tail(option)}
                </CheckboxInputGroup>
              </div>
            ))}
          </div>
        </div>}

      {hasWhatIsIncludedList &&
        <div css={flexColumn}>
          <H3 css={[marginTop50, marginBottom20]}>Incluso no transporte</H3>
          <div css={checkboxContainer}>
            {whatIsIncludedList.map(option => (
              <div css={marginVertical5} key={head(option)}>
                <CheckboxInputGroup
                  id={head(option)}
                  onChange={handleWhatIsIncluded(head(option))}
                  checked={haveWhatIsIncluded(head(option))}
                >
                  {tail(option)}
                </CheckboxInputGroup>
              </div>
            ))}
          </div>
        </div>}

      {hasPaymentsTypeList &&
        <div css={flexColumn}>
          <H3 css={[marginTop50, marginBottom20]}>Pagamentos aceitos</H3>
          {paymentsTypeList.map(option => (
            <div key={head(option)}>
              <CheckboxInputGroup
                id={head(option)}
                onChange={handlePaymentsTypes(head(option))}
                checked={havePaymentsTypes(head(option))}
              >
                {tail(option)}
              </CheckboxInputGroup>
            </div>
          ))}
        </div>}

      <div css={flexColumn}>
        <H3 css={[marginTop50, marginBottom20]}>Aplicativo</H3>
        <ToggleInputGroup id='ally-visible' checked={isAllyVisible} onChange={onChangeAllyVisibility}>
          Habilitar transporte no aplicativo Ally
        </ToggleInputGroup>
      </div>

      <div css={flexColumn}>
        <H3 css={[marginTop50, marginBottom20]}>Antecedência da venda online</H3>
        <div css={flexHorizontallyCentered}>
          <span>Permitir a venda online até</span>
          <HorizontalInputFormGroup errorMessage={errors.sellingDeadline}>
            <InputWithSuffix
              type='number'
              css={inputStyle}
              value={sellingDeadline}
              onChange={onInputChange('sellingDeadline')}
            >
              <InfoSwatch css={marginRight10} size={140} tooltip='As vendas online são realizadas via Xpert e Ally' />
            </InputWithSuffix>
            <span>minutos antes do horário do transporte</span>
          </HorizontalInputFormGroup>
        </div>
      </div>

      <div css={[flexRow, marginTop50]}>
        <GhostButton css={marginRight20} onClick={emitBackClickEvent}>Voltar</GhostButton>
        <Button onClick={onClick}>Salvar e continuar</Button>
      </div>
    </div>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useState } from 'react'
import { identity } from '@bonitour/common-functions'
import { GroupedFab, PlusIcon, CloseIcon, UserIcon, PartnersIcon, Tag, CircleButton, Dialog } from '@bonitour/components'
import { floating, buttonContainer, fontSize20, tagStyle } from './Fab.style'
import { SellerInviteForm } from 'containers/Sellers/InviteForm/InviteForm'
import { useHistory } from 'react-router-dom'
import { whiteButton } from 'assets/styles/global'

export const SellerFab = ({
  groups = [],
  partnerInvitation = {},
  onInviteFormSubmission: emitInviteFormSubmission = identity,
  onValidationError: emitValidationError = identity
}) => {
  const { push } = useHistory()
  const createPartnershipGroup = () => push('/group/create')
  const [isInvitePartnerOpen, setInvitePartnerVisibility] = useState(false)
  const openInvitePartner = () => setInvitePartnerVisibility(true)
  const closeInvitePartner = () => setInvitePartnerVisibility(false)

  const onSellerInviteSuccess = (data) => {
    emitInviteFormSubmission(data)
    closeInvitePartner()
  }

  return (
    <>
      <Dialog title='Convidar parceiro fora da plataforma' isVisible={isInvitePartnerOpen} onClose={closeInvitePartner}>
        <SellerInviteForm
          partnerInvitation={partnerInvitation}
          groups={groups}
          onValidationError={emitValidationError}
          onSuccess={onSellerInviteSuccess}
          onCancel={closeInvitePartner}
        />
      </Dialog>

      {isInvitePartnerOpen || (
        <GroupedFab containerCss={floating} BaseIcon={PlusIcon} HoverIcon={CloseIcon}>
          <>
            <div css={buttonContainer} onClick={createPartnershipGroup}>
              <Tag css={tagStyle}>Novo grupo</Tag>
              <CircleButton size={50} css={whiteButton}>
                <PartnersIcon css={fontSize20} />
              </CircleButton>
            </div>

            {groups.length > 0 && (
              <div css={buttonContainer} onClick={openInvitePartner}>
                <Tag css={tagStyle}>Convidar parceiro fora do Orb</Tag>
                <CircleButton size={50} css={whiteButton}>
                  <UserIcon css={fontSize20} />
                </CircleButton>
              </div>)}
          </>
        </GroupedFab>
      )}
    </>
  )
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useCallback } from 'react'
import { useForm } from '@bonitour/app-functions'
import { useAddressLocation, useZipCode, useGeoCode } from 'containers/Address/Address.hooks'
import { transportInfoSchema } from 'containers/TransportBaseForm/TransportInfo.schema'

export const useTransportForm = ({ transportBase, addToast, onSuccess }) => {
  const { originInfo: { country: originCountry, state: originState } = {} } = transportBase ?? {}
  const { destinationInfo: { country: destinationCountry, state: destinationState } = {} } = transportBase ?? {}

  const originAddress = useAddressLocation(addToast, originCountry, originState)
  const destinationAddress = useAddressLocation(addToast, destinationCountry, destinationState)

  const [originZipCodeData, onOriginZipCodeUpdate] = useZipCode(addToast)
  const [destinationZipCodeData, onDestinationZipCodeUpdate] = useZipCode(addToast)

  const [originLatLong, onOriginGeoCodeUpdate] = useGeoCode(addToast)
  const [destinationLatLong, onDestinationGeoCodeUpdate] = useGeoCode(addToast)

  const schema = useMemo(() => transportInfoSchema(
    { states: originAddress.states, destination: originAddress.cities },
    { states: destinationAddress.states, destinationAddress: destinationAddress.cities }
  ), [originAddress.states, originAddress.cities, destinationAddress.states, destinationAddress.cities])
  const {
    form,
    errors,
    setForm,
    onSubmit,
    utils: { onInputBlur, onInputChange, addItemOnArray, removeItemArray }
  } = useForm(transportBase, schema)

  const onValidationError = useCallback(() => addToast('Preencha corretamente o formulário'), [addToast])
  const handleSubmit = useMemo(() => onSubmit(onSuccess, onValidationError), [onSubmit, onSuccess, onValidationError])
  const onFormError = useCallback((message) => addToast(message), [addToast])

  const dataByType = (type) => ({
    address: type === 'origin' ? originAddress : destinationAddress,
    propName: type === 'origin' ? 'originInfo' : 'destinationInfo',
    zipCodeData: type === 'origin' ? originZipCodeData : destinationZipCodeData,
    onZipCodeUpdate: type === 'origin' ? onOriginZipCodeUpdate : onDestinationZipCodeUpdate,
    onGeoCodeUpdate: type === 'origin' ? onOriginGeoCodeUpdate : onDestinationGeoCodeUpdate
  })

  const onGeoCodeUpdate = useCallback((type) => {
    const { onGeoCodeUpdate } = dataByType(type)
    return onGeoCodeUpdate
  }, [onOriginGeoCodeUpdate, onDestinationGeoCodeUpdate])

  useEffect(() => {
    if (!form?.originInfo) return
    onInputChange('originInfo')({ ...form.originInfo, ...originLatLong })
  }, [originLatLong])
  useEffect(() => {
    if (!form?.destinationInfo) return
    onInputChange('destinationInfo')({ ...form.destinationInfo, ...destinationLatLong })
  }, [destinationLatLong])

  const onZipCodeBlur = useCallback(
    (type) => () => {
      const { propName, onZipCodeUpdate } = dataByType(type)
      onZipCodeUpdate(form[propName]?.zipCode)
      onInputBlur(`${propName}.zipCode`)()
    },
    [
      onOriginZipCodeUpdate,
      form.originInfo?.zipCode,
      onDestinationZipCodeUpdate,
      form.destinationInfo?.zipCode,
      onInputBlur
    ]
  )

  const onCountryChange = useCallback(
    (type) => (countryValue) => {
      const { address, propName } = dataByType(type)

      const country = address.countries.find((country) => Object.values(country).includes(countryValue))
      if (country) {
        address.updateAvailableStates(country.value)
        onInputChange(`${propName}.country`)(country.value)
      } else {
        address.updateAvailableStates(null)
        onInputChange(`${propName}.country`)('')
        onInputChange(`${propName}.state`)('')
        onInputChange(`${propName}.city`)('')
      }
    },
    [
      originAddress.countries,
      originAddress.updateAvailableStates,
      destinationAddress.countries,
      destinationAddress.updateAvailableStates,
      onInputChange
    ]
  )

  const onStateChange = useCallback(
    (type) => (stateValue) => {
      const { address, propName } = dataByType(type)

      const state = address.states.find((state) => Object.values(state).includes(stateValue))
      if (state) {
        address.updateAvailableCities(form[propName]?.country, state.value)
        onInputChange(`${propName}.state`)(state.value)
      } else {
        originAddress.updateAvailableCities(null)
        onInputChange(`${propName}.state`)('')
        onInputChange(`${propName}.city`)('')
      }
    },
    [
      originAddress.states,
      originAddress.updateAvailableCities,
      form.originInfo?.country,
      destinationAddress.states,
      destinationAddress.updateAvailableCities,
      form.destinationInfo?.country,
      onInputChange
    ]
  )

  const onCityChange = useCallback(
    (type) => (cityValue) => {
      const { address, propName } = dataByType(type)

      const city = address.cities.find((city) => Object.values(city).includes(cityValue))
      if (city) {
        onInputChange(`${propName}.city`)(city.value)
      } else {
        onInputChange(`${propName}.city`)('')
      }
    },
    [originAddress.cities, destinationAddress.cities, onInputChange]
  )

  const updateAddress = useCallback((type) => () => {
    const { zipCodeData, propName } = dataByType(type)
    if (Object.keys(zipCodeData).length) {
      setForm((previousForm) => ({
        ...previousForm,
        [propName]: {
          ...previousForm[propName],
          street: zipCodeData.address,
          district: zipCodeData.district,
          state: zipCodeData.state,
          city: zipCodeData.city
        }
      }))
      onCountryChange(type)('BR')
    }
  }, [setForm, onCountryChange, originZipCodeData, destinationZipCodeData])

  useEffect(() => { updateAddress('origin')() }, [originZipCodeData])
  useEffect(() => { updateAddress('destination')() }, [destinationZipCodeData])

  const updateCountries = useCallback((type) => () => {
    const { address, propName } = dataByType(type)

    if (address.countries?.length) {
      onCountryChange(type)(form[propName]?.country)
    }
  }, [
    originAddress.countries,
    destinationAddress.countries,
    onCountryChange,
    form.originInfo?.country,
    form.destinationInfo?.country
  ])

  useEffect(() => { updateCountries('origin')() }, [originAddress.countries])
  useEffect(() => { updateCountries('destination')() }, [destinationAddress.countries])

  const updateStates = useCallback((type) => () => {
    const { address, propName } = dataByType(type)
    if (address.states?.length) {
      onStateChange(type)(form[propName]?.state)
    }
  }, [
    originAddress.states,
    destinationAddress.states,
    onStateChange,
    form.originInfo?.state,
    form.destinationInfo?.state
  ])

  useEffect(() => { updateStates('origin')() }, [originAddress.states])
  useEffect(() => { updateStates('destination')() }, [destinationAddress.states])

  const updateCities = useCallback((type) => () => {
    const { address, propName } = dataByType(type)

    if (address.cities?.length) {
      onCityChange(type)(form[propName]?.city)
    }
  }, [originAddress.cities, destinationAddress.cities, onCityChange, form.originInfo?.city, form.destinationInfo?.city])

  useEffect(() => { updateCities('origin')() }, [originAddress.cities])
  useEffect(() => { updateCities('destination')() }, [destinationAddress.cities])

  return {
    form,
    errors,
    origin: {
      countries: originAddress.countries,
      states: originAddress.states,
      cities: originAddress.cities
    },
    destination: {
      countries: destinationAddress.countries,
      states: destinationAddress.states,
      cities: destinationAddress.cities
    },
    onInputChange,
    addItemOnArray,
    removeItemArray,
    onInputBlur,
    onZipCodeBlur,
    onCountryChange,
    onStateChange,
    onLatLongBlur: onGeoCodeUpdate,
    onError: onFormError,
    onSubmit: handleSubmit

  }
}

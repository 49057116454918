/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { ReservationFinance } from 'containers/Reservation/Finance/Finance'
import { useHistory } from 'react-router-dom'
import { TransactionsProvider } from 'domains/Reservation/Transactions/hooks/useTransactions'
import { ReservationFinanceProvider } from 'containers/Reservation/Finance/FinanceProvider'
import { useCompany } from 'contexts/Company'
import { useCheckIsRefund } from 'containers/Reservation/hooks/useCheckIsRefund'
import { PaymentsTotalizersProvider } from 'domains/Reservation/Transactions/hooks/usePaymentsTotalizers'

export const ReservationFinanceApp = ({
  reservationId = '',
  isCommercialPartner = false,
  reservation,
  getSubordinates,
  subordinatesLoading,
  hasFiscalDuty = false
}) => {
  const { id } = useCompany()
  const history = useHistory()
  const redirectToPay = () => history.push('/checkout')
  const { partnerId: accountableId = '' } = reservation
  const isCompanyAccountable = accountableId === id
  const { isRefund } = useCheckIsRefund(reservationId)

  return (
    <PaymentsTotalizersProvider>
      <TransactionsProvider isCommercialPartner={isCommercialPartner}>
        <ReservationFinance
          reservationId={reservationId}
          isCommercialPartner={isCommercialPartner}
          getSubordinates={getSubordinates}
          subordinatesLoading={subordinatesLoading}
          redirectToPay={redirectToPay}
          hasFiscalDuty={hasFiscalDuty}
        />

        <ReservationFinanceProvider
          reservationId={reservationId}
          isCommercialPartner={isCommercialPartner}
          isCompanyAccountable={isCompanyAccountable}
          isRefund={isRefund}
          partnerId={accountableId}
        />
      </TransactionsProvider>
    </PaymentsTotalizersProvider>
  )
}

export const invoicePaymentTypesOptions = [
  { label: 'Cartão de Débito', value: 'debit' },
  { label: 'Cartão de Crédito', value: 'credit' },
  { label: 'Dinheiro', value: 'cash' },
  { label: 'Boleto', value: 'bank_billet' },
  { label: 'Transferência Bancária', value: 'bank_transfer' },
  { label: 'PIX', value: 'pix' },
  { label: 'Crédito da parceria', value: 'partnership_account_credit' },
  { label: 'Cheque', value: 'bank_check' }
]

export const invoicePaymentTypeMap = invoicePaymentTypesOptions.reduce((acc, { label, value }) => {
  acc[value] = label
  return acc
}, {})

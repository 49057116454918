/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useCallback } from 'react'
import { TableBodyRow, TableBodyCell, CircleThumbnail } from '@bonitour/components'
import { capitalize, circleColor } from 'assets/styles/global'
import { formatMoney, identity } from '@bonitour/common-functions'
import { formatISOTime } from 'utils/time'
import { defaultCellPadding } from './InvoicesBatchTableRow.style'
import { batchStatusMap } from 'constants/paymentBatch'

export const InvoicesBatchTableRow = ({
  batch = {},
  ActionsComponent = identity
}) => {
  const { referenceNumber = '', expectedPaymentDate = '', status = '', value = '', liquidValue = '', beneficiaryName = '---' } = batch

  const stopPropagation = useCallback(event => event.stopPropagation(), [])

  const [label, color] = batchStatusMap[status] || []
  return (
    <>
      <TableBodyRow>
        <TableBodyCell>
          {expectedPaymentDate ? `${formatISOTime(expectedPaymentDate, 'DD/MM/YYYY', false)}` : ''}
        </TableBodyCell>
        <TableBodyCell>
          {beneficiaryName}
        </TableBodyCell>
        <TableBodyCell>
          {referenceNumber}
        </TableBodyCell>
        <TableBodyCell>
          {formatMoney(liquidValue || value)}
        </TableBodyCell>
        <TableBodyCell css={capitalize}>
          <CircleThumbnail size={10} css={circleColor(color)} />
          <span>{label}</span>
        </TableBodyCell>
        <TableBodyCell css={defaultCellPadding} onClick={stopPropagation}>
          <ActionsComponent
            batch={batch}
          />
        </TableBodyCell>
      </TableBodyRow>
    </>
  )
}

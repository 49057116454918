import { useCallback, useEffect, useState } from 'react'
import { useToast } from '@bonitour/components'
import { IntegrationService } from 'core/services/Integration'
import { usePermissions } from 'hooks/usePermissions'

export const useListIntegration = ({ perPage = 10 } = {}) => {
  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()
  const [loading, setLoading] = useState(false)
  const [isConfirmIntegrationVisible, setConfirmIntegrationVisible] = useState(false)
  const toggleConfirmIntegrationVisible = () => {
    setConfirmIntegrationVisible(visibility => !visibility)
  }
  const [pagination, setPagination] = useState({ currentPage: 1, perPage })

  const changePagination = useCallback((currentPage, perPage) => {
    setPagination({ currentPage, perPage })
    // eslint-disable-next-line
  }, [])

  const [integrationsData, setIntegrationsData] = useState({ integrations: undefined, meta: undefined })

  const fetchIntegrations = useCallback(({ pagination }) => {
    setLoading(true)

    return IntegrationService.list({ pagination }).then(setIntegrationsData)
      .catch((error) => {
        const hasPermission = handlePermission(error, 'Você não possui permissão de acesso as integrações')
        hasPermission && addToast('Ocorreu um erro ao realizar a busca')
      })
      .finally(() => setLoading(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchIntegrations({ pagination })
  }, [pagination, fetchIntegrations])

  return {
    pagination,
    changePagination,
    integrations: integrationsData?.integrations,
    meta: integrationsData?.meta,
    fetchIntegrations,
    loading,
    isConfirmIntegrationVisible,
    toggleConfirmIntegrationVisible
  }
}

import { useState, useEffect, useCallback } from 'react'
import { PlanService } from 'core/services/Plan'

export const useCompanyPlan = (id) => {
  const [plan, setPlan] = useState({})
  const [loading, setLoading] = useState(false)

  const fetchCompanyPlan = useCallback((companyId) => {
    setLoading(true)

    return PlanService.getById(companyId).then((data) => {
      setPlan(data)
    }).finally(() => {
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    fetchCompanyPlan(id)
  }, [id, fetchCompanyPlan])

  return {
    plan,
    setPlan,
    loading
  }
}

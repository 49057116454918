/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { flexRow, H1, Button, GhostButton, flexCenteredSpaceBetweenRow, BREAK_POINTS } from '@bonitour/components'
import { marginBottom } from 'assets/styles/global'

const ghostStyling = css`
  margin-right: 20px;
  padding: 12px 30px;

  @media (max-width: ${BREAK_POINTS.tableUp}) {
    margin-right: 0;
    padding: 10px 20px;
  }
`

const ctaPadding = css`
  padding: 12px 40px;

  @media (max-width: ${BREAK_POINTS.tableUp}) {
    padding: 10px 20px;
    margin-top: 10px;
  }
`

const buttonsContainer = css`
  @media (max-width: ${BREAK_POINTS.tableUp}) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`

const headerContainer = css`
    @media (max-width: ${BREAK_POINTS.tableUp}) {
    flex-direction: column;
  }
`

export const FormHeader = ({ title, ctaTitle = 'Salvar', ghostTitle = 'Cancelar', ctaClick, ghostClick, ...other }) => (
  <div {...other} css={[flexRow, marginBottom(20), flexCenteredSpaceBetweenRow, headerContainer]}>
    <H1>{title}</H1>
    <div css={buttonsContainer}>
      {Boolean(ghostClick) && (<GhostButton onClick={ghostClick} css={ghostStyling}>{ghostTitle}</GhostButton>)}
      {Boolean(ctaClick) && (<Button onClick={ctaClick} css={ctaPadding}>{ctaTitle}</Button>)}
    </div>
  </div>
)

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Card, colors, Row } from '@bonitour/components'
import { formatMoney } from '@bonitour/common-functions'
import { companyNameStyle, valueStyle, alignLeft } from '../List/PartnershipAccountCard.styles'
import { smallContentContainer, smallCard, noMarginBottom } from '../Edit/PartnershipTransactions.styles'

export const PartnershipAccountTotalizers = ({ totalizers = {} }) => {
  return (
    <Row customCss={[noMarginBottom]}>
      <Card customCss={[smallCard]}>
        <div css={smallContentContainer}>
          <p css={[companyNameStyle(0), alignLeft]}>Total em créditos</p>
          <p css={valueStyle(0, colors.green2)}>+{formatMoney(totalizers.totalCredit)}</p>
        </div>
      </Card>
      <Card customCss={[smallCard]}>
        <div css={smallContentContainer}>
          <p css={[companyNameStyle(0), alignLeft]}>Total em débitos</p>
          <p css={valueStyle(0, colors.red3)}>-{formatMoney(totalizers.totalDebit)}</p>
        </div>
      </Card>
    </Row>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Card, H1, PaginationContainer } from '@bonitour/components'
import { CachierHandlingFilters } from './CachierHandlingFilters'
import { CashierHandlingTable } from './CashierHandlingTable'
import { AddLabel } from 'components/AddLabel'
import { NavLink } from 'react-router-dom'
import { resetLink } from 'assets/styles/global'
import { useListCashiersHandling } from '../hooks/useListCashierHandling'
import { CashierLinks } from 'domains/Cashier/Links'
import { useListEmployees } from 'domains/Cashier/hooks/useListEmployees'

export const CashierHandlingList = () => {
  const {
    initialFilters,
    setFilters,
    loading,
    handlePagination,
    cashiersHandlings,
    meta
  } = useListCashiersHandling()
  const { filterEmployeesOptions, loading: loadingEmployees } = useListEmployees()
  const { total = 0, totalPages = 1 } = meta ?? {}

  return (
    <>
      <CashierLinks />
      <H1>Movimentações de caixa</H1>
      <Card>
        <CachierHandlingFilters
          loading={loading}
          setFilters={setFilters}
          initialFilters={initialFilters}
          employeesOptions={filterEmployeesOptions}
          loadingEmployees={loadingEmployees}
        />
        <PaginationContainer total={total} pagesQuantity={totalPages} onPagination={handlePagination}>
          <CashierHandlingTable cashiers={cashiersHandlings} loading={loading} />
        </PaginationContainer>
        <NavLink css={resetLink} to='/cashier-handling/create'>
          <AddLabel>Nova movimentação de caixa</AddLabel>
        </NavLink>
      </Card>
    </>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { Button, Card, colors, Column, DatePicker, InputFormGroup, Row, useToast } from '@bonitour/components'
import { SafeDate } from '@binamik/js-providers'
import { useCallback } from 'react'
import { useForm } from '@bonitour/app-functions'
import { date, ref } from 'yup'
import { width100 } from 'assets/styles/global'

const buttonStyle = css`
  margin-top: 1rem;
  width: 100%;
`

const cardContrast = css`
  margin-bottom: 20px;
  padding: 15px;
  background-color: ${colors.gray13};
  border: none;
`

const ReservationVisualizedTableFiltersSchema = {
  initialDate: date().max(ref('finalDate'), 'A data inicial deve ser menor ou igual a data final'),
  finalDate: date().min(ref('initialDate'), 'A data final deve ser maior ou igual a data inicial')
}

/**
 * Renders a filter for the visualized reservations table.
 * @component
 * @param {Function} onFilterChange - A function to handle the filter change.
 * @param {Object} filters - The filters to be applied.
 * @returns {JSX.Element} - The rendered ReservationVisualizedTableFilters component.
**/
export const ReservationVisualizedTableFilters = ({ onFilterChange = identity, filters }) => {
  const {
    form: { initialDate, finalDate },
    utils: { onInputChange, onInputBlur },
    onSubmit,
    errors
  } = useForm(filters, ReservationVisualizedTableFiltersSchema)

  const { add: addToast } = useToast()

  const onError = useCallback(() => {
    addToast('Houve um erro ao filtrar as reservas visualizadas')
  }, [addToast])

  const handleSubmit = onSubmit(() => onFilterChange({ initialDate, finalDate }), onError)

  return (
    <Card css={cardContrast}>
      <Row>
        <Column phone={12} desktop={6}>
          <InputFormGroup label='Data inicial' errorMessage={errors.initialDate}>
            <DatePicker
              value={initialDate && new SafeDate(initialDate).jsDate}
              onChange={onInputChange('initialDate')}
              onBlur={onInputBlur('initialDate')}
              allowsEmpty
              placeholder='__/__/____'
              css={width100}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={6}>
          <InputFormGroup label='Data final' errorMessage={errors.finalDate}>
            <DatePicker
              value={finalDate && new SafeDate(finalDate).jsDate}
              onChange={onInputChange('finalDate')}
              onBlur={onInputBlur('finalDate')}
              allowsEmpty
              placeholder='__/__/____'
              css={width100}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={3}>
          <Button onClick={handleSubmit} css={buttonStyle}>Pesquisar</Button>
        </Column>
      </Row>
    </Card>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { H2, HeaderPage, LoadingAnimation, InputWithSuffix, SearchIcon } from '@bonitour/components'
import { GroupsList } from './Groups/List'
import { SellersList } from './List/List'
import { identity } from '@bonitour/common-functions'
import { useHistory } from 'react-router-dom'
import { loadingContainer, marginBottom } from 'assets/styles/global'
import { search as searchCss, searchIcon } from './Sellers.style'
import { memo, useContext, useEffect, useMemo, useState } from 'react'
import { PaginatedSellersContext } from 'app/Sellers/SellersContext'
import { useDebounce } from '@bonitour/app-functions'

const marginBottom20 = marginBottom(20)
const partnersContainerCss = css`
  margin-top: 15px;
  padding-bottom: 64px;
`

const SearchComponent = memo(() => {
  const [search, setSearch] = useState('')
  const { handleSearch, toSearch } = useContext(PaginatedSellersContext)

  const debouncedSearch = useDebounce(search, 1500)

  const memoizedSearch = useMemo(() => toSearch, [toSearch])

  useEffect(() => {
    if (debouncedSearch === memoizedSearch) return
    handleSearch(debouncedSearch)
  }, [debouncedSearch, handleSearch, memoizedSearch])

  return <div style={{
    display: 'flex',
    justifyContent: 'end'
  }}>
    <InputWithSuffix type='text' css={searchCss}
      onChange={setSearch} value={search}
      placeholder='Buscar parceiros'>
      <SearchIcon css={searchIcon}/>
    </InputWithSuffix>
  </div>
})

const ContainerList = ({ title, children, isLoading = false, ...other }) => {
  return (
    <div {...other}>
      <H2 css={marginBottom20}>{title}</H2>
      {isLoading ? <LoadingAnimation css={loadingContainer} /> : children}
    </div>
  )
}

export const SellersPage = ({
  isLoadingGroup = false,
  groups = [],
  onClickGroupCard: emitClickGroupCard = identity
}) => {
  const { push } = useHistory()
  const backToDashboard = () => push('/dashboard')

  return (
    <>
      <HeaderPage onBack={backToDashboard} title='Vendedores' />

      <ContainerList title='Grupos' isLoading={isLoadingGroup}>
        <GroupsList groups={groups} onClick={emitClickGroupCard} />
      </ContainerList>

      <ContainerList css={partnersContainerCss} title='Parceiros'>
        <SearchComponent/>
        <SellersList/>

      </ContainerList>
    </>
  )
}

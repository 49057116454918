/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useMemo } from 'react'
import { TicketAffiliateTable } from './TicketAffiliateTable'
import { TicketOfflineTable } from './TicketOfflineTable'
import { affiliateType, offlineType } from '../constants/InvoiceBatchType'

export const TicketTable = ({
  loading,
  isBinded = false,
  tickets = [],
  handleOnSelectRow,
  selectedIds = [],
  handleToggleAll,
  isEditing = true,
  type
}) => {
  const isAllSelected = useMemo(
    () => !tickets?.some((ticket) => !selectedIds.includes(ticket.ticketId)),
    [selectedIds, tickets]
  )

  const isOffline = useMemo(() => Boolean(type === offlineType), [type])

  const isAffiliate = useMemo(() => Boolean(type === affiliateType), [type])

  if (isAffiliate) {
    return (
      <TicketAffiliateTable
        handleOnSelectRow={handleOnSelectRow}
        isBinded={isBinded}
        isEditing={isEditing}
        isLoading={loading}
        selectedIds={selectedIds}
        tickets={tickets}
        handleToggleAll={handleToggleAll}
        isAllSelected={isAllSelected}
      />
    )
  }

  if (isOffline) {
    return (
      <TicketOfflineTable
        handleOnSelectRow={handleOnSelectRow}
        isBinded={isBinded}
        isEditing={isEditing}
        isLoading={loading}
        selectedIds={selectedIds}
        tickets={tickets}
        handleToggleAll={handleToggleAll}
        isAllSelected={isAllSelected}
      />
    )
  }
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import {
  TableContainer,
  TableBody,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell,
  TableBodyRow,
  TableBodyCell,
  TruncateCell
} from '@bonitour/components'
import { clickable } from 'assets/styles/global'
import { useCallback } from 'react'

export const pendingTicketsTable = css`
  margin-top: 30px;
`

const PendingTicketTableRow = ({
  travelDate = '',
  reservationCode = '',
  traveler = '',
  serviceName = '',
  ekoLocator = '',
  reservationId
}) => {
  const redirectToReservation = useCallback(() => {
    reservationId &&
    window.open(
      `/reservation/${reservationId}`,
      '_blank',
      'noopener noreferrer'
    )
  }, [reservationId])

  return (
    <TableBodyRow css={clickable} role='button' onClick={redirectToReservation}>
      <TableBodyCell>{travelDate}</TableBodyCell>
      <TruncateCell text={reservationCode} size='115px' />
      <TruncateCell text={traveler} size='115px' />
      <TruncateCell text={serviceName} size='115px' />
      <TruncateCell text={ekoLocator} size='115px' />
    </TableBodyRow>
  )
}

export const PendingTicketsTable = ({ pendingTickets = [], loading }) => {
  return (
    <TableContainer css={pendingTicketsTable}>
      <TableHeader>
        <TableHeaderRow>
          <TableHeaderCell>Data<br />do passeio</TableHeaderCell>
          <TableHeaderCell>Código da reserva</TableHeaderCell>
          <TableHeaderCell>Nome do viajante</TableHeaderCell>
          <TableHeaderCell>Experiência</TableHeaderCell>
          <TableHeaderCell>Localizador da reserva</TableHeaderCell>
        </TableHeaderRow>
      </TableHeader>
      <TableBody loading={loading}>
        {
          pendingTickets.map((pendingTicket) => {
            return (
              <PendingTicketTableRow
                travelDate={pendingTicket.travelDate}
                reservationCode={pendingTicket.reservationCode}
                traveler={pendingTicket.traveler}
                serviceName={pendingTicket.serviceName}
                ekoLocator={pendingTicket.ekoLocator}
                reservationId={pendingTicket.reservationId}
                key={pendingTicket.reservationId + pendingTicket.id}
              />
            )
          })
        }
      </TableBody>
    </TableContainer>
  )
}

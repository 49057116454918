import moment from 'moment'
import { getImageUrl } from 'services/Orb/Service'
import { ReservationTicketParser } from './Reservation/ReservationTicket'

/* eslint-disable camelcase */
export const PaymentParser = {
  singlePayment (payment = {}) {
    const {
      id = '',
      value,
      payment_method: method,
      state,
      installments = 0,
      receipt = '',
      aut = '',
      payment_date: isoDate,
      doc = '',
      tickets = [],
      acquirer_name: acquirer,
      brand,
      custom_vendor_id: vendorId,
      destination_bank_account,
      custom_vendor_id: customVendorId,
      acquirer_id: acquirerId,
      payment_mean: paymentMean = ''
    } = payment?.advance_reservation_payment || payment?.reservation_payment || {}

    const day = moment.utc(isoDate).format('YYYY-MM-DD')
    const time = moment.utc(isoDate).format('HH:mm')

    return {
      id,
      value,
      method,
      state,
      aut,
      day,
      time,
      doc,
      tickets: (tickets || []).map(ReservationTicketParser.paymentTicketDetail),
      upload: (receipt && [{ id: receipt, src: getImageUrl(receipt) }]) || [],
      installments,
      receipt: getImageUrl(receipt),
      acquirer,
      brand,
      vendorId,
      bankAccount: destination_bank_account?.id,
      destinationBankAccountName: destination_bank_account?.bank_name,
      customVendorId,
      acquirerId,
      paymentMean
    }
  },

  singlePaymentLink (payment = {}) {
    const {
      id = '',
      value,
      state,
      max_installments: maxInstallments,
      custom_vendor_id: vendorId,
      gateway,
      payment_link_tickets = []
    } = payment.payment_link

    return {
      id,
      value,
      method: 'pay_credit',
      state,
      gateway,
      maxInstallments,
      vendorId,
      tickets: (payment_link_tickets || []).map(ReservationTicketParser.ticket)
    }
  },

  remove (reasonDelete = '') {
    return {
      reason_delete: reasonDelete
    }
  },

  singlePaymentTransfer (payment = {}) {
    const {
      id,
      liable_company_id: liableCompanyId,
      liable_user_id: liableUserId,
      part_payment_ticket_destination: partPaymentTicketDestination,
      part_payment_ticket_origin: partPaymentTicketOrigin,
      part_payment_tickets_destination_id: partPaymentTicketsDestinationId,
      part_payment_tickets_origin_id: partPaymentTicketsOriginId,
      reservation_id: reservationId,
      reservation_payment_id: reservationPaymentId,
      ticket_company_destination_id: ticketCompanyDestinationId,
      ticket_company_origin_id: ticketCompanyOriginId,
      ticket_destination_id: ticketDestinationId,
      ticket_origin_id: ticketOriginId,
      value
    } = payment

    return {
      id,
      liableCompanyId,
      liableUserId,
      partPaymentTicketDestination: PaymentParser.partPaymentTicketDestination(partPaymentTicketDestination),
      partPaymentTicketOrigin: PaymentParser.partPaymentTicketOrigin(partPaymentTicketOrigin),
      partPaymentTicketsDestinationId,
      partPaymentTicketsOriginId,
      reservationId,
      reservationPaymentId,
      ticketCompanyDestinationId,
      ticketCompanyOriginId,
      ticketDestinationId,
      ticketOriginId,
      value
    }
  },

  listPaymentTransfer ({ part_payment_ticket_transfers: payments = [] }) {
    return payments.map(PaymentParser.singlePaymentTransfer)
  },

  partPaymentTicketDestination (ticket = {}) {
    const {
      id,
      company_name: companyName,
      service_name: serviceName,
      ticket_code: ticketCode,
      ticket_id: ticketId,
      ticket_slot: ticketSlot,
      value
    } = ticket

    return {
      id,
      companyName,
      serviceName,
      ticketCode,
      ticketId,
      ticketSlot,
      value
    }
  },

  partPaymentTicketOrigin (ticket = {}) {
    const {
      id,
      company_name: companyName,
      service_name: serviceName,
      ticket_code: ticketCode,
      ticket_id: ticketId,
      ticket_slot: ticketSlot,
      value,
      amount_left: amountLeft
    } = ticket

    return {
      id,
      companyName,
      serviceName,
      ticketCode,
      ticketId,
      ticketSlot,
      value,
      amountLeft
    }
  }
}

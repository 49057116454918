import React, { createContext, useCallback, useMemo, useState } from 'react'
import { identity } from '@bonitour/common-functions'

export const BookingEditDiffContext = createContext({})

export const BookingEditDiffProvider = ({ children }) => {
  const [modalData, setModalData] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggleModalOpen = useCallback((data = {}, onEditClick = identity) => {
    setModalData({
      values: {
        ...data
      },
      onEditClick
    })
    setIsModalOpen((curr) => !curr)
  }, [])

  const values = useMemo(() => ({
    isModalOpen,
    toggleModalOpen,
    modalData
  }), [isModalOpen, modalData, toggleModalOpen])
  return <BookingEditDiffContext.Provider value={values}>
    {children}
  </BookingEditDiffContext.Provider>
}

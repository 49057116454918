/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { string as yupString } from 'yup'
import { useState, useCallback, useMemo } from 'react'
import { useForm } from '@bonitour/app-functions'
import { H2, Input, InputFormGroup } from '@bonitour/components'

import { IntegrationsFormButtons } from './IntegrationsFormButtons'
import { useReservation } from 'hooks/context/Reservation'

const loc = { eko_locator: yupString() }

export const ReservationIntegrationsApp = () => {
  const { reservation, updateEkoLocator } = useReservation()

  const [isEditing, setIsEditing] = useState(false)

  const ekoLocator = useMemo(() => {
    return reservation?.info?.extraInfos?.ekoLocator || 'dasdfd'
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservation?.info?.extraInfos?.ekoLocator])

  const baseProps = useMemo(() => ({ eko_locator: ekoLocator }), [ekoLocator])

  const {
    form: formData,
    errors,
    utils: { onInputBlur, onInputChange },
    onSubmit
  } = useForm(baseProps, loc)

  const handleSubmit = useCallback(async (data) => {
    await updateEkoLocator(data.eko_locator)
  }, [updateEkoLocator])

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <H2>Integraçoes</H2>
      <InputFormGroup
        errorMessage={errors?.eco_locator}
        label='Eco Localizador'
      >
        <Input
          disabled={!isEditing}
          value={formData?.eko_locator || ''}
          name='eko_locator'
          onChange={onInputChange('eko_locator')}
          onBlur={onInputBlur('eko_locator')}
        />
      </InputFormGroup>
      <IntegrationsFormButtons
        isEditing={isEditing}
        onCancel={() => setIsEditing(false)}
        onEdit={() => setIsEditing(true)}
        onConfirm={onSubmit(handleSubmit)}
      />
    </form>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { H1, Card, colors, flexCenteredSpaceBetweenRow, GhostButton, Button, useToast, Column, Row, LoadingAnimation, BREAK_POINTS, Dialog } from '@bonitour/components'
import { InvoiceInfo } from './InvoiceInfo'
import { InvoiceAmounts } from './InvoiceAmounts'
import { InvoiceNote } from './InvoiceNote'
import { InvoiceDetail } from './InvoiceDetail'
import { loadingContainer, marginBottom, marginRight, width100 } from 'assets/styles/global'
import { identity } from '@bonitour/common-functions'
import { useForm } from '@bonitour/app-functions'
import { invoiceInfoSchema } from 'core/schemas/invoiceInfo'
import { object } from 'yup'
import { InvoicePayments } from 'domains/Invoice/Containers/InvoicePayments'
import { useCallback, useState } from 'react'

const schema = isInvoiceSent => ({
  info: object(invoiceInfoSchema(isInvoiceSent)).required()
})

const marginBottom20 = marginBottom(20)
const marginRight20 = marginRight(20)

const buttons = css`
  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    ${width100}
    flex-direction: column;

    button:nth-child(n+2) {
      margin-top: 10px;
    }
  }
`

const container = css`
  ${marginBottom(70)};
  color: ${colors.gray3};
`

const buttonStyle = css`
  padding: 12px 30px;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    ${width100}
  }
`

const title = css`
  text-align: center;
`

const mobileHeader = css`
  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    flex-direction: column;
  }
`

const dialogCss = css`
  max-width: 1200px;
  max-height: 95vh;
  overflow-y: auto;
  color: ${colors.gray1};
  h1 {
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 10px;
  }
  b {
    font-weight: 600;
  }
`

export const InvoiceReceiveForm = ({
  invoice = {},
  invoiceDetail = [],
  amountDue = 0,
  isInvoiceSent = false,
  isInvoicePaid = false,
  isInvoiceOpen = false,
  isInvoiceDraft = false,
  isActivityLoading = false,
  canPayOffInvoice = false,
  onInvoicePaymentViewClick = identity,
  onBackClick: emitBackClickEvent = identity,
  onPaginate: emitPaginationEvent = identity,
  onSave: emitSaveEvent = identity,
  onPaymentRegisterClick: emitPaymentRegisterClick = identity,
  onSend: emitSendEvent = identity,
  onPayOffInvoice: emitPayOffInvoice = identity,
  onExportSubmit: emitExportSubmit = identity,
  exportInvoiceColumns,
  exportLoading
}) => {
  const { add: addToast } = useToast()
  const { form, errors, onSubmit, utils: { onInputBlur, onInputChange } } = useForm(invoice, schema(isInvoiceSent))
  const { info = {}, amount: invoiceAmount = {}, payments = [] } = invoice
  const { code = '' } = info

  const [isSendDialogOpen, setIsSendDialogOpen] = useState(false)
  const openSendDialog = useCallback(() => setIsSendDialogOpen(true), [])
  const closeSendDialog = useCallback(() => setIsSendDialogOpen(false), [])

  const isPaymentRegisterDisabled = !isInvoiceSent || isInvoicePaid || isInvoiceOpen

  const onValidationError = () => addToast('Preencha corretamente o formulário')

  const onSave = onSubmit(emitSaveEvent, onValidationError)

  const onSaveAndSend = onSubmit(
    (payload) => {
      closeSendDialog()
      emitSendEvent(payload)
    },
    onValidationError
  )
  const onSaveAndSendConfirmation = onSubmit(openSendDialog, onValidationError)

  const onPayOffInvoice = onSubmit(emitPayOffInvoice, onValidationError)

  return (
    <>
      <Dialog
        isVisible={isSendDialogOpen}
        onClose={closeSendDialog}
        title="Confirmar envio da fatura"
        customContainercss={dialogCss}
      >
        <p><b>Após o envio desta fatura, seus dados não poderão ser alterados futuramente!</b></p>
        <p>Confira os dados que serão enviados na fatura:</p>
        <InvoiceInfo
          invoiceInfo={info}
          invoiceInfoErrors={errors.info}
          onChange={onInputChange}
          onInputBlur={onInputBlur}
          amountDue={amountDue}
          isReadOnly
        />
        <Button css={[buttonStyle, marginRight20]} onClick={onSaveAndSend}>Enviar fatura</Button>
        <GhostButton css={[buttonStyle, marginRight20]} onClick={closeSendDialog}>Cancelar</GhostButton>
      </Dialog>
      {isActivityLoading
        ? <LoadingAnimation css={loadingContainer} />
        : (
          <>
            <div css={[flexCenteredSpaceBetweenRow, marginBottom20, mobileHeader]}>
              <H1 css={title}>Fatura {code}</H1>
              <div css={[flexCenteredSpaceBetweenRow, buttons]}>
                <GhostButton onClick={emitBackClickEvent} css={[buttonStyle, marginRight20]}>Voltar</GhostButton>
                {isInvoiceSent || <GhostButton css={[buttonStyle, marginRight20]} onClick={onSave}>Salvar informações</GhostButton>}
                {isInvoiceDraft && <Button css={[buttonStyle, marginRight20]} onClick={onSaveAndSendConfirmation}>Enviar</Button>}
                {!isInvoiceSent || <Button css={[buttonStyle, marginRight20]} onClick={onSave}>Salvar</Button>}
                {canPayOffInvoice && <Button css={[buttonStyle, marginRight20]} onClick={onPayOffInvoice}>Quitar</Button>}
                {isPaymentRegisterDisabled || <Button css={[buttonStyle]} onClick={emitPaymentRegisterClick}>Registrar pagamento</Button>}
              </div>
            </div>
            <Card css={container}>
              <InvoiceInfo
                invoiceInfo={info}
                invoiceInfoErrors={errors.info}
                isReadOnly={isInvoiceSent}
                onChange={onInputChange}
                onInputBlur={onInputBlur}
                amountDue={amountDue}
              />
              <Row>
                <Column phone={12} desktop={9}>
                  <InvoicePayments isEnabledToApprove payments={payments} onInvoicePaymentViewClick={onInvoicePaymentViewClick} />
                </Column>
                <Column phone={12} desktop={3}>
                  <InvoiceAmounts invoiceAmounts={invoiceAmount} amountDue={amountDue} receivedValueDescription={info?.invoiceValueReceivedDescription} />
                </Column>
              </Row>
              <InvoiceDetail
                invoiceDetail={invoiceDetail}
                onPaginate={emitPaginationEvent}
                onExportSubmit={emitExportSubmit}
                exportInvoiceColumns={exportInvoiceColumns}
                exportLoading={exportLoading}
              />
              <InvoiceNote details={form.details} onChange={onInputChange} />
            </Card>
          </>
        )}
    </>
  )
}

/* eslint-disable camelcase */

export const addressModel = data => {
  const {
    city: city_id,
    state: state_id,
    country: country_id,
    zipCode: post_code,
    street,
    district: region,
    supplement,
    latLong = ''
  } = data

  const [latitude = 0, longitude = 0] = latLong
    .replace(/\s/gi, '')
    .split(',')
    .map(parseFloat)

  return {
    city_id,
    state_id,
    country_id,
    post_code,
    street,
    region,
    supplement,
    latitude,
    longitude
  }
}

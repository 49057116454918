/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { TableContainer, TableBody } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { BatchTableHeader } from './BatchTableHeader'
import { BatchTableRow } from './BatchTableRow'

export const BatchesTable = ({
  batches = [],
  onBatchClick: emitBatchClick = identity,
  onBatchDelete: emitBatchDelete = identity,
  loading
}) => {
  return (
    <TableContainer>
      <BatchTableHeader />
      <TableBody loading={loading}>
        {batches.map((batch, index) =>
          <BatchTableRow
            key={index}
            batch={batch}
            onBatchClick={emitBatchClick}
            onBatchDelete={emitBatchDelete}
          />
        )}
      </TableBody>
    </TableContainer>
  )
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Card, InputFormGroup, colors, Button, Select, useToast, Row, Column, DatePicker, Input } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { useForm } from '@bonitour/app-functions'
import { string } from 'yup'
import { useEffect, useState } from 'react'
import { voucherStatusOptions } from '../constants/voucherStatus'
import { paxTypesList } from 'constants/paxTypes'
import { useValidateDateRange } from 'domains/CompanyReports/hooks/useValidadeDateRange'
import { useKeyPress } from 'hooks/useKeyPress'

const button = css`
  height: 40px;
  width: 100%;
  margin-top: 20px;
`
const cardContrast = css`
  margin-bottom: 20px;
  padding: 15px;
  background-color: ${colors.gray13};
  border: none;
`

const width150 = css`
  width: 150px;
  width: 100%;
`

const selectStyle = css`
  .component-drawer-list {
    max-width: 230px;
  }
`

const paxTypeParser = paxTypesList()
  .map(({ name }) => ({
    value: name, label: name
  }))

export const VouchersFilterSchema = {
  externalCode: string(),
  paxType: string(),
  status: string(),
  partner: string(),
  activity: string()
}

export const VouchersFilter = ({
  onFilterSubmit: emitFilterSubmit = identity,
  initialFilters = {},
  partnerOptions = [],
  activityOptions = [],
  getActivityOptions
}) => {
  const { add: addToast } = useToast()
  const { form, errors, onSubmit, utils: { onInputChange } } = useForm(initialFilters, VouchersFilterSchema)
  const { voucherStartDate, voucherEndDate, externalCode = '', paxType = '', status = '', partner = '', activity = '' } = form
  const [filterVoucherStatusOptions] = useState([{ value: '', label: 'Todos' }, ...voucherStatusOptions])
  const [filterPaxTypeOptions] = useState([{ value: '', label: 'Todos' }, ...paxTypeParser])
  const { validateDateRange } = useValidateDateRange()
  const { onKeyDown } = useKeyPress()

  useEffect(() => {
    getActivityOptions(partner)
    onInputChange('activity')('')
    // eslint-disable-next-line
  }, [partner])

  const onError = () => addToast('Preencha o formulário corretamente')

  const validateFilters = (filters) => {
    validateDateRange(voucherStartDate, voucherEndDate) && emitFilterSubmit(filters)
  }

  const onClick = onSubmit(validateFilters, onError)

  return (
    <Card css={cardContrast}>
      <Row>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Data inicial da experiência' errorMessage={errors.voucherStartDate}>
            <DatePicker
              value={voucherStartDate}
              onChange={onInputChange('voucherStartDate')}
              css={width150}
              allowsEmpty
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Data final da experiência' errorMessage={errors.voucherEndDate}>
            <DatePicker
              value={voucherEndDate}
              onChange={onInputChange('voucherEndDate')}
              css={width150}
              allowsEmpty
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Código externo' errorMessage={errors.externalCode}>
            <Input
              value={externalCode}
              error={errors.externalCode}
              onChange={onInputChange('externalCode')}
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Tipo do ingresso' errorMessage={errors.paxType}>
            <Select
              placeholder='Todos'
              options={filterPaxTypeOptions}
              value={paxType}
              error={errors.paxType}
              onChange={onInputChange('paxType')}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Parceiro' errorMessage={errors.partner}>
            <Select
              css={selectStyle}
              placeholder='Todos'
              options={partnerOptions}
              value={partner}
              error={errors.partner}
              onChange={onInputChange('partner')}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Experiência' errorMessage={errors.activity}>
            <Select
              css={selectStyle}
              placeholder='Todos'
              options={activityOptions}
              value={activity}
              error={errors.activity}
              onChange={onInputChange('activity')}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Status' errorMessage={errors.status}>
            <Select
              placeholder='Todos'
              options={filterVoucherStatusOptions}
              value={status}
              error={errors.status}
              onChange={onInputChange('status')}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <Button css={button} onClick={onClick}>Pesquisar</Button>
        </Column>
      </Row>
    </Card>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { colors, CookieConsent, scrollBar } from '@bonitour/components'
import { useCookieConsent } from './hooks/useCookieConsent'

const cookieConsentPosition = css`
  left: 60px;
  bottom: 100px;
`

const cookiesPolicyLink = css`
  display: block;
  margin-top: 20px;
  font-size: 0.75rem;
  color: ${colors.primary};
`

const messageDetail = css`
  max-height: calc(100vh - 400px);
  padding: 10px;
  overflow-y: auto;
  ${scrollBar(5)};
`

const CookieConsentDetail = () => {
  return (
    <>
      <div css={messageDetail}>
        <p>
          “Cookies” são identificadores que transferimos para o seu navegador ou dispositivo.
          As principais categorias e finalidades dos cookies utilizados pela Binamik são:
        </p>
        <h2>Cookies Primários e Cookies de Terceiros</h2>
        <p>
          Cookies primários são cookies emitidos pelo domínio do website,
          e geralmente são utilizados para identificar preferências de idioma e local ou facilitar a funcionalidade básica do site.
        </p>
        <p>
          Cookies de terceiros pertencem e são administrados por outras empresas,
          tais como parceiros de negócios da Binamik ou provedores de serviços.
          Estes cookies podem ser necessários para produzir certos formulários ou
          permitir alguma propaganda fora do website da Binamik.
        </p>
        <h2>Cookies de Sessão</h2>
        <p>
          São cookies temporários que são utilizados para lembrar de você durante o curso da sua visita ao website,
          e eles perdem a validade quando você fecha o navegador.
        </p>
        <h2>Cookies Persistentes</h2>
        <p>São utilizados para lembrar suas preferências dentro da
          plataforma. Estes cookies permanecem no seu desktop ou dispositivo
          móvel mesmo depois de você fechar o seu navegador ou efetuar uma
          reinicialização. Podemos usar estes cookies para analisar o
          comportamento do usuário e estabelecer padrões, de modo que
          possamos melhorar a funcionalidade do nosso website para você e
          outros visitantes. Estes cookies também permitem que possamos
          oferecer anúncios segmentados e medir a eficácia da nossa
          plataforma, assim como as funcionalidades de tais anúncios.
        </p>
      </div>

      <a
        css={cookiesPolicyLink}
        href='https://binamik.com.br/politica-de-cookies'
        target='_blank'
        rel='noopener noreferrer'
      >
        Leia mais sobre nossa política de cookies
      </a>
    </>
  )
}

export const CookieConsentHandler = () => {
  const { isCookieConsentVisible = false, onClickAccept, onClickReject } = useCookieConsent()

  return (
    <CookieConsent
      message='Utilizamos cookies para oferecer melhor experiência, melhorar o desempenho, analisar como você interage em nosso site e personalizar conteúdo.'
      mode='simplified'
      isVisible={isCookieConsentVisible}
      onAccept={onClickAccept}
      onReject={onClickReject}
      css={cookieConsentPosition}
    >
      <CookieConsentDetail />
    </CookieConsent>
  )
}

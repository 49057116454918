import { unformatMoney } from '@bonitour/common-functions'
import { date, string, number, object } from 'yup'

export const offlineExperienceTicketBase = {
  checkinDate: new Date(),
  checkinTime: '',
  count: 1
}

const dropoffAddressSchema = string()
const pickupAddressSchema = string()
const companyNameSchema = string()
const stateSchema = string().required('O estado é obrigatório')
const externalReservationCodeSchema = string()
const touristNameFieldSchema = string()
const documentFieldSchema = string()
const checkoutDateFieldSchema = date().required('A data de checkout é obrigatória').when('checkinDate', (checkinDate, schema) => (checkinDate && schema.min(checkinDate, 'A data de checkout precisa ser maior ou igual a de checkin')))

const timeSchema = () => string().test('test-hour', 'O horário deve ser válido', (time) => {
  if (!time) return true

  const [hours, minutes] = time.split(':')
  if (!hours || !minutes) return true

  return hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59
})

const baseSchema = (isEditingTicket = false) => ({
  serviceId: string().required(),
  ...(!isEditingTicket && {
    count: number('A quantidade deve ser um número').required('A quantidade é obrigatória').positive('A quantidade deve ser um número positivo').integer('A quantidade deve ser num número  inteiro'),
    unitPrice: string().required('O preço unitário é obrigatório'),
    unitNetPrice: string().required('O preço net unitário é obrigatório').test({
      name: 'test-unit-net-price',
      message: 'O preço net deve ser menor ou igual ao preço',
      test: function (netPrice) {
        if (!this.parent.unitPrice) return
        return unformatMoney(netPrice) <= unformatMoney(this.parent.unitPrice)
      }
    })
  }),
  checkinDate: date().required('A data é obrigatória'),
  checkinTime: timeSchema().required('O horário é obrigatório'),
  price: string().required('O preço é obrigatório').test({
    name: 'test-price',
    message: 'O preço deve ser maior ou igual ao preço net',
    test: function (price) {
      if (!this.parent.netPrice) return
      return unformatMoney(price) >= unformatMoney(this.parent.netPrice)
    }
  }),
  netPrice: string().required('O preço net é obrigatório').test({
    name: 'test-net-price',
    message: 'O preço net deve ser menor ou igual ao preço',
    test: function (netPrice) {
      if (!this.parent.price) return
      return unformatMoney(netPrice) <= unformatMoney(this.parent.price)
    }
  }),
  observation: string().max(2048, 'A observação deve ter no máximo 2048 caracteres'),
  externalReservationCode: externalReservationCodeSchema,
  reservationHeader: object({
    agent: string().optional()
  }).required(),
  affiliateCode: string().nullable(),
  offlineProviderId: string().nullable()
})

const accommodationSchema = (isEditingTicket = false) => ({
  ...baseSchema(isEditingTicket),
  accommodationType: string().required('O tipo de acomodação é obrigatório'),
  checkoutDate: checkoutDateFieldSchema,
  checkoutTime: timeSchema()
})

const activitiesSchema = (isEditingTicket = false) => ({
  ...baseSchema(isEditingTicket),
  feeType: string()
})

const airTransportSchema = (isEditingTicket = false) => ({
  ...baseSchema(isEditingTicket),
  airCompanyName: string()
})

const carRentalSchema = (isEditingTicket = false) => ({
  ...baseSchema(isEditingTicket),
  checkoutDate: checkoutDateFieldSchema,
  checkoutTime: timeSchema(),
  pickupAddress: pickupAddressSchema,
  driverAddress: string(),
  driverName: string(),
  driverLicense: string(),
  driverLicenseDueDate: string().test('testingExpirationDate', 'A data de expiração deve ser válida', expirationDate => {
    const [month, year] = expirationDate ? expirationDate.split('/') : []
    return month >= 1 && month <= 12 && year >= 1990
  }),
  state: stateSchema,
  dropoffAddress: dropoffAddressSchema,
  transporteType: string(),
  companyName: companyNameSchema,
  driverDocument: documentFieldSchema
})

const equipmentRentalSchema = (isEditingTicket = false) => ({
  ...baseSchema(isEditingTicket),
  pickupAddress: pickupAddressSchema,
  touristName: touristNameFieldSchema,
  companyName: companyNameSchema,
  state: stateSchema,
  activityName: string(),
  document: documentFieldSchema
})

const gastronomySchema = (isEditingTicket = false) => ({
  ...baseSchema(isEditingTicket)
})

const otherSchema = (isEditingTicket = false) => ({
  ...baseSchema(isEditingTicket)
})

const transportSchema = (isEditingTicket = false) => ({
  ...baseSchema(isEditingTicket),
  pickupAddress: pickupAddressSchema,
  dropoffAddress: dropoffAddressSchema,
  checkoutTime: timeSchema()
})

export const handleOfflineTicketFormSchema = ({ category = '', isEditingTicket = false }) => {
  switch (category) {
  case 'accommodation':
    return accommodationSchema(isEditingTicket)
  case 'activity':
    return activitiesSchema(isEditingTicket)
  case 'air_transport':
    return airTransportSchema(isEditingTicket)
  case 'car_rental':
    return carRentalSchema(isEditingTicket)
  case 'equipment_rental':
    return equipmentRentalSchema(isEditingTicket)
  case 'gastronomy':
    return gastronomySchema(isEditingTicket)
  case 'transport':
    return transportSchema(isEditingTicket)
  case 'others':
    return otherSchema(isEditingTicket)
  default:
    return baseSchema(isEditingTicket)
  }
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { BusIcon, Dialog, Label, MountainIcon, PlusIcon, Row, OfflineExperienceIcon, CombinedExperienceIcon, flexRowCentered } from '@bonitour/components'
import { cardButton, cardIcon, labelCardButton } from 'assets/styles/global'
import { CardButton } from 'components/CardButton'
import { AddButton } from 'components/AddButton'
import { identity } from '@bonitour/common-functions'
import { cardContainerStyle, cardOptionStyle, dialogStyle, singleLineAddButton } from './NewExperienceButton.styles'
import { useFeatureFlag } from 'contexts/Feature'

const COMBINED_EXPERIENCE_OPTION = 'combined-experience'

const modalOptions = [
  { label: 'Atividade', Icon: MountainIcon, value: 'activity' },
  { label: 'Transporte', Icon: BusIcon, value: 'transport' },
  { label: 'Experiência Offline', Icon: OfflineExperienceIcon, value: 'offline-experience' },
  { label: 'Experiência combinada', Icon: CombinedExperienceIcon, value: COMBINED_EXPERIENCE_OPTION }
]

export default function NewExperienceButton ({
  singleLine = false,
  onNavigation: emitNavigationEvent = identity
}) {
  const [isEnabledCombinedExperience] = useFeatureFlag('orb-combined-experience')
  const { push } = useHistory()

  const [newServiceDialog, setNewServiceDialog] = useState(false)
  const closeDialog = useCallback(() => setNewServiceDialog(false), [])
  const openDialog = useCallback(() => setNewServiceDialog(true), [])

  const onNewServiceClick = useCallback((serviceType = '') => () => {
    closeDialog()
    push(`/${serviceType}/create`)
    emitNavigationEvent()
  }, [push, closeDialog, emitNavigationEvent])

  const BaseButton = useCallback(({ onClick }) => singleLine
    ? (
      <AddButton onClick={onClick} css={singleLineAddButton}>
      Nova Experiência
      </AddButton>
    )
    : (
      <CardButton css={cardButton} onClick={onClick}>
        <PlusIcon css={cardIcon} />
        <Label css={labelCardButton}>Nova experiência</Label>
      </CardButton>
    ), [singleLine])

  const [filteredModalExperienceOptions, canCentralizeRow] = useMemo(() => {
    const filteredModalExperienceOptions = modalOptions
      .filter(({ value }) => !(isEnabledCombinedExperience ? [] : [COMBINED_EXPERIENCE_OPTION]).includes(value))
    const canCentralizeRow = filteredModalExperienceOptions.length < 4
    return [filteredModalExperienceOptions, canCentralizeRow]
  },
  [isEnabledCombinedExperience])

  return (
    <>
      <BaseButton onClick={openDialog} />
      <Dialog title='Selecione o tipo de experiência' isVisible={newServiceDialog} onClose={closeDialog} customCss={[dialogStyle]}>
        <Row css={[cardContainerStyle, canCentralizeRow && flexRowCentered]}>
          {filteredModalExperienceOptions.map(({ label, Icon, value }, index) => (
            <div
              key={`experience-modal-selector-option-${value}#${index}`}
              css={cardOptionStyle}
              onClick={onNewServiceClick(value)}
            >
              <Icon/>
              <Label>{label}</Label>
            </div>
          ))}
        </Row>
      </Dialog>
    </>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { H3, H4, flex } from '@bonitour/components'
import { marginTop, marginBottom, required } from 'assets/styles/global'
import { ContactPhone } from './ContactPhone/ContactPhone'
import { ContactEmail } from './ContactEmail/ContactEmail'
import { AddLabel } from 'components/AddLabel'
import { identity } from '@bonitour/common-functions'

const marginTop50 = marginTop(50)
const marginBottom20 = marginBottom(20)
const marginBottom30 = marginBottom(30)

const emptyPhone = { title: '', number: '' }
const emptyEmail = { name: '', email: '' }

export const ContactForm = ({
  phones: phoneList = [],
  emails: emailList = [],
  phonesErrors = [],
  emailsErrors = [],
  onChange: emitChangeEvent = identity,
  onBlur: emitBlurEvent = identity,
  onPhoneRemove: emitPhoneRemove = identity,
  onPhoneAdd: emitPhoneAdd = identity,
  onEmailAdd: emitEmailAdd = identity,
  onEmailRemove: emitEmailRemove = identity
}) => {
  const addPhone = () => emitPhoneAdd(emptyPhone)
  const removePhone = index => () => emitPhoneRemove(index)
  const addEmail = () => emitEmailAdd(emptyEmail)
  const removeEmail = index => () => emitEmailRemove(index)
  return (
    <>
      <H3 css={[marginTop50, marginBottom20]}>Contato</H3>
      <H4 css={marginBottom20}>
        E-mail
        <span css={required}>(Obrigatório)</span>
      </H4>
      <div css={marginBottom20}>
        {emailList.map((email, index) => (
          <ContactEmail
            key={`contact-email-${index}`}
            email={email}
            errors={emailsErrors[index]}
            index={index}
            onBlur={emitBlurEvent}
            onChange={emitChangeEvent}
            onDelete={removeEmail(index)}
          />
        ))}
      </div>
      <AddLabel css={[flex, marginBottom30]} onClick={addEmail}>
        Novo e-mail
      </AddLabel>
      <H4 css={marginBottom20}>
        Telefone
        <span css={required}>(Obrigatório)</span>
      </H4>
      <div css={marginBottom20}>
        {phoneList.map((phone, index) => (
          <ContactPhone
            key={`contact-phone-${index}`}
            contact={phone}
            errors={phonesErrors[index]}
            index={index}
            onBlur={emitBlurEvent}
            onChange={emitChangeEvent}
            onDelete={removePhone(index)}
          />
        ))}
      </div>
      <AddLabel css={[flex, marginBottom30]} onClick={addPhone}>
        Novo telefone
      </AddLabel>
    </>
  )
}

import moment from 'moment'
import { unformatMoney } from '@bonitour/common-functions'
import { formatISOTime } from 'utils/time'
import { metaModel } from './meta'

export const CashierHandlingModel = {
  list: ({ filters, pagination }) => {
    return {
      searchParams: CashierHandlingSearchParamsModel(filters),
      pagination: metaModel(pagination)
    }
  },
  create (payload) {
    const {
      date,
      value,
      comments,
      cashHandlingType,
      operatorLogin,
      operatorPassword,
      supervisorLogin,
      companyId,
      supervisorPassword
    } = payload ?? {}

    return {
      date: formatISOTime(moment(date).toISOString(), 'YYYY-MM-DD HH:mm:ss', false),
      value: unformatMoney(value),
      observation: comments,
      cash_handling_type: cashHandlingType,
      operator_login: operatorLogin,
      operator_password: operatorPassword,
      supervisor_login: supervisorLogin,
      supervisor_password: supervisorPassword,
      company_id: companyId
    }
  }
}

export const CashierHandlingSearchParamsModel = (filterParams = {}) => {
  const {
    initialDate = '',
    finalDate = '',
    cashHandlingType = '',
    operator = '',
    supervisor = ''
  } = filterParams

  return {
    initial_date: initialDate,
    end_date: finalDate,
    cash_handling_type: cashHandlingType,
    operator_id: operator,
    supervisor_id: supervisor
  }
}

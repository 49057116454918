/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useCallback, useMemo, useState } from 'react'
import { formatMoney } from '@bonitour/common-functions'
import { EyeIcon, TableBodyCell, TableBodyRow, TooltipOnHover } from '@bonitour/components'
import { formatISOTime } from 'utils/time'
import { alignCenter } from './InvoiceBatchPayment.style'
import { invoicePaymentTypeMap } from 'domains/Invoice/constants/InvoicePaymentStatus'
import { InvoiceBatchPaymentReceipts } from './InvoiceBatchPaymentReceipts'
import { clickable } from 'assets/styles/global'
import { sizeIcon } from './InvoiceBatchPaymentRow.style'

const InvoiceBatchPaymentRow = ({ payment, ...others }) => {
  const paymentStatus = useMemo(() => (payment.status === 'paid' ? 'Pago' : 'Reembolsado'), [payment.status])
  const [isReceiptsOpened, setIsReceiptsOpened] = useState(false)
  const toggleIsReceiptsOpened = useCallback(() => setIsReceiptsOpened((curr) => !curr), [])

  const tooltipMessage = useMemo(() => {
    const pluralAddon = (payment?.receipts || []).length > 1 ? 's' : ''
    return `Visualizar comprovante${pluralAddon} de pagamento.`
  }, [payment?.receipts])

  return (
    <>
      <TableBodyRow {...others}>
        <TableBodyCell css={alignCenter}>{formatISOTime(payment.transactionDate, 'DD/MM/YYYY', false)}</TableBodyCell>
        <TableBodyCell css={alignCenter}>{paymentStatus}</TableBodyCell>
        <TableBodyCell css={alignCenter}>{invoicePaymentTypeMap[payment.paymentMethod] || '-'}</TableBodyCell>
        <TableBodyCell css={alignCenter}>{formatMoney(payment.value)}</TableBodyCell>
        <TableBodyCell css={alignCenter}>
          <TooltipOnHover text={tooltipMessage}>
            <EyeIcon css={[clickable, sizeIcon]} onClick={toggleIsReceiptsOpened} />
          </TooltipOnHover>
        </TableBodyCell>
      </TableBodyRow>
      <InvoiceBatchPaymentReceipts
        isOpen={isReceiptsOpened}
        onClose={toggleIsReceiptsOpened}
        unmountedReceipts={payment?.receipts || []}
      />
    </>
  )
}

export default InvoiceBatchPaymentRow

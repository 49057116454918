/**
 * @typedef {Object} Props
 * @property {number} vX
 * @property {number} vY
 * @property {number} arcRadius
 * @property {number} arcStartingAt
 * @property {number} arcEndingAt
 */

/**
 * @type {(props:Props) => string}
 */
export const createArcPathWithArrow = (props) => {
  const { vX, vY, arcRadius, arcStartingAt, arcEndingAt } = props

  // Convert degrees to radians
  const startRadians = arcStartingAt * (Math.PI / 180)
  const endRadians = arcEndingAt * (Math.PI / 180)

  // Calculate the center of the SVG
  const centerX = vX / 2
  const centerY = vY / 2

  // Calculate start and end points
  const startX = centerX + arcRadius * Math.cos(startRadians)
  const startY = centerY + arcRadius * Math.sin(startRadians)
  const endX = centerX + arcRadius * Math.cos(endRadians)
  const endY = centerY + arcRadius * Math.sin(endRadians)

  // Determine if the arc should be drawn as a large arc
  const largeArcFlag = arcEndingAt - arcStartingAt <= 180 ? '0' : '1'

  // Determine the sweep flag; 1 for positive rotation (counterclockwise)
  const sweepFlag = '1'

  // Calculate the arrowhead
  const ARROW_DRAG = 20 // deg
  const ARROW_GRIT = 3 // pt
  const DRAG_OUTER_COMPENSATION = 4 // pt

  const arrowLeftRadians =
    endRadians - (ARROW_DRAG - DRAG_OUTER_COMPENSATION) * (Math.PI / 180)
  const arrowRightRadians = endRadians - ARROW_DRAG * (Math.PI / 180)

  const arrowLeftX =
    centerX + (arcRadius + ARROW_GRIT) * Math.cos(arrowLeftRadians)
  const arrowLeftY =
    centerY + (arcRadius + ARROW_GRIT) * Math.sin(arrowLeftRadians)

  const arrowRightX =
    centerX + (arcRadius - ARROW_GRIT) * Math.cos(arrowRightRadians)
  const arrowRightY =
    centerY + (arcRadius - ARROW_GRIT) * Math.sin(arrowRightRadians)

  // Create the d attribute for the path with arrow
  const pathData =
    `M ${startX} ${startY}` + // Move to start point
    `A ${arcRadius} ${arcRadius}, 0, ${largeArcFlag}, ${sweepFlag}, ${endX} ${endY} ` + // Draw arc
    `L ${arrowLeftX} ${arrowLeftY} M ${endX} ${endY} L ${arrowRightX} ${arrowRightY}` // Draw arrow

  return pathData
}

import { addressModel } from 'core/models/address'
import { contactsModel } from 'core/models/contact'

import { head, replaceCommaToDot } from '@bonitour/common-functions'
import { deepGetOrElse } from 'deep-getter'

export function activityModel (data, companyId) {
  const { activityId = {}, addressInfo = {}, phones = [], emails = [], sellingDeadline = 0 } = data

  const address = addressModel(addressInfo)
  const contacts = contactsModel({ emails, phones })
  const image = deepGetOrElse(activityId, 'files.0.file', null)

  const { name: title = '' } = activityId

  return {
    title,
    selling_deadline: sellingDeadline,
    company_id: companyId,
    image,
    document_type: 'cnpj',
    enabled: true,
    contacts,
    address
  }
}

export function activityUpdateModel (data) {
  const { activityId = {}, addressInfo = {}, phones = [], emails = [] } = data

  const address = addressModel(addressInfo)
  const contacts = contactsModel({ emails, phones })
  const { name: title = '' } = activityId

  const basePayload = {
    title,
    contacts,
    address
  }
  const image = (head(activityId.files.filter(({ file }) => file)) || {}).file
  const hasImage = !!activityId?.files?.length
  if (image || hasImage) {
    basePayload.image = image
  } else {
    basePayload.image = ''
  }

  return basePayload
}

export const aboutModel = (activityAbout = {}) => {
  const { duration, workDuration, category, difficultyLevel, infrastructure, generalRules } = activityAbout

  return {
    service_time: duration,
    employee_time: workDuration,
    category_id: category,
    difficulty_level: difficultyLevel,
    infrastructure,
    general_rules: generalRules
  }
}

const rulesModel = (activityRules = {}) => {
  const { minAge, minHeight, maxWeight } = activityRules.restrictions || {}
  const minHeightNormalized = replaceCommaToDot(String(minHeight))
  const maxWeightNormalized = replaceCommaToDot(String(maxWeight))

  return {
    restrictions: {
      min_age: Number(minAge),
      min_height: String(minHeightNormalized),
      max_weight: String(maxWeightNormalized)
    }
  }
}

export const complementaryInfoModel = (activityComplementaryInfo = {}) => {
  const { description, paymentsTypes, whatToTake, whatIsIncluded, observation, isAllyVisible, sellingDeadline } = activityComplementaryInfo

  return {
    description: JSON.stringify({
      'pt-br': description || ''
    }),
    observation,
    payment_types: paymentsTypes,
    what_to_take: whatToTake,
    what_is_included: whatIsIncluded,
    ally_visible: isAllyVisible,
    selling_deadline: sellingDeadline
  }
}

export function activityAboutModel (data) {
  const { activityAbout = {}, activityRules = {} } = data

  const about = aboutModel(activityAbout)
  const rules = rulesModel(activityRules)

  return {
    ...about,
    ...rules
  }
}

import { isTodayOrAfter } from 'utils/date'
import { array, date, string } from 'yup'
import { guideSchema } from './Guide.schema'

export const batchSchema = {
  reference: string()
    .required('A referência do lote é obrigatória'),
  expectedPaymentDate: date()
    .required('A data é obrigatória')
    .test('test-today', 'Não é permitido data retroativa', isTodayOrAfter),
  observation: string(),
  vouchers: array(),
  ...guideSchema
}

export const sellersGroupsModel = (data = {}) => {
  const { id = '', tradeAgreement = {}, invoicePolicy = {}, comercialPartners = [], editable = true } = data
  const { name, commissioning, sellViaAlly, netFeeEnabled, customComissions = [] } = tradeAgreement
  const {
    invoiced,
    deadline,
    deadlineType,
    closingPeriod,
    closingDay,
    paymentDay,
    invoice: {
      noShow
    }
  } = invoicePolicy

  const deadlineInfo = deadline ? { deadline, deadline_type: deadlineType } : { deadline: null }

  const invoiceInfo = { payment_guarantee: { no_show: invoiced ? noShow : false } }

  const commissioningByService = customComissions.reduce((acc, curr) => {
    const {
      service,
      commission,
      netFeeEnabled
    } = curr
    return {
      ...acc,
      [service]: {
        commission: Number(commission),
        net_fee_enabled: netFeeEnabled
      }
    }
  }, {})

  const partnershipGroupInfo = editable
    ? {
      name,
      net_fee_enabled: netFeeEnabled,
      commissioning,
      commissioning_by_service: commissioningByService,
      sell_via_ally: sellViaAlly,
      ...deadlineInfo,
      invoice_policy: {
        invoiced,
        invoice_closing_period: closingPeriod,
        invoice_closing_day: closingDay,
        payment_day: paymentDay,
        ...invoiceInfo
      }
    }
    : {}

  return {
    id,
    comercial_partner_ids: comercialPartners,
    sell_via_ally: sellViaAlly,
    ...partnershipGroupInfo
  }
}

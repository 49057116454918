import { date, number, string } from 'yup'

const today = new Date()
const tomorrow = new Date(today)
tomorrow.setDate(tomorrow.getDate() + 1)
tomorrow.setHours(0, 0, 0, 0)

export const invoiceInfoSchema = alreadySent => ({
  dueDate: alreadySent
    ? date().required('Preencha a data de vencimento')
    : date().required('Preencha a data de vencimento').min(tomorrow, 'Informe uma data de vencimento válida'),
  discountPercentage: number()
    .typeError('Deve ser um número')
    .required()
    .min(0, 'A porcentagem deve ser maior ou igual a 0')
    .max(100, 'A porcentagem deve ser menor que 100'),
  taxPercentage: number()
    .typeError('Deve ser um número')
    .required()
    .min(0, 'A porcentagem deve ser maior ou igual a 0')
    .max(100, 'A porcentagem deve ser menor que 100'),
  invoiceNumber: alreadySent
    ? string().nullable()
    : string().required('Preencha o número da nota fiscal')
})

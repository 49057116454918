/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { TableContainer, TableHeader, TableHeaderRow, TableHeaderCell, TableBody, TableBodyRow, TableBodyCell, AngleRightIcon, colors, TruncateCell, scrollBar } from '@bonitour/components'
import { formatDate, head, tail } from '@bonitour/common-functions'
import { useEffect, useState } from 'react'
import { hidden, marginRight, clickable } from 'assets/styles/global'
import { eventMap, allowedTypes } from './constants/ReservationHistoryContants'

const marginRight10 = marginRight(10)

const width100 = css`
  width: 100%;
  padding: 0;
`

const detail = css`
  border: solid ${colors.gray4};
  border-width: 1px;
  border-radius: 10px;
  padding: 20px 0 20px 20px;
  width: 100%;
`

const collapsableIcon = css`
  ${marginRight10};
  transition: 0.2s transform ease-in;
`

const collapsableIconOpen = css`
  ${marginRight10};
  transform: rotate(90deg);
`

const bold = css`
  font-weight: bold;
`

const tableContainer = css`
  overflow-x: auto;
  ${scrollBar(5)};
`

export const ReservationHistory = ({ reservationHistory = [], loading }) => {
  const [indexDetail, setIndexDetail] = useState()
  const addIndexDetail = (index) => {
    indexDetail === index ? setIndexDetail() : setIndexDetail(index)
  }
  useEffect(() => { setIndexDetail() }, [reservationHistory])

  const isExpandable = (type) => {
    return allowedTypes.includes(type)
  }

  const isExpanded = (index) => {
    return index === indexDetail
  }

  const isTicketUpdate = reservationHistory.find(reservation => reservation.action.toLowerCase().includes('ingresso'))

  const onClickDetail = (type, index) => () => isExpandable(type) || type === 'id' ? addIndexDetail(index) : null

  return (
    <div css={tableContainer}>
      <TableContainer>
        <TableHeader>
          <TableHeaderRow>
            <TableHeaderCell>Ação</TableHeaderCell>
            {isTicketUpdate && <TableHeaderCell>Código do ingresso</TableHeaderCell>}
            <TableHeaderCell>Data</TableHeaderCell>
            <TableHeaderCell>Hora</TableHeaderCell>
            <TableHeaderCell>Responsável</TableHeaderCell>
          </TableHeaderRow>
        </TableHeader>
        <TableBody loading={loading}>
          {reservationHistory.map(({ action, date, time, user, objectChanges, ticketCode, payerChanged, type }, index) => {
            const isPayerIdChange = head(Object.keys(objectChanges)) === 'payer_id'
            const initialTicketCode = objectChanges.ticket_code
            const isPaymentDeletion = action === 'Exclusão de pagamento'
            return (
              <>
                {action && (
                  <TableBodyRow key={`history-${index}`} onClick={onClickDetail(type, index)} css={(isExpandable(type) || isPaymentDeletion) && clickable}>
                    <TableBodyCell>
                      <AngleRightIcon css={[collapsableIcon, isExpanded(index) && collapsableIconOpen, (isExpandable(type) || isPaymentDeletion) || hidden]} />
                      {action}
                    </TableBodyCell>
                    {isTicketUpdate && <TableBodyCell>{ticketCode ?? initialTicketCode}</TableBodyCell>}
                    <TableBodyCell>{formatDate(date)}</TableBodyCell>
                    <TableBodyCell>{time}</TableBodyCell>
                    <TableBodyCell>{user}</TableBodyCell>
                  </TableBodyRow>
                )}
                {isExpanded(index) && !isPayerIdChange &&
                  <TableBodyCell colSpan='100%'>
                    <div css={detail} key={index}>{isPaymentDeletion ? `Motivo da exclusão: ${objectChanges.reason_delete.join('')}` : eventMap(objectChanges)}</div>
                  </TableBodyCell>}
                {isExpanded(index) && isPayerIdChange &&
                  <TableBodyCell colSpan='100%'>
                    <div css={width100} key={index}>
                      <TableContainer>
                        <TableBodyRow>
                          <TableBodyCell>
                            <span css={bold}>De</span>
                          </TableBodyCell>
                          <TableBodyCell>
                            <span css={bold}>Para</span>
                          </TableBodyCell>
                        </TableBodyRow>
                        <TableBodyRow>
                          <TruncateCell size='400px' label='Nome:' text={`${head(payerChanged)?.full_name}`} />
                          <TruncateCell size='400px' label='Nome:' text={`${tail(payerChanged)?.full_name}`} />
                        </TableBodyRow>
                        <TableBodyRow>
                          <TruncateCell size='400px' label='E-mail:' text={`${head(payerChanged)?.email}`} />
                          <TruncateCell size='400px' label='E-mail:' text={`${tail(payerChanged)?.email}`} />
                        </TableBodyRow>
                        <TableBodyRow>
                          <TableBodyCell>
                            Documento: {head(payerChanged)?.document ?? 'Não informado'}
                          </TableBodyCell>
                          <TableBodyCell>
                            Documento: {tail(payerChanged)?.document ?? 'Não informado'}
                          </TableBodyCell>
                        </TableBodyRow>
                        <TableBodyRow>
                          <TableBodyCell>
                            Telefone: {head(payerChanged)?.telephone ?? 'Não informado'}
                          </TableBodyCell>
                          <TableBodyCell>
                            Telefone: {tail(payerChanged)?.telephone ?? 'Não informado'}
                          </TableBodyCell>
                        </TableBodyRow>
                      </TableContainer>
                    </div>
                  </TableBodyCell>}
              </>
            )
          })}
        </TableBody>
      </TableContainer>
    </div>
  )
}

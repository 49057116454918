import { css } from '@emotion/core'
import { BREAK_POINTS } from '@bonitour/components'

export const dateInputCss = css`
  width: 100%;
`

export const observationInputCss = css`
  width: 100%;
  height: 100px;
`

export const buttonsContainerCss = css`
  display: flex;
  flex-direction: row;

  &.space_between {
    justify-content: space-between;
  }

  button + button {
    margin-left: 20px;
  }

  div {
    margin-right: 1.5rem;
    display: flex;
    justify-content: space-between;

    height: auto;
  }

  @media screen and (max-width: ${BREAK_POINTS.smallTablet}) {
    button + button {
      margin: 0;
    }

    &,
    div {
      flex-direction: column;
      justify-content: center;
      margin-right: 0;

      button {
        margin-top: 0.5rem;
      }
    }
  }
`

export const sendingStateCss = css`
  opacity: 0.5;

  @keyframes pulse {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 0.85;
    }
    100% {
      opacity: 0.5;
    }
  }

  animation: pulse 1s infinite;

  pointer-events: none;
`

export const dialogContainerCss = css`
  max-width: 40rem;
`

export const dialogLabelCss = css`
  font-size: 1.15rem;
  line-height: 1.40rem;
  width: 100%;
  white-space: pre-wrap;
  font-weight: normal;
  margin: 0;
`

export const dialogCardCss = css`
  margin: 1.5rem 0 1.5rem 0;
  padding: 0;
  border: transparent;
  text-align: justify;
`

/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */

import { css, jsx } from '@emotion/core'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { CashierUsersAuthForm } from './CashierUsersAuthForm'
import { CashierService } from 'core/services/Cashier'
import { Button, Card, colors, Column, Dialog, H1, H2, Row, useToast } from '@bonitour/components'
import { useCashierDetails } from '../hooks/useCashierDetails'
import { CashierOperationsTable } from './CashierOperationsTable'
import { CashierPaymentMethods } from './CashierPaymentMethods'
import { CashierLinks } from '../Links'
import { CashierStatus } from '../constants/cashier'
import { CashierValuesToCredit } from './CashierValuesToCredit'
import { cashierAlreadyClosedError, cashierAlreadyOpenedError } from 'domains/CashierHandling/constants/cashier'
import { head } from '@bonitour/common-functions'

const dialogCss = css`
  max-width: 600px;
  table {
    margin: 15px 0;
  }
`

export const CashierDetails = () => {
  const { cashierId } = useParams()
  const { add: addToast } = useToast()
  const { cashier, loading, setCashier, fetchCashier } = useCashierDetails({ cashierId })
  const [closeCashierLoading, setCloseCashierLoading] = useState(false)
  const statusMapData = useMemo(() => {
    const statusMap = {
      [CashierStatus.open]: {
        statusLabel: 'Aberto',
        statusDetails: `${cashier?.createdAt} às ${cashier?.createdAtTime}`,
        color: colors.green2,
        submitMethod: 'closeCashier',
        submitSuccessMessage: 'Caixa fechado com sucesso',
        submitErrorMessage: 'Erro ao fechar o caixa',
        submitLabel: 'Fechar caixa'
      },
      [CashierStatus.closed]: {
        statusLabel: 'Fechado',
        statusDetails: `${cashier?.closedAt} às ${cashier?.closedAtTime} por ${cashier?.supervisorName ?? ''}`,
        color: colors.red3,
        submitMethod: 'reopenCashier',
        submitSuccessMessage: 'Caixa reaberto com sucesso',
        submitErrorMessage: 'Erro ao reabrir o caixa',
        submitLabel: 'Reabrir caixa'
      }
    }

    return statusMap[cashier.status]
  }, [cashier])

  const isCashierOpen = useMemo(() => statusMapData?.submitMethod === 'closeCashier', [statusMapData])

  const [submitConfirmationForm, setSubmitConfirmationForm] = useState(null)

  const handleSubmitIntent = (form) => {
    setSubmitConfirmationForm(form)
  }

  const handleSubmit = () => {
    if (submitConfirmationForm) {
      setCloseCashierLoading(true)
      setSubmitConfirmationForm(null)
      return CashierService[statusMapData.submitMethod]({ ...submitConfirmationForm, cashierId: cashier.id })
        .then((updatedCashier) => {
          setCashier(updatedCashier)
          addToast(statusMapData.submitSuccessMessage, 'success')
        })
        .catch(({ data: { errors } }) => {
          const errorMap = {
            [cashierAlreadyClosedError]: () => {
              fetchCashier(cashierId)
              addToast('Caixa já fechado.')
            },
            [cashierAlreadyOpenedError]: () => {
              fetchCashier(cashierId)
              addToast('Caixa já aberto.')
            },
            '': () => {
              addToast(statusMapData.submitErrorMessage)
            }
          }
          const errorCallback = errorMap?.[head(errors)] ?? errorMap['']

          errorCallback()
        })
        .finally(() => {
          setCloseCashierLoading(false)
        })
    }
  }

  const {
    cashierOperations,
    paymentMethodTotalizator,
    operatorName,
    id,
    valuesToCredit
  } = cashier ?? {}

  return (
    <>
      <Dialog
        title={`Confirmar ${isCashierOpen ? 'fechamento' : 'reabertura'} de caixa`}
        isVisible={Boolean(submitConfirmationForm)}
        onClose={() => setSubmitConfirmationForm(null)}
        onConfirm={() => handleSubmit(submitConfirmationForm)}
        confirmButtonLabel="Confirmar"
        cancelButtonLabel="Cancelar"
        customContainercss={[dialogCss]}
      >
        <p>Os seguintes valores serão {isCashierOpen ? 'creditados' : 'debitados'} nas contas de parceria:</p>
        <CashierValuesToCredit loading={loading} valuesToCredit={valuesToCredit} isDebit={!isCashierOpen} hideTotal />
        <Button onClick={handleSubmit}>Confirmar</Button>
      </Dialog>
      <CashierLinks />
      <H1>
        Caixa - <span style={{ color: colors.indigo1 }}>{loading ? '...' : operatorName}</span>
      </H1>

      <Card>
        <H2>
          Status: <span style={{ color: statusMapData?.color }}>{statusMapData?.statusLabel}</span>
          {' em '}
          {statusMapData?.statusDetails}
        </H2>
        <CashierOperationsTable operations={cashierOperations} loading={loading} isCashierOpen={isCashierOpen} />
      </Card>

      {id && (
        <Row>
          <Column phone={12} desktop={6} style={{ marginBottom: 10, paddingLeft: 0 }}>
            <CashierPaymentMethods paymentMethods={paymentMethodTotalizator} valuesToCredit={valuesToCredit} loading={loading} isCashierOpen={isCashierOpen} />
          </Column>
          <Column phone={12} desktop={6} style={{ marginBottom: 10, paddingRight: 0 }}>
            <CashierUsersAuthForm onSubmit={handleSubmitIntent} submitButtonLabel={statusMapData.submitLabel} loading={closeCashierLoading} />
          </Column>
        </Row>
      )}
    </>
  )
}

import { css } from '@emotion/core'

export const horizontallyCentered = css`
  display: flex;
  align-items: center;
`

export const editIconStyle = css`
  font-size: 22px;
  cursor: pointer;
`

export const collapsableIconStyle = css`
  margin-right: 10px;
  font-size: 12px;
  cursor: pointer;
`

export const collapsableIcon = css`
  transform: rotate(-90deg);
  transition: 0.2s transform ease-in;
`

export const collapsableIconOpen = css`
  transform: rotate(0deg);
`

export const textOverflow = css`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  white-space: nowrap;
`

export const tableContents = css`
  display: contents;
`

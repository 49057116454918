/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { H1, CollapsableSection, Button, GhostButton } from '@bonitour/components'
import { ctaPadding, defaultCursor, ghostStyling, title, buttonsContainer, headerContainer } from './PartnershipInvite.style'
import { identity } from '@bonitour/common-functions'
import { InvoicePolicy } from 'containers/PartnershipGroup/InvoicePolicy/InvoicePolicy'
import { TradeAgreement } from 'containers/PartnershipGroup/TradeAgreement/TradeAgreement'

export const PartnershipInvite = ({
  inviteInformation = {},
  onAcceptance = identity,
  onRefusal = identity,
  onBackClick = identity
}) => {
  const {
    tradeAgreement = {},
    invoicePolicy = {},
    customComissions = []
  } = inviteInformation
  const isGroupEditable = true

  return (
    <>
      <div css={headerContainer}>
        <H1 css={title}>Grupo</H1>
        <div css={buttonsContainer}>
          <GhostButton onClick={onBackClick} css={ghostStyling}>Voltar</GhostButton>
          <GhostButton onClick={onRefusal} css={ghostStyling}>Recusar</GhostButton>
          <Button onClick={onAcceptance} css={ctaPadding}>Aceitar</Button>
        </div>
      </div>
      <CollapsableSection
        indicator={1}
        css={defaultCursor}
        title='Regras'
        isExpanded
        topics='Nome da categoria / Comissionamento'
      >
        <TradeAgreement
          tradeAgreement={tradeAgreement}
          isFormDisabled
          isGroupEditable={isGroupEditable}
          savedCustomComissions={customComissions}
          isLoading={!tradeAgreement.name}
        />
      </CollapsableSection>

      <CollapsableSection
        indicator={2}
        isExpanded
        css={defaultCursor}
        title='Cobrança'
        topics='Fatura / Cobrança antecipada'
      >
        <InvoicePolicy isFormDisabled invoicePolicy={invoicePolicy} />
      </CollapsableSection>
    </>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { marginBottom } from 'assets/styles/global'
import { H3, colors, flexColumn, Card, BREAK_POINTS, ErrorTooltip } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { AddLabel } from 'components/AddLabel'
import { PeriodRow } from './PeriodRow'
import { errorTooltip } from './Periods.style'

const customCardPadding = css`
  padding: 10px 20px;
  margin-bottom: 0;
  border: none;

  @media (max-width: ${BREAK_POINTS.tableUp}) {
    position: relative;
  }
`

const darkBackgroundCard = css`
  background: ${colors.gray11};
`

const marginBottom20 = marginBottom(20)
const marginBottom30 = marginBottom(30)

const emptyActivityVacancyPeriod = {
  name: '',
  startDate: new Date(),
  endDate: new Date()
}

export const Periods = ({
  label = 'Períodos',
  periodList = [],
  placeholder = '',
  errors = [],
  onChange: emitChangeEvent = identity,
  onBlur: emitBlurEvent = identity,
  onItemAdd: emitAddItemEvent = identity,
  onItemRemove: emitRemoveItemEvent = identity
}) => {
  const addDefaultVacancyPeriod = () => emitAddItemEvent(emptyActivityVacancyPeriod)
  const deleteVacancyPeriod = index => () => emitRemoveItemEvent(index)

  return (
    <>
      <H3 css={marginBottom20}>{label}</H3>
      {typeof errors === 'string' &&
        <div css={errorTooltip}>
          <ErrorTooltip>
            {errors}
          </ErrorTooltip>
        </div>
      }
      {periodList.map((vacancyPeriod, index) => (
        <Card css={[customCardPadding, index % 2 === 0 && darkBackgroundCard, flexColumn]} key={`fee-period-${index}`}>
          <PeriodRow
            index={index}
            vacancyPeriod={vacancyPeriod}
            placeholder={placeholder}
            errors={errors[index]}
            onBlur={emitBlurEvent}
            onChange={emitChangeEvent}
            onDelete={deleteVacancyPeriod(index)}
          />
        </Card>
      ))}
      <AddLabel css={marginBottom30} onClick={addDefaultVacancyPeriod}>
        Novo período
      </AddLabel>
    </>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useCallback, useEffect, useMemo } from 'react'
import { jsx } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import {
  AccommodationForm,
  ActivitiesForm,
  AirTransportForm,
  CarRentalForm,
  EquipmentRentalForm,
  GastronomyForm,
  OtherForm,
  TransportForm
} from './Forms'
import { stateOptions } from 'constants/offlineTicket'
import { OfflineExperienceService } from 'services/OfflineExperience/Service'
import { ticketStatusDictionary } from 'constants/ticketStatus'
import { fillReservationFinancialForm } from 'containers/Booking/CreationForm/BookingForm'

export const OfflineTicketForm = ({
  onInputChange = identity,
  onInputBlur = identity,
  form = {},
  errors = {},
  category = '',
  ticketState = '',
  isEditing = false,
  disabled = false,
  isOpenConfirmationModal = false
}) => {
  const isTotalPriceDisabled = useMemo(() => {
    if (!ticketState) return false

    const isTicketReserved = ticketState === ticketStatusDictionary.reserved.value

    return !isTicketReserved
  }, [ticketState])

  const updatePrices = useCallback(() => {
    if (!isEditing && form?.serviceId && !isOpenConfirmationModal) {
      OfflineExperienceService.get(form.serviceId).then((service) => {
        const { defaultValue, defaultNetValue, offlineProviderId } = service?.offlineExperienceBase?.serviceId || {}
        onInputChange('unitPrice')(defaultValue)
        onInputChange('unitNetPrice')(defaultNetValue)
        onInputChange('price')(defaultValue)
        onInputChange('netPrice')(defaultNetValue)
        onInputChange('offlineProviderId')(offlineProviderId)
      })
    }
  }, [form.serviceId, isEditing, isOpenConfirmationModal, onInputChange])

  const updateFinancialData = useCallback((form, onChange) => fillReservationFinancialForm(form, onChange), [])

  const onInputChangeMiddleware = useCallback((field) => {
    const onFinancialDataChange = updateFinancialData(form, onInputChange)
    switch (field) {
    case 'count':
      return (count) => onFinancialDataChange({ count })
    case 'unitNetPrice':
      return (unitNetPrice) => onFinancialDataChange({ unitNetPrice })
    case 'unitPrice':
      return (unitPrice) => onFinancialDataChange({ unitPrice })
    default:
      return onInputChange(field)
    }
  }, [form, onInputChange, updateFinancialData])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updatePrices, [form.serviceId, isEditing])

  const currentForm = useMemo(() => {
    switch (category) {
    case 'accommodation':
      return (
        <AccommodationForm
          isEditing={isEditing}
          form={form}
          errors={errors}
          onInputChange={onInputChangeMiddleware}
          onInputBlur={onInputBlur}
          disableTotalPrice={isTotalPriceDisabled}
          disabled={disabled}
        />
      )
    case 'air_transport':
      return (
        <AirTransportForm
          isEditing={isEditing}
          form={form}
          errors={errors}
          onInputChange={onInputChangeMiddleware}
          onInputBlur={onInputBlur}
          disableTotalPrice={isTotalPriceDisabled}
          disabled={disabled}
        />
      )
    case 'car_rental':
      return (
        <CarRentalForm
          isEditing={isEditing}
          form={form}
          errors={errors}
          onInputChange={onInputChangeMiddleware}
          onInputBlur={onInputBlur}
          stateOptions={stateOptions}
          disableTotalPrice={isTotalPriceDisabled}
          disabled={disabled}
        />
      )
    case 'activity':
      return (
        <ActivitiesForm
          isEditing={isEditing}
          form={form}
          errors={errors}
          onInputChange={onInputChangeMiddleware}
          onInputBlur={onInputBlur}
          disableTotalPrice={isTotalPriceDisabled}
          disabled={disabled}
        />
      )
    case 'transport':
      return (
        <TransportForm
          isEditing={isEditing}
          form={form}
          errors={errors}
          onInputChange={onInputChangeMiddleware}
          onInputBlur={onInputBlur}
          disableTotalPrice={isTotalPriceDisabled}
          disabled={disabled}
        />
      )
    case 'gastronomy':
      return (
        <GastronomyForm
          isEditing={isEditing}
          form={form}
          errors={errors}
          onInputChange={onInputChangeMiddleware}
          onInputBlur={onInputBlur}
          disableTotalPrice={isTotalPriceDisabled}
          disabled={disabled}
        />
      )
    case 'equipment_rental':
      return (
        <EquipmentRentalForm
          isEditing={isEditing}
          form={form}
          errors={errors}
          onInputChange={onInputChangeMiddleware}
          onInputBlur={onInputBlur}
          stateOptions={stateOptions}
          disableTotalPrice={isTotalPriceDisabled}
          disabled={disabled}
        />
      )
    case 'others':
      return (
        <OtherForm
          isEditing={isEditing}
          form={form}
          errors={errors}
          onInputChange={onInputChangeMiddleware}
          onInputBlur={onInputBlur}
          disableTotalPrice={isTotalPriceDisabled}
          disabled={disabled}
        />
      )
    default:
      return null
    }
  }, [category, isEditing, form, errors, onInputChangeMiddleware, onInputBlur, isTotalPriceDisabled, disabled])

  return currentForm
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Card, InputFormGroup, colors, Button, Select, useToast, Row, Column, DatePicker } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { useForm } from '@bonitour/app-functions'
import { string } from 'yup'
import { useState } from 'react'
import { useValidateDateRange } from 'domains/CompanyReports/hooks/useValidadeDateRange'
import { invoiceStatusOptions } from 'constants/invoiceStatus'
import moment from 'moment'
import { useKeyPress } from 'hooks/useKeyPress'

const button = css`
  height: 40px;
  width: 100%;
  margin-top: 20px;
`
const cardContrast = css`
  margin-bottom: 20px;
  padding: 15px;
  background-color: ${colors.gray13};
  border: none;
`

const width150 = css`
  width: 150px;
  width: 100%;
`

const label100 = css`
  label {
    width: 100%;
  }
`

export const InvoiceFiltersSchema = {
  status: string(),
  partner: string()
}

const filterZeroedInvoicesOptions = [
  { value: false, label: 'Mostrar' },
  { value: true, label: 'Ocultar' }
]

export const InvoiceFilters = ({
  onFilterSubmit: emitFilterSubmit = identity,
  initialFilters = {},
  partnerOptions = []
}) => {
  const { add: addToast } = useToast()
  const { form, errors, onSubmit, utils: { onInputChange } } = useForm(initialFilters, InvoiceFiltersSchema)
  const { invoiceStartDate = '', invoiceEndDate = '', invoiceCompetenceStartDate = '', invoiceCompetenceEndDate = '', status = '', partner = '', ignoreZeroedInvoiceValues = false } = form
  const [filterVoucherStatusOptions] = useState([{ value: '', label: 'Todos' }, ...invoiceStatusOptions])
  const { validateDateRange } = useValidateDateRange()
  const { onKeyDown } = useKeyPress()

  const onError = () => addToast('Preencha o formulário corretamente')

  const validateFilters = (filters) => {
    validateDateRange(invoiceStartDate, invoiceEndDate) && emitFilterSubmit(filters)
    validateDateRange(invoiceCompetenceStartDate, invoiceCompetenceEndDate) && emitFilterSubmit(filters)
  }

  const onClick = onSubmit(validateFilters, onError)

  return (
    <Card css={cardContrast}>
      <Row>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Data inicial do vencimento' errorMessage={errors.invoiceStartDate}>
            <DatePicker
              value={invoiceStartDate && moment(invoiceStartDate)}
              onChange={onInputChange('invoiceStartDate')}
              css={width150}
              allowsEmpty
              onKeyDown={event => onKeyDown(event, onClick)}
              placeholder='__/__/____'
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Data final do vencimento' errorMessage={errors.invoiceEndDate}>
            <DatePicker
              value={invoiceEndDate && moment(invoiceEndDate)}
              onChange={onInputChange('invoiceEndDate')}
              css={width150}
              allowsEmpty
              onKeyDown={event => onKeyDown(event, onClick)}
              placeholder='__/__/____'
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Parceiro' errorMessage={errors.partner}>
            <Select
              placeholder='Todos'
              options={partnerOptions}
              value={partner}
              error={errors.partner}
              onChange={onInputChange('partner')}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Status' errorMessage={errors.status}>
            <Select
              placeholder='Todos'
              options={filterVoucherStatusOptions}
              value={status}
              error={errors.status}
              onChange={onInputChange('status')}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup css={label100} label='Data inicial do período de competência' errorMessage={errors.invoiceCompetenceStartDate}>
            <DatePicker
              value={invoiceCompetenceStartDate && moment(invoiceCompetenceStartDate)}
              onChange={onInputChange('invoiceCompetenceStartDate')}
              css={width150}
              allowsEmpty
              onKeyDown={event => onKeyDown(event, onClick)}
              placeholder='__/__/____'
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Data final do período de competência' errorMessage={errors.invoiceCompetenceEndDate}>
            <DatePicker
              value={invoiceCompetenceEndDate && moment(invoiceCompetenceEndDate)}
              onChange={onInputChange('invoiceCompetenceEndDate')}
              css={width150}
              allowsEmpty
              onKeyDown={event => onKeyDown(event, onClick)}
              placeholder='__/__/____'
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Faturas zeradas' errorMessage={errors.status}>
            <Select
              placeholder='Mostrar'
              options={filterZeroedInvoicesOptions}
              value={ignoreZeroedInvoiceValues}
              error={errors.ignoreZeroedInvoiceValues}
              onChange={onInputChange('ignoreZeroedInvoiceValues')}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <Button css={button} onClick={onClick}>Pesquisar</Button>
        </Column>
      </Row>
    </Card>
  )
}

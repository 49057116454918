/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { AvatarCard, BREAK_POINTS, Button, colors, flexColumn, Swatch } from '@bonitour/components'
import { useHistory } from 'react-router-dom'

const card = css`
  width: 100%;
  min-width: 180px;
  margin: 10px 5px;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    max-width: 100%;
  }
`

const swatchInline = css`
  display: inline-block;
  margin-right: 10px;
`

const marginTop10 = css`
  margin-top: 10px;
`

const ActiveComponent = ({ groupId = '' }) => {
  const redirectToGroup = () => push(`/group/${groupId}/view`)
  const { push } = useHistory()
  return (
    <div css={flexColumn}>
      <div>
        <Swatch size={10} color={colors.green3} css={swatchInline} />
        Ativo
      </div>
      <Button css={marginTop10} onClick={redirectToGroup}>
        Visualizar Grupo
      </Button>
    </div>
  )
}

export const ProviderCard = ({ group = {}, children, name, image, active }) => {
  const { name: groupName, id = '' } = group
  return (
    <AvatarCard css={card} image={image} title={name} description={groupName}>
      {active && <ActiveComponent groupId={id} />}
      {children}
    </AvatarCard>
  )
}

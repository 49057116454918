import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

export const useBack = () => {
  const history = useHistory()

  const goTo = useCallback((pathName = '') => history.push(pathName), [history])

  const onSafeBack = useCallback((pathName = '') => {
    if (history.length >= 3) {
      return history.goBack()
    }
    goTo(pathName)
  }, [goTo, history])

  return {
    goTo,
    onSafeBack
  }
}

/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/core'

import { clickable, margin } from 'assets/styles/global'
import VacancyTable from './Table/VacancyTable'
import { useActivity } from 'contexts/Activity'
import { identity, tail, head } from '@bonitour/common-functions'
import { BookingMapButtons } from './Structure/BookingMapButtons'
import {
  colors,
  flexCenteredSpaceBetweenRow,
  flexHorizontallyCentered,
  flexRowCentered,
  GhostButton,
  H1,
  LinkIcon,
  Modal,
  Dialog,
  Label,
  CircleThumbnail,
  InfoIcon
} from '@bonitour/components'
import { useOffsetHook } from './hooks/useOffsetHook'
import { useBookingMapDateTime } from './hooks/useBookingMapDateTime'
import { useDateColumns } from './hooks/useDateColumns'
import { useMapSubscriber } from './hooks/useMapSubscriber'
import { useMapDetailSubscriber } from './hooks/useMapDetailSubscriber'
import { useCallback, useContext, useMemo, useState } from 'react'
import { BookingEditDiffProvider, BookingEditDiffContext } from './BookingEditionDiff/BookingEditionDiffContext'
import BookingEditionDiffDialog from './BookingEditionDiff/BookingEditionDiffDialog'
import { formatISOTime } from 'utils/time'
import { StatusEnum } from 'constants/reservationsStatus'

const noMargin = margin(0, 0, 0, 0)

const title = css`
  align-items: flex-end;
  margin-top: -50px;
`

const vacancyTableContainer = css`
  padding: 30px;
  margin: 20px 0;
  position: relative;
  border-radius: 10px;
  border: 1px solid ${colors.gray11};
  background-color: ${colors.white};
`

const popoverItem = css`
  color: ${colors.gray4};
  padding: 15px 20px;
  font-size: 16px;
  border-radius: 10px;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  text-align: left;

  &:hover {
    background-color: ${colors.gray13};
  }
`

const iconStyle = css`
  margin-right: 10px;
  font-size: 23px;
`

const rowWithBreakpoint = css`
  display: inline;
  max-inline-size: 400px;
  white-space: pre-line;
  text-align: justify;
`

const icon = css`
  display: inline-flex;
  cursor: pointer;
  margin-left: 5px;
  color: ${colors.gray2};
  border: 1px solid ${colors.gray2};
  border-radius: var(--round-element-radius, 100%);
  transform: rotateX(180deg);

  &:hover {
    color: ${colors.white};
    border-color: ${colors.primary};
    background-color: ${colors.primary};
  }
`

const labelStyle = css`
  margin: 0;
  white-space: break-spaces;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.1rem;
`

const containerDialogStyle = css`
  text-align: justify;

  .label-container {
    margin-top: 5px;
  }
`

const BookinErrorDialog = ({ message, title, isVisible = false, onClose = identity }) => {
  return <Dialog isVisible={isVisible} onClose={onClose} title={title} customContainercss={containerDialogStyle}>
    <div className='label-container'>
      <Label css={labelStyle}>
        {message}
      </Label>
    </div>
  </Dialog>
}

const BookinError = ({ message, title, isVisible, toggleDialog = identity }) => {
  return (
    <>
      <div css={[flexHorizontallyCentered, popoverItem, rowWithBreakpoint]}>
        {title}
        <CircleThumbnail size={20} css={icon} onClick={toggleDialog}>
          <InfoIcon/>
        </CircleThumbnail>
      </div>
      <BookinErrorDialog
        title={title}
        message={message}
        isVisible={isVisible}
        onClose={toggleDialog}
      />
    </>
  )
}

export const BookinEditGuide = (onEditClick = identity, minTickets = 0, selectedTickets = []) => function BookinEditGuideComponent ({ hour = '', date = '', totalVacancies = 0, ticketTariff }) {
  const {
    toggleModalOpen
  } = useContext(BookingEditDiffContext)

  const getTicketPriceByNewTariff = useCallback((ticketType) => {
    const ticket = ticketTariff.find(({ name }) => name === ticketType)
    if (!ticket) return ticket
    return Number(ticket.pricing)
  }, [ticketTariff])

  const [ticketList, hasDivergenceByPax, hasDivergenceBySmallValue] = useMemo(() => {
    const newTariffNames = ticketTariff.map(({ name }) => name)

    let divergenceCountByPax = 0
    let divergenceCountBySmallValue = 0

    const ticketList = selectedTickets.map(({ price, type, ticketCode: code, state, ticketDates }) => {
      const oldDate = ticketDates?.startDate

      const oldHour = ticketDates?.startHour

      const parsedNewDate = formatISOTime(date, 'DD/MM/YYYY')

      const hasTypeNewTariff = newTariffNames.includes(type)

      if (!hasTypeNewTariff) {
        divergenceCountByPax += 1
      }

      const oldValue = Number(price)

      const newValue = hasTypeNewTariff ? getTicketPriceByNewTariff(type) : 0

      if (newValue < oldValue && state === StatusEnum.confirmed) {
        divergenceCountBySmallValue += 1
      }

      const diffValue = (newValue > oldValue) ? newValue - oldValue : oldValue - newValue

      return {
        type,
        code,
        oldValue,
        newValue,
        oldDate,
        newDate: parsedNewDate,
        oldHour,
        newHour: hour,
        diffValue,
        isEqual: newValue === oldValue
      }
    })

    return [ticketList, Boolean(divergenceCountByPax), Boolean(divergenceCountBySmallValue)]
  }, [date, getTicketPriceByNewTariff, hour, ticketTariff])

  const modalData = useMemo(() => {
    const [oldTotalPrice, newTotalPrice] = ticketList.reduce((acc, { oldValue, newValue }) => {
      return [acc[0] + Number(oldValue), acc[1] + Number(newValue)]
    }, [0, 0])

    return {
      ticketList,
      priceProperties: {
        newPrice: newTotalPrice,
        oldPrice: oldTotalPrice,
        isEqual: oldTotalPrice === newTotalPrice
      }
    }
  }, [ticketList])

  const toggleModal = useCallback(() => toggleModalOpen(modalData, () => onEditClick(hour, date, true)), [date, hour, modalData, toggleModalOpen])

  const [isOpenDialogError, setIsOpenDialogError] = useState(false)
  const toggleDialogError = useCallback(() => setIsOpenDialogError(curr => !curr), [])

  if (minTickets > totalVacancies) {
    return (
      <div css={[flexHorizontallyCentered, popoverItem]}>
        Sem vagas disponíveis
      </div>
    )
  }

  if (hasDivergenceByPax) {
    return (
      <BookinError
        title={'Divergência por tipificação'}
        message={'Nessa data há tipificação diferente em relação a data anterior,  para esse caso crie um ingresso na nova data e cancele na data anterior.'}
        isVisible={isOpenDialogError}
        onClose={toggleDialogError}
        toggleDialog={toggleDialogError}
      />
    )
  }

  if (hasDivergenceBySmallValue) {
    return (
      <BookinError
        title={'Alteração indisponível'}
        message={'Não é possível alterar de data após o ticket estar pago, necessário cancelar e criar um na nova data.'}
        isVisible={isOpenDialogError}
        onClose={toggleDialogError}
        toggleDialog={toggleDialogError}
      />
    )
  }

  return (
    <>
      <div css={[flexHorizontallyCentered, popoverItem, clickable]} onClick={toggleModal}>
        <LinkIcon css={iconStyle} />
        Editar
      </div>
    </>
  )
}

export const BookingEditionMapModalPage = ({
  date = '',
  isVisible = false,
  onModalClose = identity,
  serviceId = '',
  ticketsIds = [],
  reservationCode = '',
  onEditionSubmitted = identity,
  reservationTickets = [],
  selectedTicketsIds = []
}) => {
  const { getActivityById } = useActivity()
  const activity = useMemo(() => (isVisible && getActivityById(serviceId)) || {}, [isVisible, getActivityById, serviceId])
  const ticketCount = useMemo(() => ticketsIds.length, [ticketsIds])

  const [tableReference, offset] = useOffsetHook(isVisible)
  const { selectedHour, referenceDate, changeHour, changeToPrevious, changeToNext } = useBookingMapDateTime(offset, date || '')
  const dateColumns = useDateColumns(referenceDate, offset)
  const { registries, prices } = useMapSubscriber(activity, dateColumns)
  const tableDetailedData = useMapDetailSubscriber(activity, dateColumns, selectedHour)

  const selectedTickets = useMemo(() => {
    const currentReservation = tail(reservationTickets.find((data) => {
      const ticketData = head(tail(data))
      const filteredTicket = ticketData?.tickets?.find(({ id }) => ticketsIds.includes(id))
      return Boolean(filteredTicket)
    }))?.[0]

    return currentReservation?.tickets?.filter(({ id }) => selectedTicketsIds.includes(id))
  }, [reservationTickets, selectedTicketsIds, ticketsIds])

  const GuideComponent = useMemo(() => BookinEditGuide(onEditionSubmitted, ticketCount, selectedTickets, reservationTickets), [onEditionSubmitted, reservationTickets, selectedTickets, ticketCount])

  const {
    isModalOpen,
    modalData,
    toggleModalOpen
  } = useContext(BookingEditDiffContext)

  return (
    <Modal isVisible={isVisible} onCloseClick={onModalClose}>
      <div css={[flexCenteredSpaceBetweenRow]}>
        <div css={[flexRowCentered, title]}>
          <H1 css={noMargin}>Editar {ticketCount} ingresso(s) em {activity.name} na reserva {reservationCode}</H1>
        </div>
      </div>

      <div css={vacancyTableContainer} ref={tableReference}>
        <BookingMapButtons
          onClickPrevious={changeToPrevious}
          onNextClick={changeToNext}
          offset={offset}
        />
        <VacancyTable
          isExpandable={false}
          date={referenceDate}
          dateColumns={dateColumns}
          tableData={registries}
          tablePrices={prices}
          tableDetailedData={tableDetailedData}
          currentExpandedRowLabel={selectedHour}
          VacancySummaryPopoverContent={GuideComponent}
          onExpand={changeHour}
        />
        <BookingEditionDiffDialog
          modalData={modalData}
          isVisible={isModalOpen}
          onClose={() => toggleModalOpen({})}
        />
      </div>
      <GhostButton onClick={onModalClose}>
        Cancelar
      </GhostButton>
    </Modal>
  )
}

export const BookingEditionMapModal = (props) => {
  return <BookingEditDiffProvider>
    <BookingEditionMapModalPage {...props} />
  </BookingEditDiffProvider>
}

export const dailyOptions = [
  {
    label: 'Dia 1',
    value: '1'
  }
]

export const weekDaysOptions = [
  {
    label: 'Domingo',
    value: 'sunday'
  },
  {
    label: 'Segunda',
    value: 'monday'
  },
  {
    label: 'Terça',
    value: 'tuesday'
  },
  {
    label: 'Quarta',
    value: 'wednesday'
  },
  {
    label: 'Quinta',
    value: 'thursday'
  },
  {
    label: 'Sexta',
    value: 'friday'
  },
  {
    label: 'Sábado',
    value: 'saturday'
  }
]

export const tenlyOptions = [
  {
    label: 'Dia 5',
    value: '5'
  },
  {
    label: 'Dia 10',
    value: '10'
  }
]

export const biWeekDaysOptions = [
  {
    label: 'Dia 1',
    value: '1'
  },
  {
    label: 'Dia 15',
    value: '15'
  },
  {
    label: 'Dia 30',
    value: '30'
  }
]

export const monthDaysOptions = [
  {
    label: 'Dia 1',
    value: '1'
  },
  {
    label: 'Dia 5',
    value: '5'
  },
  {
    label: 'Dia 10',
    value: '10'
  },
  {
    label: 'Dia 15',
    value: '15'
  },
  {
    label: 'Dia 20',
    value: '20'
  },
  {
    label: 'Dia 30',
    value: '30'
  }
]

export const mapDaysOptions = {
  daily: dailyOptions,
  weekly: weekDaysOptions,
  tenly: tenlyOptions,
  biweekly: biWeekDaysOptions,
  monthly: monthDaysOptions
}

export const workingDaysOptions = [
  {
    label: '5',
    value: '5'
  },
  {
    label: '7',
    value: '7'
  },
  {
    label: '15',
    value: '15'
  }
]

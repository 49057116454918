/* eslint-disable camelcase */
import { formatISOTime } from 'utils/time'
import { MetaParser } from './Meta'
import { getImageUrl } from 'services/Orb/Service'
import { paymentsTypes } from 'domains/BankAccounts/constants/bankAccountConstants'
const { REACT_APP_API_HOST } = process.env

export const PartnershipAccountParser = {
  partnershipAccount: (partnershipAccount) => {
    const {
      id,
      debtor_id: partnerId,
      debtor_name: partnerName,
      debtor_image: partnerImage,
      creditor_id: creditorId,
      creditor_name: creditorName,
      creditor_image: creditorImage,
      balance,
      updated_at: lastModification
    } = partnershipAccount

    return {
      id,
      partnerId,
      partnerName,
      partnerImage: `${REACT_APP_API_HOST}${partnerImage}`,
      creditorId,
      creditorName,
      creditorImage: `${REACT_APP_API_HOST}${creditorImage}`,
      balance,
      lastModification: lastModification ? formatISOTime(lastModification, 'DD/MM/YYYY HH:mm') : ''
    }
  },
  single: ({ partnership_account: partnershipAccount }) => PartnershipAccountParser.partnershipAccount(partnershipAccount),
  transaction: transaction => {
    const {
      id,
      value,
      date: transactionDate,
      transaction_type: transactionType,
      description: observations,
      liable_user_name: userName,
      polymorphic_info: polymorphicInfo
    } = transaction

    const extraData = {
      invoiceCode: polymorphicInfo?.invoice_code,
      installments: polymorphicInfo?.installments,
      brand: polymorphicInfo?.brand,
      doc: polymorphicInfo?.doc,
      aut: polymorphicInfo?.AUT || polymorphicInfo?.aut,
      payment_type: polymorphicInfo?.payment_type,
      reservation_payment_date: polymorphicInfo?.reservation_payment_date ? formatISOTime(polymorphicInfo.reservation_payment_date, 'DD/MM/YYYY', false) : undefined,
      invoice_payment_date: polymorphicInfo?.invoice_payment_date ? formatISOTime(polymorphicInfo.invoice_payment_date, 'DD/MM/YYYY', false) : undefined
    }

    return {
      id,
      value,
      transactionDate: formatISOTime(transactionDate, 'DD/MM/YYYY'),
      transactionType,
      observations,
      extraData,
      userName
    }
  },
  list: ({ meta, partnership_accounts: partnershipAccounts = [] }) => {
    return {
      partnershipAccounts: partnershipAccounts.map(PartnershipAccountParser.partnershipAccount),
      meta: MetaParser.meta(meta)
    }
  },
  transactions: ({ meta, partnership_account_transactions: transactions = [] }) => {
    return {
      transactions: transactions.map(PartnershipAccountParser.transaction),
      meta: MetaParser.meta(meta)
    }
  },
  getTransaction: ({ partnership_account_transaction: transaction }) => {
    const {
      id: transactionId,
      payment_type: paymentType,
      transaction_type: type,
      value,
      destination_bank_account: destinationBankAccount,
      origin_bank_account: originBankAccount,
      description,
      attachments
    } = transaction

    const { id: destinationBankName } = destinationBankAccount || {}
    const { id: originBankName } = originBankAccount || {}

    return {
      transactionId,
      paymentType,
      type,
      value,
      destinationBankAccount: destinationBankName,
      originBankAccount: originBankName,
      description,
      upload: (attachments && attachments.map(({ signed_id, url }) => ({
        id: signed_id,
        src: getImageUrl(url)
      }))) || []
    }
  },
  totalizers: ({ total_debit: totalDebit, total_credit: totalCredit }) => {
    return {
      totalDebit,
      totalCredit
    }
  },
  getPaymentsType: (payments_type) => {
    const collected_at = new Date()
    const items = payments_type.map((paymentType) => {
      const Item = paymentsTypes.find((Item) => Item.value === paymentType)
      return Item || null
    })

    return {
      collected_at,
      items
    }
  }
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useCallback, useEffect, useState, useMemo } from 'react'
import { Card, Input, DatePicker, MaskedInputWithPrefix, H4, InputFormGroup, Email, Row, Column, Select, MaskedInputWithSuffix, GhostButton, useToast, RadioInputGroup, RadioGroup, flexColumn, LoadingAnimation, PhoneSelector, InputHeightMask, InputWeightMask, InputZipCodeMask } from '@bonitour/components'
import { marginBottom15, cardContainer, inputGroup, fullWidth, documentLabel, inputButton, label, reservationPassengerContainer, marginVertical20, addPayerLabel, emailSearchStyle } from './ReservationType.style'
import { identity, maskCpf, head } from '@bonitour/common-functions'
import { useForm } from '@bonitour/app-functions'
import { string } from 'yup'
import { loadingContainer } from 'assets/styles/global'
import { useKeyPress } from 'hooks/useKeyPress'
import { PassengerInfo } from 'domains/Reservation/Passenger/PassengerInfo'
import { inputStyle } from 'containers/ActivityFeeForm/FeePaxType/FeePaxTypeRow.style'

const passengerSearchSchema = { emailSearch: string().email('O e-mail deve ser válido') }

export const ReservationType = ({
  reservationType = {},
  reservationTypeErrors: errors = {},
  countries,
  states,
  cities,
  updateAvailableStates,
  updateAvailableCities,
  onZipCodeBlur = identity,
  setCountrySelected,
  setStateSelected,
  passengersFound = [],
  searchPassengerLoading,
  setReservationTypeValidation,
  onChange: emitChangeEvent = identity,
  onBlur: emitBlurEvent = identity,
  onSearchPassenger: emitSearchPassengerSubmit = identity,
  initialEmail = '',
  disabled = false
}) => {
  const { add: addToast } = useToast()
  const { onKeyDown } = useKeyPress()
  const [isFormVisible, setFormVisibility] = useState(false)
  const [isSearchClicked, setSearchClick] = useState(false)
  const [passengerSelected, setPassengerSelected] = useState('')
  const {
    name = '',
    email = '',
    birthDate,
    identificationDocument = '',
    number = '',
    height = '',
    weight = '',
    emergencyName = '',
    emergencyTelephone = '',
    country,
    state,
    city,
    zipCode = '',
    street = '',
    supplement = '',
    district = ''
  } = reservationType
  const passengerSearchInitial = useMemo(() => ({ emailSearch: initialEmail }), [initialEmail])
  const { form, errors: passengerSearchErrors, onSubmit, utils: { onInputBlur, onInputChange } } = useForm(passengerSearchInitial, passengerSearchSchema)
  const { emailSearch = '' } = form

  const passengersFoundLength = passengersFound.length
  const isPassengersFound = passengersFoundLength > 0
  const isPassengerSelected = Boolean(passengerSelected)

  useEffect(() => {
    if (isSearchClicked && !searchPassengerLoading && !isPassengersFound) {
      emitChangeEvent('reservationType.email')(emailSearch)
      setFormVisibility(true)
    } else {
      emitChangeEvent('reservationType.email')('')
      setFormVisibility(false)
    }
    // eslint-disable-next-line
  }, [isSearchClicked, searchPassengerLoading, isPassengersFound])

  const onCountryChange = useCallback((countryValue) => {
    const country = countries.find((country) => Object.values(country).includes(countryValue))
    if (country) {
      setCountrySelected(country.value)
      updateAvailableStates(country.value)
      emitChangeEvent('reservationType.country')(country.value)
    } else {
      setCountrySelected('')
      updateAvailableStates(null)
      emitChangeEvent('reservationType.country')('')
      emitChangeEvent('reservationType.state')('')
      emitChangeEvent('reservationType.city')('')
    }
  }, [countries, updateAvailableStates, emitChangeEvent, setCountrySelected])

  const onStateChange = useCallback((stateValue) => {
    const state = states.find((state) => Object.values(state).includes(stateValue))
    if (state) {
      setStateSelected(state.value)
      updateAvailableCities(country, state.value)
      emitChangeEvent('reservationType.state')(state.value)
    } else {
      setStateSelected('')
      updateAvailableCities(null)
      emitChangeEvent('reservationType.state')('')
      emitChangeEvent('reservationType.city')('')
    }
  }, [states, updateAvailableCities, country, emitChangeEvent, setStateSelected])

  const cleanReservationTypeForm = () => {
    emitChangeEvent('reservationType.payerId')('')
    emitChangeEvent('reservationType.name')('')
    emitChangeEvent('reservationType.email')('')
    emitChangeEvent('reservationType.birthDate')('')
    emitChangeEvent('reservationType.identificationDocument')('')
    emitChangeEvent('reservationType.number')('')
    emitChangeEvent('reservationType.country')('')
    emitChangeEvent('reservationType.state')('')
    emitChangeEvent('reservationType.city')('')
  }

  const mergeSelectedPassengerWithReservationPayer = passengerId => {
    const passenger = passengersFound.filter(({ id }) => id === passengerId)
    const { id: payerId, fullName, email, birthDate, document, phone, country, state, city } = head(passenger) || {}

    cleanReservationTypeForm()
    payerId && emitChangeEvent('reservationType.payerId')(payerId)
    fullName && emitChangeEvent('reservationType.name')(fullName)
    email && emitChangeEvent('reservationType.email')(email)
    birthDate && emitChangeEvent('reservationType.birthDate')(birthDate)
    document && emitChangeEvent('reservationType.identificationDocument')(document)
    phone && emitChangeEvent('reservationType.number')(phone)
    country && emitChangeEvent('reservationType.country')(country)
    country && updateAvailableStates(country)
    state && emitChangeEvent('reservationType.state')(state)
    country && state && updateAvailableCities(country, state)
    city && emitChangeEvent('reservationType.city')(city)
  }

  const onPassengerSelected = passengerId => {
    setPassengerSelected(passengerId)
    setReservationTypeValidation(false)
    mergeSelectedPassengerWithReservationPayer(passengerId)
    setFormVisibility(true)
  }

  const onPassengerSearchSuccess = ({ emailSearch }) => {
    if (!emailSearch) return addToast('Informe um e-mail')

    cleanReservationTypeForm()
    setPassengerSelected('')
    setFormVisibility(false)
    setSearchClick(true)
    setReservationTypeValidation(true)
    emitSearchPassengerSubmit({ email: emailSearch })
  }

  const onValidationError = () => addToast('Preencha corretamente o formulário')

  const onSearchPassenger = onSubmit(onPassengerSearchSuccess, onValidationError)

  return (
    <>
      <Card css={cardContainer}>
        <Row>
          <Column phone={12} desktop={12}>
            <H4 css={marginBottom15}>Responsável pela compra</H4>
          </Column>
        </Row>

        <Row>
          <Column phone={12} desktop={4}>
            <div css={flexColumn}>
              <InputFormGroup errorMessage={passengerSearchErrors.emailSearch} label='Adicionar responsável'>
                <MaskedInputWithSuffix
                  css={emailSearchStyle}
                  type='emailSearch'
                  value={emailSearch}
                  onChange={onInputChange('emailSearch')}
                  onBlur={onInputBlur('emailSearch')}
                  placeholder='E-mail do responsável'
                  onKeyDown={event => onKeyDown(event, onSearchPassenger)}
                  disabled={disabled}
                >
                  <GhostButton css={inputButton} onClick={onSearchPassenger}>Buscar email</GhostButton>
                </MaskedInputWithSuffix>
              </InputFormGroup>

              {searchPassengerLoading && <LoadingAnimation size={30} css={[loadingContainer, marginVertical20]} />}
            </div>
          </Column>
        </Row>

        {isSearchClicked && !searchPassengerLoading && isPassengersFound &&
          <Row>
            <Column phone={12} desktop={12}>
              <div css={flexColumn}>
                <p css={label}>{`${passengersFoundLength} viajante(s) vinculado(s) com este e-mail, selecione o responsável pela reserva:`}</p>
                <div css={reservationPassengerContainer}>
                  <RadioGroup
                    name='reservation-passengers-found'
                    value={passengerSelected}
                    onChange={onPassengerSelected}
                    disabled={disabled}>
                    {passengersFound.map((passenger) => (
                      <RadioInputGroup
                        key={passenger?.id}
                        id={passenger?.id}
                        value={passenger?.id}
                        disabled={disabled}>
                        {PassengerInfo(passenger)}
                      </RadioInputGroup>
                    ))}
                  </RadioGroup>
                </div>
              </div>
            </Column>
          </Row>}

        {isSearchClicked && !searchPassengerLoading && !isPassengersFound &&
          <Row>
            <Column phone={12} desktop={12}>
              <div css={flexColumn}>
                <p css={label}>Nenhum viajante encontrado com este e-mail</p>
                <p css={addPayerLabel}>Cadastrar um responsável</p>
              </div>
            </Column>
          </Row>}

        {isFormVisible &&
          <>
            <Row>
              <Column phone={12} desktop={6}>
                <InputFormGroup errorMessage={errors.name} label='Nome Completo' required>
                  <Input
                    id='reservationType.name'
                    value={name}
                    error={errors.name}
                    onChange={emitChangeEvent('reservationType.name')}
                    onBlur={emitBlurEvent('reservationType.name')}
                    required={!isPassengerSelected}
                    disabled={isPassengerSelected || disabled}
                  />
                </InputFormGroup>
              </Column>

              <Column phone={12} desktop={6}>
                <InputFormGroup errorMessage={errors.email} label='E-mail' required>
                  <Email
                    value={email}
                    onChange={emitChangeEvent('reservationType.email')}
                    onBlur={emitBlurEvent('reservationType.email')}
                    required={!isPassengerSelected}
                    disabled={isPassengerSelected || disabled}
                  />
                </InputFormGroup>
              </Column>
            </Row>

            <Row>
              <Column phone={12} desktop={4}>
                <InputFormGroup errorMessage={errors.birthDate} label='Data de nascimento'>
                  <DatePicker
                    css={inputGroup}
                    value={birthDate}
                    onChange={emitChangeEvent('reservationType.birthDate')}
                    onBlur={emitBlurEvent('reservationType.birthDate')}
                    disabled={isPassengerSelected || disabled}
                    allowsEmpty
                  />
                </InputFormGroup>
              </Column>

              <Column phone={12} desktop={4}>
                <InputFormGroup errorMessage={errors.identificationDocument} label='Documento'>
                  <MaskedInputWithPrefix
                    id='reservationType.identificationDocument'
                    value={identificationDocument}
                    error={errors.identificationDocument}
                    formatterFunction={maskCpf}
                    onChange={emitChangeEvent('reservationType.identificationDocument')}
                    onBlur={emitBlurEvent('reservationType.identificationDocument')}
                    disabled={isPassengerSelected || disabled}
                  >
                    <div css={documentLabel}>CPF</div>
                  </MaskedInputWithPrefix>
                </InputFormGroup>
              </Column>

              <Column phone={12} desktop={4}>
                <InputFormGroup errorMessage={errors.number} label='Telefone'>
                  <PhoneSelector
                    customCss={[fullWidth]}
                    value={number}
                    error={errors.number}
                    onBlur={emitBlurEvent('reservationType.number')}
                    onChange={emitChangeEvent('reservationType.number')}
                    disabled={isPassengerSelected || disabled}
                  />
                </InputFormGroup>
              </Column>
            </Row>

            <Row>
              <Column phone={12} desktop={2}>
                <InputFormGroup errorMessage={errors.zipCode} label='CEP'>
                  <InputZipCodeMask
                    id='reservationType.zipCode'
                    value={zipCode}
                    error={errors.zipCode}
                    onChange={emitChangeEvent('reservationType.zipCode')}
                    onBlur={onZipCodeBlur}
                    disabled={isPassengerSelected || disabled}
                  />
                </InputFormGroup>
              </Column>
              <Column phone={12} desktop={4}>
                <InputFormGroup errorMessage={errors.street} label='Endereço'>
                  <Input
                    id='reservationType.street'
                    value={street}
                    error={errors.street}
                    onChange={emitChangeEvent('reservationType.street')}
                    onBlur={emitBlurEvent('reservationType.street')}
                    disabled={isPassengerSelected || disabled}
                  />
                </InputFormGroup>
              </Column>
              <Column phone={12} desktop={2}>
                <InputFormGroup errorMessage={errors.supplement} label='Número'>
                  <Input
                    id='reservationType.supplement'
                    value={supplement}
                    error={errors.supplement}
                    onChange={emitChangeEvent('reservationType.supplement')}
                    onBlur={emitBlurEvent('reservationType.supplement')}
                    disabled={isPassengerSelected || disabled}
                  />
                </InputFormGroup>
              </Column>
              <Column phone={12} desktop={4}>
                <InputFormGroup errorMessage={errors.district} label='Bairro'>
                  <Input
                    id='reservationType.district'
                    value={district}
                    error={errors.district}
                    onChange={emitChangeEvent('reservationType.district')}
                    onBlur={emitBlurEvent('reservationType.district')}
                    disabled={isPassengerSelected || disabled}
                  />
                </InputFormGroup>
              </Column>
            </Row>

            <Row>
              <Column phone={12} desktop={4}>
                <InputFormGroup label='Pais' errorMessage={errors.country}>
                  <Select
                    options={countries}
                    value={country}
                    onChange={onCountryChange}
                    placeholder='Selecione ou digite o país'
                    disabled={isPassengerSelected || disabled}
                  />
                </InputFormGroup>
              </Column>

              <Column phone={12} desktop={4}>
                <InputFormGroup label='Estado' errorMessage={errors.state}>
                  <Select
                    options={states}
                    value={state}
                    onChange={onStateChange}
                    placeholder='Selecione ou digite o estado'
                    disabled={isPassengerSelected || disabled}
                  />
                </InputFormGroup>
              </Column>
              <Column phone={12} desktop={4}>
                <InputFormGroup label='Cidade' errorMessage={errors.city}>
                  <Select
                    options={cities}
                    value={city}
                    onChange={emitChangeEvent('reservationType.city')}
                    placeholder='Selecione ou digite a cidade'
                    disabled={isPassengerSelected || disabled}
                  />
                </InputFormGroup>
              </Column>
            </Row>
            <Row>
              <Column phone={12} desktop={4}>
                <InputFormGroup label='Nome do contato de emergência'>
                  <Input
                    value={emergencyName}
                    placeholder='Digite o nome completo do contato de emergência'
                    required
                    disabled={isPassengerSelected || disabled}
                    onChange={emitChangeEvent('reservationType.emergencyName')}
                    onBlur={emitBlurEvent('reservationType.emergencyName')}
                  />
                </InputFormGroup>
              </Column>
              <Column phone={12} desktop={4}>
                <InputFormGroup label='Telefone do contato de emergência'>
                  <PhoneSelector
                    customCss={[fullWidth]}
                    value={emergencyTelephone}
                    disabled={isPassengerSelected || disabled}
                    onChange={emitChangeEvent('reservationType.emergencyTelephone')}
                    onBlur={emitBlurEvent('reservationType.emergencyTelephone')}
                  />
                </InputFormGroup>
              </Column>
              <Column phone={6} desktop={2}>
                <InputFormGroup label='Altura'>
                  <InputHeightMask
                    value={height}
                    css={[inputStyle, fullWidth]}
                    disabled={isPassengerSelected || disabled}
                    onChange={emitChangeEvent('reservationType.height')}
                    onBlur={emitBlurEvent('reservationType.height')}
                  />
                </InputFormGroup>
              </Column>
              <Column phone={6} desktop={2}>
                <InputFormGroup label='Peso'>
                  <InputWeightMask
                    value={weight}
                    css={[inputStyle, fullWidth]}
                    disabled={isPassengerSelected || disabled}
                    onChange={emitChangeEvent('reservationType.weight')}
                    onBlur={emitBlurEvent('reservationType.weight')}
                  />
                </InputFormGroup>
              </Column>
            </Row>
          </>}
      </Card>
    </>
  )
}

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { orbEntities } from 'constants/orbEntities'
import { head, tail, formatMoney } from '@bonitour/common-functions'
import { paymentTypesMap } from 'constants/reservationPaymentTypes'
import { providers, cardBrands, paymentMeansShort } from 'constants/acquirers'
import { timelineStateTypes, timelineEventTypes } from 'components/ReservationTimeline'
import { formatISOTime } from 'utils/time'

dayjs.extend(utc)

const findType = (type, { objectChanges, event, payerChanged }) => {
  switch (type) {
  case 'Financial::ReservationPayment':
    return eventMapFinancial(event)
  case 'Financial::ReservationRefund':
    return eventMapRefund()
  case 'Financial::AdvanceReservationPayment':
    return eventAdvancePayment()
  case 'TicketSeller::Reservation':
    return payerChanged ? eventChangePayer() : eventMap(objectChanges, type)
  case 'TicketSeller::Ticket':
    return eventMap(objectChanges, type)
  default:
    return ''
  }
}

const eventMap = (change, type) => {
  switch (Object.keys(change)[0]) {
  case 'state':
    return 'Estado do ingresso alterado'
  case 'slot':
    return 'Data do ingresso alterada'
  case 'payment_channel':
    return 'Pagamento realizado'
  case 'id':
    return type === 'TicketSeller::Reservation' ? 'Reserva criada' : 'Ingresso criado'
  case 'status':
    return `Estado ${type === 'TicketSeller::Reservation' ? 'da reserva' : 'do ingresso'}  alterado`
  case 'updated_at':
    delete change.updated_at
    return eventMap(change)
  default:
    return ''
  }
}

const eventMapFinancial = (change) => {
  switch (change) {
  case 'create':
    return 'Registro de pagamento'
  case 'destroy':
    return 'Exclusão de pagamento'
  default:
    return ''
  }
}

const eventMapRefund = () => {
  return 'Estorno de pagamento'
}

const eventAdvancePayment = () => {
  return 'Registro de Pagamento Antecipado'
}

const eventChangePayer = () => 'Alteração do responsável da reserva'

export const ReservationHistoryParser = {
  history ({ created_at: createdAt, id, item_type: type, user_name: userName, whodunnit, ticket_code: ticketCode, object_changes: objectChanges, payer_changed: payerChanged, event }) {
    const action = findType(type, { objectChanges, payerChanged, event })
    const worker = whodunnit && whodunnit.includes('Worker') ? 'Processo interno do sistema' : '-'
    const parsedDatetime = formatISOTime(createdAt, 'YYYY-MM-DD HH:mm')

    return {
      id,
      action,
      date: formatISOTime(createdAt, 'YYYY-MM-DD'),
      time: formatISOTime(createdAt, 'HH:mm'),
      datetime: parsedDatetime,
      user: userName || worker,
      objectChanges,
      payerChanged,
      type: head(Object.keys(objectChanges)),
      ticketCode
    }
  },
  historyV2 ({
    item_type: itemType,
    item_id: itemId = 'N/A',
    created_at: createdAt,
    event,
    user_name: userName,
    related_objects_info: relatedObjectsInfo,
    object_changes: objectChanges
  }, allHistorics = []) {
    const {
      state,
      reason_delete: reasonDelete,
      refund_method: refundMethod,
      payment_method: paymentMethod,
      payment_mean: paymentMean
    } = objectChanges || {}

    const {
      payment_method: relatedPaymentMethod,
      refund_method: relatedRefundMethod,
      aut = null,
      doc = null,
      installments = null,
      values_by_ticket: valuesByTicket = {},
      value,
      origin_bank_account: originBankAccount,
      destination_bank_account: destinationBankAccount,
      acquirer_name: acquirerName,
      ticket_code_origin: ticketCodeOrigin,
      ticket_code_destination: ticketCodeDestination,
      destination_bank_account_after_change: destinationBankAccountAfterChange,
      destination_bank_account_before_change: destinationBankAccountBeforeChange,
      origin_bank_account_before_change:
      originBankAccountBeforeChange,
      origin_bank_account_after_change:
      originBankAccountAfterChange,
      custom_vendor_name: customVendorName,
      custom_vendor_name_after_change: customVendorNameAfterChange,
      custom_vendor_name_before_change: customVendorNameBeforeChange
    } = relatedObjectsInfo || {}

    const originFilter = ({ item_id: originItemId, event: originEvent }) => (originItemId === itemId && originEvent === timelineEventTypes.create)

    const isRefund = [
      orbEntities.ADVANCE_RESERVATION_REFUND,
      orbEntities.RESERVATION_REFUND
    ].includes(itemType)

    const isTicketBalanceTransfer = itemType === orbEntities.TICKET_BALANCE_TRANSFER

    const keysOfObjectChanges = Object.keys(objectChanges)

    const isUpdate = event === timelineEventTypes.update
    const isDestroy = event === timelineEventTypes.destroy
    const hasSomeTwoChanges = keysOfObjectChanges.length === 2
    const isChangedState = keysOfObjectChanges.includes('state')
    const isChangedUpdatedAt = keysOfObjectChanges.includes('updated_at')
    const isChangedReason = keysOfObjectChanges.includes('reason_delete')

    const isUpdateBeforeDestroy = hasSomeTwoChanges && isUpdate && isChangedUpdatedAt && isChangedReason

    if (isUpdateBeforeDestroy) return null

    const parseValuesByTicket = (valuesByTicket = {}) => {
      return Object.keys(valuesByTicket).reduce((acc, curr) => {
        return [
          ...acc,
          `${curr} ${formatMoney(valuesByTicket[curr])}`
        ]
      }, []).join(' - ')
    }

    const getValueAndTicketsByOrigin = () => {
      const originHistory = allHistorics.find(originFilter)

      const {
        value,
        values_by_ticket: valuesByTicket,
        custom_vendor_name: customVendorName
      } = originHistory?.related_objects_info || {}

      const parsedTicketAndValues = parseValuesByTicket(valuesByTicket)

      return {
        value: Number(value),
        valuesByTicket: parsedTicketAndValues,
        customVendorName
      }
    }

    if (isDestroy) {
      const {
        value,
        valuesByTicket,
        customVendorName
      } = getValueAndTicketsByOrigin()

      return {
        id: itemId,
        type: itemType,
        date: createdAt,
        userName,
        event: timelineEventTypes.destroy,
        fieldsUpdated: [],
        reason: head(reasonDelete),
        value,
        linkedTickets: valuesByTicket,
        customVendorName
      }
    }

    if (isUpdate && isChangedState) {
      const isApproved = tail(state) === timelineStateTypes.approved

      const destinationBankAccount = (function () {
        const valueByChange = destinationBankAccountAfterChange || destinationBankAccountBeforeChange

        if (!isApproved) return undefined

        if (valueByChange) return valueByChange

        const originHistory = allHistorics.find(originFilter)

        const {
          destination_bank_account: destinationBankAccount
        } = originHistory?.related_objects_info || {}

        return destinationBankAccount
      }())

      return {
        destinationBankAccount,
        date: createdAt,
        id: itemId,
        type: itemType,
        userName: userName || 'Fornecedor',
        event,
        fieldsUpdated: [],
        isApproved
      }
    }

    if (isUpdate) {
      function getDate (firstDate, lastDate, finalLabel) {
        const firstDateFormatted = dayjs(firstDate).format('DD/MM/YYYY')
        const lastDateFormatted = dayjs(lastDate).format('DD/MM/YYYY')
        if (firstDateFormatted === lastDateFormatted) {
          return [null, null]
        }
        return [`Data de ${finalLabel}`, 'a', firstDateFormatted, lastDateFormatted]
      }

      const fieldsUpdated = (function () {
        if (!objectChanges) return []

        const filteredFields = keysOfObjectChanges
          .filter((key) => Boolean(head(objectChanges[key])) || Boolean(tail(objectChanges[key])))

        const fieldsChanged = filteredFields.reduce((acc, curr) => {
          const originalValue = tail(objectChanges[curr])

          const lastValue = head(objectChanges[curr])

          const isBankAccountLastValue = Object.keys(paymentTypesMap).includes(lastValue)

          const [fieldName, pronoun, value, lastValueAdded] = (function () {
            if (curr.includes('refund_method')) {
              return [
                'Método de reembolso',
                'o',
                paymentTypesMap[originalValue].toLowerCase()
              ]
            }
            if (curr.includes('payment_method')) {
              return [
                'Método de pagamento',
                'o',
                paymentTypesMap[originalValue].toLowerCase()
              ]
            }
            if (curr.includes('reason_delete')) {
              return ['Motivo', 'o']
            }
            if (curr.includes('aut')) {
              return ['Aut', 'o']
            }
            if (curr.includes('doc')) {
              return ['Doc', 'o']
            }
            if (curr.includes('acquirer_name')) {
              return [
                'Adquirente',
                'o',
                providers?.[originalValue] || originalValue,
                providers?.[lastValue] || lastValue
              ]
            }
            if (curr.includes('value')) {
              return ['Valor', 'o']
            }
            if (curr.includes('origin_bank_account_id')) {
              return [
                'Conta bancária de origem',
                'a',
                originBankAccountAfterChange,
                originBankAccountBeforeChange
              ]
            }
            if (curr.includes('destination_bank_account')) {
              return [
                'Conta bancária de destino',
                'a',
                destinationBankAccountAfterChange,
                destinationBankAccountBeforeChange
              ]
            }
            if (curr.includes('installments')) {
              return ['Parcelas', 'a']
            }
            if (curr.includes('payment_date')) {
              return getDate(originalValue, lastValue, 'pagamento')
            }
            if (curr.includes('refund_date')) {
              return getDate(originalValue, lastValue, 'reembolso')
            }
            if (curr.includes('brand')) {
              return [
                'Bandeira',
                'a',
                cardBrands?.[originalValue] || originalValue
              ]
            }
            if (curr.includes('payment_mean')) {
              return [
                'Meio de pagamento',
                'o',
                paymentMeansShort?.[originalValue] || originalValue,
                paymentMeansShort?.[lastValue] || lastValue
              ]
            }
            if (curr.includes('custom_vendor_id')) {
              return [
                'Fornecedor multiponto',
                'o',
                customVendorNameAfterChange,
                customVendorNameBeforeChange
              ]
            }
            return [null, null]
          }())

          if (!fieldName) return acc

          const lastValueLabel = (function () {
            const brandName = cardBrands?.[lastValue]

            if (brandName) return brandName

            if (isBankAccountLastValue) {
              return String(paymentTypesMap?.[lastValue] || lastValue).toLowerCase()
            }

            return lastValueAdded || lastValue
          }())

          const newValue = value || originalValue

          if (!lastValue && newValue) {
            return [
              ...acc,
              `${fieldName} adicionad${pronoun} como sendo "${newValue}"`
            ]
          }

          if (!newValue) {
            return [
              ...acc,
              `${fieldName} removid${pronoun}`
            ]
          }

          return [
            ...acc,
            `${fieldName} alterad${pronoun} de "${lastValueLabel}" para "${newValue}"`
          ]
        }, [])

        return fieldsChanged
      })()

      if (fieldsUpdated.length === 0) return null

      const {
        valuesByTicket,
        value,
        customVendorName
      } = getValueAndTicketsByOrigin()

      return {
        date: createdAt,
        event,
        type: itemType,
        userName,
        fieldsUpdated,
        paymentMethod: paymentMethod && refundMethod && String(head(paymentMethod || refundMethod)).toUpperCase(),
        reason: head(reasonDelete),
        id: itemId,
        linkedTickets: valuesByTicket,
        value,
        customVendorName
      }
    }

    const [parsedValue, valueForRefund] = (function () {
      if (isRefund) return [undefined, Number(value)]

      if (isTicketBalanceTransfer) return [Number(tail(objectChanges.value)), undefined]

      return [Number(value), undefined]
    }())

    const isBalanceTransfer = itemType === orbEntities.TICKET_BALANCE_TRANSFER

    const [ticketIds, ticketsLabel] = (function () {
      const ticketIds = Object.keys(valuesByTicket)
      const ticketsLabel = parseValuesByTicket(valuesByTicket)
      return [ticketIds, ticketsLabel]
    }())

    const pluralizedLabel = ticketIds.length > 1 ? 's' : ''

    const installmentsLabel = installments ? ` (${installments}x)` : ''
    const paymentMethodLabel = (relatedPaymentMethod || relatedRefundMethod) && paymentTypesMap[relatedPaymentMethod ?? relatedRefundMethod] + installmentsLabel

    const autDoc = aut ? `${aut}/${doc}` : null

    const ticketPaid = isBalanceTransfer ? `Ingresso${pluralizedLabel} pago${pluralizedLabel}: ${ticketsLabel}` : undefined

    const parsedPaymentMean = paymentMean && (paymentMeansShort?.[tail(paymentMean)] || tail(paymentMean))

    const brand = cardBrands?.[tail(objectChanges.brand)]

    return {
      date: createdAt,
      paymentMethod: paymentMethodLabel,
      value: parsedValue,
      valueForRefund,
      autDoc,
      type: itemType,
      event,
      linkedTickets: ticketsLabel,
      ticketPaid,
      originBankAccount,
      userName,
      fieldsUpdated: [],
      id: itemId,
      acquirerName,
      destinationBankAccount,
      fromTicket: ticketCodeOrigin,
      toTicket: ticketCodeDestination,
      paymentMean: parsedPaymentMean,
      brand,
      customVendorName
    }
  }
}

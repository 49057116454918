/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { TableContainer, TableBody } from '@bonitour/components'
import { VoucherTableHeader } from './VoucherTableHeader'
import { VoucherTableRow } from './VoucherTableRow'
import { identity } from '@bonitour/common-functions'

export const VoucherTable = ({
  vouchers = [],
  isSelectedAll: emitSelectedAll = identity,
  onSelectedAllClick: emitSelectedAllClick = identity,
  isVoucherSelect: emitVoucherSelect = identity,
  isVoucherSelectable: emitVoucherSelectable = identity,
  onVoucherClick: emitVoucherClick = identity,
  loading
}) => {
  return (
    <TableContainer>
      <VoucherTableHeader isSelectedAll={emitSelectedAll} onSelectedAllClick={emitSelectedAllClick} />
      <TableBody loading={loading}>
        {vouchers.map((voucher, index) =>
          <VoucherTableRow
            key={index}
            voucher={voucher}
            isVoucherSelect={emitVoucherSelect}
            isVoucherSelectable={emitVoucherSelectable}
            onVoucherClick={emitVoucherClick}
          />
        )}
      </TableBody>
    </TableContainer>
  )
}

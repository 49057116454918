/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { ReservationInfo } from 'containers/Reservation/ReservationInfo'
import { identity } from '@bonitour/common-functions'
import { useForm } from '@bonitour/app-functions'
import { reservationInfoSchema } from 'containers/Reservation/ReservationPayerInfo.rules'

export const ReservationInfoApp = ({
  info,
  reservationId = '',
  isCommercialPartner = false,
  editStatusReservationLoading = false,
  onStatusEdition: emitStatusEditionEvent = identity,
  onPayerInfoEdition: emitPayerInfoEditionEvent = identity,
  reservationTotalPrice = '',
  activities = []
}) => {
  const { form, setForm, errors, utils: { onInputBlur, onInputChange } } = useForm(info, reservationInfoSchema)

  const onClick = () => emitPayerInfoEditionEvent(form)

  return (
    <ReservationInfo
      reservationInfo={form}
      reservationId={reservationId}
      isCommercialPartner={isCommercialPartner}
      reservationInfoErrors={errors}
      onChange={onInputChange}
      onBlur={onInputBlur}
      onPayerInfoEdition={onClick}
      onStatusEdition={emitStatusEditionEvent}
      editStatusReservationLoading={editStatusReservationLoading}
      reservationTotalPrice={reservationTotalPrice}
      activities={activities}
      setForm={setForm}
    />
  )
}

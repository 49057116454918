/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Card, Input, InputFormGroup, colors, Button, useToast, Row, Column } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { useForm } from '@bonitour/app-functions'
import { useKeyPress } from 'hooks/useKeyPress'
import { string } from 'yup'

const button = css`
  height: 40px;
  width: 100%;
  margin-top: 20px;
`
const cardContrast = css`
  margin-bottom: 20px;
  padding: 15px;
  background-color: ${colors.gray13};
  border: none;
`

export const ExternalProviderFilterSchema = {
  name: string()
}

export const FilterExternalProviders = ({
  initialFilters,
  onFilterSubmit: emitFilterSubmit = identity,
  loading
}) => {
  const { add: addToast } = useToast()
  const { onKeyDown } = useKeyPress()
  const { form, errors, onSubmit, utils: { onInputBlur, onInputChange } } = useForm(initialFilters, ExternalProviderFilterSchema)
  const { name = '' } = form

  const onError = () => addToast('Preencha o formulário corretamente')

  const onClick = () => onSubmit(emitFilterSubmit(form), onError)

  return (
    <Card css={cardContrast}>
      <Row>
        <Column phone={12} desktop={6}>
          <InputFormGroup label='Nome do fornecedor externo' errorMessage={errors.name}>
            <Input
              value={name}
              onChange={onInputChange('name')}
              onBlur={onInputBlur('name')}
              disabled={loading}
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={2}>
          <Button css={button} onClick={onClick}>Pesquisar</Button>
        </Column>
      </Row>
    </Card>
  )
}

import { useMemo } from 'react'
import { useToast } from '@bonitour/components'

// TODO: Get macroPerrmissions from backend
const macroPermissions = {
  admin: {
    companies: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
    services: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
    fees: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
    vacancies: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
    calendars: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
    invoices: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
    reservations: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
    tickets: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
    passengers: ['CREATE', 'READ', 'UPDATE'],
    checkout: ['CREATE', 'READ'],
    payment_link: ['CREATE', 'READ', 'UPDATE'],
    reservation_payments: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
    reservation_refunds: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
    acquirers: ['CREATE', 'READ', 'UPDATE', 'DELETE']
  }
}

const hasPermission = ({ domain, userPermissionGroup, method }) => {
  return macroPermissions?.[userPermissionGroup]?.[domain].includes(method)
}

// TODO: Get logged user perrmission group from backend and store in a session state (Context?)
const getUserPermissionGroup = () => 'admin'

export const usePermissions = () => {
  const { add: addToast } = useToast()
  const userPermissionGroup = useMemo(() => getUserPermissionGroup(), [])

  // TODO: remove admin assertion
  const isBinamik = useMemo(() => userPermissionGroup === 'binamik' || userPermissionGroup === 'admin', [userPermissionGroup])

  const canRead = (domain) => {
    return hasPermission({ domain, userPermissionGroup, method: 'READ' })
  }

  const canCreate = (domain) => {
    return hasPermission({ domain, userPermissionGroup, method: 'CREATE' })
  }

  const canUpdate = (domain) => {
    return hasPermission({ domain, userPermissionGroup, method: 'UPDATE' })
  }

  const canDelete = (domain) => {
    return hasPermission({ domain, userPermissionGroup, method: 'DELETE' })
  }

  const handlePermission = (error, permissionDeniedMessage = 'Você não possui permissão de acesso') => {
    if (error && error.status === 403) {
      permissionDeniedMessage && addToast(permissionDeniedMessage)
      return false
    }
    return true
  }

  return {
    isBinamik,
    permission: userPermissionGroup,
    canRead,
    canCreate,
    canUpdate,
    canDelete,
    handlePermission
  }
}

import { VoucherTurisService } from 'core/services/VoucherTuris'
import { VoucherUnicoService } from 'core/services/VoucherUnico'
import { useReservation } from 'hooks/context/Reservation'
import { useState } from 'react'

export const useCancelVoucher = () => {
  const [loading, setLoading] = useState(false)
  const { updateReservation } = useReservation()

  const cancelVoucherUnico = async (ticketsIds) => {
    setLoading(true)
    return VoucherUnicoService.cancelVoucher(ticketsIds).then(() => updateReservation()).finally(() => setLoading(false))
  }

  const cancelVoucherTuris = async (ticketsIds) => {
    setLoading(true)
    return VoucherTurisService.cancelVoucher(ticketsIds).then(() => updateReservation()).finally(() => setLoading(false))
  }

  return {
    cancelVoucherUnico,
    cancelVoucherTuris,
    loading
  }
}

import { useEffect, useState, useCallback } from 'react'
import { ReservationAdvanceTransaction } from 'core/services/ReservationAdvanceTransaction'
import { useReservation } from 'hooks/context/Reservation'
import { ReservationService } from 'core/services/Reservation'
import { PaymentService } from 'core/services/Payment'
import { RefundService } from 'core/services/Refund'

export const useTransactionsProvider = (shouldUpdate) => {
  const { reservationId } = useReservation()
  const [transactions, setTransactions] = useState([])
  const [amount, setAmount] = useState([])

  const updateTransactions = useCallback(() => {
    if (!reservationId) {
      return
    }
    return ReservationAdvanceTransaction.getAdvanceTransactions(reservationId)
      .then(({ transactions = [], amount = {} }) => {
        setTransactions(transactions)
        setAmount(amount)
      })
  }, [reservationId])

  const createPayment = useCallback((payload) => (
    reservationId && ReservationAdvanceTransaction.createPayment(reservationId, payload).then(updateTransactions)
  ), [reservationId, updateTransactions])

  const createRefund = useCallback((payload) => (
    reservationId && RefundService.createPartnershipRefund(reservationId, payload).then(updateTransactions)
  ), [reservationId, updateTransactions])

  const approvePaymentTransaction = useCallback((paymentId, destinationBankAccount = null) => (
    reservationId && ReservationAdvanceTransaction.approvePaymentTransaction(reservationId, paymentId, destinationBankAccount).then(updateTransactions)
  ), [reservationId, updateTransactions])

  const approveProviderRefundTransaction = useCallback((paymentId) => (
    reservationId && ReservationAdvanceTransaction.approveProviderRefundTransaction(reservationId, paymentId).then(updateTransactions)
  ), [reservationId, updateTransactions])

  const rejectPaymentTransaction = useCallback((paymentId) => (
    reservationId && ReservationAdvanceTransaction.rejectPaymentTransaction(reservationId, paymentId).then(updateTransactions)
  ), [reservationId, updateTransactions])

  const rejectProviderPaymentTransaction = useCallback((paymentId) => (
    reservationId && ReservationAdvanceTransaction.rejectProviderRefundTransaction(reservationId, paymentId).then(updateTransactions)
  ), [reservationId, updateTransactions])

  const getPaymentById = useCallback((paymentId) => {
    if (reservationId) {
      return ReservationAdvanceTransaction.getAdvanceTransactionsById(reservationId, paymentId)
    }
  }, [reservationId])

  const getAdvancePaymentById = useCallback((paymentId) => {
    if (reservationId) {
      return PaymentService.getAdvancePaymentById(reservationId, paymentId)
    }
  }, [reservationId])

  const getAdvancedRefundById = useCallback((refundId) => {
    if (reservationId) {
      return RefundService.getPartnershipById(reservationId, refundId)
    }
  }, [reservationId])

  const getPartnershipTicketsToRefund = useCallback((reservationId) => {
    if (reservationId) {
      return ReservationService.getPartnershipTicketsToRefund(reservationId)
    }
  }, [])

  useEffect(() => {
    updateTransactions()
  }, [updateTransactions, shouldUpdate])

  return {
    transactions,
    amount,
    updateTransactions,
    createPayment,
    createRefund,
    approvePaymentTransaction,
    approveProviderRefundTransaction,
    rejectPaymentTransaction,
    rejectProviderPaymentTransaction,
    getPartnershipTicketsToRefund,
    getPaymentById,
    getAdvancedRefundById,
    getAdvancePaymentById
  }
}

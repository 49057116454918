/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useForm } from '@bonitour/app-functions'
import { identity } from '@bonitour/common-functions'
import { Button, GhostButton, LoadingAnimation, useToast } from '@bonitour/components'
import { jsx } from '@emotion/core'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { OfflineTicketService } from 'services/OfflineTicket/Service'
import { handleOfflineTicketFormSchema } from '../OfflineExperience.schema'
import { buttonsContainer } from './OfflineTicketEdition.style'
import { loadingContainer } from 'assets/styles/global'
import { OfflineTicketForm } from '../OfflineTicketForm'
import { handleOfflineTicketFormBase } from './OfflineTicketEdition.schema'

export const OfflineTicketEdition = ({
  toggleOfflineTicketEdition = identity,
  onTicketUpdate = identity,
  ticketId = ''
}) => {
  const [offlineTicket, setOfflineTicket] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const { add: addToast } = useToast()
  const ticketState = useMemo(() => offlineTicket?.state, [offlineTicket])

  useEffect(() => {
    if (!ticketId) return

    setIsLoading(true)
    OfflineTicketService.get(ticketId).then(ticket => setOfflineTicket(ticket)).catch(() => addToast('Houve um erro ao buscar pelo ticket')).finally(() => setIsLoading(false))
  }, [addToast, ticketId])

  const schema = useMemo(() => handleOfflineTicketFormSchema({ category: offlineTicket?.offline_category, isEditingTicket: true }), [offlineTicket])

  const offlineTicketFormBase = useMemo(() => {
    if (!offlineTicket) return

    return handleOfflineTicketFormBase({ category: offlineTicket?.offline_category, ticketData: offlineTicket })
  }, [offlineTicket])

  const {
    form,
    errors,
    onSubmit,
    utils: {
      onInputBlur,
      onInputChange
    }
  } = useForm(offlineTicketFormBase, schema)

  const submit = useCallback((form) => {
    OfflineTicketService.update(ticketId, {
      ...form,
      category: offlineTicket?.offline_category
    }).then(() => {
      addToast('Ticket atualizado com sucesso', 'success')
      onTicketUpdate()
      toggleOfflineTicketEdition()
    }).catch((errors) => {
      const message = errors?.data?.extra_data?.message
      let messageTranslated = 'Erro ao atualizar ingresso'

      if (message && message.includes('Ticket already batched')) {
        messageTranslated = 'Ingresso não pode ser editado pois já está dentro de um lote'
      }

      toggleOfflineTicketEdition()
      addToast(messageTranslated)
    })
  }, [addToast, offlineTicket, ticketId, toggleOfflineTicketEdition, onTicketUpdate])

  const onError = useCallback(() => {
    addToast('Erro ao atualizar o ingresso, confira os campos e tente novamente')
  }, [addToast])

  const onSave = onSubmit(() => submit(form), onError)

  if (isLoading) return <LoadingAnimation css={loadingContainer} />

  return (
    <>
      <OfflineTicketForm
        form={form}
        errors={errors}
        onInputBlur={onInputBlur}
        onInputChange={onInputChange}
        category={offlineTicket?.offline_category}
        ticketState={ticketState}
        isEditing
      />
      <div css={buttonsContainer}>
        <GhostButton onClick={toggleOfflineTicketEdition}>Cancelar</GhostButton>
        <Button onClick={onSave}>
          Salvar
        </Button>
      </div>
    </>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { AngleRightIcon, BREAK_POINTS, Card, colors, Dialog, flexCenteredSpaceBetweenRow, flexColumn, flexHorizontallyCentered, InfoIcon, Label, Row, scrollBar } from '@bonitour/components'
import { formatMoney, identity } from '@bonitour/common-functions'
import { hidden, marginBottom } from 'assets/styles/global'
import { useFeatureFlag } from 'contexts/Feature'
import { useState } from 'react'

const marginBottom5 = marginBottom(5)

const container = css`
  margin-bottom: 30px;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    flex-wrap: nowrap;
    border: 1px solid ${colors.gray11};
    border-radius: 10px;
    margin-bottom: 20px;
  }
`

const amountDueCard = css`
  min-width: 150px;
  display: inline-flex;
  margin-right: 20px;
  padding: 20px;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    min-width: initial;
    margin: 0;
    padding: 10px;
    border: none;
  }
`

const totalLabel = css`
  font-size: 12px;
  color: ${colors.gray5};

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    font-size: 10px;
  }
`

const amountDueStyle = css`
  font-size: 24px;
  color: ${colors.gray1};
  font-weight: bold;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    font-size: 14px;
  }
`

const amountpaidValue = css`
  color: ${colors.indigo1};
`

const amountRefundValue = css`
  color: ${colors.green1};
`

const amountOwed = css`
  color: ${colors.red1}
`

const angleRight = css`
  margin-left: 20px;
  font-size: 26px;
  color: ${colors.gray5};
  cursor: pointer;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    display: none;
  }
`

const infoIcon = css`
  display: inline-flex;
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
  margin-left: 5px;
  padding: 4px;
  color: ${colors.gray4};
  border-radius: 100%;
  border: 1px solid ${colors.gray4};
  cursor: pointer;
`

const detailsContainer = css`
  margin-top: 20px;
  max-height: 300px;
  padding-right: 5px;
  overflow: auto;
  transition: 0.5s;
  ${scrollBar(5)};
`

export const ReservationAmountDue = ({
  amounts = {},
  isDetailsHidden = true,
  isCreditHidden = false,
  isCommercialPartner = true,
  onReservationBalanceClick: emitReservationBalanceClick = identity
}) => {
  const [isReservationBalanceEnabled] = useFeatureFlag('orb-reservation-balance')
  const { total = 0, totalAmountByCompany = [], due = 0, totalAmountDueByCompany = [], paid = 0, totalAmountPaidByCompany = [], credit = 0 } = amounts
  const [details, setDetails] = useState('')
  const isTotalDetailsVisible = Boolean(details)
  const hasCredit = Boolean(credit)
  const showTotalDetail = !isDetailsHidden && total > 0 && totalAmountByCompany.length > 0
  const showDueDetail = !isDetailsHidden && due > 0 && totalAmountDueByCompany.length > 0
  const showPaidDetail = !isDetailsHidden && paid > 0 && totalAmountPaidByCompany.length > 0
  const onDetailsClick = label => () => setDetails(label)
  const onClose = () => setDetails('')

  return (
    <>
      <Row css={container}>

        <Card css={amountDueCard}>
          <div css={flexColumn}>
            <div css={[flexHorizontallyCentered, marginBottom5]}>
              <p css={totalLabel}>Valor total</p>
              {showTotalDetail && <InfoIcon css={infoIcon} onClick={onDetailsClick('total')} />}
            </div>
            <span css={amountDueStyle}>{formatMoney(total > 0 ? total : 0)}</span>
          </div>
        </Card>

        <Card css={amountDueCard}>
          <div css={flexColumn}>
            <div css={[flexHorizontallyCentered, marginBottom5]}>
              <p css={totalLabel}>Valor pago</p>
              {showPaidDetail && <InfoIcon css={infoIcon} onClick={onDetailsClick('paid')} />}
            </div>
            <span css={[amountDueStyle, amountpaidValue]}>{formatMoney(paid || 0)}</span>
          </div>
        </Card>

        {isCommercialPartner && (
          <>
            <Card css={amountDueCard}>
              <div css={flexColumn}>
                <div css={[flexHorizontallyCentered, marginBottom5]}>
                  <p css={totalLabel}>Valor devido</p>
                  {showDueDetail && <InfoIcon css={infoIcon} onClick={onDetailsClick('due')} />}
                </div>
                <span css={[amountDueStyle, amountOwed]}>{formatMoney(due > 0 ? due : 0)}</span>
              </div>
            </Card>
            <Card css={[amountDueCard, Boolean(isCreditHidden) && hidden]}>
              <div css={flexColumn}>
                <p css={[totalLabel, marginBottom5]}>Saldo</p>
                <span css={[amountDueStyle, amountRefundValue]}>{formatMoney(credit || 0)}</span>
              </div>
              {(hasCredit && isReservationBalanceEnabled)
                ? (
                  <AngleRightIcon css={angleRight} onClick={hasCredit && emitReservationBalanceClick} />
                )
                : null
              }
            </Card>
          </>
        )}
      </Row>

      <Dialog title='Valor detalhado' isVisible={isTotalDetailsVisible} onClose={onClose}>
        <div css={detailsContainer}>
          {details === 'total' && totalAmountByCompany.map(({ companyName, total }) => (
            <div css={[flexCenteredSpaceBetweenRow, marginBottom5]} key={companyName}>
              <Label>{companyName}</Label>
              <Label>{formatMoney(total || 0)}</Label>
            </div>
          ))}
          {details === 'due' && totalAmountDueByCompany.map(({ companyName, total }) => (
            <div css={[flexCenteredSpaceBetweenRow, marginBottom5]} key={companyName}>
              <Label>{companyName}</Label>
              <Label>{formatMoney(total || 0)}</Label>
            </div>
          ))}
          {details === 'paid' && totalAmountPaidByCompany.map(({ companyName, total }) => (
            <div css={[flexCenteredSpaceBetweenRow, marginBottom5]} key={companyName}>
              <Label>{companyName}</Label>
              <Label>{formatMoney(total || 0)}</Label>
            </div>
          ))}
        </div>
      </Dialog>
    </>
  )
}

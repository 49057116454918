import { OrbStore } from 'services/Orb/Store'
const ACTIVITY_ID_KEY = 'orb-id'
const SELECTED_ID_KEY = 'orb-selected'

export const ActivityStore = {
  getId () {
    return OrbStore.get(ACTIVITY_ID_KEY) || ''
  },
  setId (value) {
    OrbStore.set(ACTIVITY_ID_KEY, value)
    return value
  },
  getActivity () {
    try {
      return JSON.parse(OrbStore.get(SELECTED_ID_KEY)) || {}
    } catch (error) {
      return {}
    }
  },
  setActivity (value) {
    try {
      OrbStore.set(SELECTED_ID_KEY, JSON.stringify(value))
      return value
    } catch (error) {
      return {}
    }
  }
}

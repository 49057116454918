import React from 'react'
import { useParams } from 'react-router'
import { PlanForm } from '../Form/PlanForm'
import { useCompanyPlan } from '../hooks/useCompanyPlan'

export const PlanDetails = () => {
  const { companyId } = useParams()
  const { plan, loading } = useCompanyPlan(companyId)
  return (
    <PlanForm plan={plan} loading={loading} />
  )
}

import { getUniqueValues, validateTime } from '@bonitour/common-functions'
import { array, boolean, number, object, string } from 'yup'

const isSomeDataDuplicated = originalArray => {
  const newArray = getUniqueValues(originalArray)
  return originalArray.length !== newArray.length
}

export const bookingMapConfigFormSchema = {
  dailyLimit: number(),
  registries: array().of(object().shape({
    time: string().test('test-validateTime', 'O tempo de duração deve ser um horário valido', validateTime),
    vacancies: number(),
    reservations: number(),
    vacanciesLimit: number().min(0, 'O limite de vagas deve ser maior ou igual a 0'),
    isTimeDisabled: boolean()
  })).test('has-not-equal-hours', 'Horário duplicado', (form) => {
    const hourArray = form.map(({ time }) => time)
    return !isSomeDataDuplicated(hourArray)
  })
}

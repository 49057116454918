import { useState, useCallback, useMemo } from 'react'
import { identity } from '@bonitour/common-functions'
import { useToast } from '@bonitour/components'
import { CREATE_PROVIDER_PAYMENT_STATE, CREATE_PROVIDER_REFUND_STATE, providerTransactionStates } from '../ProviderTransaction/ProviderTransaction.states'
import { ReservationService } from 'core/services/Reservation'
import { useTickets } from './useTickets'

const buildFormFunctions = ({
  createPayment = identity,
  getTicketsToPayFromVendor = identity,
  getPartnershipTicketsToRefund = identity,
  createRefund = identity
}) => ({
  [CREATE_PROVIDER_PAYMENT_STATE]: {
    view: providerTransactionStates[CREATE_PROVIDER_PAYMENT_STATE],
    sucessMessage: 'Pagamento registrado',
    callback: createPayment,
    ticketsGetter: getTicketsToPayFromVendor
  },
  [CREATE_PROVIDER_REFUND_STATE]: {
    view: providerTransactionStates[CREATE_PROVIDER_REFUND_STATE],
    sucessMessage: 'Reembolso registrado',
    callback: createRefund,
    ticketsGetter: getPartnershipTicketsToRefund
  }
})

export const useTransactionProviderForm = (reservationId, createPayment, createRefund) => {
  const { add: addToast } = useToast()

  const [formState, setFormState] = useState(CREATE_PROVIDER_PAYMENT_STATE)
  const [isVisible, setPaymentVisibility] = useState(false)
  const [isButtonEnabled, setButtonStatus] = useState(true)
  const [providerTransactionFormLoading, setProviderTransactionFormLoading] = useState(false)

  const { tickets, clearTickets, getTicketsToPayFromVendor, getPartnershipTicketsToRefund } = useTickets()
  const [vendors, setVendors] = useState([])
  const [transactionFormData, setTransactionForm] = useState({})

  const possibleStates = useMemo(() => buildFormFunctions({ createPayment, getTicketsToPayFromVendor, getPartnershipTicketsToRefund, createRefund } || {}),
    [createPayment, getTicketsToPayFromVendor, getPartnershipTicketsToRefund, createRefund])

  const { callback, sucessMessage, ticketsGetter, view } = useMemo(() => possibleStates[formState], [possibleStates, formState])

  const updateVendors = useCallback(() => ReservationService.getReservationVendors(reservationId).then(setVendors), [reservationId])

  const openTransactionForm = useCallback(
    async (state, data = {}) => {
      const { tickets = [] } = data
      const { ticketsGetter = identity } = possibleStates[state]
      if (state === CREATE_PROVIDER_PAYMENT_STATE) updateVendors()
      if (state === CREATE_PROVIDER_REFUND_STATE) ticketsGetter(tickets)
      setTransactionForm(data)
      setFormState(state)
      setPaymentVisibility(true)
    },
    [possibleStates, updateVendors]
  )

  const closeTransactionForm = useCallback(() => {
    setPaymentVisibility(false)
    setTransactionForm({})
    clearTickets()
    setButtonStatus(true)
  }, [clearTickets])

  const onTransactionSubmit = useCallback(async (data) => {
    setProviderTransactionFormLoading(true)
    if (!isButtonEnabled) {
      setProviderTransactionFormLoading(false)
      return
    }
    try {
      setButtonStatus(false)
      await callback(data)
      setProviderTransactionFormLoading(false)
      addToast(sucessMessage, 'success')
      closeTransactionForm()
    } catch (error) {
      addToast('Ocorreu um erro no registro')
      setProviderTransactionFormLoading(false)
      closeTransactionForm()
    }
  }, [callback, closeTransactionForm, sucessMessage, addToast, isButtonEnabled])

  return {
    isVisible,
    view,
    tickets,
    vendors,
    ticketsGetter,
    transactionFormData,
    openTransactionForm,
    closeTransactionForm,
    onTransactionSubmit,
    providerTransactionFormLoading
  }
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Button, Column, GhostButton, H4, Row, useToast } from '@bonitour/components'
import { BatchTotals } from './BatchTotals'
import { VouchersBatchTable } from './VouchersBatchTable'
import { InfoGuideForm } from './InfoGuideForm'
import { useForm } from '@bonitour/app-functions'
import { identity } from '@bonitour/common-functions'
import { marginRight, marginTop } from 'assets/styles/global'
import { InfoBatchForm } from './InfoBatchForm'
import { batchSchema } from './BatchForm.schema'
import { useCallback, useState } from 'react'

const marginTop40 = marginTop(40)
const marginRight20 = marginRight(20)

export const BatchForm = ({
  batch = {},
  vouchers = [],
  totals = {},
  onSubmit: emitSubmit = identity,
  onCloseClick: emitCloseClick = identity
}) => {
  const { add: addToast } = useToast()
  const { form, errors, onSubmit, utils: { onInputBlur, onInputChange } } = useForm(batch, batchSchema)

  const onValidationError = useCallback(() => addToast('Preencha corretamente o formulário'), [addToast])

  const onBatchSuccess = useCallback(batch => {
    emitSubmit(batch)
    emitCloseClick()
  }, [emitSubmit, emitCloseClick])

  const onClickSubmit = onSubmit(onBatchSuccess, onValidationError)

  const [discountTotalValue, setDiscountTotalValue] = useState(0)

  return (
    <>
      <H4>Informações do lote</H4>

      <Row>
        <Column tabletlandscape={12} desktop={4}>
          <div>
            <BatchTotals totals={totals} discountTotalValue={discountTotalValue} />
            <InfoBatchForm
              batch={form}
              errors={errors}
              onInputBlur={onInputBlur}
              onInputChange={onInputChange}
              totals={totals}
              setDiscountTotalValue={setDiscountTotalValue}/>
          </div>
        </Column>
        <Column tabletlandscape={12} desktop={8}>
          <VouchersBatchTable vouchers={vouchers} />
        </Column>
      </Row>

      <InfoGuideForm guide={form} errors={errors} onInputBlur={onInputBlur} onInputChange={onInputChange} />

      <Row css={marginTop40}>
        <GhostButton css={marginRight20} onClick={emitCloseClick}>Cancelar</GhostButton>
        <Button onClick={onClickSubmit}>Criar lote</Button>
      </Row>
    </>
  )
}

import { useCallback, useState } from 'react'
import { useToast } from '@bonitour/components'
import { usePermissions } from 'hooks/usePermissions'
import { ExceptionalDayService } from 'core/services/ExceptionalDay'

export const useListExceptionalDay = () => {
  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()
  const [loading, setLoading] = useState(false)
  const [exceptionalDays, setExceptionalDay] = useState({})

  const fetchExceptionalDay = useCallback(({ params }) => {
    setLoading(true)
    return ExceptionalDayService.list({ params }).then(({ exceptionalDays }) => setExceptionalDay(exceptionalDays))
      .catch((error) => {
        const hasPermission = handlePermission(error, 'Você não possui permissão de acesso ao dia excepcional')
        hasPermission && addToast('Ocorreu um erro ao realizar a busca')
      })
      .finally(() => setLoading(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    loading,
    exceptionalDays,
    fetchExceptionalDay
  }
}

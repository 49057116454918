import { useEffect, useState } from 'react'
import { BankAccountService } from 'core/services/BankAccount'

export const useBankAccountsOptions = () => {
  const [bankAccountsOptions, setBankAccountsOptions] = useState([])
  const [loading, setLoading] = useState({})

  useEffect(() => {
    setLoading(true)

    BankAccountService.list({ pagination: { currentPage: 1, perPage: 999 } })
      .then((res) => {
        const bankAccounts = res.bankAccounts || []
        setBankAccountsOptions(
          bankAccounts.map(({ id, accountName, bankName }) =>
            ({ value: id, label: `${accountName}${bankName ? ' - ' + bankName : ''}` })
          )
        )
      })
      .finally(() => setLoading(false))
  }, [])

  return {
    bankAccountsOptions,
    loadingBankAccounts: loading
  }
}

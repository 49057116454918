/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Card, Input, InputFormGroup, colors, Button, MaskedInput, DatePicker, Select, useToast, Column, Row } from '@bonitour/components'
import { marginBottom, width100 } from 'assets/styles/global'
import { reservationsStatusOptions } from 'constants/reservationsStatus'
import { useEffect, useState } from 'react'
import { head, identity, maskCpf, tail } from '@bonitour/common-functions'
import { useForm } from '@bonitour/app-functions'
import { ReservationFilterSchema } from './ReservationFilter.schema'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'hooks/useQuery'
import moment from 'moment'
import { useKeyPress } from 'hooks/useKeyPress'
import { sellingChannelOptions } from 'domains/CompanyReports/Sales/constants/sellingChannel'

const marginBottom30 = marginBottom(30)

const card = css`
  padding: 20px;
  background-color: ${colors.gray8};
`

const button = css`
  height: 40px;
  width: 100%;
  margin-top: 20px;
`

export const ReservationsFilter = ({
  partnerList = [],
  onFilterSubmit: emitFilterSubmit = identity
}) => {
  const history = useHistory()
  const { add: addToast } = useToast()
  const [params] = useQuery()

  const {
    initialDate: initialDateParam,
    finalDate: finalDateParam,
    code: codeParam = '',
    name: nameParam = '',
    email: emailParam = '',
    cpf: cpfParam = '',
    status: statusParam = '',
    responsible: responsibleParam = ''
  } = params

  const [initialFilter] = useState({
    initialDate: initialDateParam && moment(initialDateParam, 'YYYY-MM-DD'),
    finalDate: finalDateParam && moment(finalDateParam, 'YYYY-MM-DD'),
    code: codeParam,
    name: decodeURI(nameParam),
    email: emailParam,
    cpf: cpfParam,
    status: statusParam,
    responsible: responsibleParam
  })

  const { form, errors, onSubmit, utils: { onInputBlur, onInputChange } } = useForm(initialFilter, ReservationFilterSchema)
  const { onKeyDown } = useKeyPress()

  const pushQueryParams = () => {
    const queryParams = Object.entries(form).reduce((acc, item) => {
      const key = head(item)
      const value = tail(item)

      if (!value) {
        return acc
      }

      if (key === 'initialDate' || key === 'finalDate') {
        return acc.concat(`${key}=${moment(value).format('YYYY-MM-DD')}&`)
      }

      return acc.concat(`${key}=${tail(item)}&`)
    }, '')

    history.push({ search: `?${queryParams.slice(0, -1)}` })
  }

  const onError = () => addToast('Preencha o formulário corretamente')

  const emitSubmit = () => {
    pushQueryParams()
    onSubmit(emitFilterSubmit(form), onError)
  }

  const onClick = onSubmit(emitSubmit, onError)

  useEffect(() => {
    if (Object.keys(params).length > 0) {
      onSubmit(emitFilterSubmit(initialFilter), onError)
    }
    // eslint-disable-next-line
  }, [])

  const { code = '', email = '', cpf = '', name = '', initialDate, finalDate, status = '', responsible = '', sellingChannel = '' } = form

  return (
    <Card css={[card, marginBottom30]}>
      <Row>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Cód. de reserva' errorMessage={errors.code}>
            <Input
              id='code'
              value={code}
              onChange={onInputChange('code')}
              onBlur={onInputBlur('code')}
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='E-mail' errorMessage={errors.email}>
            <Input
              id='email'
              value={email}
              onChange={onInputChange('email')}
              onBlur={onInputBlur('email')}
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='CPF' errorMessage={errors.cpf}>
            <MaskedInput
              id='cpf'
              value={cpf}
              onChange={onInputChange('cpf')}
              onBlur={onInputBlur('cpf')}
              formatterFunction={maskCpf}
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Cliente' errorMessage={errors.name}>
            <Input
              id='name'
              value={name}
              onChange={onInputChange('name')}
              onBlur={onInputBlur('name')}
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Inicial' errorMessage={errors.initialDate}>
            <DatePicker
              css={width100}
              id='initialDate'
              value={initialDate}
              onChange={onInputChange('initialDate')}
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Final' errorMessage={errors.finalDate}>
            <DatePicker
              css={width100}
              id='finalDate'
              value={finalDate}
              onChange={onInputChange('finalDate')}
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Estado' errorMessage={errors.status}>
            <Select
              id='status'
              value={status}
              onChange={onInputChange('status')}
              onBlur={onInputBlur('status')}
              options={reservationsStatusOptions}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Responsável' errorMessage={errors.responsible}>
            <Select
              id='responsible'
              value={responsible}
              onChange={onInputChange('responsible')}
              onBlur={onInputBlur('responsible')}
              options={partnerList}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Origem' errorMessage={errors.sellingChannel}>
            <Select
              placeholder='Todos'
              options={sellingChannelOptions}
              value={sellingChannel}
              error={errors.sellingChannel}
              onChange={onInputChange('sellingChannel')}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <Button css={button} onClick={onClick}>Pesquisar</Button>
        </Column>
      </Row>
    </Card>
  )
}

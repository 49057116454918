/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Card, InputFormGroup, colors, Button, useToast, Row, Column, DatePicker, Input, Select } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { useForm } from '@bonitour/app-functions'
import { string, date } from 'yup'
import { useValidateDateRange } from 'domains/CompanyReports/hooks/useValidadeDateRange'
import { useKeyPress } from 'hooks/useKeyPress'
import { paymentTypesMap } from 'constants/reservationPaymentTypes'

const button = css`
  height: 40px;
  width: 100%;
  margin-top: 20px;
`
const cardContrast = css`
  margin-bottom: 20px;
  padding: 15px;
  background-color: ${colors.gray13};
  border: none;
`

const width150 = css`
  width: 150px;
  width: 100%;
`

const paymentMethodOptions = [
  { value: '', label: 'Todos' },
  ...Object.entries(paymentTypesMap).map(([key, value]) => ({ value: key, label: value }))
]

export const TouristPaymentsFilterSchema = {
  reservationCode: string().nullable(),
  paymentMethod: string().oneOf([...Object.keys(paymentTypesMap), '']),
  initialSlotDate: date().nullable(),
  finalSlotDate: date().nullable(),
  initialPaymentDate: date().nullable(),
  finalPaymentDate: date().nullable()
}

export const TouristPaymentsFilter = ({
  onFilterSubmit: emitFilterSubmit = identity,
  initialFilters = {}
}) => {
  const { add: addToast } = useToast()
  const { form, errors, onSubmit, utils: { onInputChange } } = useForm(initialFilters, TouristPaymentsFilterSchema)
  const {
    paymentMethod = '',
    reservationCode = '',
    initialSlotDate,
    finalSlotDate,
    initialPaymentDate,
    finalPaymentDate
  } = form
  const { validateDateRange } = useValidateDateRange()
  const { onKeyDown } = useKeyPress()

  const onError = () => addToast('Preencha o formulário corretamente')

  const validateFilters = (filters) => {
    if (validateDateRange(initialPaymentDate, finalPaymentDate) && validateDateRange(initialSlotDate, finalSlotDate)) {
      emitFilterSubmit(filters)
    }
  }

  const onClick = onSubmit(validateFilters, onError)

  const onDateChange = (name) => (value) => onInputChange(name)(value || null)

  return (
    <Card css={cardContrast}>
      <Row>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Data inicial do pagamento' errorMessage={errors.initialPaymentDate}>
            <DatePicker
              value={initialPaymentDate}
              onChange={onDateChange('initialPaymentDate')}
              css={width150}
              allowsEmpty
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Data final do pagamento' errorMessage={errors.finalPaymentDate}>
            <DatePicker
              value={finalPaymentDate}
              onChange={onDateChange('finalPaymentDate')}
              css={width150}
              allowsEmpty
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Data inicial da experiência' errorMessage={errors.initialSlotDate}>
            <DatePicker
              value={initialSlotDate}
              onChange={onDateChange('initialSlotDate')}
              css={width150}
              allowsEmpty
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Data final da experiência' errorMessage={errors.finalSlotDate}>
            <DatePicker
              value={finalSlotDate}
              onChange={onDateChange('finalSlotDate')}
              css={width150}
              allowsEmpty
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Código da reserva' errorMessage={errors.reservationCode}>
            <Input
              value={reservationCode}
              error={errors.reservationCode}
              onChange={onInputChange('reservationCode')}
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Tipo de pagamento' errorMessage={errors.payerName}>
            <Select
              placeholder='Todos'
              options={paymentMethodOptions}
              value={paymentMethod}
              error={errors.paymentMethod}
              onChange={onInputChange('paymentMethod')}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <Button css={button} onClick={onClick}>Pesquisar</Button>
        </Column>
      </Row>
    </Card>
  )
}

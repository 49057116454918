/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { TableHeader, TableHeaderCell, TableHeaderRow } from '@bonitour/components'
import { jsx } from '@emotion/core'

/**
 * Renders the header of the reservations visualized table.
 * @component
 * @returns {JSX.Element} - The rendered ReservationVisualizedTableHeader component.
 */
export const ReservationVisualizedTableHeader = () => {
  return (
    <TableHeader>
      <TableHeaderRow>
        <TableHeaderCell>Código da reserva</TableHeaderCell>
        <TableHeaderCell>Visualizou no app</TableHeaderCell>
        <TableHeaderCell>Check-in feito</TableHeaderCell>
      </TableHeaderRow>
    </TableHeader>
  )
}

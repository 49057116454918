export const invoiceBatchTypeTitleMap = {
  affiliate: 'Afiliado',
  offline: 'Fornecedor externo'
}

export const invoiceBatchTypeOldTitleMap = {
  affiliate: 'Afiliados',
  offline: 'Experiência offline'
}

export const invoiceBatchTypeMap = {
  affiliate: 'Account::Affiliate',
  offline: 'Experiences::OfflineProvider'
}

export const invoiceBatchReverseTypeMap = {
  'Account::Affiliate': 'affiliate',
  'Experiences::OfflineProvider': 'offline'
}

export const affiliateType = 'affiliate'

export const offlineType = 'offline'

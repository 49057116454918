/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import moment from 'moment'
import { DatePicker, flexCenteredSpaceBetweenRow, H2, HeaderPage, useToast } from '@bonitour/components'
import { marginBottom, marginTop, width } from 'assets/styles/global'
import { useActivity } from 'contexts/Activity'
import { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useMapSubscriber } from '../hooks/useMapSubscriber'
import { BookingMapConfigEdit } from './Edit/BookingMapConfigEdit'
import { head, tail } from '@bonitour/common-functions'
import { useListExceptionalDay } from './hooks/useListExceptionalDay'
import { useParamsFilter } from 'hooks/useParamsFilter'

import { ExceptionalDayService } from 'core/services/ExceptionalDay'
import { TRANSPORT_TYPE } from 'constants/activityTypes'
import { useIsLockedService } from 'hooks/domains/useIsLockedService'
import { BookingMapOperationProgress } from 'components/BookingMap/BookingMapOperationProgress'

const marginBottom0 = marginBottom(0)
const marginBottom20 = marginBottom(20)
const fullSizeStyle = width(100)
const marginTop20 = marginTop(20)

export const BookingMapConfig = () => {
  const { push } = useHistory()
  const { add: addToast } = useToast()
  const { activity } = useActivity()
  const today = useMemo(() => new Date(), [])
  const [params, changeParams] = useParamsFilter()
  const { date = moment(today).format('YYYY-MM-DD') } = params || {}
  const { id: activityId, name: activityName, type } = activity || {}
  const dateParam = useMemo(() => [moment(date).format('YYYY-MM-DD')], [date])
  const { registries = {}, fetchTableData } = useMapSubscriber(activity, dateParam, false)
  const { exceptionalDays = [], fetchExceptionalDay } = useListExceptionalDay()

  const {
    isLoading: isLoadingLockedActivity,
    isLocked: isLockedActivity
  } = useIsLockedService(activityId)

  const isTransport = useMemo(() => type === TRANSPORT_TYPE, [type])

  const onBack = () => push(
    `/${isTransport ? 'transport' : 'activity'}/${activityId}/bookings?date=${date}`
  )

  const removeExceptionalDay = exceptionalDayId => () => {
    ExceptionalDayService.remove(exceptionalDayId).then(() => {
      addToast('O dia excepcional foi excluído com sucesso', 'success')
      onBack()
    }).catch(addToast)
  }

  const onDateChange = date => changeParams({ date: moment(date).format('YYYY-MM-DD') })
  const registriesData = useMemo(() => Object.entries(registries).map(item => {
    const time = head(item) || ''
    const totals = tail(item) || []
    const { vacancies: vacanciesData = {}, reservations: reservationsData = {} } = head(Object.values(totals)) || {}
    const exceptionalDayVacancies = head(exceptionalDays)?.vacanciesBySchedule?.find(entry => Object.keys(entry).includes(time))?.[time]
    const reservations = reservationsData?.total || 0
    const vacancies = (exceptionalDayVacancies ? exceptionalDayVacancies - reservations : vacanciesData?.total) || 0
    const vacanciesLimit = exceptionalDayVacancies ?? (vacancies + reservations)

    return {
      time,
      vacancies,
      reservations,
      vacanciesLimit,
      isTimeDisabled: true
    }
  }), [exceptionalDays, registries])

  const hourData = registries ? head(Object.entries(registries)) : {}
  const dayData = hourData ? tail(hourData) : {}
  const data = dayData ? head(Object.entries(dayData)) : {}
  const { limitOfDay = 0 } = data ? tail(data) : {}

  const registriesBase = { dailyLimit: limitOfDay, registries: registriesData }
  const { id: exceptionalDayId } = head(exceptionalDays) || {}

  useEffect(() => {
    const params = {
      serviceId: activityId,
      exceptionalDate: moment(date).format('YYYY-MM-DD')
    }

    fetchTableData()
    fetchExceptionalDay({ params })
    // eslint-disable-next-line
  }, [date, activityId])

  return (
    <div>
      <HeaderPage title='Dia excepcional' css={marginBottom20} onBack={onBack} />

      <div css={flexCenteredSpaceBetweenRow}>
        <H2 css={marginBottom0}>{activityName}</H2>
        <DatePicker value={moment(date)} onChange={onDateChange} />
      </div>

      <BookingMapOperationProgress
        isOperationInProgress={isLockedActivity}
        isLoading={isLoadingLockedActivity}
        customCss={[fullSizeStyle, marginTop20]}
        isTransport={isTransport}
      />

      <BookingMapConfigEdit
        date={date}
        activityId={activityId}
        exceptionalDayId={exceptionalDayId}
        registriesBase={registriesBase}
        onBack={onBack}
        onDelete={removeExceptionalDay}
      />
    </div>
  )
}

export const parseAddress = (activity = {}) => {
  const { address: addressContent = {} } = activity
  const {
    city_id: city,
    state_id: state,
    country_id: country,
    post_code: zipCode,
    street,
    region: district,
    supplement,
    latitude,
    longitude
  } = addressContent || {}

  const latLong = `${latitude},${longitude}`

  return {
    city,
    state,
    country,
    zipCode,
    street,
    district,
    supplement,
    latLong
  }
}

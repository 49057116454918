/**
 * @typedef {Object} Props
 * @property {number} vX
 * @property {number} vY
 * @property {number} height
 * @property {number} width
 */

/**
 * @type {(props:Props) => string}
 */
export const createTicketPath = (props) => {
  const { vX, vY, height, width } = props

  const startX = vX / 2
  const startY = (vY - height) / 2

  const yStep = height / 3
  const cutRadius = yStep / 2

  const ticketD = [
    `M${startX} ${startY}`,
    `h${width / -2}`,
    `v${yStep}`,
    `a${cutRadius} ${cutRadius} 0 0 1 ${cutRadius} ${cutRadius}`,
    `a${cutRadius} ${cutRadius} 0 0 1 -${cutRadius} ${cutRadius}`,
    `v${yStep}`,
    `h${width}`,
    `v-${yStep}`,
    `a${cutRadius} ${cutRadius} 0 0 1 -${cutRadius} -${cutRadius}`,
    `a${cutRadius} ${cutRadius} 0 0 1 ${cutRadius} -${cutRadius}`,
    `v-${yStep}`,
    `h${width / -2}`,
    `M${startX + yStep} ${startY}`,
    `v${cutRadius}`,
    `M${startX + yStep} ${startY + height}`,
    `v-${cutRadius}`
  ]

  return ticketD.join('')
}

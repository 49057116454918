/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useForm } from '@bonitour/app-functions'
import {
  Button,
  Column,
  DatePicker,
  GhostButton,
  InputFormGroup,
  InputMoneyMask,
  Row,
  Select,
  useToast
} from '@bonitour/components'
import { unformatMoney } from '@bonitour/common-functions'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { fullInput, payBatchFormContainer, totalCss, columnMargin, rowMargin, disabledElement } from './PayBatch.styles'
import {
  affiliateBatchPaymentMethodOptions as BATCH_PAYMENT_OPTIONS
} from 'constants/affiliate'
import { paymentMethodsThatRequiredBankAccount } from 'constants/paymentBatch'
import { useListBankAccountByPaymentMethod } from 'domains/BankAccounts/hooks/useListBankAccountByPaymentMethod'
import { useCompany } from 'contexts/Company'
import { buttonsContainerCss, sendingStateCss } from '../Create/CreateBatch.styles'
import { useQuery } from 'hooks/useQuery'
import { useEditPaymentBatch } from '../hooks/useEditPaymentBatch'
import { retrieveBatchPaymentSchema } from '../formSchema/BatchPaymentForm.schema'
import { UploadReceipts } from './UploadReceipts'
import { SafeDate } from '@binamik/js-providers'
import { apiPaymentMethods } from 'constants/paymentMethods'

const emptyForm = {
  transactionDate: new SafeDate().jsDate
}

export const PayBatchForm = ({ paymentBatch, isUnableToPay, totalToPaid }) => {
  const [originBankAccountOptions, setOriginBankAccountOptions] = useState([])

  const [{ payment }, { removeQueryParam }] = useQuery()
  const isPaying = useMemo(() => payment === 'true', [payment])

  const { add: addToast } = useToast()

  const exitPaymentMode = useCallback(() => removeQueryParam('payment'), [removeQueryParam])

  const { loadingPayment, loading, onAddPaymentToBatch } = useEditPaymentBatch()

  const batchPaymentSchema = useMemo(() => retrieveBatchPaymentSchema({ maxSubtotalValue: totalToPaid }), [totalToPaid])

  const {
    form,
    errors: formErrors,
    onSubmit,
    utils: { onInputBlur, onInputChange }
  } = useForm(emptyForm, batchPaymentSchema)
  const { id: companyId } = useCompany()

  const { fetchBankAccountsOptions } = useListBankAccountByPaymentMethod()

  useEffect(() => {
    if (isUnableToPay) {
      exitPaymentMode()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUnableToPay])

  useEffect(() => {
    if (paymentBatch) {
      onInputChange('subtotalValue')(totalToPaid)
      onInputChange('totalValue')(totalToPaid)
      onInputChange('paymentMethod')(paymentBatch.paymentMethod)
      onInputChange('originBankAccountId')(paymentBatch.originBankAccountId)
    }
    // eslint-disable-next-line
  }, [paymentBatch, totalToPaid])

  useEffect(() => {
    if (form.paymentMethod === apiPaymentMethods.cash) return setOriginBankAccountOptions([])
    if (form.paymentMethod && companyId) {
      fetchBankAccountsOptions({ paymentMethod: form.paymentMethod, companyId }).then(setOriginBankAccountOptions)
    }
  }, [companyId, fetchBankAccountsOptions, form.paymentMethod])

  const handleOnSubTotalChange = useCallback(
    (value) => {
      const subTotalValue = unformatMoney(value)
      onInputChange('subtotalValue')(subTotalValue)
    },
    [onInputChange]
  )

  const isBankAccountRequired = useMemo(
    () => paymentMethodsThatRequiredBankAccount.includes(form.paymentMethod),
    [form.paymentMethod]
  )

  const handleSubmit = useCallback(() => {
    onAddPaymentToBatch(paymentBatch.id, {
      paymentMethod: form.paymentMethod,
      transactionDate: form.transactionDate,
      value: form.subtotalValue,
      receipts: form.receipts,
      ...(isBankAccountRequired && { originBankAccountId: form.originBankAccountId })
    }).then(() => {
      onInputChange('receipts')([])
      setOriginBankAccountOptions([])
      addToast('Pagamento registrado com sucesso', 'success')
      exitPaymentMode()
    })
  }, [onAddPaymentToBatch, paymentBatch.id, form.paymentMethod, form.transactionDate, form.subtotalValue, form.receipts, form.originBankAccountId, isBankAccountRequired, onInputChange, addToast, exitPaymentMode])

  const submitFn = onSubmit(handleSubmit, () => addToast('Preencha os dados corretamente'))

  const firstRollDivision = useMemo(() => {
    if (!isBankAccountRequired && !originBankAccountOptions.length) {
      return {
        paymentDate: { phone: 12, desktop: 6 },
        paymentMethod: { phone: 12, desktop: 6 },
        originBankAccountId: { phone: 0, desktop: 0, hidden: true }
      }
    }

    return {
      paymentDate: { phone: 12, desktop: 4 },
      paymentMethod: { phone: 12, desktop: 4 },
      originBankAccountId: { phone: 12, desktop: 4, hidden: false }
    }
  }, [
    isBankAccountRequired,
    originBankAccountOptions.length
  ])

  const isButtonDisabled = useMemo(
    () => Boolean(loading || loadingPayment || form.totalValue === 0),
    [loading, loadingPayment, form.totalValue]
  )

  const [receiptsLabel, setReceitsLabel] = useState('')

  if (!isPaying) return null

  return (
    <div css={payBatchFormContainer} data-subtitle="Novo Pagamento">
      <Row customCss={[rowMargin]}>
        <Column
          phone={firstRollDivision.paymentDate.phone}
          desktop={firstRollDivision.paymentDate.desktop}
          customCss={[columnMargin]}
        >
          <InputFormGroup label="Data de pagamento" errorMessage={formErrors.transactionDate}>
            <DatePicker
              value={form.transactionDate}
              onChange={onInputChange('transactionDate')}
              onBlur={onInputBlur('transactionDate')}
              customCss={[fullInput, disabledElement]}
            />
          </InputFormGroup>
        </Column>
        <Column
          phone={firstRollDivision.paymentMethod.phone}
          desktop={firstRollDivision.paymentMethod.desktop}
          customCss={[columnMargin]}
        >
          <InputFormGroup label="Método de pagamento" errorMessage={formErrors.paymentMethod}>
            <Select
              placeholder="Selecione o método de pagamento"
              options={BATCH_PAYMENT_OPTIONS}
              value={form.paymentMethod}
              error={formErrors.paymentMethod}
              onChange={onInputChange('paymentMethod')}
              disabled={!isPaying}
            />
          </InputFormGroup>
        </Column>
        {firstRollDivision.originBankAccountId.hidden
          ? null
          : (
            <Column
              phone={firstRollDivision.originBankAccountId.phone}
              desktop={firstRollDivision.originBankAccountId.desktop}
              customCss={[columnMargin]}
            >
              <InputFormGroup label="Conta bancária de origem" errorMessage={formErrors.originBankAccountId}>
                <Select
                  placeholder="Todos"
                  options={originBankAccountOptions}
                  value={form.originBankAccountId}
                  error={formErrors.originBankAccountId}
                  onChange={onInputChange('originBankAccountId')}
                  disabled={!isPaying}
                />
              </InputFormGroup>
            </Column>
          )}
      </Row>
      <Row customCss={[rowMargin]}>
        <Column phone={12} desktop={6} customCss={[columnMargin]}>
          <InputFormGroup label="Valor subtotal" errorMessage={formErrors.subtotalValue}>
            <InputMoneyMask
              value={form.subtotalValue}
              onChange={handleOnSubTotalChange}
              onBlur={onInputBlur('subtotalValue')}
              disabled={loading}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={6} customCss={[columnMargin]}>
          <InputFormGroup label="Valor total" customCss={[totalCss]}>
            <InputMoneyMask value={form.totalValue} disabled css={[disabledElement]}/>
          </InputFormGroup>
        </Column>
      </Row>
      <Row customCss={[rowMargin]}>
        <Column phone={12} desktop={12} customCss={[columnMargin]}>
          <InputFormGroup label={receiptsLabel} errorMessage={formErrors.receipts}>
            <UploadReceipts
              isDisabled={loadingPayment}
              onLabelChange={setReceitsLabel}
              onChange={onInputChange('receipts')}
              receipts={form?.receipts || []}
            />
          </InputFormGroup>
        </Column>
      </Row>
      {isPaying && (
        <Row css={buttonsContainerCss}>
          <Column phone={12} desktop={12}>
            <GhostButton onClick={exitPaymentMode} disabled={isButtonDisabled}>
              Cancelar
            </GhostButton>
            <Button onClick={submitFn} customCss={loading ? [sendingStateCss] : []} disabled={isButtonDisabled}>
              {loadingPayment ? 'Confirmando' : 'Confirmar'}
            </Button>
          </Column>
        </Row>
      )}
    </div>
  )
}

import { useState, useCallback } from 'react'
import { useToast } from '@bonitour/components'
import { ReportService } from 'core/services/Reports'
import { usePermissions } from 'hooks/usePermissions'
import { useParamsFilter } from 'hooks/useParamsFilter'

export const useListPaidTickets = () => {
  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()
  const [filters, setFilters] = useParamsFilter()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingTotals, setIsLoadingTotals] = useState(false)
  const [pagination, setPagination] = useState({ currentPage: 1, perPage: 10 })
  const [paidTicketsData, setPaidTicketsData] = useState({
    paidTickets: [], meta: undefined
  })
  const [totals, setTotals] = useState({ totals: undefined })

  const fetchPaidTickets = useCallback(({ filters, newPagination }) => {
    setIsLoading(true)
    setIsLoadingTotals(true)

    return ReportService
      .listPaidTickets({ filters, pagination: newPagination })
      .then(data => {
        setPaidTicketsData(data)
        ReportService.paidTicketsTotals({ filters })
          .then(setTotals)
          .finally(() => setIsLoadingTotals(false))
      })
      .catch((error) => {
        const hasPermission = handlePermission(
          error,
          'Você não possui permissão de acesso ao relatório de tickets pendentes'
        )
        hasPermission && addToast('Ocorreu um erro ao realizar a busca')
      })
      .finally(() => setIsLoading(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changePagination = useCallback((currentPage, perPage) => {
    const params = { currentPage, perPage }
    setPagination(params)
    fetchPaidTickets({ filters, newPagination: params })
    // eslint-disable-next-line
  }, [filters])

  const onFilterSubmit = useCallback(filters => {
    setFilters(filters)
  }, [setFilters])

  return {
    filters,
    onFilterSubmit,
    changePagination,
    pagination,
    paidTickets: paidTicketsData.paidTickets,
    meta: paidTicketsData.meta,
    totals,
    isLoading,
    isLoadingTotals
  }
}

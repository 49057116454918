/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { SidebarSection, CompanyIcon, TicketIcon, LogoutIcon, HandshakeIcon, MenuItem, CollapsableMenuItem, SubMenuItem, LogoMenuItem, UsersIcon, RedeemCodeIcon, ReportsIcon, ChartsIcon, BankCardIcon, MegaphoneIcon, ImageIcon, colors, flexHorizontallyCentered, scrollBar, CloseIcon, flex, CashierIcon } from '@bonitour/components'
import logoOrb from 'assets/svgs/logoOrb.svg'
import logoBinamik from 'assets/svgs/logoBinamik.svg'
import { useLocation, NavLink } from 'react-router-dom'
import { useCallback } from 'react'
import { clickable } from 'assets/styles/global'
import { useFeatureFlag } from 'contexts/Feature'
const { REACT_APP_ACCOUNTS_URL } = process.env

const marginTop30 = css`
  margin-top: 30px;
`

const redirectOnClick = (link) => () => {
  window.location = link
}

const noDecorationLine = css`
 text-decoration: none;
`

const ticketIconSize = css`
  font-size: 21px;
`

const flexSpaceBetweenRow = css`
  justify-content: space-between;
`

const closeIcon = css`
  align-self: center;
  font-size: 24px;
  color: ${colors.gray3};
  cursor: pointer;
  border-radius: var(--small-round-element-radius, 50%);
  height: 30px;
  width: 30px;
`

const logo = css`
  width: 200px;
`

export const tagStyle = css`
  margin: 0 0 0 15px;
  padding: 4px;
  font-size: 13px;
  border-radius: 5px;
  color: ${colors.white};
  background: ${colors.warning};
`

export const sidebarContainer = css`
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  ${scrollBar(5)}
`

export const NavMenuSection = ({
  onNavigation: emitNavigationEvent = identity,
  ...other
}) => {
  const [isPartnershipAccountEnabled] = useFeatureFlag('orb-partnership-account')
  const { pathname } = useLocation()
  const isLinkActive = useCallback((to) => (pathname.indexOf(to) !== -1), [pathname])

  return (
    <SidebarSection {...other} css={sidebarContainer}>
      <span css={[flex, flexSpaceBetweenRow]}>
        <LogoMenuItem logoSrc={logoOrb} css={logo} />
        <CloseIcon css={closeIcon} onClick={emitNavigationEvent} />
      </span>

      <NavLink css={noDecorationLine} to='/my-day'>
        <MenuItem onClick={emitNavigationEvent} icon={ChartsIcon} label='Meu dia' isActive={isLinkActive('/my-day')} />
      </NavLink>

      <div css={[flexSpaceBetweenRow, flexHorizontallyCentered]}>
        <NavLink css={noDecorationLine} to='/reservations'>
          <MenuItem onClick={emitNavigationEvent} icon={TicketIcon} label='Reservas' isActive={isLinkActive('/reservations')} />
        </NavLink>
      </div>

      <NavLink css={noDecorationLine} to='/activities'>
        <MenuItem onClick={emitNavigationEvent} icon={ImageIcon} label='Experiências' isActive={isLinkActive('/activities')} />
      </NavLink>

      <NavLink css={noDecorationLine} to='/external-providers'>
        <MenuItem onClick={emitNavigationEvent} icon={CashierIcon} label='Fornecedores' isActive={isLinkActive('/external-providers')} />
      </NavLink>

      <CollapsableMenuItem icon={HandshakeIcon} label='Parceiros'>
        <NavLink css={noDecorationLine} to='/sellers'>
          <SubMenuItem onClick={emitNavigationEvent} label='Vendedores' isActive={isLinkActive('/sellers')} />
        </NavLink>
        <NavLink css={noDecorationLine} to='/providers'>
          <SubMenuItem onClick={emitNavigationEvent} label='Fornecedores' isActive={isLinkActive('/providers')} />
        </NavLink>
      </CollapsableMenuItem>

      <CollapsableMenuItem icon={BankCardIcon} label='Financeiro'>
        <NavLink css={noDecorationLine} to='/bank-accounts'>
          <SubMenuItem onClick={emitNavigationEvent} label='Dados e taxas bancárias' isActive={isLinkActive('/bank-accounts')} />
        </NavLink>

        <NavLink css={noDecorationLine} to='/invoices-pay'>
          <SubMenuItem onClick={emitNavigationEvent} label='Faturas a pagar' isActive={isLinkActive('/invoices-pay')} />
        </NavLink>

        <NavLink css={noDecorationLine} to='/invoices-receive'>
          <SubMenuItem onClick={emitNavigationEvent} label='Faturas a receber' isActive={isLinkActive('/invoices-receive')} />
        </NavLink>

        <NavLink css={noDecorationLine} to='/acquirers'>
          <SubMenuItem onClick={emitNavigationEvent} label='Adquirentes' isActive={isLinkActive('/acquirers')} />
        </NavLink>

        <NavLink css={noDecorationLine} to='/cashiers/my_cashier'>
          <SubMenuItem onClick={emitNavigationEvent} label='Caixas' isActive={isLinkActive('/cashiers/my_cashier')} />
        </NavLink>

        {isPartnershipAccountEnabled
          ? (
            <NavLink css={noDecorationLine} to='/partnership-accounts'>
              <SubMenuItem onClick={emitNavigationEvent} label='Conta de parceria' isActive={isLinkActive('/cashiers/my_cashier')} />
            </NavLink>
          )
          : null}
        <>
          <NavLink css={noDecorationLine} to='/invoices-batch/affiliate'>
            <SubMenuItem onClick={emitNavigationEvent} label='Fatura lote - Afiliado' isActive={isLinkActive('/cashiers/my_cashier')} />
          </NavLink>
          <NavLink css={noDecorationLine} to='/tour-guide-payment'>
            <SubMenuItem onClick={emitNavigationEvent} label='Fatura lote - Guia' isActive={isLinkActive('/cashiers/my_cashier')} />
          </NavLink>
          <NavLink css={noDecorationLine} to='/invoices-batch/offline'>
            <SubMenuItem onClick={emitNavigationEvent} label='Fatura lote - Fornec. externo' isActive={isLinkActive('/cashiers/my_cashier')} />
          </NavLink>
        </>
      </CollapsableMenuItem>

      <NavLink css={noDecorationLine} to='/reports'>
        <MenuItem onClick={emitNavigationEvent} icon={ReportsIcon} label='Relatórios' isActive={isLinkActive('/reports')} />
      </NavLink>

      <NavLink css={noDecorationLine} to='/tickets/check-in'>
        <MenuItem iconSize={ticketIconSize} onClick={emitNavigationEvent} icon={RedeemCodeIcon} label='Utilizar Ingresso' isActive={isLinkActive('/tickets/check-in')} />
      </NavLink>

      <NavLink css={noDecorationLine} to='/notices'>
        <MenuItem onClick={emitNavigationEvent} icon={MegaphoneIcon} label='Comunicados' isActive={isLinkActive('/notices')} />
      </NavLink>

      <LogoMenuItem onClick={redirectOnClick(`${REACT_APP_ACCOUNTS_URL}/app`)} logoSrc={logoBinamik} css={[marginTop30, clickable]} />

      <MenuItem onClick={redirectOnClick(`${REACT_APP_ACCOUNTS_URL}/app/company/edit`)} icon={CompanyIcon} label='Empresa' isActive={isLinkActive('/company/edit')} />

      <MenuItem onClick={redirectOnClick(`${REACT_APP_ACCOUNTS_URL}/app/users`)} icon={UsersIcon} label='Usuários' isActive={isLinkActive('/users')} />

      <NavLink css={noDecorationLine} to='/logout'>
        <MenuItem onClick={emitNavigationEvent} icon={LogoutIcon} label='Sair' isActive={isLinkActive('/logout')} />
      </NavLink>
    </SidebarSection>
  )
}

import { useEffect, useCallback, useState } from 'react'
import { AcquirerService } from 'core/services/Acquirer'

export const useAcquirersOptions = (companyId) => {
  const [acquirersList, setAcquirersList] = useState([])
  const [loading, setLoading] = useState({})

  const fetchAcquirers = useCallback(({
    companyId,
    transactionType
  }) => {
    setLoading(true)

    return AcquirerService.listAll({ companyId, transactionType })
      .then(setAcquirersList)
      .finally(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    fetchAcquirers({ companyId })
    // eslint-disable-next-line
  }, [companyId])

  return {
    acquirersList,
    loading,
    fetchAcquirers
  }
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { InputFormGroup, Column, Row, Select, Input, flexColumn, colors, Card, Button, scrollBar, H3, GhostButton, ConfirmDialog, AsyncButton, BREAK_POINTS, useToast } from '@bonitour/components'
import { reservationPaymentTypesVisualization } from 'constants/reservationPaymentTypes'
import { hidden, marginTop, marginBottom } from 'assets/styles/global'
import { TicketList } from 'domains/Reservation/Transactions/TicketList'
import { formatMoney, identity } from '@bonitour/common-functions'
import { useCompany } from 'contexts/Company'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { APPROVE_PAYMENT, APPROVE_REFUND, REJECT_PAYMENT, REJECT_REFUND } from './dialog.states'
import { formatISOTime } from 'utils/time'
import { paymentMethodsThatRequiredBankAccount } from 'constants/paymentBatch'
import { useListBankAccountByPaymentMethod } from 'domains/BankAccounts/hooks/useListBankAccountByPaymentMethod'
import { useForm } from '@bonitour/app-functions'
import { providerTransactionDetailSchema } from './ProviderTransactionDetail.schema'

const Dialog = ({ dialogData = {}, isVisible = false }) => {
  const {
    successCallback = identity,
    cancelCallback = identity,
    title = '',
    message = ''
  } = dialogData

  return (
    <ConfirmDialog
      title={title}
      message={message}
      isVisible={isVisible}
      successCallback={successCallback}
      cancelCallback={cancelCallback}
    />
  )
}

const marginTop30 = marginTop(30)
const marginBottom20 = marginBottom(20)
const marginBottom60 = marginBottom(60)

const fullInput = css`
  width: 100%;
`

const amountDueCard = css`
  min-width: 130px;
  display: inline-flex;
  padding: 20px;
`

const amountDueLabel = css`
  font-size: 12px;
  color: ${colors.gray5};
  margin-bottom: 5px;
`

const amountDueStyle = css`
  font-size: 21px;
  color: ${colors.primary};
  font-weight: bold;
`

const overflowHidden = css`
  overflow: hidden;
`

const container = css`
  width: 100%;
  max-height: 350px;
  overflow: auto;
  ${scrollBar(5)};
`

const buttonsContainer = css`
  margin-top: 20px;

  button {
    margin: 5px;
  }

  @media (max-width: ${BREAK_POINTS.tableUp}) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`

export const ProviderTransactionDetail = ({
  detail = {},
  labels = 'pagamento',
  amountDueValueLabel = 'Valor pago',
  loading = false,
  onClose = identity,
  onApproval: emitApproval = identity,
  onRefundApproval: emitRefundApproval = identity,
  onRejection: emitRejection = identity,
  onRefundRejection: emitRefundRejection = identity
}) => {
  const {
    id,
    value = '',
    method,
    installments,
    aut,
    doc,
    status,
    state,
    receipt,
    companyDestinationId,
    tickets = [],
    acquirerName,
    acquirer,
    brand,
    isRefund,
    date,
    destinationBankAccount = null,
    originBankAccount = null,
    payerCompanyId = null
  } = detail
  const { id: companyId } = useCompany()
  const { isCredit, isRefundingPending, isPaymentPending } = useMemo(() => ({
    isCredit: method === 'credit',
    isRefundingPending: status === 'pending',
    isPaymentPending: state === 'pending'
  }), [method, state, status])
  const hasDate = useMemo(() => Boolean(date), [date])
  const [dialogType, setDialogType] = useState('')
  const openReceipt = () => window.open(receipt, '_blank', 'noopener noreferrer')

  const { add: addToast } = useToast()

  const initialValues = useMemo(() => ({
    destinationBankAccount: destinationBankAccount || undefined
  }), [destinationBankAccount])

  const {
    form: { destinationBankAccount: destinationBankAccountForm },
    onSubmit,
    utils: {
      onInputBlur,
      onInputChange
    }
  } = useForm(initialValues, providerTransactionDetailSchema)

  const canShowBankAccountFields = useMemo(() => {
    return paymentMethodsThatRequiredBankAccount.includes(method)
  }, [method])

  const [isConfirmActionVisible, setConfirmActionVisible] = useState(false)
  const toggleConfirmActionVisible = useCallback((action, type) => {
    setDialogType(type)
    setConfirmActionVisible(action)
  }, [])

  const onError = useCallback(() => addToast('Preencha corretamente o formulário'), [addToast])

  const handleSubmit = useCallback((action, type) => onSubmit(() =>
    toggleConfirmActionVisible(action, type), onError),
  [onSubmit, onError, toggleConfirmActionVisible])

  const toggleConfirmActionHidden = () => setConfirmActionVisible(false)

  const onPaymentApproval = useCallback(() => {
    const bankAccount = canShowBankAccountFields ? destinationBankAccountForm : null
    emitApproval(id, bankAccount)
    toggleConfirmActionHidden()
  }, [canShowBankAccountFields, destinationBankAccountForm, emitApproval, id])

  const onRefundApproval = () => {
    emitRefundApproval(id)
    toggleConfirmActionHidden()
  }

  const onPaymentRejection = () => {
    emitRejection(id)
    toggleConfirmActionHidden()
  }

  const onRefundRejection = () => {
    emitRefundRejection(id)
    toggleConfirmActionHidden()
  }

  const formatAcquirer = (acquirer, brand) => {
    return acquirer + ' - ' + brand
  }

  const {
    bankAccountOptionsForVendorOrSupplier,
    fillBankAccountOptionsForVendorOrSupplier
  } = useListBankAccountByPaymentMethod()

  const isCompany = useMemo(() => companyId === companyDestinationId, [companyId, companyDestinationId])

  useEffect(() => {
    if (canShowBankAccountFields && payerCompanyId && companyDestinationId) {
      fillBankAccountOptionsForVendorOrSupplier(method, payerCompanyId, companyDestinationId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canShowBankAccountFields, method])

  const dialogStates = {
    [APPROVE_PAYMENT]: {
      title: 'Confirmar pagamento',
      message: 'Ao aceitar este pagamento, todos os ingressos associados serão confirmados. Essa ação não pode ser revertida. Deseja continuar?',
      successCallback: () => onPaymentApproval(),
      cancelCallback: toggleConfirmActionHidden
    },
    [REJECT_PAYMENT]: {
      title: 'Recusar pagamento',
      message: 'Ao recusar este pagamento, os ingressos associados não mudarão de estado. Essa ação não pode ser revertida. Deseja continuar?',
      successCallback: () => onPaymentRejection(),
      cancelCallback: toggleConfirmActionHidden
    },
    [APPROVE_REFUND]: {
      title: 'Confirmar estorno',
      message: 'Você deseja confirmar o registro de reembolso realizado pelo parceiro?',
      successCallback: () => onRefundApproval(),
      cancelCallback: toggleConfirmActionHidden
    },
    [REJECT_REFUND]: {
      title: 'Recusar estorno',
      message: 'Você deseja recusar o registro de reembolso realizado pelo parceiro.',
      successCallback: () => onRefundRejection(),
      cancelCallback: toggleConfirmActionHidden
    }
  }

  return (
    <>
      <Dialog
        dialogData={dialogStates[dialogType]}
        isVisible={isConfirmActionVisible}
      />

      <div css={marginBottom60}>
        <Row>
          <Column phone={12} desktop={2}>
            <Card css={amountDueCard}>
              <div css={flexColumn}>
                <p css={amountDueLabel}>{amountDueValueLabel}</p>
                <span css={amountDueStyle}>{formatMoney(value) || 0}</span>
              </div>
            </Card>
          </Column>
          <Column phone={isCredit ? 6 : 12} desktop={3}>
            <InputFormGroup label={`Forma de ${labels}`} css={fullInput}>
              <Select
                options={reservationPaymentTypesVisualization}
                value={method}
                css={fullInput}
                disabled
              />
            </InputFormGroup>
          </Column>

          <Column phone={isCredit ? 6 : 12} desktop={2} css={!hasDate && hidden}>
            <InputFormGroup label="Data do pagamento">
              <Input disabled value={formatISOTime(date, 'DD/MM/YYYY')} css={fullInput} />
            </InputFormGroup>
          </Column>

          <Column phone={12} desktop={3} css={!canShowBankAccountFields && hidden}>
            <InputFormGroup label="Conta bancária de origem">
              <Select
                options={bankAccountOptionsForVendorOrSupplier.origin}
                value={originBankAccount}
                disabled={true} />
            </InputFormGroup>
          </Column>

          <Column phone={12} desktop={3} css={!canShowBankAccountFields && hidden}>
            <InputFormGroup label="Conta bancária de destino">
              <Select
                options={bankAccountOptionsForVendorOrSupplier.destination}
                value={destinationBankAccountForm || destinationBankAccount}
                onBlur={onInputBlur('destinationBankAccount')}
                onChange={onInputChange('destinationBankAccount')}
                disabled={!isCompany || !isPaymentPending} />
            </InputFormGroup>
          </Column>

          <Column phone={isCredit ? 6 : 12} desktop={2} css={!isCredit && hidden}>
            <InputFormGroup label='Adquirente e bandeira'>
              <Input value={formatAcquirer(acquirerName || acquirer, brand)} css={fullInput} disabled />
            </InputFormGroup>
          </Column>

          <Column phone={isCredit ? 6 : 12} desktop={1} css={!isCredit && hidden}>
            <InputFormGroup label='Parcelas'>
              <Input value={installments} css={fullInput} disabled />
            </InputFormGroup>
          </Column>

          {Boolean(receipt) &&
            <Column phone={6} desktop={2}>
              <InputFormGroup label='Comprovante'>
                <Button onClick={openReceipt}>Clique para abrir</Button>
              </InputFormGroup>
            </Column>}
        </Row>

        <Row>
          {Boolean(aut) &&
            <Column phone={6} desktop={2}>
              <InputFormGroup label='Aut'>
                <Input value={aut} css={fullInput} disabled />
              </InputFormGroup>
            </Column>}

          {Boolean(doc) &&
            <Column phone={6} desktop={2}>
              <InputFormGroup label='Doc'>
                <Input value={doc} css={fullInput} disabled />
              </InputFormGroup>
            </Column>}
        </Row>

        {Boolean(tickets.length) &&
          <Row>
            <Column phone={12} desktop={12} css={marginBottom20}>
              <div css={[flexColumn, overflowHidden]}>
                <H3 css={marginTop30}>Ingressos pagos</H3>
                <div css={container}>
                  <TicketList tickets={tickets} isSelectable={false} isProvider />
                </div>
              </div>
            </Column>
          </Row>}

        <div css={buttonsContainer}>
          <GhostButton onClick={onClose}>Voltar</GhostButton>
          {isCompany && isPaymentPending &&
            <>
              <GhostButton onClick={handleSubmit('rejection', REJECT_PAYMENT)} disabled={loading}>Recusar</GhostButton>
              <AsyncButton onClick={handleSubmit('approval', APPROVE_PAYMENT)} disabled={loading}>Confirmar</AsyncButton>
            </>}

          {isCompany && isRefundingPending && isRefund &&
            <>
              <GhostButton onClick={handleSubmit('refundRejection', REJECT_REFUND)} disabled={loading}>Recusar</GhostButton>
              <AsyncButton onClick={handleSubmit('refundApproval', APPROVE_REFUND)} disabled={loading}>Confirmar</AsyncButton>
            </>}
        </div>
      </div>
    </>
  )
}

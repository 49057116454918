import { TicketsService } from 'core/services/Tickets'
import { useState } from 'react'
import { useToast } from '@bonitour/components'
import { useReservation } from 'hooks/context/Reservation'

export const useTicketConfirm = () => {
  const [loading, setLoading] = useState(false)
  const { add: addToast } = useToast()
  const { fetchReservationTickets } = useReservation()

  const ticketConfirm = async (ticketId, confirmData) => {
    setLoading(true)
    return TicketsService.ticketConfirm(ticketId, confirmData)
      .then(() => {
        fetchReservationTickets()
        addToast('Ação realizada com sucesso', 'success')
      })
      .catch((error) => {
        const errorMessage = error?.data?.errors_msg
        const errorCode = error?.data?.errors

        const errorMessages = {
          default: `Aconteceu algum problema, tente novamente. ${errorCode}`
        }
        addToast(errorMessages[errorMessage] ?? errorMessages.default)
      }).finally(() => setLoading(false))
  }

  return {
    ticketConfirm,
    loading
  }
}

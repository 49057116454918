/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { marginBottom, width100 } from 'assets/styles/global'
import { InputMoneyMask, flexHorizontallyCentered, H3, Input, HorizontalInputFormGroup, BREAK_POINTS } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'

const marginBottom20 = marginBottom(20)

const valueStyle = css`
  margin-left: 25px;

@media (max-width: ${BREAK_POINTS.tableUp}) {
  margin-left: 0;
  margin-top: 10px;
}
`

const sameLineInput = css`
  margin-left: 10px;
`

const littleInput = css`
  width: 160px;
`

const flexColumnStart = css`
  @media (max-width: ${BREAK_POINTS.tableUp}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const FeeBasicForm = ({
  feeBasic = {},
  feeBasicErrors = {},
  onChange: emitChangeEvent = identity,
  onInputBlur: emitInputBlur = identity
}) => {
  const { name = '', price = '' } = feeBasic

  return (
    <>
      <H3 css={marginBottom20}>Resumo</H3>
      <div css={[flexHorizontallyCentered, marginBottom20, flexColumnStart]}>
        <div css={[flexHorizontallyCentered]}>
          <p>Nome</p>
          <HorizontalInputFormGroup css={sameLineInput} errorMessage={feeBasicErrors.name}>
            <Input css={width100} value={name} placeholder='Ex. Baixa Temporada' onChange={emitChangeEvent('feeBasic.name')} onBlur={emitInputBlur('feeBasic.name')} />
          </HorizontalInputFormGroup>
        </div>
        <div css={[flexHorizontallyCentered, valueStyle]}>
          <p>Valor</p>
          <HorizontalInputFormGroup css={sameLineInput} errorMessage={feeBasicErrors.price}>
            <InputMoneyMask
              css={littleInput}
              value={price}
              onChange={emitChangeEvent('feeBasic.price')}
              onBlur={emitInputBlur('feeBasic.price')}
            />
          </HorizontalInputFormGroup>
        </div>
      </div>
    </>
  )
}

import { unformatMoney } from '@bonitour/common-functions'
import { metaModel } from './meta'
import { ACCOUNT_TYPES } from 'domains/BankAccounts/constants/bankAccountConstants'
import { apiPaymentMethods } from 'constants/paymentMethods'

/* eslint-disable camelcase */
export const BankAccountModel = {
  single: (bankAccount = {}) => {
    const {
      bankCode,
      accountName,
      agencyNumber,
      agencyDigit,
      accountNumber,
      accountDigit,
      accountType,
      enabled,
      fees = []
    } = bankAccount

    const isCompanyCashier = accountType === ACCOUNT_TYPES.COMPANY_CASHIER

    const paymentMethods = fees
      .filter(({ paymentMethod }) => {
        if (isCompanyCashier) {
          return paymentMethod === apiPaymentMethods.cash
        }
        return true
      }).reduce(
        (acc, { paymentMethod, value }) => ({
          ...acc, [paymentMethod]: unformatMoney(String(value))
        }), {})

    const {
      bank_number,
      bank_agency_number,
      bank_agency_vd,
      bank_account_number,
      bank_account_vd
    } = (function () {
      if (isCompanyCashier) {
        return {
          bank_number: '000',
          bank_agency_number: '0',
          bank_agency_vd: '0',
          bank_account_number: '0',
          bank_account_vd: '0'
        }
      }
      return {
        bank_number: bankCode,
        bank_agency_number: agencyNumber,
        bank_agency_vd: agencyDigit,
        bank_account_number: accountNumber,
        bank_account_vd: accountDigit
      }
    }())

    return {
      bank_number,
      bank_agency_number,
      bank_agency_vd,
      bank_account_number,
      bank_account_vd,
      enabled,
      bank_name: accountName,
      bank_account_type: accountType,
      payment_methods: paymentMethods
    }
  },
  list: ({ pagination }) => {
    const params = [
      metaModel(pagination)
    ]
    return params
  }
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Button, colors } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'

const buttonsContainer = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin-top: 20px;

  button {
    border: 1px solid ${colors.primary};
  }

  button + button {
    margin-left: 12px;
  }
`

const outlinedButton = css`
  background-color: transparent;
  color: ${colors.primary};
`

export const IntegrationsFormButtons = ({
  isEditing = true,
  onCancel = identity,
  onConfirm = identity,
  onEdit = identity
}) => {
  if (!isEditing) {
    return (
      <div css={buttonsContainer}>
        <Button
          type='button'
          onClick={onEdit}
        >
          Editar
        </Button>
      </div>
    )
  }

  return (
    <div css={buttonsContainer}>
      <Button
        type='button'
        customCss={[outlinedButton]}
        onClick={onCancel}
      >
        Cancelar
      </Button>

      <Button
        type='submit'
        onClick={onConfirm}
      >
        Salvar
      </Button>
    </div>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Button, Column, GhostButton, InputFormGroup, InputMoneyMask, Row, Select, Textarea, UploadInline, useToast } from '@bonitour/components'
import { useForm } from '@bonitour/app-functions'
import { identity } from '@bonitour/common-functions'
import { marginRight, marginTop } from 'assets/styles/global'
import { entrySchema } from './PartnershipAccountEntryForm.schema'
import { useEffect, useMemo, useState } from 'react'
import { useListBankAccountByPaymentMethod } from 'domains/BankAccounts/hooks/useListBankAccountByPaymentMethod'
import { useCompany } from 'contexts/Company'
import {
  partnershipPaymentTypesOptions,
  partnershipCreditPaymentTypeOptions,
  partnershipDebitPaymentTypeOptions
} from 'domains/Invoice/constants/partnershipCreditPaymentStatus'

const marginTop40 = marginTop(40)
const marginRight20 = marginRight(20)
const marginRight10 = marginRight(10)

const typeOptions = [
  { value: 'credit', label: 'Crédito' },
  { value: 'debit', label: 'Débito' }
]

export const PartnershipAccountEditForm = ({
  sellers = [],
  transaction = {},
  entry = {},
  isSelledDisabled = false,
  canDebitType,
  creditorId,
  balance,
  updateTransaction: emitUpdateTransaction = identity,
  onCloseClick: emitCloseClick = identity
}) => {
  const { add: addToast } = useToast()
  const [originBankAccountOptions, setOriginBankAccountOptions] = useState([])
  const [destinationBankAccountOptions, setDestinationBankAccountOptions] = useState([])
  const { form, errors, onSubmit, utils: { onInputBlur, onInputChange } } = useForm(transaction, entrySchema(balance))
  const { seller, type, value, description = '', originBankAccount = '', destinationBankAccount = '', paymentType = '', upload = [] } = form
  const { fetchBankAccountsOptions } = useListBankAccountByPaymentMethod()
  const { id: vendorId } = useCompany()

  const onValidationError = () => addToast('Preencha corretamente o formulário')

  const onSuccess = (partnershipAccountInfo) => {
    emitUpdateTransaction(partnershipAccountInfo)
    emitCloseClick()
  }

  const isBankAccountShow = useMemo(() => {
    if (paymentType === 'bank_billet' || paymentType === 'bank_transfer' || paymentType === 'pix') {
      return true
    }
    return false
  }, [paymentType])

  useEffect(() => {
    if (paymentType && entry?.seller) {
      fetchBankAccountsOptions({ paymentMethod: paymentType, companyId: entry.seller }).then(setOriginBankAccountOptions)
    }

    if (paymentType && vendorId) {
      fetchBankAccountsOptions({ paymentMethod: paymentType, companyId: creditorId }).then(setDestinationBankAccountOptions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentType, fetchBankAccountsOptions, setOriginBankAccountOptions, entry.seller, creditorId])

  const onClickSubmit = onSubmit(onSuccess, onValidationError)

  const attachmentUrl = useMemo(() => upload[0]?.src, [upload])
  const openAttachment = () => window.open(attachmentUrl, '_blank')
  const removeAttachment = () => onInputChange('upload')([])

  const paymentMethods = useMemo(() => [
    ...partnershipPaymentTypesOptions,
    ...partnershipDebitPaymentTypeOptions,
    ...partnershipCreditPaymentTypeOptions
  ], [])

  return (
    <>
      <Row>
        <Column phone={12} desktop={3}>
          <InputFormGroup label='Parceiro' errorMessage={errors.seller}>
            <Select
              placeholder='Parceiro'
              options={sellers}
              value={seller}
              error={errors.seller}
              onChange={onInputChange('seller')}
              disabled
            />
          </InputFormGroup>
        </Column>

        {canDebitType &&
          <Column phone={12} desktop={3}>
            <InputFormGroup label='Tipo' errorMessage={errors.type}>
              <Select
                placeholder='Tipo'
                options={typeOptions}
                value={type}
                error={errors.type}
                onChange={onInputChange('type')}
                disabled
              />
            </InputFormGroup>
          </Column>
        }

        <Column phone={12} desktop={3}>
          <InputFormGroup id='type' errorMessage={errors.paymentType} label='Forma de pagamento'>
            <Select options={paymentMethods} value={paymentType} onChange={onInputChange('paymentType')} disabled />
          </InputFormGroup>
        </Column>

        {isBankAccountShow &&
          <>
            <Column phone={12} desktop={3}>
              <InputFormGroup id='originBankAccount' errorMessage={errors.originBankAccount} label='Conta bancária de origem'>
                <Select options={originBankAccountOptions} value={originBankAccount} onChange={onInputChange('originBankAccount')} disabled />
              </InputFormGroup>
            </Column>
            <Column phone={12} desktop={3}>
              <InputFormGroup id='destinationBankAccount' errorMessage={errors.destinationBankAccount} label='Conta bancária de destino'>
                <Select options={destinationBankAccountOptions} value={destinationBankAccount} onChange={onInputChange('destinationBankAccount')} disabled />
              </InputFormGroup>
            </Column>
          </>
        }

        <Column phone={12} desktop={3}>
          <InputFormGroup label='Valor' errorMessage={errors.value}>
            <InputMoneyMask
              value={parseFloat(value)}
              onChange={onInputChange('value')}
              onBlur={onInputBlur('value')}
              disabled
            />
          </InputFormGroup>
        </Column>

        <Column phone={12} desktop={3}>
          <InputFormGroup label='Anexar comprovante (max 15mb)'>
            {attachmentUrl
              ? (
                <div>
                  <Button onClick={openAttachment} customCss={[marginRight10]}>Ver anexo</Button>
                  {!isSelledDisabled && (
                    <GhostButton onClick={removeAttachment}>Remover anexo</GhostButton>
                  )}
                </div>
              )
              : (
                <UploadInline
                  files={upload}
                  limitMbSize={15}
                  error={errors.upload}
                  accept='image/png, image/jpeg, application/pdf'
                  onChange={onInputChange('upload')}
                  onBlur={onInputBlur('upload')}
                  disabled={isSelledDisabled}
                />
              )
            }
          </InputFormGroup>
        </Column>
      </Row>

      <Row>
        <Column phone={12} desktop={12}>
          <InputFormGroup label='Descrição' errorMessage={errors.description}>
            <Textarea
              value={description}
              onChange={onInputChange('description')}
              onBlur={onInputBlur('description')}
              disabled={isSelledDisabled}
            />
          </InputFormGroup>
        </Column>
      </Row>

      <Row css={marginTop40}>
        <GhostButton css={marginRight20} onClick={emitCloseClick}>Cancelar</GhostButton>
        <Button onClick={onClickSubmit} disabled={isSelledDisabled}>Confirmar</Button>
      </Row>
    </>
  )
}

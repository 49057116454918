/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { Button, Column, GhostButton, Modal, Textarea, useToast } from '@bonitour/components'
import { customTextArea, marginBottom20, marginLeft10 } from './Observation.style'
import { useCallback } from 'react'
import { useObservationForm } from './hooks/useObservationForm'

export const ObservationModal = ({
  isObservationModalVisible = false,
  onClose: emitCloseClick,
  onSuccess = identity,
  observation = ''
}) => {
  const { add: addToast } = useToast()

  const {
    form,
    errors,
    onInputBlur,
    onInputChange,
    onSubmit
  } = useObservationForm({ observation, onSuccess, addToast })

  const onObservationInputBlur = useCallback(observation => onInputBlur(observation), [onInputBlur])
  const onObservationInputChange = useCallback(observation => onInputChange(observation), [onInputChange])

  return (
    <Modal title='Observação' isVisible={isObservationModalVisible} onCloseClick={emitCloseClick}>
      <Column css={marginBottom20}>
        <Textarea
          id='observation'
          value={form.observation || ''}
          error={errors.observation}
          onChange={onObservationInputChange('observation')}
          onBlur={onObservationInputBlur('observation')}
          css={customTextArea}
        />
      </Column>
      <div>
        <GhostButton onClick={emitCloseClick}>Cancelar</GhostButton>
        <Button onClick={onSubmit} css={marginLeft10}>Salvar alterações</Button>
      </div>
    </Modal>
  )
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { TableBodyRow, TableBodyCell, CircleThumbnail } from '@bonitour/components'
import { formatMoney, formatDate } from '@bonitour/common-functions'
import { reservationPaymentStatusMap } from 'constants/reservationPaymentStatus'
import { reservationPaymentTypesMap } from 'constants/reservationPaymentTypes'
import { capitalize, circleColor } from 'assets/styles/global'

export const ReservationTableRow = ({
  transaction
}) => {
  const {
    type,
    value,
    method,
    installments,
    autDoc,
    state,
    hour,
    date
  } = transaction

  const status = reservationPaymentStatusMap[state]

  return (
    <TableBodyRow>
      <TableBodyCell css={capitalize}>{type}</TableBodyCell>
      <TableBodyCell>{autDoc || '-'}</TableBodyCell>
      <TableBodyCell>{date ? formatDate(date) : '-'}</TableBodyCell>
      <TableBodyCell>{hour || '-'}</TableBodyCell>
      <TableBodyCell>{value ? formatMoney(value) : '-'}</TableBodyCell>
      <TableBodyCell>{method ? reservationPaymentTypesMap(method, installments) : '-'}</TableBodyCell>
      <TableBodyCell>
        <CircleThumbnail size={10} css={Boolean(status) && circleColor(status.color)} />
        {status ? status.label : '-'}
      </TableBodyCell>
    </TableBodyRow>
  )
}

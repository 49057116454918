import { css } from '@emotion/core'
import { BREAK_POINTS, colors, flexRowCentered } from '@bonitour/components'

export const width100 = css`
  width: 100%;
`

export const cardTaxesStyle = css`
  background-color: ${colors.gray14};
  padding: 20px;
  border: none;
`

export const feeLabel = css`
  white-space: nowrap;
`

export const feeInput = css`
  ${flexRowCentered};  
  justify-content: flex-start;
  width: 100%;

  @media (max-width: ${BREAK_POINTS.tableUp}){
    flex-wrap: wrap;
  }
`

export const feeValue = css`
  @media (max-width: ${BREAK_POINTS.tableUp}){
    width: 100%;
  }
`

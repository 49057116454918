import { useCompany } from 'contexts/Company'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { VendorsService } from 'services/Vendors/Service'

export const useVendorsList = (hasNullOption = true, nullOptionLabel = 'Todos') => {
  const { id: companyId } = useCompany()
  const [vendors, setVendors] = useState([])

  const companyFiltered = useCallback((vendors) => vendors.filter(({ id }) => id !== companyId), [companyId])

  const vendorsOptions = useMemo(() => {
    const options = vendors.reduce((acc, { id, name }) => acc.concat({ value: id, label: name }), [])
    if (hasNullOption) return [{ value: '', label: nullOptionLabel }, ...options]
    return options
  }, [vendors, hasNullOption, nullOptionLabel])

  const vendorsOptionsCompanyFiltered = useMemo(() => {
    const filteredVendors = companyFiltered(vendors)
    const options = filteredVendors.reduce((acc, { id, name }) => acc.concat({ value: id, label: name }), [])
    if (hasNullOption) return [{ value: '', label: nullOptionLabel }, ...options]
    return options
  }, [companyFiltered, vendors, hasNullOption, nullOptionLabel])

  useEffect(() => { VendorsService.getList().then(setVendors) }, [])

  return {
    vendors,
    vendorsOptions,
    vendorsOptionsCompanyFiltered
  }
}

import { useState } from 'react'
import { useToast } from '@bonitour/components'
import { PartnershipAccountService } from 'core/services/PartnershipAccount'

export const usePartnershipAccountCreateEntry = () => {
  const [loading, setLoading] = useState(false)
  const { add: addToast } = useToast()

  const createEntry = async entry => {
    setLoading(true)
    PartnershipAccountService.createEntry(entry)
      .then(() => { addToast('Lançamento realizado com sucesso', 'success') })
      .catch(() => addToast('Ocorreu um erro no lançamento'))
      .finally(() => setLoading(false))
  }

  return {
    submit: createEntry,
    loading
  }
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { BatchService } from 'core/services/Batch'
import { useRef, useState } from 'react'
import { useToast } from '@bonitour/components'
import { useListBatches } from '../hooks/useListBatches'
import { GeneratedBatchesFilter } from './GeneratedBatchesFilter'
import { GeneratedBatchesList } from './GeneratedBatchesList'

export const GeneratedBatches = () => {
  const { add: addToast } = useToast()
  const {
    filters,
    isLoading,
    pagination,
    onChangePagination,
    vouchers,
    meta,
    fetchVouchers,
    onFilterSubmit
  } = useListBatches()
  const { current: initialFilters } = useRef(filters)

  const [batch, setBatch] = useState({})
  const [isBatchDetailVisible, setBatchDetailVisibility] = useState(false)
  const batchDetailVisible = () => setBatchDetailVisibility(true)
  const batchDetailHidden = () => setBatchDetailVisibility(false)
  const onBatchClick = id => () => BatchService.getById(id).then(setBatch).finally(() => batchDetailVisible())

  const onBatchDelete = id => BatchService.remove(id).then(() => {
    fetchVouchers({ pagination, filters })
    addToast('Lote excluído com sucesso', 'success')
  })

  const onSubmit = batch => BatchService.payBatch(batch?.id, batch)
    .then(() => {
      fetchVouchers({ pagination, filters })
      addToast('O pagamento foi registrado com sucesso', 'success')
    })
    .catch(() => addToast('Ocorreu um erro no pagamento do lote'))

  return (
    <>
      <GeneratedBatchesFilter initialFilters={initialFilters} onFilterSubmit={onFilterSubmit} isLoading={isLoading} />
      <GeneratedBatchesList
        loading={isLoading}
        batches={vouchers}
        pagesQuantity={meta?.totalPages}
        total={meta?.total}
        batch={batch}
        isBatchDetailVisible={isBatchDetailVisible}
        batchDetailHidden={batchDetailHidden}
        onBatchClick={onBatchClick}
        onPagination={onChangePagination}
        onBatchDelete={onBatchDelete}
        onSubmit={onSubmit}
      />
    </>
  )
}

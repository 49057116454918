import { useEffect, useState } from 'react'
import { useToast } from '@bonitour/components'
import { CommuniquesCategoriesService } from 'services/Communiques/Categories/Service'

export const useCategories = () => {
  const { add: addToast } = useToast()

  const [categories, setCategories] = useState([])

  useEffect(() => {
    CommuniquesCategoriesService.listCategories()
      .then(setCategories)
      .catch(() => addToast('Ocorreu um erro ao carregar as categorias'))
  }, [addToast])

  return { categories }
}

/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import {
  Card,
  Input,
  InputFormGroup,
  Button,
  Row,
  Column,
  DatePicker,
  H1,
  GhostButton,
  InputMoneyMask,
  InputWithSuffix
} from '@bonitour/components'
import { useForm } from '@bonitour/app-functions'
import { useMemo } from 'react'
import { actionRow, cardSpacing, inputOffset } from 'domains/CashierHandling/Create/CashierHandlingForm.styles'
import { validationSchema } from './PlanForm.schema'
import { useHistory } from 'react-router'

export const PlanForm = ({ loading, handleSubmit, onError, plan, onCancel }) => {
  const id = plan?.id
  const history = useHistory()
  const initialForm = useMemo(
    () =>
      plan ?? {
        name: '',
        activitiesNumber: '',
        activityCost: '',
        orbSellerTax: '',
        orbVendorTax: '',
        xpertSellerTax: '',
        xpertVendorTax: '',
        payVendorTax: '',
        paySellerTax: '',
        expirationDate: '',
        hiringDate: '',
        monthlyPrice: ''
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  )

  const {
    form,
    errors,
    onSubmit,
    utils: { onInputChange, onInputBlur }
  } = useForm(initialForm, validationSchema)

  const onConfirm = onSubmit(handleSubmit, onError)

  const {
    name,
    activitiesNumber,
    activityCost,
    orbSellerTax,
    orbVendorTax,
    xpertSellerTax,
    xpertVendorTax,
    payVendorTax,
    paySellerTax,
    expirationDate,
    hiringDate,
    monthlyPrice
  } = form

  return (
    <>

      <Row>
        <H1>Plano</H1>
        <GhostButton style={{ marginLeft: 'auto' }} onClick={() => history.goBack()}>Voltar</GhostButton>
      </Row>

      <Card css={cardSpacing}>

        <Row>
          <Column phone={12} desktop={6}>
            <InputFormGroup label='Nome' errorMessage={errors.name} css={inputOffset}>
              <Input
                value={name}
                error={errors.name}
                onChange={onInputChange('name')}
                onBlur={onInputBlur('name')}
                disabled={loading || !!id}
              />
            </InputFormGroup>
          </Column>

          <Column phone={12} desktop={6}>
            <InputFormGroup label='Mensalidade' errorMessage={errors.monthlyPrice}>
              <InputMoneyMask
                value={monthlyPrice}
                error={errors.monthlyPrice}
                onChange={onInputChange('monthlyPrice')}
                onBlur={onInputBlur('monthlyPrice')}
                disabled={loading || !!id}
              />
            </InputFormGroup>
          </Column>
        </Row>

        <Row>
          <Column phone={12} desktop={6}>
            <InputFormGroup label='Número de experiências' errorMessage={errors.activitiesNumber}>
              <Input
                min='0'
                type='number'
                value={activitiesNumber}
                onChange={onInputChange('activitiesNumber')}
                onBlur={onInputBlur('activitiesNumber')}
                disabled={loading || !!id}
              />
            </InputFormGroup>
          </Column>
          <Column phone={12} desktop={6}>
            <InputFormGroup label='Custo da experiência' errorMessage={errors.activityCost}>
              <InputMoneyMask
                value={activityCost}
                error={errors.activityCost}
                onChange={onInputChange('activityCost')}
                onBlur={onInputBlur('activityCost')}
                disabled={loading || !!id}
              />
            </InputFormGroup>
          </Column>
        </Row>

        <Row>
          <Column phone={12} desktop={6}>
            <InputFormGroup label='Taxa de seller Orb' errorMessage={errors.orbSellerTax}>
              <InputWithSuffix
                step='0.01'
                min={0.0}
                max={100}
                type='number'
                value={orbSellerTax}
                onChange={onInputChange('orbSellerTax')}
                onBlur={onInputBlur('orbSellerTax')}
                disabled={loading || !!id}
              >
                %
              </InputWithSuffix>
            </InputFormGroup>
          </Column>

          <Column phone={12} desktop={6}>
            <InputFormGroup label='Taxa de vendor Orb' errorMessage={errors.orbVendorTax}>
              <InputWithSuffix
                step='0.01'
                min={0.0}
                max={100}
                type='number'
                value={orbVendorTax}
                onChange={onInputChange('orbVendorTax')}
                onBlur={onInputBlur('orbVendorTax')}
                disabled={loading || !!id}
              >
                %
              </InputWithSuffix>
            </InputFormGroup>
          </Column>
        </Row>

        <Row>
          <Column phone={12} desktop={6}>
            <InputFormGroup label='Taxa de seller Xpert' errorMessage={errors.xpertSellerTax}>
              <InputWithSuffix
                step='0.01'
                min={0.0}
                max={100}
                type='number'
                value={xpertSellerTax}
                onChange={onInputChange('xpertSellerTax')}
                onBlur={onInputBlur('xpertSellerTax')}
                disabled={loading || !!id}
              >
                %
              </InputWithSuffix>
            </InputFormGroup>
          </Column>

          <Column phone={12} desktop={6}>
            <InputFormGroup label='Taxa de vendor Xpert' errorMessage={errors.xpertVendorTax}>
              <InputWithSuffix
                step='0.01'
                min={0.0}
                max={100}
                type='number'
                value={xpertVendorTax}
                onChange={onInputChange('xpertVendorTax')}
                onBlur={onInputBlur('xpertVendorTax')}
                disabled={loading || !!id}
              >
                %
              </InputWithSuffix>
            </InputFormGroup>
          </Column>
        </Row>

        <Row>
          <Column phone={12} desktop={6}>
            <InputFormGroup label='Taxa de seller Pay' errorMessage={errors.paySellerTax}>
              <InputWithSuffix
                step='0.01'
                min={0.0}
                max={100}
                type='number'
                value={paySellerTax}
                onChange={onInputChange('paySellerTax')}
                onBlur={onInputBlur('paySellerTax')}
                disabled={loading || !!id}
              >
                %
              </InputWithSuffix>
            </InputFormGroup>
          </Column>

          <Column phone={12} desktop={6}>
            <InputFormGroup label='Taxa de vendor Pay' errorMessage={errors.payVendorTax}>
              <InputWithSuffix
                step='0.01'
                min={0.0}
                max={100}
                type='number'
                value={payVendorTax}
                onChange={onInputChange('payVendorTax')}
                onBlur={onInputBlur('payVendorTax')}
                disabled={loading || !!id}
              >
                %
              </InputWithSuffix>
            </InputFormGroup>
          </Column>
        </Row>

        <Row>
          <Column phone={12} desktop={6}>
            <InputFormGroup label='Data de contrato' errorMessage={errors.hiringDate}>
              <DatePicker value={hiringDate} onChange={onInputChange('hiringDate')} disabled={loading || !!id} fullWidth />
            </InputFormGroup>
          </Column>
          <Column phone={12} desktop={6}>
            <InputFormGroup label='Data de vencimento' errorMessage={errors.expirationDate}>
              <DatePicker value={expirationDate} onChange={onInputChange('expirationDate')} disabled={loading || !!id} fullWidth />
            </InputFormGroup>
          </Column>
        </Row>
      </Card>

      {!id && !loading && (
        <div css={actionRow}>
          <GhostButton onClick={onCancel}>Cancelar</GhostButton>
          <Button onClick={onConfirm}>Criar plano</Button>
        </div>
      )}
    </>
  )
}

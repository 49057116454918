import { css } from '@emotion/core'
import { BREAK_POINTS, flexRowCentered } from '@bonitour/components'

export const cardMargin = css`
  margin: 10px;
`

export const toggleWrapper = css`
  ${flexRowCentered};
  margin-top: 10px;
`

export const paddingRight40 = css`
  padding-right: 40px;
`
export const search = css`
width: 440px;
margin-right: 10px;
@media (max-width: ${BREAK_POINTS.bigPhone}) {
  width: 100%;
  margin: 0;
}
`

export const searchIcon = css`
  font-size: 20px;
`

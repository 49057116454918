import styled from '@emotion/styled'
import { css } from '@emotion/core'

export const UploadContent = styled.div`
  max-height: 15.625rem;
  border: 1px dashed var(--clr-gray7);
  border-radius: 0.625rem;
  overflow: auto;
`

export const uploadContentCss = css`
  background-color: red !important;
  border: 0;
`

export const uploadCss = css`
  width: inherit;
`

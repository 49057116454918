/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useToast } from '@bonitour/components'
import { useActivity } from 'contexts/Activity'
import { useCallback, useState } from 'react'
import { usePermissions } from 'hooks/usePermissions'
import { OfflineExperienceBaseForm } from 'containers/OfflineExperienceBaseForm/OfflineExperienceBaseForm'
import { useOfflineExperienceForm } from 'containers/OfflineExperienceBaseForm/useOfflineExperienceForm'
import { OfflineExperienceService } from 'services/OfflineExperience/Service'
import { useHistory } from 'react-router-dom'

export const CreateOfflineExperienceInfo = () => {
  const [offlineExperienceBase] = useState({
    phones: [{ name: '', number: '' }],
    emails: [{ name: '', email: '' }],
    sellingDeadline: 0
  })
  const { push } = useHistory()
  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()
  const { setId, updateList } = useActivity()

  const onSuccess = useCallback(
    async (data) => {
      try {
        const { id } = await OfflineExperienceService.create(data)
        if (id) {
          setId(id)
          updateList(true)
          push(`/offline-experience/${id}/edit?accordion=2`)
          addToast('Experiência offline criada com sucesso', 'success')
        }
      } catch (error) {
        const hasPermission = handlePermission(error, 'Você não possui permissão para criar uma experiência offline')
        if (hasPermission) {
          const errorMessage = error?.data?.errors_msg
          const errorCode = error?.data?.errors
          const errorMessages = {
            'uc_create_service::permission': 'Você não pode criar experiências devido a restrições em seu plano',
            default: `Ocorreu um erro na criação da experiência. ${errorCode}`
          }
          addToast(errorMessages[errorMessage] ?? errorMessages.default)
        }
      }
    },
    [setId, updateList, push, addToast, handlePermission]
  )

  const offlineExperienceFormProps = useOfflineExperienceForm({ offlineExperienceBase, onSuccess, addToast })

  return <OfflineExperienceBaseForm {...offlineExperienceFormProps} />
}

import React from 'react'
import { Card, H1, PaginationContainer } from '@bonitour/components'
import { CashierTable } from './CashierTable'
import { CashierFilters } from './CashierFilters'
import { useListCashiers } from '../hooks/useListCashiers'
import { useListEmployees } from '../hooks/useListEmployees'
import { CashierLinks } from '../Links'

export const CashierList = () => {
  const {
    initialFilters,
    setFilters,
    loading,
    handlePagination,
    cashiers,
    meta
  } = useListCashiers()
  const { filterEmployeesOptions, loading: loadingEmployees } = useListEmployees()
  const { total = 0, totalPages = 1 } = meta ?? {}

  return (
    <>
      <CashierLinks />
      <H1>Lista de caixas</H1>
      <Card>
        <CashierFilters
          loading={loading}
          setFilters={setFilters}
          initialFilters={initialFilters}
          employeesOptions={filterEmployeesOptions}
          loadingEmployees={loadingEmployees}
        />
        <PaginationContainer total={total} pagesQuantity={totalPages} onPagination={handlePagination}>
          <CashierTable cashiers={cashiers} loading={loading} />
        </PaginationContainer>
      </Card>
    </>
  )
}

import { colors, scrollBar, BREAK_POINTS } from '@bonitour/components'

import { css } from '@emotion/core'

export const container = css`
width: 100%;
max-height: 230px;
overflow: auto;
${scrollBar(5)};
padding: 10px;
color: ${colors.gray3};
border: none;
`

export const reduceHeaderPadding = css`
padding: 15px;
`

export const swatchInline = css`
display: inline-block;
margin-right: 10px;
`

export const justifyEvenly = css`
justify-content: space-evenly;
`

export const mobileOptions = css`
display: none;
cursor: pointer;  

@media (max-width: ${BREAK_POINTS.bigPhone}){
  display: flex;
  justify-content: center;
  width: 100%;
}
`

export const options = css`
display: flex;
  justify-content: center;
  width: 100%;
@media (max-width: ${BREAK_POINTS.bigPhone}){
  cursor: pointer;  
  display: none;
}
`

export const optionsIcon = css`
font-size: 20px;
`

export const dialog = css`
max-width: 300px;
padding: 20px;
`

export const optionsContainer = css`
margin-top: 10px;
display: flex;
flex-direction: column;
align-items: flex-start;
`

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { TableHeaderCell, TableHeaderRow, TableHeader } from '@bonitour/components'

const width10 = css`
  width: 10%;
  text-align: center;
`

export const ReservationsTableHeader = () => {
  return (
    <TableHeader>
      <TableHeaderRow>
        <TableHeaderCell>Cód. de reserva</TableHeaderCell>
        <TableHeaderCell>E-mail</TableHeaderCell>
        <TableHeaderCell>Operadora</TableHeaderCell>
        <TableHeaderCell>Cliente</TableHeaderCell>
        <TableHeaderCell>Estado</TableHeaderCell>
        <TableHeaderCell css={width10} fixed>Ação</TableHeaderCell>
      </TableHeaderRow>
    </TableHeader>
  )
}

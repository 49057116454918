/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { ActivityComplementaryInfoForm } from 'containers/ActivityComplementaryInfoForm/ActivityComplementaryInfoForm'
import { useToast } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { ActivityService } from 'services/Activity/Service'
import { ActivityAdditionInformationService } from 'services/Activity/ActivityAdditionInformation/Service'
import { useEffect, useState } from 'react'

export const ActivityComplementaryInfo = ({
  activityId = '',
  activityComplementaryInfo = {},
  onActivityUpdated = identity,
  onBackStep = identity,
  onNextStep = identity
}) => {
  const [paymentsTypeList, setPaymentsTypeList] = useState([])
  const [whatToTakeList, setWhatToTakeList] = useState([])
  const [whatIsIncludedList, setWhatIsIncludedList] = useState([])
  const { add } = useToast()
  const onValidationError = () => {
    add('Preencha corretamente o formulário')
  }

  const onSuccess = async data => {
    try {
      await ActivityService.updateActivityComplementaryInfo(data, activityId)
      onNextStep()
      onActivityUpdated()
    } catch (error) {
      add('Ocorreu um erro ao adicionar as informações')
    }
  }

  useEffect(() => {
    ActivityAdditionInformationService.list({ type: 'activity' }).then(({ whatToTakeList, whatIsIncludedList, paymentsTypeList }) => {
      whatToTakeList && setWhatToTakeList(whatToTakeList)
      whatIsIncludedList && setWhatIsIncludedList(whatIsIncludedList)
      paymentsTypeList && setPaymentsTypeList(paymentsTypeList)
    })
  }, [])

  return (
    <ActivityComplementaryInfoForm
      activityComplementaryInfo={activityComplementaryInfo}
      paymentsTypeList={paymentsTypeList}
      whatToTakeList={whatToTakeList}
      whatIsIncludedList={whatIsIncludedList}
      onBackClick={onBackStep}
      onSuccess={onSuccess}
      onValidationError={onValidationError}
    />
  )
}

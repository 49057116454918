/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useState, useEffect } from 'react'
import { jsx } from '@emotion/core'
import { ProvidersPage } from 'containers/Providers/Providers'
import { InvitesService } from 'services/Invites/Service'

export const Providers = () => {
  const [invitesInfo, setInvitesInfo] = useState({})

  useEffect(() => {
    InvitesService.list().then(setInvitesInfo)
  }, [])

  return (
    <>
      <ProvidersPage
        invitesInfo={invitesInfo}
      />
    </>
  )
}

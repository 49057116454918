/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Card, PaginationContainer, HeaderPage, flex } from '@bonitour/components'
import { useHistory } from 'react-router-dom'
import { useListPaidTickets } from 'domains/CompanyReports/hooks/useListPaidTickets'
import { TouristPaymentsFilter } from './TouristPaymentsFilter'
import { useRef } from 'react'
import { TouristPaymentsTable } from '../Table/TouristPaymentsTable'
import { TouristPaymentsResults } from './TouristPaymentResults'
import { ExportFile } from 'components/ExportFile/ExportFile'
import { useTouristPaymentExport } from 'domains/CompanyReports/hooks/useTouristPaymentPaidExport'
import { exportFormatsOptions } from 'constants/reports'
import { flexEnd } from 'domains/PartnershipAccount/Edit/PartnershipTransactions.styles'

export const TouristPaymentsList = () => {
  const { push } = useHistory()
  const backToReports = () => push('/reports')

  const { changePagination, onFilterSubmit, isLoading, isLoadingTotals, filters, meta, paidTickets, totals } =
    useListPaidTickets()
  const pageLimitOptions = [25, 50, 100, 200].map((x) => ({ value: x, label: `${x}` }))

  const { current: initialFilters } = useRef(filters)

  const { isLoading: isLoadingPaymentExport, paidColumns, getPaidAttributes } = useTouristPaymentExport()

  return (
    <>
      <HeaderPage onBack={backToReports} title="Pagamentos dos turistas" />

      <Card>
        <TouristPaymentsFilter onFilterSubmit={onFilterSubmit} initialFilters={initialFilters} />

        <TouristPaymentsResults totals={totals} meta={meta} isLoading={isLoadingTotals} />

        <PaginationContainer
          total={meta?.total}
          pagesQuantity={meta?.totalPages}
          onPagination={changePagination}
          pageLimitOptions={pageLimitOptions}
          pageLimitDefault={pageLimitOptions[0].value}
        >
          <div css={[flex, flexEnd]}>
            <ExportFile
              filters={filters}
              initialFilters={initialFilters}
              multiSelectOptions={paidColumns}
              loading={isLoadingPaymentExport}
              formatOptions={exportFormatsOptions}
              onSubmitExport={getPaidAttributes}
            />
          </div>
          <TouristPaymentsTable touristPayments={paidTickets} loading={isLoading} />
        </PaginationContainer>
      </Card>
    </>
  )
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { ReservationsList } from 'containers/Reservations/ReservationList/ReservationsList'
import { jsx } from '@emotion/core'
import { useState, useEffect, useCallback, useRef } from 'react'
import { PartnerService } from 'services/Partner/Service'
import { ReservationService } from 'services/Reservations/Service'
import { usePermissions } from 'hooks/usePermissions'
import { useToast } from '@bonitour/components'

const hasData = obj => Object.keys(obj).length > 0

export const Reservations = () => {
  const { add: addToast } = useToast()
  const [partnerList, setPartnerList] = useState([])
  const [reservationsInfo, setReservationsInfo] = useState([])
  const [reservationFilters, setReservationFilters] = useState({})
  const [pagination, setPagination] = useState({})
  const [loading, setLoading] = useState(false)
  const { handlePermission } = usePermissions()

  useEffect(() => {
    setLoading(true)
    PartnerService.getList().then(setPartnerList).finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    if (hasData(pagination) && hasData(reservationFilters)) {
      reservationsRequest(reservationFilters, pagination.currentPage, pagination.pageLimit)
    }
    // eslint-disable-next-line
  }, [pagination, reservationFilters])

  const onFilterSubmit = filters => {
    setReservationFilters(filters)
  }

  const requestCount = useRef(0)

  const reservationsRequest = useCallback((filters, pagesQuantity, total) => {
    setLoading(true)
    const requestNumber = ++requestCount.current
    ReservationService.list(filters, pagesQuantity, total).then(newReservations => {
      requestNumber === requestCount.current && setReservationsInfo(newReservations)
    }).catch((error) => {
      const hasPermission = handlePermission(error, 'Você não possui permissão de acesso as reservas')
      hasPermission && addToast('Ocorreu um erro ao realizar a busca')
    }).finally(() => requestNumber === requestCount.current && setLoading(false))
    // eslint-disable-next-line
  }, [])

  const paginateReservationList = useCallback((currentPage, pageLimit) => {
    const paginationInfo = {}
    paginationInfo.currentPage = currentPage
    paginationInfo.pageLimit = pageLimit
    setPagination(paginationInfo)
  }, [])

  return (
    <ReservationsList
      onFilterSubmit={onFilterSubmit}
      partnerList={partnerList}
      reservations={reservationsInfo.reservations}
      pagesQuantity={reservationsInfo.totalPages}
      total={reservationsInfo.total}
      onPagination={paginateReservationList}
      loading={loading}
    />
  )
}

import { orbCore } from 'services/Orb/Service'
import { TransactionParser } from 'core/parsers/Transaction'

export const TransactionService = {
  list (reservationId = '') {
    return orbCore.financialTransactionsDomain.list(reservationId).then(TransactionParser.list)
  },
  paymentLinks (reservationId = '') {
    return orbCore.financialTransactionsDomain.paymentLinks(reservationId).then(TransactionParser.paymentLinks)
  },
  payments (reservationId = '') {
    return orbCore.financialTransactionsDomain.payments(reservationId).then(TransactionParser.payments)
  },
  refunds (reservationId = '') {
    return orbCore.financialTransactionsDomain.refunds(reservationId).then(TransactionParser.refunds)
  }
}

import { useToast } from '@bonitour/components'
import { BankAccountService } from 'core/services/BankAccount'
import { useCallback } from 'react'
import { useQuery } from 'react-query'

export const useListBankAccounts = ({ paymentMethod, companyId }) => {
  const { add: addToast } = useToast()

  const onError = useCallback(() => {
    addToast('Ocorreu um erro ao buscar as contas bancárias')
  }, [addToast])

  const {
    data: bankAccounts,
    isLoading,
    isFetching
  } = useQuery(
    ['listBankAccountByPaymentMethodV2', `C: ${companyId}`, `PM: ${paymentMethod}`],
    async () => {
      if (!paymentMethod || !companyId) return []

      return await BankAccountService.getBankAccountByPaymentMethod({ paymentMethod, companyId })
    },
    {
      staleTime: 5 * 60 * 1000, // 5 minutes
      onError,
      retry: 2
    }
  )

  return {
    isLoading: isLoading || isFetching,
    bankAccounts: bankAccounts || []
  }
}

import { useCallback, useState } from 'react'
import { useToast } from '@bonitour/components'
import { useParamsFilter } from 'hooks/useParamsFilter'
import { PartnershipAccountService } from 'core/services/PartnershipAccount'
import { useCompany } from 'contexts/Company'
import { usePermissions } from 'hooks/usePermissions'

export const useListPartnershipAccounts = () => {
  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()
  const [filters, setFilters] = useParamsFilter()
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({ currentPage: 1, perPage: 10 })
  const { id: companyId } = useCompany()

  const changePagination = useCallback((currentPage, perPage) => {
    if (currentPage === pagination.currentPage && perPage === pagination.perPage) {
      return
    }

    setPagination({ currentPage, perPage })
  }, [pagination.currentPage, pagination.perPage])

  const [partnershipAccountData, setPartnershipAccountData] = useState({ partnershipAccounts: undefined, meta: undefined })

  const fetchPartnershipAccounts = useCallback(({ filters, pagination }, type = 'creditor') => {
    setLoading(true)

    return PartnershipAccountService.list({ filters, pagination, companyId, type }).then(setPartnershipAccountData)
      .catch((error) => {
        const hasPermission = handlePermission(error, 'Você não possui permissão de acesso à conta de parceria')
        hasPermission && addToast('Ocorreu um erro ao realizar a busca')
      })
      .finally(() => setLoading(false))
  }, [addToast, companyId, handlePermission])

  return {
    filters,
    setFilters,
    pagination,
    changePagination,
    partnershipAccounts: partnershipAccountData?.partnershipAccounts,
    meta: partnershipAccountData?.meta,
    fetchPartnershipAccounts,
    loading
  }
}

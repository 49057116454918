import { metaModel } from './meta'

export const RefundedTicketsModel = {
  list ({
    filters, pagination = {
      page: 1,
      perPage: 10
    }
  }) {
    const {
      initialRefundDate = '',
      finalRefundDate = '',
      initialSlotDate = '',
      finalSlotDate = '',
      refundMethod = '',
      reservationCode = ''
    } = filters

    const meta = metaModel(pagination)

    return {
      searchParams: {
        initial_refund_date: initialRefundDate,
        final_refund_date: finalRefundDate,
        initial_slot_date: initialSlotDate,
        final_slot_date: finalSlotDate,
        refund_method: refundMethod,
        reservation_code: reservationCode,
        page: pagination.page,
        per_page: pagination.perPage
      },
      pagination: meta
    }
  },
  totals ({ filters }) {
    const {
      initialRefundDate = '',
      finalRefundDate = '',
      initialSlotDate = '',
      finalSlotDate = '',
      refundMethod = '',
      reservationCode = ''
    } = filters

    return {
      searchParams: {
        initial_refund_date: initialRefundDate,
        final_refund_date: finalRefundDate,
        initial_slot_date: initialSlotDate,
        final_slot_date: finalSlotDate,
        refund_method: refundMethod,
        reservation_code: reservationCode
      }
    }
  }
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { NavLink } from 'react-router-dom'
import { CalendarIcon, CogIcon, colors, LightEmptyResult, Label, ChartsIcon, PlusIcon } from '@bonitour/components'
import { marginTop, resetLink } from 'assets/styles/global'
import { CardButton } from 'components/CardButton'
import { ActivitySelector } from 'containers/Activity/Selector/Selector'
import { useActivity } from 'contexts/Activity'
import { useMemo } from 'react'
import { useRouteByType } from 'hooks/useRouteByType'
import { COMBINED_EXPERIENCE_TYPE, OFFLINE_EXPERIENCE_TYPE, TRANSPORT_TYPE } from 'constants/activityTypes'
import { useIsLockedService } from 'hooks/domains/useIsLockedService'
import { BookingMapOperationProgress } from 'components/BookingMap/BookingMapOperationProgress'
import { ServiceCombinedExperienceCard } from 'domains/CombinedExperience/components'

const container = css`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`

const inlineFlex = css`
  display: inline-flex;
`

const iconSize = css`
  font-size: 24px;
`

const cardButtonColor = css`
  color: ${colors.gray4};
`

const labelCardButton = css`
  width: 80px;
  cursor: pointer;
  ${cardButtonColor};
  text-align: center;
  margin: 2px 0;
`

const emptyContainer = css`
  margin: 25px;
`

const cardButton = css`
  margin: 10px;
  padding: 5px 10px;
  min-height: 65px;
`

const marginBottom20 = css`
  margin-bottom: 20px;
`

const alertPaddingRightStyle = css`
  padding-right: 1rem;
`

export const ActivityDashboard = () => {
  const {
    id: activityId,
    activity: { isPartner = false, type, experiences }
  } = useActivity()

  const {
    isOffline,
    isCombined,
    isTransport
  } = useMemo(() => ({
    isOffline: type === OFFLINE_EXPERIENCE_TYPE,
    isCombined: type === COMBINED_EXPERIENCE_TYPE,
    isTransport: type === TRANSPORT_TYPE
  }), [type])

  const { basePath } = useRouteByType(`${activityId}/dashboard`)

  const {
    isLoading: isLoadingActivityLocked,
    isLocked: isActivityLocked
  } = useIsLockedService(activityId)

  return (
    <>
      <div css={marginBottom20}>
        <ActivitySelector subdomain='dashboard'/>
        <BookingMapOperationProgress
          isLoading={isLoadingActivityLocked}
          isOperationInProgress={isActivityLocked}
          customCss={[marginTop(15), alertPaddingRightStyle]}
          isTransport={isTransport}
        />
      </div>
      <div css={container}>
        <div css={inlineFlex}>
          {isCombined && (
            <ServiceCombinedExperienceCard experiences={experiences} />
          )}
        </div>
        <div css={inlineFlex}>
          {!isOffline
            ? (
              <NavLink to={`${basePath}/bookings`} css={[resetLink]}>
                <CardButton css={cardButton}>
                  <CalendarIcon css={[iconSize, cardButtonColor]} />
                  <Label css={labelCardButton}>Mapa de Vagas</Label>
                </CardButton>
              </NavLink>
            )
            : (
              <NavLink to={`${basePath}/bookings/create`} css={[resetLink]}>
                <CardButton css={cardButton}>
                  <PlusIcon css={[iconSize, cardButtonColor]} />
                  <Label css={labelCardButton}>Nova reserva</Label>
                </CardButton>
              </NavLink>
            )}

          {!isCombined && (
            <NavLink to={`${basePath}/daily-monitoring`} css={[resetLink]}>
              <CardButton css={cardButton}>
                <ChartsIcon css={[iconSize, cardButtonColor]} />
                <Label css={labelCardButton}>Acompanhamento Diário</Label>
              </CardButton>
            </NavLink>
          )}

          {!isPartner && (
            <NavLink to={`${basePath}/edit`} css={[resetLink]}>
              <CardButton css={cardButton}>
                <CogIcon css={[iconSize, cardButtonColor]} />
                <Label css={labelCardButton}>Editar</Label>
              </CardButton>
            </NavLink>
          )}

        </div>
      </div>
      <div css={emptyContainer}>
        <LightEmptyResult title='Dashboard sem informações' subtitle='' />
      </div>
    </>
  )
}

import { useToast } from '@bonitour/components'
import { PassengerService } from 'core/services/Passenger'
import { TicketsService } from 'core/services/Tickets'
import { useState } from 'react'

export const usePassenger = (reservation, updateReservation) => {
  const { add: addToast } = useToast()
  const [isLinkPassengerModalVisible, setLinkPassengerModalVisibility] = useState(false)
  const [isPassengerFormVisible, setPassengerFormVisibility] = useState(false)
  const [passenger, setPassenger] = useState({})
  const [passengersFound, setPassengersFound] = useState([])
  const [searchPassengerLoading, setSearchPassengerLoading] = useState(false)
  const [linkPassengerLoading, setLinkPassengerLoading] = useState(false)
  const [isEditPassenger, setEditPassenger] = useState(false)
  const [ticketId, setTicketId] = useState('')

  const getPassenger = passengerId => {
    PassengerService.getById(passengerId)
      .then(setPassenger)
      .catch(() => addToast('Ocorreu um erro ao buscar o viajante'))
  }

  const onPassengerSelection = ticketId => () => {
    setTicketId(ticketId)
    setLinkPassengerModalVisibility(true)
  }

  const onCloseLinkPassengerModal = () => {
    setPassengersFound([])
    setLinkPassengerModalVisibility(false)
  }

  const onClickNewPassenger = ({ email }) => {
    email ? setPassenger({ email }) : setPassenger({})
    onCloseLinkPassengerModal()
    setEditPassenger(false)
    setPassengerFormVisibility(true)
  }

  const onClickNewDependent = () => {
    setPassenger({ email: reservation?.info?.email, isDependent: true })
    onCloseLinkPassengerModal()
    setEditPassenger(false)
    setPassengerFormVisibility(true)
  }

  const onClickEditPassenger = passengerId => {
    getPassenger(passengerId)
    setEditPassenger(true)
    setPassengerFormVisibility(true)
  }

  const onBackPassengerForm = () => {
    setPassengerFormVisibility(false)
    setLinkPassengerModalVisibility(true)
  }

  const onClosePassengerForm = () => {
    setPassengerFormVisibility(false)
    setPassenger({})
  }

  const onSearchPassengers = async (filters) => {
    setSearchPassengerLoading(true)

    return PassengerService.list(filters)
      .then((data) => {
        const passengersSorted = data?.passengers.sort((a, b) => a.userId && a.userId.localeCompare(b.userId))
        setPassengersFound({ passengers: passengersSorted })
      })
      .finally(() => setSearchPassengerLoading(false))
  }

  const onCreatePassenger = async (passengerInfo) => {
    const passenger = { ...passengerInfo }
    return PassengerService.createPassenger({ ...passenger })
      .then(({ passenger }) => onLinkPassenger(passenger?.id))
      .catch(() => addToast('Ocorreu um erro ao cadastrar o viajante'))
  }

  const onCreateLinkPayer = async (payerInfo, reservationId, changePayer) => {
    const {
      name: fullName,
      email,
      birthDate,
      identificationDocument: document,
      number: phone,
      country,
      state,
      city,
      emergencyName,
      emergencyTelephone,
      height,
      weight
    } = payerInfo

    const passenger = { fullName, email, birthDate, document, phone, country, state, city, emergencyName, emergencyTelephone, height, weight }

    return PassengerService.createPassenger({ ...passenger })
      .then(({ passenger }) => changePayer(reservationId, passenger?.id))
      .catch(() => addToast('Ocorreu um erro ao cadastrar o viajante'))
  }

  const onEditPassenger = async (passenger) => {
    return PassengerService.editPassenger({ ...passenger }, passenger?.id)
      .then(() => addToast('Viajante editado com sucesso', 'success'))
      .catch(() => addToast('Ocorreu um erro ao editar o viajante'))
  }

  const onEditLinkPayer = async (payerInfo, reservationId, changePayer) => {
    const {
      payerId: id,
      name: fullName,
      email,
      birthDate,
      identificationDocument: document,
      number: phone,
      country,
      state,
      city,
      emergencyName,
      emergencyTelephone,
      height,
      weight,
      zipCode,
      street,
      supplement,
      region
    } = payerInfo

    const passenger = { id, fullName, email, birthDate, document, phone, country, state, city, emergencyName, emergencyTelephone, height, weight, zipCode, street, supplement, region }

    return PassengerService.editPassenger({ ...passenger }, id)
      .then(() => id && changePayer(reservationId, id))
      .catch(() => addToast('Ocorreu um erro ao atualizar o viajante'))
  }

  const onLinkPassenger = passengerId => {
    setLinkPassengerLoading(ticketId)
    return TicketsService.linkPassenger(ticketId, passengerId)
      .then(() => {
        addToast('Viajante vinculado com sucesso', 'success')
        updateReservation()
      })
      .catch(() => addToast('Ocorreu um erro ao vincular o viajante'))
      .finally(() => setLinkPassengerLoading(false))
  }

  const onUnlinkPassenger = ticketId => {
    setLinkPassengerLoading(ticketId)
    return TicketsService.unlinkPassenger(ticketId)
      .then(() => {
        addToast('Viajante desvinculado com sucesso', 'success')
        updateReservation()
      })
      .catch(() => addToast('Ocorreu um erro ao desvincular o viajante'))
      .finally(() => setLinkPassengerLoading(false))
  }

  return {
    passenger,
    isLinkPassengerModalVisible,
    onCloseLinkPassengerModal,
    onPassengerSelection,
    onClickNewPassenger,
    onClickNewDependent,
    onClickEditPassenger,
    isPassengerFormVisible,
    isEditPassenger,
    onBackPassengerForm,
    onClosePassengerForm,
    onCreatePassenger,
    onEditPassenger,
    onLinkPassenger,
    getPassenger,
    onSearchPassengers,
    onUnlinkPassenger,
    onCreateLinkPayer,
    onEditLinkPayer,
    passengersFound,
    searchPassengerLoading,
    linkPassengerLoading
  }
}

/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx, css } from '@emotion/core'
import {
  Card,
  InputFormGroup,
  colors,
  useToast,
  Row,
  Column,
  DatePicker,
  Button
} from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { useForm } from '@bonitour/app-functions'
import { useValidateDateRange } from 'domains/CompanyReports/hooks/useValidadeDateRange'
import { useKeyPress } from 'hooks/useKeyPress'
import { pointer } from 'domains/Notice/Form/NoticeForm.style'

const cardContrast = css`
  margin-bottom: 20px;
  padding: 15px;
  background-color: ${colors.gray13};
  border: none;
`

const button = css`
  height: 40px;
  width: 100%;
  margin-top: 20px;
`

const width100pc = css`
  width: 100%;
`
export const scheduleSelect = css`
  width: 100%;
  ${pointer};
`

export const scheduleInput = css`
  ${pointer};
  background-color: ${colors.white} !important;
`

export const PendintTicketsFilterSchema = {}

export const PendingTicketsFilter = ({
  onFilterSubmit: emitFilterSubmit = identity,
  initialFilters = {}
}) => {
  const { add: addToast } = useToast()
  const {
    form: { dateStart, dateEnd },
    errors,
    onSubmit,
    utils: { onInputChange }
  } = useForm(initialFilters, PendintTicketsFilterSchema)

  const { validateDateRange } = useValidateDateRange()
  const { onKeyDown } = useKeyPress()

  const onError = () => addToast('Preencha o formulário corretamente')

  const validateFilters = (filters) => {
    validateDateRange(dateStart, dateEnd) && emitFilterSubmit(filters)
  }

  const onClick = onSubmit(validateFilters, onError)

  return (
    <Card css={cardContrast}>
      <Row>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup
            label='Data inicial'
            errorMessage={errors.dateStart}
          >
            <DatePicker
              value={dateStart}
              onChange={onInputChange('dateStart')}
              css={width100pc}
              allowsEmpty
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup
            label='Data final'
            errorMessage={errors.dateEnd}
          >
            <DatePicker
              value={dateEnd}
              onChange={onInputChange('dateEnd')}
              css={width100pc}
              allowsEmpty
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <Button
            css={button}
            onClick={onClick}
            disabled={errors.dateStart || errors.dateEnd || !dateStart || !dateEnd}
          >
            Pesquisar
          </Button>
        </Column>
      </Row>
    </Card>
  )
}

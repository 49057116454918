/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import { H1 } from '@bonitour/components'
import { AcquirerForm } from '../Form/AcquirerForm'
import { useEditAcquirer } from '../hooks/useEditAcquirer'

export const EditAcquirer = () => {
  const { acquirer, submit, loading } = useEditAcquirer()

  return (
    <>
      <H1>Edição do Adquirente</H1>
      <AcquirerForm submit={submit} loading={loading} acquirer={acquirer} />
    </>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Dialog, GhostButton, useToast } from '@bonitour/components'
import {
  buttonStyle,
  dialogCss,
  disabledButtonCss,
  marginRight20,
  offlineExperienceTicketFormContainer,
  progressDivCss
} from './ConfirmationModal.style'
import { hidden } from 'assets/styles/global'

export const ConfirmationModal = ({
  handleAddTicket,
  onClose,
  isVisible = false,
  children,
  isAllowedRenderWithoutDialog = false,
  isPluralizedLabels = false
}) => {
  const { add: addToast } = useToast()

  const [isAllowHiddenProgressDiv, setIsAllowHiddenProgressDiv] = useState(false)
  const currentTimeout = useRef(null)

  const [isDisabledControlsDialog, setIsDisabledControlsDialog] = useState(true)

  const onClickSendTicket = useCallback(() => {
    if (isDisabledControlsDialog) {
      addToast('Por favor, aguarde o término da operação antes de continuar.')
    } else {
      handleAddTicket()
    }
  }, [addToast, handleAddTicket, isDisabledControlsDialog])

  const onCloseDialog = useCallback(() => {
    if (currentTimeout.current) {
      clearTimeout(currentTimeout.current)
      currentTimeout.current = null
    }
    onClose()
  }, [onClose])

  const progressDivStyle = useMemo(() => {
    if (isAllowHiddenProgressDiv) {
      return hidden
    } else {
      return progressDivCss
    }
  }, [isAllowHiddenProgressDiv])

  useEffect(() => {
    if (isVisible) {
      currentTimeout.current = setTimeout(() => {
        setIsDisabledControlsDialog(false)
        setIsAllowHiddenProgressDiv(true)
      }, 5000)
    } else {
      setIsDisabledControlsDialog(true)
      setIsAllowHiddenProgressDiv(false)
    }
  }, [isVisible])

  const {
    titleLabel,
    descriptionLabel,
    submitButtonLabel
  } = useMemo(() => {
    const pluralized = isPluralizedLabels ? 's' : ''
    return {
      titleLabel: `Confirmação do${pluralized} ingresso${pluralized}`,
      descriptionLabel: `Confira os dados que serão adicionados para o${pluralized} ingresso${pluralized}:`,
      submitButtonLabel: `Enviar ingresso${pluralized}`
    }
  }, [isPluralizedLabels])

  return <>
    {isAllowedRenderWithoutDialog && children}
    <Dialog
      isVisible={isVisible}
      onClose={onCloseDialog}
      title={titleLabel}
      customContainercss={dialogCss}
    >
      <p>{descriptionLabel}</p>
      <div css={offlineExperienceTicketFormContainer} className='div-not-allowed'>
        {children}
      </div>
      <Button
        css={[buttonStyle, marginRight20, isDisabledControlsDialog && disabledButtonCss]}
        onClick={onClickSendTicket}
      >
        {submitButtonLabel}
      </Button>
      <GhostButton
        css={[buttonStyle, marginRight20]}
        onClick={onCloseDialog}
      >
          Voltar
      </GhostButton>

      <div css={progressDivStyle}/>
    </Dialog>
  </>
}

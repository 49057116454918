/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import { formatMoney, identity } from '@bonitour/common-functions'
import { Card, colors, H2, TableBody, TableBodyCell, TableBodyRow, TableContainer, TableHeader, TableHeaderCell, TableHeaderRow, EyeIcon, TooltipOnHover, PencilIcon, Swatch, EditInfoIcon, flex, EllipsisIcon, Dialog, Label } from '@bonitour/components'
import { invoicePaymentTypeMap } from '../constants/InvoicePaymentStatus'
import { clickable, hidden } from 'assets/styles/global'
import { formatISOTime } from 'utils/time'
import { useClickOutside } from '@bonitour/app-functions'
import { useMemo, useRef } from 'react'
import { mobileOptions, optionsIcon, swatchInline, container, dialog, justifyEvenly, options, optionsContainer, reduceHeaderPadding } from './InvoicePayments.styles'

export const invoiceStatusMap = {
  pending: ['Aguardado Aprovação', colors.gray5],
  rejected: ['Rejeitado', colors.red2],
  approved: ['Aprovado', colors.green2]
}

const InvoicePaymentRow = ({
  payment = {},
  isEnabledToApprove = false,
  onInvoicePaymentViewClick = identity
}) => {
  const approvalStatus = payment.vendorApproval
  const [codeName, codeColor] = invoiceStatusMap[approvalStatus] || invoiceStatusMap.open
  const paymentAction = isEnabledToApprove && approvalStatus === 'pending' ? 'Averiguar' : 'Visualizar'
  const PaymentIcon = isEnabledToApprove && approvalStatus === 'pending' ? PencilIcon : EyeIcon
  const onViewClick = () => onInvoicePaymentViewClick(payment, 'show')
  const onEditClick = () => onInvoicePaymentViewClick(payment, 'edit')

  const reference = useRef()
  const [isOptionsVisible, setOptionsVisibility] = useClickOutside(reference)
  const onClickOptions = () => setOptionsVisibility(!isOptionsVisible)
  const onCloseOptions = () => setOptionsVisibility(false)

  const isEditAllow = useMemo(() => {
    if (payment.type === 'bank_billet' || payment.type === 'bank_transfer' || payment.type === 'pix') {
      return true
    }
    return false
  }, [payment.type])

  return (
    <>
      <TableBodyRow key={payment.id}>
        <TableBodyCell>
          {formatISOTime(payment.date, 'DD/MM/YYYY', false)}
        </TableBodyCell>
        <TableBodyCell>
          {invoicePaymentTypeMap[payment.type] || '-'}
        </TableBodyCell>
        <TableBodyCell>
          {formatMoney(payment.value)}
        </TableBodyCell>
        <TableBodyCell>
          <Swatch size={10} color={codeColor} css={swatchInline} />
          {codeName}
        </TableBodyCell>
        <TableBodyCell fixed>
          <div css={mobileOptions} onClick={onClickOptions}>
            <EllipsisIcon css={optionsIcon} />
          </div>
          <div css={[flex, options, justifyEvenly]}>
            <TooltipOnHover text={`${paymentAction} pagamento`}>
              <PaymentIcon css={[clickable]} onClick={onViewClick} />
            </TooltipOnHover>
            <TooltipOnHover text='editar pagamento' css={!isEditAllow && hidden}>
              <EditInfoIcon css={[clickable]} onClick={onEditClick} />
            </TooltipOnHover>
          </div>
        </TableBodyCell>
      </TableBodyRow>

      <Dialog customContainercss={[dialog]} title='Ação' isVisible={isOptionsVisible} onClose={onCloseOptions}>
        <div css={optionsContainer}>
          <TooltipOnHover text={`${paymentAction} pagamento`}>
            <PaymentIcon css={[clickable]} onClick={onViewClick} />
            <Label onClick={onViewClick}>Visualizar pagamento</Label>
          </TooltipOnHover>
          <TooltipOnHover text='editar pagamento'>
            <EditInfoIcon css={[clickable]} onClick={onEditClick} />
            <Label onClick={onEditClick}>Editar pagamento</Label>
          </TooltipOnHover>
        </div>
      </Dialog>
    </>
  )
}

export const InvoicePayments = ({
  isEnabledToApprove = false,
  payments = [],
  onInvoicePaymentViewClick = identity
}) => {
  const hasPayments = payments.length > 0

  return (
    <Card css={container}>
      <H2>Pagamentos</H2>
      <TableContainer>
        <TableHeader>
          <TableHeaderRow>
            <TableHeaderCell css={reduceHeaderPadding}>Data</TableHeaderCell>
            <TableHeaderCell css={reduceHeaderPadding}>Método</TableHeaderCell>
            <TableHeaderCell css={reduceHeaderPadding}>Valor</TableHeaderCell>
            <TableHeaderCell css={reduceHeaderPadding}>Status</TableHeaderCell>
            <TableHeaderCell css={reduceHeaderPadding} fixed={hasPayments}>Ações</TableHeaderCell>
          </TableHeaderRow>
        </TableHeader>
        <TableBody fallbackMessage='Sem pagamentos registrados'>
          {
            payments.map((payment) => (
              <InvoicePaymentRow
                key={payment.id}
                payment={payment}
                isEnabledToApprove={isEnabledToApprove}
                onInvoicePaymentViewClick={onInvoicePaymentViewClick}
              />
            ))
          }
        </TableBody>
      </TableContainer>
    </Card>
  )
}

import React from 'react'
import { UserThumb } from '@bonitour/components'
import { useUser } from 'contexts/User'
import { useCompany } from 'contexts/Company'

export const UserSection = props => {
  const { user } = useUser()
  const { company } = useCompany()
  const { name, avatar } = user
  const { image } = company

  return <UserThumb {...props} name={name} avatar={avatar} companyImage={image} />
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { identity } from '@bonitour/components'
import { useEffect, useMemo, useRef } from 'react'
import { affiliatesListContainer, affiliatesListItem, observerDivStyle, notFoundStyle, centralizedDiv } from './Affiliate.styles'

export const AffiliatesTable = ({
  onSelectAffiliate = identity,
  fetchMoreAffiliates = identity,
  isLoading = false,
  affiliates = []
}) => {
  const elementToObserve = useRef(null)

  const observer = useMemo(() => {
    const observer = new IntersectionObserver((entities) => {
      if (entities[0].isIntersecting) {
        fetchMoreAffiliates()
      }
    }, { threshold: 0.5 })
    return observer
  }, [fetchMoreAffiliates])

  useEffect(() => {
    const observedElement = elementToObserve.current

    if (observedElement) {
      observer.observe(observedElement)
    }

    return () => observer.unobserve(observedElement)
  }, [observer])

  const canShowNotFoundLabel = useMemo(() => !affiliates.length && !isLoading, [affiliates.length, isLoading])

  return <div css={[affiliatesListContainer, canShowNotFoundLabel && centralizedDiv]}>
    {canShowNotFoundLabel && <p css={notFoundStyle}>Nenhum afiliado encontrado</p>}
    {affiliates.map(({ id, name, affiliateCode }) => (<div key={`affiliate-${id}`} css={affiliatesListItem} onClick={onSelectAffiliate(affiliateCode)}>
      <div>
        <p>{name || '--'}</p>
        <p>{affiliateCode}</p>
      </div>
    </div>))}
    <div ref={elementToObserve} css={observerDivStyle} />
  </div>
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useEffect, useState, useCallback } from 'react'
import { MyDayList } from './MyDayList'
import { useListActivities } from '../hooks/useListActivities'
import { TicketCheckInModal } from 'domains/Ticket/CheckIn/TicketCheckInModal'
import { useTicketsCheckin } from 'domains/Ticket/CheckIn/hooks/useTicketsCheckIn'
import { Loading } from '@bonitour/components'
import { isDateInFuture } from 'utils/date'
import { SelectedTicketsModal } from '../SelectedTicketsModal/SelectedTicketsModal'
import { SelectedTicketsDialog } from '../SelectedTicketsDialog/SelectedTicketsDialog'
import { useActivity } from 'contexts/Activity'

const ticketHeadings = [{ title: 'Obtendo ingressos' }]

export const MyDay = () => {
  const {
    filters,
    setFilters,
    activities,
    meta,
    loading,
    pagination,
    totals = {},
    exportColumns,
    exporting,
    fetchActivities,
    exportMyDay,
    changePagination,
    initialFilters
  } = useListActivities()

  const { companyActivities } = useActivity()

  const {
    isModalVisible,
    selectedCode,
    onModalClose,
    onValidateClick,
    validateCode,
    validateManyCodes,
    loading: ticketValidationLoading
  } = useTicketsCheckin()
  const [isSelectedAll, setSelectedAll] = useState(false)
  const [isDialogVisible, setisDialogVisible] = useState(false)
  const [selectedTickets, setSelectedTickets] = useState([])
  const isTicketInFuture = isDateInFuture(filters?.date)
  const quantity = selectedTickets?.length

  const isOwner = (companyActivities, serviceId) => companyActivities.filter(({ id }) => id === serviceId)?.length > 0

  const isValidateVisible = (status) => status === 'confirmed' || status === 'reserved'

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const canValidateTicket = useCallback(
    (state, serviceId) => isOwner(companyActivities, serviceId) && !isTicketInFuture && isValidateVisible(state),
    [isTicketInFuture, companyActivities]
  )

  const toggleDialogVisibility = () => setisDialogVisible((isDialogVisible) => !isDialogVisible)

  const isTicketSelect = (selectedTicketCode) =>
    selectedTickets.some(({ ticketCode }) => ticketCode === selectedTicketCode)

  const isTicketSelectable = (status) => status !== 'canceled'

  const onSelectedAllClick = () => setSelectedAll(!isSelectedAll)

  const onTicketClick = (ticket) =>
    isTicketSelect(ticket?.ticketCode)
      ? setSelectedTickets(selectedTickets.filter(({ ticketCode }) => ticketCode !== ticket.ticketCode))
      : isTicketSelectable(ticket) && setSelectedTickets([...selectedTickets, ticket])

  const onValidateSubmit = () => {
    validateCode(() => fetchActivities({ pagination, filters }))
    onModalClose()
  }

  const onValidateMany = () => {
    setisDialogVisible(false)
    validateManyCodes(selectedTickets, () => fetchActivities({ pagination, filters }))
    setSelectedTickets([])
  }

  useEffect(() => {
    isSelectedAll
      ? setSelectedTickets(activities.filter((ticket) => canValidateTicket(ticket?.state, ticket?.serviceId)))
      : setSelectedTickets([])
    // eslint-disable-next-line
  }, [isSelectedAll])

  return (
    <>
      <SelectedTicketsDialog
        isVisible={isDialogVisible}
        quantity={quantity}
        onCloseDialog={toggleDialogVisibility}
        onSubmitValidation={onValidateMany}
      />

      <SelectedTicketsModal quantity={quantity} onValidateSubmit={toggleDialogVisibility} />

      <TicketCheckInModal
        isVisible={isModalVisible}
        selectedCode={selectedCode}
        onValidate={onValidateSubmit}
        onClose={onModalClose}
      />

      <MyDayList
        onFilterSubmit={setFilters}
        loading={loading}
        exporting={exporting}
        activities={activities}
        initialFilters={initialFilters}
        pagesQuantity={meta?.totalPages}
        total={meta?.total}
        onPagination={changePagination}
        onValidateClick={onValidateClick}
        totalizer={totals}
        exportColumns={exportColumns}
        onSubmitExport={exportMyDay}
        filters={filters}
        isTicketInFuture={!isTicketInFuture}
        onSelectedAllClick={onSelectedAllClick}
        onTicketClick={onTicketClick}
        isTicketSelectable={isTicketSelectable}
        canValidateTicket={canValidateTicket}
        isTicketSelect={isTicketSelect}
        isSelectedAll={isSelectedAll}
      />

      <Loading isLoading={ticketValidationLoading} headings={ticketHeadings} />
    </>
  )
}

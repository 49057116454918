import { BREAK_POINTS, colors, iconHex, scrollBar } from '@bonitour/components'
import { css } from '@emotion/core'

export const iconClick = css`
  font-size: 23px;
  margin: 0 5px;
  color: ${colors.gray2};
  cursor: pointer;
`

export const infoSwatchContainer = css`
  margin-left: 10px;
  height: 20px;
  width: 20px;
`

export const dateHourStyle = css`
  display: flex;
  align-items: center;
`

export const paymentMethodStyle = dateHourStyle

export const paddingRight5 = css`
  padding-right: 5px;
`

export const options = card => css`
  display: none;
  cursor: pointer;

  @media (max-width: ${BREAK_POINTS.bigPhone}){
    display: ${card ? 'none' : 'flex'};
    justify-content: center;
    width: 100%;
  }
`

export const inlineOptions = card => css`
  display: flex;

  @media (max-width: ${BREAK_POINTS.bigPhone}){
    ${!card && 'display: none;'}
  }
`

export const iconLabel = card => css`
  display: none;

  @media (max-width: ${BREAK_POINTS.bigPhone}){
    display: ${card ? 'none' : 'flex'};
  }
`

export const optionsIcon = css`
  font-size: 20px;
`

export const optionsContainer = css`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const dialog = css`
  max-width: 300px;
  padding: 20px;
`

export const textOverflow = css`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
  white-space: nowrap;
  line-height: 1.1;

  @media (max-width: ${BREAK_POINTS.bigPhone}){
    max-width: initial;
  }
`

export const tableContainer = css`
  overflow-x: auto;
  ${scrollBar(5)};
`

export const collapsableCss = open => css`
  > div {
    padding: 20px;
    &:not(:first-of-type) {
      padding-top: 0;
    }
  }
  [class*="indicator"] {
    display: none;
  }
  h2 {
    margin-left: 0;
    &::after {
      display: inline-block;
      margin: 5px;
      font-family: 'bonifont' !important;
      content: "\\${iconHex('angle-down')}";
      transition: transform 250ms ease-in-out;
      transform: rotate(${open ? '-180' : '0'}deg) translateY(${open ? '-4' : '2'}px);
    }
  }
`

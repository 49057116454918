import { BREAK_POINTS } from '@bonitour/components'
import { css } from '@emotion/core'
import { marginBottom, marginRight, marginTop } from 'assets/styles/global'

export const marginTop50 = marginTop(50)
export const marginBottom20 = marginBottom(20)
export const marginRight20 = marginRight(20)

export const marginTop7 = css`
  @media (max-width: ${BREAK_POINTS.tablet}) {
    ${marginTop(7)}
  }
`

export const padding0 = css`
  padding: 0;
`

export const paddingLeft0 = css`
  padding-left: 0;
`

export const mobilePaddingLeft0 = css`
  @media (max-width: ${BREAK_POINTS.desktop}) {
    padding-left: 0;
  }
`

export const cep = css`
   @media (max-width: ${BREAK_POINTS.tablet}) {
    flex-direction: column;
    align-items: flex-start;
  }
`
export const inputGroup = css`
  width: 100%;
  ${marginRight20};
  @media (max-width: ${BREAK_POINTS.tablet}) {
    margin: 0;
  }
`

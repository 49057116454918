/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { TableBodyCell, TableBodyRow } from '@bonitour/components'
import { useCallback } from 'react'

/**
 * Renders a row in the reservations visualized table.
 * @component
 * @param {Object} reservation - The reservation object.
 * @returns {JSX.Element} - The rendered ReservationVisualizedTableRow component.
 */
export const ReservationVisualizedTableRow = ({ reservation }) => {
  const GetBooleanLabel = useCallback((value) => {
    return value ? 'Sim' : 'Não'
  }, [])
  return (
    <TableBodyRow>
      <TableBodyCell>
        <a href={`/reservation/${reservation.id}`} target='_blank' rel="noreferrer">
          {reservation.reservationCode}
        </a>
      </TableBodyCell>
      <TableBodyCell>{GetBooleanLabel(reservation.visualizedInAlly)}</TableBodyCell>
      <TableBodyCell>{GetBooleanLabel(reservation.checkedIn)}</TableBodyCell>
    </TableBodyRow>
  )
}

import React from 'react'
import { Card, H1, H3, PaginationContainer } from '@bonitour/components'
import { BinamikCompaniesTable } from './BinamikCompaniesTable'
import { useBinamikCompanies } from '../hooks/useBinamikCompanies'

export const BinamikCompanies = () => {
  const { companies, meta, handlePagination } = useBinamikCompanies()
  const { total = 0, totalPages = 1 } = meta ?? {}

  return (
    <>
      <H1>Parceiros Binamik</H1>
      <Card>
        <PaginationContainer
          total={total}
          pagesQuantity={totalPages}
          onPagination={handlePagination}
        >
          <H3>Lista dos parceiros</H3>
          <BinamikCompaniesTable companies={companies} />
        </PaginationContainer>
      </Card>
    </>
  )
}

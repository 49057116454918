import { orbCore, extractData } from 'services/Orb/Service'
import { AffiliateBatchModel, AffiliateCreateModel, AffiliateTicketsToBatchModel, AffiliatePaginatedModel } from './Model'
import { AffiliateParser } from './Parser'

const { affiliateDomain } = orbCore

export const AffiliateService = {
  create (data) {
    const requestPayload = AffiliateCreateModel(AffiliateParser.instance(data))
    return affiliateDomain.create(requestPayload).then(extractData)
  },
  affiliateTicketsToBatch ({ filters, pagination }, affiliateCode) {
    return affiliateDomain
      .affiliateTicketsToBatch(AffiliateTicketsToBatchModel({ filters, pagination }), affiliateCode)
      .then(AffiliateParser.affiliateTicketsToBatch)
  },
  options () {
    return affiliateDomain
      .nonPaginatedList()
      .then(AffiliateParser.options)
  },
  createPaymentBatch: batch => {
    const { affiliateCode } = batch
    return affiliateDomain.createBatch(AffiliateBatchModel({ batch }), affiliateCode)
  },
  paginatedList: ({ filters, pagination }) => {
    return affiliateDomain
      .paginatedList(AffiliatePaginatedModel({ ...filters, ...pagination }))
      .then(AffiliateParser.paginatedList)
  }
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Modal, Row, Column, InputTimeMask, InputFormGroup, Input, GhostButton, Button, useToast, flexHorizontallyCentered, flexCenteredSpaceBetweenRow, BREAK_POINTS } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { useState, useEffect } from 'react'
import { useForm } from '@bonitour/app-functions'
import { marginRight, marginTop, mobileBackButton, mobileButtonContainer, mobileContinueButton, width100 } from 'assets/styles/global'
import { vacancyScheduleIntervalSchema } from './VacancyScheduleInterval.schema'

const marginTop20 = marginTop(40)
const marginRight20 = marginRight(20)
const padding0 = css`padding: 0;`

const horizontalPadding = css`
  padding: 0 10px;

  @media (max-width: ${BREAK_POINTS.desktop}) {
    padding: 0;
  }
`

const vacancyDay = css`
  width: 150px;
  ${horizontalPadding};

  &:first-of-type {
    padding-left: 0;
  } 

  &:last-of-type {
    padding-right: 0;
  }
`

const flexColumnStart = css`
  @media (max-width: ${BREAK_POINTS.desktop}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const customHeaderCss = css`
  @media (max-width: ${BREAK_POINTS.tableUp}) {
    align-items: baseline;
  }
`

const customCloseIconCss = css`
  min-width: 30px;
`

const titleStyle = css`
  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    font-size: 18px;
  }
`

const initialForm = {
  interval: 0,
  vacancySunday: 0,
  vacancyMonday: 0,
  vacancyTuesday: 0,
  vacancyWednesday: 0,
  vacancyThursday: 0,
  vacancyFriday: 0,
  vacancySaturday: 0
}

export const CreateVacancyScheduleForm = ({
  isVisible = false,
  onCloseClick = identity,
  onSuccess: emitSuccessEvent = identity
}) => {
  const { add: addToast } = useToast()
  const [initialState, setInitialState] = useState(initialForm)
  const { form, errors, onSubmit, utils: { onInputBlur, onInputChange } } = useForm(initialState, vacancyScheduleIntervalSchema)

  useEffect(() => {
    setInitialState(initialForm)
  }, [isVisible])

  const onFormSuccess = (form) => {
    emitSuccessEvent(form)
    onCloseClick()
  }

  const emitValidationError = () => addToast('Preencha corretamente o formulário')

  const onConfirm = onSubmit(onFormSuccess, emitValidationError)
  const {
    initialTime = '',
    finalTime = '',
    interval,
    vacancySunday,
    vacancyMonday,
    vacancyTuesday,
    vacancyWednesday,
    vacancyThursday,
    vacancyFriday,
    vacancySaturday
  } = form

  return (
    <Modal
      title='Cadastrar vagas por horário'
      maxWidth='1368px'
      isVisible={isVisible}
      onCloseClick={onCloseClick}
      customHeaderCss={[customHeaderCss]}
      customCloseIconCss={[customCloseIconCss]}
      customTitle={[titleStyle]}
    >
      <Row>
        <Column phone={12} desktop={4} css={padding0}>
          <InputFormGroup errorMessage={errors.initialTime} label='Horário Inicial'>
            <InputTimeMask
              value={initialTime}
              error={errors.initialTime}
              onChange={onInputChange('initialTime')}
              onBlur={onInputBlur('initialTime')}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={4} css={padding0}>
          <InputFormGroup errorMessage={errors.finalTime} label='Horário Final'>
            <InputTimeMask
              value={finalTime}
              error={errors.finalTime}
              onChange={onInputChange('finalTime')}
              onBlur={onInputBlur('finalTime')}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={4} css={padding0}>
          <InputFormGroup errorMessage={errors.interval} label='Tempo entre vagas(em minutos)'>
            <Input
              type='number'
              min='0'
              value={interval}
              error={errors.interval}
              onChange={onInputChange('interval')}
              onBlur={onInputBlur('interval')}
            />
          </InputFormGroup>
        </Column>
      </Row>

      <div css={[flexCenteredSpaceBetweenRow, horizontalPadding, flexColumnStart]}>
        <InputFormGroup css={vacancyDay} errorMessage={errors.vacancySunday} label='Domingo'>
          <Input
            type='number'
            min='0'
            value={vacancySunday}
            onChange={onInputChange('vacancySunday')}
            onBlur={onInputBlur('vacancySunday')}
          />
        </InputFormGroup>
        <InputFormGroup css={vacancyDay} errorMessage={errors.vacancyMonday} label='Segunda'>
          <Input
            type='number'
            min='0'
            value={vacancyMonday}
            onChange={onInputChange('vacancyMonday')}
            onBlur={onInputBlur('vacancyMonday')}
          />
        </InputFormGroup>
        <InputFormGroup css={vacancyDay} errorMessage={errors.vacancyTuesday} label='Terça'>
          <Input
            type='number'
            min='0'
            value={vacancyTuesday}
            onChange={onInputChange('vacancyTuesday')}
            onBlur={onInputBlur('vacancyTuesday')}
          />
        </InputFormGroup>
        <InputFormGroup css={vacancyDay} errorMessage={errors.vacancyWednesday} label='Quarta'>
          <Input
            type='number'
            min='0'
            value={vacancyWednesday}
            onChange={onInputChange('vacancyWednesday')}
            onBlur={onInputBlur('vacancyWednesday')}
          />
        </InputFormGroup>
        <InputFormGroup css={vacancyDay} errorMessage={errors.vacancyThursday} label='Quinta'>
          <Input
            type='number'
            min='0'
            value={vacancyThursday}
            onChange={onInputChange('vacancyThursday')}
            onBlur={onInputBlur('vacancyThursday')}
          />
        </InputFormGroup>
        <InputFormGroup css={vacancyDay} errorMessage={errors.vacancyFriday} label='Sexta'>
          <Input
            type='number'
            min='0'
            value={vacancyFriday}
            onChange={onInputChange('vacancyFriday')}
            onBlur={onInputBlur('vacancyFriday')}
          />
        </InputFormGroup>
        <InputFormGroup css={vacancyDay} errorMessage={errors.vacancySaturday} label='Sábado'>
          <Input
            type='number'
            min='0'
            value={vacancySaturday}
            onChange={onInputChange('vacancySaturday')}
            onBlur={onInputBlur('vacancySaturday')}
          />
        </InputFormGroup>
      </div>

      <div css={[flexHorizontallyCentered, marginTop20, mobileButtonContainer]}>
        <GhostButton css={[marginRight20, mobileBackButton, width100]} onClick={onCloseClick}>
          Cancelar
        </GhostButton>
        <Button css={[mobileContinueButton, width100]} onClick={onConfirm}>Registrar</Button>
      </div>
    </Modal>
  )
}

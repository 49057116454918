/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { HeaderPage } from '@bonitour/components'
import { useHistory } from 'react-router-dom'
import { useRef } from 'react'
import { InvoiceList } from 'containers/Invoices/InvoiceList'
import { useInvoiceRevenueList } from '../hooks/useInvoiceRevenueList'
import { useSellersList } from 'hooks/domains/useSellersList'

export const InvoicesReceive = () => {
  const { push } = useHistory()
  const backToDashboard = () => push('/dashboard')
  const navigateToInvoice = invoiceId => () => window.open(`/invoices-receive/${invoiceId}/edit`, '_blank', 'noopener noreferrer')

  const {
    invoices,
    filters,
    setFilters,
    changePagination,
    meta,
    totals = {},
    loading
  } = useInvoiceRevenueList()

  const { current: initialFilters } = useRef(filters)

  const { sellersOptionsCompanyFiltered } = useSellersList()

  return (
    <>
      <HeaderPage onBack={backToDashboard} title='Faturas a receber' />

      <InvoiceList
        invoiceList={invoices}
        totals={totals}
        invoiceMeta={meta}
        onNavigation={navigateToInvoice}
        onPaginate={changePagination}
        loading={loading}
        onFilterSubmit={setFilters}
        initialFilters={initialFilters}
        partnerOptions={sellersOptionsCompanyFiltered}
      />
    </>
  )
}

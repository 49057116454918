import { Button, colors } from '@bonitour/components'
import React, { useCallback, useMemo, useState } from 'react'
import { RefundTransactionsModal } from '../RefundTransactionsModal/RefundTransactionsModal'
import { css } from '@emotion/core'

export const reopenBatch = css`
  background-color: ${colors.red1};
`

export const RefundBatchButton = ({
  fetchPaymentBatch,
  paymentBatchId,
  batchStatus,
  disabled,
  transactions
}) => {
  const [showModal, setShowModal] = useState(false)

  const isAllowedToRefundBatch = useMemo(() => {
    const paidStatuses = ['paid', 'partially_paid']
    return paidStatuses.includes(batchStatus) && paymentBatchId
  }, [batchStatus, paymentBatchId])

  const onToggleModal = useCallback(() => {
    setShowModal(!showModal)
  }, [showModal])

  if (!isAllowedToRefundBatch) {
    return null
  }

  return (
    <>
      <RefundTransactionsModal
        paymentBatchId={paymentBatchId}
        fetchPaymentBatch={fetchPaymentBatch}
        onCloseModal={onToggleModal}
        transactions={transactions}
        isVisible={showModal}
      />
      <Button
        onClick={onToggleModal}
        customCss={[reopenBatch]}
        disabled={disabled}
      >
        Estornar pagamentos
      </Button>
    </>
  )
}

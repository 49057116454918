/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { marginBottom } from 'assets/styles/global'
import { H3, P, colors, BREAK_POINTS } from '@bonitour/components'
import { identity, tail } from '@bonitour/common-functions'
import { useMemo, useEffect } from 'react'
import { paxTypesList } from 'constants/paxTypes'
import { FeePaxDetails } from './FeePaxTypeDetails'

const marginBottom20 = marginBottom(20)
const marginBottom10 = marginBottom(10)

const description = css`
  font-size: 14px;
  color: ${colors.gray4};
`

const uniqueAndDefaultContainer = css`
  display: flex;

  @media (max-width: ${BREAK_POINTS.desktop}) {
    flex-direction: column;
  }
`

export const FeePaxType = ({
  activityMinAge = 1,
  feePrice = 0,
  feePaxTypes = [],
  feePaxTypesErrors = [],
  paxTypeNeedValidation = [],
  paxTypeWhenIsPrivate = [],
  aditionalPax = [],
  paxTypeSingleFee = '',
  paxPrivateFee = '',
  onChange = identity,
  onInputBlur = identity,
  onItemAddition = identity,
  onItemRemoval = identity
}) => {
  const paxTypes = useMemo(() => paxTypesList(activityMinAge), [activityMinAge])
  const uniqueFeeFilter = ['Tarifa Única', 'Privativo']
  const defaultFeeFilter = ['Adulto', 'Criança', 'Sênior']
  const aditionalFeeFilter = ['Não pagante', 'Meia', 'Regional', 'Cortesia']
  const uniqueFee = paxTypes.filter(({ name }) => uniqueFeeFilter.includes(name))
  const defaultFee = paxTypes.filter(({ name }) => defaultFeeFilter.includes(name))
  const aditionalFee = paxTypes.filter(({ name }) => aditionalFeeFilter.includes(name))

  useEffect(() => {
    const hasFeePaxTypes = feePaxTypes.length > 0
    const lastPaxType = (tail(feePaxTypes) || {}).name

    const hasPaxTypeNeedValidation = hasFeePaxTypes && paxTypeNeedValidation.includes(lastPaxType)
    const hasSingleFee = hasFeePaxTypes && lastPaxType === paxTypeSingleFee
    const hasPrivateFee = hasFeePaxTypes && lastPaxType === paxPrivateFee
    const hasAditionalFee = hasFeePaxTypes && aditionalPax.includes(lastPaxType)

    const removePaxType = paxTypeName => {
      const index = feePaxTypes.findIndex(({ name }) => name === paxTypeName)
      index >= 0 && onItemRemoval(index)
    }

    if (hasPaxTypeNeedValidation) {
      removePaxType(paxTypeSingleFee)
      removePaxType(paxPrivateFee)
    }

    if (hasSingleFee) {
      paxTypeNeedValidation.forEach(removePaxType)
      removePaxType(paxPrivateFee)
    }

    if (hasPrivateFee) {
      paxTypeWhenIsPrivate.forEach(removePaxType)
      removePaxType(paxTypeSingleFee)
    }

    if (hasAditionalFee) {
      removePaxType(paxPrivateFee)
    }

    // eslint-disable-next-line
  }, [feePaxTypes])

  function breakReduceWhenConditionIsTrue (array, func, acc) {
    const result = func(acc, array[0])
    return array.length === 0
      ? acc
      : (result || breakReduceWhenConditionIsTrue(array.slice(1), func, result))
  }

  const isUniqueActive = breakReduceWhenConditionIsTrue(feePaxTypes, (_, currentValue) => uniqueFeeFilter.includes(currentValue?.name), [])
  const isAditionalActive = breakReduceWhenConditionIsTrue(feePaxTypes, (_, currentValue) => aditionalFeeFilter.includes(currentValue?.name), [])
  const isDefaultActive = breakReduceWhenConditionIsTrue(feePaxTypes, (_, currentValue) => defaultFeeFilter.includes(currentValue?.name), [])

  return (
    <>
      <H3 css={marginBottom10}>Tipificação de PAX</H3>
      <P css={[marginBottom20, description]}>A seleção de Tarifa única ou Multi Vagas implica no bloqueio das tarifas de adulto, criança e sênior.</P>
      <div css={[marginBottom20, uniqueAndDefaultContainer]}>
        <FeePaxDetails
          title='Tarifário único'
          isActive={isUniqueActive}
          feeType={uniqueFee}
          feePaxTypes={feePaxTypes}
          feePrice={feePrice}
          feePaxTypesErrors={feePaxTypesErrors}
          onChange={onChange}
          onInputBlur={onInputBlur}
          onItemAddition={onItemAddition}
          onItemRemoval={onItemRemoval}
        />
        <FeePaxDetails
          title='Tarifário padrão'
          isActive={isDefaultActive}
          feeType={defaultFee}
          feePaxTypes={feePaxTypes}
          feePrice={feePrice}
          feePaxTypesErrors={feePaxTypesErrors}
          onChange={onChange}
          onInputBlur={onInputBlur}
          onItemAddition={onItemAddition}
          onItemRemoval={onItemRemoval}
        />
      </div>
      <FeePaxDetails
        title='Tarifas adicionais'
        isActive={isAditionalActive}
        feeType={aditionalFee}
        feePaxTypes={feePaxTypes}
        feePrice={feePrice}
        feePaxTypesErrors={feePaxTypesErrors}
        onChange={onChange}
        onInputBlur={onInputBlur}
        onItemAddition={onItemAddition}
        onItemRemoval={onItemRemoval}
      />
    </>
  )
}

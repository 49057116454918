import { useCallback, useState } from 'react'
import { useToast } from '@bonitour/components'
import { ReportService } from 'core/services/Reports'
import { usePermissions } from 'hooks/usePermissions'
import { useParamsFilter } from 'hooks/useParamsFilter'

export const useListVouchers = () => {
  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()
  const [filters, setFilters] = useParamsFilter()
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({ currentPage: 1, perPage: 10 })
  const [vouchersData, setvouchersData] = useState({ vouchers: undefined, meta: undefined })
  const [totals, setTotals] = useState({ totals: undefined })

  const fetchVouchers = useCallback(({ filters, pagination }) => {
    setLoading(true)
    setTotals({})

    return ReportService.listVouchers({ filters, pagination }).then((data) => {
      setvouchersData(data)
      ReportService.vouchersTotals({ filters }).then(setTotals)
    }).catch((error) => {
      const hasPermission = handlePermission(error, 'Você não possui permissão de acesso ao relatório de vouchers')
      hasPermission && addToast('Ocorreu um erro ao realizar a busca')
    }).finally(() => setLoading(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFilterSubmit = filters => setFilters(filters)

  const changePagination = useCallback((currentPage, perPage) => {
    const params = { currentPage, perPage }
    setPagination(params)
    fetchVouchers({ filters, pagination: params })
    // eslint-disable-next-line
  }, [filters])

  return {
    filters,
    setFilters,
    onFilterSubmit,
    pagination,
    changePagination,
    vouchers: vouchersData?.vouchers,
    meta: vouchersData?.meta,
    totals,
    loading
  }
}

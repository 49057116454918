import { css } from '@emotion/core'

export const toggleStyle = css`
  margin-top: 1.35rem;
`

export const columnStyle = css`
  padding: 0;
`

export const containerWithScroll = css`
  width: 100%;
  max-height: 180px;
  overflow-y: auto;
`

export const deadlineTableStyle = css`
  padding: 0;
  border: 0;
  li > [class*='TableRow'] {
    margin-top: 1rem;
    &:first-of-type {
      margin-top: 0;
    }
  }
`

/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import { formatMoney } from '@bonitour/common-functions'
import { Card, H4 } from '@bonitour/components'
import { table } from './ExpandedTaxes.styles'
import { paymentMethodFees } from 'domains/BankAccounts/constants/bankAccountConstants'

export const ExpandedTaxes = ({ fees = [] }) => {
  const paymentMethodLabel = paymentMethod => {
    const { label = '' } = paymentMethodFees.find(item => item.value === paymentMethod) || {}
    return label
  }

  return (
    <Card>
      <H4>Taxas bancárias</H4>
      <table css={table}>
        <tr>
          <th>Forma de pagamento</th>
          <th>Taxa bancária</th>
        </tr>
        {fees.map(({ paymentMethod, value }) => (
          <tr key={paymentMethod}>
            <td>{paymentMethodLabel(paymentMethod)}</td>
            <td>{formatMoney(value)}</td>
          </tr>
        ))}
      </table>
    </Card>
  )
}

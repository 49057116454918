import { useCompany } from 'contexts/Company'
import { useEffect, useMemo, useState } from 'react'
import { SellersService } from 'services/Sellers/Service'

export const useSellersList = (hasNullOption = true, nullOptionLabel = 'Todos') => {
  const { id: companyId } = useCompany()
  const [sellers, setSellers] = useState([])

  const sellersCompanyFiltered = useMemo(
    () => sellers.filter(({ id }) => id !== companyId),
    [companyId, sellers]
  )

  useEffect(() => {
    SellersService.getList().then(setSellers)
  }, [])

  const sellersOptions = useMemo(() => {
    const options = sellers.reduce((acc, { id, name }) => acc.concat({ value: id, label: name }), [])
    if (hasNullOption) return [{ value: '', label: nullOptionLabel }, ...options]
    return options
  }, [sellers, hasNullOption, nullOptionLabel])

  const sellersOptionsCompanyFiltered = useMemo(() => {
    const options = sellersCompanyFiltered.reduce((acc, { id, name }) => acc.concat({ value: id, label: name }), [])
    if (hasNullOption) return [{ value: '', label: nullOptionLabel }, ...options]
    return options
  }, [sellersCompanyFiltered, hasNullOption, nullOptionLabel])

  return {
    sellers,
    sellersCompanyFiltered,
    sellersOptions,
    sellersOptionsCompanyFiltered
  }
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { AngleRightIcon, CircleThumbnail, colors } from '@bonitour/components'
import { circleColor, clickable } from 'assets/styles/global'

const cellColor = css`
  color: ${colors.primary};
`

const iconStyle = css`
  margin: 2px 0 0 3px;
`

const confirmTicket = css`
  display: flex;
  align-items: center;
`

export const ConfirmTicket = () => {
  return (
    <div css={confirmTicket}>
      <CircleThumbnail size={10} css={circleColor(colors.primary)} />
      <span css={[cellColor, clickable]}>Confirmar</span>
      <AngleRightIcon css={[cellColor, iconStyle]} />
    </div>
  )
}

import { cardBrands, providers, paymentMeansShort } from 'constants/acquirers'
import { useMemo } from 'react'

export const formatAcquirer = ({ name, brand, paymentMean }) => {
  const safeProviderName = providers[name] ?? name ?? '##'
  const safeCardBrand = cardBrands?.[brand] || brand || '##'
  const safePaymentMean = paymentMeansShort[paymentMean] ?? paymentMean ?? '##'
  return `${safeProviderName} - ${safeCardBrand} - ${safePaymentMean}`
}

export const useTransactionFormAcquirers = (method, acquirersList, acquirerId, clearInstallments) => {
  const [acquirers, acquirersOptions] = useMemo(() => {
    if (!method || !acquirersList) {
      return [[], []]
    }
    const acquirers = acquirersList.filter(({ transactionType }) => {
      return transactionType.replace('_card', '') === method
    })
    const acquirersOptions = [
      { value: '', label: 'Nenhum' },
      ...acquirers
        .map((acquirer) => ({
          value: acquirer.id,
          label: formatAcquirer(acquirer)
        }))
        .sort((a, b) => a.label.replace(/##/g, 'ZZZ').localeCompare(b.label.replace(/##/g, 'ZZZ')))
    ]

    return [acquirers, acquirersOptions]
  }, [acquirersList, method])

  const installmentsOptions = useMemo(() => {
    if (!acquirerId) {
      return []
    }

    clearInstallments()
    const acquirerFees = acquirers?.find(({ id }) => id === acquirerId)?.fees

    return (
      acquirerFees?.map(({ installments }) => ({
        value: installments,
        label: installments
      })) || []
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acquirerId, acquirers])

  return {
    acquirersOptions,
    installmentsOptions
  }
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useRef } from 'react'
import { identity } from '@bonitour/common-functions'
import { useClickOutside } from '@bonitour/app-functions'
import { clickable, loadingContainer, margin } from 'assets/styles/global'
import { EllipsisIcon, Label, LoadingAnimation, PlusIcon, Popover, TooltipOnHover, TrashIcon, Reference, Manager, EditInfoIcon, flexCenteredSpaceBetweenRow } from '@bonitour/components'
import { customLinkPassengerLabel, customPassengerNameStyle, buttonContainer, linkPassengerContainer, linkPassengerIcon, linkPassengerLabel, linkPassengerOptionsIcon, marginHorizontal50, passengerNameStyle, passengerOption, passengerOptionsContainer, passengerTicketIcon } from './LinkPassengerButton.style'

const margin5 = margin(5, 5, 5, 5)

export const LinkPassengerButton = ({
  ticketId = '',
  passengerName = '',
  type = '',
  linkPassengerLoading = false,
  isTransport = false,
  onLinkPassenger = identity,
  onUnlinkPassenger = identity,
  onEditPassenger = identity
}) => {
  const reference = useRef()
  const isPassengerNameOverflow = passengerName?.length > 17 || false
  const [isPassengerOptionsVisible, setPassengerOptionsVisibility] = useClickOutside(reference)
  const onClickPassengerOptions = () => setPassengerOptionsVisibility(!isPassengerOptionsVisible)

  if (ticketId === linkPassengerLoading) {
    return (
      <div css={linkPassengerContainer(isTransport)}>
        <LoadingAnimation size={25} css={[loadingContainer, marginHorizontal50]} />
      </div>
    )
  }

  return (
    <div css={linkPassengerContainer(isTransport)}>
      {passengerName &&
        <div css={[buttonContainer, flexCenteredSpaceBetweenRow, margin5]}>
          {isPassengerNameOverflow &&
            <TooltipOnHover size={250} text={`${passengerName} - ${type}`}>
              <span css={isTransport ? customPassengerNameStyle : passengerNameStyle}>{`${passengerName} - ${type}`}</span>
            </TooltipOnHover>}

          {!isPassengerNameOverflow && <span css={passengerNameStyle}>{`${passengerName} - ${type}`}</span>}

          <div ref={reference}>
            <Manager>
              <Reference>
                {({ ref }) => (
                  <div css={clickable} ref={ref} onClick={onClickPassengerOptions}>
                    <EllipsisIcon css={linkPassengerOptionsIcon} />
                  </div>
                )}
              </Reference>
              {isPassengerOptionsVisible && (
                <Popover position='left'>
                  <div css={passengerOptionsContainer}>
                    <div css={passengerOption} onClick={onEditPassenger}>
                      <EditInfoIcon css={passengerTicketIcon} />
                      <span>Editar viajante</span>
                    </div>
                    <div css={passengerOption} onClick={onUnlinkPassenger}>
                      <TrashIcon css={passengerTicketIcon} />
                      <span>Desvincular viajante</span>
                    </div>
                  </div>
                </Popover>
              )}
            </Manager>
          </div>
        </div>}

      {!passengerName &&
        <div css={[buttonContainer, clickable]} onClick={onLinkPassenger}>
          <PlusIcon css={linkPassengerIcon} />
          <Label css={isTransport ? customLinkPassengerLabel : linkPassengerLabel}>{`Vincular ${type}`}</Label>
        </div>}
    </div>
  )
}

import { css } from '@emotion/core'
import { BREAK_POINTS, colors, flexRowCentered, scrollBar } from '@bonitour/components'

export const removeLinkProperties = css`
  text-decoration: none;
  color: initial;
`

export const countSwatch = css`
  ${flexRowCentered};
  width: 18px;
  height: 18px;
  background-color: ${colors.primary};
  color: ${colors.white};
  font-size: 12px;
  padding: 5px;
  margin-left: 5px;
  border-radius: 100%;
`

export const dateSelectorContainer = css`
  margin: 0 0 20px;
`

export const circleButton = css`
  margin-right: 10px;
  border-color: transparent;

  &:hover{
    box-shadow: 0 0 5px #00000033;
  }
`

export const alignCenter = css`
  text-align: center;
`

export const eyeIcon = css`
  font-size: 20px;
  color: ${colors.gray3};
`

export const selectorPadding = css`
  padding-left: 15px;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    padding: 0;
  }
`

export const tableContainer = css`
  overflow-x: auto;
  ${scrollBar(5)};
`

export const activitySelectorContainer = css`
  ${flexRowCentered};

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    flex-direction: column;
  }
`

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useMemo } from 'react'
import { identity } from '@bonitour/common-functions'
import { PaginationContainer, Modal } from '@bonitour/components'
import { BatchesTable } from '../BatchesTable/BatchesTable'
import { PaymentBatchForm } from '../Form/PaymentBatchForm'

export const GeneratedBatchesList = ({
  batches = [],
  total = 0,
  pagesQuantity = 1,
  batch = {},
  isBatchDetailVisible,
  batchDetailHidden: emitBatchDetailHidden = identity,
  loading,
  onBatchClick: emitBatchClick = identity,
  onPagination: emitPaginationEvent = identity,
  onBatchDelete: emitBatchDelete = identity,
  onSubmit: emitSubmit = identity
}) => {
  const { reference, vouchers } = batch

  const totals = useMemo(() => {
    return {
      vouchersQuantity: vouchers?.length || 0,
      value: vouchers?.reduce((sum, { value }) => sum + value, 0) || 0
    }
  }, [vouchers])

  return (
    <>
      <PaginationContainer total={total} pagesQuantity={pagesQuantity} onPagination={emitPaginationEvent}>
        <BatchesTable
          batches={batches}
          onBatchDelete={emitBatchDelete}
          onBatchClick={emitBatchClick}
          loading={loading}
        />
      </PaginationContainer>

      <Modal title={`Lote: ${reference}`} isVisible={isBatchDetailVisible} onCloseClick={emitBatchDetailHidden}>
        <PaymentBatchForm
          batch={batch}
          totals={totals}
          onCloseClick={emitBatchDetailHidden}
          onSubmit={emitSubmit}
        />
      </Modal>
    </>
  )
}

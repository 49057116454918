import { formatMoney, unformatMoney } from '@bonitour/common-functions'
import { string, ref } from 'yup'

export const entrySchema = (maxDebitValue) => ({
  seller: string().required('Informe um parceiro'),
  type: string().required('Informe o tipo do lançamento'),
  value: string()
    .required('Informe um valor')
    .test('max-value', `O valor máximo para lançamento de débito é ${formatMoney(maxDebitValue)}`, function (value) {
      if (this.resolve(ref('type')) === 'debit') {
        return unformatMoney(value) <= maxDebitValue
      }
      return true
    }),
  description: string().nullable(),
  originBankAccount: string().nullable(),
  destinationBankAccount: string().nullable(),
  paymentType: string().nullable()
})

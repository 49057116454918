import { orbCore } from 'services/Orb/Service'
import { PaymentBatchModel } from 'core/models/paymentBatch'
import { PaymentBatchParser } from 'core/parsers/paymentBatchParser'
import { convertToFormData } from '@bonitour/common-functions'

const { paymentBatchDomain, paymentBatchDomainV2 } = orbCore

export const PaymentBatchService = {
  getPaymentBatches: ({ filters, pagination }) => {
    return paymentBatchDomain
      .getPaymentBatches(
        PaymentBatchModel.paymentBatches({ filters, pagination })
      ).then(PaymentBatchParser.paymentBatchList)
  },
  getPaymentBatchesTotalizers: ({ filters }) => {
    return paymentBatchDomain
      .getPaymentBatchTotalizers(
        PaymentBatchModel.paymentBatchesTotalizers({ filters })
      )
      .then(PaymentBatchParser.paymentBatchTotalizers)
  },
  editPaymentBatch: (paymentBatchId, batch) => {
    const parsedBatch = PaymentBatchModel.paymentEditBatch({ batch })
    return paymentBatchDomain.editPaymentBatch(paymentBatchId, parsedBatch)
  },
  registerPaymentToBatch: (paymentBatchId, paymentData) => {
    const parsedPayment = PaymentBatchModel.registerPayment({ paymentData })
    return paymentBatchDomain.registerPaymentToBatch(paymentBatchId, convertToFormData(parsedPayment))
  },
  showPaymentBatch: paymentBatchId => {
    return paymentBatchDomain.showPaymentBatch(paymentBatchId).then(data =>
      PaymentBatchParser.paymentBatch(data?.payment_batch)
    )
  },
  deletePaymentBatch: paymentBatchId => {
    return paymentBatchDomain.deletePaymentBatch(paymentBatchId)
  },
  createPaymentBatch: (batch) => {
    const parsedBatch = PaymentBatchModel.paymentCreateBatch({ batch })
    return paymentBatchDomain.createPaymentBatch(parsedBatch).then(data =>
      PaymentBatchParser.paymentBatch(data?.payment_batch)
    )
  },
  addBatchItems: (id, batch) => {
    const parsedBatch = PaymentBatchModel.addBatchItems({ batch })
    return paymentBatchDomain.addBatchItems(id, parsedBatch)
  },
  getTicketsToBatch: ({ filters, pagination }, beneficiaryId = '', beneficiaryType = '') => {
    return paymentBatchDomain.getTicketsToBatch(
      PaymentBatchModel.ticketsToBatch({
        filters: {
          ...filters,
          beneficiaryType,
          beneficiaryId
        },
        pagination
      }))
      .then(PaymentBatchParser.ticketsToBatch)
  },
  changeStatus: ({ id, status }) => {
    return paymentBatchDomain.changeStatus(id, status)
  },

  refundTransaction: ({ id, transactionId }) => {
    return paymentBatchDomain.refundTransaction(id, transactionId)
  },

  __legacy_showPaymentBatch: paymentBatchId => paymentBatchDomain.showPaymentBatch(paymentBatchId).then(data => {
    return PaymentBatchParser.paymentBatch(data?.payment_batch)
  }),
  __legacy_editPaymentBatch: (paymentBatchId, batch) => {
    const parsedBatch = PaymentBatchModel.paymentBatch({ batch })
    return paymentBatchDomain.updatePaymentBatch(paymentBatchId, parsedBatch)
  }
}

export const PaymentBatchServiceV2 = {
  getPaymentBatches: ({ filters, pagination }) => {
    return paymentBatchDomainV2
      .getPaymentBatches(
        PaymentBatchModel.paymentBatches({ filters, pagination })
      ).then(PaymentBatchParser.paymentBatchList)
  },
  getPaymentBatchesTotalizers: ({ filters }) => {
    return paymentBatchDomainV2
      .getPaymentBatchTotalizers(
        PaymentBatchModel.paymentBatchesTotalizers({ filters })
      )
      .then(PaymentBatchParser.paymentBatchTotalizers)
  },
  editPaymentBatch: (paymentBatchId, batch) => {
    const parsedBatch = PaymentBatchModel.paymentEditBatch({ batch })
    return paymentBatchDomainV2.editPaymentBatch(paymentBatchId, parsedBatch)
  },
  showPaymentBatch: paymentBatchId => {
    return paymentBatchDomainV2.showPaymentBatch(paymentBatchId).then(data =>
      PaymentBatchParser.paymentBatch(data?.payment_batch)
    )
  }
}

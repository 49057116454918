import { colors } from '@bonitour/components'
import { orbEntities } from 'constants/orbEntities'

export const reservationTimelineActionTypes = {
  RESERVATION: orbEntities.RESERVATION,
  TICKET: orbEntities.TICKET,
  PAYMENT: orbEntities.RESERVATION_PAYMENT,
  REFUND: orbEntities.RESERVATION_REFUND,
  TICKET_BALANCE_TRANSFER: orbEntities.TICKET_BALANCE_TRANSFER,
  ADVANCE_PAYMENT: orbEntities.ADVANCE_RESERVATION_PAYMENT,
  ADVANCE_REFUND: orbEntities.ADVANCE_RESERVATION_REFUND
}

export const reservationTextColor = colors.gray3

export const reservationTimelineLabels = {
  [reservationTimelineActionTypes.PAYMENT]: 'pagamento',
  [reservationTimelineActionTypes.REFUND]: 'reembolso',
  [reservationTimelineActionTypes.TICKET_BALANCE_TRANSFER]: 'Transferência de saldo para pagamento de ingresso',
  [reservationTimelineActionTypes.ADVANCE_PAYMENT]: 'pagamento antecipado',
  [reservationTimelineActionTypes.ADVANCE_REFUND]: 'reembolso de pagamento antecipado'
}

export const reservationTimelineFiltersLabels = {
  [reservationTimelineActionTypes.PAYMENT]: 'Pagamento',
  [reservationTimelineActionTypes.REFUND]: 'Reembolso',
  [reservationTimelineActionTypes.TICKET_BALANCE_TRANSFER]: 'Transferências de saldo',
  [reservationTimelineActionTypes.ADVANCE_PAYMENT]: 'Pagamentos antecipados',
  [reservationTimelineActionTypes.ADVANCE_REFUND]: 'Reembolsos de pagamentos antecipados'
}

export const reservationTimelineFiltersEventTypes = {
  create: 'Criação',
  update: 'Atualização',
  destroy: 'Deleção'
}

export const timelineEventTypes = Object.keys(reservationTimelineFiltersEventTypes).reduce((acc, curr) => {
  return {
    ...acc,
    [curr]: curr
  }
}, {})

export const timelineStateTypes = {
  approved: 'approved'
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { TableBodyRow, TableBodyCell, Swatch } from '@bonitour/components'
import { clickable } from 'assets/styles/global'
import { formatMoney, identity } from '@bonitour/common-functions'
import { memo } from 'react'
import { invoiceStatusMap } from 'constants/invoiceStatus'

const swatchInline = css`
  display: inline-block;
  margin-right: 10px;
`

const padding10 = css`
  padding: 20px 10px;
`

const InvoiceStatus = memo(({ status }) => {
  const [label, color] = invoiceStatusMap[status] || invoiceStatusMap.pending
  return (
    <>
      <Swatch size={10} color={color} css={swatchInline} />
      {label}
    </>
  )
})

export const InvoiceTableRow = ({ invoice = {}, onNavigation: emitInvoiceClickRow = identity }) => {
  const { partnerName, dueDate, amountLeft, status, documentNumber, invoiceTotalValue, startCompetence, endCompetence } = invoice
  return (
    <TableBodyRow css={clickable} onClick={emitInvoiceClickRow}>
      <TableBodyCell css={padding10}>{dueDate}</TableBodyCell>
      <TableBodyCell css={padding10}>{`${startCompetence} - ${endCompetence}`}</TableBodyCell>
      <TableBodyCell css={padding10}>{partnerName}</TableBodyCell>
      <TableBodyCell css={padding10}>{documentNumber}</TableBodyCell>
      <TableBodyCell css={padding10}>{formatMoney(invoiceTotalValue)}</TableBodyCell>
      <TableBodyCell css={padding10}>{formatMoney(amountLeft)}</TableBodyCell>
      <TableBodyCell css={padding10}>
        <InvoiceStatus status={status} />
      </TableBodyCell>
    </TableBodyRow>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { H3, Input, ToggleInputGroup, InputHeightMask, InputWeightMask } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { toggleContainer, container, inputStyle, label, marginBottom20, marginTop50, toggle } from './ActivityRules.style'

const RuleRestriction = ({
  restrictions = {},
  restrictionKey = '',
  enabledValue = '1',
  disabledValue = '0',
  children,
  onChange: emitChangeEvent
}) => {
  const keyValue = restrictions[restrictionKey]
  const hasValue = restrictionKey === 'maxWeight' ? parseFloat(keyValue) !== 0 : keyValue !== '0'

  const resetValue = () => (hasValue ? disabledValue : enabledValue)
  const toggleValue = () => emitChangeEvent(resetValue())

  return (
    <div>
      <ToggleInputGroup id={restrictionKey} onChange={toggleValue} checked={hasValue} customCss={[toggleContainer]} customToggleCss={[toggle]} customLabelCss={[label]}>
        {children}
        {hasValue && restrictionKey === 'minAge' &&
          <Input min={1} max={100} type='number' value={keyValue} css={[inputStyle]} onChange={emitChangeEvent} />}

        {hasValue && restrictionKey === 'minHeight' &&
          <InputHeightMask value={keyValue} css={inputStyle} onChange={emitChangeEvent} />}

        {hasValue && restrictionKey === 'maxWeight' &&
          <InputWeightMask value={keyValue} css={inputStyle} onChange={emitChangeEvent} />}
      </ToggleInputGroup>
    </div>
  )
}

export const ActivityRules = ({ activityRules = {}, onChange: emitChangeEvent = identity }) => {
  const { restrictions = {} } = activityRules

  return (
    <div css={container}>
      <H3 css={[marginTop50, marginBottom20]}>Limitações Técnicas</H3>
      <RuleRestriction
        restrictions={restrictions}
        onChange={emitChangeEvent('activityRules.restrictions.minAge')}
        restrictionKey='minAge'
      >
        Idade mínima
      </RuleRestriction>
      <RuleRestriction
        restrictions={restrictions}
        onChange={emitChangeEvent('activityRules.restrictions.minHeight')}
        restrictionKey='minHeight'
      >
        Altura mínima (metros)
      </RuleRestriction>
      <RuleRestriction
        restrictions={restrictions}
        onChange={emitChangeEvent('activityRules.restrictions.maxWeight')}
        restrictionKey='maxWeight'
      >
        Peso máximo (kg)
      </RuleRestriction>
    </div>
  )
}

/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import { InfoIcon, Manager, Reference, Popover, H4 } from '@bonitour/components'
import { formatMoney, unformatMoney } from '@bonitour/common-functions'
import { useHover } from 'hooks/useHover'
import { alreadyPaidStyle, referenceStyle, container, historicContainer, hidePopover, paymentDetailContainer, paymentDetail, paymentDetailTitle, historyStyle, excedingValue, remainingValue } from './TicketDetail.styles'
import { paymentTypesMap } from 'constants/reservationPaymentTypes'
import { formatISOTime } from 'utils/time'
import { infoIconStyle } from 'assets/styles/global'

export const TicketDetail = ({
  value,
  ticketPrice,
  alreadyPaidTicketValue,
  sentLinksValue = 0,
  remainingTicketValue,
  historic = [],
  isRefund = false,
  isPaymentShow = false,
  isEditing = false,
  popoverPositionStyle
}) => {
  const [isVisible, showOnHover, hideOnHover] = useHover()
  const hasRemaingValue = parseFloat(remainingTicketValue) !== 0
  const ticketValue = isRefund ? formatMoney(alreadyPaidTicketValue) : hasRemaingValue ? formatMoney(remainingTicketValue) : formatMoney(value)

  const parseHistory = history => {
    const parseTimeRegex = /DATE<(.+)>/
    const parsePaymentMethodRegex = new RegExp(Object.keys(paymentTypesMap).join('|'), 'gi')
    const [replace, unparsedDate] = history.match(parseTimeRegex) || []
    return history
      .replace(parsePaymentMethodRegex, matched => (paymentTypesMap[matched]).toLowerCase())
      .replace(replace, formatISOTime(unparsedDate, 'DD/MM/YY [ás] HH:mm.'))
      .replace('foi', 'foi:')
  }

  const isOverpaid = remainingTicketValue < 0

  return (
    <div css={container}>
      <div css={referenceStyle}>
        {formatMoney(isPaymentShow || isEditing ? value : Math.max(unformatMoney(ticketValue), 0))}
        {hasRemaingValue && <p css={alreadyPaidStyle}>{formatMoney(ticketPrice)}</p>}
      </div>
      <Manager>
        <Reference>
          {({ ref }) => (
            <span ref={ref} css={referenceStyle} onMouseLeave={hideOnHover} onMouseOver={showOnHover}>
              <InfoIcon css={infoIconStyle} />
            </span>
          )}
        </Reference>
        <Popover position='top' css={[popoverPositionStyle, !isVisible && hidePopover]} onMouseOver={showOnHover} onMouseLeave={hideOnHover}>
          <div css={paymentDetailContainer}>
            <H4 css={paymentDetailTitle}>Detalhamento</H4>
            <div css={paymentDetail}>
              <p>Valor do ingresso</p>
              <p>{formatMoney(ticketPrice)}</p>
            </div>
            <div css={paymentDetail}>
              <p>Valor pago</p>
              <p>{formatMoney(alreadyPaidTicketValue)}</p>
            </div>
            <div css={paymentDetail}>
              <p>Valor em links pendentes</p>
              <p>{formatMoney(sentLinksValue)}</p>
            </div>
            {!isRefund &&
              <div css={[paymentDetail, isOverpaid ? excedingValue : remainingValue]}>
                <p>{isOverpaid ? 'Valor excedente' : 'Valor restante'}</p>
                <p>{formatMoney(Math.abs(remainingTicketValue))}</p>
              </div>}
            <div css={historicContainer}>
              {historic.map(history => (
                <>
                  <hr />
                  <p css={historyStyle}>{parseHistory(history['pt-br'])}</p>
                </>
              ))}
            </div>
          </div>
        </Popover>
      </Manager>
    </div>
  )
}

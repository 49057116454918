/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { TableBodyCell, Reference, Manager, colors } from '@bonitour/components'
import { ReservationsDetails } from '../Guides/ReservationsDetails'
import { VacancyPopover } from '../Guides/VacancyPopover'
import { useHover } from 'hooks/useHover'
import { BookingCard } from 'domains/Booking/Map/Card/BookingCard'
import { VacancyCard } from 'domains/Booking/Map/Card/VacancyCard'
import { clickable } from 'assets/styles/global'
import { identity } from '@bonitour/common-functions'

const summaryContainer = css`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

const summaryWarning = css`
  border: 2px solid ${colors.red4};
`

const vacancyTableBodyCell = css`
  padding: 5px;
`

const SummaryContainer = ({ children }) => {
  return (
    <TableBodyCell css={vacancyTableBodyCell}>
      <div css={summaryContainer}>{children}</div>
    </TableBodyCell>
  )
}

const DetailedBooking = ({ totalReservations, reservationDetails, ...other }) => {
  const [isVisible, showDetails, hideDetails] = useHover(300)

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <div
            ref={ref}
            onMouseOver={showDetails}
            onMouseOut={hideDetails}
          >
            <BookingCard disabled={!totalReservations} {...other}>
              {totalReservations}
            </BookingCard>
            {isVisible && <ReservationsDetails reservationDetails={reservationDetails} />}
          </div>
        )}
      </Reference>
    </Manager>
  )
}

export const VacancyTableSummaryCell = ({
  date = '',
  rowHour = '',
  VacancySummaryPopoverContent,
  vacancies: { total: totalVacancies = 0 } = {},
  reservations: { total: totalReservations = 0, details: reservationDetails = [] } = {},
  isExpandable = true,
  isAllowedBookingDate,
  onExpand: emitExpandEvent = identity,
  ticketTariff
}) => {
  const hasVacancies = totalVacancies > 0
  const hasNegativeVacancy = totalVacancies < 0

  return (
    <SummaryContainer>
      {hasVacancies && isAllowedBookingDate
        ? (
          <VacancyPopover totalVacancies={totalVacancies} hour={rowHour} date={date} ticketTariff={ticketTariff} PopoverContent={VacancySummaryPopoverContent}>
            <VacancyCard>{totalVacancies}</VacancyCard>
          </VacancyPopover>
        )
        : (
          <VacancyCard css={[hasNegativeVacancy && summaryWarning]} disabled>
            {totalVacancies}
          </VacancyCard>
        )}
      {
        reservationDetails.length
          ? (
            <DetailedBooking
              totalReservations={totalReservations}
              reservationDetails={reservationDetails}
              css={[isExpandable && clickable]} onClick={isExpandable ? emitExpandEvent : identity}
            />
          )
          : (
            <BookingCard disabled={!totalReservations}>
              {totalReservations}
            </BookingCard>
          )
      }
    </SummaryContainer>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { identity } from '@bonitour/common-functions'
import { jsx } from '@emotion/core'

import { Popover, Reference, Manager, PrinterIcon, EditInfoIcon } from '@bonitour/components'
import { externalCodeStyle, voucherActionIconSize, hidePopover, popoverStyle } from './GeneratedVoucher.style'
import { ProcessingContainer } from '../ProcessingContainer/ProcessingContainer'
import { ExternalCodeAction } from '../ExternalCodeAction/ExternalCodeAction'
import { useHover } from 'hooks/useHover'
import { useMemo } from 'react'

export const GeneratedVoucher = ({
  handleTicketPrint = identity,
  externalCode = '',
  status = '',
  id = '',
  externalIntegrationResponse = '',
  toggleEditVoucher = identity
}) => {
  const [isVisible, showOnHover, hideOnHover] = useHover()
  const canEdit = useMemo(() => externalIntegrationResponse === 'Código gerado e associado manualmente', [externalIntegrationResponse])
  return (
    <div
      onMouseLeave={hideOnHover}
      onMouseOver={showOnHover}
    >
      <Manager>
        <Reference>
          {({ ref }) => (
            <span ref={ref} css={externalCodeStyle}>
              <ProcessingContainer status={status}>{externalCode}</ProcessingContainer>
            </span>
          )}
        </Reference>
        <Popover position='top-start' css={[popoverStyle, !isVisible && hidePopover]}>
          <ExternalCodeAction handleFunction={handleTicketPrint} ticketId={id} title='Imprimir voucher'>
            <PrinterIcon css={voucherActionIconSize} />
          </ExternalCodeAction>
          {canEdit && (
            <ExternalCodeAction handleFunction={toggleEditVoucher} ticketId={id} title='Editar código'>
              <EditInfoIcon css={voucherActionIconSize} />
            </ExternalCodeAction>
          )}
        </Popover>
      </Manager>
    </div>
  )
}

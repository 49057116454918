/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Card, PaginationContainer, HeaderPage } from '@bonitour/components'
import { useHistory } from 'react-router-dom'
import { RevenueFilter } from './RevenueFilter'
import { useListRevenues } from 'domains/CompanyReports/hooks/useListRevenues'
import { useRef } from 'react'
import { RevenueTable } from '../Table/RevenueTable'
import { RevenueResults } from './RevenueResults'

export const RevenueList = () => {
  const { push } = useHistory()
  const backToReports = () => push('/reports')

  const {
    filters,
    onFilterSubmit,
    changePagination,
    revenues,
    meta,
    isLoading,
    update,
    isLoadingTotals,
    totals
  } = useListRevenues()

  const { current: initialFilters } = useRef(filters)

  return (
    <>
      <HeaderPage onBack={backToReports} title='Receita' />

      <Card>
        <RevenueFilter
          onFilterSubmit={onFilterSubmit}
          initialFilters={initialFilters}
        />

        <RevenueResults totals={totals} isLoading={isLoadingTotals} />

        <PaginationContainer total={meta?.total} pagesQuantity={meta?.totalPages} onPagination={changePagination}>
          <RevenueTable revenues={revenues} loading={isLoading} onUpdate={update} />
        </PaginationContainer>

      </Card>
    </>
  )
}

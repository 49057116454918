/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { ColumnsToExport, FileExportIcon, Button, useToast, Dialog } from '@bonitour/components'
import { jsx } from '@emotion/core'
import { clickable } from 'assets/styles/global'
import { identity } from '@bonitour/common-functions'
import { useForm } from '@bonitour/app-functions'
import { useEffect, useState } from 'react'
import { string, array } from 'yup'
import { baseExportToggle, dialogContainer, exportContainer, exportContainerOpen, exportIconContainer, exportIconStyle, exportOpenIconStyle, exportToggleButtonClosed, exportToggleButtonOpen } from './ExportFile.style'

const exportSchema = {
  wantedFields: array().min(1, 'É necessário escolher alguma coluna'),
  format: string().required('É necessário escolher o formato')
}

export const ExportFile = ({
  onSubmitExport = identity,
  loading,
  initialFilters,
  filters = {},
  multiSelectOptions = [],
  formatOptions = []
}) => {
  const { add: addToast } = useToast()
  const { form, errors, onSubmit, utils: { onInputChange } } = useForm(initialFilters, exportSchema)
  const { wantedFields = [], format = '' } = form
  const [isVisible, setVisibility] = useState(false)
  const [isMultiSelectOpen, setIsMultiSelectOpen] = useState(false)
  const toggleMultiSelect = () => setIsMultiSelectOpen(!isMultiSelectOpen)
  const toggleVisible = () => setVisibility(true)
  const toggleHidden = () => setVisibility(false)

  const [areAllSelected, setAreAllSelected] = useState(false)

  useEffect(() => {
    onInputChange('wantedFields')(multiSelectOptions.map(option => option.label))
    // eslint-disable-next-line
  }, [multiSelectOptions])

  useEffect(() => {
    onInputChange('format')(formatOptions?.[0]?.value || '')
    // eslint-disable-next-line
  }, [formatOptions])

  useEffect(() => {
    onInputChange('wantedFields')(wantedFields && [...wantedFields])
    allScheduleChange()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (multiSelectOptions.length === wantedFields.length) {
      setAreAllSelected(true)
    } else setAreAllSelected(false)
    // eslint-disable-next-line
  }, [wantedFields])

  const isChecked = (selectedOption) => wantedFields.includes(selectedOption)

  const onOptionChange = (selectedOption) => () => {
    if (isChecked(selectedOption)) {
      onInputChange('wantedFields')(wantedFields && wantedFields.filter((item) => item !== selectedOption))
    } else {
      onInputChange('wantedFields')([...wantedFields, selectedOption])
    }
  }

  const allScheduleChange = () => {
    areAllSelected
      ? onInputChange('wantedFields')([])
      : onInputChange('wantedFields')(multiSelectOptions.map(option => option.label))
  }

  const validateFilters = ({ wantedFields }) => {
    const exportColumns = wantedFields && wantedFields.map(fieldType => multiSelectOptions.reduce((acc, { value, label }) => {
      if (fieldType === label) return acc.concat(value)
      return acc
    }, ''))

    onSubmitExport({ wantedFields: exportColumns, format }, filters)
    toggleHidden()
  }

  const validateAllFilters = () => {
    const allColumns = multiSelectOptions.map(option => option.value)
    onSubmitExport({ wantedFields: allColumns, format }, filters)
    toggleHidden()
  }

  const onError = () => addToast('Preencha o formulário corretamente')
  const onClick = onSubmit(validateFilters, onError)
  const onExportAll = onSubmit(validateAllFilters)

  return (
    <>
      <div css={exportIconContainer}>
        <div onClick={toggleVisible} css={[clickable, isVisible ? exportContainerOpen : exportContainer]}>
          <Button className='exportButton' css={[isVisible ? exportToggleButtonOpen : exportToggleButtonClosed, baseExportToggle]}>
            <FileExportIcon css={[isVisible ? exportOpenIconStyle : exportIconStyle]} />
            Exportar
          </Button>
        </div>
      </div>

      <Dialog title='Configuração da exportação da tabela' isVisible={isVisible} onClose={toggleHidden} customContainercss={dialogContainer}>
        <ColumnsToExport
          toggleMultiSelect={toggleMultiSelect}
          wantedFields={wantedFields}
          format={format}
          errors={errors}
          isMultiSelectOpen={isMultiSelectOpen}
          multiSelectOptions={multiSelectOptions}
          formatOptions={formatOptions}
          isChecked={isChecked}
          onOptionChange={onOptionChange}
          onInputChange={onInputChange}
          allScheduleChange={allScheduleChange}
          areAllSelected={areAllSelected}
          loading={loading}
          onClick={onClick}
          onExportAll={onExportAll}
        />
      </Dialog>
    </>
  )
}

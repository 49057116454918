/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { TableBodyRow, TableBodyCell, colors, TooltipOnHover, PencilIcon, TrashIcon, ConfirmDialog, flex } from '@bonitour/components'
import { clickable, marginRight, resetLink } from 'assets/styles/global'
import { NavLink } from 'react-router-dom'
import { identity } from '@bonitour/common-functions'
import { useState } from 'react'
import { cardBrands, providers, transactionTypes } from 'constants/acquirers'

const smallField = css`
  max-width: 80px;
`

const iconStyle = css`
  font-size: 23px;
  color: ${colors.gray3};
`

const defaultCellPadding = css`
  width: 100px;
  padding: 0 0 0 20px;
`

export const AcquirerTableRow = ({ acquirer = {}, onDeleteAcquirer = identity }) => {
  const { name = '', transactionType = '', brand = '', id: acquirerId = '' } = acquirer
  const acquirerEditUrl = `/acquirers/${acquirerId}/edit`
  const handleAcquirerDelete = acquirerId => () => onDeleteAcquirer(acquirerId)
  const [isConfirmActionVisible, setConfirmActionVisible] = useState(false)
  const toggleConfirmActionVisible = () => setConfirmActionVisible(true)
  const toggleConfirmActionHidden = () => setConfirmActionVisible(false)

  return (
    <>
      <ConfirmDialog
        title='Exclusão de adquirente'
        message='Esse adquirente será excluído permanentemente. Deseja continuar?'
        isVisible={isConfirmActionVisible}
        successCallback={handleAcquirerDelete(acquirerId)}
        cancelCallback={toggleConfirmActionHidden}
      />

      <TableBodyRow>
        <TableBodyCell>
          <div css={smallField}>{providers[name]}</div>
        </TableBodyCell>
        <TableBodyCell>
          <div css={smallField}>{cardBrands?.[brand] || '--'}</div>
        </TableBodyCell>
        <TableBodyCell>
          <div css={smallField}>{transactionTypes[transactionType]}</div>
        </TableBodyCell>
        <TableBodyCell css={defaultCellPadding} fixed>
          <div css={flex}>
            <TooltipOnHover text='Editar adquirente'>
              <NavLink to={acquirerEditUrl} css={[resetLink, marginRight(10)]}>
                <PencilIcon css={[iconStyle, clickable]} />
              </NavLink>
            </TooltipOnHover>
            <TooltipOnHover text='Excluir adquirente'>
              <TrashIcon css={[iconStyle, clickable]} onClick={toggleConfirmActionVisible} />
            </TooltipOnHover>
          </div>
        </TableBodyCell>
      </TableBodyRow>
    </>
  )
}

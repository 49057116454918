/* eslint-disable camelcase */
import moment from 'moment'
import { tail } from '@bonitour/common-functions'
import { getImageUrl } from 'services/Orb/Service'
import { ReservationTicketParser } from './Reservation/ReservationTicket'

export const RefundParser = {
  singleRefund (refund = {}) {
    const {
      id = '',
      value,
      refund_method: method,
      state,
      refund_date: isoDate,
      installments = 0,
      receipt = '',
      tickets = [],
      part_refunds: partRefunds = [],
      origin_bank_account = {},
      acquirer_id = '',
      doc = '',
      aut = '',
      brand = '',
      acquirer_name = '',
      payment_mean = ''
    } = refund.reservation_refund
    const day = moment.utc(isoDate).format('YYYY-MM-DD')
    const time = moment.utc(isoDate).format('HH:mm')

    const partRefundTickets = (partRefunds || [])
      .map(({ part_refund_tickets: tickets }) => tickets || [])
      .flat() || []

    const ticketsRefunded = (tickets || []).map(ticket => {
      const { value: paidValue = 0 } = partRefundTickets
        .find(({ ticket_id: ticketId }) => ticketId === ticket?.id) || {}

      return {
        ...ticket,
        paid_value: paidValue
      }
    })

    return {
      id,
      value,
      method,
      state,
      day,
      time,
      tickets: (ticketsRefunded || []).map(ReservationTicketParser.refundTicketDetail),
      upload: (receipt && [{ id: receipt, src: getImageUrl(receipt), name: 'Comprovante' }]) || [],
      installments,
      bankAccount: origin_bank_account?.id,
      acquirerId: acquirer_id,
      acquirer: acquirer_name,
      doc,
      aut,
      brand,
      paymentMean: payment_mean
    }
  },
  singlePartnershipRefund (refund = {}) {
    const {
      id = '',
      value,
      refund_method: method,
      status,
      refund_date: isoDate,
      installments = 0,
      receipt = '',
      destination_company_id: companyDestinationId,
      tickets = []
    } = refund.advance_reservation_refund
    const day = moment.utc(isoDate)
    const time = moment.utc(isoDate).format('HH:mm')

    return {
      id,
      value,
      method,
      status,
      day,
      time,
      companyDestinationId,
      tickets: (tickets || []).map(ReservationTicketParser.ticket),
      upload: (receipt && [{ id: receipt, src: getImageUrl(receipt) }]) || [],
      installments
    }
  },
  singleRefundLink (refund = {}) {
    const {
      id,
      value,
      tickets = [],
      part_refunds: partRefunds = []
    } = refund.reservation_refund

    const { part_refund_tickets: partRefundTickets = [] } = tail(partRefunds) || []

    const ticketsRefunded = (tickets || []).map(ticket => {
      const { value: paidValue = 0 } = partRefundTickets.find(({ ticket_id: ticketId }) => ticketId === ticket?.id) || {}

      return {
        ...ticket,
        paid_value: paidValue
      }
    })

    return {
      id,
      value,
      method: 'pay_credit',
      tickets: (ticketsRefunded || []).map(ReservationTicketParser.refundTicketDetail)
    }
  },
  remove (reasonDelete = '') {
    return {
      reason_delete: reasonDelete
    }
  },
  advancePartRefunds (ticket = {}) {
    const {
      id,
      payer_company_id: payerCompanyId,
      service_id: serviceId,
      ticket_id: ticketId,
      value
    } = ticket

    return {
      id,
      payerCompanyId,
      serviceId,
      ticketId,
      value
    }
  }

}

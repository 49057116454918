/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { TableHeaderCell, TableHeaderRow, TableHeader } from '@bonitour/components'

export const BatchTableHeader = () => {
  return (
    <TableHeader>
      <TableHeaderRow>
        <TableHeaderCell>Referência do lote</TableHeaderCell>
        <TableHeaderCell>Data do pagamento</TableHeaderCell>
        <TableHeaderCell>Nome do guia responsável</TableHeaderCell>
        <TableHeaderCell>Status</TableHeaderCell>
        <TableHeaderCell>Valor pago</TableHeaderCell>
        <TableHeaderCell fixed>Ação</TableHeaderCell>
      </TableHeaderRow>
    </TableHeader>
  )
}

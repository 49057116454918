/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { HeaderPage } from '@bonitour/components'
import { CombinedExperienceForm } from './form/CombinedExperienceForm'
import { useCallback, useEffect, useMemo } from 'react'
import { useCombinedExperience } from './hooks/useCombinedExperience'
import { useActivity } from 'contexts/Activity'
import { useBack } from 'hooks/useBack'
import { useParams } from 'react-router-dom'

export const EditCombinedExperience = () => {
  const {
    combinedExperienceId: activityId
  } = useParams()

  const {
    goTo
  } = useBack()

  const {
    getForEdition,
    edit,
    getCombinedExperienceForEditionResponse,
    isLoadingGetForEdition,
    isLoadingEdition
  } = useCombinedExperience()

  const {
    loading: isLoadingExperienceList,
    companyActivities: experienceList = []
  } = useActivity()

  const fallbackPaths = useMemo(() => ({
    dashboard: `/combined-experience/${activityId}/dashboard`,
    serviceListing: '/activities'
  }), [activityId])

  const onBackClick = useCallback(() => goTo(fallbackPaths.dashboard), [goTo, fallbackPaths.dashboard])

  useEffect(() => {
    getForEdition(activityId, fallbackPaths.serviceListing)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>
    <HeaderPage
      title={'Experiência combinada'}
      onBack={onBackClick}
    />
    <CombinedExperienceForm
      isLoading={isLoadingGetForEdition || isLoadingEdition}
      isEdition={true}
      onSubmit={edit(fallbackPaths.dashboard)}
      isLoadingExperienceList={isLoadingExperienceList}
      experienceList={experienceList}
      isSuccessSubmit={true}
      initialData={getCombinedExperienceForEditionResponse}
    />
  </>
}

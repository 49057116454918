/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { TableContainer, TableHeader, TableHeaderRow, TableHeaderCell, TableBody, TableBodyRow, TableBodyCell, scrollBar } from '@bonitour/components'
import { formatISOTime } from 'utils/time'
import { formatMoney } from '@bonitour/common-functions'

const tableContainer = css`
  overflow: auto;
  ${scrollBar(5)};
`

const ReservationBalanceTransferRow = ({ detail }) => {
  const { partPaymentTicketOrigin, partPaymentTicketDestination, value } = detail

  const {
    ticketSlot: originTicketSlot,
    companyName: originCompanyName,
    ticketCode: originTicketCode
  } = partPaymentTicketOrigin

  const {
    ticketSlot: destinationTicketSlot,
    companyName: destinationCompanyName,
    ticketCode: destinationTicketCode
  } = partPaymentTicketDestination

  return (
    <TableBodyRow>
      <TableBodyCell data-label='De'>{`${originTicketCode} - ${formatISOTime(originTicketSlot)} ${formatISOTime(originTicketSlot, 'HH:mm')} - ${originCompanyName}`}</TableBodyCell>
      <TableBodyCell data-label='Para'>{`${destinationTicketCode} - ${formatISOTime(destinationTicketSlot)} ${formatISOTime(destinationTicketSlot, 'HH:mm')} - ${destinationCompanyName}`}</TableBodyCell>
      <TableBodyCell data-label='Valor transferido'>{formatMoney(value)}</TableBodyCell>
    </TableBodyRow>
  )
}

export const ReservationBalanceTransferTable = ({
  transferDetail = []
}) => {
  const sortByTicketCode = (head, next) => head?.partPaymentTicketOrigin?.ticketCode > next?.partPaymentTicketOrigin?.ticketCode ? 1 : -1
  const sortedTransferDetail = transferDetail.length > 0 ? transferDetail?.sort(sortByTicketCode) : []
  return (
    <div css={tableContainer}>
      <TableContainer card>
        <TableHeader>
          <TableHeaderRow>
            <TableHeaderCell>De</TableHeaderCell>
            <TableHeaderCell>Para</TableHeaderCell>
            <TableHeaderCell>Valor transferido</TableHeaderCell>
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {sortedTransferDetail?.map((detail, key) => (
            <ReservationBalanceTransferRow detail={detail} key={key} />
          ))}
        </TableBody>
      </TableContainer>
    </div>
  )
}

import { periodModel } from 'core/models/period'

const dayKeys = ['sundayQuantity', 'mondayQuantity', 'tuesdayQuantity', 'wednesdayQuantity', 'thursdayQuantity', 'fridayQuantity', 'saturdayQuantity']

const vacanciesModel = (scheduleList = {}) => {
  const weekSchedule = dayKeys.reduce((acc, day, index) => {
    const dayHoursQuantity = scheduleList.reduce((acc, { time, [day]: value }) => ({ ...acc, [time]: value }), {})
    return { ...acc, [index]: { ...dayHoursQuantity } }
  }, {})

  return weekSchedule
}

export const vacancyModel = (serviceId, companyId = '', data = {}) => {
  const { activityVacancy = {}, periodList = [], scheduleList = [] } = data
  const { vacancyName: name = '', dailyLimit = 0 } = activityVacancy
  const vacancies = vacanciesModel(scheduleList)
  const periodsParams = periodList.map(period => periodModel(period, companyId, 'vacancy'))

  return {
    name,
    active: 'true',
    duration: '45',
    minimum_interval: '20',
    daily_limit: dailyLimit,
    vacancies,
    periods_params: periodsParams,
    company_id: companyId,
    service_id: serviceId
  }
}

import { colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const userFormSection = css`
  background-color: ${colors.gray14};
  border-radius: 10px;
  padding: 20px 0;
`

export const actionRow = css`
  display: flex;
  padding-top: 10px;
  justify-content: flex-end;
`

export const cardWidth = css`
  width:100%;
  padding: 20px;
`

export const submitButtonStyle = css`
  padding-left: 30px;
  padding-right: 30px;
`

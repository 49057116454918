import { orbCore } from 'services/Orb/Service'
import { CashierHandlingParser } from 'core/parsers/cashierHandling'
import { CashierHandlingModel } from '../models/cashierHandling'

const { cashierHandlingDomain } = orbCore

export const CashierHandlingService = {
  list ({ filters, pagination }) {
    return cashierHandlingDomain.list(CashierHandlingModel.list({ filters, pagination })).then(CashierHandlingParser.list)
  },
  create (payload) {
    return cashierHandlingDomain
      .create(CashierHandlingModel.create(payload))
  }
}

import { css } from '@emotion/core'
import { colors, flexCenteredSpaceBetweenRow, flex } from '@bonitour/components'

export const smartphoneContainer = css`
  border: 1px solid ${colors.gray7};
  border-radius: 10px;
  width: 272px;
  height: 445px;
  overflow: hidden;
  user-select: none;
`

export const header = css`
  ${flexCenteredSpaceBetweenRow};
  padding: 10px;
`

export const battery = css`
  position: relative;
  width: 18px;
  height: 7px;
  background-color: ${colors.gray8};
  border: 2px solid ${colors.gray9};
  border-radius: 2px;

  &::after {
    position: absolute;
    content: '';
    left: 18px;
    top: 1px;
    width: 4px;
    height: 5px;
    border-radius: 1px;
    background-color: ${colors.gray9};
  }
`

export const wifiStyle = css`
  color: ${colors.gray4};
`

export const phoneSignal = css`
  ${flex};
  align-items: flex-end;
`

export const phoneSignalBar = (height) => css`
  width: 4px;
  height: ${height}px;
  background-color: ${colors.gray8};
  margin-right: 2px;
  border-radius: 2px;
`

export const content = css`
  padding: 10px;
  height: 86%;
  display: flex;
  flex-direction: column;
  align-items: center;

  overflow-y: scroll;
  overflow-x: hidden;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

import { number } from 'yup'

export const feeByDemandSchema = {
  limit: number()
    .typeError('Deve ser um número')
    .min(1, 'A porcentagem das vagas com tarifa por demanda deve estar entre 1 e 100')
    .max(100, 'A porcentagem das vagas com tarifa por demanda deve estar entre 1 e 100'),
  valueModificator: number()
    .typeError('Deve ser um número')
    .moreThan(0, 'A porcentagem de incremento ou decremento do valor final das vagas deve ser maior que zero')
}

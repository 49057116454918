/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { AddLabel, Card, HeaderPage, Loading, PaginationContainer } from '@bonitour/components'
import { margin } from 'assets/styles/global'
import { useHistory } from 'react-router'
import { useEffect, useState, useCallback, useMemo, useContext } from 'react'
import { PartnershipAccountEntry } from '../Create/PartnershipAccountEntry'
import { PartnershipTransactionsTable } from '../Table/PartnershipTransactionsTable'
import { useSellersList } from '../../../hooks/domains/useSellersList'
import { PartnershipAccountBalance } from '../List/PartnershipAccountBalance'
import { usePartnershipAccountCreateEntry } from '../hooks/usePartnershipAccountCreateEntry'
import { useCompany } from 'contexts/Company'
import { useQuery } from 'hooks/useQuery'
import { PartnershipAccountEdit } from './PartnershipAccountEdit'
import { useShowPartnershipTransactions } from '../hooks/useShowPartnershipTransactions'
import { useEditPartnershipAccount } from '../hooks/useEditPartnershipAccount'
import { PartnershipTransactionsFilters } from './PartnershipTransactionsFilters'
import { PartnershipAccountTotalizers } from '../List/PartnershipAccountTotalizers'
import { ExportFile } from 'components/ExportFile/ExportFile'
import { partnershipFormatOptions } from './partnershipFormatOptions'
import { useForm } from '@bonitour/app-functions'
import { date as yupDate, ref as yupRef, string as yupString } from 'yup'
import { PartnershipContext, PartnershipProvider } from '../partnershipContext'

const marginVertical30 = margin(30, 0, 30, 0)

const PAGINATION_OPTIONS = [
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 30, label: '30' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 200, label: '200' }
]

const BASE_FILTERS = {
  startDate: null,
  endDate: null,
  paymentType: null
}

const FILTERS_SCHEMA = {
  startDate: yupDate().typeError('Selecione a data inicial'),
  endDate: yupDate()
    .typeError('Selecione a data final')
    .min(yupRef('startDate'), 'A data final deve ser maior que a data inicial'),
  paymentType: yupString().nullable(true).typeError('Selecione um meio de pagamento')
}

const PartnershipTransactionsWithContext = () => {
  const { push } = useHistory()
  const [{ type = '' }] = useQuery()
  const redirectToList = useCallback(() => push('/partnership-accounts'), [push])
  const { sellersOptionsCompanyFiltered } = useSellersList()
  const [isEntryVisible, setEntryVisibility] = useState(false)
  const [isEditVisible, setEditVisibility] = useState(false)
  const onEntryVisible = useCallback(() => setEntryVisibility(true), [])
  const onEntryHidden = useCallback(() => setEntryVisibility(false), [])
  const onEditVisible = useCallback(() => setEditVisibility(true), [])
  const onEditHidden = useCallback(() => setEditVisibility(false), [])
  const { submit, loading: loadingCreateEntry } = usePartnershipAccountCreateEntry()
  const {
    pagination,
    partnershipAccount,
    transactions,
    meta,
    loading,
    changePagination,
    fetchPartnershipAccount,
    getTransactions,
    totalizers,
    columnsToExport,
    exportMyDay,
    exporting,
    fetchPaymentsType
  } = useContext(PartnershipContext)
  const { id: partnershipAccountId, partnerId, creditorId } = partnershipAccount || {}
  const { id: companyId } = useCompany()
  const canCreditEntry = useMemo(() => companyId !== partnerId, [companyId, partnerId])
  const isDebtor = useMemo(() => type === 'debtor', [type])

  const { fetchTransaction, transaction } = useShowPartnershipTransactions()
  const { updateTransaction } = useEditPartnershipAccount()

  const getTransactionInfo = (partnershipTransactionId) => {
    fetchTransaction({ partnershipAccountId, partnershipTransactionId })
  }

  const partnershipForm = useForm(BASE_FILTERS, FILTERS_SCHEMA)
  const headings = [{ title: 'Gerando arquivo...' }]
  const [filters, setFilters] = useState({
    startDate: '',
    endDate: '',
    paymentType: null
  })

  useEffect(() => {
    if (partnershipAccountId) {
      fetchPartnershipAccount(partnershipAccountId)
      getTransactions({ partnershipAccountId, pagination })
    }
    // eslint-disable-next-line
  }, [loadingCreateEntry])

  const handleFetchWithFilters = useCallback(
    (startDate, endDate, paymentType) => {
      getTransactions({ partnershipAccountId, pagination, startDate, endDate, paymentType })
      setFilters({ startDate, endDate, paymentType })
    },
    [getTransactions, pagination, partnershipAccountId]
  )

  const paginationMiddleware = useCallback(
    (currentPage, perPage) => {
      changePagination({ currentPage, perPage, ...filters })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters]
  )

  return (
    <>
      {exporting && <Loading isLoading={exporting} headings={headings} />}
      <HeaderPage onBack={redirectToList} title="Conta de parceria" />

      <PartnershipAccountBalance partnershipAccount={partnershipAccount} isDebtor={isDebtor} />

      <Card css={marginVertical30}>
        <PartnershipTransactionsFilters
          onFilter={handleFetchWithFilters}
          partnershipForm={partnershipForm}
          fetchPaymentsType={fetchPaymentsType}
        />

        <PartnershipAccountTotalizers totalizers={totalizers} />

        <ExportFile
          onSubmitExport={exportMyDay}
          loading={loading}
          initialFilters={BASE_FILTERS}
          filters={{
            startDate: partnershipForm?.form?.startDate,
            endDate: partnershipForm?.form?.endDate
          }}
          multiSelectOptions={columnsToExport}
          formatOptions={partnershipFormatOptions}
        />

        <PaginationContainer
          pagesQuantity={meta?.totalPages}
          total={meta?.total}
          onPagination={paginationMiddleware}
          pageLimitOptions={PAGINATION_OPTIONS}
        >
          <PartnershipTransactionsTable
            transactions={transactions}
            loading={loading}
            onEditVisible={onEditVisible}
            getTransactionInfo={getTransactionInfo}
          />
        </PaginationContainer>

        {!loading && canCreditEntry && <AddLabel onClick={onEntryVisible}>Novo lançamento</AddLabel>}
      </Card>

      <PartnershipAccountEntry
        sellers={sellersOptionsCompanyFiltered}
        sellerSelected={partnerId}
        isEntryVisible={isEntryVisible}
        isSelledDisabled
        canDebitType={true}
        canCreditType={true}
        balance={partnershipAccount?.balance}
        onEntryHidden={onEntryHidden}
        onSubmit={submit}
      />

      <PartnershipAccountEdit
        sellers={sellersOptionsCompanyFiltered}
        sellerSelected={partnerId}
        creditorId={creditorId}
        isEditVisible={isEditVisible}
        isSelledDisabled={isDebtor}
        canDebitType
        balance={partnershipAccount?.balance}
        onEditHidden={onEditHidden}
        updateTransaction={updateTransaction}
        transaction={transaction}
      />
    </>
  )
}

export const PartnershipTransactions = () => {
  return (
    <PartnershipProvider>
      <PartnershipTransactionsWithContext />
    </PartnershipProvider>
  )
}

import { date, string } from 'yup'
import { validateCnpj, validateCpf, validatePhoneWithDDI } from '@bonitour/common-functions'
import { SafeDate } from '@binamik/js-providers'

export const guideSchema = {
  name: string().required('Nome do guia é obrigatório'),
  documentType: string(),
  document: string().when('documentType', {
    is: 'Cpf',
    then: string()
      .required('O CPF é obrigatório')
      .test('test-cpf', 'O CPF deve ser válido', validateCpf),
    otherwise: string()
      .required('O documento é obrigatório')
      .test('test-cnpj', 'O documento deve ser válido', validateCnpj)
  }),
  email: string()
    .required('O e-mail é obrigatório')
    .email('Informe um e-mail válido'),
  phone: string().test('validate-phone', 'O número de telefone deve ser válido', (phone) => {
    if (phone?.length <= 4) {
      return true
    }
    return phone && validatePhoneWithDDI(phone)
  }),
  address: string().nullable(),
  taxValue: string(),
  paymentDate: date().max(new SafeDate().add({ days: 1 }).jsDate, 'A data de pagamento não pode ser maior que a data atual')
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { BookingMapCard } from './BookingMapCard'

const vacancyCardExpanded = css`
  width: 96px;
`

export const VacancyCard = ({ children, ...others }) => {
  return (
    <BookingMapCard {...others}>
      {children}
    </BookingMapCard>
  )
}

export const VacancyCardExpanded = ({ children, ...others }) => {
  return <BookingMapCard css={[vacancyCardExpanded]} {...others}>{children}</BookingMapCard>
}

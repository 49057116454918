/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useState } from 'react'
import {
  H2,
  SortRightIcon,
  TableContainer,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell,
  TableBody,
  PaginationContainer,
  flexCenteredSpaceBetweenRow,
  flexHorizontallyCentered,
  Loading
} from '@bonitour/components'
import { arrowOpenIndicator, clickable, hidden } from 'assets/styles/global'
import { InvoiceDetailRow } from './InvoiceDetailRow'
import { identity } from '@bonitour/common-functions'
import { ExportFile } from 'components/ExportFile/ExportFile'
import { invoiceFormatOptions } from './invoiceFormatOptions'
import { flatBottom, marginBottom20, marginBottom50, marginTop50, sortIcon } from './invoice.style'

export const pageLimitOptions = [
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 500, label: '500' },
  { value: 1000, label: '1000' }
]

export const InvoiceDetail = ({
  invoiceDetail = {},
  isReadOnly = false,
  onPaginate: emitPaginationEvent = identity,
  onExportSubmit: emitExportSubmit = identity,
  exportInvoiceColumns,
  exportLoading = false
}) => {
  const [isOpen, setOpened] = useState(true)
  const toggleDetailClick = () => setOpened(!isOpen)
  const invoiceItems = invoiceDetail.items || []
  const invoiceMeta = invoiceDetail.meta || {}
  const headings = [{ title: 'Gerando arquivo...' }]

  return (
    <>
      {exportLoading && <Loading isLoading={exportLoading} headings={headings} />}

      <div css={[marginTop50, marginBottom50]}>
        <div css={[flexCenteredSpaceBetweenRow, marginBottom20]}>
          <div css={[flexHorizontallyCentered, clickable]} onClick={toggleDetailClick}>
            <SortRightIcon css={[sortIcon, isOpen && arrowOpenIndicator]} />
            <H2 css={flatBottom}>Detalhamento</H2>
          </div>

          <ExportFile
            onSubmitExport={emitExportSubmit}
            loading={exportLoading}
            multiSelectOptions={exportInvoiceColumns}
            formatOptions={invoiceFormatOptions}
          />
        </div>

        {isOpen && (
          <PaginationContainer
            total={invoiceMeta.totalEntries || 0}
            pagesQuantity={invoiceMeta.totalPages || 1}
            onPagination={emitPaginationEvent}
            pageLimitOptions={pageLimitOptions}
            pageLimitDefault={100}
          >
            <TableContainer>
              <TableHeader>
                <TableHeaderRow>
                  <TableHeaderCell>Data</TableHeaderCell>
                  <TableHeaderCell>Serviço</TableHeaderCell>
                  <TableHeaderCell>Cód. Ingresso</TableHeaderCell>
                  <TableHeaderCell>Viajante</TableHeaderCell>
                  <TableHeaderCell>Valor</TableHeaderCell>
                  <TableHeaderCell css={hidden}>Valor creditado</TableHeaderCell>
                  <TableHeaderCell>Valor recebido</TableHeaderCell>
                  <TableHeaderCell>Saldo do item</TableHeaderCell>
                  <TableHeaderCell>Pagamento</TableHeaderCell>
                  <TableHeaderCell fixed>Ações</TableHeaderCell>
                </TableHeaderRow>
              </TableHeader>
              <TableBody>
                {invoiceItems.map((invoiceDetailItem, index) => (
                  <InvoiceDetailRow
                    key={`invoice-detail-item-${index}`}
                    invoiceDetail={invoiceDetailItem}
                    isReadOnly={isReadOnly}
                  />
                ))}
              </TableBody>
            </TableContainer>
          </PaginationContainer>
        )}
      </div>
    </>
  )
}

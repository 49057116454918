import { colors } from '@bonitour/components'

export const voucherStatusMap = {
  generated: ['Gerado', colors.green1],
  canceled: ['Cancelado', colors.danger],
  failure: ['Erro na geração', colors.danger]
}

export const voucherStatusOptions = [
  { value: 'generated', label: 'Gerado' },
  { value: 'canceled', label: 'Cancelado' },
  { value: 'failure', label: 'Erro na geração' }
]

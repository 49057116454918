import { formatISOTime } from 'utils/time'

const ticketStatusDictionary = {
  canceled: 'Cancelado',
  reserved: 'Reservado',
  'no-show': 'Não compareceu',
  confirmed: 'Confirmado',
  used: 'Usado'
}

export const eventMap = (change) => {
  switch (Object.keys(change)[0]) {
  case 'state':
    return `De ${ticketStatusDictionary[change.state[0]]} para ${ticketStatusDictionary[change.state[1]]}`
  case 'status':
    return `De ${ticketStatusDictionary[change.status[0]]} para ${ticketStatusDictionary[change.status[1]]}`
  case 'slot':
    return `De ${formatISOTime(change.slot[0], 'YYYY/MM/DD')} para ${formatISOTime(change.slot[1], 'YYYY/MM/DD')}`
  default:
    return 'Sem informação'
  }
}

export const allowedTypes = ['state', 'slot', 'status', 'payer_id']

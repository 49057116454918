import { colors } from '@bonitour/components'
import { apiPaymentMethods } from './paymentMethods'

export const TO_BE_PAID_STATUS = 'to_be_paid'
export const REFUND_STATUS = 'reversed'
export const NOT_PAID_STATUS = 'not_paid'
export const OPEN_STATUS = 'open'
export const PAID_STATUS = 'paid'
export const PARTIALLY_PAID_STATUS = 'partially_paid'

export const batchStatusMap = {
  [OPEN_STATUS]: ['Aberto', colors.orange4],
  [PAID_STATUS]: ['Pago', colors.green1],
  [PARTIALLY_PAID_STATUS]: ['Parcialmente pago', colors.blue2],
  [TO_BE_PAID_STATUS]: ['A pagar', colors.orange1],
  [NOT_PAID_STATUS]: ['Não pago', colors.red3],
  [REFUND_STATUS]: ['Estornado', colors.gray4]
}

export const paymentMethodsMap = {
  debit: 'Cartão de Débito',
  credit: 'Cartão de Crédito',
  cash: 'Dinheiro',
  bank_billet: 'Boleto',
  bank_transfer: 'Transferência Bancária',
  pix: 'PIX'
}

export const RECEIPTS_MIME_TYPES = ['image/png', 'image/jpeg', 'image/bmp', 'application/pdf']

export const paymentMethodsThatRequiredBankAccount = [
  apiPaymentMethods.pix,
  apiPaymentMethods.bank_billet,
  apiPaymentMethods.bank_transfer
]

export const BATCHES_ORDERING_COLUMNS_OPTIONS = [
  {
    label: 'Nenhuma',
    value: ''
  },
  {
    label: 'Data',
    value: 'ticket_slot'
  },
  {
    label: 'Experiência',
    value: 'service_name'
  },
  {
    label: 'Código da reserva',
    value: 'reservation_code'
  }
]

export const PAYMENT_METHODS_REQUIRED_BANK_ACCOUNT = [
  apiPaymentMethods.pix,
  apiPaymentMethods.bank_transfer,
  apiPaymentMethods.bank_billet
]

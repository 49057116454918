/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { TableContainer, TableBody, TableHeader, TableHeaderRow, TableHeaderCell, TableBodyRow, TableBodyCell, colors, TruncateCell } from '@bonitour/components'
import { formatMoney } from '@bonitour/common-functions'
import { Link } from 'react-router-dom'
import { useCallback, useEffect, useMemo, useState } from 'react'

export const table = css`
  margin-top: 30px;

  tr:not(thead tr) {
    td {
      vertical-align: top;
      line-height: 22px;
      color: ${colors.gray1};
    }
    transition: height 0.3s ease;
    height: 56px;
    overflow: hidden;
    font-size: 1rem;
    color: ${colors.gray1};
    b {
      font-weight: 700;
      line-height: 1.5;
    }

    .reservation_code {
      cursor: default;
      a {
        cursor: pointer;
      }
    }
  }

  .refund_method {
    > div {
      display: inline-flex;
      margin-left: 5px;
    }
  }

  td, th {
    padding: 20px 10px;
  }
`

const TouristRefundsTableRow = ({ touristRefundItem = [], onClick, expanded }) => {
  const {
    reservationId,
    reservationAgentName,
    refundDate,
    startDate,
    endDate,
    payerName,
    refundMethod,
    refundValue,
    refundAgentName,
    aut,
    doc,
    acquirerName,
    customCompanyName,
    brand,
    installments
  } = touristRefundItem

  const displayInstallments = useMemo(
    () => installments && (refundMethod?.toLowerCase()?.includes('crédito') || installments > 1),
    [installments, refundMethod]
  )

  const refundMethodInfo = useMemo(
    () => [
      ...(refundMethod ? [refundMethod] : []),
      ...(acquirerName ? [acquirerName] : []),
      ...(brand ? [brand] : []),
      ...(displayInstallments ? [`(${installments}x)`] : [])
    ].join(' '),
    [acquirerName, brand, displayInstallments, installments, refundMethod]
  )

  return (
    <>
      <TableBodyRow style={{ position: 'relative' }} className={expanded && 'expanded'} onClick={onClick}>
        <TableBodyCell>{refundDate}</TableBodyCell>
        <TableBodyCell>{startDate} - {endDate}</TableBodyCell>
        <TruncateCell text={reservationAgentName} size='130px' />
        <TruncateCell text={`${payerName}`} size='130px'>
          <Link to={`/reservation/${reservationId}`} target='_blank'>{payerName}</Link>
        </TruncateCell>
        <TableBodyCell className='refund_method'>
          <p>{refundMethodInfo}</p>
          {customCompanyName && <p>Multiponto: {customCompanyName}</p>}
        </TableBodyCell>
        <TruncateCell text={`${doc || '-'}/${aut || '-'}`} size='115px' />
        <TableBodyCell>{formatMoney(refundValue)}</TableBodyCell>
        <TruncateCell text={refundAgentName} size='125px' />
      </TableBodyRow>
    </>
  )
}

export const TouristRefundsTable = ({ touristRefunds = [], loading }) => {
  const [expandedIndexes, setExpandedIndexes] = useState([])
  const toggleExpanded = useCallback((index) => () => {
    setExpandedIndexes((expandedIndexes) => {
      if (expandedIndexes.includes(index)) {
        return expandedIndexes.filter((i) => i !== index)
      } else {
        return [...expandedIndexes, index]
      }
    })
  }, [])

  useEffect(
    () => { setExpandedIndexes([]) },
    [touristRefunds]
  )

  return (
    <TableContainer css={table}>
      <TableHeader>
        <TableHeaderRow>
          <TableHeaderCell>Data do<br />reembolso</TableHeaderCell>
          <TableHeaderCell>Período da reserva</TableHeaderCell>
          <TableHeaderCell>Agente responsável</TableHeaderCell>
          <TableHeaderCell>Comprador/Turista</TableHeaderCell>
          <TableHeaderCell>Forma de<br />reembolso</TableHeaderCell>
          <TableHeaderCell>Doc/Aut</TableHeaderCell>
          <TableHeaderCell>Valor</TableHeaderCell>
          <TableHeaderCell>Registrado por</TableHeaderCell>
        </TableHeaderRow>
      </TableHeader>
      <TableBody loading={loading}>
        {
          touristRefunds.map((item, index) => (
            <TouristRefundsTableRow
              key={index}
              touristRefundItem={item}
              onClick={toggleExpanded(index)}
              expanded={expandedIndexes.includes(index)}
            />
          ))
        }
      </TableBody>
    </TableContainer>
  )
}

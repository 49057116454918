/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx, css } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { Backdrop, CalendarIcon, colors, flexHorizontallyCentered, BREAK_POINTS } from '@bonitour/components'

import { clickable } from 'assets/styles/global'
import { useHistory } from 'react-router-dom'
import { useActivity } from 'contexts/Activity'
import { useCallback, useMemo } from 'react'
import moment from 'moment'
import { TRANSPORT_TYPE } from 'constants/activityTypes'

const backdrop = css`
  display: none;
  width: 100%;
  z-index: 5;

  @media (max-width: ${BREAK_POINTS.bigPhone}){
    display: initial;
  }
`

const container = css`
  position: absolute;
  right: 30px;
  top: 0;
  padding: 20px;
  border-radius: 10px;
  background-color: ${colors.white};
  overflow: hidden;
  z-index: 6;
  box-shadow: 0px 0px 6px #0000001a;

  @media (max-width: ${BREAK_POINTS.bigPhone}){
    position: fixed;
    top: 35%;
    left: 10%;
    right: 10%;
  }
`

const labelStyle = css`
  margin-left: 10px;
  font-size: 14px;
  color: ${colors.gray3};
  cursor: pointer;
  white-space: nowrap;
`

const iconStyle = css`
  font-size: 22px;
  color: ${colors.gray3};
`

const ConfigItem = ({ icon: Icon = null, label = '', onClick: emitClick = identity }) => {
  return (
    <div css={[flexHorizontallyCentered, clickable]} onClick={emitClick}>
      {Icon && <Icon css={iconStyle} />}
      <span css={labelStyle}>{label}</span>
    </div>
  )
}

export const BookingMapOptions = (
  {
    enabled = false,
    onClose: emitClose = identity,
    referenceDate
  }
) => {
  const { push } = useHistory()
  const { activity: { id: activityId, type } = {} } = useActivity()

  const safeDate = useMemo(
    () => referenceDate ? moment(referenceDate)?.format('YYYY-MM-DD') : ''
    , [referenceDate]
  )

  const onEdit = useCallback(
    () => push(
      `/${
        type === TRANSPORT_TYPE ? 'transport' : 'activity'
      }/${activityId}/bookings-day-config?date=${safeDate}`
    )
    , [activityId, push, safeDate, type]
  )

  if (!enabled) return null

  return (
    <>
      <Backdrop css={backdrop} onClick={emitClose} />
      <div css={container}>
        <ConfigItem icon={CalendarIcon} label='Editar dia específico no mapa' onClick={onEdit} />
      </div>
    </>
  )
}

/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import { useHistory } from 'react-router-dom'
import { Card, HeaderPage, Label, PaginationContainer, PlusIcon } from '@bonitour/components'
import { cardButton, cardIcon, labelCardButton, marginBottom, marginTop } from 'assets/styles/global'
import { CardButton } from 'components/CardButton'
import { ListExternalProviders } from './List/ListExternalProviders'
import { FilterExternalProviders } from './List/FilterExternalProviders'
import { useRef } from 'react'
import { useListExternalProvider } from './hooks/useListExternalProvider'

const marginTop20 = marginTop(20)
const marginBottom20 = marginBottom(20)

export const ExternalProviders = () => {
  const { push } = useHistory()
  const {
    filters,
    setFilters,
    changePagination,
    externalProviders,
    meta,
    loading
  } = useListExternalProvider()
  const { current: initialFilters } = useRef(filters)

  const goToDashboard = () => push('/')

  const onCreateExternalProvider = () => push('/external-providers/create')

  return (
    <>
      <HeaderPage onBack={goToDashboard} title='Fornecedores externos' />
      <CardButton css={cardButton} onClick={onCreateExternalProvider}>
        <PlusIcon css={cardIcon} />
        <Label css={labelCardButton}>Novo fornecedor externo</Label>
      </CardButton>

      <Card css={[marginTop20, marginBottom20]}>
        <FilterExternalProviders
          onFilterSubmit={setFilters}
          externalProviders={externalProviders}
          loading={loading}
          initialFilters={initialFilters}
        />

        <PaginationContainer title='Não há fornecedores externos cadastrados' total={meta?.total} pagesQuantity={meta?.totalPages} onPagination={changePagination}>
          <ListExternalProviders externalProviders={externalProviders} loading={loading} />
        </PaginationContainer>
      </Card>
    </>
  )
}

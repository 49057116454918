/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { TableHeader, TableHeaderRow, TableHeaderCell } from '@bonitour/components'
import { tableHeaderBase, tableHeaderCellBase, width } from 'assets/styles/global'

export function ActivityVacancySchedulesHeader () {
  return (
    <TableHeader>
      <TableHeaderRow css={tableHeaderBase}>
        <TableHeaderCell css={[tableHeaderCellBase, width(10)]}>Horário</TableHeaderCell>
        <TableHeaderCell css={[tableHeaderCellBase, width(10)]}>N° de vagas</TableHeaderCell>
        <TableHeaderCell css={[tableHeaderCellBase, width(10)]}>Domingo</TableHeaderCell>
        <TableHeaderCell css={[tableHeaderCellBase, width(10)]}>Segunda</TableHeaderCell>
        <TableHeaderCell css={[tableHeaderCellBase, width(10)]}>Terça</TableHeaderCell>
        <TableHeaderCell css={[tableHeaderCellBase, width(10)]}>Quarta</TableHeaderCell>
        <TableHeaderCell css={[tableHeaderCellBase, width(10)]}>Quinta</TableHeaderCell>
        <TableHeaderCell css={[tableHeaderCellBase, width(10)]}>Sexta</TableHeaderCell>
        <TableHeaderCell css={[tableHeaderCellBase, width(10)]}>Sábado</TableHeaderCell>
        <TableHeaderCell css={[tableHeaderCellBase, width(10)]} fixed />
      </TableHeaderRow>
    </TableHeader>
  )
}

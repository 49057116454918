/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { TableHeaderCell, TableHeaderRow, TableHeader } from '@bonitour/components'

export const PartnershipTransactionsHeader = () => {
  return (
    <TableHeader>
      <TableHeaderRow>
        <TableHeaderCell>Data do<br/>lançamento</TableHeaderCell>
        <TableHeaderCell>Data do<br/>pagamento</TableHeaderCell>
        <TableHeaderCell>AUT/DOC</TableHeaderCell>
        <TableHeaderCell>Operação</TableHeaderCell>
        <TableHeaderCell>Bandeira</TableHeaderCell>
        <TableHeaderCell>Parcelas</TableHeaderCell>
        <TableHeaderCell>Observações</TableHeaderCell>
        <TableHeaderCell>Valor lançado</TableHeaderCell>
        <TableHeaderCell>Ações</TableHeaderCell>
      </TableHeaderRow>
    </TableHeader>
  )
}

import { unformatMoney } from '@bonitour/common-functions'

const offlineTicketParser = (offlineTicket = {}) => {
  const { price, netPrice } = {
    price: unformatMoney(offlineTicket?.unitPrice || offlineTicket?.price).toFixed(2),
    netPrice: unformatMoney(offlineTicket?.unitNetPrice || offlineTicket?.netPrice).toFixed(2)
  }

  return {
    ...offlineTicket,
    price,
    netPrice
  }
}

export const OfflineTicketParser = {
  instance (offlineTicket = {}) {
    return offlineTicketParser(offlineTicket)
  }
}

import { MetaParser } from './Meta'
import { formatISOTime } from 'utils/time'

export const PendingTicketsReportParser = {
  single: (pendingTicket) => {
    const {
      ticket_slot: ticketSlot,
      ticket_id: id,
      reservation_code: reservationCode,
      reservation_eko_locator: ekoLocator,
      service_name: serviceName,
      pax_name: paxName,
      ticket_state: ticketState,
      reservation_id: reservationId
    } = pendingTicket

    return {
      id,
      travelDate: ticketSlot ? formatISOTime(ticketSlot) : '-',
      reservationCode: reservationCode || '-',
      traveler: paxName || '-',
      serviceName: serviceName || '-',
      ekoLocator: ekoLocator || '-',
      ticketState: ticketState || '-',
      reservationId
    }
  },
  list: ({ meta, voucherless_tickets_reports: pendingTickets = [] }) => {
    return {
      pendingTickets: pendingTickets.map(PendingTicketsReportParser.single),
      meta: MetaParser.meta(meta)
    }
  }
}

export const seasonalPeriodOptions = [
  {
    label: 'Diária',
    value: 'daily'
  },
  {
    label: 'Semanal',
    value: 'weekly'
  },
  {
    label: 'Dezenal',
    value: 'tenly'
  },
  {
    label: 'Quinzenal',
    value: 'biweekly'
  },
  {
    label: 'Mensal',
    value: 'monthly'
  }
]

import { css } from '@emotion/core'
import { flexColumn, colors, scrollBar, BREAK_POINTS } from '@bonitour/components'

export const container = css`
  ${flexColumn};
  justify-content: space-between;
  width: 350px;
  min-height: 250px;
  color: ${colors.gray3};
  border: none;
  margin-left: 20px;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    margin-top: 25px;
  }
`

export const bold = css`
  font-weight: bold;
`

export const receivedIcon = css`
  background-color: ${colors.primaryVariant};
  color: ${colors.white};
  margin-left: 20px;
`

export const amountDueContainer = css`
  padding: 20px;
`

export const amountDueLabel = css`
  font-size: 12px;
  color: ${colors.gray5};
  margin-bottom: 5px;
`

export const amountDueStyle = css`
  font-size: 24px;
  color: ${colors.gray1};
`
export const invoiceInfoIconStyle = css`
  margin-left: 5px;
  border: 1px solid ${colors.gray4};
  padding: 4px;
  border-radius: 50%;
  height: 11px;
  width: 11px;
  transition: 0.3s;
  &:hover {
    background-color: ${colors.primary};
    color: white;
  }
`
export const invoiceInfoIconOpenedStyle = css`
  margin-left: 5px;
  border: 1px solid ${colors.gray4};
  padding: 4px;
  border-radius: 50%;
  height: 11px;
  width: 11px;
  background-color: ${colors.primary};
  color: white;
`
export const popoverStyle = css`
  width: 440px;

  transform: none !important;
  top: initial !important;
  bottom: 20px !important;
  left: -165px !important;
`

export const popoverContentContainer = css`
  display: flex;
  flex-direction: column;
  align-items: start;
  max-height: 300px;

  ${scrollBar(5)};
  overflow-y: auto;

  padding: 20px;
  width: 100%;
`
export const invoiceDetailTitle = css`
  font-weight: bold;
  margin-top: 15px;
`

export const invoiceDetailItems = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
`

export const popoverContainer = css`
  position: relative;
`

import { useCallback, useEffect, useState } from 'react'
import { useToast } from '@bonitour/components'
import moment from 'moment'
import { InvoiceService } from 'core/services/Invoice'
import { usePermissions } from 'hooks/usePermissions'
import { useParamsFilter } from 'hooks/useParamsFilter'

export const useInvoiceDebtsList = () => {
  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()
  const [filters, setFilters] = useParamsFilter()
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({ currentPage: 1, perPage: 10 })
  const [invoicesData, setInvoicesData] = useState({ invoices: undefined, meta: undefined })
  const [totals, setTotals] = useState({ totals: undefined })
  const invoiceFilterDates = Object.fromEntries(Object.entries(filters).filter(invoiceFilter => invoiceFilter.some(value => value.includes('Date'))))
  const hasValidStartAndEndRangeDates = (invoiceFilterDates.invoiceStartDate && invoiceFilterDates.invoiceEndDate) && moment(invoiceFilterDates.invoiceEndDate) >= moment(invoiceFilterDates.invoiceStartDate)
  const hasValidCompetenceStartAndEndRangeDates = (invoiceFilterDates.invoiceCompetenceStartDate && invoiceFilterDates.invoiceCompetenceEndDate) && moment(invoiceFilterDates.invoiceCompetenceEndDate) >= moment(invoiceFilterDates.invoiceCompetenceStartDate)

  const changePagination = useCallback((currentPage, perPage) => {
    setPagination({ currentPage, perPage })
    // eslint-disable-next-line
  }, [])

  const fetchInvoices = useCallback(({ filters, pagination }) => {
    setLoading(true)

    return InvoiceService.listDebts({ filters, pagination }).then((data) => {
      setInvoicesData(data)
      InvoiceService.listDebtsTotals({ filters, pagination }).then(setTotals)
    }).catch((error) => {
      const hasPermission = handlePermission(error, 'Você não possui permissão de acesso as faturas a pagar')
      if (hasPermission) {
        const errorMessage = error?.data?.errors_msg
        const errorCode = error?.data?.errors
        const errorMessages = { default: `Não foi possível obter os dados. ${errorCode}` }
        addToast(errorMessages[errorMessage] ?? errorMessages.default)
      }
    }).finally(() => {
      setLoading(false)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    (hasValidStartAndEndRangeDates || hasValidCompetenceStartAndEndRangeDates) && fetchInvoices({
      pagination,
      filters
    })
  }, [pagination, filters, fetchInvoices, hasValidStartAndEndRangeDates, hasValidCompetenceStartAndEndRangeDates])

  return {
    filters,
    setFilters,
    changePagination,
    invoices: invoicesData?.invoices,
    meta: invoicesData?.meta,
    totals,
    loading
  }
}

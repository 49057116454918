import { removeArchiveUrl } from 'core/services/Orb'
import moment from 'moment'
import { removeSpecialCharactersFromString } from 'utils/string'
import { formatISOTime } from 'utils/time'
import { metaModel } from './meta'

/* eslint-disable camelcase */
export const BatchModel = {
  file ({ file, src, id, name }) {
    if (file) {
      return file
    }
    return {
      name,
      url: removeArchiveUrl(src),
      id
    }
  },
  single: (batch = {}) => {
    const {
      reference: reference_number,
      expectedPaymentDate: expected_payment_date,
      observation,
      name: guide_name,
      document,
      email: guide_email,
      phone: guidePhone,
      address: guide_address,
      vouchers: tickets_ids,
      taxValue: tax_value
    } = batch

    const phoneNumberWithDDI = guidePhone?.length > 4 ? guidePhone : ''

    return {
      reference_number,
      expected_payment_date,
      observation,
      guide_name,
      guide_document_number: removeSpecialCharactersFromString(document),
      guide_email,
      guide_phone: phoneNumberWithDDI,
      guide_address,
      tickets_ids,
      tax_value
    }
  },
  singleBatchPayment: (batch = {}) => {
    const {
      id,
      reference,
      name,
      document,
      email,
      phone,
      address,
      method,
      observation,
      vouchers,
      attachments,
      originBankAccountId,
      taxValue,
      paymentDate
    } = batch

    const value = vouchers?.reduce((sum, { value }) => sum + value, 0) || 0

    const parsedPaymentDate = (function () {
      const ISOFormat = 'YYYY-MM-DD'
      if (paymentDate) return formatISOTime(moment(paymentDate), ISOFormat, false)
      return formatISOTime(moment(), ISOFormat, false)
    }())

    return {
      id,
      reference_number: reference,
      guide_name: name,
      guide_document_number: removeSpecialCharactersFromString(document),
      guide_email: email,
      guide_phone: phone,
      guide_address: address,
      observation,
      payment_method: method,
      value: value.toFixed(2),
      payment_date: parsedPaymentDate,
      origin_bank_account_id: originBankAccountId,
      receipts: (attachments || []).map(BatchModel.file),
      tax_value: Number(taxValue || 0).toString()
    }
  },
  list: ({ filters, pagination }) => {
    const params = [
      BatchSearchParamsModel(filters),
      metaModel(pagination)
    ]
    return params
  },
  listVouchers: ({ filters, pagination }) => {
    const params = [
      VouchersSearchParamsModel(filters),
      metaModel(pagination)
    ]
    return params
  }
}

const BatchSearchParamsModel = (filterParams = {}) => {
  const { reference, expectedInitialPaymentDate, expectedFinalPaymentDate, guideName = '', status = '', guideDocument = '' } = filterParams

  return {
    reference_number: reference,
    start_expected_payment_date: expectedInitialPaymentDate,
    end_expected_payment_date: expectedFinalPaymentDate,
    guide_name: guideName,
    guide_document_number: removeSpecialCharactersFromString(guideDocument),
    status
  }
}

const VouchersSearchParamsModel = (filterParams = {}) => {
  const { voucherStartDate, voucherEndDate, externalCode, status = '' } = filterParams
  const regex = /[ ,]+/
  const externalCodeParsed = externalCode && externalCode.split(regex)

  return {
    start_activity_date: voucherStartDate,
    end_activity_date: voucherEndDate,
    external_ticket_id: externalCodeParsed,
    status
  }
}

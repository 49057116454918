import { useCallback, useEffect, useState } from 'react'
import { useToast } from '@bonitour/components'
import { BankAccountService } from 'core/services/BankAccount'
import { usePermissions } from 'hooks/usePermissions'

export const useListBankAccount = () => {
  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({ currentPage: 1, perPage: 10 })
  const [data, setData] = useState({ bankAccounts: undefined, meta: undefined })

  const changePagination = useCallback((currentPage, perPage) => {
    setPagination({ currentPage, perPage })
    // eslint-disable-next-line
  }, [])  

  const fetchBankAccounts = useCallback(({ pagination }) => {
    setLoading(true)
    return BankAccountService.list({ pagination }).then(setData)
      .catch((error) => {
        const hasPermission = handlePermission(error, 'Você não possui permissão de acesso as contas bancárias')
        hasPermission && addToast('Ocorreu um erro ao realizar a busca')
      })
      .finally(() => setLoading(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchBankAccounts({ pagination })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination])

  return {
    loading,
    bankAccounts: data?.bankAccounts,
    meta: data?.meta,
    pagination,
    fetchBankAccounts,
    changePagination
  }
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useCallback, useMemo, useState } from 'react'
import { TrashIcon, EyeIcon, PencilIcon, EllipsisIcon, Label, Popover, Manager, Reference, flexColumn, CashIcon, ConfirmDialog } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { iconStyle } from 'containers/Layout/TopNavbar/UserOptions.style'
import { label, popoverCloseButton } from './InvoicesBatchActions.style'
import { ActionsContainer, optionsIcon } from './InvoicesBatchTableRow.style'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useDeletePaymentBatch } from 'domains/InvoiceBatch/hooks/useDeletePaymentBatch'
import { NOT_PAID_STATUS, OPEN_STATUS, PARTIALLY_PAID_STATUS, TO_BE_PAID_STATUS } from 'constants/paymentBatch'

export const InvoicesBatchActionsBuilder = ({
  fetchTicketList: emitFetchTicketList = identity
}) => function InvoicesBatchActions ({
  batch = {}
}) {
  const { push, location } = useHistory()
  const { type } = useParams()
  const { deleteBatch } = useDeletePaymentBatch()

  const { id, status } = batch
  const [isActionsVisible, setActionsVisible] = useState(false)
  const [isConfirmationVisible, setConfirmationVisible] = useState(false)

  const togglePopoverVisible = useCallback(
    () => setActionsVisible(v => !v),
    []
  )
  const cancelDeletion = useCallback(
    () => setConfirmationVisible(false),
    []
  )

  const canBePaid = useMemo(
    () => [TO_BE_PAID_STATUS, NOT_PAID_STATUS, PARTIALLY_PAID_STATUS].includes(status),
    [status]
  )
  const canBeEdited = useMemo(
    () => status === OPEN_STATUS,
    [status]
  )
  const canBeExcluded = useMemo(
    () => status === OPEN_STATUS,
    [status]
  )

  const onShowClick = useCallback(
    ({ edit = false, pay = false } = {}) => () => {
      push(`/invoices-batch/${type}/${id}?search=${
        encodeURIComponent(location.search)
      }${
        edit ? '&edit=true' : pay ? '&payment=true' : ''
      }`)
    },
    [push, type, id, location.search]
  )
  const onDeleteBatch = useCallback(
    () => setConfirmationVisible(true),
    []
  )
  const confirmDeletion = useCallback(
    () => {
      setConfirmationVisible(false)
      deleteBatch(id).then(emitFetchTicketList)
    },
    [deleteBatch, id]
  )

  return (
    <>
      <ConfirmDialog
        title='Exclusão de lote'
        message='Esse lote será excluído permanentemente. Deseja continuar?'
        isVisible={isConfirmationVisible}
        successCallback={confirmDeletion}
        cancelCallback={cancelDeletion}
      />
      <Manager>
        <ActionsContainer>
          <Reference>
            {({ ref }) => (
              <div className='actions__icon' onClick={togglePopoverVisible} ref={ref}>
                <EllipsisIcon css={optionsIcon} />
              </div>
            )}
          </Reference>
          {isActionsVisible && (
            <>
              <button type='button' onClick={togglePopoverVisible} css={popoverCloseButton} />
              <Popover preventOverflow position='bottom'>
                <div css={flexColumn}>
                  <Label css={label} onClick={onShowClick()}>
                    <EyeIcon css={[iconStyle]} /> Visualizar lote
                  </Label>
                  {canBePaid && (
                    <Label css={label} onClick={onShowClick({ pay: true })}>
                      <CashIcon css={[iconStyle]} />
                      Registrar pagamento
                    </Label>
                  )}
                  {canBeEdited && (
                    <Label css={label} onClick={onShowClick({ edit: true })}>
                      <PencilIcon css={[iconStyle]} />
                      Editar lote
                    </Label>
                  )}
                  {canBeExcluded && (
                    <Label css={label} onClick={onDeleteBatch}>
                      <TrashIcon css={[iconStyle]} />
                      Excluir lote
                    </Label>
                  )}
                </div>
              </Popover>
            </>
          )}
        </ActionsContainer>
      </Manager>
    </>
  )
}

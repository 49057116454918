import { handleOfflineTicketFormSchema } from 'containers/OfflineExperiencesTicketForm/OfflineExperience.schema'
import { object, string, array, number } from 'yup'
import { reservationTypeSchema } from '../Form/ReservationType/ReservationType.rules'

export const bookingSchema = (states = [], cities = [], mustReservationTypeValidation = true, offlineExperienceCategory = '') => {
  const reservationType = mustReservationTypeValidation && object(reservationTypeSchema(states, cities))

  const offlineExperienceSchema = {
    ...handleOfflineTicketFormSchema({
      category: offlineExperienceCategory
    }),
    reservationType
  }

  const experienceSchema = {
    reservationHeader: object({
      partner: string().required(),
      agent: string().optional()
    }).required(),
    tickets: array()
      .of(
        object({
          quantity: number()
            .typeError('Deve ser um número')
            .min(0)
        })
      )
      .min(1),
    reservationType,
    comments: string(),
    affiliateCode: string().nullable()
  }

  return offlineExperienceCategory ? offlineExperienceSchema : experienceSchema
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { TableContainer, TableBody } from '@bonitour/components'
import { ReservationsTableHeader } from './ReservationsTableHeader'
import { ReservationsTableRow } from './ReservationsTableRow'

export const ReservationsTable = ({ reservations = [], loading }) => {
  if (!loading && !reservations.length) {
    return null
  }
  return (
    <TableContainer>
      <ReservationsTableHeader />
      <TableBody loading={loading}>
        {reservations.map((reservation, index) => (
          <ReservationsTableRow key={index} reservation={reservation} />
        ))}
      </TableBody>
    </TableContainer>
  )
}

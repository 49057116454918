import { object, string } from 'yup'
import { validateCnpj } from '@bonitour/common-functions'
import { addressSchema } from 'containers/Address/Address.rules'

export const externalProviderSchema = (states = [], cities = []) => ({
  name: string().required('Preencha o nome do fornecedor externo'),
  document: string()
    .test('test-validateCnpj', 'O CNPJ deve ser válido', validateCnpj)
    .required('O CNPJ é obrigatório'),
  addressInfo: object().shape(addressSchema(states, cities))
})

import { orbCore } from 'services/Orb/Service'
import { SellersGroupsParser } from './Parser'
import { sellersGroupsModel } from './Model'

export const SellersGroupsService = {
  create (apiData) {
    const sellersGroups = sellersGroupsModel(apiData)
    return orbCore.partnershipGroupDomain.create(sellersGroups)
  },
  getById (groupId = '') {
    return orbCore.partnershipGroupDomain.get(groupId).then(SellersGroupsParser.form)
  },
  update (apiData) {
    const sellersGroups = sellersGroupsModel(apiData)
    return orbCore.partnershipGroupDomain.update(sellersGroups, sellersGroups.id)
  },
  listGroups () {
    return orbCore.partnershipGroupDomain.list().then(SellersGroupsParser.cards)
  },
  invite ({ email = '', id = '' }, groupId) {
    return orbCore.partnershipGroupDomain.invite({ id, email }, groupId)
  },
  acceptInvite (inviteToken, companyId) {
    return orbCore.invitationDomain.accept({
      invite_token: inviteToken,
      commercial_partner_id: companyId
    })
  }
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { ArrowThickLeftIcon, EmptyResult, flexHorizontallyCentered, H1, Loading } from '@bonitour/components'
import { backButton } from './ReservationBalance.styles'
import { useHistory, useParams } from 'react-router'
import { margin, marginBottom, marginTop } from 'assets/styles/global'
import { identity } from '@bonitour/common-functions'
import { useReservationBalance } from './hooks/useReservationBalance'
import { ReservationBalanceItems } from './ReservationBalanceItems'
import { ReservationBalanceSelectionModal } from './ReservationBalanceSelectionModal'
import { useState } from 'react'
import { ReservationBalanceTransferDetails } from './ReservationBalanceTransferDetails'

const noMargin = margin(0, 0, 0, 0)
const marginTop20 = marginTop(20)
const marginBottom10 = marginBottom(10)

const ReservationBalanceHeader = ({ onBackClick: emitBackClick = identity }) => {
  return (
    <div css={[flexHorizontallyCentered, marginTop20, marginBottom10]}>
      <ArrowThickLeftIcon css={backButton} onClick={emitBackClick} />
      <H1 css={noMargin}>Saldo do viajante</H1>
    </div>
  )
}

export const ReservationBalance = () => {
  const { reservationId } = useParams()
  const { push } = useHistory()
  const {
    balance,
    fetchTicketsToPay,
    ticketsToPay,
    isLoading,
    isPaymentTransferLoading,
    paymentTransfer,
    isFreelyTransferable,
    transferDetail,
    setIsFreelyTransferable
  } = useReservationBalance(reservationId)
  const [isSelectionTicketsOpen, setSelectionTicketsOpen] = useState(false)
  const onSelectionTicketsOpen = () => setSelectionTicketsOpen(true)
  const onSelectionTicketsClose = () => setSelectionTicketsOpen(false)
  const [canceledTickets, setCanceledTickets] = useState([])
  const [destinationCompanyId, setDestinationCompanyId] = useState('')
  const [showTransferDetails, setTransferDetailsShow] = useState(false)
  const { seller, vendors = [] } = balance || {}
  const hasSellerTickets = seller?.tickets?.length > 0
  const hasVendorsTickets = vendors.length > 0

  const onBackClick = () => push(`/reservation/${reservationId}?selectedNav=financeiro`)

  const onBalanceTransferClick = (companyId, tickets = []) => () => {
    setDestinationCompanyId(companyId)
    setCanceledTickets(tickets)
    fetchTicketsToPay(reservationId)
    onSelectionTicketsOpen()
  }

  const onBalanceTransferConfirm = ({ ticketsToPaySelected, canceledTicketsSelected }) => async () => {
    const transferData = {
      ticketsToPay: ticketsToPaySelected,
      canceledTickets: canceledTicketsSelected,
      destinationCompanyId,
      isFreelyTransferable
    }
    await paymentTransfer(reservationId, transferData)
      .then(() => {
        onSelectionTicketsClose()
        setTransferDetailsShow(true)
      })
  }

  if (isLoading) {
    return (
      <>
        <ReservationBalanceHeader onBackClick={onBackClick} />
        <Loading isLoading={isLoading} />
      </>
    )
  }

  if (!hasSellerTickets && !hasVendorsTickets) {
    return (
      <>
        <ReservationBalanceHeader onBackClick={onBackClick} />
        <EmptyResult
          title='Não há saldo de transferência'
          subtitle='Não há saldo de transferência para essa reserva'
        />
      </>
    )
  }

  return (
    <>
      <ReservationBalanceHeader onBackClick={onBackClick} />

      {showTransferDetails
        ? <ReservationBalanceTransferDetails transferDetail={transferDetail} isTransferLoading={isPaymentTransferLoading} />
        : <ReservationBalanceItems balance={balance} onBalanceTransferClick={onBalanceTransferClick} setIsFreelyTransferable={setIsFreelyTransferable} />}

      <ReservationBalanceSelectionModal
        ticketsToPay={ticketsToPay}
        canceledTickets={canceledTickets}
        isSelectionTicketsOpen={isSelectionTicketsOpen}
        onSelectionTicketsClose={onSelectionTicketsClose}
        onConfirm={onBalanceTransferConfirm}
        isLoading={isPaymentTransferLoading}
      />
    </>
  )
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Card, InputFormGroup, colors, Button, Row, Column, DatePicker, Input, Select } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { useForm } from '@bonitour/app-functions'
import { useKeyPress } from 'hooks/useKeyPress'
import { refundTypesMap } from 'constants/refund'
import { touristRefundsFiltersSchema } from './TouristRefundsFilter.schema'

const button = css`
  height: 40px;
  width: 100%;
  margin-top: 20px;
`
const cardContrast = css`
  margin-bottom: 20px;
  padding: 15px;
  background-color: ${colors.gray13};
  border: none;
`

const width150 = css`
  width: 150px;
  width: 100%;
`

const refundMethodOptions = [
  { value: '', label: 'Todos' },
  ...Object.entries(refundTypesMap).map(([key, value]) => ({ value: key, label: value }))
]

export const TouristRefundsFilter = ({
  onFilterSubmit: emitFilterSubmit = identity,
  initialFilters = {}
}) => {
  const { form, errors, onSubmit, utils: { onInputChange, onInputBlur } } = useForm(initialFilters, touristRefundsFiltersSchema)
  const {
    refundMethod = '',
    reservationCode = '',
    initialSlotDate,
    finalSlotDate,
    initialRefundDate,
    finalRefundDate
  } = form
  const { onKeyDown } = useKeyPress()

  const onClick = onSubmit(emitFilterSubmit)

  const onDateChange = (name) => (value) => onInputChange(name)(value || null)

  return (
    <Card css={cardContrast}>
      <Row>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Data inicial do reembolso' errorMessage={errors.initialRefundDate}>
            <DatePicker
              value={initialRefundDate}
              onChange={onDateChange('initialRefundDate')}
              css={width150}
              allowsEmpty
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Data final do reembolso' errorMessage={errors.finalRefundDate}>
            <DatePicker
              value={finalRefundDate}
              onChange={onDateChange('finalRefundDate')}
              onBlur={onInputBlur('finalRefundDate')}
              css={width150}
              allowsEmpty
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Data inicial da experiência' errorMessage={errors.initialSlotDate}>
            <DatePicker
              value={initialSlotDate}
              onChange={onDateChange('initialSlotDate')}
              css={width150}
              allowsEmpty
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Data final da experiência' errorMessage={errors.finalSlotDate}>
            <DatePicker
              value={finalSlotDate}
              onChange={onDateChange('finalSlotDate')}
              css={width150}
              allowsEmpty
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Código da reserva' errorMessage={errors.reservationCode}>
            <Input
              value={reservationCode}
              error={errors.reservationCode}
              onChange={onInputChange('reservationCode')}
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Tipo de reembolso' errorMessage={errors.refundMethod}>
            <Select
              placeholder='Todos'
              options={refundMethodOptions}
              value={refundMethod}
              error={errors.refundMethod}
              onChange={onInputChange('refundMethod')}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <Button css={button} onClick={onClick}>Pesquisar</Button>
        </Column>
      </Row>
    </Card>
  )
}

import { formatMoney } from '@bonitour/common-functions'
import { MetaParser } from './Meta'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export const RevenueReportParser = {
  single: (reservationRevenue) => {
    const {
      reservation_id: reservationId,
      revenue,
      deduction,
      reservation_code: reservationCode,
      total_reservation_value: reservationValue,
      fiscal_duty: fiscalDuty,
      seller_id: sellerId,
      payer,
      checkout_date: checkoutDate
    } = reservationRevenue

    return {
      reservationId,
      revenue: revenue ? parseFloat(revenue) : NaN,
      deduction: deduction ? parseFloat(deduction) : NaN,
      reservationCode,
      reservationValue: reservationValue ? parseFloat(reservationValue) : NaN,
      fiscalDuty,
      sellerId,
      checkoutDate: dayjs.utc(checkoutDate).format('DD/MM/YYYY'),
      payer: payer
        ? {
          fullName: payer.full_name,
          email: payer.email,
          document: payer.document,
          address: payer.address
            ? {
              cityName: payer.address.city_name,
              stateName: payer.address.state_name,
              countryName: payer.address.country_name,
              street: payer.address.street,
              postCode: payer.address.post_code,
              supplement: payer.address.supplement,
              region: payer.address.region
            }
            : {}
        }
        : {}
    }
  },
  list: ({ meta, reservations_revenues: revenues = [] }) => {
    return {
      revenues: revenues ? revenues.map(RevenueReportParser.single) : [],
      meta: MetaParser.meta(meta)
    }
  },
  totals: ({ reservations_revenue_report_totalizers }) => {
    const {
      total_reservations_amount,
      total_sold_value,
      total_revenue_value,
      total_deduction_value
    } = reservations_revenue_report_totalizers

    return {
      totalReservationsAmount: total_reservations_amount,
      totalSoldValue: total_sold_value ? formatMoney(total_sold_value) : undefined,
      totalRevenueValue: total_revenue_value ? formatMoney(total_revenue_value) : undefined,
      totalDeductionValue: total_deduction_value ? formatMoney(total_deduction_value) : undefined
    }
  }
}

import { BREAK_POINTS, colors, flexHorizontallyCentered } from '@bonitour/components'
import { css } from '@emotion/core'
import { margin } from 'assets/styles/global'

export const marginHorizontal50 = margin(0, 50, 0, 50)

export const linkObservationContainer = css`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 41px;
  border: 1px solid ${colors.gray7};
  background-color: ${colors.white};
  border-radius: 10px;
`

export const buttonContainer = css`
  ${flexHorizontallyCentered};
  width: 100%;
  min-width: 120px;
  margin: 5px;
`

export const linkObservationIcon = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background: ${colors.purple1};
  border-radius: var(--small-round-element-radius, 100%);
  color: ${colors.white};
  margin-right: 5px;
  min-width: 20px;
`

export const linkObservationLabel = css`
  margin: 2px;
  font-size: 12px;
  line-height: 1.2;
  text-transform: none;
  color: ${colors.purple1};
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

export const linkPickupOptionsIcon = css`
  font-size: 16px;
  margin: 0 2px 0px 10px;
  cursor: pointer;
`

export const localNameStyle = css`
  max-width: 135px;
  line-height: 1.5;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    max-width: 200px;
  }
`

export const observationOptionsContainer = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px;
  text-transform: none;
`

export const observationOption = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
  font-size: 14px;
  cursor: pointer;
`

export const observationTicketIcon = css`
  font-size: 22px;
  margin-right: 8px;
  color: ${colors.gray4};
`

export const paymentTypesMap = {
  debit: 'Cartão de Débito',
  credit: 'Cartão de Crédito',
  cash: 'Dinheiro',
  bank_billet: 'Boleto',
  bank_transfer: 'Transferência Bancária',
  pay_credit: 'Pay - Cartão de Crédito',
  pay_pix: 'Pay - Pix',
  pix: 'Pix',
  discount: 'Desconto'
}

export const reservationPaymentTypesMap = (method, installments) => {
  if (method === 'credit' && installments) {
    return `Cartão de Crédito (${installments})`
  }

  return paymentTypesMap[method]
}

export const reservationPaymentTypesOptions = [
  {
    label: 'Cartão de Débito',
    value: 'debit'
  },
  {
    label: 'Cartão de Crédito',
    value: 'credit'
  },
  {
    label: 'Dinheiro',
    value: 'cash'
  },
  {
    label: 'Boleto',
    value: 'bank_billet'
  },
  {
    label: 'Transferência Bancária',
    value: 'bank_transfer'
  },
  {
    label: 'PIX',
    value: 'pix'
  },
  {
    label: 'Desconto',
    value: 'discount'
  }
]

export const reservationPaymentProviderTypesOptions = [
  {
    label: 'Cartão de Débito',
    value: 'debit'
  },
  {
    label: 'Cartão de Crédito',
    value: 'credit'
  },
  {
    label: 'Dinheiro',
    value: 'cash'
  },
  {
    label: 'Boleto',
    value: 'bank_billet'
  },
  {
    label: 'Transferência Bancária',
    value: 'bank_transfer'
  },
  {
    label: 'PIX',
    value: 'pix'
  }
]

export const reservationPaymentTypesVisualization = [
  ...reservationPaymentTypesOptions,
  {
    label: 'Pay',
    value: 'pay_credit'
  },
  {
    label: 'Pay Pix',
    value: 'pay_pix'
  }
]

export const refundPaymentTypesOptions = [
  {
    label: 'Transferência Bancária',
    value: 'bank_transfer'
  },
  {
    label: 'Dinheiro',
    value: 'cash'
  },
  {
    label: 'PIX',
    value: 'pix'
  },
  {
    label: 'Cartão de Crédito',
    value: 'credit'
  }
]

export const splitOption = [
  {
    label: 'Pay - Cartão de Crédito',
    value: 'pay_credit'
  }
]

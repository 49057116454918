import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'

const getReferenceDate = (referenceDate, daysOffset, shouldAddDays = true) => {
  const action = shouldAddDays ? 'add' : 'subtract'
  const newDay = moment(referenceDate)[action](daysOffset, 'days')
  return newDay.toDate()
}

export const useBookingMapDateTime = (daysOffset, startingDate) => {
  const [selectedHour, setSelectedHour] = useState(null)
  const [referenceDate, setReferenceDate] = useState(() => moment().toDate())

  useEffect(() => {
    setReferenceDate(moment(startingDate).toDate())
  }, [startingDate])

  const changeHour = hour => setSelectedHour(previousHour => {
    if (previousHour === hour) {
      return null
    }
    return hour
  })

  const changeToPrevious = useCallback(() => {
    setReferenceDate(getReferenceDate(referenceDate, daysOffset, false))
    setSelectedHour()
  }, [referenceDate, daysOffset])

  const changeToNext = useCallback(() => {
    setReferenceDate(getReferenceDate(referenceDate, daysOffset))
    setSelectedHour()
  }, [referenceDate, daysOffset])

  return {
    selectedHour,
    referenceDate,
    setReferenceDate,
    changeHour,
    changeToPrevious,
    changeToNext
  }
}

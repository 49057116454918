/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { colors } from '@bonitour/components'
import { BookingMapCard } from './BookingMapCard'

const bookingCardBase = css`
  background-color: ${colors.primaryVariant};

  .card__value {
    color: ${colors.white};
  }
`

export const BookingCard = ({ children, disabled: isDisabled = false, ...other }) => {
  return (
    <BookingMapCard {...other} css={bookingCardBase} disabled={isDisabled}>
      {children}
    </BookingMapCard>
  )
}

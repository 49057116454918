import { useMemo, useCallback } from 'react'
import { useForm } from '@bonitour/app-functions'
import { observationSchema } from '../Observation.schema'

export const useObservationForm = ({ observation, addToast, onSuccess }) => {
  const schema = useMemo(() => observationSchema(), [])

  const initialForm = useMemo(() => ({ observation }), [observation])

  const {
    form,
    errors,
    onSubmit,
    utils: { onInputBlur, onInputChange }
  } = useForm(initialForm, schema)

  const onValidationError = useCallback(() => addToast('Preencha corretamente o formulário'), [addToast])
  const handleSubmit = useMemo(() => onSubmit(onSuccess, onValidationError), [onSubmit, onSuccess, onValidationError])
  const onFormError = useCallback((message) => addToast(message), [addToast])

  return {
    form,
    errors,
    onError: onFormError,
    onInputBlur,
    onInputChange,
    onSubmit: handleSubmit
  }
}

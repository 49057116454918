/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useState, useEffect } from 'react'
import { TabContainer, UnderlineTabPane, UnderlineNavContainer, UnderlineNavItem } from '@bonitour/components'
import { navStyle, activeNav, navContainer, tabPane, activityLabel } from './ReservationBalance.styles'
import { hidden, marginBottom } from 'assets/styles/global'
import { ReservationBalanceCanceledTickets } from './ReservationBalanceCanceledTickets'
import { formatMoney, identity } from '@bonitour/common-functions'

const marginBottom40 = marginBottom(40)

export const ReservationBalanceItems = ({
  balance = {},
  onBalanceTransferClick: emitBalanceTransferClick = identity,
  setIsFreelyTransferable = identity
}) => {
  const { seller, vendors } = balance
  const hasSellerTickets = seller?.tickets?.length > 0
  const [selectedNav, setSelectedNav] = useState(hasSellerTickets ? 'seller' : 'vendor1')

  useEffect(() => {
    setIsFreelyTransferable(selectedNav === 'seller')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNav])

  return (
    <>
      <UnderlineNavContainer selectedNav={selectedNav} onChange={setSelectedNav} css={navContainer}>
        <UnderlineNavItem navId='seller' css={[navStyle, !hasSellerTickets && hidden, selectedNav === 'seller' && activeNav]}>
          <p css={activityLabel}>Ingressos livres</p>
          <p>{formatMoney(seller?.balance)}</p>
        </UnderlineNavItem>
        {vendors.map(({ name, balance }, index) => {
          const navId = `vendor${index + 1}`
          return (
            <UnderlineNavItem key={`balance${index + 1}`} navId={navId} css={[navStyle, selectedNav === navId && activeNav]}>
              <p css={activityLabel}>{name}</p>
              <p>{formatMoney(balance)}</p>
            </UnderlineNavItem>
          )
        })}
      </UnderlineNavContainer>
      <TabContainer activeTab={selectedNav}>
        <UnderlineTabPane tabId='seller' css={[!hasSellerTickets && hidden, marginBottom40, tabPane]}>
          <ReservationBalanceCanceledTickets companyId={seller?.id} tickets={seller?.tickets} onBalanceTransferClick={emitBalanceTransferClick} isTicketsFree />
        </UnderlineTabPane>
        {vendors.map(({ id, tickets }, index) => (
          <UnderlineTabPane key={`tickets${index + 1}`} tabId={`vendor${index + 1}`} css={[marginBottom40, tabPane]}>
            <ReservationBalanceCanceledTickets companyId={id} tickets={tickets} onBalanceTransferClick={emitBalanceTransferClick} />
          </UnderlineTabPane>
        ))}
      </TabContainer>
    </>
  )
}

import { OrbStore } from 'services/Orb/Store'

export const AuthStore = {
  clean () {
    return OrbStore.clean()
  },
  get authKey () {
    return OrbStore.get('auth-key')
  }
}

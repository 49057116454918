/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { TabContainer, UnderlineNavContainer, UnderlineNavItem, UnderlineTabPane, colors, HeaderPage, Label, PersonIcon } from '@bonitour/components'
import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { marginBottom, hidden } from 'assets/styles/global'
import { useCompany } from 'contexts/Company'
import { ReservationInfoApp } from './ReservationInfoApp'
import { ReservationFinanceApp } from './ReservationFinanceApp'
import { ReservationTicketApp } from 'domains/Reservation/Show/ReservationTicketApp'
import { ReservationHistoryApp } from 'domains/Reservation/History/ReservationHistoryApp'
import { ReservationTimelineApp } from 'domains/Reservation/Timeline/ReservationTimelineApp'
import { ReservationIntegrationsApp } from 'domains/Reservation/Integrations/ReservationIntegrationsApp'
import { useQuery } from 'hooks/useQuery'
import { ReservationProvider, useReservation } from 'hooks/context/Reservation'
import { TicketEditProvider } from 'domains/Reservation/Show/Tickets/context/TicketEditContext'
import { useTicketPrint } from 'containers/Reservation/hooks/useTicketPrint'
import { useTicketConfirm } from 'containers/Reservation/hooks/useTicketConfirm'
import { TicketPrintLoading } from 'containers/Reservation/TicketLoading'
import { identity } from '@bonitour/common-functions'
import { useFeatureFlag } from 'contexts/Feature'

const marginBottom60 = marginBottom(60)
const marginBottom80 = marginBottom(80)

const zIndex = css`
  z-index: 2;
`

const zIndex4 = css`
  z-index: 4;
`

const underlineTabPane = css`
  padding: 30px 0;
`

const contentContainer = css`
  background-color: ${colors.white};
  border-radius: 10px;
  padding: 30px;
`

const labelPayerName = css`
  font-size: 1rem;
  margin-left: 2.25rem;
  color: ${colors.gray1};
  i {
    font-weight: bolder;
  }
`

const headerPageCss = css`
margin-bottom: 0.2rem;
`

export const ReservationShow = ({ ticketPrint = identity, ticketPrintVoucherTuris = identity }) => {
  const { push } = useHistory()
  const [isOrbEkoLocatorEnabled] = useFeatureFlag('orb_eko_locator')
  const [isTimelineEnabled] = useFeatureFlag('orb-reservation-timeline')
  const [{ selectedNav = 'info' }, { changeQueryParam }] = useQuery()
  const setSelectedNav = (value) => changeQueryParam('selectedNav', value)
  const { id: companyId } = useCompany()
  const {
    reservationId,
    reservationCode,
    reservation,
    reservationPassengers,
    reservationPassengersLoading,
    reservationTicketLoading,
    fetchReservationPassengers,
    totalValue,
    getSubordinates,
    changeReservation,
    changeReservationStatus,
    deleteReservationTicket,
    subordinatesLoading,
    deleteTicketLoading,
    editStatusReservationLoading,
    reservationTickets,
    fetchReservationTickets
  } = useReservation()
  const isCommercialPartner = useMemo(() => reservation.partnerId && reservation.partnerId === companyId, [reservation, companyId])
  const { loading: ticketConfirmLoading, ticketConfirm } = useTicketConfirm()
  const returnToReservations = () => push('/reservations')
  const hasFiscalDuty = useMemo(() => Boolean(reservation?.fiscalDuty), [reservation?.fiscalDuty])
  const [hasPayerName, payerName] = useMemo(
    () => [Boolean(reservation?.finance?.name), reservation?.finance?.name],
    [reservation?.finance?.name])

  return (
    <>
      <HeaderPage onBack={returnToReservations} title={`Reserva ${reservationCode || ''}`} customCss={[headerPageCss]} />
      {hasPayerName && (
        <Label css={labelPayerName}>
          <PersonIcon/> {payerName}
        </Label>
      )}

      <UnderlineNavContainer selectedNav={selectedNav} onChange={setSelectedNav}>
        <UnderlineNavItem navId='info'>Resumo</UnderlineNavItem>
        <UnderlineNavItem navId='ingressos'>Ingressos</UnderlineNavItem>
        <UnderlineNavItem navId='financeiro'>Financeiro</UnderlineNavItem>
        <UnderlineNavItem navId='historico' css={[zIndex, isCommercialPartner || hidden]}>Histórico</UnderlineNavItem>
        <UnderlineNavItem navId='timeline' css={[zIndex, isCommercialPartner || hidden, isTimelineEnabled || hidden]}>Timeline</UnderlineNavItem>
        <>
          {isOrbEkoLocatorEnabled ? <UnderlineNavItem navId='integracoes'>Integrações</UnderlineNavItem> : null}
        </>

      </UnderlineNavContainer>
      <TabContainer activeTab={selectedNav}>
        <UnderlineTabPane tabId='info' css={[underlineTabPane, marginBottom60]}>
          <div css={contentContainer}>
            <ReservationInfoApp
              reservationId={reservation?.id}
              info={reservation?.info}
              isCommercialPartner={isCommercialPartner}
              onStatusEdition={changeReservationStatus}
              editStatusReservationLoading={editStatusReservationLoading}
              onPayerInfoEdition={changeReservation}
              reservationTotalPrice={totalValue}
              activities={reservation?.tickets?.activities}
            />
          </div>
        </UnderlineTabPane>
        <UnderlineTabPane css={[underlineTabPane, marginBottom80]} tabId='ingressos' alwaysRerender>
          <div>
            <TicketEditProvider>
              <ReservationTicketApp
                reservationCode={reservationCode}
                total={totalValue}
                tickets={reservation.tickets}
                fetchReservationTickets={fetchReservationTickets}
                reservationTickets={reservationTickets}
                reservationTicketLoading={reservationTicketLoading}
                onTicketDelete={deleteReservationTicket}
                onTicketPrint={ticketPrint}
                onTicketPrintVoucherTuris={ticketPrintVoucherTuris}
                deleteTicketLoading={deleteTicketLoading}
                reservation={reservation}
                reservationPassengers={reservationPassengers}
                reservationPassengersLoading={reservationPassengersLoading}
                fetchReservationPassengers={fetchReservationPassengers}
                companyId={companyId}
                onTicketConfirm={ticketConfirm}
                ticketConfirmLoading={ticketConfirmLoading}
                reservationId={reservation?.id}
              />
            </TicketEditProvider>
          </div>
        </UnderlineTabPane>
        <UnderlineTabPane css={underlineTabPane} tabId='financeiro' alwaysRerender>
          <div css={contentContainer}>
            <ReservationFinanceApp
              reservation={reservation}
              reservationId={reservationId}
              isCommercialPartner={isCommercialPartner}
              getSubordinates={getSubordinates}
              subordinatesLoading={subordinatesLoading}
              hasFiscalDuty={hasFiscalDuty}
            />
          </div>
        </UnderlineTabPane>
        <UnderlineTabPane tabId='historico' css={[underlineTabPane, isCommercialPartner || hidden]}>
          <div css={contentContainer}>
            <ReservationHistoryApp info={reservation?.info} />
          </div>
        </UnderlineTabPane>
        <UnderlineTabPane tabId='timeline' css={[underlineTabPane, isCommercialPartner || hidden]}>
          <div css={contentContainer}>
            <ReservationTimelineApp
              createdAt={reservation?.info?.createdAt}
              selectedNav={selectedNav}
            />
          </div>
        </UnderlineTabPane>
        <UnderlineTabPane tabId='integracoes' css={[underlineTabPane, isCommercialPartner || hidden]}>
          <div css={contentContainer}>
            <ReservationIntegrationsApp />
          </div>
        </UnderlineTabPane>
      </TabContainer>
    </>
  )
}

export const Reservation = () => {
  const { loading: ticketPrintLoading, ticketPrint, ticketPrintVoucherTuris } = useTicketPrint()
  return (
    <ReservationProvider>
      <TicketPrintLoading isLoading={ticketPrintLoading} backgroundStyle={zIndex4} />
      <ReservationShow ticketPrint={ticketPrint} ticketPrintVoucherTuris={ticketPrintVoucherTuris} />
    </ReservationProvider>

  )
}

import { CompanyStore } from 'core/store/Company'
import { orbCore } from 'services/Orb/Service'
import { VendorsParser } from './Parser'

export const VendorsService = {
  getList () {
    const companyId = CompanyStore.getId()
    return orbCore.vendorDomain.list(companyId).then(VendorsParser.list)
  }
}

import { head } from '@bonitour/common-functions'

/* eslint-disable camelcase */
export const ReservationBalanceParser = {
  ticket (ticket = {}, companyId = undefined) {
    const { id, part_payment_tickets_ids: partPaymentTicketsIds, ticket_code: ticketCode, vendor_name: VendorName, service_name: activityName, slot, ticket_balance: value, limited_transfer_split_payment: isLimitedTransferSplitPayment } = ticket

    return {
      id,
      ticketId: id,
      partPaymentTicketsIds,
      ticketCode,
      company: VendorName || '-',
      activity: activityName || '-',
      date: slot,
      value: Number(value),
      isLimitedTransferSplitPayment,
      companyId
    }
  },
  item (item = {}) {
    const { company_id: id, company_name: name, total_balance: balance, tickets } = item

    return {
      id,
      name,
      balance,
      tickets: tickets.map((ticket) => ReservationBalanceParser.ticket(ticket, id))
    }
  },
  balance (ticketsToTransfer = []) {
    const seller = ticketsToTransfer.filter(({ freely_transferable_tickets: freelyTransferableTickets }) => Boolean(freelyTransferableTickets))
    const vendors = ticketsToTransfer.filter(({ freely_transferable_tickets: freelyTransferableTickets }) => !freelyTransferableTickets)
    const hasSellerTickets = seller.length > 0
    const hasVendorsTickets = vendors.length > 0

    return {
      seller: hasSellerTickets ? ReservationBalanceParser.item(head(seller)) : {},
      vendors: hasVendorsTickets ? vendors.map(ReservationBalanceParser.item) : []
    }
  }
}

import { css } from '@emotion/core'
import { scrollBar } from '@bonitour/components'

export const tableContainer = css`
  overflow-x: auto;
  ${scrollBar(5)};

  td, th {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

export const cellWithTooltip = css`
  > div {
    right: 0;
    display: inline-flex;
    margin-left: 5px;
  }
`

export const cellWithBreakpoint = css`
  width: 200px;
  > a {
    white-space: pre-wrap;
    line-height: 1.2rem;
  }
`

import { useCallback, useState } from 'react'
import { useToast } from '@bonitour/components'
import { PaymentBatchService } from 'core/services/PaymentBatch'

export const useDeletePaymentBatch = () => {
  const { add: addToast } = useToast()
  const [loading, setLoading] = useState(false)

  const deleteBatch = useCallback((paymentBatchId) => {
    setLoading(true)
    return PaymentBatchService.deletePaymentBatch(paymentBatchId)
      .then(() => addToast('Lote deletado', 'success'))
      .catch(() => addToast('Ocorreu um erro ao deletar o lote'))
      .finally(() => setLoading(false))
  }, [addToast])

  return {
    deleteBatch,
    loading
  }
}

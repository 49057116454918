/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { HeaderPage, InputWithSuffix, SearchIcon } from '@bonitour/components'
import { ProvidersList } from './List/List'
import { useHistory } from 'react-router-dom'
import { PaginatedProvidersContext, PaginatedProvidersProvider } from 'app/Providers/ProvidersContext'
import { memo, useContext, useEffect, useMemo, useState } from 'react'
import { searchIcon, search } from './Providers.style'
import { useDebounce } from '@bonitour/app-functions'

const SearchComponent = memo(() => {
  const [providerName, setProviderName] = useState('')

  const { toSearch, handleSearch } = useContext(PaginatedProvidersContext)

  const debouncedSearch = useDebounce(providerName, 1500)

  const memoizedSearch = useMemo(() => toSearch, [toSearch])

  useEffect(() => {
    if (debouncedSearch === memoizedSearch) return
    handleSearch(debouncedSearch)
  }, [debouncedSearch, handleSearch, memoizedSearch])

  return <div style={{
    display: 'flex',
    justifyContent: 'end'
  }}>
    <InputWithSuffix type="text" value={providerName}
      onChange={setProviderName} placeholder={'Buscar fornecedor'}
      css={search}>
      <SearchIcon css={searchIcon}/>
    </InputWithSuffix>
  </div>
})

export const ProvidersPage = ({
  invitesInfo = {}
}) => {
  const { push } = useHistory()
  const backToDashboard = () => push('/dashboard')

  return (
    <>
      <HeaderPage onBack={backToDashboard} title='Fornecedores' />

      <PaginatedProvidersProvider>
        <SearchComponent/>
        <ProvidersList
          invitesInfo={invitesInfo}
        />
      </PaginatedProvidersProvider>
    </>
  )
}

import { orbCore } from 'services/Orb/Service'
import { CompanyStore } from 'core/store/Company'
import { ProvidersParser } from './Parser'

const { providerDomain } = orbCore

export const ProvidersService = {
  getPaginatedList (page, perPage) {
    if (!(perPage && page)) {
      return
    }
    const companyId = CompanyStore.getId()
    return providerDomain.getPaginatedList(companyId, perPage, page).then(ProvidersParser.cards)
  },
  getPayProviders (companyId) {
    return providerDomain.getPayProviders(companyId).then(ProvidersParser.payProviders)
  },
  getProvidersByName (toSearch, perPage, page) {
    const companyId = CompanyStore.getId()
    return providerDomain.getProvidersByName(companyId, perPage, page, toSearch).then(ProvidersParser.cards)
  }
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { TableContainer, TableHeader, TableHeaderRow, TableHeaderCell, TableBody, scrollBar } from '@bonitour/components'
import { width } from 'assets/styles/global'
import { ActivityFeeRow } from 'containers/ActivityFeeTable/ActivityFeeRow'
import { identity } from '@bonitour/common-functions'

const width10 = width(10)

const tableContainer = css`
  overflow-x: auto;
  ${scrollBar(5)};
`

export const ActivityFeeList = ({
  activityFeeList = [],
  onDelete: emitDeleteEvent = identity,
  onCreate: emitCreateEvent = identity,
  onNavigation: emitNavigationEvent = identity
}) => {
  const emitCopyEvent = activityFeeItem => event => {
    event.stopPropagation()
    emitCreateEvent(activityFeeItem)
  }

  return (
    <div css={tableContainer}>
      <TableContainer>
        <TableHeader>
          <TableHeaderRow>
            <TableHeaderCell>Cor</TableHeaderCell>
            <TableHeaderCell>Nome</TableHeaderCell>
            <TableHeaderCell>Preço</TableHeaderCell>
            <TableHeaderCell>Períodos</TableHeaderCell>
            <TableHeaderCell css={width10} fixed>Opções</TableHeaderCell>
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {activityFeeList.map((activityFeeItem, index) => (
            <ActivityFeeRow
              key={`activity-fee-item-${index}`}
              activityFee={activityFeeItem}
              onDelete={emitDeleteEvent(activityFeeItem.id)}
              onUpdate={emitNavigationEvent(activityFeeItem.id)}
              onCopy={emitCopyEvent(activityFeeItem)}
            />
          ))}
        </TableBody>
      </TableContainer>
    </div>
  )
}

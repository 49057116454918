/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { TableContainer, TableBody, scrollBar } from '@bonitour/components'
import { ReservationTableHeader } from './ReservationTableHeader'
import { ReservationTableRow } from './ReservationTableRow'

const tableContainer = css`  
  margin-top: 30px;
  overflow-x: auto;
  ${scrollBar(5)};
`

export const ReservationTable = ({ transactions = [], loading = false }) => {
  return (
    <div css={tableContainer}>
      <TableContainer>
        <ReservationTableHeader />
        <TableBody loading={loading}>
          {
            transactions.map((transaction, index) => {
              return <ReservationTableRow key={index} transaction={transaction} />
            })
          }
        </TableBody>
      </TableContainer>
    </div>
  )
}

/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react'
import PropTypes from 'prop-types'
import {
  serviceCombinedExperienceCardItem,
  serviceCombinedExperienceCardImage,
  serviceCombinedExperienceCardInfo,
  participantCount,
  priceAndParticipants,
  serviceCombinedExperienceTitle,
  serviceCombinedExperienceCard,
  ServiceCombinedExperienceCardContainer
} from './ServiceCombinedExperienceList.style'
import { SwatchImage } from 'components/SwatchImage'

export const ServiceCombinedExperienceCard = ({ experiences }) => {
  return (
    <ServiceCombinedExperienceCardContainer>
      {experiences.map((experience, index) => (
        <div
          css={serviceCombinedExperienceCard}
          key={`combined-experience-card-${experience.id}-${index}`}
        >
          <div css={serviceCombinedExperienceCardItem(index)}>
            <SwatchImage
              css={serviceCombinedExperienceCardImage}
              image={experience.serviceImage}
              alternativeText={experience.serviceTitle}
            />
            <h3 css={serviceCombinedExperienceTitle(index)}>{experience.serviceTitle}</h3>
          </div>
          {experience.price && (
            <div css={serviceCombinedExperienceCardInfo}>
              <div css={priceAndParticipants}>
                <div css={participantCount(index)}>
                  <span>{experience.participants}</span>
                </div>
                <span>{experience.price}</span>
              </div>
            </div>
          )}
        </div>
      ))}
    </ServiceCombinedExperienceCardContainer>
  )
}

ServiceCombinedExperienceCard.propTypes = {
  experiences: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    imgSrc: PropTypes.string.isRequired,
    participants: PropTypes.number.isRequired
  })).isRequired
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { css } from '@emotion/core'
import { colors, flex } from '@bonitour/components'

export const container = css`
  width: 100%;
`

export const maxLengthContainer = css`
  ${flex};
  justify-content: flex-end;
  padding: 5px 10px;
`

export const maxLengthStyle = css`
  font-size: 12px;
  color: ${colors.gray5};
`

export const fullWidth = css`
  width: 100%;
`

export const pushNotificationHeight = css`
  height: 60px;
`

export const pointer = css`
  cursor: pointer;
`

export const hourSelect = css`
  cursor: pointer;

  &.disabled {
    pointer-events: none;
    cursor: default;

    input {
      background-color: ${colors.gray11} !important;
    }
  }
`

export const scheduleInputGroup = css`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    top: 22px;
    opacity: 0;
  }

  input {
    pointer-events: none;
    cursor: default;
    background-color: ${colors.white} !important;
  }
`

export const scheduleAngleDown = css`
  position: absolute;
  right: 22px;
  bottom: 14px;
  font-size: 14px;
  color: ${colors.gray3};
`

export const smsHelp = css`
  font-size: 13px;
  border: 1px solid ${colors.gray7};
  background-color: ${colors.gray14};
  padding: 8px;
  border-radius: 10px;
  margin-bottom: 15px;
`
export const customSelectStyle = css`
  cursor: pointer;
  .component-drawer-item {
    max-width: 285px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

export const list = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 185px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  margin-top: 1.5px;
  border: 0.5px solid ${colors.gray8};
  border-radius: 12px;
  padding-top: 8px;

  li {
    display: block;
    width: 100%;

    p {
      margin-left: 10px;
      color: ${colors.gray3};
      font-size: clamp(0.75rem, 0.7298rem + 0.2151vw, 0.875rem);
    }
  }
`

export const label = css`
  display: block;
`

import { useCallback, useState } from 'react'
import { PlanService } from 'core/services/Plan'

export const useCreateCompanyPlan = () => {
  const [loading, setLoading] = useState(false)

  const createCashierHandling = useCallback((companyId, formValues) => {
    setLoading(true)

    return PlanService.create(companyId, formValues)
      .finally(() => setLoading(false))
  }, [])

  return {
    submit: createCashierHandling,
    loading
  }
}

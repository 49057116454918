import { replaceCommaToDot } from '@bonitour/common-functions'

const isUndefined = value => value === 'undefined' || value === undefined
const isEmpty = value => value.length > 0

/* eslint-disable camelcase */
export const PassengerModel = {
  single: (passenger = {}) => {
    const {
      id,
      fullName,
      email,
      birthDate,
      document,
      phone,
      country,
      state,
      city,
      emergencyName,
      emergencyTelephone,
      weight,
      height,
      zipCode,
      street,
      supplement,
      district
    } = passenger

    const address = {
      country_id: country,
      state_id: state,
      city_id: city,
      post_code: zipCode,
      street,
      supplement,
      region: district
    }

    const additionalInformation = {
      emergency_name: emergencyName,
      emergency_telephone: emergencyTelephone,
      weight: weight && isEmpty(weight) ? replaceCommaToDot(weight) : 0,
      height: isUndefined(height) ? 0 : Number(height.replace(',', ''))
    }

    const phoneNumberWithDDI = phone?.length > 4 ? phone : ''

    return {
      id,
      full_name: fullName,
      email,
      birth_date: birthDate,
      document,
      telephone: phoneNumberWithDDI,
      address: country ? address : undefined,
      additional_information: additionalInformation
    }
  },
  list: ({ filters }) => {
    const params = [PassengerSearchParamsModel(filters)]
    return params
  }
}

export const PassengerSearchParamsModel = (filterParams = {}) => {
  const {
    id,
    fullName,
    email,
    birthDate,
    document,
    phone
  } = filterParams

  return {
    id,
    full_name: fullName,
    email,
    birth_date: birthDate,
    document,
    telephone: phone
  }
}

import moment from 'moment'
import { metaModel } from './meta'

/* eslint-disable camelcase */
export const PaidTicketsReportModel = {
  totals: ({ filters = {}, pagination = {} }) => {
    const {
      paymentMethod: payment_method,
      reservationCode: reservation_code,
      initialSlotDate: initial_slot_date,
      finalSlotDate: final_slot_date,
      initialPaymentDate: initial_payment_date,
      finalPaymentDate: final_payment_date
    } = filters
    const searchParams = {
      payment_method,
      reservation_code,
      initial_slot_date: initial_slot_date ? moment(initial_slot_date).format('YYYY-MM-DD') : undefined,
      final_slot_date: final_slot_date ? moment(final_slot_date).format('YYYY-MM-DD') : undefined,
      initial_payment_date: initial_payment_date ? moment(initial_payment_date).format('YYYY-MM-DD') : undefined,
      final_payment_date: final_payment_date ? moment(final_payment_date).format('YYYY-MM-DD') : undefined
    }
    const meta = metaModel(pagination)
    return { searchParams, pagination: meta }
  },
  list: ({ filters = {}, pagination = {} }) => {
    const meta = metaModel(pagination)
    return { ...PaidTicketsReportModel.totals({ filters }), pagination: meta }
  }
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useCallback, useRef } from 'react'
import { AcquirersList } from './AcquirersList'
import { AcquirerService } from 'core/services/Acquirer'
import { useListAcquirer } from '../hooks/useListAcquirer'
import { useToast } from '@bonitour/components'

export const Acquirers = () => {
  const {
    filters,
    setFilters,
    pagination,
    changePagination,
    acquirers,
    meta,
    fetchAcquirers,
    loading
  } = useListAcquirer()

  const { current: initialFilters } = useRef(filters)
  const { add: addToast } = useToast()

  const deleteAcquirer = useCallback(async (acquirerId) => {
    try {
      await AcquirerService.remove(acquirerId)
      fetchAcquirers({ pagination, filters })
      addToast('Adquirente excluído', 'success')
    } catch (error) {
      error.status
        ? (
          addToast('Erro ao excluir adquirente')
        )
        : (
          addToast('Adquirente excluído', 'success')
        )
    }
  }, [fetchAcquirers, pagination, addToast, filters])

  return (
    <AcquirersList
      onFilterSubmit={setFilters}
      loading={loading}
      acquirers={acquirers}
      initialFilters={initialFilters}
      pagesQuantity={meta?.totalPages}
      total={meta?.total}
      onPagination={changePagination}
      onDeleteAcquirer={deleteAcquirer}
    />
  )
}

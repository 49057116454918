/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Card, Input, Label, StandaloneSelect, colors, Select, Textarea, BREAK_POINTS } from '@bonitour/components'
import { marginTop } from 'assets/styles/global'
import { formatMoney, formatDate, identity } from '@bonitour/common-functions'
import { useListEmployeesFromCompany } from 'domains/Cashier/hooks/useListEmployeesFromCompany'
import { useMemo } from 'react'
import { useUser } from 'contexts/User'

const marginTop10 = marginTop(10)
const marginTop20 = marginTop(20)

const highlightLabel = css`
  font-weight: 700;
`

const darkBackground = css`
  background-color: ${colors.gray13};
`

const formGrid = css`
  display: grid;
  grid-template-columns: 3fr 2fr 5fr 3fr 5fr;
  gap: 20px;

  @media (max-width: ${BREAK_POINTS.desktop}) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: ${BREAK_POINTS.tablet}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${BREAK_POINTS.smallTablet}) {
    grid-template-columns: 1fr;
  }
`

const formItem = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const ReservationHeader = ({
  isPartner = false,
  reservationHeader = {},
  reservationHeaderErrors = {},
  partnerList = [],
  tickets = [],
  comments = '',
  onChange: emitChangeEvent = identity,
  onBlur: emitBlurEvent = identity,
  disabled = false,
  ...other
}) => {
  const { date, hour, partner, agent } = reservationHeader
  const totalValue = tickets.reduce((acc, { quantity, price }) => {
    return acc + Number(quantity) * price
  }, 0)

  const employeesFromCompany = useListEmployeesFromCompany(reservationHeader.partner)
  const { user } = useUser()

  const employeesOptions = useMemo(() => {
    return employeesFromCompany.employees.map(({ userId, userName }) => ({
      value: userId,
      label: userName
    }))
  }, [employeesFromCompany.employees])

  return (
    <Card padding='20' css={darkBackground} {...other}>
      <div css={[formGrid]}>
        <div css={[formItem]}>
          <Label htmlFor='date' css={highlightLabel}>Data</Label>
          <StandaloneSelect id='date' disabled={true}>{date && formatDate(date)}</StandaloneSelect>
        </div>

        <div css={[formItem]}>
          <Label htmlFor='hour' css={highlightLabel}>Horário</Label>
          <StandaloneSelect id='hour' disabled={true}>{hour}</StandaloneSelect>
        </div>

        <div css={[formItem]}>
          <Label htmlFor='partner' css={highlightLabel}>Parceiro</Label>
          <Select
            placeholder='Selecione a empresa parceira'
            onChange={emitChangeEvent('reservationHeader.partner')}
            onBlur={emitBlurEvent('reservationHeader.partner')}
            error={reservationHeaderErrors.partner}
            disabled={isPartner || disabled}
            value={partner}
            options={partnerList}
          />
        </div>

        <div css={[formItem]}>
          <Label htmlFor='totalValue' css={highlightLabel}>Valor total</Label>
          <Input id='totalValue' disabled={true} value={formatMoney(totalValue)} size={1} />
        </div>

        <div css={[formItem]}>
          <Label htmlFor='agent' css={highlightLabel}>Agente Responsável</Label>
          <Select
            placeholder='Selecione o agente responsável'
            onChange={emitChangeEvent('reservationHeader.agent')}
            onBlur={emitBlurEvent('reservationHeader.agent')}
            error={reservationHeaderErrors.agent}
            disabled={disabled}
            value={agent || user.userId}
            options={employeesOptions}
            id='agent'
          />
        </div>
      </div>

      <div css={marginTop20}>
        <Label htmlFor='comments' css={highlightLabel}>Observações</Label>
        <Textarea css={marginTop10} id='comments' onChange={emitChangeEvent('comments')} value={comments} disabled={disabled} />
      </div>
    </Card>
  )
}

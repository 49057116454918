/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Card, InputFormGroup, colors, Button, Select, useToast, Row, Column, DatePicker } from '@bonitour/components'
import { useForm } from '@bonitour/app-functions'
import { CashHandlingTypes } from '../constants/cashier'
import { useKeyPress } from 'hooks/useKeyPress'

const button = css`
  height: 40px;
  width: 100%;
  margin-top: 20px;
`
const cardContrast = css`
  margin-bottom: 20px;
  padding: 15px;
  background-color: ${colors.gray13};
  border: none;
`

const filterCashHandlingTypesOptions = [
  { value: '', label: 'Todos' },
  { value: CashHandlingTypes.in, label: 'Entrada de caixa' },
  { value: CashHandlingTypes.out, label: 'Saída de caixa' }
]

export const CachierHandlingFilters = ({
  loading,
  setFilters,
  employeesOptions,
  loadingEmployees,
  initialFilters
}) => {
  const { add: addToast } = useToast()
  const { onKeyDown } = useKeyPress()
  const { form, errors, onSubmit, utils: { onInputChange } } = useForm(initialFilters, {})
  const onError = () => addToast('Preencha o formulário corretamente')
  const onClick = () => onSubmit(setFilters(form), onError)
  const { initialDate, finalDate, cashHandlingType, operator, supervisor } = form

  return (
    <Card css={cardContrast}>
      <Row>
        <Column phone={12} desktop={4}>
          <InputFormGroup label='Data inicial' errorMessage={errors.initialDate}>
            <DatePicker
              id='initialDate'
              value={initialDate}
              onChange={onInputChange('initialDate')}
              onKeyDown={event => onKeyDown(event, onClick)}
              disabled={loading} fullWidth
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={4}>
          <InputFormGroup label='Data final' errorMessage={errors.brand}>
            <DatePicker
              id='finalDate'
              value={finalDate}
              onChange={onInputChange('finalDate')}
              onKeyDown={event => onKeyDown(event, onClick)}
              disabled={loading} fullWidth
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={4}>
          <InputFormGroup label='Tipo de operação' errorMessage={errors.cashHandlingType}>
            <Select
              placeholder='Todos'
              options={filterCashHandlingTypesOptions}
              value={cashHandlingType}
              error={errors.cashHandlingType}
              onChange={onInputChange('cashHandlingType')}
              disabled={loading}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={4}>
          <InputFormGroup label='Operador' errorMessage={errors.operator}>
            <Select
              placeholder='Todos'
              options={employeesOptions}
              value={operator}
              error={errors.operator}
              onChange={onInputChange('operator')}
              disabled={loading || loadingEmployees}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={4}>
          <InputFormGroup label='Supervisor' errorMessage={errors.supervisor}>
            <Select
              placeholder='Todos'
              options={employeesOptions}
              value={supervisor}
              error={errors.supervisor}
              onChange={onInputChange('supervisor')}
              disabled={loading || loadingEmployees}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={4}>
          <Button css={button} onClick={onClick}>Pesquisar</Button>
        </Column>
      </Row>
    </Card>
  )
}

import { BinamikCompanies } from 'domains/Financial/BinamikCompanies/List/BinamikCompanies'
import { CreatePlan } from 'domains/Financial/CompanyPlan/Create/CreatePlan'
import { PlanDetails } from 'domains/Financial/CompanyPlan/Details/PlanDetails'

export const binamikRoutes = [
  {
    path: '/binamik/companies/:companyId/plan/create',
    component: CreatePlan
  },
  {
    path: '/binamik/companies/:companyId/plan',
    component: PlanDetails
  },
  {
    path: '/binamik/companies',
    component: BinamikCompanies
  }

]

export const ACCOUNT_TYPES = {
  COMPANY_CASHIER: 'internal'
}

export const accountTypeOptions = [
  { value: 'checking_account', label: 'Conta corrente' },
  { value: 'savings_account', label: 'Conta poupança' },
  { value: 'salary_account', label: 'Conta salário' },
  { value: ACCOUNT_TYPES.COMPANY_CASHIER, label: 'Caixa da empresa' }
]

export const paymentMethodFees = [
  { label: 'Boleto', value: 'bank_billet' },
  { label: 'Transferência Bancária', value: 'bank_transfer' },
  { label: 'PIX', value: 'pix' },
  { label: 'Dinheiro', value: 'cash' }
]

export const paymentsTypes = [
  { label: 'Link de pagamento', value: 'Financial::Paymentlink' },
  { label: 'Conta de parceria', value: 'Financial::PartnershipAccountTransaction' },
  { label: 'Pagamento manual - Reserva', value: 'Financial::ReservationPayment' },
  { label: 'Pagamento em Fatura', value: 'Financial::InvoicePayment' }
]

export const bankAccountErrors = {
  '010::004::015': 'Já existe um caixa da empresa cadastrado',
  '027::008::005': 'Dígito da agência inválido',
  '': 'Erro ao salvar conta bancária'
}

/* eslint-disable camelcase */
/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useCallback, useMemo, useState } from 'react'
import { Button, Column, DatePicker, H4, InputFormGroup, Row, ToggleInputGroup, useToast, TableV2, TableRow } from '@bonitour/components'
import { useForm } from '@bonitour/app-functions'
import { identity, formatMoney, head, formatDate } from '@bonitour/common-functions'
import { boolean, date } from 'yup'
import { isTodayOrAfterOrNull } from 'utils/date'
import { TicketsService } from 'core/services/Tickets'
import { marginTop, width100 } from 'assets/styles/global'
import { columnStyle, containerWithScroll, deadlineTableStyle, toggleStyle } from './EditDeadlineNoShow.styles'

const formSchema = {
  deadlineDate: date().test('test-today', 'Não é permitido data retroativa', isTodayOrAfterOrNull).nullable(),
  noShow: boolean()
}

const today = new Date().toISOString().split('T')[0]

const deadlineColumns = [
  'Código do ingresso',
  'Valor',
  'Data atual de deadline'
]

const deadlineColumnsWidths = ['1.2fr', '1fr', '1fr']

const DeadlineTicketsTable = ({ tickets = [] }) => {
  return <>
    <H4 css={marginTop(10)}>Ingressos selecionados</H4>
    <div css={containerWithScroll}>
      <TableV2 labels={deadlineColumns} columnsWidths={deadlineColumnsWidths} emptyMessage='Nenhum ingresso selecionado' mobileBreakpoint={575} customCss={deadlineTableStyle}>
        {tickets.map(({ ticketCode, price, deadlineDate }) => (
          <TableRow key={`deadline-item-${ticketCode}`}>
            {[
              ticketCode,
              formatMoney(price),
              formatDate(deadlineDate)
            ]}
          </TableRow>
        ))}
      </TableV2>
    </div>
  </>
}

export const EditDeadlineNoShow = ({
  initialDateValue = new Date().toISOString().split('T')[0],
  tickets = [],
  closeDialog = identity,
  onTicketUpdate = identity
}) => {
  const { add: addToast } = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const handleSubmit = useCallback(
    form => {
      const ticketsIds = tickets.map(({ id }) => id)
      if (ticketsIds?.length) {
        if (isLoading) return
        setIsLoading(true)
        TicketsService.updateDeadlineRule({ ticketsIds, ...form }).then(() => {
          addToast('Regras de Deadline alteradas com sucesso', 'success')
          closeDialog()
          onTicketUpdate()
        }).catch(({ data: { errors } }) => {
          const errorMap = {
            '001::018': 'Apenas vendedores podem alterar a data de deadline',
            '': 'Erro ao alterar regras de Deadline'
          }
          const error = head(errors)
          addToast(errorMap?.[error] || errorMap[''])
        }).finally(() => {
          setIsLoading(false)
        })
      }
    },
    [addToast, closeDialog, isLoading, tickets, onTicketUpdate]
  )

  const initialState = useMemo(
    () => ({
      deadlineDate: initialDateValue,
      noShow: false
    }),
    [initialDateValue]
  )
  const { form, errors, onSubmit, utils: { onInputChange, onInputBlur } } = useForm(initialState, formSchema)

  const toggle = useCallback(() => onInputChange('noShow')(!form.noShow), [form.noShow, onInputChange])

  return (
    <>
      <DeadlineTicketsTable tickets={tickets}/>
      <Row>
        <Column phone={12} desktop={5} css={columnStyle}>
          <InputFormGroup errorMessage={errors.deadlineDate} label='Nova data de deadline'>
            <DatePicker
              value={form.deadlineDate}
              error={Boolean(errors.deadlineDate)}
              onChange={onInputChange('deadlineDate')}
              onBlur={onInputBlur('deadlineDate')}
              minDate={today}
              css={width100}
              disabled={isLoading}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={7}>
          <ToggleInputGroup id='no-show' checked={form.noShow} onChange={toggle} disabled={isLoading} customCss={[toggleStyle]}>
            Alterar status para no-show
          </ToggleInputGroup>
        </Column>
      </Row>
      <div>
        <Button
          type='button'
          onClick={onSubmit(handleSubmit)}
          disabled={isLoading}
        >
          Salvar
        </Button>
      </div>
    </>
  )
}

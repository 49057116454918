/* eslint-disable camelcase */
import { removeEmptyAttribute } from '@bonitour/common-functions'

const basicInfoModel = ({
  serviceId: transport_id,
  ticketIds: tickets_ids,
  name
}) => {
  return {
    transport_id,
    tickets_ids,
    name
  }
}

const basicUpdateInfoModel = ({
  serviceId: transport_id,
  ticketId: ticket_id,
  name
}) => {
  return {
    transport_id,
    ticket_id,
    name
  }
}

const addressModel = ({
  city: city_id,
  state: state_id,
  country: country_id,
  zipCode: post_code,
  district,
  addressDetail: supplement,
  address: street
}) => {
  return {
    city_id,
    state_id,
    country_id,
    post_code,
    district,
    supplement,
    street
  }
}

export const PickupAndDropOffModel = {
  PickupAndDropOffUpdateModel: (pickUpDropOff) => {
    const cleanAddress = pickUpDropOff?.addressInfo ? addressModel(removeEmptyAttribute(pickUpDropOff?.addressInfo)) : {}
    const basicInfo = basicUpdateInfoModel(pickUpDropOff)
    const name = pickUpDropOff?.name

    return {
      ...basicInfo,
      address: {
        ...cleanAddress,
        name
      }
    }
  },
  PickupAndDropOffCreateModel: (pickUpDropOff) => {
    const cleanAddress = pickUpDropOff?.addressInfo ? addressModel(removeEmptyAttribute(pickUpDropOff?.addressInfo)) : {}
    const basicInfo = basicInfoModel(pickUpDropOff)
    const name = pickUpDropOff?.name

    return {
      ...basicInfo,
      address: {
        ...cleanAddress,
        name
      }
    }
  }
}

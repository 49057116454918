import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { routes } from 'routes'

const RouteBuilder = ({ path, component: MainComponent, routes = [], exact = false }) => {
  if (routes.length) {
    return (
      <Route path={path} exact={exact}>
        <MainComponent>
          <Switch>
            {
              routes.map(
                ({ path, component, routes = [], exact = false }) => {
                  return (
                    <RouteBuilder
                      key={path}
                      component={component}
                      routes={routes}
                      path={path}
                      exact={exact}
                    />
                  )
                }
              )
            }
          </Switch>
        </MainComponent>
      </Route>
    )
  }
  return (
    <Route path={path} component={MainComponent} exact={exact} />
  )
}

export const Router = () => {
  return (
    <Switch>
      {
        routes.map(
          ({ path, component, routes = [], exact = false }) => {
            return (
              <RouteBuilder
                key={path}
                component={component}
                routes={routes}
                path={path}
                exact={exact}
              />
            )
          }
        )
      }
    </Switch>
  )
}

import { css } from '@emotion/core'
import { colors } from '@bonitour/components'
import { marginTop } from 'assets/styles/global'

export const card = css`
  width: 290px;
  max-width: 290px;
  margin: 8px 8px 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const smallCard = css`
  ${card}
  width: 130px;
  justify-content: flex-start;
  padding: 20px;
  p:first-of-type {
    font-size: 14px;
    color: ${colors.gray4};
  }
  p:last-of-type {
    font-size: 16px;
  }
`

export const contentContainer = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
`

export const smallContentContainer = css`
  ${contentContainer}
  margin-left: 0;
`

export const noMarginBottom = css`
  margin-bottom: 0;
`

export const marginTop15 = marginTop(15)

export const width100 = css`
width: 100%;
`

export const height40 = css`
height: 40px;
`

export const flexEnd = css`
${width100}
display: flex;
justify-content: flex-end;
align-items: flex-end;
`

export const formContainer = css`
  display: flex;
  padding: 20px;
  background: ${colors.gray13};
  border-radius: 16px;
  margin-bottom: 10px;

  > * {
    width: 100%;
  }
`

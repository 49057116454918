import { css } from '@emotion/core'
import { BREAK_POINTS, colors, flexCenteredSpaceBetweenRow } from '@bonitour/components'
import { margin } from 'assets/styles/global'

const margin10 = margin(10, 10, 10, 10)

export const cardStyle = css`
  margin: 10px 0;
  padding: 30px 20px;
`

export const marginSide = css`
  margin: 0 10px;
`

export const width110 = css`
  width: 110px;
`

export const grayColor = css`
  color: ${colors.gray3};
`

export const width150 = css`
  width: 150px;
`

export const defaultCursor = css`
  cursor: default;
`

export const sideSpacing = css`
  margin: 0 10px;
`

export const activitiesContainer = css`
  padding: 20px;
`

export const activityCard = css`
  position: relative;
  ${margin10};
  padding: 20px;
  border: 1px solid ${colors.gray8};
  max-width: 210px;
`

export const activityCheckbox = css`
  position: absolute;
  top: 20px;
  left: 20px;
`

export const ghostStyling = css`
  margin-right: 20px;
  padding: 12px 30px;
`

export const ctaPadding = css`
  padding: 12px 40px;
`

export const title = css`
  margin: 0;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    margin-bottom: 10px;
  }
`

export const buttonsContainer = css`
  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    display: flex;
    flex-direction: column;
    width: 100%;

    button {
      width: 100%;
      margin: 10px 0;
    }
  }
`

export const headerContainer = css`
  ${flexCenteredSpaceBetweenRow};
  margin-bottom: 20px;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    flex-direction: column;
  }  
`

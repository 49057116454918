/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { Button, flexHorizontallyCentered, GhostButton, Modal } from '@bonitour/components'

const marginTop40 = css`
  margin-top: 40px;
`

const marginRight20 = css`
  margin-right: 20px;
`

const button = css`
  padding: 10px 30px; 
`

const highlight = css`
  font-weight: bold;
`

export const TicketCheckInModal = ({
  selectedCode = '',
  isVisible = false,
  onValidate = identity,
  onClose = identity
}) => {
  return (
    <Modal title='Confirmação de validação' maxWidth='1368px' isVisible={isVisible} onCloseClick={onClose}>
      Tem certeza que deseja validar o ingresso <span css={highlight}>{selectedCode}</span> como usado? <span css={highlight}>Essa ação não poderá ser alterada depois.</span>
      <div css={[flexHorizontallyCentered, marginTop40]}>
        <GhostButton css={[marginRight20, button]} onClick={onClose}>Cancelar</GhostButton>
        <Button css={button} onClick={() => onValidate()}>Confirmar</Button>
      </div>
    </Modal>
  )
}

import { CompanyStore } from 'core/store/Company'
import { orbCore } from 'services/Orb/Service'
import { SellersParser } from './Parser'

export const SellersService = {
  getPaginatedList (page, perPage) {
    if (!(perPage && page)) {
      return
    }
    const companyId = CompanyStore.getId()
    return orbCore.sellerDomain.getPaginatedList(companyId, perPage, page)
      .then(SellersParser.cards)
  },
  getList () {
    const companyId = CompanyStore.getId()
    return orbCore.sellerDomain.list(companyId).then(SellersParser.list)
  },
  searchCompany (company, perPage, page) {
    const companyId = CompanyStore.getId()
    return orbCore.sellerDomain.searchCompany(company, companyId, perPage, page)
  }
}

import { css } from '@emotion/core'
import { colors } from '@bonitour/components'

export const activitySelector = css`
  user-select: none;
  display: inline-block;
  cursor: pointer;
`

export const companyTitle = css`
  font-size: 12px;
  color: ${colors.primary};
`

export const activityTitle = css`
  font-size: 24px;
  color: ${colors.primary};
  font-weight: bold;
  display: flex;
`

export const activityTitleIcon = css`
  font-size: 16px;
  position: relative;
`

export const activityPopoverWrapper = css`
    position: relative;
    margin-left: 5px;
`

export const popoverStyle = css`
  margin: 0;
  margin-top: -10px;
  margin-right: 30px;
  text-align: left;
  max-width: 230px;
  padding: 15px;
  box-sizing: border-box;
  transform: translate3d(-95px, 54px, 0px) !important;
`

export const selectorStyle = css`
  margin: 0;
  max-height: 400px;
`

export const hidePopover = css`
  display: none;
`

export const dialogContainer = css`
  max-width: 800px;
`

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import {
  bold,
  amountDueContainer,
  amountDueLabel,
  amountDueStyle,
  containerOfTotalizer
} from './InvoiceBatchPayment.style'
import { flexCenteredSpaceBetweenRow, Card } from '@bonitour/components'
import { marginBottom } from 'assets/styles/global'
import { formatMoney } from '@bonitour/common-functions'

const marginBottom20 = marginBottom(20)

const formatValue = (value) => formatMoney(value || 0)

const InvoiceBatchPaymentTotalizer = ({ paymentsInfo }) => {
  return (
    <div css={[containerOfTotalizer]}>
      <div css={[flexCenteredSpaceBetweenRow, marginBottom20]}>
        <span>Valor bruto</span>
        <span css={bold}>{formatValue(paymentsInfo.grossValue)}</span>
      </div>

      <div css={[flexCenteredSpaceBetweenRow, marginBottom20]}>
        <span>Desconto</span>
        <span css={bold}>{formatValue(paymentsInfo.discount)}</span>
      </div>

      <div css={[flexCenteredSpaceBetweenRow, marginBottom20]}>
        <span>Alíquota</span>
        <span css={bold}>{formatValue(paymentsInfo.aliquot)}</span>
      </div>

      <div css={[flexCenteredSpaceBetweenRow, marginBottom20]}>
        <span>Valor líquido</span>
        <span css={bold}>{formatValue(paymentsInfo.liquidValue)}</span>
      </div>

      <Card css={[amountDueContainer, marginBottom20]}>
        <p css={amountDueLabel}>Valor pago</p>
        <span css={[amountDueStyle, bold]}>{formatValue(paymentsInfo.totalPaid)}</span>
      </Card>
    </div>
  )
}

export default InvoiceBatchPaymentTotalizer

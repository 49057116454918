export const EDIT_RESERVATION = 'editreservation'
export const SEND_EMAIL = 'sendEmail'

export const editStates = {
  [EDIT_RESERVATION]: {
    title: 'Alteração do estado da reserva',
    message: 'Deseja cancelar a sua reserva? Todos os ingressos serão cancelados. Deseja continuar?',
    buttonLabelConfirm: 'Cancelar',
    buttonLabelCancel: 'Voltar'
  },
  [SEND_EMAIL]: {
    title: 'Confirmar ação',
    message: 'Você está prestes a enviar um email para o responsável da reserva com o resumo da reserva. Apenas os ingressos confirmados aparecerão nesse email. Gostaria de continuar?',
    buttonLabelConfirm: 'Enviar',
    buttonLabelCancel: 'Cancelar'
  }
}

/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import { useState } from 'react'
import { editIconStyle, horizontallyCentered, collapsableIcon, collapsableIconOpen, collapsableIconStyle, textOverflow, tableContents } from './ListBankAccounts.styles'
import { TableContainer, TableHeader, TableHeaderRow, TableHeaderCell, TableBody, TableBodyRow, TableBodyCell, PencilIcon, SortDownIcon, flexHorizontallyCentered, ToggleInputGroup } from '@bonitour/components'
import { ExpandedTaxes } from './ExpandedTaxes/ExpandedTaxes'
import { clickable } from 'assets/styles/global'
import { accountTypeOptions } from '../constants/bankAccountConstants'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { identity } from '@bonitour/common-functions'

const BankAccountsTableHeader = () => (
  <TableHeader>
    <TableHeaderRow>
      <TableHeaderCell>Identificador da conta</TableHeaderCell>
      <TableHeaderCell>Banco</TableHeaderCell>
      <TableHeaderCell>Agência</TableHeaderCell>
      <TableHeaderCell>Conta</TableHeaderCell>
      <TableHeaderCell>Tipo de conta</TableHeaderCell>
      <TableHeaderCell>Status</TableHeaderCell>
      <TableHeaderCell fixed>Ações</TableHeaderCell>
    </TableHeaderRow>
  </TableHeader>
)

const BankAccountsTableRow = ({ bankAccount = {}, onToggleEnabledClick: emitToggleEnabledClick = identity }) => {
  const { push } = useHistory()
  const { id, accountName = '-', bankCode = '', bankName = '', agencyNumber = '-', accountNumber = '-', accountType, enabled, fees = [] } = bankAccount
  const [bankAccountExpanded, setBankAccountExpanded] = useState(false)

  const onBankAccountDetail = () => () => setBankAccountExpanded(!bankAccountExpanded)

  const onEditClick = bankAccountId => () => push(`/bank-accounts/${bankAccountId}/edit`)

  const onToggleEnabledClick = bankAccount => () => emitToggleEnabledClick(bankAccount)

  const accountTypeLabel = accountType => {
    const { label = '' } = accountTypeOptions.find(item => item.value === accountType) || {}
    return label
  }

  return (
    <>
      <TableBodyRow>
        <TableBodyCell css={clickable} onClick={onBankAccountDetail()}>
          <div css={flexHorizontallyCentered}>
            <SortDownIcon css={[collapsableIconStyle, collapsableIcon, bankAccountExpanded && collapsableIconOpen]} />
            <span css={textOverflow}>{accountName}</span>
          </div>
        </TableBodyCell>
        <TableBodyCell css={clickable} onClick={onBankAccountDetail()}>
          <span css={textOverflow}>{`${bankCode} ${bankName}`}</span>
        </TableBodyCell>
        <TableBodyCell css={clickable} onClick={onBankAccountDetail()}>{agencyNumber}</TableBodyCell>
        <TableBodyCell css={clickable} onClick={onBankAccountDetail()}>{accountNumber}</TableBodyCell>
        <TableBodyCell css={clickable} onClick={onBankAccountDetail()}>{accountTypeLabel(accountType)}</TableBodyCell>
        <TableBodyCell css={horizontallyCentered}>
          <ToggleInputGroup id={id} checked={enabled} onClick={onToggleEnabledClick(bankAccount)}>
            {enabled ? 'Ativo' : 'Desativado'}
          </ToggleInputGroup>
        </TableBodyCell>
        <TableBodyCell fixed>
          <PencilIcon css={editIconStyle} onClick={onEditClick(id)} />
        </TableBodyCell>
      </TableBodyRow>
      {bankAccountExpanded && (
        <TableBodyCell colSpan='100%'>
          <ExpandedTaxes fees={fees} />
        </TableBodyCell>)}
    </>
  )
}

export const ListBankAccounts = ({ bankAccounts, loading, onToggleEnabledClick = identity }) => {
  return (
    <TableContainer>
      <BankAccountsTableHeader />
      <TableBody loading={loading}>
        {bankAccounts.map((bankAccount, index) => (
          <div css={tableContents} key={index}>
            <BankAccountsTableRow bankAccount={bankAccount} onToggleEnabledClick={onToggleEnabledClick} />
          </div>
        ))}
      </TableBody>
    </TableContainer>
  )
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { TableContainer, TableBody, scrollBar, flexColumnCentered } from '@bonitour/components'
import { identity, head } from '@bonitour/common-functions'
import { VacancyTableHeader } from 'domains/Booking/Map/Table/Header/VacancyTableHeader'
import { VacancyTableRow } from 'domains/Booking/Map/Table/Row/VacancyTableRow'
import { marginBottom } from 'assets/styles/global'
import { useCallback, useMemo } from 'react'
import { MIN_VACANCY_WARN_PERCENTAGE } from 'constants/bookingMap'

const tableBody = css`
  position: relative;
  padding: 10px 0;
  min-height: 165px;
  max-height: calc(100vh - 440px);
  overflow: auto;
  ${scrollBar(5)};
`

const block = css`
  display: block;
`

const scrollTable = css`
  display: table;
  width: 100%;
  table-layout: fixed;
`

const marginBottom20 = marginBottom(20)

const VacancyTable = ({
  date = new Date(),
  isExpandable = true,
  dateColumns = [],
  tablePrices = {},
  tableData = {},
  tableDetailedData = {},
  isLoading = false,
  isActivityOwner = false,
  VacancySummaryPopoverContent,
  currentExpandedRowLabel,
  onExpand: emitExpandEvent = identity,
  onExceptionalDayEditClick = identity
}) => {
  const isExpanded = hour => hour === currentExpandedRowLabel
  const hasData = useMemo(() => Object.keys(tableData).length, [tableData])

  const tableDataValues = useMemo(() => Object.values(tableData), [tableData])

  /**
   * @param {string} dateString Date in 'YYYY-MM-DD' format
   * @returns {number} Daily vacancy limit
   */
  const getLimitOfDay = useCallback((dateString) => {
    // TODO: put hourlies inside of day instead days inside of hourlies
    const day = head(tableDataValues.filter((datesObject) => Boolean(datesObject?.[dateString])))?.[dateString]

    if (!day) return 0

    const limitOfDay = day?.limitOfDay || 0

    return limitOfDay
  }, [tableDataValues])

  /**
   * @param {string} dateString Date in 'YYYY-MM-DD' format
   * @returns {boolean, boolean, boolean, boolean, number} First value is if the limit of the day is near, second value is if the limit of the day was reached, third value is if the limit of the day is safe, fourth value is if the daily limit is greater than the taotal vacancies of day, fifth value is the total of occupied vacancies
   */
  const checkCanShowLimitOfDay = useCallback((dateString) => {
    const [occupiedVacanciesTotals, freeVacancyTotals] = tableDataValues.reduce((acc, currDatesData) => {
      const dateData = currDatesData?.[dateString]

      if (!dateData) return acc

      const totalOccupiedVacancies = dateData?.reservations?.total + acc[0]
      const totalFreeVacancies = dateData?.vacancies?.total + acc[1]

      return [totalOccupiedVacancies, totalFreeVacancies]
    }, [0, 0])

    // It's determine that haven't vacancy
    if (freeVacancyTotals === 0 && occupiedVacanciesTotals === 0) {
      return [false, false, false, false, occupiedVacanciesTotals]
    }

    const limitOfDay = getLimitOfDay(dateString)

    const {
      isReachedLimitOfDay,
      isNearLimitOfDay,
      isSafeLimitOfDay,
      isDailyLimitGreatherThanTotalVacanciesOfDay
    } = (function () {
      const isReached = occupiedVacanciesTotals >= limitOfDay

      const isNear = isReached
        ? false
        : (function () {
          const freeVacanciesPercentage = ((limitOfDay - occupiedVacanciesTotals) / limitOfDay) * 100

          const isReachedMinVacancyWarn = (freeVacanciesPercentage <= (100 - MIN_VACANCY_WARN_PERCENTAGE))

          return isReachedMinVacancyWarn
        }())

      const isSafe = !isReached && !isNear

      const isDailyLimitGreatherThanTotalVacanciesOfDay = (function () {
        const totalVacanciesOfDay = freeVacancyTotals + occupiedVacanciesTotals

        const isLimitGreaterThanVancaciesOfDay = limitOfDay > totalVacanciesOfDay

        return isLimitGreaterThanVancaciesOfDay && !isReached
      }())

      return {
        isReachedLimitOfDay: isReached,
        isNearLimitOfDay: isNear,
        isSafeLimitOfDay: isSafe,
        isDailyLimitGreatherThanTotalVacanciesOfDay
      }
    }())

    if (occupiedVacanciesTotals && !limitOfDay) {
      return [
        false,
        false,
        false,
        false,
        0
      ]
    }

    return [
      isNearLimitOfDay,
      isReachedLimitOfDay,
      isSafeLimitOfDay,
      isDailyLimitGreatherThanTotalVacanciesOfDay,
      occupiedVacanciesTotals
    ]
  }, [getLimitOfDay, tableDataValues])

  return (
    <TableContainer css={isLoading && flexColumnCentered}>
      <VacancyTableHeader
        selectedDate={date}
        dateColumns={dateColumns}
        tablePrices={tablePrices}
        getLimitOfDay={getLimitOfDay}
        css={[scrollTable, isLoading && marginBottom20]}
        onExceptionalDayEditClick={onExceptionalDayEditClick}
        isActivityOwner={isActivityOwner}
        checkCanShowLimitOfDay={checkCanShowLimitOfDay}
      />
      <TableBody css={[tableBody, hasData && block]} loading={isLoading}>
        {Object.entries(tableData).map(([hour, datesData]) => (
          <VacancyTableRow
            css={scrollTable}
            isExpandable={isExpandable}
            key={`date-row-hour-${hour}`}
            VacancySummaryPopoverContent={VacancySummaryPopoverContent}
            rowHour={hour}
            isExpanded={isExpanded(hour)}
            onExpand={emitExpandEvent}
            datesData={datesData}
            tableDetailedData={tableDetailedData}
            dateColumns={dateColumns}
            onExceptionalDayEditClick={onExceptionalDayEditClick}
            tablePrices={tablePrices}
          />
        ))}
      </TableBody>
    </TableContainer>
  )
}

export default VacancyTable

import { useToast } from '@bonitour/components'
import React, { useCallback } from 'react'
import { useHistory, useParams } from 'react-router'
import { PlanForm } from '../Form/PlanForm'
import { useCreateCompanyPlan } from '../hooks/useCreateCompanyPlan'

export const CreatePlan = () => {
  const { companyId } = useParams()
  const { add: addToast } = useToast()
  const { push } = useHistory()
  const { submit, loading } = useCreateCompanyPlan()
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const onBack = useCallback(() => push('/binamik/companies'), [push])
  const onError = useCallback(() => addToast('Preencha corretamente o formulário'), [addToast])

  const handleSubmit = useCallback((formValues) => {
    return submit(companyId, formValues).then(() => {
      addToast('Plano criado com sucesso', 'success')
      onBack()
    }).catch(() => {
      addToast('Erro ao criar o plano')
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PlanForm loading={loading} onCancel={onBack} handleSubmit={handleSubmit} onError={onError} />
  )
}

import { date, ref } from 'yup'

export const NoticesFilterSchema = {
  sentInitialDate: date()
    .max(ref('sentFinalDate'), 'A data de envio inicial deve ser menor ou igual a data final'),
  sentFinalDate: date()
    .min(ref('sentInitialDate'), 'A data de envio final deve ser maior ou igual a data inicial'),
  serviceInitialDate: date()
    .max(ref('serviceFinalDate'), 'A data da experiência inicial deve ser menor ou igual a data final'),
  serviceFinalDate: date()
    .min(ref('serviceInitialDate'), 'A data da experiência final deve ser maior ou igual a data inicial')
}

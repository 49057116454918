import { css } from '@emotion/core'

import { BREAK_POINTS, colors } from '@bonitour/components'
import { marginBottom, marginLeft, marginRight, marginTop } from 'assets/styles/global'

export const marginTop50 = marginTop(50)
export const marginBottom20 = marginBottom(20)
export const marginBottom0 = marginBottom(0)
export const marginRight8 = marginRight(8)

export const checkboxGroup = css`
  @media (max-width: ${BREAK_POINTS.tableUp}) {
    flex-direction: column;
  }
`

export const infoSwatch = css`
  -webkit-text-fill-color: currentColor;
`

export const container = css`
  color: ${colors.gray3};
`

export const inputStyle = css`
  width: 110px;
  ${marginRight(10)};
  ${marginLeft(10)};
`

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Manager, Reference, Popover, Label, InputWithSuffix, SearchIcon } from '@bonitour/components'
import { hidden, marginBottom } from 'assets/styles/global'
import { useMemo, useRef, useState } from 'react'
import { identity } from '@bonitour/common-functions'
import { useClickOutside, useDebounce } from '@bonitour/app-functions'
import { selectorContainer, selectorArrowContainer, selectorOptions, selectorOption, optionLabel, searchIcon } from './SellersSelector.styles'
import { SwatchImage } from 'components/SwatchImage'

const marginBottom10 = marginBottom(10)

export const SellersSelector = ({
  options = [],
  children,
  onOptionSelected: emitOptionSelected = identity
}) => {
  const reference = useRef()
  const [searchValue, setSearchValue] = useState('')
  const [isOptionsOpen, setOptionsOpen] = useClickOutside(reference)
  const onOptionsOpen = () => setOptionsOpen(!isOptionsOpen)

  const onOptionClick = id => () => {
    setOptionsOpen(false)
    emitOptionSelected(id)
  }

  const queryWithDebounce = useDebounce(searchValue, 200)

  const optionsFiltered = useMemo(() => {
    if (queryWithDebounce) {
      return options.filter(({ name }) => {
        const optionQueryLowercase = queryWithDebounce.toLowerCase()
        const optionLowecase = name.toLowerCase()
        const hasQueryInName = optionLowecase.includes(optionQueryLowercase)
        return hasQueryInName
      })
    }
    return options
  }, [options, queryWithDebounce])

  return (
    <div css={selectorContainer} ref={reference}>
      <Manager>
        <>
          <Reference>
            {({ ref }) => (
              <div ref={ref} onClick={onOptionsOpen}>{children}</div>
            )}
          </Reference>
          <Popover position='right-start' css={[selectorArrowContainer, !isOptionsOpen && hidden]}>
            <div css={selectorOptions}>
              <>
                <Label>Parceiros</Label>
                <InputWithSuffix css={marginBottom10} value={searchValue} onChange={setSearchValue} placeholder='Busca'>
                  <SearchIcon css={searchIcon} />
                </InputWithSuffix>

                {optionsFiltered.map(({ id, name, image }) =>
                  <div key={id} css={selectorOption} onClick={onOptionClick(id)}>
                    <SwatchImage size={30} image={image} alternativeText={name} />
                    <p css={optionLabel}>{name}</p>
                  </div>
                )}
              </>
            </div>
          </Popover>
        </>
      </Manager>
    </div>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useForm } from '@bonitour/app-functions'
import { identity } from '@bonitour/common-functions'
import { Card, Button, H4, useToast, GhostPrimaryButton } from '@bonitour/components'
import { marginTop, marginBottom, marginRight } from 'assets/styles/global'
import { FormHeader } from 'components/FormHeader'

import { useEffect } from 'react'
import { bookingSchema } from './LinkingForm.schema'
import { PaxSection } from '../Form/PaxSection/PaxSection'
import { ReservationHeader } from '../Form/ReservationHeader/ReservationHeader'

const marginTop20 = marginTop(20)
const marginBottom50 = marginBottom(50)
const marginRight20 = marginRight(20)

export const BookingLinkingForm = ({
  reservationCode = '',
  isPartner = false,
  reservationBase = {},
  ticketsList = [],
  partnerList = [],
  onBackClick: emitBackClickEvent = identity,
  onFinish: emitFinishEvent = identity,
  onContinue: emitContinueEvent = identity
}) => {
  const { add: addToast } = useToast()
  const onValidationError = () => addToast('Preencha corretamente o formulário')
  const {
    form,
    errors,
    onSubmit,
    utils: { onInputBlur, onInputChange }
  } = useForm(reservationBase, bookingSchema)

  useEffect(() => {
    if (ticketsList && ticketsList.length) {
      onInputChange('tickets')(ticketsList)
    }
    // eslint-disable-next-line
  }, [ticketsList])

  const onFinishClick = onSubmit(emitFinishEvent, onValidationError)
  const onContinueClick = onSubmit(emitContinueEvent, onValidationError)
  return (
    <>
      <FormHeader title={`Vincular na reserva ${reservationCode}`} ghostClick={emitBackClickEvent} />
      <Card>
        <ReservationHeader
          isPartner={isPartner}
          reservationHeader={form.reservationHeader}
          reservationHeaderErrors={errors.reservationHeader}
          tickets={form.tickets}
          onChange={onInputChange}
          onBlur={onInputBlur}
          partnerList={partnerList}
          css={marginBottom50}
        />

        <H4>Vagas</H4>

        <PaxSection
          title='Tipificação base'
          isExpanded
          tickets={form.tickets}
          ticketsErrors={errors.tickets}
          onChange={onInputChange}
          onBlur={onInputBlur}
        />

        <div css={marginTop20}>
          <GhostPrimaryButton css={marginRight20} onClick={onContinueClick}>
            Reservar e Continuar
          </GhostPrimaryButton>
          <Button onClick={onFinishClick}>Finalizar Reserva</Button>
        </div>
      </Card>
    </>
  )
}

import { css } from '@emotion/core'
import { BREAK_POINTS } from '@bonitour/components'

export const search = css`
width: 440px;
margin-right: 10px;

@media (max-width: ${BREAK_POINTS.bigPhone}) {
  width: 100%;
  margin: 0;
}
`

export const searchIcon = css`
  font-size: 20px;
`

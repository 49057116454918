/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Card, GhostButton, Button, flexHorizontallyCentered, useToast, ConfirmDialog, LoadingAnimation } from '@bonitour/components'
import { width240, padding20, label, infoCardStyle } from './PaymentRegister.style'
import { identity, formatMoney, unformatMoney } from '@bonitour/common-functions'
import { marginBottom, marginRight, hidden, loadingContainerOverlay } from 'assets/styles/global'
import { BankInfo } from './BankInfo'
import { useForm } from '@bonitour/app-functions'
import { invoicePaySchema } from 'core/schemas/invoicePay'
import { PaymentRegister } from './PaymentRegister'
import { useState } from 'react'

const marginBottom50 = marginBottom(50)
const marginRight20 = marginRight(20)
const marginRight30 = marginRight(30)

export const PaymentRegisterForm = ({
  bankInfo = {},
  amountDue = 0,
  onCancel: emitClickCancel = identity,
  onSuccess: emitSuccessEvent = identity,
  vendorId = '',
  companyId = '',
  isLoading = false
}) => {
  const { add } = useToast()
  const [paymentInfo] = useState({})
  const [isConfirmActionVisible, setIsConfirmActionVisible] = useState(false)
  const { form, errors, onSubmit, utils: { onInputBlur, onInputChange } } = useForm(paymentInfo, invoicePaySchema)

  const onValidationError = () => add('Preencha corretamente o formulário')
  const onConfirm = onSubmit(emitSuccessEvent, onValidationError)
  const toggleConfirmActionVisible = () => setIsConfirmActionVisible(true)
  const toggleConfirmActionHidden = () => setIsConfirmActionVisible(false)

  const formatValue = value => formatMoney(value || 0)

  const isVendor = vendorId === companyId

  const showConfirmDialog = (unformatMoney(form.value) === amountDue) && isVendor

  return (
    <>
      {isLoading && <LoadingAnimation customCss={[loadingContainerOverlay]}/>}
      <ConfirmDialog
        title='Registrar pagamento'
        message='Você está prestes a registrar o pagamento da fatura, e esta ação irá quitar a fatura. A ação não poderá ser revertida depois. Tem certeza que deseja continuar?'
        isVisible={isConfirmActionVisible}
        successCallback={onConfirm}
        cancelCallback={toggleConfirmActionHidden}
      />

      <div css={[flexHorizontallyCentered, marginBottom50, hidden]}>
        <Card css={[padding20, width240, marginRight30]}>
          <p css={label}>Valor devido</p>
          <span css={infoCardStyle}>{formatValue(amountDue)}</span>
        </Card>
        <BankInfo bankInfo={bankInfo} />
      </div>

      <PaymentRegister
        vendorId={vendorId}
        companyId={companyId}
        paymentInfo={form}
        paymentInfoErrors={errors}
        onChange={onInputChange}
        onInputBlur={onInputBlur}
      />

      <div css={flexHorizontallyCentered}>
        <GhostButton css={[marginRight20]} onClick={emitClickCancel}>Cancelar</GhostButton>
        <Button onClick={showConfirmDialog ? toggleConfirmActionVisible : onConfirm}>Registrar</Button>
      </div>
    </>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { Card, HeaderPage, Loading, PaginationContainer } from '@bonitour/components'
import { jsx } from '@emotion/core'
import { marginBottom } from 'assets/styles/global'
import { useHistory } from 'react-router'
import { MyDayFilters } from './MyDayFilters'
import { MyDayTable } from '../Table/MyDayTable'
import { identity } from '@bonitour/common-functions'
import { usePartnerActivityOptions } from 'domains/CompanyReports/hooks/usePartnerActivityOptions'
import { MyDayResults } from './MyDayResults'
import { ExportFile } from 'components/ExportFile/ExportFile'
import { myDayFormatOptions } from './myDayFormatOptions'

const marginBottom50 = marginBottom(50)

export const MyDayList = ({
  onFilterSubmit = identity,
  onSubmitExport = identity,
  loading,
  exporting = false,
  isTicketInFuture = false,
  isTicketSelectable = false,
  isSelectedAll = false,
  activities = [],
  initialFilters,
  filters = {},
  pagesQuantity = 1,
  total = 0,
  totalizer = 0,
  exportColumns = [],
  onPagination: emitPaginationEvent = identity,
  isTicketSelect = identity,
  onValidateClick = identity,
  onSelectedAllClick = identity,
  onTicketClick = identity,
  canValidateTicket = identity
}) => {
  const { push } = useHistory()
  const goToDashboard = () => push('/dashboard')
  const { activityOptions, getActivityOptions } = usePartnerActivityOptions()
  const navigateToReservation = reservationId => () => window.open(`/reservation/${reservationId}`, '_blank', 'noopener noreferrer')
  const headings = [{ title: 'Gerando arquivo...' }]

  return (
    <>
      {exporting && <Loading isLoading={exporting} headings={headings} />}

      <HeaderPage onBack={goToDashboard} title='Meu dia' />

      <Card css={marginBottom50}>
        <MyDayFilters
          onFilterSubmit={onFilterSubmit}
          loading={loading}
          initialFilters={initialFilters}
          getActivityOptions={getActivityOptions}
          activityOptions={activityOptions}
        />

        {!loading && Boolean(totalizer?.activitiesAmount > 0) && <MyDayResults totals={totalizer} />}

        <ExportFile
          onSubmitExport={onSubmitExport}
          loading={loading}
          initialFilters={initialFilters}
          filters={filters}
          multiSelectOptions={exportColumns}
          formatOptions={myDayFormatOptions}
        />

        <PaginationContainer total={total} pagesQuantity={pagesQuantity} onPagination={emitPaginationEvent}>
          <MyDayTable
            activities={activities}
            loading={loading}
            onNavigation={navigateToReservation}
            onValidateClick={onValidateClick}
            isTicketInFuture={isTicketInFuture}
            onSelectedAllClick={onSelectedAllClick}
            onTicketClick={onTicketClick}
            isTicketSelectable={isTicketSelectable}
            canValidateTicket={canValidateTicket}
            isTicketSelect={isTicketSelect}
            isSelectedAll={isSelectedAll}
          />
        </PaginationContainer>
      </Card>
    </>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { CollapsableSection, Row, RadioInputGroup, RadioGroup, BREAK_POINTS } from '@bonitour/components'
import { GroupCard } from 'containers/Sellers/Groups/Card'
import { FormHeader } from 'components/FormHeader'
import { identity } from '@bonitour/common-functions'

const groupCard = css`
  position: relative;
  max-width: 220px;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    max-width: 100%;
  }
`

const radioPosition = css`
  position: absolute;
  top: 10px;
  left: 10px;
`

const header = css`
  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    flex-direction: column;
  }
`

export const InvitePage = ({
  selectedGroup,
  setSelectedGroup,
  groups = [],
  onBackClick = identity,
  onNextClick = identity
}) => {
  return (
    <>
      <FormHeader
        css={header}
        title='Convidar para acordo comercial'
        ghostTitle='Voltar'
        ctaTitle='Convidar'
        ghostClick={onBackClick}
        ctaClick={onNextClick}
      />
      <CollapsableSection indicator={1} title='Grupo' isExpanded>
        <RadioGroup value={selectedGroup} onChange={setSelectedGroup}>
          <Row>
            {
              groups.map(({ id, tradeAgreement: { name = '' } }) => (
                <GroupCard css={groupCard} key={id} title={name} name={name}>
                  <RadioInputGroup name='trade-agreement-invite' id={id} value={id} css={radioPosition} />
                </GroupCard>
              ))
            }
          </Row>
        </RadioGroup>
      </CollapsableSection>
    </>
  )
}

import { css } from '@emotion/core'

export const simplifiedFilterBar = css`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;

  > div {
    flex-direction: row-reverse;
  }
`

import { useState } from 'react'
import { PartnershipAccountService } from 'core/services/PartnershipAccount'

export const useShowPartnershipTransactions = () => {
  const [loading, setLoading] = useState(false)
  const [transaction, setTransaction] = useState({})

  const fetchTransaction = async ({ partnershipAccountId, partnershipTransactionId }) => {
    setLoading(true)
    return PartnershipAccountService.getTransactionById({ partnershipAccountId, partnershipTransactionId })
      .then(data => {
        setTransaction(data)
      })
      .finally(() => setLoading(false))
  }

  return {
    fetchTransaction,
    loading,
    transaction
  }
}

import { string, boolean, number } from 'yup'

const isDeadlineValid = deadline => deadline > 0

export const invoicePolicySchema = {
  invoiced: boolean().required(),
  deadline: number().when('invoiced', {
    is: false,
    then: s => s
      .required('O deadline é obrigatório para pagamento antecipado')
      .typeError('Deve ser um número')
      .min(1, 'O número de dias deve ser maior ou igual a 1')
  }),
  deadlineType: string().when('deadline', {
    is: isDeadlineValid,
    then: s => s
      .required('O tipo do deadline é obrigatório')
  }),
  closingPeriod: string()
    .required('A sazonalidade de fechamento da fatura é obrigatória'),
  closingDay: string()
    .required('O dia de fechamento da fatura é obrigatória'),
  paymentDay: string()
    .required('O dia de pagamento da fatura é obrigatória')
}

import { formatDate, identity } from '@bonitour/common-functions'
import { formatISOTime } from 'utils/time'
import { MetaParser } from './Meta'

const RedirectToMapping = {
  'invoices-debts': '/invoices-pay/$id/edit',
  'invoices-revenues': '/invoices-receive/$id/edit'
}

const LinkMapping = {
  'TicketSeller::Reservation': '/reservation/$id?selectedNav=ingressos',
  'TicketSeller::Ticket': '/reservation/$id?selectedNav=ingressos',
  'Partnership::Invite': '/group/invitation/$id',
  'Financial::InvoiceRevenue': '/invoices-receive/$id/edit',
  'Financial::Invoice': '/invoices-pay/$id/edit',
  'Financial::InvoiceDebt': '/invoices-pay/$id/edit',
  'Financial::InvoicePayment': '/invoices-receive/$id/edit',
  'Financial::AdvanceReservationPayment': '/reservation/$id?selectedNav=financeiro',
  'Financial::AdvanceReservationRefund': '/reservation/$id?selectedNav=financeiro',
  'Account::AlertConfiguration': '/reservation/$id?selectedNav=ingressos',
  OverbookOnDay: '/activity/$id/bookings?date=$date',
  OverbookOnHours: '/activity/$id/bookings?date=$date'
}

const InitialsMapping = {
  'Account::AlertConfiguration': 'CA',
  'Account::Company': 'DI',
  'TicketSeller::Reservation': 'RC',
  'TicketSeller::Ticket': 'RD',
  'Partnership::Invite': 'CP',
  'Financial::Invoice': 'FA',
  'Financial::InvoiceRevenue': 'FA',
  'Financial::InvoiceDebt': 'FA',
  'Financial::InvoicePayment': 'FP',
  'Financial::AdvanceReservationPayment': 'FR',
  'Financial::AdvanceReservationRefund': 'FR',
  OverbookOnDay: 'OD',
  OverbookOnHours: 'OH'
}

const parseDescription = (initials, title, description, dateTime) => {
  const parseMap = {
    OD: ({ title, description }) => ({
      title: `Overbooking em ${formatDate(title)}`,
      description: `Overbooking ocorrido no dia: ${formatDate(description)}`
    }),
    OH: ({ title, description }) => ({
      title: `Overbooking em ${formatDate(title)}`,
      description: `Overbooking ocorrido no horário: ${description}`
    }),
    RC: ({ title, description, dateTime }) => ({
      title,
      description: dateTime ? `${description}${formatISOTime(dateTime, 'DD/MM/YYYY - HH:mm')}` : description
    })
  }

  return (parseMap[initials] || identity)({ title, description, dateTime })
}

const generateUrl = (
  recordType,
  recordId,
  refId,
  redirectRoute,
  date = ''
) => (RedirectToMapping[redirectRoute] || LinkMapping[recordType] || '')
  .replace('$id', refId || recordId)
  .replace('$date', date)

const Notification = ({
  id,
  record_id: recordId,
  record_type: recordType,
  is_readed: isRead,
  details: {
    title: originalTitle = '',
    description: originalDescription = '',
    date_time: dateTime
  } = {},
  redirect_to: {
    ref_id: refId = '',
    route: redirectRoute = ''
  } = {}
}) => {
  const url = generateUrl(recordType, recordId, refId, redirectRoute, originalTitle)
  const initials = InitialsMapping[recordType]
  if (!url) {
    return
  }

  const { title, description } = parseDescription(initials, originalTitle, originalDescription, dateTime)

  return {
    id,
    url,
    title,
    initials,
    subtitle: description,
    recordId,
    isRead
  }
}

export const NotificationParser = {
  list ({ notifications = [], meta = {} }) {
    return {
      notifications: notifications.map(Notification).filter(Boolean),
      meta: MetaParser.meta(meta)
    }
  }
}

export const ActivityParser = {
  dailyMonitoring ({ daily_monitoring_reservations: dailyData = [] }) {
    return dailyData.map((data) => ({
      hour: data.slot_hour,
      reservationId: data.reservation_id,
      reservationCode: data.reservation_code,
      status: data.status,
      client: data.payer || '-',
      partner: data.commercial_partner_name,
      ticketCount: data.tickets_quantity
    }))
  },
  getIsLockedService: ({ locked }) => ({
    isLocked: locked
  })
}

import { orbCore } from 'services/Orb/Service'
import { MyDayParser } from 'core/parsers/myDay'
import { MyDayModel } from 'core/models/myDay'

const { myDayDomain, myDayTotalizersDomain } = orbCore

export const MyDayService = {
  list ({ filters, pagination }) {
    return myDayDomain.list(MyDayModel.list({ filters, pagination })).then(MyDayParser.list)
  },
  csvExport ({ filters, pagination }) {
    return myDayDomain.csvExport(MyDayModel.myDayExport({ filters, pagination }))
  },
  xlsxExport ({ filters, pagination }) {
    return myDayDomain.xlsxExport(MyDayModel.myDayExport({ filters, pagination }))
  },
  totals ({ filters }) {
    return myDayTotalizersDomain.totals(MyDayModel.list({ filters })).then(MyDayParser.totals)
  }
}

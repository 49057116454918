/** @jsxRuntime classic */
/** @jsx jsx */
import { useMemo } from 'react'
import { Button, Card, Column, H2, Input, InputFormGroup, Row, useToast } from '@bonitour/components'
import { jsx } from '@emotion/core'
import { useForm } from '@bonitour/app-functions'
import { validationSchema } from './CashierUsersAuthForm.schema'
import { cardWidth, userFormSection, actionRow, submitButtonStyle } from './CashierUsersAuthForm.style'

export const CashierUsersAuthForm = ({ onSubmit, loading, submitButtonLabel }) => {
  const { add: addToast } = useToast()
  const initialForm = useMemo(
    () => ({
      operatorLogin: '',
      operatorPassword: '',
      supervisorLogin: '',
      supervisorPassword: ''
    }),
    []
  )

  const {
    form,
    errors,
    onSubmit: submitForm,
    utils: { onInputChange, onInputBlur }
  } = useForm(initialForm, validationSchema)

  const onError = () => addToast('Preencha o formulário corretamente')
  const handleSubmit = submitForm(onSubmit, onError)

  const { operatorLogin = '', operatorPassword = '', supervisorLogin = '', supervisorPassword = '' } = form

  return (
    <Card css={cardWidth}>
      <H2>Fechamento de caixa</H2>
      <Row css={userFormSection}>
        <Column phone={12} desktop={6}>
          <InputFormGroup label='Agente' errorMessage={errors.operatorLogin}>
            <Input
              value={operatorLogin}
              error={errors.operatorLogin}
              onChange={onInputChange('operatorLogin')}
              onBlur={onInputBlur('operatorLogin')}
              disabled={loading}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={6}>
          <InputFormGroup label='Senha' errorMessage={errors.operatorPassword}>
            <Input
              type='password'
              value={operatorPassword}
              error={errors.operatorPassword}
              onChange={onInputChange('operatorPassword')}
              onBlur={onInputBlur('operatorPassword')}
              disabled={loading}
            />
          </InputFormGroup>
        </Column>
      </Row>

      <Row css={userFormSection}>
        <Column phone={12} desktop={6}>
          <InputFormGroup label='Supervisor' errorMessage={errors.supervisorLogin}>
            <Input
              value={supervisorLogin}
              error={errors.supervisorLogin}
              onChange={onInputChange('supervisorLogin')}
              onBlur={onInputBlur('supervisorLogin')}
              disabled={loading}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={6}>
          <InputFormGroup label='Senha' errorMessage={errors.supervisorPassword}>
            <Input
              type='password'
              value={supervisorPassword}
              error={errors.supervisorPassword}
              onChange={onInputChange('supervisorPassword')}
              onBlur={onInputBlur('supervisorPassword')}
              disabled={loading}
            />
          </InputFormGroup>
        </Column>
      </Row>

      <div css={actionRow}>
        <Button css={submitButtonStyle} onClick={handleSubmit} disabled={loading}>
          {submitButtonLabel}
        </Button>
      </div>
    </Card>
  )
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { AvatarCard, BREAK_POINTS } from '@bonitour/components'
import { clickable } from 'assets/styles/global'

const card = css`
  width: 100%;
  margin: 10px 5px;
  overflow: hidden;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    max-width: 155px;
  }
`

export const GroupCard = ({ name, image, ...other }) => {
  return (
    <AvatarCard css={[card, clickable]} image={image} title={name} {...other} />
  )
}

/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import { useCallback, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from '@bonitour/app-functions'
import { Button, GhostButton, HeaderPage, useToast } from '@bonitour/components'
import { BankAccountForm } from '../Form/BankAccountForm'
import { BankAccountSchema } from '../Form/BankAccount.schema'
import { marginRight, marginTop } from 'assets/styles/global'
import { bankAccountBase } from '../constants/bankAccountBase'
import { useCreateBankAccount } from '../hooks/useCreateBankAccount'
import { bankAccountPaymentMethods } from 'constants/paymentMethods'
import { bankAccountErrors } from '../constants/bankAccountConstants'
import { usePermissions } from 'hooks/usePermissions'

const marginTop20 = marginTop(20)
const marginRight10 = marginRight(10)

export const CreateBankAccount = () => {
  const { handlePermission } = usePermissions()
  const { push } = useHistory()
  const { add: addToast } = useToast()
  const { form, errors, onSubmit, utils: { onInputBlur, onInputChange, resetArray } } = useForm(bankAccountBase, BankAccountSchema)
  const { createBankAccount } = useCreateBankAccount()

  const onResetFees = () => {
    const newFeesValue = bankAccountPaymentMethods.reduce((acc, curr) => {
      return [
        ...acc,
        { paymentMethod: curr, value: 0 }
      ]
    }, [])
    resetArray('fees')(newFeesValue)
  }

  const redirectToList = () => push('/bank-accounts')

  const onValidationSuccess = useCallback((bankAccount) => {
    createBankAccount(bankAccount)
      .then(() => {
        addToast('Conta bancária salva com sucesso', 'success')
        redirectToList()
      })
      .catch((error) => {
        const { data: { errors } } = error

        let isShowedError = false

        errors?.forEach((errorCode) => {
          const errorDetail = bankAccountErrors?.[errorCode]

          if (errorDetail) {
            addToast(errorDetail); isShowedError = true
          }
        })

        if (isShowedError) return

        const hasPermission = handlePermission(error, 'Você não possui permissão de criação de conta bancária')
        hasPermission && addToast(bankAccountErrors[''])
      })
    // eslint-disable-next-line
  }, [])

  const onValidationError = useCallback(() => addToast('Preencha corretamente o formulário'), [addToast])

  const onSaveClick = useMemo(() => onSubmit(onValidationSuccess, onValidationError), [onSubmit, onValidationSuccess, onValidationError])

  useEffect(() => {
    onResetFees()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <HeaderPage onBack={redirectToList} title='Cadastrar conta bancária' />
      <BankAccountForm form={form} errors={errors} onInputBlur={onInputBlur} onInputChange={onInputChange} />
      <div css={marginTop20}>
        <GhostButton onClick={redirectToList} css={marginRight10}>Cancelar</GhostButton>
        <Button onClick={onSaveClick}>Salvar</Button>
      </div>
    </>
  )
}

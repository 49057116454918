/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { PlusIcon, ResetButton, CircleThumbnail, colors } from '@bonitour/components'

const wrapper = css`
  display: inline-flex;
  align-items: center;
  margin: 10px 0;
  font-size: 15px;
  font-weight: 500;
  color: ${colors.primaryVariant};
`

const wrapperNegative = css`
  color: ${colors.red3};
`

const circle = css`
  background-color: ${colors.primaryVariant};
  color: ${colors.white};
`

const circleNegative = css`
  background-color: ${colors.red3};
  color: ${colors.white};
`

const label = css`
  margin-left: 10px;
  text-align: left;
`

export const AddLabel = ({ children, negative = false, ...other }) => (
  <ResetButton css={[wrapper, negative && wrapperNegative]} {...other}>
    <CircleThumbnail css={[circle, negative && circleNegative]} size={30}>
      <PlusIcon />
    </CircleThumbnail>
    <span css={label}>{children}</span>
  </ResetButton>
)

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Card, colors, flexColumn, BREAK_POINTS } from '@bonitour/components'
import { formatMoney } from '@bonitour/common-functions'
import { marginBottom } from 'assets/styles/global'
import { useMemo } from 'react'

const marginBottom30 = marginBottom(30)
const marginTop10 = css`
  @media (max-width: ${BREAK_POINTS.desktop}) {
    margin-top: 10px;
  }
`

const totalCard = css`
  min-width: 150px;
  display: inline-flex;
  margin-right: 5px;
  padding: 20px;

  @media (max-width: ${BREAK_POINTS.tableUp}) {
    width: 100%;
  }
  `

const totalCardContent = css`
  @media (max-width: ${BREAK_POINTS.tableUp}) {
    align-items: center;
  }

`

const totalLabel = css`
  font-size: 12px;
  color: ${colors.gray5};
  margin-bottom: 5px;
`

const totalValue = css`
  font-size: 24px;
  font-weight: bold;
  color: ${colors.indigo1};
`

const cardsContainer = css`
  @media (max-width: ${BREAK_POINTS.tableUp}) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`

export const BatchTotals = ({ totals = {}, discountTotalValue = 0 }) => {
  const { vouchersQuantity = 0, value = 0 } = totals

  const totalValueLabel = useMemo(() => {
    const discountedValue = (value || 0) - discountTotalValue
    return formatMoney(discountedValue)
  }, [discountTotalValue, value])

  return (
    <div css={[marginBottom30, cardsContainer]}>
      <Card css={totalCard}>
        <div css={[flexColumn, totalCardContent]}>
          <p css={totalLabel}>Total de vouchers no lote</p>
          <span css={totalValue}>{vouchersQuantity}</span>
        </div>
      </Card>

      <Card css={[totalCard, marginTop10]}>
        <div css={[flexColumn, totalCardContent]}>
          <p css={totalLabel}>Valor total a pagar</p>
          <span css={totalValue}>{totalValueLabel}</span>
        </div>
      </Card>
    </div>
  )
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { TableBodyRow, TableBodyCell, TrashIcon, Input, InputTimeMask } from '@bonitour/components'
import { defaultRowHeight, defaultCellPadding, clickable } from 'assets/styles/global'
import { identity } from '@bonitour/common-functions'
import { useEffect } from 'react'

const iconSize = css`
  font-size: 23px;
`

const inputWidth = css`
  width: 100px;
`

export const ActivityVacancySchedulesRow = ({
  vacancySchedule,
  errors = {},
  index = 0,
  onChange: emitChangeEvent = identity,
  onBlur: emitBlurEvent = identity,
  onDelete: emitDeleteEvent = identity
}) => {
  const {
    time,
    vacancyQuantity,
    sundayQuantity,
    mondayQuantity,
    tuesdayQuantity,
    wednesdayQuantity,
    thursdayQuantity,
    fridayQuantity,
    saturdayQuantity
  } = vacancySchedule

  const changeQuantity = name => value => {
    emitChangeEvent(`scheduleList.${index}.${name}`)(value)
  }

  const changeVacancyQuantity = name => value => {
    changeQuantity(name)(value)
    changeQuantity('sundayQuantity')(value)
    changeQuantity('mondayQuantity')(value)
    changeQuantity('tuesdayQuantity')(value)
    changeQuantity('wednesdayQuantity')(value)
    changeQuantity('thursdayQuantity')(value)
    changeQuantity('fridayQuantity')(value)
    changeQuantity('saturdayQuantity')(value)
  }

  const weekdayQuantityArray = [sundayQuantity, mondayQuantity, tuesdayQuantity, wednesdayQuantity, thursdayQuantity, fridayQuantity, saturdayQuantity]

  const biggestWeekdayQuantity = weekdayQuantityArray.reduce((acc, item) => Math.max(acc, item))

  useEffect(() => {
    changeQuantity('vacancyQuantity')(biggestWeekdayQuantity)
    // eslint-disable-next-line
  }, [sundayQuantity, mondayQuantity, tuesdayQuantity, wednesdayQuantity, thursdayQuantity, fridayQuantity, saturdayQuantity])

  return (
    <TableBodyRow css={defaultRowHeight}>
      <TableBodyCell css={defaultCellPadding}>
        <InputTimeMask
          css={inputWidth}
          value={time}
          error={errors.time}
          onChange={emitChangeEvent(`scheduleList.${index}.time`)}
          onBlur={emitBlurEvent(`scheduleList.${index}.time`)}
        />
      </TableBodyCell>
      <TableBodyCell css={defaultCellPadding}>
        <Input
          type='number'
          min='0'
          css={inputWidth}
          maxLength='6'
          value={vacancyQuantity}
          error={errors.vacancyQuantity}
          onChange={changeVacancyQuantity('vacancyQuantity')}
        />
      </TableBodyCell>
      <TableBodyCell css={defaultCellPadding}>
        <Input
          type='number'
          min='0'
          css={inputWidth}
          maxLength='6'
          value={sundayQuantity}
          error={errors.sundayQuantity}
          onChange={changeQuantity('sundayQuantity')}
        />
      </TableBodyCell>
      <TableBodyCell css={defaultCellPadding}>
        <Input
          type='number'
          min='0'
          css={inputWidth}
          maxLength='6'
          value={mondayQuantity}
          error={errors.mondayQuantity}
          onChange={changeQuantity('mondayQuantity')}
        />
      </TableBodyCell>
      <TableBodyCell css={defaultCellPadding}>
        <Input
          type='number'
          min='0'
          css={inputWidth}
          maxLength='6'
          value={tuesdayQuantity}
          error={errors.tuesdayQuantity}
          onChange={changeQuantity('tuesdayQuantity')}
        />
      </TableBodyCell>
      <TableBodyCell css={defaultCellPadding}>
        <Input
          type='number'
          min='0'
          css={inputWidth}
          maxLength='6'
          value={wednesdayQuantity}
          error={errors.wednesdayQuantity}
          onChange={changeQuantity('wednesdayQuantity')}
        />
      </TableBodyCell>
      <TableBodyCell css={defaultCellPadding}>
        <Input
          type='number'
          min='0'
          css={inputWidth}
          maxLength='6'
          value={thursdayQuantity}
          error={errors.thursdayQuantity}
          onChange={changeQuantity('thursdayQuantity')}
        />
      </TableBodyCell>
      <TableBodyCell css={defaultCellPadding}>
        <Input
          type='number'
          min='0'
          css={inputWidth}
          maxLength='6'
          value={fridayQuantity}
          error={errors.fridayQuantity}
          onChange={changeQuantity('fridayQuantity')}
        />
      </TableBodyCell>
      <TableBodyCell css={defaultCellPadding}>
        <Input
          type='number'
          min='0'
          css={inputWidth}
          maxLength='6'
          value={saturdayQuantity}
          error={errors.saturdayQuantity}
          onChange={changeQuantity('saturdayQuantity')}
        />
      </TableBodyCell>
      <TableBodyCell css={defaultCellPadding} fixed>
        <TrashIcon css={[iconSize, clickable]} onClick={emitDeleteEvent} />
      </TableBodyCell>
    </TableBodyRow>
  )
}

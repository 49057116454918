import * as Sentry from '@sentry/react'
import pkg from '../../../package.json'
import { UserStore } from 'services/User/Store'
import { APP_ENVIRONMENT } from 'shared/config/env'

const { REACT_APP_SENTRY_DSN } = process.env
const { version } = pkg

export function SentrySetup () {
  const getSentryUserScope = () => {
    const { id, email } = UserStore.getUser()
    return { id, email }
  }

  if (APP_ENVIRONMENT === 'development') {
    return
  }

  if (REACT_APP_SENTRY_DSN) {
    Sentry.init({
      dsn: REACT_APP_SENTRY_DSN,
      release: version,
      environment: APP_ENVIRONMENT
    })

    Sentry.configureScope(function (scope) {
      scope.setUser(getSentryUserScope())
    })
  }
}

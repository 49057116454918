import moment from 'moment'
import { metaModel } from './meta'

/* eslint-disable camelcase */
export const PendingTicketsReportModel = {
  list: ({ filters = {}, pagination = {} }) => {
    const {
      dateStart: start_date,
      dateEnd: end_date
    } = filters

    const meta = metaModel(pagination)

    const searchParams = {
      initial_date: start_date ? moment(start_date).format('YYYY-MM-DD') : undefined,
      final_date: end_date ? moment(end_date).format('YYYY-MM-DD') : undefined
    }

    return { searchParams, pagination: meta }
  }
}

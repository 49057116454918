import { string, number, object, array } from 'yup'
import { vacancySchedulesSchema } from './VacancySchedules/VacancySchedules.schema'
import { periodsSchemaBuilder } from 'containers/Periods/Periods.schema'
import { getUniqueValues } from '@bonitour/common-functions'
import dayjs from 'dayjs'
import minMax from 'dayjs/plugin/minMax'

dayjs.extend(minMax)

const isSomeDataDuplicated = (originalArray) => {
  const newArray = getUniqueValues(originalArray)
  return originalArray.length !== newArray.length
}

export const activityVacancySchema = {
  activityVacancy: object({
    vacancyName: string().required('O nome da vaga é obrigatório'),
    dailyLimit: number()
      .typeError('Deve ser um número')
      .required('O limite diário obrigatório')
      .min(1, 'O limite deve ser no mínimo 1')
  }),
  periodList: array().of(object().shape(periodsSchemaBuilder(true)))
    .min(1, 'Adicione um período')
    .test('valid-date-range', 'O intervalo total de datas deve ser menor que 2 anos', (periods) => {
      const startDates = periods.map(period => dayjs(period.startDate).startOf('day'))
      const endDates = periods.map(period => dayjs(period.endDate).startOf('day'))

      const minStartDate = dayjs.min(startDates)
      const maxEndDate = dayjs.max(endDates)
      const daysDifference = Math.abs(maxEndDate.diff(minStartDate, 'day', true))

      return daysDifference < (365 * 2)
    }),
  scheduleList: array().of(object().shape(vacancySchedulesSchema))
    .min(1, 'Adicione uma vaga')
    .test('has-not-equal-hours', 'Não devem haver intervalos de horários iguais', (formData) => {
      const hourArray = formData.map(({ time }) => time)
      return !isSomeDataDuplicated(hourArray)
    })
}

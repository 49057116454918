import { css } from '@emotion/core'
import { colors } from '@bonitour/components'

export const payBatchFormContainer = css`
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1;
  border: 1px solid ${colors.gray7};
  border-radius: 8px;
  margin-top: 40px;
  padding-top: 12px;

  &::after {
    content: attr(data-subtitle);
    position: absolute;
    top: -6px;
    left: 10px;
    background-color: ${colors.white};
    padding: 0 8px;
    font-size: 14px;
    color: ${colors.gray4};
    font-weight: 700;
  }
`

export const fullInput = css`
  width: 100%;

  > div {
    width: 100%;
  }
`

export const disabledElement = css`
  cursor: not-allowed !important;
`

export const totalCss = css`
  * {
    color: ${colors.green1} !important;
    -webkit-text-fill-color: ${colors.green1} !important;
  }
`

export const columnMargin = css`
  @media screen and (max-width: 1200px){
    margin: 0.7rem 0 0.7rem 0;
  }
`

export const rowMargin = css`
  @media screen and (max-width: 1200px){
    margin: 0;
  }
`

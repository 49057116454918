/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Card, InputFormGroup, colors, Button, Select, useToast, Row, Column } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { useForm } from '@bonitour/app-functions'
import { string } from 'yup'
import { transactionTypesOptions, brandOptions, providerOptions } from 'constants/acquirers'
import { useState } from 'react'

const button = css`
  height: 40px;
  width: 100%;
  margin-top: 24px;
`
const cardContrast = css`
  margin-bottom: 20px;
  padding: 15px;
  background-color: ${colors.gray13};
  border: none;
`

export const AcquirerFilterSchema = {
  provider: string(),
  brand: string(),
  transactionType: string()
}

export const AcquirersFilter = ({
  initialFilters,
  onFilterSubmit: emitFilterSubmit = identity,
  loading
}) => {
  const { add: addToast } = useToast()
  const [filterBrandOptions] = useState([{ value: '', label: 'Todos' }, ...brandOptions])
  const [filterProvidersOptions] = useState([{ value: '', label: 'Todos' }, ...providerOptions])
  const [filterTransactionTypesOptions] = useState([{ value: '', label: 'Todos' }, ...transactionTypesOptions])

  const { form, errors, onSubmit, utils: { onInputChange } } = useForm(initialFilters, AcquirerFilterSchema)

  const onError = () => addToast('Preencha o formulário corretamente')

  const onClick = () => onSubmit(emitFilterSubmit(form), onError)

  const { provider = '', brand = '', transactionType = '' } = form

  return (
    <Card css={cardContrast}>
      <Row>
        <Column phone={12} desktop={3}>
          <InputFormGroup label='Adquirente' errorMessage={errors.provider}>
            <Select
              placeholder='Todos'
              options={filterProvidersOptions}
              value={provider}
              error={errors.provider}
              onChange={onInputChange('provider')}
              disabled={loading}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={3}>
          <InputFormGroup label='Bandeira' errorMessage={errors.brand}>
            <Select
              placeholder='Todos'
              options={filterBrandOptions}
              value={brand}
              error={errors.brand}
              onChange={onInputChange('brand')}
              disabled={loading}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={3}>
          <InputFormGroup label='Tipo' errorMessage={errors.transactionType}>
            <Select
              placeholder='Todos'
              options={filterTransactionTypesOptions}
              value={transactionType}
              error={errors.transactionType}
              onChange={onInputChange('transactionType')}
              disabled={loading}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={3}>
          <Button css={button} onClick={onClick}>Pesquisar</Button>
        </Column>
      </Row>
    </Card>
  )
}

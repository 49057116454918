export const PickupAndDropOffParser = {
  address: (pickupAddress) => {
    const {
      city_id: cityId,
      country_id: countryId,
      post_code: postCode,
      state_id: stateId,
      district,
      id,
      name,
      street,
      supplement
    } = pickupAddress

    return {
      cityId,
      countryId,
      district,
      postCode,
      stateId,
      id,
      name,
      street,
      supplement
    }
  },
  list: (pickupDropoff) => {
    return pickupDropoff.pick_up_drop_offs.map(PickupAndDropOffParser.single)
  },
  single: (pickupDropoff) => {
    const {
      id,
      logistic_type: logisticType,
      pick_up_drop_off_address: pickUpDropOffAddress,
      ticket
    } = pickupDropoff

    return {
      id,
      logisticType,
      pickUpDropOffAddress: PickupAndDropOffParser.address(pickUpDropOffAddress),
      ticket
    }
  }
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { LightTooltip, colors, flexColumn, flexHorizontallyCentered, flexRowCentered } from '@bonitour/components'

const customTooltip = css`
  ${flexColumn}
  align-items: flex-start;
`

const reservationDetailRow = css`
  margin: 5px;
  font-weight: 500;
  ${flexHorizontallyCentered}
  text-transform: capitalize;
`

const totalIndicator = css`
  width: 30px;
  height: 30px;
  border-radius: 10px;
  font-size: 14px;
  ${flexRowCentered}
  margin-right: 10px;
  color: ${colors.white};
  background-color: ${colors.primaryVariant};
`

const ReservationDetailRow = ({ total, commercialPartner }) => (
  <div css={reservationDetailRow}>
    <span css={totalIndicator}>{total}</span>
    {commercialPartner}
  </div>
)

export const ReservationsDetails = ({ reservationDetails, ...other }) => {
  return (
    <LightTooltip position='right' {...other} css={customTooltip}>
      {
        reservationDetails.map(({ total, commercialPartner }, index) => (
          <ReservationDetailRow key={index} total={total} commercialPartner={commercialPartner} />
        ))
      }
    </LightTooltip>
  )
}

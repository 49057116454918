/** @jsxRuntime classic */
/** @jsx jsx */
import {
  LightTooltip,
  flexColumn,
  HandshakeIcon,
  UserIcon,
  PersonalInfoIcon,
  flexRowCentered
} from '@bonitour/components'
import { jsx, css } from '@emotion/core'

const customTooltip = css`
  ${flexColumn}
  flex-grow: 1;
  align-items: flex-start;
`

const reservationDetailRow = css`
  ${flexRowCentered}
  font-weight: 500;
  font-size: 15px;
  padding: 8px 20px 8px 10px;
`

const reservationDetailIcon = css`
  width: 20px;
  margin-right: 15px;
  display: inline-flex;
  font-size: 18px;
`

export const ReservationDetails = ({ code, payer, commercialPartner, ...other }) => {
  return (
    <LightTooltip position='right' css={customTooltip} {...other}>
      <div css={reservationDetailRow}>
        <PersonalInfoIcon css={reservationDetailIcon} />
        {code}
      </div>
      <div css={reservationDetailRow}>
        <HandshakeIcon css={reservationDetailIcon} />
        {commercialPartner}
      </div>
      {Boolean(payer) &&
        <div css={reservationDetailRow}>
          <UserIcon css={reservationDetailIcon} />
          {payer}
        </div>}
    </LightTooltip>
  )
}

import moment from 'moment'

export const periodModel = (period = {}, companyId = '', tag = 'fee') => {
  const { id, name: label, startDate, endDate } = period
  return {
    id,
    company_id: companyId,
    label: label || '',
    initial_date: moment(startDate).format('YYYY-MM-DD'),
    final_date: moment(endDate).format('YYYY-MM-DD'),
    tag
  }
}

/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import { useHistory } from 'react-router-dom'
import { CardButton } from 'components/CardButton'
import { cardButton, cardIcon, labelCardButton, marginTop } from 'assets/styles/global'
import { ListBankAccounts } from './List/ListBankAccounts'
import { Card, H3, HeaderPage, Label, PaginationContainer, PlusIcon, useToast } from '@bonitour/components'
import { useListBankAccount } from './hooks/useListBankAccount'
import { useEditBankAccount } from './hooks/useEditBankAccount'
import { usePermissions } from 'hooks/usePermissions'
import { useCallback } from 'react'

const marginTop20 = marginTop(20)

export const BankAccounts = () => {
  const { push } = useHistory()
  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()
  const goToDashboard = () => push('/')
  const goToCreateBankAccount = () => push('/bank-accounts/create')
  const { bankAccounts, meta, loading, pagination, changePagination, fetchBankAccounts } = useListBankAccount()
  const { toggleEnabledBankAccount } = useEditBankAccount()

  const onToggleEnabledClick = useCallback((bankAccount) => {
    const { id, accountName = '', enabled } = bankAccount
    const enabledMessage = enabled ? 'desativada' : 'ativada'
    const successMessage = accountName ? `Conta ${accountName} ${enabledMessage} com sucesso` : `Conta bancária ${enabledMessage} com sucesso`

    toggleEnabledBankAccount(id)
      .then(() => {
        fetchBankAccounts({ pagination })
        addToast(successMessage, 'success')
      })
      .catch((error) => {
        const hasPermission = handlePermission(error, 'Você não possui permissão de edição de conta bancária')
        hasPermission && addToast('Erro ao ativar/desativar conta bancária')
      })
    // eslint-disable-next-line
  }, [pagination])

  return (
    <>
      <HeaderPage onBack={goToDashboard} title='Dados e taxas bancárias' />
      <CardButton onClick={goToCreateBankAccount} css={cardButton}>
        <PlusIcon css={cardIcon} />
        <Label css={labelCardButton}>Cadastrar nova conta bancária</Label>
      </CardButton>
      <H3 css={marginTop20}>Contas cadastradas</H3>
      <Card>
        <PaginationContainer pagesQuantity={meta?.totalPages} total={meta?.total} onPagination={changePagination}>
          <ListBankAccounts bankAccounts={bankAccounts} loading={loading} onToggleEnabledClick={onToggleEnabledClick} />
        </PaginationContainer>
      </Card>
    </>
  )
}

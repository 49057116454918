import { useCallback, useEffect, useMemo, useState } from 'react'
import { CashierService } from 'core/services/Cashier'

export const useListEmployees = () => {
  const [employees, setEmployees] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchdEmployees = useCallback(() => {
    setLoading(true)
    CashierService.listEmployees().then(setEmployees).finally(() => setLoading(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const filterEmployeesOptions = useMemo(() => employees?.reduce(
    (employeesOptions, employee) => [...employeesOptions, { value: employee.userId, label: employee.userName }],
    [{ value: '', label: 'Todos' }]),
  [employees])

  useEffect(() => {
    fetchdEmployees()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    employees,
    filterEmployeesOptions,
    loading
  }
}

import { CANCEL_TICKET, CANCEL_TICKET_TURIS, CONFIRM_RESERVATION, CREATE_VOUCHER, CREATE_VOUCHER_TURIS, DELETE_TICKET, dialogStates } from '../dialog.states'

export const useDialogStates = (
  toggleDialogHidden,
  handleCreateVoucherUnico,
  handleCreateVoucherTuris,
  handleTicketDelete,
  handleCancelVoucherUnico,
  handleCancelVoucherTuris,
  handleTicketConfirm,
  ticketId
) => {
  return {
    [CREATE_VOUCHER]: {
      ...dialogStates[CREATE_VOUCHER],
      successCallback: () => handleCreateVoucherUnico(ticketId),
      cancelCallback: toggleDialogHidden
    },
    [CREATE_VOUCHER_TURIS]: {
      ...dialogStates[CREATE_VOUCHER],
      successCallback: () => handleCreateVoucherTuris(ticketId),
      cancelCallback: toggleDialogHidden
    },
    [DELETE_TICKET]: {
      ...dialogStates[DELETE_TICKET],
      successCallback: () => handleTicketDelete(ticketId),
      cancelCallback: toggleDialogHidden
    },
    [CANCEL_TICKET]: {
      ...dialogStates[CANCEL_TICKET],
      successCallback: () => handleCancelVoucherUnico(ticketId),
      cancelCallback: toggleDialogHidden
    },
    [CANCEL_TICKET_TURIS]: {
      ...dialogStates[CANCEL_TICKET],
      successCallback: () => handleCancelVoucherTuris(ticketId),
      cancelCallback: toggleDialogHidden
    },
    [CONFIRM_RESERVATION]: {
      title: 'Confirmar ingresso',
      message: 'Você está prestes a confirmar este ingresso, e essa ação só poderá ser alterada pelo atrativo.',
      successCallback: () => handleTicketConfirm(ticketId),
      cancelCallback: toggleDialogHidden,
      buttonLabelConfirm: 'Continuar',
      buttonLabelCancel: 'Voltar'
    }
  }
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { TableContainer, TableBody } from '@bonitour/components'
import { AcquirerTableHeader } from './AcquirerTableHeader'
import { AcquirerTableRow } from './AcquirerTableRow'
import { identity } from '@bonitour/common-functions'

export const acquirerTable = css`
  margin-top: 30px;
`

export const AcquirerTable = ({ acquirers = [], onDeleteAcquirer = identity, loading }) => {
  return (
    <TableContainer css={acquirerTable}>
      <AcquirerTableHeader />
      <TableBody loading={loading}>
        {
          acquirers.map((acquirer, index) => {
            return <AcquirerTableRow key={index} acquirer={acquirer} onDeleteAcquirer={onDeleteAcquirer} />
          })
        }
      </TableBody>
    </TableContainer>
  )
}

/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { H1 } from '@bonitour/components'
import { jsx } from '@emotion/core'
import { AcquirerForm } from '../Form/AcquirerForm'
import { useCreateAcquirer } from '../hooks/useCreateAcquirer'

export const CreateAcquirer = () => {
  const { submit, loading } = useCreateAcquirer()

  return (
    <>
      <H1>Cadastro de Adquirente</H1>
      <AcquirerForm submit={submit} loading={loading} />
    </>
  )
}

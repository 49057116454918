import { date, string, ref } from 'yup'
import dayjs from 'dayjs'

export const periodsSchemaBuilder = (requireAfterToday = false) => ({
  name: string().required('O nome do período é obrigatório'),
  startDate: date()
    .min(dayjs(requireAfterToday ? undefined : 0).startOf('day').toDate(), 'A data inicial não pode ser menor que a data atual')
    .max(ref('endDate'), 'A data inicial deve ser menor ou igual a data final')
    .required('A data inicial é obrigatória'),
  endDate: date()
    .min(ref('startDate'), 'A data final deve ser maior ou igual a data inicial')
    .required('A data final é obrigatória')
    .test('valid-date-range', 'O intervalo total de datas deve ser menor que 2 anos', (endDate, ctx) => {
      const { startDate } = ctx.parent

      const daysDifference = Math.abs(
        dayjs(endDate).startOf('day').diff(
          dayjs(startDate).startOf('day'),
          'day',
          true
        ))

      return daysDifference < (365 * 2)
    })
})

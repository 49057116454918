export const PermissionsMapParser = {
  get: ({ permissions_map: permissionsMap }) =>
    Object.fromEntries(
      Object.entries(permissionsMap).map(([key, { C, R, U, D }]) => [
        key,
        { create: C, read: R, update: U, delete: D }
      ])
    )
}

export const PermissionsMacrosParser = {
  get: ({ macro_permissions: macroPermissions }) => macroPermissions
}

import { AuthStore } from 'services/Auth/Store'
import { initialize } from '@bonitour/orb-core'
import { identity } from '@bonitour/common-functions'
import { OrbStore } from './Store'
const { REACT_APP_API_HOST } = process.env

export const getImageUrl = image => image ? `${REACT_APP_API_HOST}${image}` : ''

export const extractData = ({ data }) => data

export const extractInnerData = ({ data: { data } }) => data

export const parseAttribute = parser => ({ attributes }, index) => parser(attributes, index)

const interceptorFunction = (interceptor) => {
  interceptor.request.use(
    config => {
      if (AuthStore.authKey && !config.headers.Authorization) {
        config.headers.Authorization = AuthStore.authKey
      }

      return config
    },
    error => Promise.reject(error)
  )
  interceptor.response.use(identity, error => {
    if (error && error.status === 401) {
      OrbStore.clean()
      setTimeout(() => {
        window.location.reload()
      }, 500)
    }
    return Promise.reject(error)
  })
}

export const orbCore = initialize(
  REACT_APP_API_HOST,
  interceptorFunction
)

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { colors, Card, flexHorizontallyCentered } from '@bonitour/components'
import { SwatchImage } from 'components/SwatchImage'
import { marginRight } from 'assets/styles/global'

const marginRight10 = marginRight(10)

const container = css`
  padding: 10px;
`
const title = css`
  font-size: 16px;
  font-weight: bold;
  color: ${colors.gray3};
  margin-top: 1px;
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
`

const titleContainer = css`
  max-width: calc(100% - 3rem);
`

export const CompanyCard = ({ name, image }) => {
  return (
    <Card css={[flexHorizontallyCentered, container]}>
      <SwatchImage css={marginRight10} size={40} image={image} alternativeText={name} />
      <div css={titleContainer}>
        <p css={title}>{name}</p>
      </div>
    </Card>
  )
}

import { head, tail } from '@bonitour/common-functions'
import { useChangeableInterval } from 'hooks/useInterval'
import { useCallback, useState } from 'react'
import { ReservationMapService } from 'services/ReservationMap/Service'

const MAP_INTERVAL = 60000

export const useMapSubscriber = (activity, dateColumns, interval = true) => {
  const [registries, setRegistries] = useState({})
  const [prices, setPrices] = useState({})
  const [loading, setLoading] = useState(false)

  const fetchTableData = useCallback(() => {
    setLoading(true)
    const { id: serviceId, companyId } = activity
    if (serviceId && companyId && dateColumns.length) {
      ReservationMapService.getMap({
        serviceId,
        companyId,
        startDate: head(dateColumns),
        endDate: tail(dateColumns)
      }).then(({ registries, prices }) => {
        setRegistries(registries)
        setPrices(prices)
      }).finally(() => setLoading(false))
    } else {
      setRegistries([])
      setPrices([])
    }
  }, [activity, dateColumns])

  const intervalTime = interval ? MAP_INTERVAL : null

  useChangeableInterval(fetchTableData, intervalTime, interval)

  return { registries, prices, loading, fetchTableData }
}

import { BREAK_POINTS } from '@bonitour/components'
import { css } from '@emotion/core'
import { marginTop } from 'assets/styles/global'

export const width170 = css`
  width: 170px;
`

export const inputMargin = css`
  margin: 0 10px;
`

export const modificatorSize = css`
  width: 120px;
`

export const finalPriceSize = css`
  width: 150px;
`

export const relativeCard = css`
  position: relative;

  display: flex;
  align-items: center;
`

export const trashIcon = css`
  display: inline-flex;
  font-size: 23px;
  width: 5%;
  justify-content: center;

  @media (max-width: ${BREAK_POINTS.tablet}) {
    width: 10%;
    justify-content: flex-end;
  }

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    position: absolute;
    top: 15px;
    right: 15px;
  }
`

export const flexColumnCenter = css`
  @media (max-width: ${BREAK_POINTS.tablet}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
`

export const vacancyFieldContainer = css`
  display: flex;
  align-items: baseline;

  ${flexColumnCenter}
`

export const marginTop20 = marginTop(20)
export const marginTop10 = marginTop(10)

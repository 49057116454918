/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { Column, Input, InputFormGroup, Label, Row, Textarea, ToggleInputGroup } from '@bonitour/components'
import { fieldLabelStyle, floatContainerStyle, relativeStyle } from './CombinedExperienceForm.styles'
import { marginTop } from 'assets/styles/global'

const FieldLabel = ({ label = '', isRequired = false }) => {
  return <Label css={fieldLabelStyle}>
    {label}
    <p>({isRequired ? 'Obrigatório' : 'Opcional'})</p>
  </Label>
}

export const CombinedExperienceBaseForm = ({
  register = identity,
  toggleIsEnabledExperience = identity,
  errors = {},
  isLoading = false,
  isEnabledExperience = true
}) => {
  return <div css={relativeStyle}>
    <div css={floatContainerStyle}>
      <ToggleInputGroup checked={isEnabledExperience} onClick={toggleIsEnabledExperience} disabled={isLoading}>
          Experiência ativada
      </ToggleInputGroup>
    </div>
    <Row css={marginTop(45)}>
      <Column phone={12} desktop={6}>
        <InputFormGroup label={<FieldLabel label='Título' isRequired={true}/>} errorMessage={errors?.title?.message}>
          <Input
            disabled={isLoading}
            onChangeWholeEvent={true}
            {...register('title')}
          />
        </InputFormGroup>
      </Column>
      <Column phone={12} desktop={6}>
        <InputFormGroup label={<FieldLabel label='Subtítulo' isRequired={true}/>} errorMessage={errors?.subtitle?.message}>
          <Input
            disabled={isLoading}
            onChangeWholeEvent={true}
            {...register('subtitle')}
          />
        </InputFormGroup>
      </Column>
    </Row>
    <Row>
      <Column phone={12} desktop={12}>
        <InputFormGroup label={<FieldLabel label='Descrição'/>} errorMessage={errors?.description?.message}>
          <Textarea
            onChangeWholeEvent={true}
            disabled={isLoading}
            {...register('description')}
          />
        </InputFormGroup>
      </Column>
    </Row>
  </div>
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Address } from 'containers/Address/Address'
import { OfflineExperienceId } from './OfflineExperienceId'
import { Button, BREAK_POINTS } from '@bonitour/components'
import { marginTop, width } from 'assets/styles/global'
import { identity } from '@bonitour/common-functions'
import { ContactForm } from 'containers/ContactForm/ContactForm'
import { useCallback, useMemo } from 'react'

const marginTop50 = marginTop(50)

const mobileButtonSize = css`
  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    ${width(100)};
  }
`

export const OfflineExperienceBaseForm = ({
  form = {},
  errors = {},
  countries = [],
  states = [],
  cities = [],
  onError: emitErrorEvent = identity,
  onZipCodeBlur = identity,
  addItemOnArray = identity,
  removeItemArray = identity,
  onLatLongBlur = identity,
  onStateChange = identity,
  onCountryChange = identity,
  onInputBlur = identity,
  updateCompanyAddress = identity,
  onInputChange = identity,
  onSubmit = identity,
  isEditing = false
}) => {
  const onAddressInputBlur = useCallback(addressInputName => onInputBlur(`addressInfo.${addressInputName}`), [onInputBlur])
  const onAddressInputChange = useCallback(addressInputName => onInputChange(`addressInfo.${addressInputName}`), [onInputChange])

  const offlineProviderId = useMemo(() => form?.serviceId?.offlineProviderId || undefined, [form])
  const onAddresCopyClick = useCallback(() => updateCompanyAddress({ offlineProviderId }), [updateCompanyAddress, offlineProviderId])

  return (
    <>
      <OfflineExperienceId
        serviceId={form.serviceId}
        serviceIdErrors={errors.serviceId}
        onError={emitErrorEvent}
        onInputBlur={onInputBlur}
        onChange={onInputChange}
        isEditing={isEditing}
      />
      <ContactForm
        phones={form.phones}
        emails={form.emails}
        phonesErrors={errors.phones}
        emailsErrors={errors.emails}
        onBlur={onInputBlur}
        onChange={onInputChange}
        onPhoneAdd={addItemOnArray('phones')}
        onPhoneRemove={removeItemArray('phones')}
        onEmailAdd={addItemOnArray('emails')}
        onEmailRemove={removeItemArray('emails')}
      />
      <Address
        addressInfo={form.addressInfo}
        addressInfoErrors={errors.addressInfo}
        onInputBlur={onAddressInputBlur}
        onInputChange={onAddressInputChange}
        onCountryChange={onCountryChange}
        onStateChange={onStateChange}
        onZipCodeBlur={onZipCodeBlur}
        onLatLongBlur={onLatLongBlur}
        onAddresCopyClick={onAddresCopyClick}
        countries={countries}
        states={states}
        cities={cities}
        providerAddressButton={Boolean(offlineProviderId)}
      />
      <Button css={[marginTop50, mobileButtonSize]} onClick={onSubmit}>Salvar</Button>
    </>
  )
}

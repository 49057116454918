/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { TableContainer, TableBody, TableHeader, TableHeaderRow, TableHeaderCell, TableBodyRow, TableBodyCell, flexRow, CircleThumbnail } from '@bonitour/components'
import { voucherStatusMap } from '../constants/voucherStatus'
import { formatMoney } from '@bonitour/common-functions'

export const table = css`
  margin-top: 30px;
`

const VouchersTableRow = ({ voucher = [] }) => {
  const { activityDate = '', externalCode = '', partnerName = '', activityName = '', passagerName = '', paxType = '', status = '', value = 0 } = voucher
  const [codeName, codeColor] = voucherStatusMap[status] || '-'

  return (
    <TableBodyRow>
      <TableBodyCell>{activityDate}</TableBodyCell>
      <TableBodyCell>{externalCode}</TableBodyCell>
      <TableBodyCell>{partnerName}</TableBodyCell>
      <TableBodyCell>{activityName}</TableBodyCell>
      <TableBodyCell>{passagerName}</TableBodyCell>
      <TableBodyCell>{paxType}</TableBodyCell>
      <TableBodyCell css={flexRow}>
        <CircleThumbnail size={10} style={{ backgroundColor: codeColor, marginRight: 10, marginTop: 3 }} />
        {codeName}
      </TableBodyCell>
      <TableBodyCell>{formatMoney(value)}</TableBodyCell>
    </TableBodyRow>
  )
}

export const VouchersTable = ({ vouchers = [], loading }) => {
  return (
    <TableContainer css={table}>
      <TableHeader>
        <TableHeaderRow>
          <TableHeaderCell>Data da experiência</TableHeaderCell>
          <TableHeaderCell>Código externo</TableHeaderCell>
          <TableHeaderCell>Parceiro</TableHeaderCell>
          <TableHeaderCell>Experiência</TableHeaderCell>
          <TableHeaderCell>Viajante</TableHeaderCell>
          <TableHeaderCell>Tipo do ingresso</TableHeaderCell>
          <TableHeaderCell>Status</TableHeaderCell>
          <TableHeaderCell>Valor</TableHeaderCell>
        </TableHeaderRow>
      </TableHeader>
      <TableBody loading={loading}>
        {
          vouchers.map((voucher, index) => {
            return <VouchersTableRow key={index} voucher={voucher} />
          })
        }
      </TableBody>
    </TableContainer>
  )
}

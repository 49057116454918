/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { NotificationIcon, NotificationItem, Popover, Reference, Manager, scrollBar, colors } from '@bonitour/components'
import { useNotification } from 'hooks/domains/useNotifications'
import { useHistory, NavLink } from 'react-router-dom'
import { clickable } from 'assets/styles/global'
import { useClickOutside } from '@bonitour/app-functions'
import { useRef } from 'react'
import { identity } from '@bonitour/common-functions'

const notificationContainer = css`
  margin-right: 25px;
`

const notificationPopover = css`
  @media screen and (max-width: 480px) {
    position: fixed;
    left: 0;
    right: 0;
    .component-popover-arrow {
      right: 4.75rem;
    }
  }
`

const popover = css`
  width: 290px;
  flex-direction: column;
  text-align: left;
  max-height: 200px;
  overflow-y: auto;
  ${scrollBar(5)}
`

const notificationTypeLabel = css`
  font-size: 14px;
  color: ${colors.gray5};
  padding: 5px;
`

const notificationsLink = css`
  padding: 10px;
  display: block;
  text-decoration: none;
  font-weight: bold;
  color: ${colors.gray1};
`

const NotificationList = ({ label = '', setVisibility = identity, notifications = [] }) => {
  const history = useHistory()

  const onNotificationClick = () => () => {
    history.push('/notifications')
    setVisibility(false)
  }

  if (notifications.length === 0) return null

  return (
    <>
      <div css={notificationTypeLabel}>{label}</div>
      {notifications.map(item => (
        <NotificationItem
          isRead={item.isRead}
          key={item.id}
          css={clickable}
          title={item.title}
          subtitle={item.subtitle}
          initials={item.initials}
          onClick={onNotificationClick()}
        />))}
    </>
  )
}

export const NotificationSection = () => {
  const reference = useRef()
  const [isVisible, setVisibility] = useClickOutside(reference)
  const { notifications, readNotifications, notReadNotifications, getNotifications } = useNotification()
  const hasNotifications = notifications.length > 0
  const hasNotReadNotifications = notReadNotifications.length > 0

  const toggleVisibility = () => setVisibility(visibility => {
    if (hasNotifications) {
      getNotifications()
      return !visibility
    }
  })

  return (
    <div ref={reference} css={notificationContainer}>
      <Manager>
        <Reference>
          {({ ref }) => (
            <div css={hasNotifications && clickable} ref={ref} onClick={toggleVisibility}>
              <NotificationIcon hasNotification={hasNotReadNotifications} />
            </div>
          )}
        </Reference>
        {
          isVisible && (
            <Popover position='bottom' customCss={[notificationPopover]}>
              <div>
                <div css={popover}>
                  <NotificationList label='Notificações novas' setVisibility={setVisibility} notifications={notReadNotifications} />
                  <NotificationList label='Notificações lidas' setVisibility={setVisibility} notifications={readNotifications} />
                </div>
                <NavLink to='/notifications' css={notificationsLink}>Visualizar outras notificações</NavLink>
              </div>
            </Popover>
          )
        }
      </Manager>
    </div>
  )
}

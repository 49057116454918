import { paymentMethodsThatRequiredBankAccount } from 'constants/paymentBatch'
import { number, string, ref, date } from 'yup'

const bankAccountSchema = (testName, errorMessage, paymentMethodsRequiring, isRequired = true) => string()
  .nullable()
  .test(
    testName,
    errorMessage,
    function (value, ctx) {
      const { method } = ctx.parent
      const isIncluded = paymentMethodsRequiring.includes(method)

      if (isIncluded && isRequired) {
        return Boolean(value)
      }

      return true
    }
  )

export const paymentProviderSchema = {
  companyId: string().required(),
  aut: string().nullable(),
  doc: string().nullable(),
  method: string().required(),
  installments: number()
    .typeError('Deve ser um número')
    .test('installments', 'As parcelas devem estar presentes', function (installments) {
      if (this.resolve(ref('method')) === 'credit') {
        return Boolean(installments)
      }
      return true
    }),
  acquirer: string().nullable()
    .test('acquirer', 'Um adquirente deve ser selecionado', function (acquirer) {
      if (this.resolve(ref('method')) === 'debit') {
        return Boolean(acquirer)
      }
      return true
    }),
  originBankAccount: bankAccountSchema('origin-is-bank-transfer-or-pix', 'Informe a conta bancária de origem', paymentMethodsThatRequiredBankAccount),
  destinationBankAccount: bankAccountSchema('destination-is-bank-transfer-or-pix', 'Informe a conta bancária de destino', paymentMethodsThatRequiredBankAccount, false),
  paymentDate: date()
}

export const providerRefundSchema = {
  aut: string().nullable(),
  doc: string().nullable(),
  method: string().required(),
  acquirer: string().nullable()
    .test('acquirer', 'Um adquirente deve ser selecionado', function (acquirer) {
      if (this.resolve(ref('method')) === 'debit') {
        return Boolean(acquirer)
      }
      return true
    })
}

export const linkSchema = {}

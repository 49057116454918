import { OrbStore } from 'services/Orb/Store'

const { REACT_APP_API_HOST } = process.env
export const getImageUrl = image => image ? image.includes(REACT_APP_API_HOST) ? image : `${REACT_APP_API_HOST}${image}` : ''

const ACCESS_ID = 'company'

function companyGetter () {
  try {
    const company = JSON.parse(OrbStore.get(ACCESS_ID))
    company.image = getImageUrl(company.image)
    return company
  } catch (error) {
    return {}
  }
}

export const CompanyStore = {
  getId () {
    const { id } = companyGetter()
    return id
  },
  getCompany () {
    return companyGetter()
  },
  setCompany (user) {
    try {
      const infoString = JSON.stringify(user)
      return OrbStore.set(ACCESS_ID, infoString)
    } catch (error) {}
  },
  clean () {
    OrbStore.remove(ACCESS_ID)
  }
}

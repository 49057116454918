export const perPage = 20

export const offlineExperienceCategoriesOptions = [
  { label: 'Aéreo', value: 'air_transport' },
  { label: 'Aluguel de equipamento', value: 'equipment_rental' },
  { label: 'Aluguel de carro', value: 'car_rental' },
  { label: 'Atividade', value: 'activity' },
  { label: 'Gastronomia', value: 'gastronomy' },
  { label: 'Hospedagem', value: 'accommodation' },
  { label: 'Transporte', value: 'transport' },
  { label: 'Outros', value: 'others' }
]

export const OFFLINE_EXPERIENCES_CATEGORIES_MAP = {
  TRANSPORTE: 'Transporte'
}

import { validateCpf, validatePhoneWithDDI } from '@bonitour/common-functions'
import moment from 'moment'
import { string } from 'yup'

const validateBirthDate = birthDate => {
  const eighteenYearsAgo = moment().subtract(18, 'year')
  return moment(birthDate).isSameOrBefore(eighteenYearsAgo, 'day')
}

export const reservationInfoSchema = {
  name: string().required('O nome é obrigatório'),
  email: string().required('O e-mail é obrigatório').email('O email deve ser válido'),
  birthDate: string().test('test-birthDate', 'O responsável pela compra deve ter mais de 18 anos', (birthDate) => {
    if (!birthDate) {
      return true
    }
    return birthDate && validateBirthDate(birthDate)
  }),
  identificationDocument: string().test('test-cpf', 'O CPF deve ser válido', (cpf) => {
    if (!cpf) {
      return true
    }
    return cpf && validateCpf(cpf)
  }),
  number: string().test('validate-phone', 'O número de telefone deve ser válido', (phone) => {
    if (!phone) {
      return true
    }
    return phone && validatePhoneWithDDI(phone)
  }),
  comments: string().nullable(),
  affiliateCode: string().nullable(),
  country: string(),
  state: string(),
  city: string(),
  zipCode: string().nullable(),
  street: string().nullable(),
  supplement: string().nullable(),
  district: string().nullable(),
  emergencyName: string().nullable(),
  emergencyTelephone: string().test('validate-phone', 'O número do telefone deve ser válido', (emergencyTelephone) => {
    if (emergencyTelephone.length <= 4) {
      return true
    }
    return emergencyTelephone && validatePhoneWithDDI(emergencyTelephone)
  }).nullable(),
  weight: string().nullable(),
  height: string().nullable()
}

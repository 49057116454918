import { useCallback, useState } from 'react'
import { useToast } from '@bonitour/components'
import { PaymentBatchService as PaymentBatchServiceV1, PaymentBatchServiceV2 } from 'core/services/PaymentBatch'

export const useEditPaymentBatch = () => {
  const { add: addToast } = useToast()
  const [loading, setLoading] = useState(false)
  const [loadingPayment, setLoadingPayment] = useState(false)

  const onEditPaymentBatch = useCallback((paymentBatchId, batch) => {
    setLoading(true)
    return PaymentBatchServiceV2.editPaymentBatch(paymentBatchId, batch)
      .catch(e => {
        console.error(e)
        addToast('Ocorreu um erro na edição')
        setLoading(false)
        throw new Error('Ocorreu um erro na edição')
      })
      .then(() => {
        if (!batch?.tickets?.length > 0) return

        PaymentBatchServiceV1.addBatchItems(paymentBatchId, batch)
          .catch(e => {
            console.error(e)
            addToast('Ocorreu um erro no vínculo dos ingressos')
            throw new Error('Ocorreu um erro no vínculo dos ingressos')
          })
          .finally(() => setLoading(false))
      }).finally(() => setLoading(false))
  }, [addToast])

  const onAddPaymentToBatch = useCallback((paymentBatchId, payment) => {
    setLoadingPayment(true)
    return PaymentBatchServiceV1.registerPaymentToBatch(paymentBatchId, payment)
      .then(() => null)
      .catch(() => {
        addToast('Ocorreu um erro no registro do pagamento')
        throw new Error('Ocorreu um erro no registro do pagamento')
      })
      .finally(() => setLoadingPayment(false))
  }, [addToast])

  return {
    onEditPaymentBatch,
    onAddPaymentToBatch,

    loading,
    loadingPayment
  }
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { LoadingAnimation, PaginationContainer } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { PartnershipAccountCard } from './PartnershipAccountCard'
import { loadingContainer } from 'assets/styles/global'

export const PartnershipAccountsList = ({
  partnershipAccounts = [],
  total = 10,
  pagesQuantity = 1,
  loading = false,
  isDebtor = false,
  onPagination: emitPaginationEvent = identity
}) => {
  return (
    <PaginationContainer total={total} pagesQuantity={pagesQuantity} onPagination={emitPaginationEvent}>
      {loading
        ? <LoadingAnimation css={loadingContainer} />
        : partnershipAccounts.map((partnershipAccount, index) =>
          <PartnershipAccountCard key={index} partnershipAccount={partnershipAccount} isDebtor={isDebtor} />
        )}
    </PaginationContainer>
  )
}

import { orbCore } from 'services/Orb/Service'
import {
  PermissionsMacrosParser,
  PermissionsMapParser
} from 'core/parsers/permissions'

const { rolesDomain } = orbCore

export const PermissionsService = {
  getPermissionsMap () {
    return rolesDomain.getPermissionsMap().then(PermissionsMapParser.get)
  },
  getMacroPermissions () {
    return rolesDomain.getMacroPermissions().then(PermissionsMacrosParser.get)
  }
}

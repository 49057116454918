import { BankAccountService } from 'core/services/BankAccount'
import { useCallback } from 'react'

export const useCreateBankAccount = () => {
  const createBankAccount = useCallback(async (bankAccount) => BankAccountService
    .create(bankAccount), [])

  return {
    createBankAccount
  }
}

import { orbCore } from 'services/Orb/Service'
import { OfflineExperienceModel, OfflineExperienceAboutModel, offlineExperienceComplementaryInfoModel } from './Model'
import { OfflineExperienceParser } from './Parser'
import { CompanyStore } from 'core/store/Company'
import { convertToFormData } from '@bonitour/common-functions'

const { offlineExperienceDomain } = orbCore

export const OfflineExperienceService = {
  list ({ searchParams, pagination }) {
    return offlineExperienceDomain.list({ searchParams, pagination }).then(OfflineExperienceParser.list)
  },
  create (data) {
    const companyId = CompanyStore.getId()
    const requestPayload = OfflineExperienceModel(data, companyId)
    return offlineExperienceDomain.create(convertToFormData(requestPayload)).then(({ offline_experience: offlineExperience = {} }) => offlineExperience)
  },
  get (serviceId) {
    return offlineExperienceDomain.get(serviceId).then(OfflineExperienceParser.instance)
  },
  updateMainService (serviceId = '', data = {}) {
    const companyId = CompanyStore.getId()
    const requestPayload = OfflineExperienceModel(data, companyId)
    return offlineExperienceDomain.updateAsFormData(serviceId, convertToFormData(requestPayload)).then(({ offline_experience: offlineExperience = {} }) => offlineExperience)
  },
  updateAbout (serviceId = '', offlineExperienceData = {}) {
    const requestPayload = OfflineExperienceAboutModel(offlineExperienceData)
    return offlineExperienceDomain.update(serviceId, requestPayload)
  },
  updateComplementaryInfo (serviceId = '', data = {}) {
    const requestPayload = offlineExperienceComplementaryInfoModel(data)
    return offlineExperienceDomain.update(serviceId, requestPayload)
  }
}

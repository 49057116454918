export const EmployeesParser = {
  list ({ employees = [] }) {
    const parsedEmployees = employees.map(({
      id,
      company_name: companyName,
      user_name: userName,
      user_email: userEmail,
      user_id: userId,
      active
    }) => ({
      id,
      companyName,
      userName,
      userId,
      userEmail,
      active
    }))

    return { employees: parsedEmployees }
  }
}

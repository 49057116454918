import { useEffect, useState } from 'react'
import { PartnerService } from 'services/Partner/Service'

export const usePartnerList = (addToast) => {
  const [partnerList, setPartnerList] = useState([])

  useEffect(() => {
    PartnerService.getList().then(partners => {
      if (partners && partners.length > 1) {
        setPartnerList(partners.filter(({ value }) => value))
      } else {
        addToast('Ainda não há parceiros cadastrados')
      }
    })

    return () => {
      setPartnerList([])
    }
  }, [addToast])

  return partnerList
}

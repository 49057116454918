/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Card, colors, Column, flexColumn, H2, LightInfoAlert, Pill, Pills, ToggleInputGroup } from '@bonitour/components'
import { CombinedExperienceForm } from './form/CombinedExperienceForm'
import { hidden, marginBottom, marginTop } from 'assets/styles/global'
import { useActivity } from 'contexts/Activity'
import { useParamsFilter } from 'hooks/useParamsFilter'
import { useCompany } from 'contexts/Company'

const codeContainerStyle = css`
  font-family: monospace;
  display: inline-block;
  margin-top: 0.5rem;
  padding: 1rem;
  background-color: ${colors.gray7};
  border-radius: 0.2rem;
  &::selection {
    background-color: white;
  }
`

const noPaddingStyle = css`
  padding: 0;
`

const formatBoldTextsStyle = css`
  strong {
    font-weight: bolder;
  }
`

const formViews = {
  empty: {
    label: 'Vazio',
    value: 'empty'
  },
  filled: {
    label: 'Preenchido',
    value: 'filled'
  }
}

const CORREDEIRAS_EXPERIENCE_LIST = [
  { id: 'f9eefa96-1f16-4f95-b0bf-c23dd31984f9' },
  { id: '990cad80-2101-4123-821b-bd7266237649' },
  { id: '6c5caffd-99b8-4c85-ba51-b6b3a591baa8' }
]

const CORREDEIRAS_COMPANY_ID = '0d374a74-ddbd-4253-abc5-fe4f8f739a34'

const INITIAL_LOADING_STATE = {
  experienceList: false,
  form: false,
  isTouchedOnExperienceLoading: false
}

const INITIAL_UPDATED_DATA_STATE = { count: 0, payload: {} }

const PayloadComponent = ({ payload = {}, submitCount = 0 }) => {
  const parsedPayload = useMemo(() => JSON.stringify(payload, null, 5), [payload])

  if (!submitCount) return null

  return <Card css={marginTop(25)}>
    <H2>Payload recebido ({submitCount}x)</H2>

    <code css={codeContainerStyle}>
      {parsedPayload}
    </code>
  </Card>
}

export const CombinedExperiencePageTest = () => {
  const [isLoading, setIsLoading] = useState(INITIAL_LOADING_STATE)
  const [updatedData, setUpdatedData] = useState(INITIAL_UPDATED_DATA_STATE)

  const [params, setParams] = useParamsFilter()

  const { id: companyId } = useCompany()

  const onIsLoadingExperienceListToggle = useCallback(() => setIsLoading(prev => ({
    ...prev, experienceList: !prev.experienceList,
    isTouchedOnExperienceLoading: true
  })), [])

  const onIsLoadingFormSendToggle = useCallback(() => setIsLoading(prev => ({ ...prev, form: !prev.form })), [])

  const onFormViewChange = useCallback((view) => () => setParams({
    selectedFormView: view
  }), [setParams])

  const onSubmitForm = useCallback((data) => {
    const randomDelay = Math.floor(Math.random() * 1200) + 1000
    onIsLoadingFormSendToggle()
    setTimeout(() => {
      setUpdatedData(({ count }) => ({
        count: count + 1,
        payload: data
      }))
      onIsLoadingFormSendToggle()
    }, randomDelay)
  }, [onIsLoadingFormSendToggle])

  const {
    loading: isLoadingExperienceOptionsList,
    companyActivities: experienceOptionsList
  } = useActivity()

  const isCompanyWithServices = useMemo(() => CORREDEIRAS_COMPANY_ID === companyId, [companyId])

  const {
    isEmptyFormView,
    isFilledFormView,
    isLoadingExperienceList
  } = useMemo(() => ({
    isEmptyFormView: params?.selectedFormView === formViews.empty.value,
    isFilledFormView: params?.selectedFormView === formViews.filled.value,
    isLoadingExperienceList: isLoading.isTouchedOnExperienceLoading ? isLoading.experienceList : isLoadingExperienceOptionsList
  }), [isLoading.experienceList, isLoading.isTouchedOnExperienceLoading, isLoadingExperienceOptionsList, params?.selectedFormView])

  const initialData = useMemo(() => {
    const experiences = isCompanyWithServices ? CORREDEIRAS_EXPERIENCE_LIST : []
    return {
      title: 'Title or not',
      subtitle: 'Subtitle or not',
      description: 'Description or not',
      isEnabled: true,
      isSameDayServices: true,
      experiences
    }
  }, [isCompanyWithServices])

  useEffect(() => {
    if (!params?.selectedFormView) {
      onFormViewChange(formViews.empty.value)()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>
    <Pills css={marginBottom(15)}>
      <Pill
        label={formViews.empty.label}
        key={formViews.empty.value}
        active={isEmptyFormView}
        onClick={onFormViewChange(formViews.empty.value)}
      />
      <Pill
        label={formViews.filled.label}
        key={formViews.filled.value}
        active={isFilledFormView}
        onClick={onFormViewChange(formViews.filled.value)}/>
    </Pills>

    <Column
      customCss={[flexColumn, marginBottom(15), noPaddingStyle]}
      phone={12}
      desktop={12}
    >
      <ToggleInputGroup
        onClick={onIsLoadingExperienceListToggle}
        checked={isLoadingExperienceList}
      >
        {isLoading.experienceList ? 'Desativar' : 'Ativar'} simulação de carregamento da lista de experiências
      </ToggleInputGroup>

      <ToggleInputGroup
        onClick={onIsLoadingFormSendToggle}
        checked={isLoading.form}
      >
        {isLoading.form ? 'Desativar' : 'Ativar'} simulação de envio do formulário
      </ToggleInputGroup>
    </Column>

    {isEmptyFormView &&
        (<CombinedExperienceForm
          onSubmit={onSubmitForm}
          isLoading={isLoading.form}
          experienceList={experienceOptionsList}
          isLoadingExperienceList={isLoadingExperienceList}
        />)}

    {isFilledFormView &&
    (<>
      <LightInfoAlert css={[noPaddingStyle, marginBottom(25), formatBoldTextsStyle, isCompanyWithServices && hidden]}>
        <p>Para que também tenha experiências selecionadas, use a conta do <strong>Leonardo</strong> com a empresa {'"'}<strong>Leonardo Corredeiras</strong>{'"'}</p>
      </LightInfoAlert>
      <CombinedExperienceForm
        onSubmit={onSubmitForm}
        isLoading={isLoading.form}
        initialData={initialData}
        experienceList={experienceOptionsList}
        isLoadingExperienceList={isLoadingExperienceList}
        isEdition={true}
      />
    </>)}

    <PayloadComponent payload={updatedData.payload} submitCount={updatedData.count}/>
  </>
}

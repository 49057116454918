import { css } from '@emotion/core'
import { colors, BREAK_POINTS } from '@bonitour/components'

const { smallTablet } = BREAK_POINTS

export const verticalyCentered = css`
  justify-content: center;
`

export const logoContainer = css`
  width: 110px;
`

export const marginRight = css`
  margin-right: 20px;
`

export const fixed = css`
  position: fixed;
`

export const maxWidthContainer = css`
  max-width: 1230px;
  justify-content: space-between;
  width: 100%;
`

export const horizontalyCenteredRow = css`
  display: flex;
  align-items: center;
`

export const dashboardLink = css`
  display: inline-flex;
  height: 40px;
  padding: 0 10px;
  border-bottom: 1px solid transparent;
  color: ${colors.gray3};
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
  position: relative;
  top: 10px;

  &:hover,
  &.active {
    color: ${colors.primary};
    border-color: ${colors.primary};
  }
`
export const envStyle = css`
  margin: 10px;
  padding: 5px;
  border-radius: 5px;
  color: ${colors.white};
  background: ${colors.warning};
  text-transform: capitalize;
`
export const helpIcon = css`
  margin-right: 20px;
  cursor: pointer;
  font-size: 23px;
  text-decoration: none;
  color: ${colors.gray4}
`

export const hideInfo = css`
  @media (max-width: ${smallTablet}) {
    display: none;
  }
`

/** @jsxRuntime classic */
/** @jsx jsx */
import { css } from '@emotion/core'
import { colors } from '@bonitour/components'

export const cardIcon = css`
  font-size: 24px;
  padding: 4px 5px;
  border-radius: 50%;
  color: ${colors.gray4}
`

export const smallCardIcon = css`
  ${cardIcon}
  font-size: 18px;
`

export const mediumCardIcon = css`
  ${cardIcon}
  font-size: 20px;
`

export const clickable = css`
  cursor: pointer;
`

export const labelCardButton = css`
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  margin-top: 5px;
  color: ${colors.gray4}
`

export const cardButton = css`
  margin: 0;
`

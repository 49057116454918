import { boolean, string, number } from 'yup'

export const tradeAgreementSchema = {
  name: string()
    .required('O nome do grupo é obrigatório'),
  commissioning: number()
    .typeError('Deve ser um número')
    .required('O preenchimento do comissionamento é obrigatório')
    .min(0, 'A porcentagem de comissionamento deve estar entre 0 e 100')
    .max(100, 'A porcentagem de comissionamento deve estar entre 1 e 100'),
  sellViaAlly: boolean()
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { colors } from '@bonitour/components'
import { formatISOTime } from 'utils/time'

const item = css`
  line-height: 1.5;
`

const badge = css`
  margin-left: 5px;
  padding: 2px 5px;
  border-radius: 5px;
  color: ${colors.white};
  background: ${colors.warning};
`

const bold = css`
  font-weight: bold;
`

export const PassengerInfo = (passenger, showMainBadge = true) => {
  const { userId, fullName, phone, document, birthDate } = passenger

  return (
    <span css={item}>
      {Boolean(fullName) && <span>{fullName}</span>}
      {phone.length > 4 && <><span css={bold}> - Telefone: </span><span>{phone}</span></>}
      {Boolean(document) && <><span css={bold}> - Documento: </span><span>{document}</span></>}
      {Boolean(birthDate) && <><span css={bold}> - Data de nascimento: </span><span>{formatISOTime(birthDate, 'DD/MM/YYYY', false)}</span></>}
      {Boolean(userId) && showMainBadge && <span css={badge}>Principal</span>}
    </span>
  )
}

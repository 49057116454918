import { string, number, object, array } from 'yup'

export const bookingSchema = {
  reservationHeader: object({
    partner: string().required(),
    agent: string().optional()
  }).required(),
  tickets: array()
    .of(
      object({
        quantity: number()
          .typeError('Deve ser um número')
          .min(0)
      })
    )
    .min(1)
}

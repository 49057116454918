import { useCallback, useEffect, useState } from 'react'
import { useToast } from '@bonitour/components'
import { useParamsFilter } from 'hooks/useParamsFilter'
import { PaymentBatchServiceV2 } from 'core/services/PaymentBatch'

export const usePaymentBatchList = (batchType) => {
  const { add: addToast } = useToast()
  const [filters, setFilters] = useParamsFilter()
  const [dateInitialized, setDateInitialized] = useState(false)
  const [totalizers, setTotalizers] = useState({})
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    currentPage: 1,
    perPage: 10
  })
  const [pendingFetch, setPendingFetch] = useState(null)

  useEffect(() => {
    if (!dateInitialized) {
      if ((!filters.startDate || !filters.endDate)) {
        const year = new Date().getFullYear()
        const month = new Date().getMonth()
        setFilters({
          ...filters,
          startDate: new Date(year, month, 1),
          endDate: new Date(year, month + 1, 0)
        })
      }
      setDateInitialized(true)
    }
  }, [dateInitialized, filters, pagination, setFilters])

  const changePagination = useCallback((currentPage, perPage) => {
    if (currentPage === pagination.currentPage && perPage === pagination.perPage) {
      return
    }

    setPagination({
      currentPage,
      perPage
    })
  }, [pagination.currentPage, pagination.perPage])

  const [batchData, setBatchData] = useState({ batchData: undefined, meta: undefined })

  const fetchTotalizers = useCallback(async ({ filters }) => {
    if (!filters.startDate || !filters.endDate) {
      return
    }

    const totalizersRes = await PaymentBatchServiceV2
      .getPaymentBatchesTotalizers({
        filters: {
          ...filters,
          batchType
        }
      })
      .then((data) => {
        setTotalizers(data)
        return data
      })
      .catch(() => addToast('Ocorreu um erro ao buscar os totalizadores'))

    return totalizersRes
  }, [addToast, batchType])

  const fetchBatchListList = useCallback(({
    filters,
    pagination
  }) => {
    if ((!filters.startDate || !filters.endDate)) {
      return
    }
    setLoading(true)
    fetchTotalizers({ filters })

    return PaymentBatchServiceV2
      .getPaymentBatches({
        filters: {
          ...filters,
          batchType
        },
        pagination
      })
      .then((data) => setBatchData(data))
      .catch(() => addToast('Ocorreu um erro ao realizar a busca'))
      .finally(() => setLoading(false))
  }, [addToast, batchType, fetchTotalizers])

  useEffect(() => {
    fetchBatchListList({
      pagination,
      filters
    })
  }, [pagination, filters, fetchBatchListList])

  useEffect(() => {
    if (pendingFetch) {
      fetchBatchListList({
        pagination,
        filters
      })
      setPendingFetch(null)
    }
  }, [pendingFetch, fetchBatchListList, pagination, filters])

  return {
    filters,
    setFilters,
    pagination,
    changePagination,
    batchList: batchData?.paymentBatchList,
    meta: batchData?.meta,
    fetchBatchListList,
    fetchTotalizers,
    totalizers,
    loading
  }
}

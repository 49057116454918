function vacanciesParser (vacancies, dailyLimit = false) {
  if (dailyLimit && vacancies?.total > 0) {
    return { total: 0, isAvailable: true }
  }
  const { total = 0 } = vacancies || {}
  return { total, isAvailable: true }
}

function reservationDetailParser (reservation) {
  const { code, total, payer, agency: commercialPartner, id } = reservation
  return {
    id,
    code,
    total,
    payer,
    commercialPartner
  }
}

function reservationsParser (reservations = [], parseTotalReservations) {
  const totalReservations =
    parseTotalReservations && reservations.reduce((accumulator, details) => accumulator + details.total, 0)

  return {
    total: totalReservations,
    details: reservations.map(reservationDetailParser)
  }
}

function reservationsAndVacancyParser (
  reservationAndVacancy,
  dailyLimit = false,
  limitOfDay,
  parseTotalReservations = true
) {
  const { vacancies, reservations } = reservationAndVacancy
  return {
    vacancies: vacanciesParser(vacancies, dailyLimit),
    reservations: reservationsParser(reservations, parseTotalReservations),
    limitOfDay
  }
}

function sortByPriorityNames (prices) {
  const priorityNames = ['Criança', 'Adulto', 'Sênior', 'Tarifa Única']
  const filteredPrices = {}

  const sortByName = (head, next) => {
    if (head.name < next.name) return -1
    if (head.name > next.name) return 1
    return 0
  }

  function filterExcludingPriorityNames (element) {
    return this.indexOf(element.name) < 0
  }

  function filterByPriorityNames (element) {
    return this.indexOf(element.name) >= 0
  }

  Object.keys(prices).forEach((price) => {
    const filteredWithoutPriorityNames = prices[price]
      .filter(filterExcludingPriorityNames, priorityNames)
      .sort(sortByName)
    const filteredByPriorityNames = prices[price].filter(filterByPriorityNames, priorityNames).sort(sortByName)
    filteredPrices[price] = [...filteredByPriorityNames, ...filteredWithoutPriorityNames]
  })

  return filteredPrices
}

const sortReservationMap = (object) =>
  Object.keys(object)
    .sort()
    .reduce((accumulator, key) => ({ ...accumulator, [key]: object[key] }), {})

export const ReservationMapParser = {
  normal (apiData = {}) {
    const data = Object.keys(apiData).reduce((accumulator, day) => {
      const { registries, daily_limit: dailyLimit = false, limit_of_day: limitOfDay } = apiData[day]
      if (registries) {
        Object.keys(registries).forEach((hour) => {
          if (!accumulator[hour]) {
            accumulator[hour] = {}
          }
          accumulator[hour][day] = reservationsAndVacancyParser(registries[hour], dailyLimit, limitOfDay)
        })
      }
      return accumulator
    }, {})

    const dataPrices = Object.keys(apiData).reduce((accumulator, day) => {
      const { prices = [] } = apiData[day]
      accumulator[day] = prices.map(({ name, pricing, description: { min_age: minAge, max_age: maxAge } }) => ({
        name,
        pricing,
        minAge,
        maxAge
      }))
      return accumulator
    }, {})

    const registries = sortReservationMap(data)
    const prices = sortReservationMap(dataPrices)
    const pricesPrioritySort = sortByPriorityNames(prices)

    return { registries, prices: pricesPrioritySort }
  },
  detailed (apiData = {}) {
    return Object.keys(apiData).reduce((accumulator, day) => {
      accumulator[day] = reservationsAndVacancyParser(apiData[day])
      return accumulator
    }, {})
  },
  hourlyOptions (apiData = {}) {
    try {
      const hourlies = Object.keys(apiData[Object.keys(apiData)[0]].registries)

      return hourlies.map((hour) => {
        return {
          value: hour,
          label: hour
        }
      })
    } catch (e) {
      return []
    }
  }
}

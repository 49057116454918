import { orbCore } from 'services/Orb/Service'
import { InvoiceModel, InvoicePayModel, invoiceMetaModel } from 'core/models/Invoice'
import { InvoiceParser } from 'core/parsers/Invoice'
import { head } from '@bonitour/common-functions'
import { ExportColumnsParser } from 'core/parsers/exportColumns'

export const InvoiceService = {
  listRevenues ({ filters, pagination }) {
    return orbCore.invoiceDomain.getInvoiceRevenues(InvoicePayModel.list({ filters, pagination })).then(InvoiceParser.list)
  },
  listRevenuesTotals ({ filters, pagination }) {
    return orbCore.invoiceDomain.getInvoiceRevenuesTotals(InvoicePayModel.list({ filters, pagination })).then(InvoiceParser.totals)
  },
  listDebts ({ filters, pagination }) {
    return orbCore.invoiceDomain.getInvoiceDebts(InvoicePayModel.list({ filters, pagination })).then(InvoiceParser.list)
  },
  listDebtsTotals ({ filters, pagination }) {
    return orbCore.invoiceDomain.getInvoiceDebtsTotals(InvoicePayModel.list({ filters, pagination })).then(InvoiceParser.totals)
  },
  getInvoice (id) {
    return orbCore.invoiceDomain.getById(id)
      .then(data => InvoiceParser.parseInvoice(data))
      .catch(({ data = {} }) => {
        return Promise.reject(head(data.errors_msg))
      })
  },
  getInvoiceDetails (id, meta) {
    return orbCore.invoiceDomain.getInvoiceItems(id, invoiceMetaModel(meta)).then(InvoiceParser.parseInvoiceDetails)
  },
  getInvoiceDetailsExportColumn () {
    return orbCore.invoiceDomain.getInvoiceItemsExportColumn().then(ExportColumnsParser.columns)
  },
  getInvoiceDetailsExportCSV (id, exportColumns) {
    return orbCore.invoiceDomain.getInvoiceItemsExportCSV(id, InvoiceModel.exportCsv(exportColumns))
  },
  updateInvoice (id = '', payload = {}) {
    return orbCore.invoiceDomain.updateInvoice(id, InvoiceModel.extraInfo(payload))
  },
  sendInvoice (id = '', payload = {}) {
    return orbCore.invoiceDomain.sendInvoice(id, InvoiceModel.extraInfo(payload))
  },
  payInvoice (id = '', payload = {}) {
    return orbCore.invoiceDomain.payInvoice(id, InvoiceModel.payment(payload))
  },
  payOffInvoice (id = '') {
    return orbCore.invoiceDomain.payOffInvoice(id)
  },
  approvePayment (invoiceId, invoicePaymentId) {
    return orbCore.invoiceDomain.approvePayment(invoiceId, invoicePaymentId)
  },
  rejectPayment (invoiceId, invoicePaymentId) {
    return orbCore.invoiceDomain.rejectPayment(invoiceId, invoicePaymentId)
  },
  editPayment (invoiceId, invoicePaymentId, invoiceEditinfo) {
    return orbCore.invoiceDomain.editInvoice(invoiceId, invoicePaymentId, InvoicePayModel.edit(invoiceEditinfo))
  }
}

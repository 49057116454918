/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { InvitePage } from 'containers/Sellers/Invite/Invite'
import { useEffect, useState } from 'react'
import { SellersGroupsService } from 'services/SellersGroups/Service'
import { useHistory, useParams } from 'react-router-dom'
import { useToast } from '@bonitour/components'

export const SellersInvite = () => {
  const history = useHistory()
  const { companyId } = useParams()
  const { add: addToast } = useToast()
  const [groupList, setGroupList] = useState([])
  const [selectedId, setSelectedId] = useState()

  const backToSellers = () => history.push('/sellers')

  const inviteToGroup = async () => {
    if (!selectedId) {
      addToast('Selecione um grupo')
    } else {
      await SellersGroupsService.invite({ id: companyId }, selectedId)
      addToast('Convite enviado', 'success')
      backToSellers()
    }
  }

  useEffect(() => {
    SellersGroupsService.listGroups().then(setGroupList)
  }, [])

  return (
    <InvitePage
      onBackClick={backToSellers}
      onNextClick={inviteToGroup}
      selectedGroup={selectedId}
      setSelectedGroup={setSelectedId}
      groups={groupList}
    />)
}

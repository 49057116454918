/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/core'
import { CircleButton, AngleLeftIcon, AngleRightIcon } from '@bonitour/components'
import { dateButtonContainer, dateButtonRightPosition, dateButton } from './BookingMapButton.style'

export const DateButtonLeft = React.forwardRef((props, ref) => (
  <div css={dateButtonContainer} ref={ref}>
    <CircleButton css={dateButton} {...props}>
      <AngleLeftIcon />
    </CircleButton>
  </div>
))

export const DateButtonRight = React.forwardRef((props, ref) => (
  <div css={[dateButtonContainer, dateButtonRightPosition]} ref={ref}>
    <CircleButton css={dateButton} {...props}>
      <AngleRightIcon />
    </CircleButton>
  </div>
))

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { H3 } from '@bonitour/components'
import { hidden } from 'assets/styles/global'

export const DashboardChart = () => {
  return (
    <H3 css={hidden}>
      Desempenho de reservas
    </H3>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useDebounce } from '@bonitour/app-functions'
import { jsx } from '@emotion/core'
import { useMemo, useState } from 'react'
import { PartnershipAccountsFilter } from './PartnershipAccountsFilter'
import { PartnershipAccountsList } from './PartnershipAccountsList'

export const PartnershipAccountCredit = ({
  partnershipAccounts,
  meta,
  loading,
  changePagination,
  isDebtor = false
}) => {
  const [query, setQuery] = useState('')
  const queryWithDebounce = useDebounce(query, 200)

  const filteredActivities = useMemo(
    () => {
      if (queryWithDebounce) {
        return partnershipAccounts.filter(({ creditorName, partnerName }) => {
          const activityQueryLowercase = queryWithDebounce.toLowerCase()
          const activityLowecase = isDebtor ? creditorName.toLowerCase() : partnerName.toLowerCase()
          const hasQueryInName = activityLowecase.includes(activityQueryLowercase)
          return hasQueryInName
        })
      }
      return partnershipAccounts
    }
    , [partnershipAccounts, queryWithDebounce, isDebtor])

  if (!partnershipAccounts?.length) {
    return null
  }

  return (
    <>
      <PartnershipAccountsFilter query={query} setQuery={setQuery} />

      <PartnershipAccountsList
        partnershipAccounts={filteredActivities}
        pagesQuantity={meta?.totalPages}
        total={meta?.total}
        loading={loading}
        onPagination={changePagination}
        isDebtor={isDebtor}
      />
    </>
  )
}

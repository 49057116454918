import { css } from '@emotion/core'
import { reservationTextColor } from './ReservationTimeline.constant'
import { colors } from '@bonitour/components'

export const chainRowStyle = css`
  display: flex;
  flex-direction: row;
  place-items: center;
  color: ${reservationTextColor};
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  > p {
    width: 250px;
    text-align: center;
    .register-by {
      font-weight: bold;
    }

    .date-time {
      display: block;
      margin-top: 6px;
    }
  }

  > section {
    margin-left: 1rem;
    width: 100%;
  }
`

export const chainIconStyle = css`
  color: ${reservationTextColor};
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  > svg {
    transform: scale(1.1);
  }
`

export const chainStyle = css`
  position: absolute;
  left: 50%;
  top: 3.375rem;
  transform: translateX(-50%);
  display: block;
  width: 0.375rem;
  --spacing: 1rem;
  background:
   radial-gradient(circle closest-side,${colors.gray6} 90%,transparent) 0 0/100% var(--spacing),
   linear-gradient(${colors.gray6} 50%, transparent 0) 0 calc(var(--spacing)/2)/100% calc(2*var(--spacing));
`
export const chainContainerStyle = css`
  position: relative;
`

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useEffect, useState, useCallback, useMemo } from 'react'
import { ActivityVacancyService } from 'services/Vacancy/Service'
import { flexRow, GhostButton, Button, Timeline, useToast } from '@bonitour/components'
import { marginTop, marginRight, resetLink, marginBottom, mobileButtonContainer, mobileBackButton, mobileContinueButton, width, hidden } from 'assets/styles/global'
import { NavLink, useHistory } from 'react-router-dom'
import { AddLabel } from 'components/AddLabel'
import { PeriodListParser } from 'services/PeriodList/Parser'
import { ActivityVacancyList } from 'containers/Activity/ActivityVacancyList/ActivityVacancyList'
import { identity } from '@bonitour/common-functions'
import { useActivity } from 'contexts/Activity'
import { useListExceptionalDay } from 'domains/Booking/Map/Config/hooks/useListExceptionalDay'
import moment from 'moment'
import { ExceptionalDayService } from 'core/services/ExceptionalDay'
import { getCorrectPath } from 'hooks/useRouteByType'
import { BookingMapOperationProgress } from 'components/BookingMap/BookingMapOperationProgress'

const marginTop20 = marginTop(20)
const marginBottom40 = marginBottom(40)

export const ListActivityVacancy = ({
  onBackStep = identity,
  onNextStep = identity,
  onActionCallback = identity,
  isTransport = false,
  isActivityLocked = false,
  isLoadingActivityLocked = false
}) => {
  const { push } = useHistory()
  const { add: addToast } = useToast()
  const { id: activityId, activity: { type } } = useActivity()
  const [list, setList] = useState([])
  const [periodList, setPeriodList] = useState([])
  const { exceptionalDays = [], fetchExceptionalDay } = useListExceptionalDay()

  const navigateToVacancy = (id) => () => push(`${baseNavigationPath}/${id}/edit`)

  const navigateToExceptionalDay = (exceptionalDate) => () => {
    return window.open(`/${isTransport ? 'transport' : 'activity'}/${activityId}/bookings-day-config?date=${exceptionalDate}`, '_blank', 'noopener noreferrer')
  }

  const updateVacancies = useCallback(() =>
    ActivityVacancyService.get(activityId).then(vacancyList => {
      setList(vacancyList)
      setPeriodList(PeriodListParser(vacancyList))
        .finally(() => onActionCallback())
    }), [activityId, onActionCallback])

  const removeFee = feeId => ActivityVacancyService.remove(activityId, feeId).then(() => {
    updateVacancies()
    fetchExceptionalDay({ params: { serviceId: activityId } })
    addToast('A vaga foi excluída com sucesso', 'success')
  })
    .catch(addToast)

  const removeExceptionalDay = exceptionalDayId => ExceptionalDayService.remove(exceptionalDayId).then(() => {
    updateVacancies()
    fetchExceptionalDay({ params: { serviceId: activityId } })
    addToast('O dia excepcional foi excluído com sucesso', 'success')
  }).catch(addToast)

  const baseNavigationPath = useMemo(() => {
    return getCorrectPath(type, `${activityId}/edit/vacancy`)
  }, [activityId, type])

  useEffect(() => {
    updateVacancies()
  }, [updateVacancies])

  useEffect(() => {
    fetchExceptionalDay({ params: { serviceId: activityId } })
  }, [activityId, fetchExceptionalDay])

  useEffect(() => {
    const mergeExceptionalDayList = exceptionalDays.length > 0
      ? exceptionalDays.map(exceptionalDay => {
        const { id, name, dailyLimit, exceptionalDate, vacanciesBySchedule = [] } = exceptionalDay || {}
        const vacancyQuantity = vacanciesBySchedule.reduce((acc, item) => acc + Number(Object.values(item)), 0)
        const schedulesQuantity = vacanciesBySchedule.length || 0

        return {
          id,
          color: '#9B77DA',
          name: `${name} (${moment(exceptionalDate).format('DD/MM/YYYY')})`,
          dailyLimit,
          intervalList: [{ name, initialDate: exceptionalDate, finalDate: exceptionalDate }],
          vacancyQuantity,
          periodQuantity: 1,
          schedulesQuantity,
          isExceptionalDay: true,
          exceptionalDate
        }
      })
      : []

    setList([...list, ...mergeExceptionalDayList])
    // eslint-disable-next-line
  }, [exceptionalDays])

  return (
    <>
      <BookingMapOperationProgress
        isLoading={isLoadingActivityLocked}
        isOperationInProgress={isActivityLocked}
        customCss={[marginBottom(15), width(100)]}
        isTransport={isTransport}
      />

      <ActivityVacancyList
        activityVacancyList={list}
        onDelete={removeFee}
        onUpdate={navigateToVacancy}
        onExceptionalDayUpdate={navigateToExceptionalDay}
        onExceptionalDayDelete={removeExceptionalDay}
        isDisabledControls={isActivityLocked}
      />

      <NavLink css={isActivityLocked ? hidden : resetLink} to={`${baseNavigationPath}/create`}>
        <AddLabel>Nova vaga</AddLabel>
      </NavLink>

      {Boolean(periodList.length) && <Timeline css={marginBottom40} periodList={periodList} />}

      <div css={[flexRow, marginTop20, mobileButtonContainer]}>
        <GhostButton onClick={onBackStep} css={[marginRight(20), mobileBackButton]}>Voltar</GhostButton>
        <Button css={mobileContinueButton} onClick={onNextStep}>Continuar</Button>
      </div>
    </>
  )
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { SellerCard } from './Card'
import { useHistory } from 'react-router-dom'
import { PaginationContainer } from '@bonitour/components'
import { PaginatedSellersContext } from 'app/Sellers/SellersContext'
import { memo, useCallback, useContext, useMemo } from 'react'
import { loadingListContainer, paginationContainer } from './List.styles'

const LoadingList = memo(({ elementsCount }) => {
  return (
    <ul css={loadingListContainer}>
      {Array.from({ length: elementsCount }, (_, i) => i).map((key) => (
        <li key={key}/>
      ))}
    </ul>
  )
}, (prevProps, nextProps) => prevProps.elementsCount === nextProps.elementsCount)

export const SellersList = () => {
  const history = useHistory()
  const redirectToInviteSeller = (id) => () => history.push(`/sellers/invite/${id}`)
  const {
    sellersInfo,
    handlePagination,
    pagination,
    loadingSellers
  } = useContext(PaginatedSellersContext)

  const paginationMiddleware = useCallback((...props) => {
    if (
      (pagination.currentPage === props[0] && pagination.pageLimit === props[1]) ||
      loadingSellers
    ) {
      return
    }
    handlePagination(...props)
  }, [handlePagination, pagination.currentPage, pagination.pageLimit, loadingSellers])

  const totalEntries = useMemo(() => sellersInfo.total, [sellersInfo.total])
  const totalPages = useMemo(() => sellersInfo.totalPages, [sellersInfo.totalPages])
  const perPage = useMemo(() => pagination.pageLimit, [pagination.pageLimit])

  return (
    <PaginationContainer
      total={totalEntries}
      pagesQuantity={totalPages}
      onPagination={paginationMiddleware}
      childrenCustomCss={[paginationContainer]}
    >
      {loadingSellers
        ? <LoadingList elementsCount={perPage} />
        : (
          sellersInfo.sellers.map(({ id, name, image, active, group }) => (
            <SellerCard
              group={group}
              name={name}
              image={image}
              active={active}
              key={id}
              onAddSeller={redirectToInviteSeller(id)}
            />
          ))
        )}

    </PaginationContainer>
  )
}

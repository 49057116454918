/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { ActivityFeeForm } from 'containers/ActivityFeeForm/ActivityFeeForm'
import { useToast } from '@bonitour/components'
import { useHistory, useLocation } from 'react-router-dom'
import { FeeService } from 'services/Fee/Service'
import { ActivityService } from 'services/Activity/Service'
import { TransportService } from 'services/Transport/Service'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { useActivity } from 'contexts/Activity'

export const CreateFee = () => {
  const [fetchedRestriction, setFetchedRestriction] = useState('')
  const [activityFee] = useState({
    feeBasic: {},
    periodList: [{ name: '', startDate: new Date(), endDate: new Date() }],
    feesByDemand: [],
    feePaxTypes: []
  })
  const { add } = useToast()
  const history = useHistory()
  const { pathname } = useLocation()
  const { id: activityId } = useActivity()
  const [activityRestrictions, setActivityRestrictions] = useState({})

  const isTransportFee = useMemo(() => {
    return pathname.includes('/transport')
  }, [pathname])

  useEffect(() => {
    if (!pathname) return
    if (fetchedRestriction === activityId) return

    const restrictionPromise = isTransportFee
      ? TransportService.getRestrictions(activityId)
      : ActivityService.getRestrictions(activityId)

    restrictionPromise
      .then(setActivityRestrictions)
      .finally(() => setFetchedRestriction(activityId))
  }, [activityId, fetchedRestriction, isTransportFee, pathname])

  const onValidationError = () => {
    add('Preencha corretamente o formulário')
  }

  const returnToEditHref = useMemo(() => {
    return `/${isTransportFee ? 'transport' : 'activity'}/${activityId}/edit?accordion=5`
  }, [activityId, isTransportFee])

  const returnToEdit = useCallback(() =>
    history.push(returnToEditHref), [history, returnToEditHref]
  )

  const onSuccess = async data => {
    if (data.feePaxTypes.length) {
      try {
        await FeeService.create(activityId, data)
        returnToEdit()
        add('Tarifa criada', 'success')
      } catch (error) {
        add(error, 'danger', 5000)
      }
    } else {
      add('Adicione uma tipificação de Pax')
    }
  }

  return (
    <ActivityFeeForm
      activityFee={activityFee}
      activityMinAge={activityRestrictions.minAge}
      onSuccess={onSuccess}
      onBackClick={returnToEdit}
      onValidationError={onValidationError}
    />
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { CSSTransition } from 'react-transition-group'
import { container, content, transition } from './TicketCountModal.style'
import { useMemo } from 'react'
import { Portal, scrollBar } from '@bonitour/components'

export function TicketCountModal ({
  quantity = '',
  children,
  maxWidth = '1368px',
  maxHeight = '100vh',
  customCss = [],
  ...other
}) {
  const className = 'modal'
  const duration = 200

  const containerMaxWidth = useMemo(() => (css`
    max-width: ${maxWidth};
    max-height: ${maxHeight};
    ${scrollBar(5)}
    overflow-y: auto;
    margin: auto;
  `), [maxWidth, maxHeight])

  return (
    <Portal>

      <CSSTransition
        in={quantity > 0}
        timeout={duration}
        classNames={className}
        css={transition(className, duration)}
        unmountOnExit
      >
        <div {...other} css={[container, ...customCss]}>
          <div css={containerMaxWidth}>
            <div css={content}>{children}</div>
          </div>
        </div>
      </CSSTransition>
    </Portal>
  )
}

import { colors } from '@bonitour/components'

export const saleStatusMap = {
  canceled: ['Cancelado', colors.danger],
  'no-show': ['Não compareceu', colors.gray5],
  reserved: ['Reservado', colors.orange1],
  confirmed: ['Confirmado', colors.green1],
  used: ['Usado', colors.green1]
}

export const saleStatusOptions = [
  { value: 'canceled', label: 'Cancelado' },
  { value: 'no-show', label: 'Não compareceu' },
  { value: 'reserved', label: 'Reservado' },
  { value: 'confirmed', label: 'Confirmado' },
  { value: 'used', label: 'Usado' }
]

import { colors, BREAK_POINTS } from '@bonitour/components'

import { css } from '@emotion/core'
import { marginBottom, marginTop } from 'assets/styles/global'

export const marginTop10 = marginTop(10)
export const marginBottom10 = marginBottom(10)

export const fullInput = css`
  width: 100%;
`

export const link = css`
  margin-left: 20px;
  color: ${colors.primary};
`

export const alert = css`
  border: 1px solid ${colors.gray5};
  background-color: ${colors.white};
`

export const selectDrawerOpenToTop = css`
  width: 100%;

  .component-drawer-container {
    top: unset;
    bottom: 45px;

    ::after {
      top: unset;
      bottom: calc(0% - 9px);
      transform: rotate(180deg);
    }
  }
`

export const maxInstallmentsDrawer = css`
  .component-drawer-list {
    max-height: 125px;
  }
`

export const buttonsContainer = css`
  margin-top: 30px;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    display: flex;
    flex-direction: column;
  }
`

export const cancelButton = css`
  margin-right: 20px;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    margin-bottom: 10px;
    margin-right: 0;
  }
`

export const multipontoOption = css`
  justify-content: space-between;
`

import { orbCore } from 'services/Orb/Service'
import { ReservationParser } from 'core/parsers/Reservation'
import { ReservationModel } from 'core/models/Reservation'

export const ReservationHistoryService = {
  get (reservationId, params = {}) {
    return orbCore.reservationHistoryDomain.get(reservationId, ReservationModel.historyParams(params)).then(ReservationParser.history)
  },
  getV2 (reservationId, params = {}) {
    return orbCore.reservationHistoryDomainV2.get(reservationId, ReservationModel.historyParamsV2(params)).then(ReservationParser.historyV2)
  }
}

import { object, string, array } from 'yup'
import { contactPhoneSchema } from 'containers/ContactForm/ContactPhone/ContactPhone.rules'
import { addressSchema } from 'containers/Address/Address.rules'
import { contactEmailSchema } from 'containers/ContactForm/ContactEmail/ContactEmail.rules'

export const transportInfoSchema = (
  origin = { states: [], cities: [] },
  destination = { states: [], cities: [] }
) => ({
  transportId: object().shape({
    name: string().required('O nome da rota de transporte é obrigatório')
  }),
  phones: array().of(object().shape(contactPhoneSchema)),
  emails: array().of(object().shape(contactEmailSchema)),
  originInfo: object().shape(addressSchema(origin.states, origin.cities)),
  destinationInfo: object().shape(addressSchema(destination.states, destination.cities))
})

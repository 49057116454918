/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Address } from 'containers/Address/Address'
import { TransportId } from './TransportId'
import { Button } from '@bonitour/components'
import { marginTop } from 'assets/styles/global'
import { identity } from '@bonitour/common-functions'
import { ContactForm } from 'containers/ContactForm/ContactForm'
import { useCallback } from 'react'

const marginTop50 = marginTop(50)

export const TransportBaseForm = ({
  form = {},
  errors = {},
  origin: {
    countries: originCountries = [],
    states: originStates = [],
    cities: originCities = []
  },
  destination: {
    countries: destinationCountries = [],
    states: destinationStates = [],
    cities: destinationCities = []
  },
  onError: emitErrorEvent = identity,
  onZipCodeBlur = identity,
  addItemOnArray = identity,
  removeItemArray = identity,
  onLatLongBlur = identity,
  onStateChange = identity,
  onCountryChange = identity,
  onInputBlur = identity,
  onInputChange = identity,
  onSubmit = identity
}) => {
  const onOriginInputBlur = useCallback(addressInputName => onInputBlur(`originInfo.${addressInputName}`), [onInputBlur])
  const onOriginInputChange = useCallback(addressInputName => onInputChange(`originInfo.${addressInputName}`), [onInputChange])

  const onDestinationInputBlur = useCallback(addressInputName => onInputBlur(`destinationInfo.${addressInputName}`), [onInputBlur])
  const onDestinationInputChange = useCallback(addressInputName => onInputChange(`destinationInfo.${addressInputName}`), [onInputChange])

  return (
    <>
      <TransportId
        transportId={form.transportId}
        transportIdErrors={errors.transportId}
        onError={emitErrorEvent}
        onInputBlur={onInputBlur}
        onChange={onInputChange}
      />
      <ContactForm
        phones={form.phones}
        emails={form.emails}
        phonesErrors={errors.phones}
        emailsErrors={errors.emails}
        onBlur={onInputBlur}
        onChange={onInputChange}
        onPhoneAdd={addItemOnArray('phones')}
        onPhoneRemove={removeItemArray('phones')}
        onEmailAdd={addItemOnArray('emails')}
        onEmailRemove={removeItemArray('emails')}
      />
      <Address
        title='Endereço de origem'
        addressInfo={form.originInfo}
        addressInfoErrors={errors.originInfo}
        onInputBlur={onOriginInputBlur}
        onInputChange={onOriginInputChange}
        onCountryChange={onCountryChange('origin')}
        onStateChange={onStateChange('origin')}
        onZipCodeBlur={onZipCodeBlur('origin')}
        onLatLongBlur={onLatLongBlur('origin')}
        countries={originCountries}
        states={originStates}
        cities={originCities}
        hideCompanyAddressButton
      />
      <Address
        title='Endereço de destino'
        addressInfo={form.destinationInfo}
        addressInfoErrors={errors.destinationInfo}
        onInputBlur={onDestinationInputBlur}
        onInputChange={onDestinationInputChange}
        onCountryChange={onCountryChange('destination')}
        onStateChange={onStateChange('destination')}
        onZipCodeBlur={onZipCodeBlur('destination')}
        onLatLongBlur={onLatLongBlur('destination')}
        countries={destinationCountries}
        states={destinationStates}
        cities={destinationCities}
        hideCompanyAddressButton
      />
      <Button css={marginTop50} onClick={onSubmit}>
        Salvar e continuar
      </Button>
    </>
  )
}

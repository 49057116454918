import { css } from '@emotion/core'
import { clickable, marginTop } from 'assets/styles/global'
import { BREAK_POINTS, colors, scrollBar } from '@bonitour/components'

export const marginTop20 = marginTop(20)

export const alignCenter = css`
  text-align: center;
`

export const tableContainer = css`
  max-height: 450px;
  overflow-x: auto;
  ${scrollBar(5)};

  th {
    padding: 30px 10px;

    &:last-of-type {
      min-width: 60px;
    }
  }

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    max-height: initial;
  }
`

export const dialogContainer = css`
  max-width: 600px;
  width: auto;
`

export const dialogFullSize = css`
  width: 100%;
`

export const optionsContainer = css`
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  label {
    line-height: 1.25;
    cursor: pointer;

    &:hover {
      color: ${colors.primary};
    }
  }
`

export const optionsContainerButton = css`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`

export const optionsIcon = css`
  font-size: 22px;
`

export const dialogButtons = css`
  font-size: 13px;
`

export const select = css`
  width: 60px;
  max-width: 60px;
  padding: 30px 10px;
`

export const ticketsContainer = css`
  display: inline-flex;
  margin-top: 20px;
  ${clickable};
`

export const sortIcon = css`
  color: ${colors.gray3};
  font-size: 10px;
  margin-right: 10px;
  transition: all 0.2s ease;
`

export const customThTransport = css`
  white-space: initial;
`

import React, { useMemo } from 'react'
import InvoiceBatchPaymentTotalizer from './InvoiceBatchPaymentTotalizer'
import InvoiceBatchPaymentTable from './InvoiceBatchPaymentTable'
import { Row, Column, flexCenteredSpaceBetweenRow, H2, Card } from '@bonitour/components'
import { container } from './InvoiceBatchPayment.style'

const InvoiceBatchPayment = ({ paymentBatchTransactions = [], paymentsInfo }) => {
  const paymentList = useMemo(
    () =>
      paymentBatchTransactions.map(({ transactionDate, paymentMethod, value, id, status, receipts }) => {
        return {
          id,
          transactionDate,
          paymentMethod,
          value,
          status,
          receipts
        }
      }),
    [paymentBatchTransactions]
  )

  return (
    <Card css={container}>
      <H2>Pagamentos</H2>
      <Row>
        <Column phone={12} desktop={9}>
          <InvoiceBatchPaymentTable paymentList={paymentList} />
        </Column>
        <Column phone={12} desktop={3} css={flexCenteredSpaceBetweenRow}>
          <InvoiceBatchPaymentTotalizer paymentsInfo={paymentsInfo} />
        </Column>
      </Row>
    </Card>
  )
}

export default InvoiceBatchPayment

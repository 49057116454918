/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { useCallback, useMemo, useRef } from 'react'
import { identity } from '@bonitour/common-functions'
import { useToast } from '@bonitour/components'
import { CombinedExperienceFormServiceList } from './CombinedExperienceFormServiceList'

const SELECT_SERVICE_NAME = 'select-service-'

export const CombinedExperienceFormServiceSelector = ({
  onAddService = identity,
  onExperienceEdit = identity,
  onExperienceRemove = identity,
  toggleSameDayServices = identity,
  servicesAdded = [],
  allServices = [],
  isEmptyUnselectedExperienceOptions = false,
  isValidForm = true,
  isCheckedSameDayServices = true,
  isLoadingExperiencesList = false,
  isLoading = false
}) => {
  const { add: addToast } = useToast()
  const timeout = useRef(null)

  const onServiceAdd = useCallback((data) => {
    if (!isValidForm) {
      addToast('Confira os serviços selecionados e tente novamente')
      return
    }

    onAddService(data)

    if (timeout.current) {
      return
    }

    timeout.current = setTimeout(() => {
      const selectInput = document.querySelector(`[name="${SELECT_SERVICE_NAME}"]`)

      if (selectInput) {
        selectInput.focus()
        selectInput.click()
      }

      timeout.current = null
    }, 500)
  }, [addToast, isValidForm, onAddService])

  const isEmptyServicesAdded = useMemo(() => servicesAdded.length === 0, [servicesAdded])

  const getServiceData = useCallback((serviceId = '') => {
    const serviceData = allServices.find(({ id }) => id === serviceId)

    if (!serviceData) return {}

    return serviceData
  }, [allServices])

  return <CombinedExperienceFormServiceList
    onExperienceEdit={onExperienceEdit}
    servicesAdded={servicesAdded}
    isLoadingServiceOptions={isLoadingExperiencesList}
    onExperienceRemove={onExperienceRemove}
    onAddService={onServiceAdd}
    allServices={allServices}
    isEmptyServicesAdded={isEmptyServicesAdded}
    getServiceData={getServiceData}
    toggleSameDayServices={toggleSameDayServices}
    isCheckedSameDayServices={isCheckedSameDayServices}
    isEmptyExperiencesOptions={isEmptyUnselectedExperienceOptions}
    isLoading={isLoading}
  />
}

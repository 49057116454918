/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useReservationTimeline } from './hooks/useReservationTimeline'
import { ReservationTimelineFilters, ReservationTimeline, reservationTimelineFiltersLabels } from 'components/ReservationTimeline'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useForm } from '@bonitour/app-functions'
import { ReservationTimelineSchema } from './form/ReservationTimeline.schema'
import { SafeDate } from '@binamik/js-providers'
import { useToast, colors, LoadingAnimation } from '@bonitour/components'

const containerStyle = css`
  padding: 1rem;
  > h1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .none-result {
    margin-top: 1.25rem;
    font-size: 1.25rem;
    color: ${colors.gray4};
    text-align: center;
  }
  .loading-container {
    display: flex;
    place-content: center;
    margin-top: 1.25rem;
  }
`

const INITIAL_FORM_DATA = {
  itemTypes: Object.keys(reservationTimelineFiltersLabels),
  eventType: '',
  initialDate: new SafeDate().toStartOf('day').subtract({ days: 1 }).jsDate,
  finalDate: new SafeDate().toStartOf('day').jsDate
}

export const ReservationTimelineApp = ({ createdAt, selectedNav = '' }) => {
  const {
    isFirstLoad,
    isLoadingMore,
    fetchReservationTimeline,
    timelineData,
    submitAttempts
  } = useReservationTimeline()

  const initialValue = useMemo(() => ({
    ...INITIAL_FORM_DATA,
    initialDate: new SafeDate(createdAt).toStartOf('day').subtract({ days: 1 }).jsDate
  }), [createdAt])

  const {
    form,
    onSubmit,
    errors,
    utils: {
      onInputBlur,
      onInputChange
    }
  } = useForm(initialValue, ReservationTimelineSchema)

  const { add: addToast } = useToast()

  const isLoading = useMemo(() => isFirstLoad || isLoadingMore, [isFirstLoad, isLoadingMore])

  const isTimelineSelected = useMemo(() => selectedNav === 'timeline', [selectedNav])

  const onSuccess = useCallback((isForLoadMore = false) => (args) => {
    if (!isTimelineSelected) return
    if (args?.itemTypes?.length === 0) {
      return addToast('Selecione ao menos uma ação')
    }
    fetchReservationTimeline(args, isForLoadMore)
  }, [fetchReservationTimeline, addToast, isTimelineSelected])

  const onError = useCallback((errors) => {
    if (errors?.itemTypes) {
      addToast(errors?.itemTypes)
    } else {
      addToast('Preencha o formulário corretamente')
    }
  }, [addToast])

  const hasTimelineData = useMemo(() => timelineData.length > 0 && !isLoading, [isLoading, timelineData.length])

  const onChangeField = useCallback((key) => (value) => {
    const isValueDate = ['initialDate', 'finalDate'].includes(key)

    const isSameValue = isValueDate ? new SafeDate(value).isSame(form?.[key]) : value === form?.[key]

    if (isSameValue) return

    onInputChange(key)(value)

    onSuccess()({
      ...form,
      [key]: value
    })
  }, [form, onInputChange, onSuccess])

  useEffect(() => {
    if (
      !form?.itemTypes ||
      (submitAttempts > 0 && !isTimelineSelected)
    ) return
    onSuccess()(form)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, selectedNav])

  const fetchHistory = useCallback(() => {
    if (isLoading) return
    onSubmit(onSuccess(true), onError)()
  }, [isLoading, onSubmit, onSuccess, onError])

  const loaderRef = useRef(null)

  /*
    https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API The IntersectionObserver API basically, this is a way to know when an element is visible in screen.

    Creating the observer here because if create directly inside the useEffect, it will be recreated every time the component re-renders
  */
  const observer = useMemo(() => {
    const options = {
      // 1.0 means 100% of the element must be visible
      threshould: 1.0
    }

    const observer = new IntersectionObserver((entities) => {
      const target = entities[0]

      // If the element is visible, fetch more data
      if (target.isIntersecting) {
        fetchHistory()
      }
    }, options)
    return observer
  }, [fetchHistory])

  useEffect(() => {
    const observedElement = loaderRef.current

    if (observedElement) {
      observer.observe(observedElement)
    }

    // Stop the observer when the component is unmounted
    return () => observer.unobserve(observedElement)
  }, [observer])

  return (
    <>
      <ReservationTimelineFilters
        form={form}
        errors={errors}
        isLoading={isLoading}
        utils={{
          onChange: onChangeField,
          onBlur: onInputBlur
        }}
      />
      <div css={containerStyle}>
        {(!hasTimelineData && !isLoading) && (
          <p className='none-result'>
            Nenhum resultado foi encontrado.
          </p>
        )}
        {!isFirstLoad && (
          <ReservationTimeline timeline={timelineData} chainLength={5} />
        )}
        {isLoading && (
          <div className={'loading-container active'}>
            <LoadingAnimation size={isFirstLoad ? 80 : 60} />
          </div>
        )}
        <div ref={loaderRef} />
      </div>
    </>
  )
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useState, useMemo } from 'react'
import { useToast } from '@bonitour/components'
import { useHistory } from 'react-router-dom'

import { useQuery } from 'hooks/useQuery'
import { ReservationService } from 'services/Reservations/Service'
import { BookingForm } from 'containers/Booking/CreationForm/BookingForm'

import { useCompany } from 'contexts/Company'
import { useActivity } from 'contexts/Activity'
import { usePartnerList } from 'hooks/domains/usePartnerList'
import { useBookingTickets } from 'hooks/domains/useBookingTickets'
import { formHasTickets } from './Booking.utils'
import { identity } from '@bonitour/common-functions'
import { TRANSPORT_TYPE } from 'constants/activityTypes'
import { useUser } from 'contexts/User'

export const CreateBooking = () => {
  const history = useHistory()
  const { add } = useToast()
  const {
    id: activityId,
    activity: { companyId: activityCompanyId, isPartner = false, type }
  } = useActivity()
  const [{ hour, date = '', email = '' }] = useQuery()
  const { id: companyId } = useCompany()
  const { user } = useUser()

  const [ticketsList] = useBookingTickets(activityCompanyId, activityId, date, hour, add, type)
  const partnerList = usePartnerList(add)
  const [reservationBase] = useState(() => ({
    reservationType: {
      paymentState: 'responsible'
    },
    reservationHeader: {
      hour,
      date,
      partner: companyId,
      agent: user.id
    },
    initialEmail: email
  }))

  const basePath = useMemo(() => {
    if (type === TRANSPORT_TYPE) {
      return `/transport/${activityId}`
    }

    return `/activity/${activityId}`
  }, [activityId, type])

  if (!(hour || date)) {
    history.push(`${basePath}/bookings`)
  }

  const returnToBooking = () => history.push(`${basePath}/bookings?date=${date}`)

  const createRedirect = (redirectTo = identity) => async data => {
    const isFormValid = formHasTickets(data)
    if (isFormValid) {
      try {
        const { id } = await ReservationService.create(activityId, activityCompanyId, data)
        history.push(redirectTo(id))
      } catch (error) {
        const { parsedErrors = [] } = error
        parsedErrors.length ? parsedErrors.forEach(errorMessage => add(errorMessage, 'error', 10000)) : add('Houve um erro no cadastro da sua reserva')
      }
    } else {
      add('Adicione ingressos na reserva')
    }
  }

  const onFinish = createRedirect((reservationId) => `/reservation/${reservationId}?selectedNav=financeiro`)
  const onContinue = createRedirect((reservationId) => `/reservation/${reservationId}?selectedNav=ingressos`)

  return (
    <BookingForm
      isPartner={isPartner}
      reservationBase={reservationBase}
      ticketsList={ticketsList}
      partnerList={partnerList}
      onFinish={onFinish}
      onContinue={onContinue}
      onBackClick={returnToBooking}
      serviceType={type}
    />
  )
}

import { css } from '@emotion/react'
import { BREAK_POINTS, colors } from '@bonitour/components'
import styled from '@emotion/styled'

const colorCycle = [
  colors.indigo2, colors.green7, colors.blue4, colors.orange4, colors.red7,
  colors.indigo4, colors.brown1, colors.green8, colors.indigo5, colors.green9,
  colors.green10, colors.purple4, colors.brown2, colors.green11, colors.yellow4
]

export const serviceCombinedExperienceCard = css`
  display: flex;
  flex-direction: column;
  background-color: ${colors.gray10};
  border-radius: var(--round-element-radius);
  box-shadow: 0 0.25rem 0.375rem rgba(0,0,0,0.1);
  width: 13.438rem;
`

export const serviceCombinedExperienceCardItem = (colorIndex) => css`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  background-color: ${colorCycle[colorIndex % colorCycle.length]};
  border-radius: var(--round-element-radius);
  padding: 0.5rem;
  overflow: hidden;
`

export const ServiceCombinedExperienceCardContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-x: auto;
  padding: 0.625rem;
  gap: 0.625rem;
  flex-wrap: wrap;
  @media screen and (max-width: ${BREAK_POINTS.tableUp}){
    justify-content: center;
  }
`

export const serviceCombinedExperienceTitle = (colorIndex) => css`
  color: white;
  font-weight: bold;
  -webkit-text-stroke: 0.5px ${colorCycle[colorIndex % colorCycle.length]};
  padding-left: 0.625rem;
  font-size: 1.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  max-width: max-content;
  overflow-wrap: break-word;
  word-break: break-word;
  line-height: 1.5rem;
`

export const serviceCombinedExperienceCardImage = css`
  object-fit: cover;
  border-radius: var(--round-element-radius);
  height: 5rem;
  width: 5rem;
  flex-shrink: 0;
`

export const serviceCombinedExperienceCardInfo = css`
  position: relative;
  display: flex;
  padding: 0.625rem;
  border-radius: var(--round-element-radius);
  align-items: center;
  justify-content: center;
`

export const priceAndParticipants = css`
  background-color: ${colors.white};
  display: flex;
  align-items: center;
  font-weight: bold;
  border-radius: var(--round-element-radius);
  color: ${colors.gray4};
  width: 8.125rem;
  gap: 0.313rem;
`

export const participantCount = (colorIndex) => css`
  width: 1.563rem;
  height: 1.563rem;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: ${colorCycle[colorIndex % colorCycle.length]};
  border-radius: 25%;
  box-shadow: inset 0 0 0.25rem 1px ${colorCycle[colorIndex % colorCycle.length]};
`

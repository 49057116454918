import moment from 'moment'
import { metaModel } from './meta'

/* eslint-disable camelcase */
export const CommuniqueModel = {
  create: (communique = {}) => {
    const {
      serviceIds: service_ids,
      hours,
      date,
      category: subject,
      pushNotificationMessage: preview_message,
      message,
      sendEmail: send_email,
      sendSms: send_sms
    } = communique

    return {
      service_ids,
      hours,
      date: moment(date).format('YYYY-MM-DD'),
      subject,
      preview_message,
      message,
      send_email: send_email || false,
      send_sms: send_sms || false
    }
  },
  list: ({ filters, pagination }) => {
    return {
      ...CommuniquesSearchParamsModel(filters),
      ...metaModel(pagination)
    }
  }
}

export const CommuniquesSearchParamsModel = (filterParams = {}) => {
  const { sentInitialDate, sentFinalDate, serviceInitialDate, serviceFinalDate } = filterParams

  return {
    sent_date_filter_initial_date: sentInitialDate ? moment(sentInitialDate).format('YYYY-MM-DD') : undefined,
    sent_date_filter_final_date: sentFinalDate ? moment(sentFinalDate).format('YYYY-MM-DD') : undefined,
    service_date_filter_initial_date: serviceInitialDate ? moment(serviceInitialDate).format('YYYY-MM-DD') : undefined,
    service_date_filter_final_date: serviceFinalDate ? moment(serviceFinalDate).format('YYYY-MM-DD') : undefined
  }
}

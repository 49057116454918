/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { TableHeaderCell, TableHeaderRow, TableHeader, Checkbox } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'

export const VoucherTableHeader = ({
  isSelectedAll = false,
  onSelectedAllClick: emitSelectedAllClick = identity
}) => {
  return (
    <TableHeader>
      <TableHeaderRow>
        <TableHeaderCell>
          <Checkbox checked={isSelectedAll} onChange={emitSelectedAllClick} />
        </TableHeaderCell>
        <TableHeaderCell>Código externo</TableHeaderCell>
        <TableHeaderCell>Data da experiência</TableHeaderCell>
        <TableHeaderCell>Parceiro</TableHeaderCell>
        <TableHeaderCell>Experiência</TableHeaderCell>
        <TableHeaderCell>Status</TableHeaderCell>
        <TableHeaderCell>Lote</TableHeaderCell>
        <TableHeaderCell>Valor a repassar</TableHeaderCell>
      </TableHeaderRow>
    </TableHeader>
  )
}

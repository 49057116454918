import { colors, BREAK_POINTS } from '@bonitour/components'
import { css } from '@emotion/core'
import { marginBottom, marginLeft, marginRight, marginTop } from 'assets/styles/global'

export const marginTop50 = marginTop(50)
export const marginBottom20 = marginBottom(20)

export const container = css`
  color: ${colors.gray3};
`

export const toggleContainer = css`
  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    text-align: center;
  }
`

export const toggle = css`
  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    min-width: 36px;
  }
`

export const label = css`
  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

export const inputStyle = css`
  width: 110px;
  ${marginRight(10)};
  ${marginLeft(10)};
`

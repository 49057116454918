import { formatMoney } from '@bonitour/common-functions'
import { activityIdParser } from './activityId'

export const offlineExperienceIdParser = (service = {}) => {
  const commonIdValues = activityIdParser(service)
  const { category, offline_provider_id: offlineProviderId, offline_experience_info: offlineExperienceInfo } = service
  return {
    ...commonIdValues,
    category,
    offlineProviderId,
    defaultValue: formatMoney(offlineExperienceInfo.default_value),
    defaultNetValue: formatMoney(offlineExperienceInfo.default_net_value)
  }
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Card, H2, Row, H3, colors } from '@bonitour/components'
import { formatMoney } from '@bonitour/common-functions'
import { marginTop } from 'assets/styles/global'

const marginTop50 = marginTop(50)

const resultContainer = css`
  min-width: 215px;
  margin-right: 20px;
  margin-bottom: 20px;
`

const resultValue = (valueColor = colors.primary) => css`
  color: ${valueColor};
  font-size: 24px;
  font-weight: bold;
`

const ResultCard = ({ title, value, valueColor }) => {
  return (
    <Card css={resultContainer}>
      <H3>{title}</H3>
      <span css={resultValue(valueColor)}>{value}</span>
    </Card>
  )
}

export const InvoiceListTotals = ({ totals }) => {
  const { totalInvoiceValue, totalInvoiceDue } = totals

  return (
    <div css={marginTop50}>
      <H2>Resultado</H2>
      <Row>
        <ResultCard title='Valor total' value={formatMoney(totalInvoiceValue)} />
        <ResultCard title='Valor devido' value={formatMoney(totalInvoiceDue)} valueColor={colors.red1} />
      </Row>
    </div>
  )
}

/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import { Card, Input, InputFormGroup, Button, Select, Row, Column, DatePicker, H1, GhostButton, InputMoneyMask, Textarea, Label } from '@bonitour/components'
import { useForm } from '@bonitour/app-functions'
import { CashHandlingTypes } from '../constants/cashier'
import { useMemo } from 'react'
import { validationSchema } from './CashierHandlingForm.schema'
import { cardSpacing, rowOffset, userFormSection, inputOffset, actionRow, marginTop10 } from './CashierHandlingForm.styles'
import { width100 } from 'assets/styles/global'

const cashHandlingTypeOptions = [
  { value: CashHandlingTypes.in, label: 'Entrada de caixa' },
  { value: CashHandlingTypes.out, label: 'Saída de caixa' }
]

export const CashierHandlingForm = ({
  loading,
  handleSubmit,
  onError,
  onCancel
}) => {
  const initialForm = useMemo(() => ({
    date: Date.now(),
    value: '',
    comments: '',
    cashHandlingType: '',
    operatorLogin: '',
    operatorPassword: '',
    supervisorLogin: '',
    supervisorPassword: ''
  }), [])

  const { form, errors, onSubmit, utils: { onInputChange, onInputBlur } } = useForm(initialForm, validationSchema)

  const onConfirm = onSubmit(handleSubmit, onError)

  const {
    date,
    value = '',
    cashHandlingType = '',
    operatorLogin = '',
    operatorPassword = '',
    supervisorLogin = '',
    supervisorPassword = '',
    comments
  } = form

  return (
    <>
      <H1>Movimentação de caixa</H1>
      <Card css={cardSpacing}>
        <Row>
          <Column phone={12} desktop={4}>
            <InputFormGroup label='Data' errorMessage={errors.date}>
              <DatePicker value={date} onChange={onInputChange('date')} disabled={loading} fullWidth />
            </InputFormGroup>
          </Column>

          <Column phone={12} desktop={4}>
            <InputFormGroup label='Tipo de operação' errorMessage={errors.cashHandlingType}>
              <Select
                options={cashHandlingTypeOptions}
                value={cashHandlingType}
                error={errors.cashHandlingType}
                onChange={onInputChange('cashHandlingType')}
                disabled={loading}
              />
            </InputFormGroup>
          </Column>

          <Column phone={12} desktop={4}>
            <InputFormGroup label='Valor' errorMessage={errors.value}>
              <InputMoneyMask
                value={value}
                error={errors.value}
                onChange={onInputChange('value')}
                onBlur={onInputBlur('value')}
                disabled={loading}
              />
            </InputFormGroup>
          </Column>
        </Row>
        <Row>
          <Column phone={12}>
            <div css={width100}>
              <Label htmlFor='comments'>Observações</Label>
              <Textarea css={marginTop10} id='comments' onChange={onInputChange('comments')} value={comments} />
            </div>
          </Column>

        </Row>

        <div css={userFormSection}>
          <Row css={rowOffset}>
            <Column phone={12} desktop={3}>
              <InputFormGroup label='Agente' errorMessage={errors.operatorLogin} css={inputOffset}>
                <Input
                  value={operatorLogin}
                  error={errors.operatorLogin}
                  onChange={onInputChange('operatorLogin')}
                  onBlur={onInputBlur('operatorLogin')}
                  disabled={loading}
                />
              </InputFormGroup>
            </Column>
            <Column phone={12} desktop={3}>
              <InputFormGroup label='Senha' errorMessage={errors.operatorPassword}>
                <Input
                  type='password'
                  value={operatorPassword}
                  error={errors.operatorPassword}
                  onChange={onInputChange('operatorPassword')}
                  onBlur={onInputBlur('operatorPassword')}
                  disabled={loading}
                />
              </InputFormGroup>
            </Column>
            <Column phone={12} desktop={3}>
              <InputFormGroup label='Supervisor' errorMessage={errors.supervisorLogin} css={inputOffset}>
                <Input
                  value={supervisorLogin}
                  error={errors.supervisorLogin}
                  onChange={onInputChange('supervisorLogin')}
                  onBlur={onInputBlur('supervisorLogin')}
                  disabled={loading}
                />
              </InputFormGroup>
            </Column>
            <Column phone={12} desktop={3}>
              <InputFormGroup label='Senha' errorMessage={errors.supervisorPassword}>
                <Input
                  type='password'
                  value={supervisorPassword}
                  error={errors.supervisorPassword}
                  onChange={onInputChange('supervisorPassword')}
                  onBlur={onInputBlur('supervisorPassword')}
                  disabled={loading}
                />
              </InputFormGroup>
            </Column>
          </Row>
        </div>
      </Card>
      <div css={actionRow}>
        <GhostButton onClick={onCancel}>Cancelar</GhostButton>
        <Button onClick={onConfirm}>Confirmar movimentação</Button>
      </div>
    </>
  )
}

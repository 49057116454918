/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { LogoutIcon, ResponsiveImage, CircleThumbnail, CompanyIcon, UsersIcon, flexColumnCentered, flexColumn, flexRowCentered, AccountIcon } from '@bonitour/components'
import { useUser } from 'contexts/User'
import { NavLink } from 'react-router-dom'
import { container, overflowLink, imageContainer, nameStyle, emailStyle, shortcutLabel, flexStart, itemStyle, iconStyle, profileCompanyStyle, profileModeStyle } from './UserOptions.style'
import { marginBottom } from 'assets/styles/global'
import { useMemo } from 'react'
import { useCompany } from 'contexts/Company'

const { REACT_APP_ACCOUNTS_URL } = process.env

const marginBottom20 = marginBottom(20)

const Item = ({ icon: Icon, label, ...other }) => (
  <div css={[flexRowCentered, itemStyle]} {...other}>
    <Icon css={iconStyle} />
    <span>{label}</span>
  </div>
)

const UserLinkRow = ({ to = '', children, icon: Icon = null }) => (
  <NavLink to={to} css={overflowLink}>
    <Item icon={Icon} label={children} />
  </NavLink>
)

const getFirstLetter = (name = '') => (name[0] || '')

const redirectOnClick = link => () => {
  window.location = link
}

export const UserOptions = () => {
  const { user, hasAffiliateProfile } = useUser()
  const { name, avatar, email } = user
  const { company, userCompanies } = useCompany()
  const allowsChangingProfile = useMemo(
    () => hasAffiliateProfile || userCompanies?.length > 1,
    [hasAffiliateProfile, userCompanies]
  )

  return (
    <div css={container}>
      <div css={[flexColumnCentered, marginBottom20]}>
        <CircleThumbnail size={60} css={imageContainer}>
          {avatar ? <ResponsiveImage src={avatar} alt={`Foto de ${name}`} /> : getFirstLetter(name)}
        </CircleThumbnail>
        <p css={nameStyle}>{name}</p>
        <p css={emailStyle}>{email}</p>
        {company?.id && (
          <>
            <p css={profileCompanyStyle}>{company.name}</p>
            <p css={profileModeStyle}>Perfil de reservas</p>
          </>
        )}
      </div>
      <div css={[flexColumn, flexStart]}>
        <span css={shortcutLabel}>Atalhos</span>
        <Item onClick={redirectOnClick(`${REACT_APP_ACCOUNTS_URL}/app/company/edit`)} icon={CompanyIcon} label='Empresa' />
        <Item onClick={redirectOnClick(`${REACT_APP_ACCOUNTS_URL}/app/users`)} icon={UsersIcon} label='Usuários' />
        {allowsChangingProfile && (
          <Item
            onClick={redirectOnClick(`${REACT_APP_ACCOUNTS_URL}/auth/profiles?redirectUrl=${decodeURIComponent(window.location.origin)}`)}
            icon={AccountIcon}
            label='Alterar perfil'
          />
        )}
        <UserLinkRow icon={LogoutIcon} to='/logout'>Sair</UserLinkRow>
      </div>
    </div>
  )
}

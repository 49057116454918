import { colors } from '@bonitour/components'

const customColors = [
  '#B9E937',
  '#FF7070',
  '#F7DBF0',
  '#BEAEE2',
  '#FFCECE',
  '#FFC1C8',
  '#FFE3B0',
  '#8ED6FF',
  '#734488',
  '#492645',
  '#9FEBEB',
  '#FAFCDB',
  '#A2453D',
  '#FFD6A6',
  '#FF8AB4',
  '#D86EFF',
  '#970747',
  '#FEF4E8',
  '#13445A',
  '#446878',
  '#FFC93C',
  '#07689F',
  '#CCEABB',
  '#FDCB9E',
  '#EA5455',
  '#6B76FF',
  '#A5AEFF',
  '#C8E4FE',
  '#16C79A',
  '#11698E',
  '#F7F373',
  '#EA9085',
  '#FF7600',
  '#FFA900',
  '#52006A',
  '#8B4367',
  '#FF6464',
  '#45B7B8',
  '#706381',
  '#F7DE1C',
  '#DFF5F2',
  '#2F9296',
  '#194350',
  '#FF8882',
  '#FFC2B4',
  '#9DBEB9',
  '#E5F9BD',
  '#A0E418',
  '#CA5FA6',
  '#7FB414',
  '#F36886',
  '#FA8282',
  '#512C96',
  '#A696C8',
  '#3C6F9C',
  '#8ED6FF',
  '#DD6892',
  '#F1ED63',
  '#EA5959',
  '#D97D97',
  '#F98B60',
  '#9862AE',
  '#FFC057',
  '#7E80FF',
  '#E7759A',
  '#FFA35F',
  '#C7EEFF',
  '#755DA3',
  '#0077C0',
  '#FF8000',
  '#FFE227',
  '#D3560E',
  '#EB596E',
  '#851E52',
  '#4D375D',
  '#445C3C',
  '#F688BB',
  '#FDA77F',
  '#E8F9E9',
  '#9DE3D0',
  '#FAB95B',
  '#553C8B',
  '#F5564E',
  '#9EA9F0',
  '#F5564E',
  '#CCC1FF',
  '#5BA19B',
  '#EB76FF',
  '#3D655D',
  '#FFA8EC',
  '#EFFF9D',
  '#D9B650',
  '#F58634',
  '#D2E603',
  '#F58634',
  '#FC4442',
  '#9B77DA'
]

export const colorSequence = [
  colors.primary,
  colors.green1,
  colors.red1,
  colors.gray2,
  colors.orange3,
  colors.green3,
  colors.yellow1,
  colors.gray6,
  colors.orange4,
  ...customColors
]

import { ReservationTicketParser } from './ReservationTicket'
import { ReservationHistoryParser } from './ReservationHistory'
import { ReservationBalanceParser } from './ReservationBalance'

export const ReservationParser = {
  ticketList ({ tickets = [] }) {
    return tickets.map(ReservationTicketParser.ticket)
  },
  ticketListToPayFromVendor ({ tickets = [] }) {
    return tickets.map(ReservationTicketParser.ticketToPayFromVendor)
  },
  ticketListToRefund ({ tickets_to_refunds = [] }) {
    return tickets_to_refunds.map(ReservationTicketParser.ticketToRefund)
  },
  partnershipTicketListToRefund ({ data: { tickets_to_refunds = [] } }) {
    return tickets_to_refunds.map(ReservationTicketParser.partnershipTickets)
  },
  history ({ historics = [] }) {
    return historics.map(ReservationHistoryParser.history)
  },
  historyV2 ({ historics = [], meta = {} }) {
    const historicsFiltered = historics.map(historic => ReservationHistoryParser.historyV2(historic, historics)).filter((history) => history !== null)

    return {
      meta: {
        totalEntries: meta.total_entries,
        currentPage: meta.current_page,
        totalPages: meta.total_pages
      },
      historics: historicsFiltered
    }
  },
  vendorsAsOptions ({ companies }) {
    return companies.map(({ id, name }) => ({ label: name, value: id }))
  },
  balance ({ tickets_to_transfer_grouped_by_companies: ticketsToTransfer }) {
    return ReservationBalanceParser.balance(ticketsToTransfer)
  }
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Card, H2, Row, H3, colors, Column } from '@bonitour/components'
import { formatMoney } from '@bonitour/common-functions'
import { marginLeft, marginTop } from 'assets/styles/global'

const marginTop50 = marginTop(50)
const marginLeft10 = marginLeft(10)

const resultContainer = css`
  width: 100%;
  margin-bottom: 20px;
  padding: 20px;
`

const resultValue = css`
  color: ${colors.primary};
  font-size: 24px;
  font-weight: bold;
`

const smallResultContainer = css`
  h3 {
    font-size: 16px;
    line-height: 20px;
  }
  span {
    font-size: 18px;
    line-height: 24px;
  }
`

const blurResults = css`
  span {
    filter: blur(0.6rem);
  }
`

const ResultCard = ({ title, value, small = false }) => {
  return (
    <Card css={[resultContainer, small && smallResultContainer]}>
      <H3>{title}</H3>
      <span css={resultValue}>{value}</span>
    </Card>
  )
}

export const SalesResults = ({ totals, loading }) => {
  const { reservationsAmount, salesAmount, priceAmount, vendorAmount, sellerAmount, guideAmount, transferredAmount } = totals

  return (
    <div css={[marginTop50, loading && blurResults]}>
      <H2 css={marginLeft10}>Resultado</H2>
      <Row>
        <Column phone={12} tabletlandscape={4} desktop={4}>
          <ResultCard title='Valor total' value={formatMoney(priceAmount)} />
        </Column>
        <Column phone={12} tabletlandscape={4} desktop={4}>
          <ResultCard small title='Reservas' value={reservationsAmount} />
        </Column>
        <Column phone={12} tabletlandscape={4} desktop={4}>
          <ResultCard small title='Ingressos' value={salesAmount} />
        </Column>
        <Column phone={12} tabletlandscape={4} desktop={4}>
          <ResultCard small title='Valor atrativo' value={formatMoney(vendorAmount)} />
        </Column>
        <Column phone={12} tabletlandscape={4} desktop={4}>
          <ResultCard small title='Valor agência' value={formatMoney(sellerAmount)} />
        </Column>
        <Column phone={12} tabletlandscape={4} desktop={4}>
          <ResultCard small title='Valor guia' value={formatMoney(guideAmount)} />
        </Column>
        <Column phone={12} tabletlandscape={4} desktop={4}>
          <ResultCard small title='Transferência de saldo' value={formatMoney(transferredAmount)} />
        </Column>
      </Row>
    </div>
  )
}

import { colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const ticketSelectorContainer = css`
  background-color: ${colors.gray13};
  display: flex;
  justify-content: flex-start;
  padding: 1rem 1rem;
  width: 100%;
  border-radius: 8px;
  margin-top: 0;

  .item__block {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    flex: 1;
    font-size: 1rem;
  }

  .item__label {
    font-size: 0.75em;
    font-weight: 600;
    color: ${colors.gray4};
    margin-bottom: 0.25rem;
  }

  .item__value {
    font-size: 1em;
    font-weight: 700;
    color: ${colors.gray1};
  }
`

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { identity, maskExpirationDate } from '@bonitour/common-functions'
import {
  Label,
  Row,
  DatePicker,
  Input,
  InputTimeMask,
  Select,
  InputFormGroup,
  MaskedInput,
  Column
} from '@bonitour/components'
import { rowStyle } from '../OfflineExperiencesTicketForm.style'
import { AgentInput, CountInputFormGroup, NetPriceInputFormGroup, ObservationRowInput, PriceInputFormGroup, SlotInputFormGroup, UnitNetPriceInputFormGroup, UnitTotalPriceInputFormGroup } from './CommonFormFields'

export const CarRentalForm = ({
  isEditing = false,
  form,
  errors,
  stateOptions,
  onInputBlur = identity,
  onInputChange = identity,
  disableTotalPrice = false,
  disabled = false
}) => {
  return (
    <>
      {!isEditing && !disabled
        ? <AgentInput errors={errors} form={form} onInputChange={onInputChange} onInputBlur={onInputBlur} />
        : null}

      <Row css={rowStyle}>
        <Column desktop={6} phone={12}>
          <div>
            <Label>Retirada</Label>
            <div className='horizontal__card__container'>
              <Row css={rowStyle}>
                <Column desktop={12} phone={12}>
                  <InputFormGroup
                    errorMessage={errors.pickupAddress}
                    label='Local'
                    className='large__text__input'
                  >
                    <Input
                      value={form.pickupAddress}
                      onChange={onInputChange('pickupAddress')}
                      onBlur={onInputBlur('pickupAddress')}
                      placeholder='Local de retirada'
                      disabled={disabled}
                    />
                  </InputFormGroup>
                </Column>
              </Row>
              <Row css={rowStyle}>
                <SlotInputFormGroup
                  form={form}
                  errors={errors}
                  onInputChange={onInputChange}
                  onInputBlur={onInputBlur}
                  desktop={6}
                  phone={12}
                  disabled={disabled}
                />
              </Row>
            </div>
          </div>
        </Column>
        <Column desktop={6} phone={12}>
          <div>
            <Label>Devolução</Label>
            <div className='horizontal__card__container'>
              <Row css={rowStyle}>
                <Column desktop={12} phone={12}>
                  <InputFormGroup
                    errorMessage={errors.dropoffAddress}
                    label='Local'
                    className='large__text__input'
                  >
                    <Input
                      value={form.dropoffAddress}
                      onChange={onInputChange('dropoffAddress')}
                      onBlur={onInputBlur('dropoffAddress')}
                      placeholder='Local de devolução'
                      disabled={disabled}
                    />
                  </InputFormGroup>
                </Column>
              </Row>
              <Row css={rowStyle}>
                <Column desktop={6} phone={12}>
                  <InputFormGroup
                    errorMessage={errors.checkoutDate}
                    label='Data'
                    className='date__input'
                  >
                    <DatePicker
                      value={form.checkoutDate}
                      onChange={onInputChange('checkoutDate')}
                      onBlur={onInputBlur('checkoutDate')}
                      disabled={disabled}
                    />
                  </InputFormGroup>
                </Column>
                <Column desktop={6} phone={12}>
                  <InputFormGroup
                    errorMessage={errors.checkoutTime}
                    label='Horário'
                    className='time__input'
                  >
                    <InputTimeMask
                      value={form.checkoutTime}
                      onChange={onInputChange('checkoutTime')}
                      onBlur={onInputBlur('checkoutTime')}
                      className='small__input'
                      disabled={disabled}
                    />
                  </InputFormGroup>
                </Column>
              </Row>
            </div>
          </div>
        </Column>
      </Row>
      <Row css={rowStyle}>
        <Column desktop={12} phone={12}>
          <div className='full_width'>
            <Label>Dados do condutor</Label>
            <div className='conductor__container'>
              <Row css={rowStyle}>
                <Column desktop={4} phone={12}>
                  <InputFormGroup
                    label='Nome'
                    errorMessage={errors.driverName}
                    className='large__text__input'
                  >
                    <Input
                      value={form.driverName}
                      onChange={onInputChange('driverName')}
                      onBlur={onInputBlur('driverName')}
                      placeholder='Nome do condutor'
                      disabled={disabled}
                    />
                  </InputFormGroup>
                </Column>
                <Column desktop={4} phone={12}>
                  <InputFormGroup
                    errorMessage={errors.driverLicense}
                    label='CNH'
                    className='text__input'
                  >
                    <Input
                      value={form.driverLicense}
                      onChange={onInputChange('driverLicense')}
                      onBlur={onInputBlur('driverLicense')}
                      placeholder='Digite a habilitação do condutor'
                      disabled={disabled}
                    />
                  </InputFormGroup>
                </Column>
                <Column desktop={4} phone={12}>
                  <InputFormGroup
                    errorMessage={errors.driverLicenseDueDate}
                    label='Vencimento CNH'
                    className='date__input'
                  >
                    <MaskedInput
                      value={form.driverLicenseDueDate}
                      onChange={onInputChange('driverLicenseDueDate')}
                      onBlur={onInputBlur('driverLicenseDueDate')}
                      formatterFunction={maskExpirationDate}
                      disabled={disabled}
                    />
                  </InputFormGroup>
                </Column>
              </Row>
              <Row css={rowStyle}>
                <Column desktop={6} phone={12}>
                  <InputFormGroup
                    errorMessage={errors.driverDocument}
                    label='Documento'
                    className='document__input'
                  >
                    <MaskedInput
                      formatterFunction={value => value.replace(/\D/g, '')}
                      value={form.driverDocument}
                      onChange={onInputChange('driverDocument')}
                      onBlur={onInputBlur('driverDocument')}
                      placeholder='Digite o documento do condutor'
                      disabled={disabled}
                    />
                  </InputFormGroup>
                </Column>
                <Column desktop={6} phone={12}>
                  <InputFormGroup
                    errorMessage={errors.driverAddress}
                    label='Endereço do motorista'
                    className='extra_large_text__input'
                  >
                    <Input
                      value={form.driverAddress}
                      onChange={onInputChange('driverAddress')}
                      onBlur={onInputBlur('driverAddress')}
                      placeholder='Digite o endereço do motorista'
                      disabled={disabled}
                    />
                  </InputFormGroup>
                </Column>
              </Row>
            </div>
          </div>
        </Column>
      </Row>
      <Row css={rowStyle}>
        <Column desktop={4} phone={12}>
          <InputFormGroup errorMessage={errors.state} label='Estado' className='state__input'>
            <Select
              placeholder='Estado'
              options={stateOptions}
              value={form.state}
              onChange={onInputChange('state')}
              onBlur={onInputBlur('state')}
              disabled={disabled}
            />
          </InputFormGroup>
        </Column>
        <Column desktop={4} phone={12}>
          <InputFormGroup
            errorMessage={errors.companyName}
            label='Empresa'
            className='text__input'
          >
            <Input
              value={form.companyName}
              onChange={onInputChange('companyName')}
              onBlur={onInputBlur('companyName')}
              placeholder='Digite o nome da empresa'
              disabled={disabled}
            />
          </InputFormGroup>
        </Column>
        <Column desktop={4} phone={12}>
          <InputFormGroup errorMessage={errors.transporteType} label='Tipo de veículo' className='text__input'>
            <Input
              value={form.transporteType}
              onChange={onInputChange('transporteType')}
              onBlur={onInputBlur('transporteType')}
              placeholder='Digite o tipo do veículo'
              disabled={disabled}
            />
          </InputFormGroup>
        </Column>
      </Row>
      <Row css={rowStyle}>
        <Column desktop={3} phone={12}>
          <InputFormGroup errorMessage={errors.externalReservationCode} label='Código externo' className='text__input'>
            <Input
              value={form.externalReservationCode}
              onChange={onInputChange('externalReservationCode')}
              onBlur={onInputBlur('externalReservationCode')}
              placeholder='Digite o código externo'
              disabled={disabled}
            />
          </InputFormGroup>
        </Column>
        {!isEditing && !disabled && (
          <>
            <Column desktop={3} phone={12}>
              <UnitTotalPriceInputFormGroup
                form={form}
                onChange={onInputChange}
                errors={errors}
              />
            </Column>
            <Column desktop={3} phone={12}>
              <UnitNetPriceInputFormGroup
                form={form}
                onChange={onInputChange}
                errors={errors}
              />
            </Column>
          </>
        )}
        {!isEditing && (
          <Column desktop={3} phone={12}>
            <CountInputFormGroup
              form={form}
              errors={errors}
              onInputChange={onInputChange}
              onInputBlur={onInputBlur}
              disabled={disabled}
            />
          </Column>
        )}
        <Column desktop={3} phone={12}>
          <PriceInputFormGroup
            disabled={disableTotalPrice || disabled}
            errors={errors}
            form={form}
            onInputChange={onInputChange}
            onInputBlur={onInputBlur}
            isEditing={isEditing}
          />
        </Column>
        <Column desktop={3} phone={12}>
          <NetPriceInputFormGroup
            disabled={disabled}
            errors={errors}
            form={form}
            onInputChange={onInputChange}
            onInputBlur={onInputBlur}
            isEditing={isEditing}
          />
        </Column>
      </Row>
      <ObservationRowInput
        errors={errors}
        form={form}
        onInputChange={onInputChange}
        onInputBlur={onInputBlur}
        disabled={disabled}
      />
    </>
  )
}

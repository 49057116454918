/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useEffect, useState } from 'react'
import { TransportAboutForm } from 'containers/TransportAboutForm/TransportAboutForm'
import { useToast } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { TransportService } from 'services/Transport/Service'
import { ActivityAdditionInformationService } from 'services/Activity/ActivityAdditionInformation/Service'

export const TransportAbout = ({
  transportId = '',
  transportAbout = {},
  categories = {},
  onTransportUpdated = identity,
  onBackStep = identity,
  onNextStep = identity
}) => {
  const [infrastructureList, setInfrastructureList] = useState([])
  const { add } = useToast()

  const onValidationError = () => {
    add('Preencha corretamente o formulário')
  }

  const onSuccess = async data => {
    try {
      await TransportService.updateAbout(data, transportId)
      onNextStep()
      onTransportUpdated()
    } catch (error) {
      add('Ocorreu um erro ao adicionar as informações')
    }
  }

  useEffect(() => {
    ActivityAdditionInformationService.list({ type: 'transport' }).then(({ infrastructureList }) => infrastructureList && setInfrastructureList(infrastructureList))
  }, [])

  return (
    <TransportAboutForm
      categories={categories}
      transportAbout={transportAbout}
      infrastructureList={infrastructureList}
      onBackClick={onBackStep}
      onSuccess={onSuccess}
      onValidationError={onValidationError}
    />
  )
}

import { string } from 'yup'

export const PickupAddressSchema = () => {
  return ({
    zipCode: string().nullable(),
    address: string().nullable(),
    addressDetail: string().nullable(),
    district: string().nullable(),
    country: string().nullable(),
    state: string().nullable(),
    city: string().nullable()
  }
  )
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { InputFormGroup, Textarea, Upload, Column, Row, useToast, UploadFootnote } from '@bonitour/components'
import { marginRight, width100 } from 'assets/styles/global'
import { identity } from '@bonitour/common-functions'

const marginRight20 = marginRight(20)

export const InvoiceNote = ({
  details = {},
  isReadOnly = false,
  onChange: emitChangeEvent = identity
}) => {
  const { add: addToast } = useToast()
  const { note = '', files = [] } = details

  return (
    <Row>
      <Column phone={12} desktop={6}>
        <div css={width100}>
          <InputFormGroup css={[marginRight20]} label='Anexar arquivo (máximo 15mb)'>
            <Upload
              disabled={isReadOnly}
              multiple
              files={files}
              onChange={emitChangeEvent('details.files')}
              limitMbSize={15}
              onError={addToast}
              emptyMessage='Adicione arquivos com no máximo 15Mb'
              accept='image/png, image/jpeg, application/pdf'
            />
          </InputFormGroup>
          <UploadFootnote css={width100} typeList={['.jpg', '.png', '.pdf']} />
        </div>
      </Column>

      <Column phone={12} desktop={6}>
        <InputFormGroup css={width100} id='note' label='Observação'>
          <Textarea disabled={isReadOnly} id='note' onChange={emitChangeEvent('details.note')} value={note} />
        </InputFormGroup>
      </Column>
    </Row>
  )
}

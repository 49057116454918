import { orbCore } from 'services/Orb/Service'
import { ReservationParser } from 'core/parsers/Reservation'
import { ReservationModel } from 'core/models/Reservation'

const { reservationTicketsDomain, reservationDomain } = orbCore

export const ReservationService = {
  getTicketsToPay (reservationId) {
    return reservationTicketsDomain.getTicketsToPay(reservationId).then(ReservationParser.ticketList)
  },

  getTicketsToRefund (reservationId, paymentId) {
    return reservationTicketsDomain.getTicketsToRefund(
      reservationId, { reservation_payment_id: paymentId }
    ).then(ReservationParser.ticketListToRefund)
  },

  getPartnershipTicketsToRefund (reservationId) {
    return reservationTicketsDomain.getPartnershipTicketsToRefund(reservationId).then(ReservationParser.partnershipTicketListToRefund)
  },

  getTicketsToPayFromVendor (reservationId, vendorId) {
    return reservationTicketsDomain.getTicketsToPayFromVendor(reservationId, vendorId).then(ReservationParser.ticketListToPayFromVendor)
  },

  getReservationVendors (reservationId) {
    return reservationDomain.getReservationVendors(reservationId).then(ReservationParser.vendorsAsOptions)
  },

  getBalance (reservationId) {
    return reservationDomain.getBalance(reservationId).then(ReservationParser.balance)
  },

  changeTicketSlot (reservationId, payload) {
    return reservationDomain.changeTicketSlot(reservationId, ReservationModel.changeTicket(payload))
  }
}

import moment from 'moment'
import { deepGetOrElse } from 'deep-getter'
import { formatISOTime } from 'utils/time'

export const ReservationAdvanceFinanceModel = ({ companyId, upload, method, installments = 0, ticketsIds, aut, doc, acquirer, paymentDate, originBankAccount, destinationBankAccount }) => ({
  payment_method: method,
  receipt: deepGetOrElse(upload, '0.file', null),
  tickets_ids: ticketsIds.map(({ id }) => id),
  destination_company_id: companyId,
  payment_date: formatISOTime(paymentDate || moment(), 'YYYY-MM-DD HH:mm:ss', false),
  installments,
  doc,
  aut,
  observation: '',
  reason_delete: '',
  acquirer_id: acquirer,
  origin_bank_account_id: originBankAccount || undefined,
  destination_bank_account_id: destinationBankAccount || undefined
})

export const ReservationAdvanceRefundModel = ({ refundCompanyId, installments, upload, method, ticketsIds, totalValue }) => ({
  destination_company_id: refundCompanyId,
  refund_date: formatISOTime(moment(), 'YYYY-MM-DD HH:mm:ss', false),
  refund_method: method,
  tickets_ids: ticketsIds.map(({ id }) => id),
  value: Number(totalValue).toFixed(2),
  receipt: deepGetOrElse(upload, '0.file', null),
  installments
})

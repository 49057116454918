import { paymentProviderSchema, providerRefundSchema } from './ProviderTransactionForm.schema'

export const CREATE_PROVIDER_PAYMENT_STATE = 'createProviderPayment'
export const EDIT_PROVIDER_PAYMENT_STATE = 'editProviderPayment'
export const CREATE_PROVIDER_REFUND_STATE = 'createProviderRefund'
export const EDIT_PROVIDER_REFUND_STATE = 'editProviderRefund'

export const providerTransactionStates = {
  [CREATE_PROVIDER_PAYMENT_STATE]: {
    modalTitle: 'Registrar pagamento para o fornecedor',
    companyTitle: 'Empresa a pagar',
    ticketTitle: 'Ingressos a pagar',
    hasTicketEdition: true,
    schema: paymentProviderSchema,
    isRefund: false
  },
  [CREATE_PROVIDER_REFUND_STATE]: {
    modalTitle: 'Reembolsar pagamento',
    companyTitle: 'Ingressos a reembolsar',
    ticketTitle: 'Ingressos a reembolsar',
    valueLabel: 'Valor a reembolsar',
    labels: 'pagamento',
    schema: providerRefundSchema,
    hasInstallments: false,
    isRefund: true,
    hasAutDoc: false,
    hasTicketEdition: true
  }
}

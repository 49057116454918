import React, { createContext, useCallback, useEffect, useState } from 'react'
import { useToast } from '@bonitour/components'
import { PartnershipAccountService } from 'core/services/PartnershipAccount'
import { useParams } from 'react-router-dom'
import { exportCsv, exportXlsx } from '@bonitour/common-functions'
import { usePermissions } from 'hooks/usePermissions'

const handleGetTotalizers = ({ partnershipAccountId, startDate, endDate, paymentType }) => {
  return PartnershipAccountService.getTotalizers({
    partnershipAccountId,
    startDate,
    endDate,
    paymentType
  })
}

const handleGetTransactions = ({ partnershipAccountId, pagination, startDate, endDate, paymentType }) => {
  return PartnershipAccountService.listTransactions({
    partnershipAccountId,
    pagination,
    startDate,
    endDate,
    paymentType
  })
}

const defaultPagination = { currentPage: 1, perPage: 10 }

export const PartnershipContext = createContext()
export const PartnershipProvider = ({ children }) => {
  const { add: addToast } = useToast()
  const { partnershipAccountId } = useParams()
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState(defaultPagination)
  const [totalizers, setTotalizers] = useState({})

  const changePagination = useCallback(
    ({ currentPage, perPage, startDate, endDate, paymentType }) => {
      setPagination({ currentPage, perPage })

      getTransactions({
        pagination: { currentPage, perPage },
        startDate,
        endDate,
        paymentType,
        partnershipAccountId
      })
      // eslint-disable-next-line
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [partnershipAccountId]
  )

  const [partnershipAccountData, setPartnershipAccountData] = useState()
  const [transactionsData, setTransactionsData] = useState({
    transactions: undefined,
    meta: { total: 0, totalPages: 1 }
  })

  const fetchPartnershipAccount = useCallback((partnershipAccountId) => {
    if (!partnershipAccountId) return
    return PartnershipAccountService.getById(partnershipAccountId).then((data) => {
      setPartnershipAccountData(data)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchPaymentsType = useCallback(() => {
    return PartnershipAccountService.getPaymentsType()
  }, [])

  const getTransactions = useCallback(({ partnershipAccountId, pagination, startDate, endDate, paymentType }) => {
    setLoading(true)

    if (!partnershipAccountId) {
      setLoading(false)
      return
    }

    handleGetTotalizers({
      partnershipAccountId,
      startDate,
      endDate,
      paymentType
    }).then(setTotalizers)

    return handleGetTransactions({
      partnershipAccountId,
      pagination,
      startDate,
      endDate,
      paymentType
    })
      .then((data) => {
        setTransactionsData(data)
      })
      .catch(() => {
        addToast('Erro inesperado')
      })
      .finally(() => {
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [columnsToExport, setColumnsToExport] = useState([])

  const fetchColumnsToExport = useCallback(() => {
    PartnershipAccountService.getColumnsToExport().then(setColumnsToExport)
  }, [])

  const exportMyDay = useCallback((columnsFilters, filters) => {
    const { format, wantedFields } = columnsFilters

    const exportType = format === 'csv' ? csvExport : xlsxExport

    return exportType(wantedFields, filters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [exporting, setExporting] = useState(false)
  const { handlePermission } = usePermissions()

  const csvExport = useCallback((columnsFilters, filters) => {
    setExporting(true)
    const csvFilters = { wantedFields: columnsFilters, ...filters }

    return PartnershipAccountService.csvExport({ filters: csvFilters, pagination }, partnershipAccountId)
      .then((data) => {
        exportCsv('transacoes-conta-de-parceria', data)
      })
      .catch((error) => {
        const hasPermission = handlePermission(error, 'Você não possui permissão de acesso a exportação do meu dia')
        hasPermission && addToast('Ocorreu um erro ao realizar a busca')
      })
      .finally(() => setExporting(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const xlsxExport = useCallback((columnsFilters, filters) => {
    setExporting(true)
    const xlsxFilters = { wantedFields: columnsFilters, ...filters }

    return PartnershipAccountService.xlsxExport({ filters: xlsxFilters, pagination }, partnershipAccountId)
      .then((data) => {
        exportXlsx('transacoes-conta-de-parceria', data)
      })
      .catch((error) => {
        const hasPermission = handlePermission(error, 'Você não possui permissão de acesso a exportação do meu dia')
        hasPermission && addToast('Ocorreu um erro ao realizar a busca')
      })
      .finally(() => setExporting(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const abortController = new AbortController()

    fetchPartnershipAccount(partnershipAccountId)
    getTransactions({ partnershipAccountId, pagination })
    fetchColumnsToExport()

    return () => {
      abortController.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PartnershipContext.Provider
      value={{
        pagination,
        changePagination,
        transactions: transactionsData?.transactions,
        partnershipAccount: partnershipAccountData,
        meta: transactionsData?.meta,
        getTransactions,
        fetchPartnershipAccount,
        loading,
        totalizers,
        columnsToExport,
        exporting,
        exportMyDay,
        fetchPaymentsType
      }}
    >
      {children}
    </PartnershipContext.Provider>
  )
}

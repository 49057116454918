import React, {
  createContext,
  useContext,
  useMemo,
  useEffect,
  useState
} from 'react'
import { useUser } from './User'
import { PermissionsService } from 'core/services/Permissions'
import { useToast } from '@bonitour/components'
import { useHistory } from 'react-router-dom'

export const PermissionsContext = createContext({
  permissions: undefined,
  hasError: false,
  isLoading: true
})

export const usePermissions = () => useContext(PermissionsContext)

export const PermissionsProvider = props => {
  const { authKey } = useUser()
  const [permissions, setPermissions] = useState(undefined)
  const [hasError, setError] = useState(false)
  const isLoading = useMemo(() => permissions === undefined && !hasError, [
    permissions,
    hasError
  ])

  useEffect(() => {
    PermissionsService.getPermissionsMap()
      .then(setPermissions)
      .catch(() => setError(true))
  }, [authKey])

  return (
    <PermissionsContext.Provider
      value={{
        permissions,
        hasError,
        isLoading
      }}
      {...props}
    />
  )
}

const permissionTranslations = {
  xpert_backoffice: 'Backoffice',
  xpert_agent: 'agente'
}

export const useRequirePermission = ({
  permission = 'xpert_backoffice',
  action = 'read',
  allowOnError = false
} = {}) => {
  const { permissions, hasError, isLoading } = usePermissions()
  const { add: addToast } = useToast()
  const { push } = useHistory()

  useEffect(() => {
    if (
      !isLoading &&
      (!hasError || !allowOnError) &&
      !permissions?.[permission]?.[action]
    ) {
      addToast(
        `Usuário sem permissão para as funcionalidades de ${
          permissionTranslations[permission] || permission
        }`
      )
      push('/')
    }
  }, [
    permissions,
    action,
    addToast,
    allowOnError,
    hasError,
    isLoading,
    permission,
    push
  ])
}

export const usePermission = ({
  permission = 'xpert_backoffice',
  action = 'read',
  allowOnError = false
} = {}) => {
  const { permissions, hasError, isLoading } = usePermissions()

  return {
    isLoading,
    allowed:
      (hasError && allowOnError) || permissions?.[permission]?.[action] || false
  }
}

import { useCallback, useEffect, useState } from 'react'
import { CompanyService } from 'core/services/Company'
import { useToast } from '@bonitour/components'

export const useBinamikCompanies = () => {
  const { add: addToast } = useToast()
  const [companiesData, setCompaniesData] = useState({ companies: undefined, meta: undefined })
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    currentPage: 1,
    perPage: 10
  })

  const fetchCompanies = useCallback(({ pagination }) => {
    setLoading(true)

    CompanyService.list({
      pagination
    })
      .then(what => {
        setCompaniesData(what)
      })
      .catch(() => {
        addToast('Erro inesperado')
      })
      .finally(() => {
        setLoading(false)
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchCompanies({
      pagination
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination])

  const handlePagination = useCallback((currentPage = 1, perPage = 10) => {
    if (currentPage === pagination.currentPage && perPage === pagination.perPage) {
      return
    }

    setPagination({
      currentPage,
      perPage
    })
  }, [pagination.currentPage, pagination.perPage])

  return {
    pagination,
    handlePagination,
    companies: companiesData?.companies,
    meta: companiesData?.meta,
    loading
  }
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useCallback, useMemo } from 'react'
import { marginTop, marginBottom, marginRight, required } from 'assets/styles/global'
import { identity } from '@bonitour/common-functions'
import { useForm } from '@bonitour/app-functions'
import { H3, colors, Textarea, GhostButton, flexRow, Button } from '@bonitour/components'
import { offlineExperienceComplementaryInfoSchema } from 'core/schemas/offlineExperienceComplementaryInfo.schema'
import { CheckListItems } from './CheckListItems'

const marginTop50 = marginTop(50)
const marginBottom20 = marginBottom(20)
const marginRight20 = marginRight(20)

const container = css`
  color: ${colors.gray3};
`

export const OfflineExperienceComplementaryInfoForm = ({
  offlineExperienceComplementaryInfo = {},
  paymentsTypeList,
  whatToTakeList,
  whatIsIncludedList,
  onBackClick: emitBackClickEvent = identity,
  onSuccess: emitSuccessEvent = identity,
  onValidationError: emitValidationError = identity
}) => {
  const {
    form,
    onSubmit,
    utils: { onInputChange }
  } = useForm(offlineExperienceComplementaryInfo, offlineExperienceComplementaryInfoSchema)

  const {
    description,
    observation,
    whatToTake = [],
    whatIsIncluded = [],
    paymentsTypes = []
  } = form

  const hasWhatToTakeList = useMemo(() => whatToTakeList.length > 0, [whatToTakeList])
  const hasWhatIsIncludedList = useMemo(() => whatIsIncludedList.length > 0, [whatIsIncludedList])
  const hasPaymentsTypeList = useMemo(() => paymentsTypeList.length > 0, [paymentsTypeList])

  const haveWhatToTake = useCallback(value => whatToTake.includes(value), [whatToTake])
  const haveWhatIsIncluded = useCallback(value => whatIsIncluded.includes(value), [whatIsIncluded])
  const havePaymentsTypes = useCallback(value => paymentsTypes.includes(value), [paymentsTypes])

  const handleWhatToTake = useCallback(value => () => {
    if (haveWhatToTake(value)) {
      onInputChange('whatToTake')(whatToTake.filter(item => item !== value))
    } else {
      onInputChange('whatToTake')([...whatToTake, value])
    }
  }, [haveWhatToTake, onInputChange, whatToTake])

  const handleWhatIsIncluded = useCallback(value => () => {
    if (haveWhatIsIncluded(value)) {
      onInputChange('whatIsIncluded')(whatIsIncluded.filter(item => item !== value))
    } else {
      onInputChange('whatIsIncluded')([...whatIsIncluded, value])
    }
  }, [haveWhatIsIncluded, onInputChange, whatIsIncluded])

  const handlePaymentsTypes = useCallback(value => () => {
    if (havePaymentsTypes(value)) {
      onInputChange('paymentsTypes')(paymentsTypes.filter(item => item !== value))
    } else {
      onInputChange('paymentsTypes')([...paymentsTypes, value])
    }
  }, [havePaymentsTypes, onInputChange, paymentsTypes])

  const onClick = onSubmit(emitSuccessEvent, emitValidationError)

  return (
    <div css={container}>
      <H3 css={marginBottom20}>Descrição<span css={required}>(Obrigatório)</span></H3>
      <Textarea onChange={onInputChange('description')} value={description} />

      <H3 css={[marginTop50, marginBottom20]}>Observações</H3>
      <Textarea onChange={onInputChange('observation')} value={observation} />

      <CheckListItems
        title='O que levar'
        handleFunction={handleWhatToTake}
        haveWhatList={haveWhatToTake}
        list={whatToTakeList}
        listLengthValidation={hasWhatToTakeList}
      />

      <CheckListItems
        title='Incluso no transporte'
        handleFunction={handleWhatIsIncluded}
        haveWhatList={haveWhatIsIncluded}
        list={whatIsIncludedList}
        listLengthValidation={hasWhatIsIncludedList}
      />

      <CheckListItems
        title='Pagamentos aceitos'
        handleFunction={handlePaymentsTypes}
        haveWhatList={havePaymentsTypes}
        list={paymentsTypeList}
        listLengthValidation={hasPaymentsTypeList}
      />

      <div css={[flexRow, marginTop50]}>
        <GhostButton css={marginRight20} onClick={emitBackClickEvent}>Voltar</GhostButton>
        <Button onClick={onClick}>Salvar</Button>
      </div>
    </div>
  )
}

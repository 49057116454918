import { useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useActivity } from 'contexts/Activity'
import { ACTIVITY_TYPE, COMBINED_EXPERIENCE_TYPE, OFFLINE_EXPERIENCE_TYPE, TRANSPORT_TYPE } from 'constants/activityTypes'

export const getCorrectPath = (type, route) => {
  switch (type) {
  case OFFLINE_EXPERIENCE_TYPE:
    if (route.endsWith('/bookings')) {
      return (`/offline-experience/${route}/create`)
    }
    return (`/offline-experience/${route}`)

  case COMBINED_EXPERIENCE_TYPE:
    return (`/combined-experience/${route}`)

  case TRANSPORT_TYPE:
    return (`/transport/${route}`)

  case ACTIVITY_TYPE:
  default:
    return (`/activity/${route}`)
  }
}

export const useRouteByType = (route) => {
  const { activity: { type, id } } = useActivity()

  const { replace } = useHistory()
  const { pathname, search } = useLocation()

  const correctPath = useMemo(() => getCorrectPath(type, route), [type, route])
  const basePath = useMemo(() => correctPath?.split('/').slice(0, 3).join('/'), [correctPath])

  useEffect(() => {
    if (correctPath !== pathname && pathname?.includes(id)) {
      replace({ pathname: correctPath, search })
    }
  }, [correctPath, replace, pathname, id, search])

  return { basePath }
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useHistory } from 'react-router-dom'
import { TransportBaseForm } from 'containers/TransportBaseForm/TransportBaseForm'
import { useTransportForm } from 'containers/TransportBaseForm/useTransportForm'
import { useToast } from '@bonitour/components'
import { useCallback, useState } from 'react'

import { TransportService } from 'services/Transport/Service'
import { usePermissions } from 'hooks/usePermissions'

export const CreateTransportInfo = () => {
  const [transportBase] = useState({
    phones: [{ name: '', number: '' }],
    emails: [{ name: '', email: '' }],
    sellingDeadline: 0
  })
  const { handlePermission } = usePermissions()
  const { add: addToast } = useToast()
  const { push } = useHistory()

  const onSuccess = useCallback(
    async (data) => {
      try {
        const { id } = await TransportService.create(data)
        if (id) {
          push(`/transport/${id}/edit?accordion=2`)
          addToast('Atividade criada com sucesso', 'success')
        }
      } catch (error) {
        const hasPermission = handlePermission(error, 'Você não possui permissão para criar um transporte')
        if (hasPermission) {
          const errorMessage = error?.data?.errors_msg
          const errorCode = error?.data?.errors
          const errorMessages = {
            'uc_create_service::permission': 'Você não pode criar transportes devido a restrições em seu plano',
            default: `Ocorreu um erro na criação do transporte. ${errorCode}`
          }
          addToast(errorMessages[errorMessage] ?? errorMessages.default)
        }
      }
    },
    [addToast, handlePermission, push]
  )

  const transportFormProps = useTransportForm({ transportBase, onSuccess, addToast })

  return <TransportBaseForm {...transportFormProps} />
}

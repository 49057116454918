import { string, date, array, object, lazy } from 'yup'
import moment from 'moment'

const yesterday = moment().subtract(1, 'days')

export const noticeSchema = {
  companyId: string().required('Selecione um Fornecedor'),
  serviceIds: array().min(1, 'Preencha com pelo menos uma atividade').required('Preencha com alguma atividade'),
  hours: lazy((value) => {
    if (!value) {
      return object().shape({})
    }

    // eslint-disable-next-line no-var
    var shape = {}

    const servicesIds = Object.keys(value)

    if (servicesIds.length < 1) {
      return object().shape({
        general: string().required('Você deve selecionar pelo menos uma atividade que contenha um horário')
      })
    }

    servicesIds.forEach((key) => {
      shape[key] = array().of(string()).min(1, 'Você deve selecionar pelo menos um horário')
    })

    return object().shape(shape)
  }),
  date: date().required('Preencha a data').min(yesterday, 'Não é possível selecionar datas anteriores ao dia atual'),
  category: string().required('Preencha a categoria'),
  pushNotificationMessage: string().required('Preencha a mensagem apresentada na notificação Push'),
  message: string().required('Preencha a mensagem apresentada na notificação aberta')
}

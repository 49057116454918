export const partnershipFormatOptions = [
  {
    label: 'CSV',
    value: 'csv'
  },
  {
    label: 'XLSX',
    value: 'xlsx'
  }
]

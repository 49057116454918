import { parseVacancy, parseVacancyList } from './Parser'
import { orbCore } from 'services/Orb/Service'
import { vacancyModel } from './Model'
import { CompanyStore } from 'core/store/Company'

const { vacancyDomain } = orbCore

export const ActivityVacancyService = {
  get (id) {
    return vacancyDomain.get(id)
      .then(parseVacancyList)
  },
  getById (vacancyId) {
    return vacancyDomain.getById(vacancyId)
      .then(parseVacancy)
  },
  remove (activityId, id) {
    return vacancyDomain.remove(activityId, id)
  },
  create (activityId, data) {
    const companyId = CompanyStore.getId()
    return vacancyDomain.create(vacancyModel(activityId, companyId, data))
  },
  update (activityId, vacancyId, data) {
    const companyId = CompanyStore.getId()
    return vacancyDomain.update(vacancyModel(activityId, companyId, data), vacancyId)
  }
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { ReservationHistory } from 'containers/Reservation/ReservationHistory'
import { useReservationHistory } from './hooks/useReservationHistory'
import { ReservationHistorySearch } from './ReservationHistorySearch'

export const ReservationHistoryApp = ({ info }) => {
  const { history, getHistory, loading } = useReservationHistory()

  return (
    <>
      <ReservationHistorySearch onReservationSearch={getHistory} createdAt={info?.createdAt} />
      <ReservationHistory reservationHistory={history} loading={loading} />
    </>
  )
}

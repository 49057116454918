/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Card, colors } from '@bonitour/components'
import { SwatchImage } from 'components/SwatchImage'
import { formatMoney } from '@bonitour/common-functions'
import { companyNameStyle, lastModificationStyle, valueLabelStyle, valueStyle, swatchImageStyle, alignLeft, accountTypeStyle } from '../List/PartnershipAccountCard.styles'
import { contentContainer, card } from '../Edit/PartnershipTransactions.styles'

export const PartnershipAccountBalance = ({ partnershipAccount, isDebtor = false }) => {
  const { partnerName, partnerImage, creditorName, creditorImage, balance, lastModification } = partnershipAccount || {}
  const balanceLabel = balance >= 0 ? 'em crédito' : 'em débito'

  return (
    <Card css={card}>
      <SwatchImage
        css={swatchImageStyle}
        size={80}
        image={isDebtor ? creditorImage : partnerImage}
        alternativeText={isDebtor ? creditorName : partnerName}
      />
      <div css={contentContainer}>
        <p css={[companyNameStyle(0), alignLeft]}>{isDebtor ? creditorName : partnerName}</p>
        <p css={[accountTypeStyle(0), alignLeft]}>{isDebtor ? 'Crédito recebido' : 'Crédito concedido'}</p>
        <p css={valueStyle(10, colors.primary)}>{formatMoney(balance)}<span css={valueLabelStyle(colors.gray5)}> {balanceLabel}</span></p>
        {lastModification &&
          <>
            <p css={lastModificationStyle(colors.gray3)}>Última modificação:</p>
            <p css={lastModificationStyle(colors.gray3)}>{lastModification}</p>
          </>}
      </div>
    </Card>
  )
}

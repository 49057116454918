import moment from 'moment'
import { metaModel } from './meta'

/* eslint-disable camelcase */
export const CashierModel = {
  list: ({ filters, pagination }) => {
    return {
      searchParams: CashierSearchParamsModel(filters),
      pagination: metaModel(pagination)
    }
  },
  closeCashier: (payload) => {
    const {
      cashierId,
      operatorLogin,
      operatorPassword,
      supervisorLogin,
      supervisorPassword
    } = payload ?? {}

    return {
      cashier_id: cashierId,
      operator_login: operatorLogin,
      operator_password: operatorPassword,
      supervisor_login: supervisorLogin,
      supervisor_password: supervisorPassword
    }
  }
}

export const CashierSearchParamsModel = (filterParams = {}) => {
  const {
    cashierType = '',
    status = '',
    user = '',
    initialDate = '',
    finalDate = ''
  } = filterParams

  return {
    cashier_type: cashierType,
    cashier_status: status,
    user_id: user,
    start_date: initialDate ? moment(initialDate).format('DD-MM-YYYY') : '',
    end_date: finalDate ? moment(finalDate).format('DD-MM-YYYY') : ''
  }
}

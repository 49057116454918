/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Checkbox, colors, scrollBar, TableBody, TableBodyCell, TableBodyRow, TableContainer, TableHeader, TableHeaderCell, TableHeaderRow, Label } from '@bonitour/components'
import { formatMoney, head, identity } from '@bonitour/common-functions'
import { TicketDetail } from 'containers/Reservation/TicketDetail/TicketDetail'
import { useMemo } from 'react'

const labelStyle = css`
  margin-bottom: 20px;
  font-weight: bold;
  color: ${colors.gray3};
`

const ticketsSelectedLabelStyle = css`
  margin-top: 20px;
  color: ${colors.gray3};
`

const ticketsSelectedTotalValueStyle = css`
  font-weight: bold;
  color: ${colors.primary};
`

const scrollTable = css`
  max-height: 400px;
  overflow: auto;
  ${scrollBar(5)};
`

const checkboxStyle = css`
  :disabled {
    background: ${colors.gray8};
  }
`

const checkboxCell = css`
  padding: 5px;
`

const maxWidthLabel = css`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 85px;
  white-space: nowrap;
`

const popoverPositionStyle = css`
  width: 300px;
  padding: 20px;
  top: -270px !important;
  left: -165px !important;
`

const valueColor = color => css`
  color: ${color};
`

const warnStyle = css`
  border: 2px solid ${colors.yellow2};
  color: ${colors.yellow2};
  background-color: ${colors.yellow3};
`

const priceSuffix = css`
  margin-left: 0.4rem;
  padding: 0.2rem;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  border-radius: 12px;
`

const canceledSplitLinksSelectedLabelStyle = css`
  margin: 0;
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 12px;
`

export const ReservationBalanceSelectionTickets = ({
  tickets = [],
  label = '',
  isTicketToPay = false,
  isTicketSelected,
  isSelectedAll,
  isSelectAllDisabled,
  isTicketSelectDisabled,
  ticketsSelectedQuantity = 0,
  ticketsSelectedTotalValue = 0,
  canceledSplitTicketsSelectedQuantity = 0,
  onSelectedAllClick: emitSelectedAllClick = identity,
  onSelectedClick: emitSelectedClick = identity
}) => {
  const hasTickets = useMemo(() => tickets?.length > 0, [tickets])
  const ticketsSelectedLabel = useMemo(() => `${ticketsSelectedQuantity} ${ticketsSelectedQuantity === 1
    ? 'ingresso selecionado'
    : 'ingressos selecionados'} com valor total de `, [ticketsSelectedQuantity])

  return (
    <>
      <p css={labelStyle}>{label}</p>

      <div css={scrollTable}>
        <TableContainer>
          <TableHeader>
            <TableHeaderRow>
              <TableHeaderCell css={checkboxCell}>
                {hasTickets &&
                  <Checkbox
                    css={checkboxStyle}
                    checked={isSelectedAll}
                    onClick={emitSelectedAllClick}
                    disabled={isSelectAllDisabled}
                  />}
              </TableHeaderCell>
              <TableHeaderCell>Empresa</TableHeaderCell>
              <TableHeaderCell>Experiência</TableHeaderCell>
              <TableHeaderCell>Data da experiência</TableHeaderCell>
              <TableHeaderCell>Valor</TableHeaderCell>
            </TableHeaderRow>
          </TableHeader>
          <TableBody>
            {tickets.map((ticket, index) => {
              const { id, company, activity, date, value, ticketPaymentBalance } = ticket
              const { alreadyPaidTicketValue, historic, remainingTicketValue, ticketPrice } = head(ticketPaymentBalance) || {}
              const hasAlreadyPaidTicketValue = alreadyPaidTicketValue > 0
              return (
                <TableBodyRow key={index}>
                  <TableBodyCell css={checkboxCell}>
                    <Checkbox
                      css={checkboxStyle}
                      checked={isTicketSelected(id)}
                      onClick={emitSelectedClick(ticket)}
                      disabled={isTicketSelectDisabled(ticket)}
                    />
                  </TableBodyCell>
                  <TableBodyCell>
                    <span css={maxWidthLabel}>{company}</span>
                  </TableBodyCell>
                  <TableBodyCell>
                    <span css={maxWidthLabel}>{activity}</span>
                  </TableBodyCell>
                  <TableBodyCell>{date}</TableBodyCell>
                  <TableBodyCell>
                    {hasAlreadyPaidTicketValue
                      ? (
                        <TicketDetail
                          value={value}
                          ticketPrice={ticketPrice}
                          alreadyPaidTicketValue={alreadyPaidTicketValue}
                          remainingTicketValue={remainingTicketValue}
                          historic={historic}
                          popoverPositionStyle={popoverPositionStyle}
                        />)
                      : <span css={isTicketToPay ? valueColor(colors.red1) : valueColor(colors.green1)}>{formatMoney(value)}</span>}
                    {ticket?.isLimitedTransferSplitPayment && <span css={[warnStyle, priceSuffix]}>Split</span>}
                  </TableBodyCell>
                </TableBodyRow>
              )
            })}
          </TableBody>
        </TableContainer>
      </div>

      {ticketsSelectedQuantity > 0 &&
        <p css={ticketsSelectedLabelStyle}>
          <span>{ticketsSelectedLabel}</span>
          <span css={ticketsSelectedTotalValueStyle}>{formatMoney(ticketsSelectedTotalValue)}</span>
        </p>}
      {canceledSplitTicketsSelectedQuantity > 0 &&
        <Label css={[warnStyle, canceledSplitLinksSelectedLabelStyle]}>
          Transferência de saldo só pode ser feita para um ingresso de maior ou igual valor.
        </Label>}
    </>
  )
}

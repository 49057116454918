import { css } from '@emotion/core'
import { colors } from '@bonitour/components'

export const container = css`
  min-width: 180px;
  padding: 10px;
`

export const overflowLink = css`
  text-decoration: none;
`

export const imageContainer = css`
  width: 60px;
  height: 60px;
  background-color: ${colors.gray12};
  font-size: 32px;
  font-weight: bold;
  text-transform: capitalize;
  color: ${colors.gray4};
`

export const nameStyle = css`
  font-size: 18px;
  font-weight: bold;
  color: ${colors.gray3};
  margin-top: 10px;
`

export const emailStyle = css`
  margin-top: 5px;
  color: ${colors.gray5};
`

export const shortcutLabel = css`
  font-size: 12px;
  color: ${colors.gray5};
`

export const flexStart = css`
  align-items: flex-start;
`

export const itemStyle = css`
  margin-top: 10px;
  font-weight: 800;
  color: ${colors.gray5};
  cursor: pointer;
`

export const iconStyle = css`
  margin-right: 10px;
  font-size: 24px;

  .b-font-logout {
    transform: scale(1.15) translateX(1px);
    -webkit-text-stroke: 0.05px;
  }

  .b-font-users {
    transform: scale(1.25) translateY(1px);
    -webkit-text-stroke: 0.15px;
  }

  .b-font-account {
    position: relative;
    &::after {
      content: '';
      display: block;
      width: 5px;
      height: 5px;
      border: 1px solid ${colors.gray5};
      border-radius: 1px;
      position: absolute;
      top: 2px;
      right: 1px;
      transform: rotate(10deg);
      border-top: none;
      border-left: none;
      box-shadow: 3px 3px 0px 0px ${colors.white};
    }
  }
`

export const profileModeStyle = css`
  font-size: 12px;
  color: ${colors.gray5};
  margin-top: 5px;
  font-weight: 500;
`

export const profileCompanyStyle = css`
  ${profileModeStyle}
  color: ${colors.gray4};
  font-weight: 700;
  font-size: 14px;
  margin-top: 15px;
  max-width: 220px;
`

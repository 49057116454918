/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx, css } from '@emotion/core'
import { Button, flexHorizontallyCentered, GhostButton, Modal } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { EditPayment } from 'containers/Invoice/EditPayment'

const buttonContainer = css`
  margin-top: 30px;
  justify-content: flex-end;
`

const marginRight20 = css`
  margin-right: 20px;
`

export const InvoiceEditPaymentModal = ({
  vendorId = '',
  companyId = '',
  isInvoicePaymentVisible = false,
  invoicePayment = {},
  onCloseClick = identity,
  onChange = identity,
  onConfirm = identity,
  invoiceReceive = false
}) => {
  return (
    <Modal isVisible={isInvoicePaymentVisible} title='Edição' onCloseClick={onCloseClick}>
      <EditPayment
        vendorId={vendorId}
        companyId={companyId}
        paymentInfo={invoicePayment}
        onChange={onChange}
        isDisabled
        invoiceReceive={invoiceReceive}
      />
      <div css={[flexHorizontallyCentered, buttonContainer]}>
        <GhostButton css={marginRight20} onClick={onCloseClick}>Cancelar</GhostButton>
        <Button onClick={onConfirm}>Salvar</Button>
      </div>
    </Modal>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { TabPane, TabContainer, NavContainer, NavItem, HeaderPage } from '@bonitour/components'
import { marginBottom } from 'assets/styles/global'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'hooks/useQuery'
import { Vouchers } from './VouchersList/Vouchers'
import { GeneratedBatches } from './GeneratedBatchesList/GeneratedBatches'

const marginBottom40 = marginBottom(40)

const noIndexStyle = css`
  z-index: 0;
`

export const TourGuidePayment = () => {
  const { push } = useHistory()
  const [{ selectedNav = 'vouchers' }] = useQuery()
  const setSelectedNav = (value) => push(`?selectedNav=${value}`)
  const goToDashboard = () => push('/dashboard')

  return (
    <>
      <HeaderPage
        onBack={goToDashboard}
        title='Faturas em lote - Guia'
        subtitle='Repasse guia'
      />

      <NavContainer selectedNav={selectedNav} onChange={setSelectedNav}>
        <NavItem navId='vouchers' css={noIndexStyle}>Vouchers</NavItem>
        <NavItem navId='generated-batches' css={noIndexStyle}>Lotes gerados</NavItem>
      </NavContainer>
      <TabContainer activeTab={selectedNav}>
        <TabPane tabId='vouchers' css={marginBottom40} alwaysRerender>
          <Vouchers />
        </TabPane>
        <TabPane tabId='generated-batches' css={marginBottom40}>
          <GeneratedBatches />
        </TabPane>
      </TabContainer>
    </>
  )
}

import { object, string, array } from 'yup'
import { contactPhoneSchema } from 'containers/ContactForm/ContactPhone/ContactPhone.rules'
import { addressSchema } from 'containers/Address/Address.rules'
import { contactEmailSchema } from 'containers/ContactForm/ContactEmail/ContactEmail.rules'
import { unformatMoney } from '@bonitour/common-functions'

export const offlineExperienceInfoSchema = (states = [], cities = []) => ({
  serviceId: object().shape({
    name: string().required('O nome da experiência offline é obrigatório'),
    offlineProviderId: string().nullable(),
    category: string().required('A categoria é obrigatória'),
    defaultValue: string().nullable(),
    defaultNetValue: string().nullable().test({
      name: 'test-net-value',
      message: 'O valor net deve ser menor ou igual ao valor para o turista',
      test: function (defaultNetValue) {
        if (!this.parent?.defaultValue) return
        return unformatMoney(defaultNetValue) <= unformatMoney(this.parent.defaultValue)
      }
    })
  }),
  phones: array().of(object().shape(contactPhoneSchema)),
  emails: array().of(object().shape(contactEmailSchema)),
  addressInfo: object().shape(addressSchema(states, cities))
})

import { useActivity } from 'contexts/Activity'
import { useCallback, useEffect, useState } from 'react'

const getUniqueValues = (arr) =>
  arr.filter((item, index, self) => self.find(({ value }) => value === item.value) === item)

export const usePartnerActivityOptions = () => {
  const { list, getActivitiesByPartnerId, partners } = useActivity()
  const [partnerOptions, setPartnerOptions] = useState([])
  const [activityOptions, setActivityOptions] = useState([])
  const [partner, setPartner] = useState([])

  const returnedGetActivityOptions = useCallback(
    (newPartner) => {
      if (JSON.stringify(partner) !== JSON.stringify(newPartner)) {
        setPartner(newPartner)
      }
    },
    [partner]
  )

  useEffect(() => {
    const partners = list.map(({ companyId, companyName }) => ({ value: companyId, label: companyName }))
    partners.length && setPartnerOptions([...getUniqueValues(partners)])
    getActivityOptions()
    // eslint-disable-next-line
  }, [list])

  const activitiesMap = useCallback((activitiesList) => {
    const activitiesMapped = getUniqueValues(activitiesList.map(({ id, name }) => ({ value: id, label: name })))
    return activitiesMapped
  }, [])

  const mapOptions = (options) => {
    const mapedOptions = getUniqueValues(
      options.map(({ companyId, companyName }) => ({ value: companyId, label: companyName }))
    )
    return mapedOptions
  }

  const getActivityOptions = useCallback(
    (partner) => {
      if (partner && (!Array.isArray(partner) || partner.length)) {
        const partnerActivities = Array.isArray(partner)
          ? partner.map((partnerId) => getActivitiesByPartnerId(partnerId)).flat()
          : getActivitiesByPartnerId(partner)
        partnerActivities.length ? setActivityOptions(activitiesMap(partnerActivities)) : setActivityOptions([])
      } else {
        list.length ? setActivityOptions(activitiesMap(list)) : setActivityOptions([])
      }
    },
    [activitiesMap, getActivitiesByPartnerId, list]
  )

  useEffect(() => {
    getActivityOptions(partner)
  }, [getActivityOptions, list, partner])

  return {
    partnerOptions,
    getActivityOptions: returnedGetActivityOptions,
    activityOptions,
    partners: mapOptions(partners)
  }
}

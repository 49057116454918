import { orbCore } from 'services/Orb/Service'
import { SaleParser } from 'core/parsers/sale'
import { SaleModel } from 'core/models/sale'
import { VoucherReportParser } from 'core/parsers/voucherReport'
import { VoucherReportModel } from 'core/models/voucherReport'
import { RevenueReportParser } from 'core/parsers/revenueReport'
import { RevenueReportModel } from 'core/models/revenueReport'

import { PendingTicketsReportParser } from 'core/parsers/pendingTicketsReportParser'
import { PendingTicketsReportModel } from 'core/models/pendingTicketsReport'
import { PaidTicketsReportModel } from 'core/models/paidTicketsReport'
import { PaidTicketsReportParser } from 'core/parsers/paidTicketsReportParser'
import { RefundedTicketsModel } from 'core/models/refundedTickets'
import { RefundedTicketsParser } from 'core/parsers/refundedTickets'

import { TicketsReportModel } from 'core/models/reports'

const {
  saleReportDomain,
  refundedTicketsDomain,
  saleReportExportDomain,
  voucherReportDomain,
  voucherReportTotalsDomain,
  reservationRevenueDomain,
  pendingTicketsReportDomain,
  paidTicketsReportDomain
} = orbCore

export const ReportService = {
  listSales ({ filters, pagination }) {
    return saleReportDomain.listSales(SaleModel.list({ filters, pagination })).then(SaleParser.list)
  },
  listVouchers ({ filters, pagination }) {
    return voucherReportDomain
      .listVouchers(VoucherReportModel.list({ filters, pagination }))
      .then(VoucherReportParser.list)
  },
  listRevenue ({ filters, pagination }) {
    return reservationRevenueDomain
      .listRevenue(RevenueReportModel.list({ filters, pagination }))
      .then(RevenueReportParser.list)
  },
  listRevenueTotals ({ filters }) {
    return reservationRevenueDomain
      .listRevenueTotals(RevenueReportModel.totals({ filters }))
      .then(RevenueReportParser.totals)
  },
  listPendingTickets ({ filters, pagination }) {
    return pendingTicketsReportDomain
      .listPendingTickets(PendingTicketsReportModel.list({ filters, pagination }))
      .then(PendingTicketsReportParser.list)
  },
  listPaidTickets ({ filters, pagination }) {
    return paidTicketsReportDomain
      .listPaidTickets(PaidTicketsReportModel.list({ filters, pagination }))
      .then(PaidTicketsReportParser.list)
  },
  listRefundedTickets ({ filters, pagination }) {
    return refundedTicketsDomain
      .listRefunds(RefundedTicketsModel.list({ filters, pagination }))
      .then(RefundedTicketsParser.list)
  },
  totalRefundedTickets ({ filters }) {
    return refundedTicketsDomain.totalizers(RefundedTicketsModel.totals({ filters })).then(RefundedTicketsParser.totals)
  },
  vouchersTotals ({ filters }) {
    return voucherReportTotalsDomain.totals(VoucherReportModel.list({ filters })).then(VoucherReportParser.totals)
  },
  paidTicketsTotals ({ filters }) {
    return paidTicketsReportDomain
      .totals(PaidTicketsReportModel.totals({ filters }))
      .then(PaidTicketsReportParser.totals)
  },
  csvExport ({ filters }) {
    return saleReportExportDomain.csvExport(SaleModel.csvExport({ filters }))
  },
  listPaidAttributesToExport ({ lang }) {
    return paidTicketsReportDomain.listAttributesToExport(lang)
  },
  getPaidAttributesToExport ({ format, wantedFields, filters = {} }) {
    return paidTicketsReportDomain.getAttributesToExport(
      TicketsReportModel.getAttributesToExport({
        format,
        wantedFields,
        filters
      })
    )
  },
  listRefundAttributesToExport ({ lang }) {
    return refundedTicketsDomain.listAttributesToExport(lang)
  },
  getRefundAttributesToExport ({ format, wantedFields, filters = {} }) {
    return refundedTicketsDomain.getAttributesToExport(
      TicketsReportModel.getAttributesToExport({
        format,
        wantedFields,
        filters
      })
    )
  }
}

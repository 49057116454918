import React, { createContext, useContext } from 'react'
import { useReservation } from 'hooks/context/Reservation'
import { useQuery } from 'react-query'
import { ReservationTotalizersService } from 'services/Reservations/Service'

const PaymentsTotalizersContext = createContext({})

export const usePaymentsTotalizers = () => {
  const context = useContext(PaymentsTotalizersContext)
  if (!context) {
    throw new Error('usePaymentsTotalizers must be used within a PaymentsTotalizersContext')
  }
  return context
}

export const PaymentsTotalizersProvider = ({ children }) => {
  const { reservationId } = useReservation()

  const {
    data: totalizers,
    isLoading,
    isFetching,
    refetch: refetchTotalizers = () => Promise.resolve()
  } = useQuery(`reservationPaymentsTotalizers, R:${reservationId}`, () => {
    if (!reservationId) return

    return ReservationTotalizersService.getFinancialTransactionsTotalizers(reservationId)
  }, {
    refetchOnWindowFocus: true
  })

  return (
    <PaymentsTotalizersContext.Provider value={
      {
        totalizers,
        isLoading: isLoading || isFetching,
        refetchTotalizers
      }
    }>
      {children}
    </PaymentsTotalizersContext.Provider>
  )
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { H3, Card, ToggleInputGroup, Select, P, RadioInputGroup, RadioGroup, Input } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { marginTop, marginBottom } from 'assets/styles/global'
import { mapDaysOptions, workingDaysOptions } from 'constants/weekDaysOptions'
import { seasonalPeriodOptions } from 'constants/seasonalPeriodOptions'
import { cardStyle, selectStyle, marginSide, grayColor, horinzontalMargin10, width110, boldText, line, radioGroup } from './InvoicePolicy.style'
import { useEffect } from 'react'

const marginTop20 = marginTop(20)
const marginTop30 = marginTop(30)
const marginBottom20 = marginBottom(20)
const selectStyle100 = selectStyle(100)
const selectStyle30 = selectStyle(30)

const Deadline = ({
  deadline = 0,
  deadlineType = '',
  enabledValue = 1,
  disabledValue = 0,
  isFormDisabled = false,
  onChange: emitChangeEvent
}) => {
  const hasValue = deadline > 0
  const resetValue = () => (hasValue ? disabledValue : enabledValue)
  const toggleValue = () => emitChangeEvent('deadline')(resetValue())
  const isBeforeSlot = deadlineType === 'before_slot'
  const isAfterCreation = deadlineType === 'after_creation'

  useEffect(() => {
    if (deadline === 0) {
      emitChangeEvent('deadlineType')('')
    }
    // eslint-disable-next-line
  }, [deadline])

  return (
    <div>
      {!isFormDisabled && (
        <div css={marginBottom20}>
          <ToggleInputGroup id='deadline' disabled={isFormDisabled} onChange={toggleValue} checked={hasValue}>Utilizar deadline</ToggleInputGroup>
        </div>
      )}
      {isFormDisabled && deadline === 0 && <P>Não há deadline</P>}
      {hasValue && (
        <div css={line}>
          <RadioGroup css={radioGroup} name='deadlineType' value={deadlineType} onChange={emitChangeEvent('deadlineType')} disabled={isFormDisabled}>
            <RadioInputGroup id='before_slot' value='before_slot'>
              <span css={grayColor}>O pagamento deverá ser feito em até</span>
              <Input
                type='number'
                min='1'
                value={isBeforeSlot ? deadline : 0}
                css={[horinzontalMargin10, width110]}
                onChange={emitChangeEvent('deadline')}
                disabled={!isBeforeSlot || isFormDisabled}
              />
              <span css={grayColor}>{`${deadline > 1 ? 'dias' : 'dia'} antes da execução da experiência`}</span>
            </RadioInputGroup>
            <RadioInputGroup id='after_creation' value='after_creation'>
              <span css={grayColor}>O pagamento deverá ser feito em até</span>
              <Input
                type='number'
                min='1'
                value={isAfterCreation ? deadline : 0}
                css={[horinzontalMargin10, width110]}
                onChange={emitChangeEvent('deadline')}
                disabled={!isAfterCreation || isFormDisabled}
              />
              <span css={grayColor}>{`${deadline > 1 ? 'dias' : 'dia'} após efetuar a reserva`}</span>
            </RadioInputGroup>
          </RadioGroup>
        </div>
      )}
    </div>
  )
}

export const InvoicePolicy = ({
  invoicePolicy = {},
  isFormDisabled = false,
  invoicePolicyErrors = {},
  onInputBlur: emitInputBlur = () => identity,
  onChange: emitChangeEvent = () => identity,
  isGroupEditable = false
}) => {
  const {
    invoiced = false,
    deadline = 0,
    deadlineType = '',
    closingPeriod = '',
    closingDay = '',
    paymentDay = '',
    invoice: {
      noShow = false
    } = {}
  } = invoicePolicy

  const isFieldEditable = !isGroupEditable

  const toggleInvoice = (value) => {
    if (value === 'true') {
      emitChangeEvent('invoiced')(true)
    } else {
      emitChangeEvent('invoiced')(false)
    }
  }

  const changeClosingPeriod = (value) => {
    emitChangeEvent('closingPeriod')(value)
    emitChangeEvent('closingDay')('')
  }

  const toggleCheckbox = (name, param) => () => emitChangeEvent(name)(!param)
  const daysOptions = mapDaysOptions[closingPeriod || ''] || []

  return (
    <div css={grayColor}>
      <H3 css={marginBottom20}>Fechamento</H3>
      <div css={line}>
        <span>O fechamento da fatura será</span>
        <Select
          css={[selectStyle100, marginSide]}
          options={seasonalPeriodOptions}
          value={closingPeriod}
          disabled={isFieldEditable}
          error={invoicePolicyErrors.closingPeriod}
          onChange={changeClosingPeriod}
          onBlur={emitInputBlur('closingPeriod')}
        />
        <span>na(o)</span>
        <Select
          css={[selectStyle100, marginSide]}
          options={daysOptions}
          value={closingDay}
          disabled={isFieldEditable}
          error={invoicePolicyErrors.closingDay}
          onChange={emitChangeEvent('closingDay')}
          onBlur={emitInputBlur('closingDay')}
        />
        <span>e o pagamento será em até</span>
        <Select
          css={[selectStyle30, marginSide]}
          options={workingDaysOptions}
          value={paymentDay}
          disabled={isFieldEditable}
          error={invoicePolicyErrors.paymentDay}
          onChange={emitChangeEvent('paymentDay')}
          onBlur={emitInputBlur('paymentDay')}
        />
        <span>dias úteis</span>
      </div>

      <H3 css={[marginTop20, marginBottom20]}>Deadline</H3>

      <Deadline
        deadline={deadline}
        deadlineType={deadlineType}
        isFormDisabled={isFieldEditable}
        onChange={emitChangeEvent}
      />

      <H3 css={[marginTop30, marginBottom20]}>Pagamento</H3>
      {
        !isFormDisabled && (
          <RadioGroup name='regular' value={invoiced} onChange={toggleInvoice} disabled={isFieldEditable}>
            <RadioInputGroup id='antecipado' value={false}>Antecipado</RadioInputGroup>
            <RadioInputGroup id='faturado' value>Faturado</RadioInputGroup>
          </RadioGroup>
        )
      }

      {isFormDisabled && (
        <P>
          <span css={boldText}>
            {'Tipo de pagamento: '}
          </span>
          {invoiced ? 'Faturado' : 'Antecipado'}
        </P>
      )}

      {invoiced && (
        <Card css={cardStyle}>
          <H3 css={marginBottom20}>Garantia</H3>

          <ToggleInputGroup
            id='payment-guarantee-no-show'
            checked={noShow}
            onChange={toggleCheckbox('invoice.noShow', noShow)}
            disabled={isFieldEditable}
          >
            Terá garantia de no-show
          </ToggleInputGroup>
        </Card>
      )}
    </div>
  )
}

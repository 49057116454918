import { unformatMoney } from '@bonitour/common-functions'
import moment from 'moment'
import { metaModel } from './meta'
import { deepGetOrElse } from 'deep-getter'

/* eslint-disable camelcase */
export const PartnershipAccountModel = {
  entry: (entry = {}) => {
    const {
      seller: debtor_id,
      type: transaction_type,
      destinationBankAccount: destination_bank_account_id,
      originBankAccount: origin_bank_account_id,
      paymentType: payment_type,
      value,
      description,
      upload
    } = entry

    return {
      debtor_id,
      transaction_type,
      destination_bank_account_id,
      origin_bank_account_id,
      payment_type,
      value: unformatMoney(value).toFixed(2),
      description,
      attachments: [deepGetOrElse(upload, '0.file', null)]
    }
  },
  update: (transactionInfo) => {
    const {
      paymentType: payment_type,
      destinationBankAccount: destination_bank_account_id,
      originBankAccount: origin_bank_account_id,
      seller: id,
      description,
      upload
    } = transactionInfo

    return {
      payment_type,
      destination_bank_account_id,
      origin_bank_account_id,
      id,
      description,
      attachments: [deepGetOrElse(upload, '0.file', null)]
    }
  },
  listTransactions: ({ pagination, startDate, endDate, paymentType }) => {
    const paginationParams = metaModel(pagination)
    const safeStartDate = startDate || ''
    const safeEndDate = endDate || ''

    return {
      ...(safeStartDate ? { start_date: safeStartDate } : {}),
      ...(safeEndDate ? { end_date: safeEndDate } : {}),
      ...(paymentType ? { polymorphic_type: paymentType } : {}),
      ...paginationParams
    }
  },
  getTotalizers: ({ startDate, endDate, paymentType }) => {
    const safeStartDate = startDate || ''
    const safeEndDate = endDate || ''

    return {
      ...(safeStartDate ? { start_date: safeStartDate } : {}),
      ...(safeEndDate ? { end_date: safeEndDate } : {}),
      ...(paymentType ? { polymorphic_type: paymentType } : {})
    }
  },
  list: ({ filters, pagination }) => {
    const params = [
      PartnershipAccountSearchParamsModel(filters),
      metaModel(pagination)
    ]
    return params
  },
  partnershipAccountExport: ({ filters = {}, pagination = {} }) => {
    const {
      startDate: start_date,
      endDate: end_date,
      wantedFields: wanted_fields
    } = filters

    const meta = metaModel(pagination)

    const searchParams = {
      start_date: start_date ? moment(start_date).format('YYYY-MM-DD') : undefined,
      end_date: end_date ? moment(end_date).format('YYYY-MM-DD') : undefined,
      wanted_fields
    }

    return { searchParams, pagination: meta }
  }
}

export const PartnershipAccountSearchParamsModel = (filterParams = {}) => {
  const { partnerId = '' } = filterParams
  return { partner_id: partnerId }
}

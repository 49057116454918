import { orbCore } from 'services/Orb/Service'
import { BankAccountModel } from 'core/models/bankAccount'
import { BankAccountParser } from 'core/parsers/bankAccount'

const { bankAccountDomain, bankAccountByPaymentMethodDomain } = orbCore

export const BankAccountService = {
  create (bankAccount) {
    return bankAccountDomain.create(BankAccountModel.single(bankAccount))
  },
  list ({ pagination }) {
    return bankAccountDomain.list(...BankAccountModel.list({ pagination })).then(BankAccountParser.list)
  },
  getById (bankAccountId) {
    return bankAccountDomain.getById(bankAccountId).then(BankAccountParser.single)
  },
  update (bankAccount, bankAccountId) {
    return bankAccountDomain.update(BankAccountModel.single(bankAccount), bankAccountId)
  },
  toggleEnabled (bankAccountId) {
    return bankAccountDomain.toggleEnabled(bankAccountId)
  },
  getBankAccountByPaymentMethod ({ paymentMethod, companyId }) {
    return bankAccountByPaymentMethodDomain.getBankAccountByPaymentMethod(paymentMethod, companyId).then(BankAccountParser.options)
  }
}

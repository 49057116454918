import { css } from '@emotion/core'
import { colors, BREAK_POINTS } from '@bonitour/components'

export const card = css`
  margin-top: 20px;
  padding: 20px;
  color: ${colors.gray3};

  @media(max-width: ${BREAK_POINTS.smallTablet}){
    background: none;
    border: none;
    padding: 0;
  }
`

export const saveButton = css`
  margin-top: 20px;

  @media(max-width: ${BREAK_POINTS.smallTablet}){
    width: 100%;
  }
`

export const deleteButton = css`
  margin-top: 20px;
  margin-left: 10px;
  background-color: ${colors.white};

  @media(max-width: ${BREAK_POINTS.smallTablet}){
    width: 100%;
    margin-top: 10px;
    margin-left: 0;
  }
`

export const line = css`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
  font-size: 14px;

  &:nth-of-type(odd){
    border-radius: 10px;
    background-color: ${colors.gray14};
  }

  @media(max-width: ${BREAK_POINTS.smallTablet}){
    &:nth-of-type(odd){
      border-radius: 0;
      background: none;
    }
  }
`

export const timeInput = css`
  height: 50px;
  max-width: 82px;
  margin-right: 10px;
  border-radius: 10px;

  &:disabled, read-only {
    border: none;
    background: none;
    color: ${colors.gray3};
    -webkit-text-fill-color: ${colors.gray3};
  }

  @media(max-width: ${BREAK_POINTS.smallTablet}){
    &:disabled, read-only {
      margin-left: 18px;
    }
  }
`

export const inputContainer = css`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  @media(max-width: ${BREAK_POINTS.smallTablet}){
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;  

    &:last-of-type {
      margin-left: 0;
    }
  }
`

export const dailyLimitContainer = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 30px 10px 0px;
  background-color: ${colors.gray14};
  border-radius: 10px;
  font-size: 14px;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  @media (max-width: ${BREAK_POINTS.smallTablet}){
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    padding: 10px;
    background: none;

    input {
      width: 25%;
    }

    span {
      margin-right: 10px;
      font-size: 16px;
    }
  }
`

export const input = css`
  width: 70px;
  margin: 0 10px;
  border-radius: 10px;
  text-align: center;

  &:last-of-type {
    margin-left: 50px;
  }

  @media (max-width: ${BREAK_POINTS.smallTablet}){
    width: 100%;
    height: 50px;
    padding: 5px;

    &:last-of-type {
      margin: 0;
    }
  }
`

export const label = css`
  @media(max-width: ${BREAK_POINTS.smallTablet}){
    font-size: 10px;
    margin-top: 5px;
  }
`

export const trashWrapper = css`
  margin-right: 10px;
  font-size: 18px;
  color: ${colors.gray3};
  cursor: pointer;

  @media(max-width: ${BREAK_POINTS.smallTablet}){
    margin-right: 5px;
  }
`

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { AddLabel, GhostButton, InputFormGroup, MaskedInputWithSuffix, useToast, colors, RadioGroup, RadioInputGroup, scrollBar, Row, Button, LoadingAnimation, BREAK_POINTS } from '@bonitour/components'
import { useForm } from '@bonitour/app-functions'
import { string } from 'yup'
import { useEffect, useState } from 'react'
import { loadingContainer, margin, marginRight, marginTop } from 'assets/styles/global'
import { useKeyPress } from 'hooks/useKeyPress'
import { PassengerInfo } from './PassengerInfo'

const marginTop10 = marginTop(10)
const marginRight20 = marginRight(20)
const marginVertical20 = margin(20, 0, 20, 0)
const marginButtons = margin(40, 0, 0, 0)

const inputButton = css`
  width: 170px;
  margin: 5px 0px;
  padding: 5px 10px;
  font-size: 12px;
  color: ${colors.primary};
  border-color: ${colors.primary};

  @media (max-width: ${BREAK_POINTS.bigPhone}){
    width: 190px;
  }
`

const label = css`
  margin-top: 20px;
  font-size: 14px;
  color: ${colors.gray4};
`

const reservationPassengerContainer = css`
  max-height: 180px;
  overflow: auto;
  margin: 15px 0;
  ${scrollBar(5)};
`

const passengerSearchInitial = {}

const passengerSearchSchema = { email: string().email('O e-mail deve ser válido') }

export const SearchPassengers = ({
  passengersFound = [],
  passengerSelected = '',
  isSearchPassengerVisible = false,
  searchPassengerLoading = false,
  onSearchPassengersSubmit: emitSubmit = identity,
  onSearchPassengerVisible: emitSearchPassengerVisible = identity,
  onClickNewPassenger: emitClickNewPassenger = identity,
  onPassengerSelected: emitPassengerSelected = identity,
  onLinkPassenger: emitLinkPassenger = identity
}) => {
  const { add: addToast } = useToast()
  const { onKeyDown } = useKeyPress()
  const [isSearchClicked, setSearchClick] = useState(false)
  const { form, errors, onSubmit, utils: { onInputBlur, onInputChange } } = useForm(passengerSearchInitial, passengerSearchSchema)
  const { email = '' } = form

  const passengersFoundLength = passengersFound.length
  const isPassengersFound = passengersFoundLength > 0

  const onValidationError = () => addToast('Preencha corretamente o formulário')

  const onPassengerSearchSuccess = filters => {
    if (!filters?.email) return addToast('Informe um e-mail')
    setSearchClick(true)
    emitSubmit(filters)
  }

  const onClickSubmit = onSubmit(onPassengerSearchSuccess, onValidationError)

  const onBack = () => {
    emitPassengerSelected('')
    onInputChange('email')('')
    setSearchClick(false)
    emitSearchPassengerVisible(false)
  }

  const onCreatePassengerClick = () => {
    setSearchClick(false)
    emitSearchPassengerVisible(false)
    emitClickNewPassenger({ email })
  }

  useEffect(() => {
    emitPassengerSelected('')
    Boolean(email) && emitSearchPassengerVisible(true)
  }, [email, emitSearchPassengerVisible, emitPassengerSelected])

  return (
    <div css={marginTop10}>
      <InputFormGroup errorMessage={errors.email} label='Adicionar outro viajante'>
        <MaskedInputWithSuffix
          type='email'
          value={email}
          onChange={onInputChange('email')}
          onBlur={onInputBlur('email')}
          onKeyDown={event => onKeyDown(event, onClickSubmit)}
        >
          <GhostButton css={inputButton} onClick={onClickSubmit}>Buscar e-mail</GhostButton>
        </MaskedInputWithSuffix>
      </InputFormGroup>

      {searchPassengerLoading && <LoadingAnimation size={30} css={[loadingContainer, marginVertical20]} />}

      {isSearchPassengerVisible &&
        <>
          {isSearchClicked && !searchPassengerLoading &&
            <>
              {isPassengersFound &&
                <>
                  <p css={label}>{`${passengersFoundLength} viajante(s) encontrado(s) com este e-mail:`}</p>

                  <div css={reservationPassengerContainer}>
                    <RadioGroup name='reservation-passengers-found' value={passengerSelected} onChange={emitPassengerSelected}>
                      {passengersFound.map((passenger) => (
                        <RadioInputGroup key={passenger?.id} id={passenger?.id} value={passenger?.id}>{PassengerInfo(passenger)}</RadioInputGroup>
                      ))}
                    </RadioGroup>
                  </div>
                </>}

              {!isPassengersFound &&
                <>
                  <p css={label}>Nenhum viajante encontrado com este e-mail</p>
                  <AddLabel onClick={onCreatePassengerClick}>Cadastrar novo viajante</AddLabel>
                </>}
            </>}

          <Row css={marginButtons}>
            <GhostButton css={marginRight20} onClick={onBack}>Voltar</GhostButton>
            <Button onClick={emitLinkPassenger} disabled={!passengerSelected}>Vincular</Button>
          </Row>
        </>}
    </div>
  )
}

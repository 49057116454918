/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { Column, Input, InputFormGroup, Row } from '@bonitour/components'
import { rowStyle } from '../OfflineExperiencesTicketForm.style'
import { AgentInput, CountInputFormGroup, NetPriceInputFormGroup, ObservationRowInput, PriceInputFormGroup, SlotInputFormGroup, UnitNetPriceInputFormGroup, UnitTotalPriceInputFormGroup } from './CommonFormFields'

export const OtherForm = ({
  isEditing = false,
  form,
  errors,
  onInputChange = identity,
  onInputBlur = identity,
  disableTotalPrice = false,
  disabled = false
}) => {
  return (
    <>
      {!isEditing && !disabled
        ? <AgentInput errors={errors} form={form} onInputChange={onInputChange} onInputBlur={onInputBlur} />
        : null}

      <Row
        css={rowStyle}
      >
        <SlotInputFormGroup
          form={form}
          errors={errors}
          onInputChange={onInputChange}
          onInputBlur={onInputBlur}
          desktop={4}
          phone={12}
          disabled={disabled}
        />
        <Column desktop={4} phone={12}>
          <InputFormGroup
            errorMessage={errors.externalReservationCode}
            label='Código externo'
            className='text__input'
          >
            <Input
              onChange={onInputChange('externalReservationCode')}
              onBlur={onInputBlur('externalReservationCode')}
              value={form.externalReservationCode}
              placeholder='Digite o código externo'
              disabled={disabled}
            />
          </InputFormGroup>
        </Column>
      </Row>
      <Row css={rowStyle} className='flex_start'>
        {!isEditing && (
          <Column desktop={4} phone={12}>
            <CountInputFormGroup
              form={form}
              errors={errors}
              onInputChange={onInputChange}
              onInputBlur={onInputBlur}
              disabled={disabled}
            />
          </Column>
        )}
        {!isEditing && !disabled && (
          <>
            <Column desktop={4} phone={12}>
              <UnitTotalPriceInputFormGroup
                form={form}
                onChange={onInputChange}
                errors={errors}
              />
            </Column>
            <Column desktop={4} phone={12}>
              <UnitNetPriceInputFormGroup
                form={form}
                onChange={onInputChange}
                errors={errors}
              />
            </Column>
          </>
        )}
        <Column desktop={4} phone={12}>
          <PriceInputFormGroup
            disabled={disableTotalPrice || disabled}
            errors={errors}
            form={form}
            onInputChange={onInputChange}
            onInputBlur={onInputBlur}
            isEditing={isEditing}
          />
        </Column>
        <Column desktop={4} phone={12}>
          <NetPriceInputFormGroup
            disabled={disabled}
            errors={errors}
            form={form}
            onInputChange={onInputChange}
            onInputBlur={onInputBlur}
            isEditing={isEditing}
          />
        </Column>
      </Row>
      <ObservationRowInput
        errors={errors}
        form={form}
        onInputChange={onInputChange}
        onInputBlur={onInputBlur}
        disabled={disabled}
      />
    </>)
}

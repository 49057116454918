/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import {
  H3,
  H4,
  InputDurationMask,
  flexHorizontallyCentered,
  flexColumn,
  ToggleInputGroup,
  InputDurationMaskWithSuffix,
  InfoSwatch,
  CheckboxInputGroup,
  Textarea,
  flexRow
} from '@bonitour/components'
import { required } from 'assets/styles/global'
import { identity } from '@bonitour/common-functions'
import { useEffect, useState } from 'react'
import { chunkArray } from 'utils/array'
import { container, checkboxGroup, infoSwatch, inputStyle, marginBottom0, marginBottom20, marginRight8, marginTop50 } from './OfflineExperienceAbout.style'

export const OfflineExperienceAbout = ({
  serviceAbout = {},
  infrastructureList: infrastructures,
  serviceAboutErrors = {},
  onInputBlur: emitInputBlur = identity,
  onChange: emitChangeEvent = identity
}) => {
  const {
    duration = '',
    workDuration = '',
    generalRules = '',
    infrastructure = []
  } = serviceAbout

  const [isWorkDurationActive, setWorkDurationActive] = useState(false)

  const haveInfrastructure = (value) => infrastructure.includes(value)

  const handleInfrastructure = (value) => () => {
    if (haveInfrastructure(value)) {
      emitChangeEvent('serviceAbout.infrastructure')(infrastructure.filter((item) => item !== value))
    } else {
      emitChangeEvent('serviceAbout.infrastructure')([...infrastructure, value])
    }
  }

  const handleWorkDuration = () => setWorkDurationActive(!isWorkDurationActive)

  useEffect(() => {
    if (!isWorkDurationActive) {
      emitChangeEvent('serviceAbout.workDuration')(duration)
    }
    // eslint-disable-next-line
  }, [isWorkDurationActive]);

  useEffect(() => {
    setWorkDurationActive(workDuration !== duration)
  }, [duration, workDuration])

  return (
    <div css={container}>
      <H3 css={marginBottom20}>Tempo de duração</H3>
      <H4>
        Para o turista
        <span css={required}>(Obrigatório)</span>
      </H4>
      <div css={[flexHorizontallyCentered, marginBottom20]}>
        <p>Será de</p>
        <InputDurationMask
          css={inputStyle}
          value={duration}
          error={serviceAboutErrors.duration}
          onChange={emitChangeEvent('serviceAbout.duration')}
          onBlur={emitInputBlur('serviceAbout.duration')}
        />
        <p>horas</p>
      </div>

      <div css={[flexHorizontallyCentered, marginBottom20]}>
        <ToggleInputGroup id='toggleWorkDurationActive' checked={isWorkDurationActive} onClick={handleWorkDuration}>
          <H4 css={marginBottom0}>Para o colaborador</H4>
        </ToggleInputGroup>
      </div>
      <div css={flexHorizontallyCentered}>
        <p>Será de</p>
        <InputDurationMaskWithSuffix
          css={inputStyle}
          value={workDuration}
          error={serviceAboutErrors.workDuration}
          onChange={emitChangeEvent('serviceAbout.workDuration')}
          onBlur={emitInputBlur('serviceAbout.workDuration')}
          disabled={!isWorkDurationActive}
        >
          <span css={infoSwatch}>
            <InfoSwatch
              css={marginRight8}
              tooltip='O tempo de duração de uma atividade pode ser ou não o mesmo para o turista e para o colaborador/guia'
            />
          </span>
        </InputDurationMaskWithSuffix>
        <p>horas</p>
      </div>

      <div css={flexColumn}>
        <H3 css={[marginTop50, marginBottom20]}>Infraestrutura</H3>
        <div css={[flexRow, checkboxGroup]}>
          {chunkArray([...infrastructures], 5).map((column, index) => (
            <div css={flexColumn} key={index}>
              {column.map(option => (
                <div key={option[0]}>
                  <CheckboxInputGroup
                    id={option[0]}
                    onChange={handleInfrastructure(option[0])}
                    checked={haveInfrastructure(option[0])}
                  >
                    {option[1]}
                  </CheckboxInputGroup>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>

      <div css={flexColumn}>
        <H3 css={[marginTop50, marginBottom20]}>Normas</H3>
        <Textarea onChange={emitChangeEvent('serviceAbout.generalRules')} value={generalRules} />
      </div>
    </div>
  )
}

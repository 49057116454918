/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Card, H2, H3, colors, flexCenteredSpaceBetweenRow, P, BREAK_POINTS, flexRow } from '@bonitour/components'
import { marginBottom, marginLeft, marginTop } from 'assets/styles/global'

const marginTop50 = marginTop(50)
const marginLeft15 = marginLeft(15)
const marginBottom20 = marginBottom(20)

const valueHighlight = css`
  color: ${colors.primary};
`

const bold = css`
  font-weight: bold;
`

const cardContainer = css`
  ${flexRow};

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    ${flexCenteredSpaceBetweenRow};
  }
`

const card = css`
  display: inline-block;
  max-height: 60px;
  min-width: 170px;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    display: flex;
    flex-direction: column;
    max-height: initial;
  }
`

const cardDetail = css`
  ${flexCenteredSpaceBetweenRow};
  
  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    flex-direction: column;
  }
`

const CardContent = ({ title, value }) => <P css={[marginLeft15, marginBottom20]}>{title}: <span css={bold}>{value}</span></P>

export const MyDayResults = ({ totals }) => {
  const { activitiesAmount, paxTypes = {} } = totals

  return (
    <div css={marginTop50}>
      <H2>Resultado</H2>
      <Card css={card}>
        <H3>Total de PAX</H3>
        <div css={cardContainer}>
          <H2 css={valueHighlight}>{activitiesAmount}</H2>
          <div css={cardDetail}>
            {Object.keys(paxTypes).map((paxType, index) => {
              return <CardContent key={index} title={paxType} value={paxTypes[paxType]} />
            })}
          </div>
        </div>
      </Card>
    </div>
  )
}

import moment from 'moment'

const buildHourInterval = ({
  initialTime,
  finalTime,
  interval,
  vacancySunday,
  vacancyMonday,
  vacancyTuesday,
  vacancyWednesday,
  vacancyThursday,
  vacancyFriday,
  vacancySaturday
}) => {
  const hourInterval = []
  const referenceDate = moment(initialTime, 'HH:mm')
  const finalDate = moment(finalTime, 'HH:mm')

  while (referenceDate.isSameOrBefore(finalDate)) {
    hourInterval.push({
      time: referenceDate.format('HH:mm'),
      vacancyQuantity: 0,
      sundayQuantity: Number(vacancySunday),
      mondayQuantity: Number(vacancyMonday),
      tuesdayQuantity: Number(vacancyTuesday),
      wednesdayQuantity: Number(vacancyWednesday),
      thursdayQuantity: Number(vacancyThursday),
      fridayQuantity: Number(vacancyFriday),
      saturdayQuantity: Number(vacancySaturday)
    })

    referenceDate.add(interval, 'minutes')
  }
  return hourInterval
}

const mergeHours = (newHours = [], schedules = []) => {
  const hours = schedules
  const hoursList = schedules.map(({ time }) => time)

  newHours.forEach((item) => {
    const { time } = item
    const timeIndex = hoursList.indexOf(time)
    if (timeIndex === -1) {
      hours.push(item)
    } else {
      hours[timeIndex] = item
    }
  })

  return schedules
    .sort((a, b) => a.time.localeCompare(b.time))
    .filter(({ time }) => time)
}

export const buildIntervalArray = (previousSchedule = [], formInfo = {}) => {
  const newHours = buildHourInterval(formInfo)
  return mergeHours(newHours, previousSchedule)
}

import { metaModel } from './meta'

/* eslint-disable camelcase */
export const EmployeeModel = {
  listAllByCompanyId: (companyId = '') => {
    return {
      company_id: companyId,
      pagination: metaModel({ currentPage: 1, perPage: 999 })
    }
  }
}

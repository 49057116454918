import { LocalitiesService } from './Service'
import { LocalityListParser } from './Parser'

function Api () {
  function list () {
    const countryListResource = '/v1/countries'
    return LocalitiesService.get(countryListResource).then(({ data }) => LocalityListParser(data))
  }

  return { list }
}

export const CountryApi = Api()

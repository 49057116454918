import { useCallback, useEffect, useState } from 'react'
import { useToast } from '@bonitour/components'
import { useParamsFilter } from 'hooks/useParamsFilter'
import { BatchService } from 'core/services/Batch'
import { usePermissions } from 'hooks/usePermissions'

const initialPaginationValues = { currentPage: 1, perPage: 10 }

export const useListBatches = () => {
  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()
  const [filters, setFilters] = useParamsFilter()
  const [isLoading, setIsLoading] = useState(false)
  const [pagination, setPagination] = useState(initialPaginationValues)

  const onChangeFilters = useCallback((newFilters) => {
    setFilters(newFilters)
    setPagination(initialPaginationValues)
  }, [setFilters, setPagination])

  const onChangePagination = useCallback((currentPage, perPage) => {
    const isEqualPagination = currentPage === pagination.currentPage && perPage === pagination.perPage
    if (
      isEqualPagination || isLoading
    ) {
      return
    }

    setPagination({
      currentPage,
      perPage
    })
  }, [isLoading, pagination.currentPage, pagination.perPage])

  const [batchesData, setBatchesData] = useState({ batches: undefined, meta: undefined })

  const fetchBatches = useCallback(({ filters, pagination }) => {
    setIsLoading(true)

    return BatchService.list({ filters, pagination }).then((data) => setBatchesData(data))
      .catch((error) => {
        const hasPermission = handlePermission(error, 'Você não possui permissão de acesso aos lotes gerados')
        hasPermission && addToast('Ocorreu um erro ao realizar a busca')
      })
      .finally(() => setIsLoading(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFilterSubmit = useCallback((newFilters = null) => {
    const stringifiedNewFilters = newFilters && JSON.stringify(newFilters)
    const stringifiedCurrentFilters = JSON.stringify(filters)
    if (stringifiedNewFilters === stringifiedCurrentFilters) {
      fetchBatches({ filters, pagination })
    } else {
      onChangeFilters(newFilters)
    }
  }, [fetchBatches, filters, pagination, onChangeFilters])

  useEffect(() => {
    fetchBatches({ pagination, filters })
  }, [pagination, fetchBatches, filters])

  return {
    filters,
    pagination,
    onChangePagination,
    vouchers: batchesData?.batches,
    meta: batchesData?.meta,
    fetchVouchers: fetchBatches,
    isLoading,
    onFilterSubmit
  }
}

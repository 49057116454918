/* eslint-disable camelcase */
import { MetaParser } from './Meta'
import dayjs from 'dayjs'

export const PaymentBatchParser = {
  _legacy_offlineTicketToBatch: (ticketToBatch) => {
    const {
      id,
      slot,
      description,
      price,
      state,
      payer_name: payerName,
      reservation_code: reservationCode,
      company_id: companyId,
      service_id: serviceId,
      passenger_id: passengerId,
      reservation_id: reservationId,
      ticket_code: ticketCode,
      invoiced,
      company_name: companyName,
      service_title: serviceTitle,
      passenger_name: passengerName,
      external_integrations: externalIntegrations,
      guide_payment_batch_id: guidePaymentBatchId,
      observation,
      campaign_discount_code: campaignDiscountCode,
      offline_ticket_info: offlineTicketInfo,
      offline_provider_name: offlineProviderName
    } = ticketToBatch

    return {
      id,
      slot,
      description: PaymentBatchParser.description(description),
      price,
      state,
      payerName,
      reservationCode,
      companyId,
      serviceId,
      passengerId,
      reservationId,
      ticketCode,
      invoiced,
      companyName,
      serviceTitle,
      passengerName,
      externalIntegrations,
      guidePaymentBatchId,
      observation,
      campaignDiscountCode,
      offlineTicketInfo,
      externalReservationCode: offlineTicketInfo?.ticket_metainfo?.external_reservation_code,
      offlineProviderName
    }
  },
  _legacy_offlineTicketsToBatch: ({ meta, tickets: ticketsToBatch = [] }) => {
    return {
      ticketsToBatch: ticketsToBatch.map(PaymentBatchParser._legacy_offlineTicketToBatch),
      meta: MetaParser.meta(meta)
    }
  },
  ticketToBatch: (ticketToBatch) => {
    const {
      commission_percentage: commissionPercentage,
      commission_value: commissionValue,
      integration_ticket_code: integrationTicketCode,
      payer_name: payerName,
      reservation_code: reservationCode,
      reservation_id: reservationId,
      service_name: serviceName,
      ticket_external_code: ticketExternalCode,
      ticket_id: ticketId,
      ticket_price: ticketPrice,
      ticket_slot: ticketSlot,
      ticket_state: ticketState,
      passenger_name: passengerName,
      ...others
    } = ticketToBatch

    return {
      ticketId,
      ticketSlot,
      ticketPrice,
      ticketState,
      ticketExternalCode,
      integrationTicketCode,
      payerName,
      commissionValue,
      commissionPercentage,
      serviceName,
      reservationId,
      reservationCode,
      passengerName,
      ...others
    }
  },
  ticketsToBatch: ({ meta, tickets_to_batches: ticketsToBatch = [] }) => {
    return {
      ticketsToBatch: ticketsToBatch.map(PaymentBatchParser.ticketToBatch),
      meta: MetaParser.meta(meta)
    }
  },
  paymentBatch: (paymentBatch) => {
    const {
      id,
      company_id: companyId,
      beneficiary_id: beneficiaryId,
      beneficiary_name: beneficiaryName,
      beneficiary_type: beneficiaryType,
      value,
      status,
      payment_method: paymentMethod,
      reference_number: referenceNumber,
      payment_date: paymentDate,
      expected_payment_date: expectedPaymentDate,
      batch_type: batchType,
      user_id: userId,
      observation,
      origin_bank_account_id: originBankAccountId,
      payment_batch_items: paymentBatchItems,
      payment_batch_transactions: paymentBatchTransactions,
      tax_value: taxValue,
      liquid_value: liquidValue,
      discount,
      payer_name: payerName
    } = paymentBatch

    return {
      id,
      companyId,
      beneficiaryId,
      beneficiaryName,
      beneficiaryType,
      value,
      subtotalValue: parseFloat(value) + parseFloat(discount),
      status,
      paymentMethod,
      referenceNumber,
      paymentDate: paymentDate ? dayjs(paymentDate.split('T')[0]).toDate() : undefined,
      expectedPaymentDate: expectedPaymentDate ? dayjs(expectedPaymentDate.split('T')[0]).toDate() : undefined,
      batchType,
      userId,
      observation,
      originBankAccountId,
      taxValue,
      paymentBatchTransactions: paymentBatchTransactions?.map(PaymentBatchParser.paymentBatchTransactions) || [],
      paymentBatchItems: paymentBatchItems ? paymentBatchItems.map(PaymentBatchParser.paymentBatchItems) : [],
      liquidValue,
      discount,
      payerName
    }
  },
  paymentBatchTotalizers: ({ payment_batches_totalizers: paymentBatchTotalizers }) => {
    return {
      count: paymentBatchTotalizers.count,
      totalValue: paymentBatchTotalizers.total_value,
      totalLiquidValue: paymentBatchTotalizers.total_liquid_value
    }
  },
  paymentBatchList: ({ meta, payment_batches: paymentBatch = [] }) => {
    return {
      paymentBatchList: paymentBatch.map(PaymentBatchParser.paymentBatch),
      meta: MetaParser.meta(meta)
    }
  },
  description: (description) => {
    const { price, net_price: netPrice } = description

    return { price, netPrice }
  },
  paymentBatchItems: (paymentBatchItems) => {
    const {
      item_id,
      item_type: itemType,
      item_state: itemState,
      observation,
      payment_batch_id: paymentBatchId,
      value,
      ticket_code: ticketCode,
      passenger_name: passengerName,
      slot,
      external_code: externalCode,
      offline_external_reservation_code: offlineExternalReservationCode,
      offline_price: offlinePrice,
      ticket_price: ticketPrice,
      offline_net_price: offlineNetPrice,
      affiliate_code: affiliateCode,
      affiliate_percentage: affiliatePercentage,
      affiliate_price: affiliatePrice,
      service_name: serviceName,
      reservation_code: reservationCode,
      reservation_id: reservationId,
      payer_name: payerName,
      item_state: ticketState
    } = paymentBatchItems

    return {
      id: item_id,
      itemType,
      itemState,
      observation,
      paymentBatchId,
      value,
      ticketCode,
      passengerName,
      slot,
      externalCode,
      ticketPrice,
      offlineExternalReservationCode,
      offlinePrice,
      offlineNetPrice,
      affiliateCode,
      affiliatePercentage,
      serviceName,
      reservationCode,
      reservationId,
      payerName,
      ticketState,
      affiliatePrice
    }
  },
  paymentBatchTransactions: (paymentBatchTransactions) => {
    const {
      id,
      origin_bank_account_id: originBankAccountId,
      payment_batch_id: paymentBatchId,
      payment_method: paymentMethod,
      receipts,
      status,
      transaction_date: transactionDate,
      transaction_type: transactionType,
      user_id: userId,
      value
    } = paymentBatchTransactions

    return {
      id,
      originBankAccountId,
      paymentBatchId,
      paymentMethod,
      receipts,
      status,
      transactionDate: transactionDate ? dayjs(transactionDate.split('T')[0]).toDate() : undefined,
      transactionType,
      userId,
      value
    }
  }
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Accordion, CollapsableSection, HeaderPage } from '@bonitour/components'
import { useHistory } from 'react-router-dom'
import { OfflineExperienceTechnicalInfo } from './OfflineExperienceAbout'
import { EditOfflineExperienceInfo } from './EditOfflineExperienceInfo'
import { useQuery } from 'hooks/useQuery'
import { useRouteByType } from 'hooks/useRouteByType'
import { useState, useEffect, useCallback } from 'react'
import { OfflineExperienceService } from 'services/OfflineExperience/Service'
import { useActivity } from 'contexts/Activity'
import { OfflineExperienceComplementaryInfo } from './OfflineExperienceComplementaryInfo'

export const EditOfflineExperience = () => {
  const { push } = useHistory()
  const { id: serviceId } = useActivity()
  const [{ accordion = 1 }] = useQuery()
  const [service, setService] = useState({})

  useRouteByType(`${serviceId}/edit`)

  const updateService = useCallback(() => OfflineExperienceService.get(serviceId).then(setService), [serviceId])
  const setIndicatorActive = useCallback(accordionValue => push({ search: `?accordion=${accordionValue}` }), [push])

  useEffect(() => {
    updateService()
  }, [updateService])

  const backToDashboard = useCallback(() => push(`/offline-experience/${serviceId}/dashboard`), [push, serviceId])
  const onBackStep = useCallback(() => setIndicatorActive(Number(accordion) - 1), [accordion, setIndicatorActive])
  const onNextStep = useCallback(() => setIndicatorActive(Number(accordion) + 1), [accordion, setIndicatorActive])

  return (
    <>
      <HeaderPage onBack={backToDashboard} title='Experiência Offline' />
      <Accordion value={Number(accordion)} onClick={setIndicatorActive}>
        <CollapsableSection
          indicator={1}
          title='Informações básicas'
          topics='Registro / Contato / Endereço de origem / Endereço de destino'
        >
          <EditOfflineExperienceInfo
            offlineExperienceBase={service.offlineExperienceBase}
            onServiceUpdated={updateService}
            onNextStep={onNextStep}
          />
        </CollapsableSection>

        <CollapsableSection
          indicator={2}
          title='Informações técnicas'
          topics='Tempo de duração / Infraestrutura / Nível de dificuldade'
        >
          <OfflineExperienceTechnicalInfo
            serviceId={serviceId}
            offlineExperienceAbout={service.offlineExperienceAbout}
            onOfflineExperienceUpdated={updateService}
            onBackStep={onBackStep}
            onNextStep={onNextStep}
          />
        </CollapsableSection>

        <CollapsableSection
          indicator={3}
          title='Informações Complementares'
          topics='Descrição / Observações / O que levar / Incluso no transporte / Pagamentos aceitos'
          tooltip='Essas informações serão usadas para informar os viajantes sobre o transpote em plataformas de vendas'
        >
          <OfflineExperienceComplementaryInfo
            serviceId={serviceId}
            offlineExperienceComplementaryInfo={service.offlineExperienceComplementaryInfo}
            onOfflineExperienceUpdated={updateService}
            onBackStep={onBackStep}
          />
        </CollapsableSection>
      </Accordion>
    </>
  )
}

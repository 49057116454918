import { orbCore } from 'services/Orb/Service'
import { BatchModel } from 'core/models/batch'
import { BatchParser } from 'core/parsers/batch'

const { batchDomain } = orbCore

export const BatchService = {
  listVouchers: ({ filters, pagination }) => batchDomain.listVouchers(...BatchModel.listVouchers({ filters, pagination })).then(BatchParser.listVouchers),
  list: ({ filters, pagination }) => batchDomain.list(...BatchModel.list({ filters, pagination })).then(BatchParser.list),
  getById: (batchId) => batchDomain.get(batchId).then(BatchParser.singleBatchPayment),
  createBatch: batch => batchDomain.create(BatchModel.single(batch)),
  payBatch: (batchId, batch) => batchDomain.payment(batchId, BatchModel.singleBatchPayment(batch)),
  remove: batchId => batchDomain.remove(batchId)
}

export const paxTypesList = (activityMinAge) => [
  {
    name: 'Não pagante',
    feeModificator: 0,
    minAge: activityMinAge,
    maxAge: 99,
    capacity: 1,
    tooltip: 'É a tipificação para crianças até uma idade que não pagam na sua atividade. Ex: Crianças até 5 anos não pagam'
  },
  {
    name: 'Criança',
    minAge: activityMinAge,
    maxAge: 17,
    capacity: 1
  },
  {
    name: 'Adulto',
    minAge: 18,
    maxAge: 59,
    capacity: 1
  },
  {
    name: 'Sênior',
    minAge: 60,
    maxAge: 99,
    capacity: 1
  },
  {
    name: 'Tarifa Única',
    minAge: activityMinAge,
    maxAge: 99,
    capacity: 1
  },
  {
    name: 'Privativo',
    minAge: 1,
    maxAge: 99,
    capacity: 1
  },
  {
    name: 'Meia',
    feeModificator: 50,
    minAge: activityMinAge,
    maxAge: 99,
    capacity: 1
  },
  {
    name: 'Regional',
    feeModificator: 0,
    minAge: activityMinAge,
    maxAge: 99,
    capacity: 1
  },
  {
    name: 'Cortesia',
    feeModificator: 0,
    minAge: activityMinAge,
    maxAge: 99,
    capacity: 1
  }
]

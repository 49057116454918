/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { formatMoney } from '@bonitour/common-functions'
import { Card, colors } from '@bonitour/components'
import { SwatchImage } from 'components/SwatchImage'
import { resetLink } from 'assets/styles/global'
import { NavLink } from 'react-router-dom'
import { card, companyNameStyle, lastModificationStyle, valueLabelStyle, valueStyle, swatchImageStyle, container } from './PartnershipAccountCard.styles'

export const PartnershipAccountCard = ({ partnershipAccount = {}, isDebtor = false }) => {
  const { id, partnerName, partnerImage, creditorName, creditorImage, balance, lastModification } = partnershipAccount
  const balanceLabel = balance >= 0 ? 'Em crédito' : 'Em débito'
  const partnershipTransactionUrl = `/partnership-accounts/${id}?type=${isDebtor ? 'debtor' : 'creditor'}`

  return (
    <NavLink to={partnershipTransactionUrl} css={[resetLink, container]}>
      <Card css={card}>
        <SwatchImage
          css={swatchImageStyle}
          size={80}
          image={isDebtor ? creditorImage : partnerImage}
          alternativeText={isDebtor ? creditorName : partnerName}
        />
        <p css={companyNameStyle(10)}>{isDebtor ? creditorName : partnerName}</p>
        <p css={valueStyle(5, colors.gray3)}>{formatMoney(balance)}</p>
        <p css={valueLabelStyle(colors.gray3)}>{balanceLabel}</p>
        <p css={lastModificationStyle(colors.gray5)}>Última modificação:</p>
        <p css={lastModificationStyle(colors.gray5)}>{lastModification}</p>
      </Card>
    </NavLink>
  )
}

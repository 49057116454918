import { orbCore } from 'services/Orb/Service'
import { partnerListModel } from './Model'
import { CompanyStore } from 'core/store/Company'

const extractSellers = ({ sellers }) => sellers

export const PartnerService = {
  getList () {
    return orbCore.sellerDomain.list(CompanyStore.getId())
      .then(extractSellers)
      .then(partnerListModel)
  }
}

import { string, number, boolean, array } from 'yup'
import { ACCOUNT_TYPES } from '../constants/bankAccountConstants'

const NUMBER_TYPE_ERROR = 'O campo deve ser um número'

function turnNotRequiredWhenIsCompanyCashier (value, ctx) {
  if (ctx.parent.accountType === ACCOUNT_TYPES.COMPANY_CASHIER) return true
  return Boolean(value)
}

export const BankAccountSchema = {
  accountName: string().required('Digite um nome para a conta'),
  bankCode: number()
    .typeError(NUMBER_TYPE_ERROR)
    .test('pass-when-is-cashier', 'Digite o código do banco', turnNotRequiredWhenIsCompanyCashier),
  bankName: string().nullable(),
  agencyNumber: number()
    .typeError(NUMBER_TYPE_ERROR)
    .test('pass-when-is-cashier', 'Digite o número da agência', turnNotRequiredWhenIsCompanyCashier),
  agencyDigit: number().nullable(),
  accountNumber: number()
    .typeError(NUMBER_TYPE_ERROR)
    .test('pass-when-is-cashier', 'Digite o número da conta', turnNotRequiredWhenIsCompanyCashier),
  accountDigit: number()
    .typeError(NUMBER_TYPE_ERROR)
    .test('pass-when-is-cashier', 'Digite o dígito verificador da conta', turnNotRequiredWhenIsCompanyCashier),
  accountType: string()
    .test('pass-when-is-cashier', 'Selecione o tipo de conta', turnNotRequiredWhenIsCompanyCashier),
  enabled: boolean(),
  fees: array()
}

import { useState, useCallback } from 'react'
import { useToast } from '@bonitour/components'
import { ReportService } from 'core/services/Reports'
import { usePermissions } from 'hooks/usePermissions'
import { useParamsFilter } from 'hooks/useParamsFilter'

export const useListPendingTickets = () => {
  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()
  const [filters, setFilters] = useParamsFilter()
  const [isLoading, setIsLoading] = useState(false)
  const [pagination, setPagination] = useState({ currentPage: 1, perPage: 10 })
  const [pendingTicketsData, setPendingTicketsData] = useState({
    pendingTickets: [], meta: undefined
  })

  const fetchPendingTickets = useCallback(({ filters, newPagination }) => {
    if (!filters.dateStart || !filters.dateEnd) return
    setIsLoading(true)

    return ReportService
      .listPendingTickets({ filters, pagination: newPagination })
      .then(setPendingTicketsData)
      .catch((error) => {
        const hasPermission = handlePermission(
          error,
          'Você não possui permissão de acesso ao relatório de tickets pendentes'
        )
        hasPermission && addToast('Ocorreu um erro ao realizar a busca')
      })
      .finally(() => setIsLoading(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changePagination = useCallback((currentPage, perPage) => {
    const params = { currentPage, perPage }
    setPagination(params)
    fetchPendingTickets({ filters, newPagination: params })
    // eslint-disable-next-line
  }, [filters])

  const onFilterSubmit = useCallback(filters => {
    setFilters(filters)
  }, [setFilters])

  return {
    filters,
    onFilterSubmit,
    changePagination,
    pagination,
    pendingTickets: pendingTicketsData.pendingTickets,
    meta: pendingTicketsData.meta,
    isLoading
  }
}

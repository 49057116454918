/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { SidebarSection, BREAK_POINTS } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'

import { ExperienceListSelector } from 'containers/Activity/ListSelector/ListSelector'
import { useCompany } from 'contexts/Company'
import { NavMenuSection } from './NavMenuSection'
import { CompanyCard } from './CompanyCard'
import NewExperienceButton from 'domains/Activity/NewExperienceButton'

const { smallTablet } = BREAK_POINTS

const sectionSize = css`
  width: 275px;
  box-sizing: border-box;
`

const showActivities = css`
  margin: 0;
  padding: 1rem;
  width: 19.5rem;

  @media (max-width: ${smallTablet}) {
    display: none;
  }
`

export const SideNavbar = ({ onNavigation: emitNavigationEvent = identity }) => {
  const { company } = useCompany()

  return (
    <>
      <NavMenuSection css={sectionSize} onNavigation={emitNavigationEvent} />

      <SidebarSection css={[sectionSize, showActivities]}>
        <CompanyCard name={company.name} image={company.image} />
        <ExperienceListSelector
          subdomain='dashboard'
          smallCards
          onSelectedExperience={emitNavigationEvent}
        />
        <NewExperienceButton singleLine onNavigation={emitNavigationEvent} />
      </SidebarSection>
    </>
  )
}

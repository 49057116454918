/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useCallback, useMemo } from 'react'
import { jsx, css } from '@emotion/core'
import { deepGetOrElse } from 'deep-getter'
import { useForm } from '@bonitour/app-functions'
import { Card, colors, useToast } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'

import { FormHeader } from 'components/FormHeader'
import { Periods } from 'containers/Periods/Periods'
import { FeePaxType } from 'containers/ActivityFeeForm/FeePaxType/FeePaxType'
import { FeeBasicForm } from 'containers/ActivityFeeForm/FeeBasic/FeeBasicForm'
import { FeeByDemandForm } from 'containers/ActivityFeeForm/FeeByDemand/FeeByDemandForm'

import { feeSchema, validateFeeTypes } from './ActivityFeeForm.schema'

const paxTypeNeedValidation = ['Criança', 'Adulto', 'Sênior', 'Não pagante']
const paxTypeWhenIsPrivate = ['Criança', 'Adulto', 'Sênior', 'Não pagante', 'Meia', 'Regional', 'Cortesia']
const aditionalPax = ['Meia', 'Regional', 'Cortesia']
const paxTypeSingleFee = 'Tarifa Única'
const paxPrivateFee = 'Privativo'

const container = css`
  color: ${colors.gray3};
`

export const ActivityFeeForm = ({
  title = 'Criar tarifário',
  activityFee: resetActivityFee = {},
  activityMinAge = 0,
  onSuccess: emitSuccessEvent = identity,
  onBackClick: emitBackClickEvent = identity,
  onValidationError: emitValidationError = identity
}) => {
  const {
    form,
    errors,
    onSubmit,
    utils: { onInputBlur, onInputChange, addItemOnArray, removeItemArray, resetArray }
  } = useForm(resetActivityFee, feeSchema(activityMinAge))
  const { add: addToast } = useToast()

  const formValidate = useCallback(data => {
    const { feePaxTypes } = data
    const { minAgeError, conflictError, gapError } = validateFeeTypes(paxTypeNeedValidation, paxTypeSingleFee, paxPrivateFee, activityMinAge, feePaxTypes) || {}

    if (minAgeError) {
      return addToast(`Não há tipificação para idade mínima definida na experiência (${activityMinAge} anos)`)
    }

    if (conflictError) {
      return addToast('A tipificação de PAX está com idades conflitantes')
    }

    if (gapError) {
      return addToast('A configuração de PAX está com intervalos vagos entre idades')
    }

    emitSuccessEvent(data)
  }, [activityMinAge, addToast, emitSuccessEvent])

  const validateForm = onSubmit(formValidate, emitValidationError)

  const feePrice = deepGetOrElse(form, 'feeBasic.price', '0')
  const formattedPrice = useMemo(() => Number(feePrice.replace(/[^\d,]/g, '').replace(',', '.')), [feePrice])

  return (
    <>
      <FormHeader title={title} ghostClick={emitBackClickEvent} ctaClick={validateForm} />

      <Card css={container}>
        <FeeBasicForm
          feeBasic={form.feeBasic}
          feeBasicErrors={errors.feeBasic}
          onChange={onInputChange}
          onInputBlur={onInputBlur}
        />
        <Periods
          label='Períodos da tarifa'
          periodList={form.periodList}
          placeholder='Ex. Baixa Temporada'
          errors={errors.periodList}
          onBlur={onInputBlur}
          onChange={onInputChange}
          onItemAdd={addItemOnArray('periodList')}
          onItemRemove={removeItemArray('periodList')}
        />
        <FeeByDemandForm
          feePrice={formattedPrice}
          feesByDemand={form.feesByDemand}
          feesByDemandErrors={errors.feesByDemand}
          onChange={onInputChange}
          onInputBlur={onInputBlur}
          onReset={resetArray('feesByDemand')}
          onItemAddition={addItemOnArray('feesByDemand')}
          onItemRemoval={removeItemArray('feesByDemand')}
        />
        <FeePaxType
          activityMinAge={activityMinAge}
          feePrice={formattedPrice}
          feePaxTypes={form.feePaxTypes}
          feePaxTypesErrors={errors.feePaxTypes}
          paxTypeNeedValidation={paxTypeNeedValidation}
          paxTypeWhenIsPrivate={paxTypeWhenIsPrivate}
          aditionalPax={aditionalPax}
          paxTypeSingleFee={paxTypeSingleFee}
          paxPrivateFee={paxPrivateFee}
          onChange={onInputChange}
          onInputBlur={onInputBlur}
          onItemAddition={addItemOnArray('feePaxTypes')}
          onItemRemoval={removeItemArray('feePaxTypes')}
        />
      </Card>
    </>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useToast } from '@bonitour/components'
import { CashierHandlingForm } from './CashierHandlingForm'
import { useHistory } from 'react-router'
import { useCallback } from 'react'
import { useCreateCashierHandling } from '../hooks/useCreateCashierHandling'
import { CashierLinks } from 'domains/Cashier/Links'
import { usePermissions } from 'hooks/usePermissions'

export const CreateCashierHandling = () => {
  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()
  const { submit, loading } = useCreateCashierHandling()
  const history = useHistory()

  const onError = () => addToast('Preencha o formulário corretamente')

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onBack = useCallback(() => history.push('/cashier-handling'), [])

  const handleSubmit = useCallback((formValues) => {
    return submit(formValues)
      .then(() => {
        addToast('Movimentação criada com sucesso', 'success')
        onBack()
      })
      .catch((error) => {
        const hasPermission = handlePermission(error, 'Você não possui permissão para criar movimentação de caixa')
        if (hasPermission) {
          const errorMessage = error?.data?.errors_msg?.[0]
          const errorMessages = {
            'authentication::supervisor::invalid': 'A senha do supervisor está incorreta',
            'authentication::operator::invalid': 'A senha do agente está incorreta',
            'authentication::user::not_found': 'Usuário não encontrado',
            default: 'Erro ao criar movimentação de caixa'
          }
          addToast(errorMessages[errorMessage] ?? errorMessages.default)
        }
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <CashierLinks />
      <CashierHandlingForm loading={loading} handleSubmit={handleSubmit} onError={onError} onCancel={onBack} />
    </>
  )
}

import { css } from '@emotion/core'
import { colors, scrollBar } from '@bonitour/components'
import { margin, marginBottom, marginRight, marginTop } from 'assets/styles/global'

export const marginBottom15 = marginBottom(15)
export const marginTop20 = marginTop(20)
export const marginVertical20 = margin(20, 0, 20, 0)

export const cardContainer = css`
  margin-top: 50px;
  padding: 10px;
`

export const highlightLabel = css`
  font-weight: 700;
`

export const inputGroup = css`
  width: 100%;
  ${marginRight(20)};
`

export const fullWidth = css`
  width: 100%;
`
export const documentLabel = css`
  font-weight: 700;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 12px;
  color: ${colors.black};
  background-color: ${colors.gray7};
`
export const borderless = css`
  border-color: transparent;
  background-color: transparent;
  padding: 5px 15px;
  padding-right: 40px;
`

export const inputButton = css`
  width: 170px;
  margin: 5px 0px;
  padding: 5px 10px;
  font-size: 12px;
  color: ${colors.primary};
  border-color: ${colors.primary};
`

export const label = css`
  font-size: 14px;
  color: ${colors.gray4};
`

export const reservationPassengerContainer = css`
  margin: 15px 0;
  max-height: 140px;
  overflow-y: auto;
  ${scrollBar(5)}
`

export const addPayerLabel = css`
  margin-top: 20px;
  font-weight: bold;
  color: ${colors.gray3};
`

export const emailSearchStyle = css`
  input {
    min-width: 25px;
  }
`

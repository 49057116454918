import React, { useId, useMemo } from 'react'

import { createArcPathWithArrow } from './helpers/createArcPathWithArrow'
import { createTicketPath } from './helpers/createTicketPath'
import { createJaggedPaperPath } from './helpers/createJaggedPaperPath'
import { reservationTimelineActionTypes } from './ReservationTimeline.constant'

const {
  RESERVATION,
  TICKET,
  TICKET_BALANCE_TRANSFER,
  PAYMENT,
  ADVANCE_PAYMENT,
  ADVANCE_REFUND,
  REFUND
} = reservationTimelineActionTypes

const PAPER_PATH_D = createJaggedPaperPath({
  vX: 36,
  vY: 36,
  width: 22,
  height: 31,
  roundness: 2
})

const TICKET_PATH_D = createTicketPath({
  vX: 36,
  vY: 36,
  height: 11,
  width: 16
})

const CIRCLE_ARROW_PATH_D = createArcPathWithArrow({
  vX: 36,
  vY: 36,
  arcRadius: 14.5,
  arcStartingAt: 70,
  arcEndingAt: 400
})

const HOUR_GLASS_PATH_D = 'M26.5 8 l5 0 l-5 5 l5 0 l-5 -5'

const DollarSign = () => {
  return (
    <>
      <line x1="18" y1="9" x2="18" y2="12" />
      <line x1="18" y1="24" x2="18" y2="27" />
      <path d="M23 12H15.5a2.5 2.5 0 0 0 0 6h5a2.5 2.5 0 0 1 0 6H13" />
    </>
  )
}

const CircleArrow = () => {
  return <path fill="none" stroke="currentColor" d={CIRCLE_ARROW_PATH_D} />
}

const ReservationSvg = () => {
  return (
    <>
      <path d={PAPER_PATH_D} fill="none" stroke="currentColor" />
      <g transform="translate(0, -3)">
        <DollarSign />
      </g>
    </>
  )
}

const TicketSvg = ({
  isTicketTransfer
}) => {
  return (
    <>
      {isTicketTransfer && <CircleArrow />}
      <path d={TICKET_PATH_D} fill="none" stroke="currentColor" />
    </>
  )
}

const PaymentSvg = ({ mode }) => {
  const hourGlassMaskId = useId()

  const flags = useMemo(() => {
    return {
      isRefund: mode.toLowerCase().includes('refund'),
      isAdvance: mode.toLowerCase().includes('advance')
    }
  }, [mode])

  return (
    <>
      {flags.isAdvance && (
        <>
          <defs>
            <mask id={hourGlassMaskId}>
              <rect x="0" y="0" width="36" height="36" fill="white" />
              <path d={HOUR_GLASS_PATH_D} stroke="black" strokeWidth="4" />
            </mask>
          </defs>
          <path d={HOUR_GLASS_PATH_D} stroke="currentColor" strokeWidth="2" />
        </>
      )}

      <g mask={flags.isAdvance && `url(#${hourGlassMaskId})`}>
        <DollarSign />
        {flags.isRefund && <CircleArrow />}
      </g>
    </>
  )
}

/**
 * @typedef {Object} Props
 * @property {'TicketSeller::Reservation' | 'Financial::ReservationPayment' | 'Financial::ReservationRefund' | 'TicketSeller::Ticket' | 'Financial::PartPaymentTicketTransfer' | 'Financial::AdvanceReservationPayment' | 'Financial::AdvanceReservationRefund'} mode
 */

/**
 * @type {React.FC<Props>}
 */
export const ReservationTimelineIcon = ({ mode }) => {
  const macroMode = useMemo(() => {
    if ([TICKET, TICKET_BALANCE_TRANSFER].includes(mode)) return 'ticket'
    if ([PAYMENT, ADVANCE_PAYMENT, REFUND, ADVANCE_REFUND].includes(mode)) return 'payment'
    if ([RESERVATION].includes(mode)) return 'reservation'

    return 'none'
  }, [mode])

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-dollar-sign"
    >
      {macroMode === 'reservation'
        ? (
          <ReservationSvg />
        )
        : macroMode === 'ticket'
          ? (
            <TicketSvg isTicketTransfer={mode === TICKET_BALANCE_TRANSFER} />
          )
          : macroMode === 'payment'
            ? (
              <PaymentSvg mode={mode} />
            )
            : null}
    </svg>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { TableBodyRow, TableBodyCell, CircleThumbnail, TooltipOnHover, TrashIcon, EyeIcon, ConfirmDialog, flexHorizontallyCentered, flex } from '@bonitour/components'
import { formatMoney, identity } from '@bonitour/common-functions'
import { clickable, defaultCellPadding, marginRight } from 'assets/styles/global'
import { useState } from 'react'
import { batchStatusMap } from 'constants/paymentBatch'

const marginRight5 = marginRight(5)

const iconSize = css`
  font-size: 23px;
`

export const BatchTableRow = ({
  batch = {},
  onBatchClick: emitBatchClick = identity,
  onBatchDelete: emitBatchDelete = identity
}) => {
  const { id, paymentDate = '', reference = '', guideName = '', status = '', value = 0 } = batch
  const [codeName, codeColor] = batchStatusMap[status] || '-'
  const canDelete = status !== 'paid'

  const [isConfirmActionVisible, setConfirmActionVisible] = useState(false)
  const toggleConfirmActionVisible = () => setConfirmActionVisible(true)
  const toggleConfirmActionHidden = () => setConfirmActionVisible(false)

  const handleAcquirerDelete = id => () => {
    emitBatchDelete(id)
    toggleConfirmActionHidden()
  }

  return (
    <>
      <ConfirmDialog
        title='Exclusão de lote'
        message='Esse lote será excluído permanentemente. Deseja continuar?'
        isVisible={isConfirmActionVisible}
        successCallback={handleAcquirerDelete(id)}
        cancelCallback={toggleConfirmActionHidden}
      />

      <TableBodyRow>
        <TableBodyCell>{reference}</TableBodyCell>
        <TableBodyCell>{paymentDate}</TableBodyCell>
        <TableBodyCell>{guideName}</TableBodyCell>
        <TableBodyCell>
          <div css={flexHorizontallyCentered}>
            <CircleThumbnail size={10} style={{ backgroundColor: codeColor, marginRight: 5 }} />
            {codeName}
          </div>
        </TableBodyCell>
        <TableBodyCell>{formatMoney(value)}</TableBodyCell>
        <TableBodyCell css={defaultCellPadding} fixed>
          <div css={flex}>
            <TooltipOnHover text='Visualizar lote'>
              <EyeIcon css={[iconSize, clickable, marginRight5]} onClick={emitBatchClick(id)} />
            </TooltipOnHover>
            {canDelete &&
              <TooltipOnHover text='Excluir lote'>
                <TrashIcon css={[iconSize, clickable]} onClick={toggleConfirmActionVisible} />
              </TooltipOnHover>}
          </div>
        </TableBodyCell>
      </TableBodyRow>
    </>
  )
}

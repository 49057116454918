/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Button, colors, Column, flexColumn, Modal, Row } from '@bonitour/components'
import { head, identity } from '@bonitour/common-functions'
import { marginBottom } from 'assets/styles/global'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ReservationBalanceSelectionTickets } from './ReservationBalanceSelectionTickets'

const marginBottom30 = marginBottom(30)

const overflowHidden = css`
  overflow: hidden;
`

const closeBtnLoadingStyle = css`
  background-color: ${colors.indigo2};
  cursor: default;
`

const customModalStyle = css`
  > div {
    max-width: initial;
  }
`

export const ReservationBalanceSelectionModal = ({
  ticketsToPay = [],
  canceledTickets = [],
  isSelectionTicketsOpen = false,
  onSelectionTicketsClose: emitSelectionTicketsClose = identity,
  onConfirm: emitClickConfirm = identity,
  isLoading = false
}) => {
  const [ticketsToPaySelected, setTicketsToPaySelected] = useState([])
  const [canceledTicketsSelected, setCanceledTicketsSelected] = useState([])

  const isSplit = useMemo(() => canceledTickets?.some(({ isLimitedTransferSplitPayment }) => isLimitedTransferSplitPayment), [canceledTickets])

  const selectedSplitLinksQuantity = useMemo(() => canceledTicketsSelected
    .filter(({ isLimitedTransferSplitPayment }) => isLimitedTransferSplitPayment).length,
  [canceledTicketsSelected])

  const filteredTicketsToPay = useMemo(() => {
    const hasSelectedTicketSplit = canceledTicketsSelected.find(({ isLimitedTransferSplitPayment }) => isLimitedTransferSplitPayment)

    const filteredTicketsToPay = hasSelectedTicketSplit ? ticketsToPay.filter(({ companyId }) => companyId === head(canceledTicketsSelected)?.companyId) : ticketsToPay
    return filteredTicketsToPay
  }, [canceledTicketsSelected, ticketsToPay])

  const isSelectedCanceledValueGreaterThanNotPaid = useMemo(() => {
    const valueFromCanceledTickets = (canceledTicketsSelected || []).reduce((acc, { value }) => acc + value, 0)

    const valueFromNotPaidTickets = (filteredTicketsToPay || []).reduce((acc, { value }) => acc + value, 0)

    return valueFromCanceledTickets >= valueFromNotPaidTickets
  }, [canceledTicketsSelected, filteredTicketsToPay])

  const canDisableSelectAllTicketsToPayBtn = useMemo(() => {
    const toPayLength = filteredTicketsToPay.length
    if (
      toPayLength === 1 ||
      (toPayLength && !isSplit && isSelectedCanceledValueGreaterThanNotPaid)
    ) {
      return false
    }
    return isSplit || !isSelectedCanceledValueGreaterThanNotPaid || false
  }, [isSelectedCanceledValueGreaterThanNotPaid, filteredTicketsToPay.length, isSplit])

  const canDisableSelectAllCanceledTicketsBtn = useMemo(() => {
    const canceledLength = canceledTickets.length

    if (canceledLength === 1 || (canceledLength && !isSplit)) {
      return false
    }
    return isSplit
  }, [canceledTickets, isSplit])

  const isConfirmButtonDisabled = canceledTicketsSelected.length < 1 || ticketsToPaySelected.length < 1

  const isTicketToPaySelected = useCallback((ticketId) => ticketsToPaySelected.find(({ id }) => id === ticketId), [ticketsToPaySelected])

  const isTicketToPaySelectedAll = ticketsToPaySelected.length === filteredTicketsToPay.length

  const onSelectedAllTicketsToPayClick = () => {
    if (isTicketToPaySelectedAll) {
      setTicketsToPaySelected([])
    } else {
      setTicketsToPaySelected(filteredTicketsToPay)
    }
  }

  const onSelectedTicketToPayClick = useCallback((ticket) => () => {
    if (isTicketToPaySelected(ticket?.id)) {
      const allTicketsFiltered = ticketsToPaySelected.filter(({ id }) => id !== ticket?.id)
      setTicketsToPaySelected(allTicketsFiltered)
    } else {
      setTicketsToPaySelected([...ticketsToPaySelected, ticket])
    }
  }, [isTicketToPaySelected, ticketsToPaySelected])

  const ticketsToPaySelectedTotalValue = ticketsToPaySelected.reduce((acc, { ticketPaymentBalance = {} }) =>
    acc + head(ticketPaymentBalance)?.remainingTicketValue, 0)

  const isCanceledTicketSelected = useCallback((ticketId) => canceledTicketsSelected.find(({ id }) => id === ticketId), [canceledTicketsSelected])

  const isCanceledTicketSelectedAll = canceledTicketsSelected.length === canceledTickets.length

  const onSelectedAllCanceledTicketsClick = useCallback(() => {
    if (isCanceledTicketSelectedAll) {
      setCanceledTicketsSelected([])
    } else {
      setCanceledTicketsSelected(canceledTickets)
    }
  }, [canceledTickets, isCanceledTicketSelectedAll])

  const onSelectedCanceledTicketClick = useCallback((ticket) => () => {
    if (isCanceledTicketSelected(ticket?.id)) {
      setCanceledTicketsSelected(canceledTicketsSelected.filter(({ id }) => id !== ticket?.id))
    } else {
      setCanceledTicketsSelected([...canceledTicketsSelected, ticket])
    }
  }, [canceledTicketsSelected, isCanceledTicketSelected])

  const canceledTicketsSelectedTotalValue = canceledTicketsSelected.reduce((acc, { value }) => acc + value, 0)

  const isTicketSelectable = ticketsToPaySelectedTotalValue < canceledTicketsSelectedTotalValue

  const canDisableTicketSelection = useCallback((ticket) => {
    const isAlreadyTicketToPaySelected = ticketsToPaySelected.length !== 0

    const isNotTheSameTicket = !isTicketToPaySelected(ticket?.id)

    if (isAlreadyTicketToPaySelected && isNotTheSameTicket && isSplit) {
      return true
    }

    const [hasSplitCanceled, companyId] = (function () {
      const splitCanceleds = canceledTicketsSelected.filter(({ isLimitedTransferSplitPayment }) => isLimitedTransferSplitPayment)
      return [splitCanceleds.length > 0, head(splitCanceleds)?.companyId]
    }())

    const isSameCompanyId = ticket?.companyId === companyId

    if (hasSplitCanceled && !isSameCompanyId) {
      return true
    }

    if (isTicketToPaySelected(ticket?.id)) {
      return false
    }
    return !isTicketSelectable
  }, [canceledTicketsSelected, isSplit, isTicketSelectable, isTicketToPaySelected, ticketsToPaySelected.length])

  const canDisableCanceledTicketSelection = useCallback((ticket) => {
    const isAlreadyTicketToPaySelected = canceledTicketsSelected.length !== 0

    const isNotTheSameTicket = !isCanceledTicketSelected(ticket?.id)

    if (isAlreadyTicketToPaySelected && isNotTheSameTicket && isSplit) {
      return true
    }

    return false
  }, [canceledTicketsSelected.length, isCanceledTicketSelected, isSplit])

  useEffect(() => {
    if (canceledTicketsSelected.length === 0) {
      setTicketsToPaySelected([])
    }
  }, [canceledTicketsSelected])

  const onCloseModal = useCallback(() => {
    if (isLoading) return
    setTicketsToPaySelected([])
    setCanceledTicketsSelected([])
    emitSelectionTicketsClose()
  }, [emitSelectionTicketsClose, isLoading])

  return (
    <Modal title='Transferir saldo' isVisible={isSelectionTicketsOpen} onCloseClick={onCloseModal} customCloseIconCss={[isLoading && closeBtnLoadingStyle]} customCss={[customModalStyle]}>
      <Row>
        <Column phone={12} desktop={6}>
          <div css={[flexColumn, marginBottom30, overflowHidden]}>
            <ReservationBalanceSelectionTickets
              tickets={canceledTickets}
              label='Selecione os ingressos com o saldo disponível a transferir'
              isSelectAllDisabled={canDisableSelectAllCanceledTicketsBtn}
              isTicketSelectDisabled={canDisableCanceledTicketSelection}
              isTicketSelected={isCanceledTicketSelected}
              isSelectedAll={isCanceledTicketSelectedAll}
              onSelectedClick={onSelectedCanceledTicketClick}
              onSelectedAllClick={onSelectedAllCanceledTicketsClick}
              ticketsSelectedQuantity={canceledTicketsSelected.length}
              ticketsSelectedTotalValue={canceledTicketsSelectedTotalValue}
              canceledSplitTicketsSelectedQuantity={selectedSplitLinksQuantity}
            />
          </div>
        </Column>

        <Column phone={12} desktop={6}>
          {canceledTicketsSelected.length > 0 &&
            <div css={[flexColumn, marginBottom30, overflowHidden]}>
              <ReservationBalanceSelectionTickets
                tickets={filteredTicketsToPay}
                label='Selecione os ingressos que deseja quitar'
                isSelectAllDisabled={canDisableSelectAllTicketsToPayBtn}
                isTicketSelectDisabled={canDisableTicketSelection}
                isTicketSelected={isTicketToPaySelected}
                isSelectedAll={isTicketToPaySelectedAll}
                onSelectedClick={onSelectedTicketToPayClick}
                onSelectedAllClick={onSelectedAllTicketsToPayClick}
                ticketsSelectedQuantity={ticketsToPaySelected.length}
                ticketsSelectedTotalValue={ticketsToPaySelectedTotalValue}
                isTicketToPay
              />
            </div>}
        </Column>
      </Row>

      <Button onClick={emitClickConfirm({ ticketsToPaySelected, canceledTicketsSelected })} disabled={isLoading || isConfirmButtonDisabled}>{`${isLoading ? 'Confirmando' : 'Confirmar'} transferência`}</Button>
    </Modal>
  )
}

import { useEffect, useState } from 'react'
import moment from 'moment'

const formatDate = date => moment(date).format('YYYY-MM-DD')
const dayInMs = 86400000

function buildDateInterval (initialDate = new Date(), offset = 1, index = 0, dateInterval = []) {
  const referenceDate = moment(initialDate)
  if (index < offset) {
    return buildDateInterval(
      referenceDate + dayInMs,
      offset,
      index + 1,
      dateInterval.concat(formatDate(referenceDate))
    )
  }
  return dateInterval
}

export const useDateColumns = (referenceDate, daysOffset) => {
  const [dateColumns, setDateColumns] = useState([])

  useEffect(() => {
    setDateColumns(buildDateInterval(referenceDate, daysOffset))
    return () => {
      setDateColumns([])
    }
  }, [referenceDate, daysOffset])

  return dateColumns
}

import { useEffect, useState } from 'react'
import { useCompany } from 'contexts/Company'
import { AcquirerService } from 'core/services/Acquirer'
import { useParams } from 'react-router-dom'

export const useEditAcquirer = () => {
  const { id: companyId } = useCompany()
  const { acquirerId } = useParams()
  const [loading, setLoading] = useState(false)
  const [acquirer, setAcquirer] = useState()

  useEffect(() => {
    setLoading(true)
    AcquirerService.getById(acquirerId).then(setAcquirer).finally(() => setLoading(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const editAcquirer = async (acquirerData) => {
    setLoading(true)
    return AcquirerService.editAcquirer({ ...acquirerData, companyId }, acquirerId).finally(() => setLoading(false))
  }

  return {
    acquirer,
    submit: editAcquirer,
    loading
  }
}

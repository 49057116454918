/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { TableContainer, TableBody } from '@bonitour/components'
import { PartnershipTransactionsHeader } from './PartnershipTransactionsHeader'
import { PartnershipTransactionsRow } from './PartnershipTransactionsRow'
import { identity } from '@bonitour/common-functions'

export const PartnershipTransactionsTable = ({ transactions = [], loading, onEditVisible = false, getTransactionInfo = identity }) => {
  return (
    <TableContainer>
      <PartnershipTransactionsHeader />
      <TableBody loading={loading}>
        {
          transactions.map((transaction, index) => {
            return <PartnershipTransactionsRow key={index} transaction={transaction} onEditVisible={onEditVisible} getTransactionInfo={getTransactionInfo} />
          })
        }
      </TableBody>
    </TableContainer>
  )
}

/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import { MultiSelectDropdown } from '@bonitour/components'
import { hourSelect } from './NoticeForm.style'
import { useEffect, useContext, useState, useMemo, useCallback } from 'react'
import { ActivityContext } from 'contexts/Activity'

const NoticeHourlySelection = ({
  activityId,
  date,
  noticeErrors,
  hours,
  onChange,
  onBlur: emitBlurEvent,
  setActivitiesWithVacancies,
  companyId
}) => {
  const { fetchHourly } = useContext(ActivityContext)

  const [hourlies, setHourlies] = useState([])

  useEffect(() => {
    const abortController = new AbortController()
    fetchHourly(activityId, date, companyId).then((data) => {
      setHourlies(data)

      if (data.length < 1) {
        delete hours[activityId]
      } else {
        setActivitiesWithVacancies((previousValue) => {
          return [...previousValue, activityId]
        })
      }
    })

    return () => {
      abortController.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSelectHours = useCallback(
    (value) => {
      onChange('hours')({ ...hours, [activityId]: value })
    },
    [activityId, hours, onChange]
  )

  const className = useMemo(() => (hourlies?.length ? '' : 'disabled'), [hourlies?.length])

  const placeholder = useMemo(() => (hourlies?.length ? 'Selecione um horário' : 'Nenhuma vaga'), [hourlies?.length])

  const errorMsg = useMemo(() => {
    if (hourlies.length < 1) return ''

    const generalError = noticeErrors?.hours

    if (hours[activityId].length < 1 && typeof generalError === 'string' && generalError !== '') return generalError

    return noticeErrors?.hours?.[activityId] || ''
  }, [activityId, noticeErrors?.hours, hours, hourlies])

  return (
    <MultiSelectDropdown
      placeholder={placeholder}
      errorMessage={errorMsg}
      selected={hours[activityId]}
      onChange={onSelectHours}
      options={hourlies}
      customCss={[hourSelect]}
      className={className}
      onBlur={emitBlurEvent('hours')}
    />
  )
}

export default NoticeHourlySelection

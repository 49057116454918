/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { PaginationContainer, GroupedFab, Tag, CircleButton, PlusIcon, CloseIcon, Modal } from '@bonitour/components'
import { whiteButton } from 'assets/styles/global'
import { identity } from '@bonitour/common-functions'
import { VoucherTable } from '../VouchersTable/VoucherTable'
import { useMemo } from 'react'
import { BatchForm } from '../Form/BatchForm'

const floating = css`
  align-items: flex-end;
  position: fixed;
  bottom: 70px;
  right: 70px;
  z-index: 3;
`

const buttonContainer = css`
  margin-bottom: 10px;
  margin-right: 5px;
  cursor: pointer;
`

export const pageLimitOptions = [
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 500, label: '500' },
  { value: 1000, label: '1000' }
]

export const VouchersList = ({
  vouchers = [],
  total = 0,
  pagesQuantity = 1,
  loading,
  selectedVouchers,
  isVoucherSelect,
  isSelectedAll,
  isVoucherSelectable,
  isCreateBatchVisible,
  onPagination: emitPaginationEvent = identity,
  onSelectedAllClick: emitSelectedAllClick = identity,
  onVoucherClick: emitVoucherClick = identity,
  onCreateBatchVisible: emitCreateBatchVisible = identity,
  onCreateBatchHidden: emitCreateBatchHidden = identity,
  onSubmit: emitSubmit = identity
}) => {
  const quantitySelected = selectedVouchers.length
  const labelItems = quantitySelected > 1 ? `${quantitySelected} itens` : `${quantitySelected} item`
  const batchForm = useMemo(() => ({ vouchers: selectedVouchers.map(({ ticketId }) => ticketId) }), [selectedVouchers])

  const totals = useMemo(() => {
    return {
      vouchersQuantity: selectedVouchers.length || 0,
      value: selectedVouchers.reduce((sum, { value }) => sum + value, 0) || 0
    }
  }, [selectedVouchers])

  return (
    <>
      <PaginationContainer
        total={total}
        pagesQuantity={pagesQuantity}
        onPagination={emitPaginationEvent}
        pageLimitOptions={pageLimitOptions}
        pageLimitDefault={100}
      >
        <VoucherTable
          vouchers={vouchers}
          isSelectedAll={isSelectedAll}
          onSelectedAllClick={emitSelectedAllClick}
          isVoucherSelect={isVoucherSelect}
          isVoucherSelectable={isVoucherSelectable}
          onVoucherClick={emitVoucherClick}
          loading={loading}
        />
      </PaginationContainer>

      {quantitySelected > 0 &&
        <GroupedFab containerCss={floating} BaseIcon={PlusIcon} HoverIcon={CloseIcon}>
          <div css={buttonContainer}>
            <div onClick={emitCreateBatchVisible}>
              <Tag>{`Novo Lote - ${labelItems}`}</Tag>
              <CircleButton size={50} css={whiteButton}>
                <PlusIcon />
              </CircleButton>
            </div>
          </div>
        </GroupedFab>}

      <Modal title='Novo lote de vouchers' isVisible={isCreateBatchVisible} onCloseClick={emitCreateBatchHidden}>
        <BatchForm
          batch={batchForm}
          vouchers={selectedVouchers}
          totals={totals}
          onCloseClick={emitCreateBatchHidden}
          onSubmit={emitSubmit}
        />
      </Modal>
    </>
  )
}

import { css } from '@emotion/core'
import { colors } from '@bonitour/components'

export const exportToggleButtonClosed = css`
  background-color: ${colors.white};
  color: ${colors.gray3};
  min-width: 110px;
`

export const exportToggleButtonOpen = css`
  background-color: ${colors.primary};
  border: 1px solid ${colors.white};
  color: ${colors.white};
  min-width: 110px;
`

export const baseExportToggle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

export const exportIconStyle = css`
  margin-right: 7px;
  margin-top: 2px;
  font-size: 18px;
  
  i {
    font-weight: bold;
  }
`

export const exportOpenIconStyle = css`
  margin-right: 7px;
  margin-top: 2px;
  color: ${colors.primary};
  font-weight: bold;
  font-size: 18px;

  i {
    font-weight: bold;
  }

  i::before {
    color: ${colors.white};
  }
`

export const dialogContainer = css`
  max-width: 520px;
  max-height: 100vh;
  overflow: auto;
`

export const exportContainer = css`
  display: flex;  
  align-items: center;
  transition: all 0.5s;
  width: 30px;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    width: 110px;
    background-color: ${colors.white};
  }
`

export const exportContainerOpen = css`
  display: flex;  
  align-items: center;
  width: 110px;
  cursor: pointer;

  .exportButton {
    border: 1px solid ${colors.primary};
  }
`

export const exportIconContainer = css`
  display: flex;
  justify-content: flex-end;
`

import { MetaParser } from './Meta'
import { formatISOTime } from 'utils/time'
import { paymentTypesMap } from 'constants/reservationPaymentTypes'

export const RefundedTicketsParser = {
  single: (refund) => {
    const {
      reservation_id: reservationId,
      reservation_code: reservationCode,
      reservation_agent_name: reservationAgentName,
      refund_date: refundDate,
      refund_method: refundMethod,
      custom_company_name: customCompanyName,
      acquirer_name: acquirerName,
      refund_value: refundValue,
      aut,
      doc,
      refund_agent_name: refundAgentName,
      payer_name: payerName,
      brand,
      bank_name: bankName,
      start_date: startDate,
      end_date: endDate
    } = refund

    return {
      reservationId,
      reservationCode,
      reservationAgentName,
      refundDate: formatISOTime(refundDate),
      refundMethod: refundMethod ? paymentTypesMap[refundMethod].replace('Cartão de ', '') || refundMethod : '-',
      customCompanyName,
      acquirerName,
      refundValue,
      aut,
      doc,
      refundAgentName,
      payerName,
      brand,
      bankName,
      startDate: formatISOTime(startDate),
      endDate: formatISOTime(endDate)
    }
  },
  list: ({ meta, refund_tickets_reports: refunds = [] }) => {
    return {
      refundedTickets: refunds.map(RefundedTicketsParser.single),
      meta: MetaParser.meta(meta)
    }
  },
  totals: ({ refund_total_value: totalRefundedValue }) => ({
    totalRefundedValue
  })
}

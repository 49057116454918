import React, { useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useCompany } from 'contexts/Company'
import { useActivity } from 'contexts/Activity'
import { activityRoutes } from './activityRoutes'
import { binamikRoutes } from './binamikRoutes'
import { usePermissions } from 'hooks/usePermissions'

const { REACT_APP_ACCOUNTS_URL } = process.env

export const PrivateRoutes = () => {
  const { id: companyId } = useCompany()
  const { isBinamik } = usePermissions()
  const { id: activityId } = useActivity()

  useEffect(() => {
    if (!companyId) {
      window.location = `${REACT_APP_ACCOUNTS_URL}/app?error=missing-company`
    }
  }, [companyId, activityId])

  return (
    <Switch>
      {activityRoutes?.map(({ path, component, exact = false }) => (
        <Route key={path} path={path} component={component} exact={exact} />
      ))}
      {isBinamik && binamikRoutes?.map(({ path, component, exact = false }) => (
        <Route key={path} path={path} component={component} exact={exact} />
      ))}
      <Route path='/'>
        <Redirect to='/dashboard' key='redirect-route' />
      </Route>
    </Switch>
  )
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Card, InputFormGroup, colors, Button, Select, useToast, Row, Column, DatePicker, InfoSwatch, InputWithSuffix } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { useForm } from '@bonitour/app-functions'
import { string } from 'yup'
import { useState } from 'react'
import { useValidateDateRange } from 'domains/CompanyReports/hooks/useValidadeDateRange'
import { voucherStatusOptions } from '../constants/voucherStatus'
import { useKeyPress } from 'hooks/useKeyPress'
import { width100 } from 'assets/styles/global'

const button = css`
  height: 40px;
  width: 100%;
  margin-top: 20px;
`
const cardContrast = css`
  margin-bottom: 20px;
  padding: 15px;
  background-color: ${colors.gray13};
  border: none;
`

const VouchersFilterSchema = {
  externalCode: string(),
  status: string()
}

export const VouchersFilter = ({
  initialFilters,
  onFilterSubmit: emitFilterSubmit = identity
}) => {
  const { add: addToast } = useToast()
  const { form, errors, onSubmit, utils: { onInputChange } } = useForm(initialFilters, VouchersFilterSchema)
  const [filterVoucherStatusOptions] = useState([{ value: '', label: 'Todos' }, ...voucherStatusOptions])
  const { validateDateRange } = useValidateDateRange()
  const { voucherStartDate, voucherEndDate, externalCode = '', status = '' } = form
  const { onKeyDown } = useKeyPress()

  const onError = () => addToast('Preencha o formulário corretamente')

  const validateFilters = (filters) => {
    validateDateRange(voucherStartDate, voucherEndDate) && emitFilterSubmit(filters)
  }

  const onClick = onSubmit(validateFilters, onError)

  return (
    <Card css={cardContrast}>
      <Row>
        <Column phone={12} tabletlandscape={4} desktop={4}>
          <InputFormGroup label='Data inicial da experiência' errorMessage={errors.voucherStartDate}>
            <DatePicker
              value={voucherStartDate}
              onChange={onInputChange('voucherStartDate')}
              css={width100}
              allowsEmpty
              onKeyDown={event => onKeyDown(event, onClick)}
              preventHeaderIndex={true}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={4} desktop={4}>
          <InputFormGroup label='Data final da experiência' errorMessage={errors.voucherEndDate}>
            <DatePicker
              value={voucherEndDate}
              onChange={onInputChange('voucherEndDate')}
              css={width100}
              allowsEmpty
              onKeyDown={event => onKeyDown(event, onClick)}
              preventHeaderIndex={true}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={4} desktop={4}>
          <InputFormGroup label='Status' errorMessage={errors.status}>
            <Select
              placeholder='Todos'
              options={filterVoucherStatusOptions}
              value={status}
              error={errors.status}
              onChange={onInputChange('status')}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={7} desktop={7}>
          <InputFormGroup label='Código externo' errorMessage={errors.externalCode}>
            <InputWithSuffix
              value={externalCode}
              error={errors.externalCode}
              onChange={onInputChange('externalCode')}
              onKeyDown={event => onKeyDown(event, onClick)}
            >
              <InfoSwatch
                size={200}
                tooltip='É possível pesquisar mais de um código externo por vez, basta separar por vírgula. Ex: 102030, 112131'
              />
            </InputWithSuffix>
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={5} desktop={5}>
          <Button css={button} onClick={onClick}>Pesquisar</Button>
        </Column>
      </Row>
    </Card>
  )
}

import { useCallback, useEffect, useState } from 'react'
import { MyDayService } from 'core/services/MyDay'
import { useToast } from '@bonitour/components'
import { useParamsFilter } from 'hooks/useParamsFilter'
import { exportCsv, exportXlsx } from '@bonitour/common-functions'
import { TicketsService } from 'core/services/Tickets'
import { usePermissions } from 'hooks/usePermissions'
import { SafeDate } from '@binamik/js-providers'

export const useListActivities = () => {
  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()
  const [filters, setFilters] = useParamsFilter()
  const [loading, setLoading] = useState(false)
  const [exporting, setExporting] = useState(false)
  const [pagination, setPagination] = useState({ currentPage: 1, perPage: 10 })
  const [exportColumns, setExportColumns] = useState([])

  const changePagination = useCallback((currentPage, perPage) => {
    if (currentPage === pagination.currentPage && perPage === pagination.perPage) {
      return
    }

    setPagination({ currentPage, perPage })
  }, [pagination.currentPage, pagination.perPage])

  const [activitiesData, setActivitiesData] = useState({ activities: undefined, meta: undefined })
  const [totals, setTotals] = useState({ totals: undefined })

  const fetchActivities = useCallback(({ filters, pagination }) => {
    setLoading(true)
    setTotals({})

    return MyDayService.list({ filters, pagination }).then((data) => {
      setActivitiesData(data)
      MyDayService.totals({ filters }).then(setTotals)
    }).catch((error) => {
      const hasPermission = handlePermission(error, 'Você não possui permissão de acesso ao meu dia')
      hasPermission && addToast('Ocorreu um erro ao realizar a busca')
    }).finally(() => setLoading(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLoading])

  const fetchExportColumns = useCallback(() => {
    TicketsService.getItemsExportColumn().then(setExportColumns)
  }, [])

  const exportMyDay = useCallback((columnsFilters, filters) => {
    const { format, wantedFields } = columnsFilters

    const exportType = format === 'csv' ? csvExport : xlsxExport

    return exportType(wantedFields, filters)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const csvExport = useCallback((columnsFilters, filters) => {
    setExporting(true)
    const csvFilters = { wantedFields: columnsFilters, ...filters }

    return MyDayService.csvExport({ filters: csvFilters, pagination }).then((data) => {
      exportCsv('relatorio-meu-dia', data)
    }).catch((error) => {
      const hasPermission = handlePermission(error, 'Você não possui permissão de acesso a exportação do meu dia')
      hasPermission && addToast('Ocorreu um erro ao realizar a busca')
    }).finally(() => setExporting(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const xlsxExport = useCallback((columnsFilters, filters) => {
    setExporting(true)
    const xlsxFilters = { wantedFields: columnsFilters, ...filters }

    return MyDayService.xlsxExport({ filters: xlsxFilters, pagination }).then((data) => {
      exportXlsx('relatorio-meu-dia', data)
    }).catch((error) => {
      const hasPermission = handlePermission(error, 'Você não possui permissão de acesso a exportação do meu dia')
      hasPermission && addToast('Ocorreu um erro ao realizar a busca')
    }).finally(() => setExporting(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchActivities({ pagination, filters })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, filters])

  useEffect(() => {
    fetchExportColumns()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    filters,
    setFilters,
    pagination,
    changePagination,
    activities: activitiesData?.activities,
    meta: activitiesData?.meta,
    totals,
    fetchActivities,
    loading,
    exportColumns,
    exporting,
    exportMyDay,
    initialFilters: { ...filters, date: filters?.date || new SafeDate().jsDate }
  }
}

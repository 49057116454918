import { css } from '@emotion/core'
import { colors, scrollBar } from '@bonitour/components'

export const alreadyPaidStyle = css`
  position: absolute;
  top: -10px;
  color: #7E7EFCFA;
  text-decoration: line-through;
  font-size: 12px;
`

export const referenceStyle = css`
  position: relative;
`

export const container = css`
  display: flex;
  align-items: center;
`

export const historicContainer = css`
  height: 125px;
  overflow-y: auto;
  ${scrollBar(5)};
  margin-top: 10px;
`

export const hidePopover = css`
  display: none;
`

export const paymentDetailContainer = css`
  width: 100%;

  p {
    white-space: initial;
    text-align: left;
  }
`

export const paymentDetail = css`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  p:nth-child(2) {
    font-weight: bold;
  }
`

export const excedingValue = css`
  color: ${colors.green1};
`

export const remainingValue = css`
  color: ${colors.red1};
`

export const paymentDetailTitle = css`
  text-align: left;
`

export const historyStyle = css`
  line-height: 25px;
  max-width: 290px;
`

/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx, css } from '@emotion/core'
import { useState } from 'react'
import { Modal, GhostButton, Button, flexHorizontallyCentered, ConfirmDialog, LoadingAnimation } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { PaymentRegister } from 'containers/Invoice/PaymentRegister'
import { loadingContainerOverlay } from 'assets/styles/global'

const buttonContainer = css`
  margin-top: -30px;
  justify-content: flex-end;
`

const marginRight20 = css`
  margin-right: 20px;
`

export const InvoicePaymentModal = ({
  vendorId = '',
  companyId = '',
  isEnabledToApprove = false,
  isInvoicePaymentVisible = false,
  invoicePayment = {},
  onCloseClick = identity,
  onRefusalClick: emitRefusalEvent = identity,
  onApprovalClick: emitApprovalEvent = identity,
  amountDue = 0,
  isLoading = false
}) => {
  const { id } = invoicePayment

  const onRefusalClick = () => emitRefusalEvent(id)

  const onApprovalClick = () => emitApprovalEvent(id)

  const [isConfirmActionVisible, setIsConfirmActionVisible] = useState(false)

  const toggleConfirmActionVisible = () => setIsConfirmActionVisible(true)

  const toggleConfirmActionHidden = () => setIsConfirmActionVisible(false)

  const isPayOffInvoice = amountDue === invoicePayment.value

  const isPendingPayment = invoicePayment.vendorApproval === 'pending'

  return (
    <Modal isVisible={isInvoicePaymentVisible} title='Pagamento' onCloseClick={onCloseClick}>
      {isLoading && <LoadingAnimation customCss={[loadingContainerOverlay]}/>}
      <PaymentRegister vendorId={vendorId} companyId={companyId} paymentInfo={invoicePayment} isDisabled />

      <ConfirmDialog
        title='Confirmar pagamento'
        message='Você está prestes a confirmar o pagamento do parceiro, e esta ação irá quitar a fatura. A ação não poderá ser revertida depois. Tem certeza que deseja continuar? '
        isVisible={isConfirmActionVisible}
        successCallback={onApprovalClick}
        cancelCallback={toggleConfirmActionHidden}
      />

      {isEnabledToApprove && isPendingPayment && (
        <div css={[flexHorizontallyCentered, buttonContainer]}>
          <GhostButton css={marginRight20} onClick={onRefusalClick}>Recusar</GhostButton>
          <Button onClick={isPayOffInvoice ? toggleConfirmActionVisible : onApprovalClick}>Aprovar</Button>
        </div>
      )}
    </Modal>
  )
}

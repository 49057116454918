import { css } from '@emotion/core'

export const resetAccordionPadding = css`
  width: max-content;
  margin: 0;
  padding: 0;
  transition: color 2s background-color 2s !important;
  > div {
    margin: 1rem;
    &:first-of-type {
      transition: background-color 2s !important;
    }
  }
`
export const iconAnimation = css`
  animation: spin 2s linear infinite;
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`

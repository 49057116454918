import { useCallback, useEffect, useState } from 'react'
import { AcquirerService } from 'core/services/Acquirer'
import { useToast } from '@bonitour/components'
import { useParamsFilter } from 'hooks/useParamsFilter'
import { usePermissions } from 'hooks/usePermissions'

export const useListAcquirer = () => {
  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()
  const [filters, setFilters] = useParamsFilter()
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    currentPage: 1,
    perPage: 10
  })

  const changePagination = useCallback((currentPage, perPage) => {
    if (currentPage === pagination.currentPage && perPage === pagination.perPage) {
      return
    }

    setPagination({
      currentPage,
      perPage
    })
  }, [pagination.currentPage, pagination.perPage])

  const [acquirersData, setAcquirersData] = useState({ acquirers: undefined, meta: undefined })

  const fetchAcquirers = useCallback(({
    filters,
    pagination
  }) => {
    setLoading(true)
    return AcquirerService.list({ filters, pagination }).then((data) => setAcquirersData(data))
      .catch((error) => {
        const hasPermission = handlePermission(error, 'Você não possui permissão de acesso aos adquirentes')
        hasPermission && addToast('Ocorreu um erro ao realizar a busca')
      })
      .finally(() => setLoading(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchAcquirers({
      pagination,
      filters
    })
  }, [pagination, filters, fetchAcquirers])

  const onChangeFilters = useCallback((filters) => {
    changePagination(1, pagination.perPage)
    setFilters(filters)
  }, [changePagination, pagination.perPage, setFilters])

  return {
    filters,
    setFilters: onChangeFilters,
    pagination,
    changePagination,
    acquirers: acquirersData?.acquirers,
    meta: acquirersData?.meta,
    fetchAcquirers,
    loading
  }
}

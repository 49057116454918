import { colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const selectorColumn = css`
  flex-direction: column;
  h4 {
    margin: 8px 5px;
  }
`

export const selectorCard = css`
  box-sizing: border-box;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.5rem;
  background-color: ${colors.gray8};

  .status-component > div {
    margin-right: 5px;
  }
`
export const bindedCard = css`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 0.75rem;
  padding-top: 0;
  margin-bottom: 0;
  h5 {
    color: ${colors.gray1};
    font-weight: 800;
    font-size: 0.875rem;
    margin-bottom: 0.625rem;
    position: sticky;
    top: 0;
    z-index: 9;
    background-color: ${colors.white};
    border-radius: 0 0 10px 0;
    width: 8.72rem;
    padding: 0.75rem 0.75rem 0.625rem;
  }
  b {
    margin: 0.625rem 0 0;
    font-weight: 500;
    text-align: center;
    color: ${colors.gray2};
    width: 100%;
    display: block;
    font-size: 1.125rem;
  }
`
export const bindedCardHeightCss = (isEditing, isCollapsed) => css`
  height: ${isEditing ? '100%' : 'auto'};
  min-height: 10rem;
  max-height: ${isEditing ? (isCollapsed ? '50vh' : '10rem') : 'auto'};
  overflow: ${isEditing ? 'auto' : 'initial'};
  transition: max-height 200ms ease-in-out;
`

export const tableHeightCss = (isEditing, isCollapsed) => css`
  overflow: ${isEditing ? (isCollapsed ? 'hidden' : 'initial') : 'initial'};
  height: ${isEditing ? (isCollapsed ? '0px' : 'initial') : 'initial'};
  transition: height 5s ease-in-out;
`

export const searchCard = css`
  ${bindedCard}
  height: auto;
`

export const cardContrast = css`
  padding: 10px 0;
  background-color: ${colors.gray13};
  border: none;
  > div {
    margin: 0;
  }
  @media (max-width: 800px) {
    > div > div {
      min-width: 100%;
    }
    button {
      margin-top: 10px;
    }
    label {
      z-index: 1;
    }
  }
`

export const width100 = css`
  width: 100%;
`

export const button = css`
  margin-top: 24px;
  width: 100%;
  margin-top: 2rem;
`

export const tableCss = css`
  padding: 5px;
  .TableCell {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  > div,
  li > div {
    padding-top: 0;
    padding-bottom: 0;
  }
  li > div > div > div {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  > div > div {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .table_row {
    border: 1px solid transparent !important;
    &:not(.table_row__binded) {
      cursor: pointer;
    }
    @keyframes delayAppear {
      0% {
        opacity: 0;
      }
      80% {
        opacity: 0;
      }
    }
    animation: delayAppear 200ms ease-in-out;
    transition: background-color 100ms ease-in-out, border-color 100ms ease-in-out;
  }
  .table_row__selected {
    border-color: ${colors.indigo2} !important;
    background-color: ${colors.indigo3} !important;
    transition: background-color 100ms ease-in-out, border-color 100ms ease-in-out;
    position: relative;
    @keyframes moveUp {
      0% {
        background-color: ${colors.indigo2};
        opacity: 0;
      }
      10% {
        opacity: 1;
        background-color: ${colors.indigo2};
      }
      100% {
        opacity: 0;
        transform: translateY(-60vh);
      }
    }
    &::before {
      content: '';
      display: block;
      position: absolute;
      inset: 0;
      animation: moveUp 200ms ease-in;
      opacity: 0;
      z-index: 9;
      background-color: ${colors.gray10};
      border-radius: 10px;
      pointer-events: none;
    }
  }
  .table_row__binded {
    @keyframes appearColor {
      25% {
        background-color: transparent;
        opacity: 0;
      }
      50% {
        background-color: ${colors.indigo2};
        opacity: 0.5;
      }
    }
    animation: appearColor 400ms ease-in-out;
  }

  [class*='TableHeaderRow'] {
    > div {
      position: relative;
      border-radius: 0;
    }
  }

  ul > li > div:first-child {
    text-overflow: unset;
  }
`

export const tableOfflineComplement = css`
  ul > li {
      width: 100%
  }
`

const tablesBreakpointContent = `
ul > li,
ul > li > div,
ul > li > div > div {
  padding: 0 !important;
  width: 100%;
}

ul > li {
  margin: 2rem 0 2rem 0;
}

[class*='TableCell'] > div {
  padding: var(--cell-padding);
  padding-top: 6px;
  padding-bottom: 6px;
}

.status-component {
  padding: 0 !important;
  padding-left: 0.3rem;
}
`

export const offlineTableBreakpoint = css`
  @media screen and (max-width: 1270px) {
    ${tablesBreakpointContent}
  }
`

export const affiliateTableBreakpoint = css`
  @media screen and (max-width: 1000px) {
    ${tablesBreakpointContent}
  }
`

export const angleIconCss = (collapsed) => css`
  transform: ${collapsed ? 'translate(2px)' : 'rotate(180deg) translateY(-2px)'};
  transition: transform 200ms ease-in-out;
  margin-left: 2px;
`

export const pointer = css`
  cursor: pointer;
`

export const iconStyle = css`
  margin-right: 4px;
  margin-left: 6px;
`

export const paginationContainerCss = css`
  margin: 10px 0;
  overflow: visible !important;
`

export const paginationRowCss = css`
  margin: 0 0 -10px;
  > div > div {
    transform: scale(0.85);
    transform-origin: left;
    margin: -10px 0 0;
  }
  nav {
    transform: scale(0.85);
    transform-origin: right;
    margin: -10px 0 0;
  }
`

export const dialogCss = css`
  max-width: 450px;
  p,
  h1 {
    margin-bottom: 5px;
  }
  button {
    margin-right: 10px;
  }
`

export const noMarginBottom = css`
  margin-bottom: 0;
`

export const dialogButtonCss = css`
  display: none;
`

export const initialHeightSufix = css`
  height: inherit !important;
`

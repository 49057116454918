import { orbCore } from 'services/Orb/Service'
import { IntegrationModel } from 'core/models/integration'
import { IntegrationParser } from 'core/parsers/integration'

const { integrationDomain } = orbCore

export const IntegrationService = {
  create (integration) {
    return integrationDomain.create(IntegrationModel.single(integration))
  },
  list ({ pagination }) {
    return integrationDomain.list(...IntegrationModel.list({ pagination })).then(IntegrationParser.list)
  },
  getById (integrationId) {
    return integrationDomain.get(integrationId).then(IntegrationParser.single)
  },
  link (integrationId, integrationLink) {
    return integrationDomain.link(integrationId, IntegrationModel.link(integrationLink))
  },
  update (integrationId, integration) {
    return integrationDomain.update(integrationId, IntegrationModel.update(integration))
  },
  remove (integrationId, integrationActivityId) {
    return integrationDomain.remove(integrationId, integrationActivityId)
  }
}

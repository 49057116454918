import { formatISOTime } from 'utils/time'
import { MetaParser } from './Meta'

export const CashierParser = {
  single: (cashier) => {
    const {
      created_at: createdAt,
      operator_name: operatorName,
      total_value: totalValue,
      closed_at: closedAt,
      supervisor_name: supervisorName,
      cashier_type: cashierType,
      operator_id: operatorId,
      supervisor_id: supervisorId,
      payment_method_totalizator: paymentMethodTotalizator,
      status,
      id
    } = cashier?.cashier ?? cashier ?? {}

    return {
      id,
      operatorId,
      operatorName,
      supervisorId,
      supervisorName,
      totalValue,
      closedAt: closedAt ? formatISOTime(closedAt, 'DD/MM/YY - HH:mm') : '',
      createdAt: createdAt ? formatISOTime(createdAt, 'DD/MM/YY - HH:mm') : '',
      paymentMethodTotalizator: CashierParser.paymentMethodTotalizator(paymentMethodTotalizator),
      cashierType,
      status
    }
  },
  list: ({ meta, cashiers = [] }) => {
    return {
      cashiers: cashiers.map(CashierParser.single),
      meta: MetaParser.meta(meta)
    }
  },
  listEmployees: ({ employees }) => {
    return employees?.map(({
      user_id: userId,
      user_name: userName
    }) => ({
      userId,
      userName
    }))
  },
  getOne: (cashier) => {
    const {
      id,
      status,
      company_id: companyId,
      operator_id: operatorId,
      operator_name: operatorName,
      supervisor_name: supervisorName,
      supervisor_id: supervisorId,
      cashier_type: cashierType,
      cashier_operations: cashierOperations,
      payment_method_totalizator: paymentMethodTotalizator,
      values_to_credit: valuesToCredit,
      created_at: createdAt,
      closed_at: closedAt
    } = cashier?.cashier ?? cashier ?? {}

    return {
      id,
      status,
      companyId,
      operatorId,
      operatorName,
      supervisorId,
      supervisorName,
      cashierType,
      createdAt: createdAt ? formatISOTime(createdAt, 'DD/MM/YY') : '',
      createdAtTime: createdAt ? formatISOTime(createdAt, 'HH:mm') : '',
      closedAt: closedAt ? formatISOTime(closedAt, 'DD/MM/YY') : '',
      closedAtTime: closedAt ? formatISOTime(closedAt, 'HH:mm') : '',
      cashierOperations: CashierParser.cashierOperations(cashierOperations),
      paymentMethodTotalizator: CashierParser.paymentMethodTotalizator(paymentMethodTotalizator),
      valuesToCredit: CashierParser.valuesToCredit(valuesToCredit)
    }
  },
  valuesToCredit: (valuesToCredit) => {
    return valuesToCredit?.map(({
      company_to_credit_name: companyName,
      value_to_credit: value
    }) => ({
      companyName,
      value
    }))
  },
  cashierOperations: (cashierOperations) => {
    return cashierOperations?.map(({
      id,
      value,
      cashier_id: cashierId,
      operation_date: operationDate,
      operation_type: operationType,
      origin_id: originId,
      origin_type: originType,
      payment_method: paymentMethod,
      installments,
      document_number: documentNumber,
      description,
      reservation_id: reservationId,
      polymorphic_info: polymorphicInfo,
      polymorphic_type: polymorphicType
    }) => ({
      id,
      value,
      cashierId,
      operationDate: operationDate ? formatISOTime(operationDate, 'DD/MM/YYYY') : '',
      operationDateTime: operationDate ? formatISOTime(operationDate, 'HH:mm') : '',
      operationType,
      originId,
      originType,
      paymentMethod,
      installments,
      documentNumber,
      description,
      reservationId,
      polymorphicInfo,
      polymorphicType
    }))
  },
  paymentMethodTotalizator: (paymentMethodTotalizator) => {
    return paymentMethodTotalizator?.map(({
      payment_method: paymentMethod,
      value
    }) => ({
      paymentMethod,
      value
    }))
  }
}

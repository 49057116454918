import { css } from '@emotion/core'
import { BREAK_POINTS, colors } from '@bonitour/components'
import { marginRight, width100 } from 'assets/styles/global'

export const offlineExperienceTicketFormContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
  max-height: min(550px, calc(100vh - 120px));
  overflow: show;

  &.with_overflow {
    overflow: auto;
  }

  .selectors__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
  }

  @media (max-width: ${BREAK_POINTS.smallTablet}) {
    .selectors__container {
      display: flex;
      flex-direction: column;
      grid-gap: 10px;
    }
  }
`

export const progressDivCss = css`
  position: absolute;
  width: 100%;
  height: 0.5rem;
  bottom: 0;
  left: 0;
  background-color: #7E7EFC;

  animation-name: timerEffect;
  animation-duration: 5s;
  animation-timing-function: linear;

  @keyframes timerEffect {
    from { width: 100%; }
    to { width: 0%; }
  }
`
export const buttonStyle = css`
  padding: 12px 30px;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    ${width100}
  }
`

export const marginRight20 = marginRight(20)

export const disabledButtonCss = css`
  opacity: 0.5;
  cursor: default;
`

export const dialogCss = css`
  max-width: 1200px;
  max-height: 95vh;
  overflow-y: auto;
  color: ${colors.gray1};
  user-select: none;

  h1 {
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 10px;
  }
  b {
    font-weight: 600;
  }

  > .div-not-allowed {
    max-height: 45vh;
    overflow-y: auto;
    height: auto;
    border-radius: 0.4rem;

    background-color: ${colors.gray9};

    * {
      cursor: default !important;
    }

    > div {
      margin: 0;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    input {
      border: 1px solid ${colors.gray7};
    }

    [class*="datePickerContainer"], [class*="selectDisabled-StandaloneSelect-Select"] {
      border: 1px solid ${colors.gray7};
    }
    [data-testid*="datepicker-input"], [class*="resetNativeInput-selectInputText"] {
      border: 0;
    }
  }
`

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { InvoiceList } from 'containers/Invoices/InvoiceList'
import { useHistory } from 'react-router-dom'
import { useRef } from 'react'
import { HeaderPage } from '@bonitour/components'
import { useInvoiceDebtsList } from '../hooks/useInvoiceDebtsList'
import { useVendorsList } from 'hooks/domains/useVendorList'

export const InvoicesPay = () => {
  const { push } = useHistory()
  const backToDashboard = () => push('/dashboard')
  const navigateToInvoice = invoiceId => () => window.open(`/invoices-pay/${invoiceId}/edit`, '_blank', 'noopener noreferrer')

  const {
    invoices,
    filters,
    setFilters,
    changePagination,
    meta,
    totals = {},
    loading
  } = useInvoiceDebtsList()

  const { current: initialFilters } = useRef(filters)

  const { vendorsOptionsCompanyFiltered } = useVendorsList()

  return (
    <>
      <HeaderPage onBack={backToDashboard} title='Faturas a pagar' />

      <InvoiceList
        invoiceList={invoices}
        totals={totals}
        invoiceMeta={meta}
        onNavigation={navigateToInvoice}
        onPaginate={changePagination}
        loading={loading}
        onFilterSubmit={setFilters}
        initialFilters={initialFilters}
        partnerOptions={vendorsOptionsCompanyFiltered}
      />
    </>
  )
}

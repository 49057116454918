import { string } from 'yup'

export const addressSchema = (states = [], cities = []) => {
  const stateRules = states.length ? string().required('O estado é um campo obrigatório') : string()
  const cityRules = cities.length ? string().required('A cidade é um campo obrigatório') : string()
  return ({
    zipCode: string().required('O CEP é um campo obrigatório'),
    street: string().required('O endereço é um campo obrigatório'),
    supplement: string().required('O número é um campo obrigatório'),
    district: string().required('O bairro é um campo obrigatório'),
    country: string().required('O país é um campo obrigatório'),
    state: stateRules,
    city: cityRules,
    latLong: string()
      .required('Preencha a latitude e longitude')
      .matches(/^-?\d+(?:\.\d+)?\s*,\s*-?\d+(?:\.\d+)?$/, "Valor deve ser preenchido como 'Latitude,Longitude'")
  }
  )
}

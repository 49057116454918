/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useRef } from 'react'
import { identity } from '@bonitour/common-functions'
import { useClickOutside } from '@bonitour/app-functions'
import { clickable, loadingContainer, margin } from 'assets/styles/global'
import { EllipsisIcon, Label, LoadingAnimation, PlusIcon, Popover, TooltipOnHover, TrashIcon, Reference, Manager, EditInfoIcon, flexCenteredSpaceBetweenRow } from '@bonitour/components'
import { customLocalNameStyle, buttonContainer, linkPickupContainer, linkPickupIcon, linkPickupLabel, linkPickupOptionsIcon, marginHorizontal50, localNameStyle, pickupOption, pickupOptionsContainer, pickupTicketIcon } from './PickupButton.style'

const margin5 = margin(5, 5, 5, 5)

export const PickupButton = ({
  pickup = {},
  ticketId = '',
  serviceId = '',
  localName = '',
  pickupId = '',
  pickupLocalLoading = false,
  isTransport = false,
  onLinkPickup: emitLinkPickup = identity,
  onUnlinkPickup: emitUnlinkPickup = identity,
  onEditClickPickup: emitEditClickPickup = identity
}) => {
  const reference = useRef()
  const isLocalNameOverflow = localName?.length > 17 || false
  const [isPickupOptionsVisible, setPickupOptionsVisibility] = useClickOutside(reference)
  const onClickPassengerOptions = () => setPickupOptionsVisibility(!isPickupOptionsVisible)

  if (pickupLocalLoading.length > 0 && pickupLocalLoading.includes(ticketId)) {
    return (
      <div css={linkPickupContainer(isTransport)}>
        <LoadingAnimation size={25} css={[loadingContainer, marginHorizontal50]} />
      </div>
    )
  }

  return (
    <div css={linkPickupContainer(isTransport)}>
      {localName &&
        <div css={[buttonContainer, flexCenteredSpaceBetweenRow, margin5]}>
          {isLocalNameOverflow &&
            <TooltipOnHover size={250} text={localName}>
              <span css={isTransport ? customLocalNameStyle : localNameStyle}>{localName}</span>
            </TooltipOnHover>}

          {!isLocalNameOverflow && <span css={localNameStyle}>{localName}</span>}

          <div ref={reference}>
            <Manager>
              <Reference>
                {({ ref }) => (
                  <div css={clickable} ref={ref} onClick={onClickPassengerOptions}>
                    <EllipsisIcon css={linkPickupOptionsIcon} />
                  </div>
                )}
              </Reference>
              {isPickupOptionsVisible && (
                <Popover position='left'>
                  <div css={pickupOptionsContainer}>
                    <div css={pickupOption} onClick={() => emitEditClickPickup(pickup, ticketId, pickupId, serviceId)}>
                      <EditInfoIcon css={pickupTicketIcon} />
                      <span>Editar local de embarque</span>
                    </div>
                    <div css={pickupOption} onClick={emitUnlinkPickup}>
                      <TrashIcon css={pickupTicketIcon} />
                      <span>Desvincular local de embarque</span>
                    </div>
                  </div>
                </Popover>
              )}
            </Manager>
          </div>
        </div>}

      {!localName &&
        <div css={[buttonContainer, clickable]} onClick={emitLinkPickup}>
          <PlusIcon css={linkPickupIcon} />
          <Label css={linkPickupLabel}>Definir local</Label>
        </div>}
    </div>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import {
  Card,
  Column,
  H4,
  PaginationContainer,
  Row,
  AngleDownIcon,
  Dialog,
  GhostButton,
  Button
} from '@bonitour/components'
import { formatMoney } from '@bonitour/common-functions'
import {
  angleIconCss,
  bindedCard,
  bindedCardHeightCss,
  dialogButtonCss,
  dialogCss,
  noMarginBottom,
  paginationContainerCss,
  paginationRowCss,
  pointer,
  searchCard,
  selectorCard,
  selectorColumn,
  tableHeightCss
} from './TicketSelector.style'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { TicketFilter } from './TicketFilter'
import { invoiceBatchTypeTitleMap } from '../constants/InvoiceBatchType'
import { ticketSelectorContainer } from './TicketSelector.styles'
import { TicketTable } from './TicketTable'

const createInitialValue = () => {
  const today = new Date()
  const startDate = new Date(today.getFullYear(), today.getMonth(), 1)
  startDate.setMonth(startDate.getMonth())
  const endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0)
  const externalTicketIds = ''
  const reservationCodes = ''
  const ticketCodes = ''
  const externalReservationCode = ''
  const payerName = ''
  const orderBy = ''
  return {
    startDate,
    endDate,
    externalTicketIds,
    reservationCodes,
    ticketCodes,
    externalReservationCode,
    payerName,
    orderBy
  }
}

const pageLimitOptions = [10, 25, 50, 100, 200].map((perPageOption) => ({
  value: perPageOption,
  label: `${perPageOption}`
}))

export const TicketSelector = ({
  form = {},
  setBeneficiaryId,
  type,
  isEditing = true,
  initiallySelectedTickets = [],
  setTickets = () => {},
  ticketManager = {
    ticketsToBatch: [],
    ticketsToBatchMeta: {},
    emitPaginationEvent: () => {},
    loading: false,
    ticketsToBatchPagination: {},
    fetchTicketsToBatch: () => {}
  }
}) => {
  const {
    ticketsToBatch,
    ticketsToBatchMeta,
    emitPaginationEvent,
    loading,
    ticketsToBatchPagination,
    fetchTicketsToBatch
  } = ticketManager

  const [filters, setFilters] = useState(createInitialValue())
  const [selectedTickets, setSelectedTickets] = useState(initiallySelectedTickets)
  const [collapseSearch, setCollapseSearch] = useState(Boolean(initiallySelectedTickets?.length))
  const toggleCollapse = useCallback(() => setCollapseSearch((v) => !v), [])

  const selectedIds = useMemo(() => selectedTickets.map((ticket) => ticket.ticketId), [selectedTickets])
  const [currentSelectionBeneficiary, setCurrentSelectionBeneficiary] = useState(form.beneficiaryId)

  const beneficiaryLabel = useMemo(() => invoiceBatchTypeTitleMap[type].toLowerCase(), [type])

  const inconsistentSelection = useMemo(
    () =>
      Boolean(
        selectedIds?.length &&
          currentSelectionBeneficiary &&
          form.beneficiaryId &&
          currentSelectionBeneficiary !== form.beneficiaryId
      ),
    [currentSelectionBeneficiary, form.beneficiaryId, selectedIds]
  )

  useEffect(() => {
    setTickets(selectedIds)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTickets])

  useEffect(() => {
    if (initiallySelectedTickets?.length) {
      setSelectedTickets(initiallySelectedTickets)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initiallySelectedTickets])

  useEffect(() => {
    if (!selectedIds?.length || !currentSelectionBeneficiary) {
      setCurrentSelectionBeneficiary(form.beneficiaryId)
    }
  }, [currentSelectionBeneficiary, form.beneficiaryId, selectedIds?.length])

  const resetBeneficiary = useCallback(() => {
    setBeneficiaryId(currentSelectionBeneficiary)
  }, [currentSelectionBeneficiary, setBeneficiaryId])
  const resetTickets = useCallback(() => {
    setSelectedTickets([])
  }, [])

  useEffect(() => {
    setFilters((filters) => ({ ...filters, beneficiaryId: form.beneficiaryId }))
  }, [form.beneficiaryId])

  useEffect(() => {
    if (filters?.beneficiaryId) {
      fetchTicketsToBatch({ filters, pagination: ticketsToBatchPagination }, filters.beneficiaryId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  useEffect(() => {
    if (isEditing) {
      setCollapseSearch(Boolean(initiallySelectedTickets?.length))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing])

  const { total = 0, totalPages = 1 } = ticketsToBatchMeta ?? {}

  const scrollBindedList = useCallback(() => {
    const bindedScrollList = document.querySelector('.binded-list')
    setTimeout(() => {
      bindedScrollList.scrollTo({ top: bindedScrollList.scrollHeight, behavior: 'smooth' })
    }, 50)
  }, [])

  const handleOnSelectRow = useCallback(
    (id) => {
      if (selectedTickets.some((ticket) => ticket.ticketId === id)) {
        setSelectedTickets(selectedTickets.filter((ticket) => ticket.ticketId !== id))
      } else {
        setSelectedTickets([...selectedTickets, ticketsToBatch.find((ticket) => ticket.ticketId === id)])
        scrollBindedList()
      }
    },
    [scrollBindedList, selectedTickets, ticketsToBatch]
  )

  const handleToggleAll = useCallback(() => {
    if (!ticketsToBatch.some((ticket) => !selectedIds.includes(ticket.ticketId))) {
      setSelectedTickets((current) =>
        current.filter(({ ticketId }) => !ticketsToBatch.some((ticket) => ticket.ticketId === ticketId))
      )
    } else {
      setSelectedTickets(Array.from(new Set([...selectedTickets, ...ticketsToBatch])))
      scrollBindedList()
    }
  }, [scrollBindedList, selectedIds, selectedTickets, ticketsToBatch])

  const onFilterMiddleware = useCallback(
    (fn) => {
      if (collapseSearch) {
        toggleCollapse()
      }
      fn()
    },
    [collapseSearch, toggleCollapse]
  )

  const totalValue = selectedTickets?.reduce((acc, ticket) => {
    return acc + (Number(ticket?.commissionValue || 0) || Number(ticket?.value || 0))
  }, 0)

  return (
    <Row>
      <Dialog
        title={`Alterar ${beneficiaryLabel}?`}
        isVisible={inconsistentSelection}
        customContainercss={[dialogCss]}
        customButtonCss={[dialogButtonCss]}
      >
        <p>Não é possível criar uma fatura com ingressos pertencentes a mais de um {beneficiaryLabel}.</p>
        <p>Deseja limpar os ingressos selecionados ou cancelar a alteração do {beneficiaryLabel}?</p>
        <Row customCss={[noMarginBottom]}>
          <Button onClick={resetTickets}>Limpar ingressos</Button>
          <GhostButton onClick={resetBeneficiary}>Cancelar alteração</GhostButton>
        </Row>
      </Dialog>
      <Column phone={12} desktop={12} css={selectorColumn}>
        <H4>Ingressos da fatura</H4>

        <Row customCss={[ticketSelectorContainer]}>
          <div className="item__block">
            <b className="item__label">Ingressos Vinculados</b>
            <span className="item__value">{selectedTickets?.length || 0}</span>
          </div>

          <div className="item__block">
            <b className="item__label">Valor do lote</b>
            <span className="item__value">
              {formatMoney(
                totalValue
              )}
            </span>
          </div>
        </Row>

        <Card customCss={[selectorCard]}>
          <Card customCss={[bindedCard, bindedCardHeightCss(isEditing, collapseSearch)]} className="binded-list">
            <h5>Ingressos vinculados</h5>
            {selectedTickets?.length
              ? (
                <TicketTable
                  tickets={selectedTickets}
                  handleOnSelectRow={handleOnSelectRow}
                  selectedIds={selectedIds}
                  isBinded
                  isEditing={isEditing}
                  type={type}
                />
              )
              : (
                <b>Nenhum ingresso vinculado</b>
              )}
          </Card>
          {isEditing && (
            <Card customCss={[searchCard]}>
              <h5 onClick={toggleCollapse} css={pointer}>
                Vincular ingressos
                <AngleDownIcon customCss={[angleIconCss(collapseSearch)]} />
              </h5>
              <TicketFilter
                filters={filters}
                setFilters={setFilters}
                type={type}
                onFilterMiddleware={onFilterMiddleware}
              />
              <div css={[tableHeightCss(isEditing, collapseSearch)]}>
                <PaginationContainer
                  total={total}
                  pagesQuantity={totalPages}
                  onPagination={emitPaginationEvent}
                  isLoading={loading}
                  childrenCustomCss={[paginationContainerCss]}
                  pageRowCustomCss={[paginationRowCss]}
                  pageLimitOptions={pageLimitOptions}
                  isOpenDrawer
                >
                  <TicketTable
                    loading={loading}
                    tickets={ticketsToBatch}
                    handleOnSelectRow={handleOnSelectRow}
                    selectedIds={selectedIds}
                    handleToggleAll={handleToggleAll}
                    type={type}
                  />
                </PaginationContainer>
              </div>
            </Card>
          )}
        </Card>
      </Column>
    </Row>
  )
}

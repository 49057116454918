/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { ActivityBaseForm } from 'containers/ActivityBaseForm/ActivityBaseForm'
import { useActivityForm } from 'containers/ActivityBaseForm/useActivityForm'
import { useToast } from '@bonitour/components'
import { ActivityService } from 'services/Activity/Service'
import { useHistory } from 'react-router-dom'
import { useActivity } from 'contexts/Activity'
import { useCallback, useState } from 'react'
import { usePermissions } from 'hooks/usePermissions'

export const CreateActivityInfo = () => {
  const [activityBase] = useState({
    phones: [{ name: '', number: '' }],
    emails: [{ name: '', email: '' }],
    sellingDeadline: 0
  })
  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()
  const { setId } = useActivity()
  const { push } = useHistory()

  const onSuccess = useCallback(
    async (data) => {
      try {
        const { id } = await ActivityService.create(data)
        if (id) {
          setId(id)
          push(`/activity/${id}/edit?accordion=2`)
          addToast('Atividade criada com sucesso', 'success')
        }
      } catch (error) {
        const hasPermission = handlePermission(error, 'Você não possui permissão para criar uma experiência')
        if (hasPermission) {
          const errorMessage = error?.data?.errors_msg
          const errorCode = error?.data?.errors
          const errorMessages = {
            'uc_create_service::permission': 'Você não pode criar atividades devido a restrições em seu plano',
            'experiences::service::title::taken': 'Já existe outra atividade com o mesmo nome.',
            default: `Ocorreu um erro na criação da atividade. ${errorCode}`
          }
          addToast(errorMessages[errorMessage] ?? errorMessages.default)
        }
      }
    },
    [addToast, push, setId, handlePermission]
  )

  const activityFormProps = useActivityForm({ activityBase, onSuccess, addToast })

  return <ActivityBaseForm {...activityFormProps} />
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { TableBodyRow, TableBodyCell, colors, TruncateCell, TooltipOnHover, EditInfoIcon } from '@bonitour/components'
import { formatMoney, identity } from '@bonitour/common-functions'
import { capitalize, clickable } from 'assets/styles/global'

export const transactionTypeMap = {
  debit: 'débito',
  credit: 'crédito'
}

const smallField = css`
  max-width: 80px;
`

const creditTransaction = css`
  color: ${colors.green2};
`

const debitTransaction = css`
  color: ${colors.red3};
`

export const PartnershipTransactionsRow = ({ transaction = {}, onEditVisible: emitEditVisible = identity, getTransactionInfo: emitGetransactionInfo = identity }) => {
  const {
    id = '',
    transactionDate = '',
    transactionType = '',
    observations = '',
    value = 0,
    extraData = {}
  } = transaction

  const isCredit = transactionType === 'credit'

  const paymentDate = extraData?.reservation_payment_date || extraData?.invoice_payment_date || ''

  const onClick = (id) => {
    emitEditVisible()
    emitGetransactionInfo(id)
  }

  return (
    <>
      <TableBodyRow>
        <TableBodyCell>
          <div css={smallField}>{transactionDate}</div>
        </TableBodyCell>
        <TableBodyCell>
          <div css={smallField}>{paymentDate}</div>
        </TableBodyCell>
        <TruncateCell
          text={
            extraData?.aut || extraData?.doc
              ? `${extraData?.aut}\xa0/\xa0${extraData?.doc}`
              : ''
          }
          size='125px'
        />
        <TruncateCell
          text={`${transactionTypeMap[transactionType]}`}
          size='55px'
          css={capitalize}
        />
        <TruncateCell
          text={extraData?.brand || ''}
          size='55px'
          css={capitalize}
        />
        <TruncateCell
          text={extraData?.installments || ''}
          size='55px'
          css={capitalize}
        />
        <TruncateCell text={observations} size='190px' />
        <TableBodyCell>
          <div css={[smallField, isCredit ? creditTransaction : debitTransaction]}>{isCredit ? '+' : '-'}{formatMoney(value)}</div>
        </TableBodyCell>
        <TableBodyCell>
          <TooltipOnHover text='editar transação'>
            <EditInfoIcon css={[clickable]} onClick={() => onClick(id)} />
          </TooltipOnHover>
        </TableBodyCell>
      </TableBodyRow>
    </>
  )
}

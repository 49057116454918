/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Column, flexHorizontallyCentered, GhostButton, H3, Input, InputFormGroup, Row, useToast, InputWithSuffix, Card, TrashIcon, Select } from '@bonitour/components'
import { useForm } from '@bonitour/app-functions'
import { AddLabel } from 'components/AddLabel'
import { validationSchema } from './AcquirerForm.schema'
import { trashWrapper, marginBottom20, marginBottom50, marginRight20, fullWidth } from './AcquirerForm.style'
import { transactionTypesOptions, paymentMeansOptions, pixTransactionType, debitCardTransactionType } from 'constants/acquirers'
import { usePermissions } from 'hooks/usePermissions'
import { useCardBrandOptions } from '../hooks/useCardBrandOptions'
import { useBankAccountsOptions } from '../hooks/useBankAccountsOptions'
import { usePaymentProviderOptions } from '../hooks/usePaymentProviderOptions'

export const AcquirerForm = ({ acquirer, submit, loading }) => {
  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()
  const { cardBrandOptions, loadingCardBrands } = useCardBrandOptions()
  const { bankAccountsOptions, loadingBankAccounts } = useBankAccountsOptions()
  const { paymentProviderOptions, loadingPaymentProviders } = usePaymentProviderOptions()
  const history = useHistory()

  const acquirerbase = useMemo(() =>
    acquirer || {
      name: '',
      brand: '',
      transactionType: '',
      fees: [{ installments: 1, feePercentage: '' }]
    }, [acquirer])

  const {
    form,
    errors,
    onSubmit,
    utils: { onInputBlur, onInputChange, addItemOnArray, removeItemArray, resetArray }
  } = useForm(acquirerbase, validationSchema)

  const redirectToList = useCallback(() => history.push('/acquirers'), [history])

  const {
    name = acquirerbase.name,
    brand = acquirerbase.brand,
    transactionType = acquirerbase.transactionType,
    paymentMean = acquirerbase.paymentMean,
    bankAccountId = acquirerbase.bankAccountId,
    fees
  } = form

  const isPixType = useMemo(() => transactionType === pixTransactionType, [transactionType])

  const onValidationError = useCallback(() => {
    addToast('Preencha corretamente o formulário')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onValidationSuccess = useCallback((data) => {
    const selectedCardBrandOption = cardBrandOptions.find(
      ({ value }) => value === brand
    )

    const selectedPaymentProviderOption = paymentProviderOptions.find(
      ({ value }) => value === name
    )

    const cardBrandId = selectedCardBrandOption?.brandId || undefined
    const providerId = selectedPaymentProviderOption?.providerId || undefined

    submit({
      ...data,
      cardBrandId,
      providerId
    })
      .then(() => {
        addToast('Adquirente salvo com sucesso', 'success')
        redirectToList()
      })
      .catch((error) => {
        const hasPermission = handlePermission(error, 'Você não possui permissão de criação de adquirente')
        hasPermission && addToast('Erro ao salvar adquirente')
      })
  }, [addToast, brand, cardBrandOptions, handlePermission, name, paymentProviderOptions, redirectToList, submit])

  const handleSubmit = useMemo(() => onSubmit(onValidationSuccess, onValidationError), [
    onSubmit,
    onValidationError,
    onValidationSuccess
  ])

  const handleFeeDeletion = (index) => () => {
    removeItemArray('fees')(index)
  }

  const handleAddFeeItem = () => {
    addItemOnArray('fees')({ installments: fees?.length + 1 })
  }

  const hasFeesValues = fees?.length > 1

  const handleTransactionChange = (value) => {
    if (value === debitCardTransactionType && hasFeesValues) {
      resetArray('fees')([
        {
          installments: 1,
          feePercentage: ''
        }
      ])
    }

    onInputChange('transactionType')(value)
  }

  const handleOnChangeAcquirerTax = useCallback(index => newValue => {
    if (newValue >= 100) {
      return onInputChange(`fees[${index}].feePercentage`)(100)
    }

    const roundedValue = Math.round(newValue * 100) / 100

    onInputChange(`fees[${index}].feePercentage`)(roundedValue.toFixed(2))
  }, [onInputChange])

  const isCreditCard = useMemo(() => transactionType === 'credit_card', [transactionType])

  return (
    <div>
      <Card css={marginBottom20}>
        <H3>Nome, bandeira e tipo</H3>
        <Row>
          <Column phone={12} desktop={4}>
            <InputFormGroup errorMessage={errors.name} label='Nome'>
              <Select
                placeholder='Selecione um adquirente'
                options={paymentProviderOptions}
                value={name}
                disabled={loading || loadingPaymentProviders}
                error={errors.name}
                onChange={onInputChange('name')}
              />
            </InputFormGroup>
          </Column>

          <Column phone={12} desktop={4}>
            <InputFormGroup errorMessage={errors.paymentMean} label='Meio de pagamento'>
              <Select
                placeholder='Selecione um meio de pagamento'
                options={paymentMeansOptions}
                value={paymentMean}
                disabled={loading}
                error={errors.paymentMean}
                onChange={onInputChange('paymentMean')}
              />
            </InputFormGroup>
          </Column>

          <Column phone={12} desktop={4}>
            <InputFormGroup errorMessage={errors.brand} label='Bandeira'>
              <Select
                placeholder='Selecione uma bandeira'
                options={cardBrandOptions}
                value={isPixType ? null : brand}
                disabled={loading || isPixType || loadingCardBrands}
                error={errors.transactionType}
                onChange={onInputChange('brand')}
              />
            </InputFormGroup>
          </Column>
        </Row>

        <Row>
          <Column phone={12} desktop={6}>
            <InputFormGroup errorMessage={errors.transactionType} label='Tipo'>
              <Select
                placeholder='Selecione um tipo'
                options={transactionTypesOptions}
                value={transactionType}
                disabled={loading}
                error={errors.transactionType}
                onChange={handleTransactionChange}
              />
            </InputFormGroup>
          </Column>

          <Column phone={12} desktop={6}>
            <InputFormGroup errorMessage={errors.bankAccountId} label='Conta Bancária'>
              <Select
                placeholder='Selecione a conta de destino'
                options={bankAccountsOptions}
                value={bankAccountId}
                disabled={loading || loadingBankAccounts}
                error={errors.bankAccountId}
                onChange={onInputChange('bankAccountId')}
              />
            </InputFormGroup>
          </Column>
        </Row>
      </Card>

      <Card css={marginBottom50}>
        <H3>Taxas</H3>
        {fees?.map((fee, index) => {
          const roundedFeePercentage = Math.round(fee.feePercentage * 100) / 100

          return (
            <Row key={`acquirer-fee-${index}`}>
              <Column phone={12} desktop={4}>
                <InputFormGroup errorMessage={errors.fees?.[index]?.installments} label='Parcelas'>
                  <Input
                    min='0'
                    type='number'
                    css={fullWidth}
                    value={fee.installments}
                    onChange={onInputChange(`fees[${index}].installments`)}
                    onBlur={onInputBlur(`fees[${index}].installments`)}
                    disabled={loading || !isCreditCard}
                  />
                </InputFormGroup>
              </Column>
              <Column phone={12} desktop={4}>
                <InputFormGroup errorMessage={errors.fees?.[index]?.feePercentage} label='Taxa'>
                  <InputWithSuffix
                    step='0.01'
                    min={0.0}
                    max={100}
                    type='number'
                    value={roundedFeePercentage}
                    onChange={handleOnChangeAcquirerTax(index)}
                    onBlur={onInputBlur(`fees[${index}].feePercentage`)}
                    disabled={loading}
                  >
                    %
                  </InputWithSuffix>
                </InputFormGroup>
              </Column>
              {hasFeesValues && !loading && (
                <div css={trashWrapper} onClick={handleFeeDeletion(index)}>
                  <TrashIcon />
                </div>
              )}
            </Row>
          )
        })}
        {isCreditCard &&
          <AddLabel disabled={loading} onClick={handleAddFeeItem}>
            Adicionar nova taxa
          </AddLabel>}
      </Card>

      <div css={flexHorizontallyCentered}>
        <GhostButton disabled={loading} css={[marginRight20]} onClick={redirectToList}>
          Voltar
        </GhostButton>
        <Button onClick={handleSubmit} disabled={loading}>Salvar e sair</Button>
      </div>
    </div>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Button, useToast } from '@bonitour/components'
import { marginTop } from 'assets/styles/global'
import { identity } from '@bonitour/common-functions'
import { useForm } from '@bonitour/app-functions'
import { tradeAgreementSchema } from './tradeAgreement.schema'
import { TradeAgreement } from './TradeAgreement'
import { useCallback, useState } from 'react'

const marginTop50 = marginTop(50)

export const TradeAgreementForm = ({
  tradeAgreement: resetTradeAgreement = {},
  onSuccess: emitSuccessEvent = identity,
  onValidationError: emitValidationError = identity,
  savedCustomComissions = [],
  isLoading = false,
  isGroupEditable = false
}) => {
  const [customComissions, setCustomComissions] = useState([])

  const { add: addToast } = useToast()

  const {
    form,
    errors,
    onSubmit,
    utils: { onInputBlur, onInputChange }
  } = useForm(resetTradeAgreement, tradeAgreementSchema)

  const onClick = useCallback(() => {
    const customComissionWithErrors = customComissions.some(({ errors }) => {
      const customComissionsErrors = Object.values(errors || {}) || []
      return customComissionsErrors.some(error => error)
    })
    if (!customComissionWithErrors) {
      onSubmit((data) => {
        emitSuccessEvent({ ...data, customComissions })
      }, emitValidationError)()
    } else {
      addToast('Existem erros nos campos de comissões personalizadas', 'error')
    }
  }, [addToast, customComissions, emitSuccessEvent, emitValidationError, onSubmit])

  return (
    <>
      <TradeAgreement
        tradeAgreement={form}
        errors={errors}
        onInputChange={onInputChange}
        onInputBlur={onInputBlur}
        isGroupEditable={isGroupEditable}
        onUpdateCustomComissions={setCustomComissions}
        savedCustomComissions={savedCustomComissions}
        isLoading={isLoading}
      />

      <Button css={marginTop50} onClick={onClick}>Continuar</Button>
    </>
  )
}

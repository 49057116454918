/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import { Column, InputFormGroup, Row, Textarea } from '@bonitour/components'
import { maxLengthContainer, maxLengthStyle, pushNotificationHeight } from './NoticeForm.style'

const placeholderPushNotificationMessage = {
  activityCancelled: 'Sua experiência foi cancelada. Clique para saber mais.',
  activityConfirmed: 'Sua experiência foi confirmada. Clique para saber mais.',
  newStatus: 'Você tem novidades sobre sua experiência. Clique para saber mais.'
}

const placeholderMessage = {
  activityCancelled:
    'A experiência foi cancelada devido às fortes chuvas. Entre em contato com o canal de vendas para mais detalhes.',
  activityConfirmed:
    'Sua experiência está confirmada! Não se esqueça de trazer o documento de identidade e chegar com antecedência.',
  newStatus:
    'Sua experiência pode ser cancelada a qualquer momento devido às condições climáticas. Entre em contato com o canal de vendas antes de se dirigir ao local.'
}

const NoticeMessageSelection = ({
  category,
  pushNotificationMessage,
  message,
  onChange,
  onBlur,
  noticeErrors
}) => {
  const currentPushPlaceholder = placeholderPushNotificationMessage[category]
    ? placeholderPushNotificationMessage[category]
    : 'Insira uma mensagem'
  const currentOpenPlaceholder = placeholderMessage[category] ? placeholderMessage[category] : 'Insira uma mensagem'

  return (
    <>
      <Row>
        <Column phone={12} desktop={12}>
          <InputFormGroup
            errorMessage={noticeErrors.pushNotificationMessage}
            label="Mensagem apresentada na notificação Push"
          >
            <Textarea
              css={pushNotificationHeight}
              value={pushNotificationMessage}
              placeholder={currentPushPlaceholder}
              maxLength="60"
              onChange={onChange('pushNotificationMessage')}
              onBlur={onBlur('pushNotificationMessage')}
            />
          </InputFormGroup>
        </Column>
        <Column css={maxLengthContainer} phone={12} desktop={12}>
          <span css={maxLengthStyle}>{`${pushNotificationMessage.length}/60`}</span>
        </Column>
      </Row>

      <Row>
        <Column phone={12} desktop={12}>
          <InputFormGroup errorMessage={noticeErrors.message} label="Mensagem apresentada na notificação aberta">
            <Textarea
              value={message}
              placeholder={currentOpenPlaceholder}
              maxLength="1000"
              onChange={onChange('message')}
              onBlur={onBlur('message')}
            />
          </InputFormGroup>
        </Column>
        <Column css={maxLengthContainer} phone={12} desktop={12}>
          <span css={maxLengthStyle}>{`${message.length}/1000`}</span>
        </Column>
      </Row>
    </>
  )
}

export default NoticeMessageSelection

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useRef } from 'react'
import { jsx } from '@emotion/core'
import { Card, HeaderPage, PaginationContainer } from '@bonitour/components'
import { useHistory } from 'react-router-dom'
import { PendingTicketsFilter } from './PendingTicketsFilter'
import { PendingTicketsExcerpt } from './PendingTicketsExcerpt'
import { PendingTicketsTable } from './PendingTicketsTable'
import { useListPendingTickets } from 'domains/CompanyReports/hooks/useListPendingTickets'

export const PendingTicketsList = () => {
  const { push } = useHistory()
  const backToReports = () => push('/reports')

  const {
    changePagination,
    onFilterSubmit,
    isLoading,
    filters,
    meta,
    pagination,
    pendingTickets
  } = useListPendingTickets()

  const { current: initialFilters } = useRef(filters)

  return (
    <>
      <HeaderPage onBack={backToReports} title='Ingressos Pendentes' />

      <Card>
        <PendingTicketsFilter
          onFilterSubmit={onFilterSubmit}
          initialFilters={initialFilters}
        />

        <PendingTicketsExcerpt totalPendingTickets={meta?.total || 0} />

        <PaginationContainer
          total={meta?.total}
          pagesQuantity={meta?.totalPages}
          currentPage={pagination?.currentPage || 1}
          onPagination={changePagination}
        >
          <PendingTicketsTable
            pendingTickets={pendingTickets}
            loading={isLoading}
          />
        </PaginationContainer>

      </Card>
    </>
  )
}

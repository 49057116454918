import { MetaParser } from './Meta'
import { formatISOTime } from 'utils/time'

export const VoucherReportParser = {
  single: (voucher) => {
    const {
      id,
      ticket_slot: activityDate,
      external_ticket_id: externalCode,
      vendor_name: partnerName,
      service_name: activityName,
      tourist_name: passagerName,
      pax_name: paxType,
      external_status: status,
      price: value
    } = voucher

    return {
      id,
      activityDate: activityDate ? formatISOTime(activityDate) : '-',
      externalCode,
      partnerName,
      activityName,
      passagerName,
      paxType,
      status,
      value
    }
  },
  list: ({ meta, vouchers_reports: vouchers = [] }) => {
    return {
      vouchers: vouchers ? vouchers.map(VoucherReportParser.single) : [],
      meta: MetaParser.meta(meta)
    }
  },
  totals: ({ vouchers_report_totalizers: totalizers = {} }) => {
    const {
      integration_tickets_amount: vouchersAmount,
      integration_tickets_price: priceAmount,
      integration_tickets_group_by_pax_name: paxTypes
    } = totalizers

    return {
      vouchersAmount,
      priceAmount,
      paxTypes
    }
  }
}

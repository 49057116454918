/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { TransportAbout } from './TransportAbout'
import { Button, GhostButton, flexRow } from '@bonitour/components'
import { marginTop, marginRight } from 'assets/styles/global'
import { identity } from '@bonitour/common-functions'
import { useForm } from '@bonitour/app-functions'
import { TransportRules } from './TransportRules'
import { transportAboutSchema } from 'core/schemas/transportAbout'
import { object } from 'yup'

const schema = { transportAbout: object(transportAboutSchema).required() }

const marginTop50 = marginTop(50)
const marginRight20 = marginRight(20)

export const TransportAboutForm = ({
  transportAbout: resetTransportAbout = {},
  onBackClick: emitBackClickEvent = identity,
  onSuccess: emitSuccessEvent = identity,
  onValidationError: emitValidationError = identity,
  categories = [],
  infrastructureList = {}
}) => {
  const {
    form,
    errors,
    onSubmit,
    utils: { onInputBlur, onInputChange }
  } = useForm(resetTransportAbout, schema)
  const onClick = onSubmit(emitSuccessEvent, emitValidationError)

  return (
    <>
      <TransportAbout
        transportAbout={form.transportAbout}
        infrastructureList={infrastructureList}
        transportAboutErrors={errors.transportAbout}
        categories={categories}
        onChange={onInputChange}
        onInputBlur={onInputBlur}
      />
      <TransportRules transportRules={form.transportRules} onChange={onInputChange} />
      <div css={[flexRow, marginTop50]}>
        <GhostButton css={marginRight20} onClick={emitBackClickEvent}>
          Voltar
        </GhostButton>
        <Button onClick={onClick}>Salvar e continuar</Button>
      </div>
    </>
  )
}

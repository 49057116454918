/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useCallback, useMemo, useState } from 'react'
import { jsx, css } from '@emotion/core'
import { Button, GhostButton, useToast } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { useForm } from '@bonitour/app-functions'
import { string } from 'yup'
import { ReservationService } from 'services/Reservations/Service'
import { Affiliate } from 'containers/Booking/Form/Affiliate/Affiliate'
import { ghostButtonPrimary } from './EditReservation.style'

const buttonsWrapper = css`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 20px;
  gap: 15px;
`

const defaultFormEvent = () => identity

const allowedStatuses = ['reserved', 'confirmed', 'quotation']
const affiliateSchema = {
  affiliateCode: string().nullable().required('O código de afiliado é obrigatório')
}

export const EditAffiliate = ({
  affiliate = '',
  reservationId = '',
  reservation = {},
  onChange: emitChangeEvent = defaultFormEvent,
  fiscalDuty = ''
}) => {
  const { add: addToast } = useToast()
  const [isEditing, setIsEditing] = useState(false)
  const canEdit = useMemo(() => allowedStatuses.includes(reservation?.status) && !affiliate, [affiliate, reservation])
  const defaultForm = useMemo(() => ({ affiliateCode: affiliate }), [affiliate])
  const hasFiscalDuty = useMemo(() => Boolean(fiscalDuty), [fiscalDuty])

  const {
    form,
    errors,
    onSubmit,
    utils: { onInputChange, onInputBlur }
  } = useForm(defaultForm, affiliateSchema)

  const toggleEdit = useCallback(() => {
    if (hasFiscalDuty) {
      addToast('Não é possível adicionar afiliado a uma reserva com nota fiscal', 'error')
      return
    }
    setIsEditing(!isEditing)
  }, [addToast, hasFiscalDuty, isEditing])

  const cancel = useCallback(() => {
    onInputChange('affiliateCode')(affiliate || '')
    setIsEditing(false)
  }, [affiliate, onInputChange])

  const save = useCallback((form) => {
    setIsEditing(false)
    ReservationService.addAffiliate(reservationId, form.affiliateCode)
      .then(() => {
        emitChangeEvent('affiliateCode')(form.affiliateCode)
        addToast('Afiliado adicionado à reserva', 'success')
      })
      .catch((err) => {
        setIsEditing(true)
        const message = err?.data?.errors_msg?.includes('account::affiliate::not_found')
          ? 'Código de afiliado não encontrado'
          : 'Erro ao adicionar afiliado'
        addToast(message, 'error')
      })
  }, [addToast, emitChangeEvent, reservationId])

  return (
    <Affiliate
      affiliate={form.affiliateCode}
      affiliateErrors={errors.affiliateCode}
      onChange={onInputChange}
      onBlur={onInputBlur}
      isDisabled={!canEdit || !isEditing}
    >
      {canEdit && (
        <div css={buttonsWrapper}>
          {isEditing
            ? (
              <>
                <GhostButton onClick={cancel}>Cancelar</GhostButton>
                <Button onClick={onSubmit(save)}>Adicionar</Button>
              </>
            )
            : (
              <GhostButton onClick={toggleEdit} css={ghostButtonPrimary(hasFiscalDuty)}>
              Adicionar afiliado
              </GhostButton>
            )}
        </div>
      )}
    </Affiliate>
  )
}

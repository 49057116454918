/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { head, tail } from '@bonitour/common-functions'
import { CheckboxInputGroup, flexColumn, H3 } from '@bonitour/components'
import { jsx, css } from '@emotion/core'
import { margin, marginBottom, marginTop } from 'assets/styles/global'

const marginTop50 = marginTop(50)
const marginBottom20 = marginBottom(20)
const marginVertical5 = margin(5, 0, 5, 0)

const checkboxContainer = css`
  display: grid;
  grid-template-columns: auto auto auto auto;
`

export const CheckListItems = ({ title, listLengthValidation, handleFunction, list, haveWhatList }) => {
  return (
    <>
      {listLengthValidation &&
        <div css={flexColumn}>
          <H3 css={[marginTop50, marginBottom20]}>{title}</H3>
          <div css={checkboxContainer}>
            {list.map(option => (
              <div css={marginVertical5} key={head(option)}>
                <CheckboxInputGroup
                  id={head(option)}
                  onChange={handleFunction(head(option))}
                  checked={haveWhatList(head(option))}
                >
                  {tail(option)}
                </CheckboxInputGroup>
              </div>
            ))}
          </div>
        </div>}
    </>
  )
}

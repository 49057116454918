/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { TableContainer, TableBody } from '@bonitour/components'
import { InvoicesBatchTableHeader } from './InvoicesBatchTableHeader'
import { InvoicesBatchTableRow } from './InvoicesBatchTableRow'
import { marginTop } from 'assets/styles/global'
import { Fragment } from 'react'

export const marginTop30 = marginTop(30)

export const InvoicesBatchTable = ({
  invoicesBatch = [],
  loading = false,
  recipientLabel = 'Nome do beneficiário',
  ActionsComponent = Fragment
}) => {
  return (
    <TableContainer css={marginTop30}>
      <InvoicesBatchTableHeader recipientLabel={recipientLabel} />
      <TableBody loading={loading}>
        {invoicesBatch.map((batch, index) =>
          <InvoicesBatchTableRow
            key={index}
            batch={batch}
            ActionsComponent={ActionsComponent}
          />
        )}
      </TableBody>
    </TableContainer>
  )
}

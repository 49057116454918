/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { PaginationContainer, Card, colors, PlusIcon, Button, HeaderPage } from '@bonitour/components'
import { marginBottom } from 'assets/styles/global'
import { useHistory, NavLink } from 'react-router-dom'
import { identity } from '@bonitour/common-functions'
import { AcquirersFilter } from './AcquirersFilter'
import { AcquirerTable } from '../AcquirersTable/AcquirerTable'

const marginBottom30 = marginBottom(30)

const noDecoration = css`
  text-decoration: none;
`

const button = css`
  background-color: transparent;
  display: flex;
  padding: 0;
`

const plusIcon = css`
  padding: 8px 10px;
  border-radius: 50%;
  background-color: ${colors.primary};
  margin-right: 10px;
`

const buttonLabel = css`
  color: ${colors.primary};
  margin: auto;
  font-weight: bold;
  font-size: 16px;
`

export const AcquirersList = ({
  onFilterSubmit = identity,
  loading,
  acquirers = [],
  initialFilters,
  total = 0,
  pagesQuantity = 1,
  onPagination: emitPaginationEvent = identity,
  onDeleteAcquirer = identity
}) => {
  const { push } = useHistory()
  const goToDashboard = () => push('/dashboard')

  return (
    <>
      <HeaderPage onBack={goToDashboard} title='Adquirentes' />

      <Card css={marginBottom30}>
        <AcquirersFilter
          onFilterSubmit={onFilterSubmit}
          acquirers={acquirers}
          loading={loading}
          initialFilters={initialFilters}
        />

        <PaginationContainer total={total} pagesQuantity={pagesQuantity} onPagination={emitPaginationEvent}>
          <AcquirerTable acquirers={acquirers} onDeleteAcquirer={onDeleteAcquirer} loading={loading} />
        </PaginationContainer>

        <NavLink to='/acquirers/create' css={noDecoration}>
          <Button css={button}>
            <PlusIcon css={plusIcon} />
            <span css={buttonLabel}>Novo adquirente</span>
          </Button>
        </NavLink>
      </Card>
    </>
  )
}

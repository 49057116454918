/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useRef } from 'react'
import { identity } from '@bonitour/common-functions'
import { useClickOutside } from '@bonitour/app-functions'
import { clickable, loadingContainer, margin } from 'assets/styles/global'
import { EllipsisIcon, Label, LoadingAnimation, PlusIcon, Popover, TrashIcon, Reference, Manager, EditInfoIcon, flexCenteredSpaceBetweenRow } from '@bonitour/components'
import { buttonContainer, observationOptionsContainer, linkObservationIcon, linkObservationLabel, linkPickupOptionsIcon, marginHorizontal50, localNameStyle, observationOption, observationTicketIcon, linkObservationContainer } from './ObservationButton.style'

const margin5 = margin(5, 5, 5, 5)

export const ObservationButton = ({
  ticketId = '',
  observation = '',
  observationLoading = false,
  onOpenObservationModal = identity,
  clearObservation = identity
}) => {
  const reference = useRef()
  const [isObservationOptionsVisible, setObservationOptionsVisible] = useClickOutside(reference)
  const onClickPassengerOptions = () => setObservationOptionsVisible(!isObservationOptionsVisible)

  if (ticketId === observationLoading) {
    return (
      <div css={linkObservationContainer}>
        <LoadingAnimation size={25} css={[loadingContainer, marginHorizontal50]} />
      </div>
    )
  }

  return (
    <div css={linkObservationContainer}>
      {observation &&
        <div css={[buttonContainer, flexCenteredSpaceBetweenRow, margin5]}>
          <span css={localNameStyle}>{observation}</span>

          <div ref={reference}>
            <Manager>
              <Reference>
                {({ ref }) => (
                  <div css={clickable} ref={ref} onClick={onClickPassengerOptions}>
                    <EllipsisIcon css={linkPickupOptionsIcon} />
                  </div>
                )}
              </Reference>
              {isObservationOptionsVisible && !observationLoading && (
                <Popover position='left'>
                  <div css={observationOptionsContainer}>
                    <div css={observationOption} onClick={onOpenObservationModal}>
                      <EditInfoIcon css={observationTicketIcon} />
                      <span>Editar</span>
                    </div>
                    <div css={observationOption} onClick={clearObservation}>
                      <TrashIcon css={observationTicketIcon} />
                      <span>Limpar</span>
                    </div>
                  </div>
                </Popover>
              )}
            </Manager>
          </div>
        </div>}

      {!observation &&
        <div css={[buttonContainer, clickable]} onClick={onOpenObservationModal}>
          <PlusIcon css={linkObservationIcon} />
          <Label css={linkObservationLabel}>Adicionar observação</Label>
        </div>}
    </div>
  )
}

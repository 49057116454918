/** @jsxRuntime classic */
/** @jsx jsx */
import { identity } from '@bonitour/common-functions'
import { css, jsx } from '@emotion/core'
import QrReader from 'react-qr-reader'
import { CircleButton, CloseIcon, colors, H1 } from '@bonitour/components'

const readerContainer = css`
  background-color: rgba(0,0,0,0.67);
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  place-content: center;
  cursor: pointer;
`

const qrReader = css`
  position: relative;
  width: 400px;
  height: 400px;
  max-width: min(calc(100vh - 4rem), 100vw);
  max-height: min(calc(100vh - 4rem), 100vw);
  cursor: default;

  &:before, 
  &:after,
  &>:first-child:before, 
  &>:first-child:after {
    z-index: 2;
    position: absolute;
    width: 80px; 
    height: 80px;
    border-color: ${colors.primary}; 
    border-style: solid;
    content: ' ';
  }
  
  &:before {
    top:0;
    left:0;
    border-width: 5px 0 0 5px
  }

  &:after {
    top:0;
    right:0;
    border-width: 5px 5px 0 0
  }

  &>:first-child:before {
    bottom: 0;
    right:0;
    border-width: 0 5px 5px 0
  }

  &>:first-child:after {
    bottom: 0;
    left:0;
    border-width: 0 0 5px 5px
  }

  div {
    box-shadow: none !important;
  }
`

const changeTitle = css`
  color: ${colors.white};
  background-color: ${colors.gray3};
  padding: 1rem 0.75rem;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
  cursor: default;
`

const closeButton = css`
  padding: 1.25rem;
  position: absolute;
  top: max(calc(50vh - 260px), 1rem);
  right: max(calc(50vw - 230px), 1rem);
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  place-content: center;
  transform: scale(1.25);
`

const stopPropagation = (event) => event.stopPropagation()

export const TicketCheckinReader = ({
  isVisible = false,
  onSuccess = identity,
  onClose = identity
}) => {
  const onScan = (code) => {
    if (code) {
      onClose()
      onSuccess({ code })
    }
  }

  if (!isVisible) {
    return null
  }

  return (
    <div css={readerContainer} onClick={onClose}>
      <CircleButton css={closeButton} onClick={onClose}>
        <CloseIcon onClick={onClose} />
      </CircleButton>
      <H1 css={changeTitle} onClick={stopPropagation}>
        Posicione o código para utilizar
      </H1>
      <div onClick={stopPropagation}>
        <QrReader
          delay={400}
          onError={console.error}
          onScan={onScan}
          css={qrReader}
        />
      </div>
    </div>
  )
}

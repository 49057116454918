/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import {
  Card,
  flexRowCentered,
  flexCenteredSpaceBetweenRow,
  InfoIcon,
  Manager,
  Reference,
  Popover,
  P,
  InputMoneyMask
} from '@bonitour/components'
import {
  container,
  bold,
  amountDueContainer,
  amountDueLabel,
  amountDueStyle,
  invoiceInfoIconStyle,
  invoiceInfoIconOpenedStyle,
  popoverStyle,
  popoverContentContainer,
  invoiceDetailTitle,
  invoiceDetailItems,
  popoverContainer
} from './InvoiceAmounts.style'
import { formatMoney, head, tail } from '@bonitour/common-functions'
import { clickable, hidden, marginBottom } from 'assets/styles/global'
import { useClickOutside } from '@bonitour/app-functions'
import { useRef } from 'react'
import { paymentTypesMap } from 'constants/reservationPaymentTypes'

const marginBottom20 = marginBottom(20)

export const InvoiceDescription = ({ description }) => {
  return (
    <div css={invoiceDetailItems}>
      <p>{paymentTypesMap[head(description)]}</p>
      <InputMoneyMask
        value={formatMoney(tail(description))}
        disabled
      />
    </div>
  )
}

export const InvoiceAmounts = ({ invoiceAmounts = {}, amountDue = 0, receivedValueDescription = {} }) => {
  const reference = useRef()
  const [isVisible, setVisibility] = useClickOutside(reference)
  const toggleVisibility = () => setVisibility(isVisible => !isVisible)

  const { total = 0, tax = 0, discount = 0, received = 0, credited = 0 } = invoiceAmounts

  const formatValue = value => formatMoney(value || 0)

  const getReceivedDescriptionData = type => Object.entries(receivedValueDescription).filter(description => head(description) === type)
  const parseDescription = description => Object.assign({}, tail(head(description)))
  const checkArrayEmptiness = arr => !(Object.keys(arr).length === 0)

  const invoiced = getReceivedDescriptionData('invoiced')
  const parsedInvoiced = parseDescription(invoiced)
  const isInvoicedEmpty = checkArrayEmptiness(parsedInvoiced)

  const antecipated = getReceivedDescriptionData('advanced')
  const parsedAntecipated = parseDescription(antecipated)
  const isAntecipateddEmpty = checkArrayEmptiness(parsedAntecipated)

  return (
    <div css={container}>
      <div css={[flexCenteredSpaceBetweenRow, marginBottom20]}>
        <span>Total</span>
        <span css={bold}>{formatValue(total)}</span>
      </div>

      <div css={[flexCenteredSpaceBetweenRow, marginBottom20]}>
        <span>Imposto retido</span>
        <span css={bold}>{formatValue(tax)}</span>
      </div>

      <div css={[flexCenteredSpaceBetweenRow, marginBottom20]}>
        <span>Desconto</span>
        <span css={bold}>{formatValue(discount)}</span>
      </div>

      <div css={[flexCenteredSpaceBetweenRow, marginBottom20]}>
        <span css={[flexRowCentered, popoverContainer]}>
          Recebido
          <Manager>
            <Reference>
              {({ ref }) => (
                <div ref={ref} onClick={toggleVisibility} css={[clickable]}>
                  <InfoIcon css={[isVisible ? invoiceInfoIconOpenedStyle : invoiceInfoIconStyle]} />
                </div>
              )}
            </Reference>
            <Popover position='top' css={[!isVisible && hidden, popoverStyle]}>
              <div css={popoverContentContainer}>
                {isAntecipateddEmpty && <P css={invoiceDetailTitle}>Antecipado</P>}
                {Object.entries(parsedAntecipated).map((antecipated, key) => (
                  <InvoiceDescription key={key} description={antecipated} />
                ))}
                {isInvoicedEmpty && <P css={invoiceDetailTitle}>Faturado</P>}
                {Object.entries(parsedInvoiced).map((invoice, key) => (
                  <InvoiceDescription key={key} description={invoice} />
                ))}
              </div>
            </Popover>
          </Manager>
        </span>
        <div css={flexRowCentered}>
          <span css={bold}>{formatValue(received)}</span>
        </div>
      </div>

      <div css={[flexCenteredSpaceBetweenRow, marginBottom20]}>
        <span>Valor creditado</span>
        <span css={bold}>{formatValue(credited)}</span>
      </div>

      <Card css={[amountDueContainer, marginBottom20]}>
        <p css={amountDueLabel}>Valor devido</p>
        <span css={[amountDueStyle, bold]}>{formatValue(amountDue)}</span>
      </Card>
    </div>
  )
}

import React from 'react'
import { Layout } from 'containers/Layout/Layout'
import { PrivateRoutes } from 'routes/private'

import { UserProvider } from 'contexts/User'
import { PermissionsProvider } from 'contexts/Permissions'
import { CompanyProvider } from 'contexts/Company'
import { ActivityProvider } from 'contexts/Activity'
import { Feature } from 'contexts/Feature'
import { CookieConsentHandler } from 'domains/Cookies/CookieConsentHandler'
import { TermsHandler } from 'domains/Terms/TermsHandler'
import { QueryClientProvider } from 'react-query'
import { queryClient } from 'queryClient'

const PrivateRouteWrapper = () => {
  return (
    <UserProvider>
      <QueryClientProvider client={queryClient}>
        <PermissionsProvider>
          <CompanyProvider>
            <ActivityProvider>
              <Feature>
                <Layout>
                  <PrivateRoutes />
                  <CookieConsentHandler />
                  <TermsHandler />
                </Layout>
              </Feature>
            </ActivityProvider>
          </CompanyProvider>
        </PermissionsProvider>
      </QueryClientProvider>
    </UserProvider>
  )
}

export const routes = [
  {
    path: '/',
    component: PrivateRouteWrapper
  }
]

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Dialog, InputWithSuffix, LoadingAnimation, SearchIcon, useToast } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { useAffiliateList } from 'containers/Booking/hooks/useAffiliateList'
import { useCallback, useEffect } from 'react'
import { AffiliatesTable } from './AffiliatesTable'
import { iconSize, fullWidth, label, affiliateDialogStyle, centralizedDiv, paddingOnTopAndBottom, wrapperInput } from './Affiliate.styles'

export const AffiliateSelector = ({
  onClose = identity,
  onSelectAffiliate: emitAffiliateChangeEvent = identity,
  isVisible = false
}) => {
  const { add: addToast } = useToast()

  const {
    fetchAffiliates,
    affiliates,
    isLoading,
    fetchMoreAffiliates,
    handleFilters,
    currentFilters,
    isFirstLoad,
    resetData
  } = useAffiliateList()

  useEffect(() => {
    if (!isVisible) return
    fetchAffiliates()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible])

  const onCloseModal = useCallback(() => {
    if (isLoading) return
    resetData()
    onClose()
  }, [isLoading, onClose, resetData])

  const onSelectAffiliate = useCallback((affiliateCode = '') => () => {
    emitAffiliateChangeEvent(affiliateCode)
    onCloseModal()
    addToast('Afiliado selecionado', 'success')
  }, [addToast, emitAffiliateChangeEvent, onCloseModal])

  const onFiltersChange = useCallback((key) => (value) => {
    handleFilters({ ...currentFilters, [key]: value })
  }, [currentFilters, handleFilters])

  return <Dialog onClose={onCloseModal} isVisible={isVisible} title='Selecione um afiliado' customContainercss={[affiliateDialogStyle]}>
    <div>
      <p css={label}>Afiliados ativos</p>
      <div css={wrapperInput}>
        <InputWithSuffix
          css={fullWidth}
          placeholder={'Busque pelo nome do afiliado'}
          onChange={onFiltersChange('name')}
          value={currentFilters?.name || ''}
        >
          <SearchIcon css={iconSize} />
        </InputWithSuffix>
      </div>
      {isFirstLoad
        ? (
          <div css={[centralizedDiv, paddingOnTopAndBottom]}>
            <LoadingAnimation/>
          </div>
        )
        : (
          <AffiliatesTable
            affiliates={affiliates}
            onSelectAffiliate={onSelectAffiliate}
            fetchMoreAffiliates={fetchMoreAffiliates}
            isLoading={isLoading}
          />
        )}
    </div>
  </Dialog>
}

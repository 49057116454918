/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useCallback, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Column, flexHorizontallyCentered, GhostButton, Input, InputFormGroup, Row, useToast, Card, InputCnpjMask } from '@bonitour/components'
import { useForm } from '@bonitour/app-functions'
import { usePermissions } from 'hooks/usePermissions'
import { marginBottom, marginRight } from 'assets/styles/global'
import { externalProviderSchema } from './ExternalProviderForm.schema'
import { Address } from 'containers/Address/Address'
import { useAddressLocation, useGeoCode, useZipCode } from 'containers/Address/Address.hooks'

const marginBottom20 = marginBottom(20)
const marginRight20 = marginRight(20)

export const ExternalProviderForm = ({ externalProvider, submit, loading }) => {
  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()
  const history = useHistory()

  const redirectToList = () => history.push('/external-providers')

  const externalProviderBase = useMemo(() => externalProvider || { name: '', document: '', addressInfo: {} }, [externalProvider])

  const { addressInfo: { country, state } = {} } = externalProviderBase ?? {}
  const { countries, states, cities, updateAvailableStates, updateAvailableCities } = useAddressLocation(addToast, country, state)
  const { form, errors, setForm, onSubmit, utils: { onInputBlur, onInputChange } } = useForm(externalProviderBase, externalProviderSchema(states, cities))
  const { name = externalProviderBase.name, document = externalProviderBase.document, addressInfo = externalProviderBase.addressInfo } = form
  const [latLong, onGeoCodeUpdate] = useGeoCode(addToast)
  const [zipCodeData, onZipCodeUpdate] = useZipCode(addToast)

  const onAddressInputBlur = useCallback(addressInputName => onInputBlur(`addressInfo.${addressInputName}`), [onInputBlur])
  const onAddressInputChange = useCallback(addressInputName => onInputChange(`addressInfo.${addressInputName}`), [onInputChange])

  const onCountryChange = useCallback(
    (countryValue) => {
      const country = countries.find((country) => Object.values(country).includes(countryValue))
      if (country) {
        updateAvailableStates(country.value)
        onInputChange('addressInfo.country')(country.value)
      } else {
        updateAvailableStates(null)
        onInputChange('addressInfo.country')('')
        onInputChange('addressInfo.state')('')
        onInputChange('addressInfo.city')('')
      }
    },
    [countries, updateAvailableStates, onInputChange]
  )

  const onStateChange = useCallback(
    (stateValue) => {
      const state = states.find((state) => Object.values(state).includes(stateValue))
      if (state) {
        updateAvailableCities(addressInfo?.country, state.value)
        onInputChange('addressInfo.state')(state.value)
      } else {
        updateAvailableCities(null)
        onInputChange('addressInfo.state')('')
        onInputChange('addressInfo.city')('')
      }
    },
    // eslint-disable-next-line
    [states, updateAvailableCities, addressInfo?.country, onInputChange]
  )

  const onCityChange = useCallback(
    (cityValue) => {
      const city = cities.find((city) => Object.values(city).includes(cityValue))
      if (city) {
        onInputChange('addressInfo.city')(city.value)
      } else {
        onInputChange('addressInfo.city')('')
      }
    },
    [cities, onInputChange]
  )

  const onZipCodeBlur = () => {
    onZipCodeUpdate(form.addressInfo?.zipCode)
    onInputBlur('addressInfo.zipCode')()
  }

  const updateAddress = useCallback(() => {
    if (Object.keys(zipCodeData).length) {
      setForm((previousForm) => ({
        ...previousForm,
        addressInfo: {
          ...previousForm.addressInfo,
          street: zipCodeData.address,
          district: zipCodeData.district,
          state: zipCodeData.state,
          city: zipCodeData.city
        }
      }))
      onCountryChange('BR')
    }
  }, [setForm, onCountryChange, zipCodeData])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updateAddress, [zipCodeData])

  const updateCountries = useCallback(() => {
    if (countries?.length) {
      onCountryChange(form.addressInfo?.country)
    }
    // eslint-disable-next-line
  }, [countries, onCountryChange, form.addressInfo?.country])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updateCountries, [countries])

  const updateStates = useCallback(() => {
    if (states?.length) {
      onStateChange(form.addressInfo?.state)
    }
    // eslint-disable-next-line
  }, [states, onStateChange, form.addressInfo?.state])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updateStates, [states])

  const updateCities = useCallback(() => {
    if (cities?.length) {
      onCityChange(form.addressInfo?.city)
    }
    // eslint-disable-next-line
  }, [cities, onCityChange, form.addressInfo?.city])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updateCities, [cities])

  useEffect(() => {
    onInputChange('addressInfo')({ ...addressInfo, ...latLong })
    // eslint-disable-next-line
  }, [latLong])

  const onValidationError = useCallback(() => {
    addToast('Preencha corretamente o formulário')
    // eslint-disable-next-line
  }, [])

  const onValidationSuccess = useCallback((data) => {
    submit(data)
      .then(() => {
        addToast('Fornecedor externo salvo com sucesso', 'success')
        redirectToList()
      })
      .catch((error) => {
        const hasPermission = handlePermission(error, 'Você não possui permissão de salvar o fornecedor externo')
        hasPermission && addToast('Erro ao salvar o fornecedor externo')
      })
    // eslint-disable-next-line
  }, [])

  const onSaveClick = useMemo(() => onSubmit(onValidationSuccess, onValidationError), [onSubmit, onValidationError, onValidationSuccess])

  return (
    <div>
      <Card css={marginBottom20}>
        <Row>
          <Column phone={12} desktop={6}>
            <InputFormGroup errorMessage={errors.name} label='Nome'>
              <Input value={name} onChange={onInputChange('name')} onBlur={onInputBlur('name')} disabled={loading} />
            </InputFormGroup>
          </Column>
          <Column phone={12} desktop={6}>
            <InputFormGroup id='document' label='CNPJ' errorMessage={errors.document} required>
              <InputCnpjMask value={document} onChange={onInputChange('document')} onBlur={onInputBlur('document')} />
            </InputFormGroup>
          </Column>
        </Row>
        <Address
          addressInfo={addressInfo}
          addressInfoErrors={errors.addressInfo}
          onInputBlur={onAddressInputBlur}
          onInputChange={onAddressInputChange}
          onCountryChange={onCountryChange}
          onStateChange={onStateChange}
          onZipCodeBlur={onZipCodeBlur}
          onLatLongBlur={onGeoCodeUpdate}
          countries={countries}
          states={states}
          cities={cities}
          hideCompanyAddressButton
        />
      </Card>

      <div css={flexHorizontallyCentered}>
        <GhostButton disabled={loading} css={marginRight20} onClick={redirectToList}>Voltar</GhostButton>
        <Button onClick={onSaveClick} disabled={loading}>Salvar</Button>
      </div>
    </div>
  )
}

/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */

import { jsx } from '@emotion/core'
import {
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow
} from '@bonitour/components'
import { formatMoney } from '@bonitour/common-functions'
import {
  increaseFontSizeStyle,
  referenceStyle,
  releaseStyle,
  scrolledContainerStyle
} from './BookingEditionDiffStyles'
import { useMemo } from 'react'

const BookingStrikeThroughData = ({
  oldValue = '',
  newValue = '',
  priceProperties = {},
  isShowPriceDetails = false
}) => {
  const isSameValue = useMemo(() => !isShowPriceDetails && oldValue === newValue, [isShowPriceDetails, newValue, oldValue])

  const arrowLabel = useMemo(() => isSameValue ? '' : '↳', [isSameValue])

  const [newPrice, oldPrice, className] = useMemo(() => {
    const oldValue = priceProperties?.oldValue
    const newValue = priceProperties?.newValue
    const diffValue = priceProperties?.diffValue

    let returnData = []

    if (newValue > oldValue) {
      returnData = [diffValue, oldValue, 'is-positive']
    } else if (newValue < oldValue) {
      returnData = [diffValue, oldValue, 'is-negative']
    } else {
      returnData = [oldValue, oldValue, 'is-equal']
    }

    return returnData
  }, [priceProperties?.diffValue, priceProperties?.newValue, priceProperties?.oldValue])

  const hasPriceDiff = useMemo(() => !(className === 'is-equal'), [className])

  if (isShowPriceDetails) {
    return <div css={referenceStyle} className={`price-container ${className}`}>
      {formatMoney(newPrice)}
      {hasPriceDiff ? <p css={releaseStyle}>{formatMoney(oldPrice)}</p> : ''}
    </div>
  }

  return <div css={referenceStyle}>
    {arrowLabel} {newValue}
    {!isSameValue ? <p css={releaseStyle}>{oldValue}</p> : ''}
  </div>
}

export const BookingEditionDiffTable = ({ modalData = {} }) => {
  const priceProperties = useMemo(() => modalData?.priceProperties || [], [modalData?.priceProperties])

  return <div css={scrolledContainerStyle}>
    <TableContainer>
      <TableHeader>
        <TableHeaderRow>
          <TableHeaderCell css={increaseFontSizeStyle}>
            Ticket
          </TableHeaderCell>
          <TableHeaderCell css={increaseFontSizeStyle}>
            Tipo
          </TableHeaderCell>
          <TableHeaderCell css={increaseFontSizeStyle}>
            Data
          </TableHeaderCell>
          <TableHeaderCell css={increaseFontSizeStyle}>
            Horário
          </TableHeaderCell>
          <TableHeaderCell css={increaseFontSizeStyle}>
            Valor
          </TableHeaderCell>
        </TableHeaderRow>
      </TableHeader>
      <TableBody>
        {(modalData?.ticketList || []).map(({ code, oldDate, newDate, oldHour, newHour, newValue, oldValue, diffValue, type }, index) => (
          <TableBodyRow key={`${index}-${code}-booking-edition-diff-table`}>
            <TableBodyCell css={increaseFontSizeStyle}>
              {code}
            </TableBodyCell>
            <TableBodyCell css={increaseFontSizeStyle}>
              {type}
            </TableBodyCell>
            <TableBodyCell css={increaseFontSizeStyle}>
              <BookingStrikeThroughData newValue={newDate} oldValue={oldDate}/>
            </TableBodyCell>
            <TableBodyCell css={increaseFontSizeStyle}>
              <BookingStrikeThroughData newValue={newHour} oldValue={oldHour}/>
            </TableBodyCell>
            <TableBodyCell css={increaseFontSizeStyle}>
              <BookingStrikeThroughData
                priceProperties={{
                  ...(priceProperties),
                  newValue,
                  oldValue,
                  diffValue
                }}
                isShowPriceDetails={true}
              />
            </TableBodyCell>
          </TableBodyRow>
        ))}
      </TableBody>
    </TableContainer>
  </div>
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { clickable } from 'assets/styles/global'

export const ExternalCodeAction = ({ handleFunction, ticketId, title, children }) => {
  return (
    <span onClick={() => handleFunction(ticketId)} css={clickable}>
      {children}
      {title}
    </span>
  )
}

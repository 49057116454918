/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { TableContainer, TableHeader, TableHeaderRow, TableHeaderCell, TableBody, scrollBar } from '@bonitour/components'
import { ReservationProviderTransactionRow } from './ProviderRow'
import { identity } from '@bonitour/common-functions'

const tableContainer = css`
  overflow-x: auto;
  ${scrollBar(5)};
`

export const ReservationProviderTransactionTable = ({
  card = false,
  transactions = [],
  onPaymentShow = identity,
  isTransactionARefund = identity,
  loading
}) => {
  return (
    <div css={tableContainer}>
      <TableContainer card={card}>
        <TableHeader>
          <TableHeaderRow>
            <TableHeaderCell>Operação</TableHeaderCell>
            <TableHeaderCell>Destino</TableHeaderCell>
            <TableHeaderCell>Aut/Doc</TableHeaderCell>
            <TableHeaderCell>Data e hora</TableHeaderCell>
            <TableHeaderCell>Valor</TableHeaderCell>
            <TableHeaderCell>Forma de Pagamento</TableHeaderCell>
            <TableHeaderCell>Status</TableHeaderCell>
            <TableHeaderCell fixed>Ação</TableHeaderCell>
          </TableHeaderRow>
        </TableHeader>
        <TableBody loading={loading}>
          {transactions.map(payment => (
            <ReservationProviderTransactionRow
              key={payment.id}
              onPaymentShow={onPaymentShow}
              isTransactionARefund={isTransactionARefund}
              {...payment}
            />
          ))}
        </TableBody>
      </TableContainer>
    </div>
  )
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { TableHeaderCell, TableHeaderRow, TableHeader } from '@bonitour/components'

const width10 = css`
  width: 10%;
  text-align: left;
`

export const AcquirerTableHeader = () => {
  return (
    <TableHeader>
      <TableHeaderRow>
        <TableHeaderCell>Adquirente</TableHeaderCell>
        <TableHeaderCell>Bandeira</TableHeaderCell>
        <TableHeaderCell>Tipo</TableHeaderCell>
        <TableHeaderCell css={width10} fixed>Ações</TableHeaderCell>
      </TableHeaderRow>
    </TableHeader>
  )
}

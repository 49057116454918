import { useCallback, useEffect, useMemo, useState } from 'react'
import { InvoiceService } from 'core/services/Invoice'
import { useToast } from '@bonitour/components'
import { useHistory } from 'react-router-dom'
import { useCompany } from 'contexts/Company'

export const useInvoice = (invoiceId = '', invoiceType) => {
  const [invoice, setInvoice] = useState({ amount: {}, info: {} })
  const [invoiceDetails, setInvoiceDetails] = useState({})
  const [loading, setLoading] = useState(false)
  const { id: companyId } = useCompany()

  const { push } = useHistory()
  const { add: addToast } = useToast()
  const { info: { vendorId, status: invoiceStatus } = {} } = invoice

  const updateInvoice = useCallback(() => {
    setLoading(true)
    InvoiceService.getInvoice(invoiceId).then(invoiceData => {
      const { id, payerId, vendorId } = invoiceData?.info

      if (invoiceType === 'pay' && companyId === vendorId && payerId !== vendorId) {
        return push(`/invoices-receive/${id}/edit`)
      }

      if (invoiceType === 'receive' && companyId === payerId && payerId !== vendorId) {
        return push(`/invoices-pay/${id}/edit`)
      }

      setInvoice(invoiceData)
    }).catch((error) => {
      if (error === 'financial::invoice::not_found' || error === 'account::invoice::not_found') {
        push('/dashboard')
        addToast('Fatura não encontrada')
      }
    }).finally(() => setLoading(false))
  }, [invoiceId, companyId, invoiceType, addToast, push])

  const amountDue = useMemo(() => {
    if (invoice.amount) {
      const { amountLeft } = invoice.amount
      return amountLeft
    }
    return 0
  }, [invoice.amount])

  const payOffInvoice = async () => {
    try {
      await InvoiceService.payOffInvoice(invoiceId)
      updateInvoice()
      addToast('Fatura quitada', 'success')
    } catch (error) {
      addToast('Ocorreu um erro ao quitar a fatura')
    }
  }

  useEffect(() => {
    updateInvoice()
  }, [updateInvoice])

  const paginateInvoiceDetails = useCallback((currentPage = 1, perPage = 10) => {
    InvoiceService.getInvoiceDetails(invoiceId, { currentPage, perPage }).then(setInvoiceDetails)
  }, [invoiceId])

  const saveInvoice = useCallback(async (payload) => {
    try {
      await InvoiceService.updateInvoice(invoiceId, payload)
      addToast('Fatura salva com sucesso', 'success')
      updateInvoice()
    } catch (error) {
      addToast('Ocorreu um erro ao salvar a fatura')
    }
  }, [updateInvoice, invoiceId, addToast])

  const sendInvoice = useCallback(async (payload) => {
    try {
      await InvoiceService.sendInvoice(invoiceId, payload)
      addToast('Fatura enviada com sucesso', 'success')
      updateInvoice()
    } catch (error) {
      const errorMessage = error?.data?.errors_msg?.[0]

      const errorMessages = {
        'financial::invoice::invalid_discount_or_tax': 'A soma do desconto com imposto não pode ser maior que o valor devido',
        default: 'Ocorreu um erro ao enviar a fatura'
      }

      addToast(errorMessages[errorMessage] ?? errorMessages.default)
    }
  }, [updateInvoice, invoiceId, addToast])

  const isInvoiceSent = useMemo(() => invoiceStatus !== 'draft', [invoiceStatus])

  const isInvoicePaid = useMemo(() => invoiceStatus === 'paid', [invoiceStatus])

  const isInvoiceOpen = useMemo(() => invoiceStatus === 'open', [invoiceStatus])

  const isInvoiceDraft = useMemo(() => invoiceStatus === 'draft', [invoiceStatus])

  const canPayOffInvoice = useMemo(() => {
    const payOffInvoiceStatusShow = ['pending', 'overdue', 'partially_paid']
    return companyId === vendorId && amountDue < 0 && payOffInvoiceStatusShow.includes(invoiceStatus)
  }, [companyId, vendorId, amountDue, invoiceStatus])

  return {
    invoice,
    invoiceDetails,
    payOffInvoice,
    amountDue,
    updateInvoice,
    paginateInvoiceDetails,
    isInvoiceSent,
    isInvoicePaid,
    isInvoiceOpen,
    isInvoiceDraft,
    loading,
    saveInvoice,
    sendInvoice,
    canPayOffInvoice,
    vendorId,
    companyId
  }
}

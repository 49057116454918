/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useCallback, useRef } from 'react'
import { useClickOutside } from '@bonitour/app-functions'
import { Manager, Reference, Popover } from '@bonitour/components'
import { clickable } from 'assets/styles/global'
import { BookingCreationAndLinkageGuide } from './BookingCreationAndLinkageGuide'

const popoverContainer = css`
  flex-direction: column;
`

export const VacancyPopover = ({ hour = '', totalVacancies = 0, date = '', PopoverContent = BookingCreationAndLinkageGuide, ticketTariff, children }) => {
  const reference = useRef()
  const [isVisible, setVisibility] = useClickOutside(reference)

  const toggleVisibility = useCallback(() => setVisibility(!isVisible), [isVisible, setVisibility])
  return (
    <div ref={reference}>
      <Manager>
        <Reference>
          {({ ref }) => (
            <div ref={ref} onClick={toggleVisibility} css={clickable}>
              {children}
            </div>
          )}
        </Reference>
        {
          isVisible && (
            <Popover position='right' css={popoverContainer}>
              <PopoverContent
                hour={hour}
                date={date}
                ticketTariff={ticketTariff}
                totalVacancies={totalVacancies}
              />
            </Popover>
          )
        }
      </Manager>
    </div>
  )
}

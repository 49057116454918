/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Card, colors } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { OfflineTicketForm } from 'containers/OfflineExperiencesTicketForm/OfflineTicketForm'

const darkBackground = css`
  background-color: ${colors.gray13};
  margin-bottom: 50px;
`

export const OfflineReservationHeader = ({
  form = {},
  errors = {},
  onInputChange = identity,
  onInputBlur = identity,
  offlineExperienceCategory = '',
  disabled = false,
  isOpenConfirmationModal = false,
  ...other
}) => {
  return (
    <Card padding='20' css={darkBackground} {...other}>
      <OfflineTicketForm
        form={form}
        errors={errors}
        onInputChange={onInputChange}
        onInputBlur={onInputBlur}
        category={offlineExperienceCategory}
        disabled={disabled}
        isOpenConfirmationModal={isOpenConfirmationModal}
      />
    </Card>
  )
}

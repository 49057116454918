/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { InputFormGroup, Input, Card, Row, Column, H4, flexHorizontallyCentered, colors } from '@bonitour/components'
import { marginBottom, marginRight, marginTop } from 'assets/styles/global'

const marginTop40 = marginTop(40)
const marginBottom20 = marginBottom(20)
const marginRight10 = marginRight(10)

const cardContrast = css`
  margin-bottom: 20px;
  padding: 15px;
  background-color: ${colors.gray13};
  border: none;
`

export const ReservationAccountable = ({
  reservationInfo = {}
}) => {
  const { accountable = '' } = reservationInfo

  return (
    <>
      <div css={[flexHorizontallyCentered, marginTop40]}>
        <H4 css={marginRight10}>Responsável pela venda</H4>
      </div>
      <Card css={cardContrast}>
        <Row>
          <Column desktop={6}>
            <InputFormGroup css={marginBottom20} label='Empresa'>
              <Input value={accountable} disabled />
            </InputFormGroup>
          </Column>
        </Row>
      </Card>
    </>
  )
}

import { validateTime } from '@bonitour/common-functions'
import { string, number } from 'yup'

export const vacancySchedulesSchema = {
  time: string()
    .required('O tempo de duração deve ser um horário valido')
    .test('test-validateTime', 'O tempo de duração deve ser um horário valido', validateTime),
  vacancyQuantity: number()
    .typeError('Deve ser um número')
    .required('O número de vagas deve ser preenchido')
    .min(1, 'O número de vagas precisa estar preenchido e ser maior que 0'),
  sundayQuantity: number()
    .typeError('Deve ser um número')
    .required('O número de vagas deve ser preenchido')
    .min(0, 'O número de vagas precisa estar preenchido e ser igual ou maior que 0'),
  mondayQuantity: number()
    .typeError('Deve ser um número')
    .required('O número de vagas deve ser preenchido')
    .min(0, 'O número de vagas precisa estar preenchido e ser igual ou maior que 0'),
  tuesdayQuantity: number()
    .typeError('Deve ser um número')
    .required('O número de vagas deve ser preenchido')
    .min(0, 'O número de vagas precisa estar preenchido e ser igual ou maior que 0'),
  wednesdayQuantity: number()
    .typeError('Deve ser um número')
    .required('O número de vagas deve ser preenchido')
    .min(0, 'O número de vagas precisa estar preenchido e ser igual ou maior que 0'),
  thursdayQuantity: number()
    .typeError('Deve ser um número')
    .required('O número de vagas deve ser preenchido')
    .min(0, 'O número de vagas precisa estar preenchido e ser igual ou maior que 0'),
  fridayQuantity: number()
    .typeError('Deve ser um número')
    .required('O número de vagas deve ser preenchido')
    .min(0, 'O número de vagas precisa estar preenchido e ser igual ou maior que 0'),
  saturdayQuantity: number()
    .typeError('Deve ser um número')
    .required('O número de vagas deve ser preenchido')
    .min(0, 'O número de vagas precisa estar preenchido e ser igual ou maior que 0')
}

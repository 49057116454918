import { useCallback, useEffect, useState } from 'react'
import { useToast } from '@bonitour/components'
import { useParamsFilter } from 'hooks/useParamsFilter'
import { usePermissions } from 'hooks/usePermissions'
import { ExternalProviderService } from 'core/services/ExternalProvider'

export const useListExternalProvider = () => {
  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()
  const [filters, setFilters] = useParamsFilter()
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({ currentPage: 1, perPage: 10 })
  const [externalProvidersData, setExternalProvidersData] = useState({ externalProviders: [], meta: undefined })

  const changePagination = useCallback((currentPage, perPage) => {
    setPagination({
      currentPage,
      perPage
    })
    // eslint-disable-next-line
  }, [])

  const fetchExternalProviders = useCallback(({ filters, pagination }) => {
    setLoading(true)
    return ExternalProviderService.list({ filters, pagination }).then((data) => setExternalProvidersData(data))
      .catch((error) => {
        const hasPermission = handlePermission(error, 'Você não possui permissão de acesso aos fornecedores externos')
        hasPermission && addToast('Ocorreu um erro ao realizar a busca')
      })
      .finally(() => setLoading(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchExternalProviders({ pagination, filters })
  }, [pagination, filters, fetchExternalProviders])

  return {
    filters,
    setFilters,
    pagination,
    changePagination,
    externalProviders: externalProvidersData?.externalProviders,
    externalProvidersOptions: externalProvidersData?.externalProviders?.map(({ name, id }) => ({ label: name, value: id })),
    meta: externalProvidersData?.meta,
    fetchExternalProviders,
    loading
  }
}

/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { useMemo } from 'react'
import { Card, H3 } from '@bonitour/components'
import { cardStyle, cardItemStyle, refundLabelStyle } from './ReservationTimelineCard.style'
import { formatMoney } from '@bonitour/common-functions'
import { reservationTimelineLabels, reservationTimelineActionTypes, timelineEventTypes } from './ReservationTimeline.constant'
import { toTitleCase } from './helpers/toTitleCase'
import { providers } from 'constants/acquirers'
import { orbEntities } from 'constants/orbEntities'
import { paymentTypesMap } from 'constants/reservationPaymentTypes'

const {
  ADVANCE_REFUND,
  REFUND,
  TICKET_BALANCE_TRANSFER
} = reservationTimelineActionTypes

/**
* @typedef {Object} cardInfo
* @property {string} type
* @property {string} ticketPaid
* @property {string} ticketCreated
* @property {string} paymentMethod
* @property {string} linkedTickets
* @property {number} value
* @property {number} valueForRefund
* @property {string} createdTickets
* @property {string} autDoc
* @property {string} reservationCode
* @property {string} originBankAccount
* @property {string} event
* @property {string[]} fieldsUpdated
* @property {string} id
* @property {string} reason
* @property {string} acquirerName
* @property {string} destinationBankAccount
* @property {boolean} isApproved
* @property {string} fromTicket
* @property {string} toTicket
* @property {string} paymentMean
* @property {string} brand
* @property {string} customVendorName
*/

/**
* @type {React.FC<{cardInfo: cardInfo}>}
*/
export const ReservationTimelineCard = ({ cardInfo }) => {
  const {
    isRefundCreated,
    isTicketBalanceTransfer,
    isAdvanceRefund,
    isDiscount
  } = useMemo(() => ({
    isRefundCreated: cardInfo.type === REFUND,
    isTicketBalanceTransfer: cardInfo.type === TICKET_BALANCE_TRANSFER,
    isAdvanceRefund: cardInfo.type === ADVANCE_REFUND,
    isDiscount: cardInfo.paymentMethod === paymentTypesMap.discount
  }), [cardInfo.paymentMethod, cardInfo.type])

  const { title, subtitles } = useMemo(() => {
    const isApprovedUndefined = cardInfo?.isApproved === undefined
    const hasValue = Boolean(cardInfo.value)
    const title = (function () {
      const event = cardInfo.event
      const rootLabel = reservationTimelineLabels?.[cardInfo.type]
      const rootLabelWithTitleCase = toTitleCase(rootLabel)
      const isAdvanceAction = [orbEntities.ADVANCE_RESERVATION_PAYMENT].includes(cardInfo.type)
      if (isDiscount) {
        return 'Desconto aplicado'
      }
      if (event === timelineEventTypes.create) {
        return `${rootLabelWithTitleCase} ${isAdvanceAction ? 'pendente' : 'realizado'}`
      } else if (event === timelineEventTypes.destroy) {
        return `Registro de ${rootLabel} deletado`
      } else if (isApprovedUndefined) {
        return `${rootLabelWithTitleCase} atualizado`
      } else {
        return `${rootLabelWithTitleCase} ${cardInfo.isApproved ? 'aprovado' : 'recusado'}`
      }
    }())
    const subtitles = (function () {
      const defaultData = [
        (cardInfo?.paymentMethod && !isDiscount) ? `Método de pagamento: ${cardInfo.paymentMethod}` : '',
        hasValue ? `Valor total: ${formatMoney(cardInfo.value)}` : '',
        cardInfo?.reason ? `Motivo: ${cardInfo.reason}` : '',
        ...(cardInfo?.fieldsUpdated || []),
        cardInfo?.customVendorName ? `Fornecedor multiponto: ${cardInfo.customVendorName}` : '',
        cardInfo?.acquirerName ? `Adquirente responsável: ${providers?.[cardInfo.acquirerName] || cardInfo.acquirerName}` : '',
        cardInfo?.destinationBankAccount ? `Conta bancária de destino: ${cardInfo.destinationBankAccount}` : '',
        cardInfo?.originBankAccount ? `Conta bancária de origem: ${cardInfo.originBankAccount}` : '',
        cardInfo?.paymentMean ? `Meio de pagamento: ${cardInfo.paymentMean}` : '',
        cardInfo?.brand ? `Bandeira: ${cardInfo.brand}` : ''
      ]

      if (isTicketBalanceTransfer) {
        return [
          ...defaultData,
          hasValue ? `Saldo: ${formatMoney(cardInfo.value)}` : '',
          `Retirado do ticket: ${cardInfo.fromTicket}`,
          `Transferido para o ticket: ${cardInfo.toTicket}`
        ]
      }

      const linkedTickets = (function () {
        const parsedLinkedTickets = (cardInfo?.linkedTickets || '').split(' - ')
          .map((linkedTicket) => {
            const linkedTicketCode = linkedTicket?.split(' ')[0]

            return <p className='list-item' key={`linked-ticket-${linkedTicketCode}`}>{linkedTicket}</p>
          })
        return [
          <p key={'list-root-linked-ticket'}>Ingressos vinculados: </p>,
          ...parsedLinkedTickets
        ]
      }())

      return [
        ...defaultData,
        cardInfo?.autDoc ? `Aut/Doc: ${cardInfo.autDoc}` : '',
        cardInfo?.linkedTickets ? linkedTickets : ''
      ]
    }())
    return { title, subtitles }
  }, [
    cardInfo?.isApproved,
    cardInfo?.value,
    cardInfo?.event,
    cardInfo?.type,
    cardInfo?.fieldsUpdated,
    cardInfo?.reason,
    cardInfo?.acquirerName,
    cardInfo?.destinationBankAccount,
    cardInfo?.originBankAccount,
    cardInfo?.paymentMethod,
    cardInfo?.autDoc,
    cardInfo?.linkedTickets,
    cardInfo?.fromTicket,
    cardInfo?.toTicket,
    isDiscount,
    isTicketBalanceTransfer,
    cardInfo?.paymentMean,
    cardInfo?.brand,
    cardInfo?.customVendorName
  ])

  const canShowRefundLabel = useMemo(() => (isRefundCreated || isAdvanceRefund) && cardInfo.valueForRefund, [isRefundCreated, isAdvanceRefund, cardInfo.valueForRefund])

  return <>
    <Card padding={0} css={cardStyle} data-id={cardInfo?.id}>
      <H3 css={cardItemStyle} className="primary">
        {title}
      </H3>
      {subtitles?.map((subtitle, index) => (
        <p css={cardItemStyle} key={`${cardInfo.type}-card-subtitle-${index}`}>
          {subtitle}
        </p>
      ))}
    </Card>
    {canShowRefundLabel && (
      <div css={refundLabelStyle}>
        <p>
          Saldo para reembolso {formatMoney(cardInfo.valueForRefund)}
        </p>
      </div>
    )}
  </>
}

import { string } from 'yup'

const validateDuration = duration => Boolean(duration.match(/^\d\d:\d\d$/))

const baseValidation = string()
  .required()
  .test('test-validateDuration', 'A duração deve ser válida', validateDuration)

export const offlineExperienceAboutSchema = {
  duration: baseValidation,
  workDuration: baseValidation
}

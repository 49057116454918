/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import {
  CircleThumbnail,
  BanIcon,
  Content,
  UserIcon,
  CompassIcon,
  TooltipOnHover,
  colors,
  flexCenteredSpaceBetweenRow,
  flexHorizontallyCentered,
  flexRowCentered,
  BREAK_POINTS
} from '@bonitour/components'
import { useActivity } from 'contexts/Activity'

const footer = css`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background: ${colors.white};
  color: ${colors.gray5};
  box-shadow: 0px -1px 6px #00000029;

  @media (max-width: ${BREAK_POINTS.tablet}) {
    display: none;
  }
`

const footerContent = css`
  padding: 10px 20px;
`

const thumbnail = css`
  width: 14px;
  height: 14px;
  margin: 0 10px;
  box-shadow: 0px 1px 3px #00000029;
`

const mapGuideIcon = css`
  width: 14px;
  height: 14px;
  margin: 0 10px;
`

const restrictionGuideIcon = css`
  font-size: 26px;
  margin: 0 10px;
`

const tooltipStyle = css`
  white-space: nowrap;
`

const RestrictionGuideItem = ({ icon: Icon = null, tooltip = '' }) => (
  <TooltipOnHover css={tooltipStyle} text={tooltip}>
    <Icon css={restrictionGuideIcon} />
  </TooltipOnHover>
)

const MapGuideItem = ({ color, icon: Icon = null, children }) => (
  <div css={flexHorizontallyCentered}>
    {Boolean(color) && <CircleThumbnail css={thumbnail} size={14} style={{ backgroundColor: color }} />}
    {Boolean(Icon) && <Icon css={mapGuideIcon} />}
    <span>{children}</span>
  </div>
)

export const BookingMapGuide = () => {
  const { activity } = useActivity()
  const { minAge = 0, duration = '' } = activity

  return (
    <div css={[footer, flexRowCentered]}>
      <Content css={[footerContent, flexCenteredSpaceBetweenRow]}>
        <div css={flexHorizontallyCentered}>
          {(Boolean(minAge) || Boolean(duration)) && <span>Restrições técnicas:</span>}
          {Boolean(minAge) && <RestrictionGuideItem icon={UserIcon} tooltip={`Idade mínima de ${minAge} anos`} />}
          {Boolean(duration) && <RestrictionGuideItem icon={CompassIcon} tooltip={`Aproximadamente ${duration} hs`} />}
        </div>
        <div css={flexHorizontallyCentered}>
          <MapGuideItem color={colors.primaryVariant}>Vagas reservadas</MapGuideItem>
          <MapGuideItem color={colors.white}>Vagas disponíveis</MapGuideItem>
          <MapGuideItem icon={BanIcon}>Vagas indisponíveis</MapGuideItem>
        </div>
      </Content>
    </div>
  )
}

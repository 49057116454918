import { css } from '@emotion/core'
import { margin, marginBottom } from 'assets/styles/global'
import { BREAK_POINTS, colors } from '@bonitour/components'

export const marginBottom20 = marginBottom(20)
export const marginRight10 = margin(0, 10, 0, 0)

export const activitiesCardContainer = css`
  gap: 25px;
  margin-bottom: 20px;
  flex-wrap: wrap;

  @media (max-width: ${BREAK_POINTS.bigPhone}){
    justify-content: center;
  }
`
export const baseCard = css`
  text-align: center;
  max-width: 210px;
  width: 100%;
  box-sizing: border-box;
  color: ${colors.gray3};
  font-size: 15px;

  @media (max-width: ${BREAK_POINTS.bigPhone}){
    max-width: 100%;
  }
`

export const activitiesCard = css`
  background-color: ${colors.gray12};
`
export const defautMargin = css`
  margin: 5px 0 15px 0;
`
export const grayBorder = css`
  border: 1px solid ${colors.gray9};
`
export const iconGreyBackground = css`
  background-color: ${colors.white};
  color: ${colors.gray3};
`

export const titleStyle = css`
  font-size: 20px;
  font-weight: 500;
  max-height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`
export const nonImageFirstLetterSize = css`
  font-size: 34px;
`

import { orbCore } from 'services/Orb/Service'
import { OfflineExperienceTicketModel, OfflineTicketUpdateModel } from './Model'
import { OfflineTicketParser } from './Parser'

const { offlineTicketDomain } = orbCore

export const OfflineTicketService = {
  create (data, category) {
    const requestPayload = OfflineExperienceTicketModel(OfflineTicketParser.instance(data))
    return offlineTicketDomain.create(requestPayload, category).then(({ data: offlineTicket = {} }) => offlineTicket)
  },
  get (id) {
    return offlineTicketDomain.get(id).then(({ ticket }) => ticket)
  },
  update (id, data) {
    const requestPayload = OfflineTicketUpdateModel(OfflineTicketParser.instance(data))
    return offlineTicketDomain.update(id, requestPayload)
  }
}

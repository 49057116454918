/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { ActivityVacancyForm } from 'containers/ActivityVacancyForm/ActivityVacancyForm'
import { useToast } from '@bonitour/components'
import { ActivityVacancyService } from 'services/Vacancy/Service'
import { useHistory, useLocation } from 'react-router-dom'
import { useMemo, useState, useCallback } from 'react'
import { useActivity } from 'contexts/Activity'

export const CreateVacancy = () => {
  const [activityVacancy] = useState({
    activityVacancy: {
      vacancyName: '',
      dailyLimit: 0
    },
    periodList: [{ name: '', startDate: new Date(), endDate: new Date() }],
    scheduleList: [
      {
        time: '',
        vacancyQuantity: 0,
        sundayQuantity: 0,
        mondayQuantity: 0,
        tuesdayQuantity: 0,
        wednesdayQuantity: 0,
        thursdayQuantity: 0,
        fridayQuantity: 0,
        saturdayQuantity: 0
      }
    ]
  })
  const { add } = useToast()
  const { id: activityId } = useActivity()
  const history = useHistory()
  const { pathname } = useLocation()

  const onValidationError = () => {
    add('Preencha corretamente o formulário')
  }

  const returnToEditHref = useMemo(() => {
    if (pathname.includes('/transport')) {
      return `/transport/${activityId}/edit?accordion=4`
    } else if (pathname.includes('/offline-experience')) {
      return `/offline-experience/${activityId}/edit?accordion=4`
    }

    return `/activity/${activityId}/edit?accordion=4`
  }, [activityId, pathname])

  const returnToEdit = useCallback(() =>
    history.push(returnToEditHref), [history, returnToEditHref]
  )

  const onSuccess = async data => {
    try {
      await ActivityVacancyService.create(activityId, data)
      returnToEdit()
      add('Vaga criada', 'success')
    } catch (message) {
      add(message, 'danger', 5000)
    }
  }

  return (
    <ActivityVacancyForm
      activityVacancy={activityVacancy}
      onSuccess={onSuccess}
      onBackClick={returnToEdit}
      onValidationError={onValidationError}
    />
  )
}

const sellerGroupTradeAgreement = ({
  id,
  name,
  commissioning,
  sell_via_ally: sellViaAlly,
  net_fee_enabled: netFeeEnabled
}) => {
  return {
    id,
    tradeAgreement: {
      name: name === 'no_commission_group' ? 'Meu grupo' : name,
      commissioning,
      sellViaAlly,
      netFeeEnabled
    }
  }
}

const sellerGroupInvoicePolicy = ({ deadline, deadline_type: deadlineType }, invoicePolicy = {}) => {
  const {
    invoiced,
    invoice_closing_period: closingPeriod,
    invoice_closing_day: closingDay,
    payment_day: paymentDay,
    payment_guarantee: guarantee = {},
    payment_type: paymentType,
    installments
  } = invoicePolicy

  const { no_show: noShow } = guarantee
  const credit = Boolean(paymentType === 'credit')

  return {
    invoicePolicy: {
      invoiced,
      deadline: deadline || 0,
      deadlineType: deadlineType || '',
      closingPeriod,
      closingDay,
      paymentDay,
      invoice: {
        noShow,
        credit,
        installments
      }
    }
  }
}

const sellerGroupComercialPartners = (comercialPartners = []) => {
  return {
    comercialPartners: [
      ...comercialPartners
    ]
  }
}

const parseCustomComissions = (comissioningByService = {}) => {
  const comissioningByServiceKeys = Object.keys(comissioningByService || {}) || []

  return comissioningByServiceKeys.map(service => {
    const { commission, net_fee_enabled: netFeeEnabled, title_name: titleName } = comissioningByService[service]
    return {
      service,
      commission,
      netFeeEnabled,
      titleName
    }
  }) || []
}

export const SellersGroupsParser = {
  cards ({ partnership_groups: partnershipGroups = [] }) {
    return partnershipGroups.map(sellerGroupTradeAgreement)
  },
  group (group = {}) {
    const {
      invoice_policy: invoicePolicy,
      commissioning_by_service: commissioningByService
    } = group

    return {
      ...sellerGroupTradeAgreement(group),
      ...sellerGroupInvoicePolicy(group, invoicePolicy),
      customComissions: parseCustomComissions(commissioningByService)
    }
  },
  form ({ partnership_group: partnershipGroup }) {
    const {
      invoice_policy: invoicePolicy,
      comercial_partner_ids: comercialPartners,
      editable,
      commissioning_by_service: commissioningByService
    } = partnershipGroup
    return {
      ...sellerGroupTradeAgreement(partnershipGroup),
      ...sellerGroupInvoicePolicy(partnershipGroup, invoicePolicy),
      ...sellerGroupComercialPartners(comercialPartners),
      customComissions: parseCustomComissions(commissioningByService),
      editable
    }
  }
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import {
  Card,
  InputFormGroup,
  Button,
  useToast,
  Row,
  Column,
  DatePicker,
  Input,
  MultiSelectDropdown
} from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { useForm } from '@bonitour/app-functions'
import { array, string } from 'yup'
import { useEffect } from 'react'
import { useKeyPress } from 'hooks/useKeyPress'
import { button, cardContrast, submitButton, width150 } from './MyDayFilters.style'
import { useMyDay, sellers, vendors } from '../hooks/useMyDay'

export const MyDayFilterSchema = {
  passengerName: string(),
  ticketCode: string(),
  servicesId: array().of(string()),
  partnerId: string(),
  date: string().required('Preencha a data'),
  providerId: array().of(string()),
  sellerId: array()
    .of(string())
    .when('providerId', (providerId, schema) => {
      if (providerId.length > 0) {
        return array().of(string()).max(0, 'Você pode só filtrar por fornecedor ou vendedor')
      }

      return schema
    })
}

export const MyDayFilters = ({
  onFilterSubmit: emitFilterSubmit = identity,
  initialFilters = {},
  activityOptions = [],
  getActivityOptions
}) => {
  const { add: addToast } = useToast()

  useMyDay()

  const {
    form,
    errors,
    onSubmit,
    utils: { onInputChange }
  } = useForm(initialFilters, MyDayFilterSchema)

  const { passengerName = '', ticketCode = '', servicesId = [], partnerId = '', date = '', sellerId, providerId } = form
  const { onKeyDown } = useKeyPress()

  useEffect(() => {
    getActivityOptions(partnerId)
    onInputChange('servicesId')([])
    // eslint-disable-next-line
  }, [partnerId])

  const onError = () => addToast('Preencha o formulário corretamente')

  const validateFilters = (filters) => {
    emitFilterSubmit(filters)
  }

  const onClick = onSubmit(validateFilters, onError)

  return (
    <Card css={cardContrast}>
      <Row>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label="Data" errorMessage={errors.date}>
            <DatePicker
              value={date}
              onChange={onInputChange('date')}
              css={width150}
              allowsEmpty
              onKeyDown={(event) => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label="Cliente" errorMessage={errors.passengerName}>
            <Input
              value={passengerName}
              error={errors.passengerName}
              onChange={onInputChange('passengerName')}
              onKeyDown={(event) => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label="Experiência" errorMessage={errors.servicesId}>
            <MultiSelectDropdown
              options={activityOptions}
              selected={servicesId}
              error={errors.servicesId}
              onChange={onInputChange('servicesId')}
            />
          </InputFormGroup>
        </Column>
      </Row>
      <Row>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label="Cód do ingresso" errorMessage={errors.ticketCode}>
            <Input
              value={ticketCode}
              error={errors.ticketCode}
              onChange={onInputChange('ticketCode')}
              onKeyDown={(event) => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label="Fornecedores" errorMessage={errors.providerId}>
            <MultiSelectDropdown options={vendors.value} selected={providerId} onChange={onInputChange('providerId')} />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label="Vendedores" errorMessage={errors.sellerId}>
            <MultiSelectDropdown options={sellers.value} selected={sellerId} onChange={onInputChange('sellerId')} />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3} css={submitButton}>
          <Button css={button} onClick={onClick}>
            Pesquisar
          </Button>
        </Column>
      </Row>
    </Card>
  )
}

import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react'

import { ProvidersService } from 'services/Provider/Service'
import { usePermissions } from 'hooks/usePermissions'
import { useToast } from '@bonitour/components'

const queryIsValid = (query) => query && query.trim().length > 0

const handleGetProviders = async ({ page, pageLimit, providerToSearch }) => {
  if (queryIsValid(providerToSearch)) {
    const providersSearch = ProvidersService.getProvidersByName(providerToSearch, pageLimit, page).then((res) => res)

    return providersSearch
  } else {
    const allProviders = ProvidersService.getPaginatedList(page, pageLimit).then((res) => res)

    return allProviders
  }
}

export const PaginatedProvidersContext = createContext()
export const PaginatedProvidersProvider = ({ children }) => {
  const [providersInfo, setProvidersInfo] = useState({ providers: [], total: 0, totalPages: 0 })
  const [toSearch, setToSearch] = useState('')
  const [loadingProviders, setLoadingProviders] = useState(false)
  const [pagination, setPagination] = useState({ currentPage: 1, pageLimit: 10 })

  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()

  const hasPermission = useMemo(
    () => handlePermission('insufficient::permissions', 'Você não possui permissão de acesso aos fornecedores'),
    [handlePermission]
  )

  const getProviders = useCallback(
    ({ page, pageLimit, searchParams = '' }) => {
      if (hasPermission) {
        setLoadingProviders(true)

        handleGetProviders({
          page,
          pageLimit,
          providerToSearch: searchParams
        })
          .then((data) => setProvidersInfo(data))
          .catch(() => {
            addToast('Erro inesperado')
          })
          .finally(() => setLoadingProviders(false))
      } else {
        addToast('Você não tem permissão')
      }
    },
    [addToast, hasPermission]
  )

  const handleSearch = useCallback(
    (newSearch) => {
      if (!queryIsValid(newSearch) && newSearch !== '') {
        addToast('Confira o nome do fornecedor')
        return
      }

      setToSearch(newSearch)
      setPagination({ currentPage: 1, pageLimit: pagination.pageLimit })
      getProviders({
        searchParams: newSearch,
        page: 1,
        pageLimit: pagination.pageLimit
      })
    },
    [addToast, getProviders, pagination]
  )

  const handlePagination = useCallback(
    (currentPage, pageLimit) => {
      setPagination({ currentPage, pageLimit })
      getProviders({
        page: currentPage,
        pageLimit,
        searchParams: toSearch
      })
    },
    [toSearch, getProviders]
  )

  useEffect(() => {
    getProviders({
      page: 1,
      pageLimit: pagination.pageLimit
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PaginatedProvidersContext.Provider
      value={{
        providersInfo,
        toSearch,
        setToSearch,
        getProviders,
        pagination,
        setPagination,
        handleSearch,
        handlePagination,
        loadingProviders
      }}
    >
      {children}
    </PaginatedProvidersContext.Provider>
  )
}

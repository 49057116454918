/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'

import { Button } from '@bonitour/components'

export const ProcessingContainer = ({ children = '', status }) => {
  return (
    status === 'processing'
      ? <Button disabled>Processando</Button>
      : children
  )
}

/* eslint-disable camelcase */
import { removeArchiveUrl } from 'core/services/Orb'
import { formatISOTime } from 'utils/time'
import { unformatMoney } from '@bonitour/common-functions'
import moment from 'moment'
import { metaModel } from './meta'

export const invoiceMetaModel = ({ currentPage = 1, perPage = 10 }) => ({
  per_page: perPage,
  page: currentPage
})

export const InvoiceModel = {
  file ({ file, src, id, signedId }) {
    if (file) {
      return file
    }
    return {
      signed_id: signedId,
      url: removeArchiveUrl(src),
      id
    }
  },
  extraInfo ({ info = {}, details = {} }) {
    const { discountPercentage, taxPercentage, invoiceNumber, dueDate } = info
    const { files = [], note = '' } = details

    return {
      discount_percentage: Number(discountPercentage || 0) / 100,
      tax_percentage: Number(taxPercentage || 0) / 100,
      document_number: invoiceNumber,
      due_date: formatISOTime(dueDate.toISOString(), 'YYYY-MM-DD HH:mm:ss'),
      receipts: files.map(InvoiceModel.file),
      observation: note
    }
  },
  payment ({ attachments = [], date, type, value, observation, originBankAccount, destinationBankAccount }) {
    return {
      payment_method: type,
      payment_value: unformatMoney(value),
      payment_date: formatISOTime(date.toISOString(), 'YYYY-MM-DD HH:mm:ss'),
      attachments: attachments.map(InvoiceModel.file),
      origin_bank_account_id: originBankAccount,
      destination_bank_account_id: destinationBankAccount,
      observation
    }
  },
  exportCsv: (exportColumns) => {
    const { wantedFields: wanted_fields } = exportColumns || {}
    return { wanted_fields }
  }
}

export const InvoicePayModel = {
  list: ({ filters = {}, pagination = {} }) => {
    const {
      invoiceStartDate: initial_date,
      invoiceEndDate: final_date,
      invoiceCompetenceStartDate: competence_initial_date,
      invoiceCompetenceEndDate: competence_final_date,
      ignoreZeroedInvoiceValues: ignore_zeroed_invoice_values,
      status,
      partner: partner_id
    } = filters

    const meta = metaModel(pagination)

    const searchParams = {
      initial_date: initial_date && moment(initial_date).format('YYYY-MM-DD'),
      final_date: final_date && moment(final_date).format('YYYY-MM-DD'),
      competence_initial_date: competence_initial_date && moment(competence_initial_date).format('YYYY-MM-DD'),
      competence_final_date: competence_final_date && moment(competence_final_date).format('YYYY-MM-DD'),
      ignore_zeroed_invoice_values: Boolean(ignore_zeroed_invoice_values),
      status,
      partner_id
    }

    return { searchParams, pagination: meta }
  },
  edit: (invoiceEditinfo = {}) => {
    const { originBankAccount, destinationBankAccount } = invoiceEditinfo

    const hasOriginBankAccount = originBankAccount !== null

    const originBankAccountData = hasOriginBankAccount && { origin_bank_account_id: originBankAccount }
    return {
      ...originBankAccountData,
      destination_bank_account_id: destinationBankAccount
    }
  }
}

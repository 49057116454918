/* eslint-disable camelcase */
import { removeEmptyAttribute, unformatMoney } from '@bonitour/common-functions'
import moment from 'moment'
import { metaModel } from './meta'
import { invoiceBatchTypeMap } from 'domains/InvoiceBatch/constants/InvoiceBatchType'
import { SafeDate } from '@binamik/js-providers'
import { formatISOTime } from 'utils/time'
import { OFFLINE_EXPERIENCE_TYPE } from 'constants/activityTypes'

export const PaymentBatchModel = {
  ticketsToBatch: ({ filters, pagination }) => {
    const searchParams = TicketsToBatchSearchParamsModel(filters)
    const meta = metaModel(pagination)
    return { ...searchParams, ...meta }
  },
  paymentBatches: ({ filters, pagination }) => {
    const searchParams = PaymentBatchesSearchParamsModel(filters)
    const meta = metaModel(pagination)
    return { searchParams, pagination: meta }
  },
  paymentDiscount: (discount) => {
    if (!discount) return undefined
    return unformatMoney(discount)
  },
  registerPayment: ({ paymentData }) => {
    const {
      paymentMethod: payment_method,
      transactionDate: transaction_date,
      originBankAccountId: origin_bank_account_id,
      value,
      receipts
    } = paymentData

    const parsedValue = Number(value).toFixed(2)

    const parsedReceipts = (receipts || []).map((receipt) => receipt.file)

    return {
      origin_bank_account_id,
      payment_method,
      transaction_date: formatISOTime(transaction_date, 'YYYY-MM-DD'),
      transaction_type: 'payment',
      value: parsedValue,
      receipts: parsedReceipts
    }
  },
  paymentEditBatch: ({ batch }) => {
    const {
      beneficiaryId: beneficiary_id,
      beneficiaryType,
      observation,
      referenceNumber,
      expectedPaymentDate: expected_payment_date,
      discount,
      ticketsToBatch,
      paymentMethod,
      originBankAccountId,
      paymentDate: payment_date,
      taxValue
    } = batch

    const parsedBeneficiaryType = invoiceBatchTypeMap[beneficiaryType] || undefined

    const parsedValues = {
      beneficiary_id,
      beneficiary_type: parsedBeneficiaryType,
      observation: observation || '',
      reference_number: referenceNumber || '',
      discount: PaymentBatchModel.paymentDiscount(discount),
      origin_bank_account_id: originBankAccountId || '',
      expected_payment_date: expected_payment_date ? new SafeDate(expected_payment_date).isoDate : undefined,
      tax_value: taxValue,
      items_ids: ticketsToBatch,
      ...(paymentMethod && {
        payment_date: payment_date ? new SafeDate(payment_date).isoDate : undefined,
        payment_method: paymentMethod
      })
    }

    return parsedValues
  },
  paymentCreateBatch: ({ batch }) => {
    const {
      beneficiaryId: beneficiary_id,
      beneficiaryType,
      observation,
      referenceNumber: reference_number,
      expectedPaymentDate: expected_payment_date
    } = batch

    const parsedBeneficiaryType = invoiceBatchTypeMap[beneficiaryType] || undefined

    const parsedValues = {
      beneficiary_id,
      beneficiary_type: parsedBeneficiaryType,
      observation,
      reference_number,
      expected_payment_date: expected_payment_date ? moment(expected_payment_date).format('YYYY-MM-DD') : undefined
    }

    return parsedValues
  },
  addBatchItems: ({ batch }) => {
    const { beneficiaryType, tickets: items_ids } = batch

    const parsedBeneficiaryType = invoiceBatchTypeMap[beneficiaryType] || undefined

    const parsedValues = {
      beneficiary_type: parsedBeneficiaryType,
      items_ids
    }

    return parsedValues
  },
  paymentBatchesTotalizers: ({ filters }) => {
    const searchParams = PaymentBatchesSearchParamsModel(filters)
    return searchParams
  },
  paymentBatch: ({ batch }) => {
    const {
      beneficiaryId: beneficiary_id,
      observation,
      originBankAccountId: origin_bank_account_id,
      paymentMethod: payment_method,
      referenceNumber: reference_number,
      paymentDate: payment_date,
      expectedPaymentDate: expected_payment_date,
      ticketsToBatch: items_ids,
      discount
    } = batch

    const parsedValues = {
      beneficiary_id,
      observation,
      origin_bank_account_id,
      payment_method,
      expected_payment_date: expected_payment_date ? moment(expected_payment_date).format('YYYY-MM-DD') : undefined,
      reference_number,
      payment_date: payment_date ? moment(payment_date).format('YYYY-MM-DD') : undefined,
      items_ids,
      discount
    }

    return removeEmptyAttribute(parsedValues)
  }
}

export const PaymentBatchesSearchParamsModel = (filterParams = {}) => {
  const {
    batchType = OFFLINE_EXPERIENCE_TYPE,
    beneficiaryId = '',
    expectedDate = '',
    status = '',
    referenceNumber = '',
    startDate: initial_date,
    endDate: final_date
  } = filterParams

  return {
    batch_type: batchType,
    beneficiary_id: beneficiaryId,
    expected_date: expectedDate,
    status,
    reference_number: referenceNumber,
    initial_expected_payment_date: initial_date && moment(initial_date).format('YYYY-MM-DD'),
    end_expected_payment_date: final_date && moment(final_date).format('YYYY-MM-DD')
  }
}

export const StringToArray = (idList = '') => {
  return idList
    .split(',')
    .map((id) => id.trim())
    .filter((id) => id !== '')
}

export const TicketsToBatchSearchParamsModel = (filterParams = {}) => {
  const {
    beneficiaryId,
    beneficiaryType,
    startDate,
    endDate,
    ticketCodes,
    externalTicketIds,
    reservationCodes,
    externalReservationCode,
    payerName,
    orderBy
  } = filterParams

  return {
    initial_slot_date: startDate && moment(startDate).format('YYYY-MM-DD'),
    final_slot_date: endDate && moment(endDate).format('YYYY-MM-DD'),
    beneficiary_id: beneficiaryId,
    beneficiary_type: beneficiaryType,
    payer_name: payerName,
    ticket_codes: StringToArray(ticketCodes),
    reservation_codes: StringToArray(reservationCodes),
    ...(externalTicketIds && { external_ticket_ids: StringToArray(externalTicketIds) }),
    ...(externalReservationCode && { external_reservation_code: StringToArray(externalReservationCode) }),
    ...(orderBy && { order_by: orderBy })
  }
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useState, useMemo, useCallback } from 'react'
import { useToast } from '@bonitour/components'
import { useHistory } from 'react-router-dom'

import { BookingForm } from 'containers/Booking/CreationForm/BookingForm'

import { useActivity } from 'contexts/Activity'
import { identity } from '@bonitour/common-functions'
import { OfflineTicketService } from 'services/OfflineTicket/Service'
import { useUser } from 'contexts/User'
import { useQuery } from 'hooks/useQuery'

export const CreateOfflineExperienceBooking = () => {
  const history = useHistory()
  const { add } = useToast()
  const {
    id: activityId,
    activity: { type, category }
  } = useActivity()
  const { user } = useUser()
  const [{ email = '' }] = useQuery()

  const [reservationBase] = useState(() => ({
    reservationType: {
      paymentState: 'responsible'
    },
    serviceId: activityId,
    checkinDate: new Date(),
    documentType: 'Cpf',
    count: 0,
    reservationHeader: {
      agent: user.id
    },
    initialEmail: email
  }))

  const basePath = useMemo(() => `/offline-experience/${activityId}`, [activityId])

  const returnToDashboard = useCallback(() => history.push(`${basePath}/dashboard`), [basePath, history])

  const createRedirect = useCallback((redirectTo = identity) => async data => {
    try {
      const { id } = await OfflineTicketService.create({
        ...data,
        payerParams: {
          ...data?.reservationType
        }
      }, category)
      history.push(redirectTo(id))
    } catch (error) {
      const { parsedErrors = [] } = error
      parsedErrors.length ? parsedErrors.forEach(errorMessage => add(errorMessage, 'error', 10000)) : add('Houve um erro no cadastro da sua reserva')
    }
  }, [add, category, history])

  const onFinish = createRedirect((reservationId) => `/reservation/${reservationId}?selectedNav=financeiro`)
  const onContinue = createRedirect((reservationId) => `/reservation/${reservationId}?selectedNav=ingressos`)

  return (
    <BookingForm
      reservationBase={reservationBase}
      onFinish={onFinish}
      onContinue={onContinue}
      onBackClick={returnToDashboard}
      serviceType={type}
      offlineExperienceCategory={category}
      isRequiredConfirmation={true}
    />
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useCallback, useEffect, useRef, useState } from 'react'
import { VouchersList } from './VouchersList'
import { VouchersFilter } from './VouchersFilter'
import { useListVouchers } from '../hooks/useListVouchers'
import { LightInfoAlert, useToast } from '@bonitour/components'
import { BatchService } from 'core/services/Batch'

const VouchersNotFound = ({ vouchersNotFound = [] }) => {
  const vouchersNotFoundString = vouchersNotFound.join(', ')

  return (
    vouchersNotFoundString && <LightInfoAlert>Voucher(s) não encontrado(s): {vouchersNotFoundString}</LightInfoAlert>
  )
}

export const Vouchers = () => {
  const {
    filters,
    setFilters,
    changePagination,
    pagination,
    vouchers,
    vouchersNotFound,
    meta,
    fetchVouchers,
    loading
  } = useListVouchers()
  const { current: initialFilters } = useRef(filters)
  const { add: addToast } = useToast()

  const [isSelectedAll, setSelectedAll] = useState(false)
  const [selectedVouchers, setSelectedVouchers] = useState([])
  const [isCreateBatchVisible, setCreateBatchVisibility] = useState(false)
  const onCreateBatchVisible = () => setCreateBatchVisibility(true)
  const onCreateBatchHidden = () => setCreateBatchVisibility(false)

  const isVoucherSelect = voucherId => selectedVouchers.some(({ ticketId }) => ticketId === voucherId)

  const isVoucherSelectable = ({ status, batch }) => status !== 'paid' && !batch

  const onSelectedAllClick = () => setSelectedAll(!isSelectedAll)

  const onVoucherClick = voucher => () => isVoucherSelect(voucher.ticketId)
    ? setSelectedVouchers(selectedVouchers.filter(({ ticketId }) => ticketId !== voucher.ticketId))
    : isVoucherSelectable(voucher) && setSelectedVouchers([...selectedVouchers, voucher])

  const onSubmit = useCallback((batch) => BatchService.createBatch(batch)
    .then(() => {
      fetchVouchers({ pagination, filters })
      setSelectedAll(false)
      setSelectedVouchers([])
      addToast('Lote criado com sucesso', 'success')
    })
    .catch(() => addToast('Ocorreu um erro na criação do lote'))
  , [addToast, fetchVouchers, filters, pagination])

  useEffect(() => {
    isSelectedAll ? setSelectedVouchers(vouchers.filter(voucher => isVoucherSelectable(voucher))) : setSelectedVouchers([])
    // eslint-disable-next-line
  }, [isSelectedAll])

  useEffect(() => {
    setSelectedAll(false)
  }, [vouchers])

  return (
    <>
      <VouchersFilter
        onFilterSubmit={setFilters}
        vouchers={vouchers}
        loading={loading}
        initialFilters={initialFilters}
      />

      <VouchersNotFound vouchersNotFound={vouchersNotFound} />

      <VouchersList
        loading={loading}
        vouchers={vouchers}
        pagesQuantity={meta?.totalPages}
        total={meta?.total}
        selectedVouchers={selectedVouchers}
        isSelectedAll={isSelectedAll}
        isCreateBatchVisible={isCreateBatchVisible}
        onPagination={changePagination}
        isVoucherSelectable={isVoucherSelectable}
        onVoucherClick={onVoucherClick}
        onSelectedAllClick={onSelectedAllClick}
        isVoucherSelect={isVoucherSelect}
        onCreateBatchVisible={onCreateBatchVisible}
        onCreateBatchHidden={onCreateBatchHidden}
        onSubmit={onSubmit}
      />
    </>
  )
}

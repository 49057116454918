import { ReportService } from 'core/services/Reports'

export const TicketsReportModel = {
  listAttributesToExport: (lang) => {
    return ReportService.listAttributesToExport({ lang })
  },
  getAttributesToExport: ({ filters, wantedFields, format }) => {
    const {
      finalRefundDate,
      finalSlotDate,
      initialRefundDate,
      initialSlotDate,
      reservationCode,
      paymentMethod,
      initialPaymentDate,
      finalPaymentDate
    } = filters

    return {
      filters: {
        ...(finalRefundDate && { final_refund_date: finalRefundDate }),
        ...(finalSlotDate && { final_slot_date: finalSlotDate }),
        ...(initialRefundDate && { initial_refund_date: initialRefundDate }),
        ...(initialSlotDate && { initial_slot_date: initialSlotDate }),
        ...(reservationCode && { reservation_code: reservationCode }),
        ...(paymentMethod && { payment_method: paymentMethod }),
        ...(initialPaymentDate && { initial_payment_date: initialPaymentDate }),
        ...(finalPaymentDate && { final_payment_date: finalPaymentDate })
      },
      wantedFields,
      format: format.toLowerCase()
    }
  }
}

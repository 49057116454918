import React, { useCallback, useMemo } from 'react'
import { TableRow, TableV2 } from '@bonitour/components'
import { affiliateTableBreakpoint, tableCss } from './TicketSelector.style'
import { TicketStatus as getTicketStatus } from 'containers/Reservation/TicketStatus'
import {
  getTicketLabelAction,
  geTableRowClassName,
  getTicketSlot,
  getTicketName,
  getTicketReservationCodeLabel,
  getTicketValueAction
} from './TicketSelector.utils'
import { useMediaQuery } from '@bonitour/app-functions'
import { formatMoney } from '@bonitour/common-functions'

export const TicketAffiliateTable = ({
  tickets = [],
  handleOnSelectRow,
  isEditing,
  selectedIds,
  isLoading,
  isBinded,
  isAllSelected,
  handleToggleAll
}) => {
  const mobileBreakpoint = 1000
  const isMobile = useMediaQuery(`(max-width: ${mobileBreakpoint}px)`)

  const getComissionValue = useCallback((ticket) => {
    const commissionValue = ticket?.commissionValue || ticket?.value || 0
    const comissionPercentage = parseFloat((commissionValue / ticket.ticketPrice * 100) || 0).toFixed(2)

    return `${formatMoney(commissionValue)} (${comissionPercentage}%)`
  }, [])

  const labels = useMemo(
    () => [
      <>
        {getTicketLabelAction({
          isAllSelected,
          onClickCallBack: handleToggleAll,
          isMobile,
          isBinded
        })}
      </>,
      'Data do ticket',
      'Nome / Reserva',
      'Experiência',
      'Valor do ticket',
      'Comissão',
      'Status'
    ],
    [handleToggleAll, isAllSelected, isBinded, isMobile]
  )

  const columnsWidths = useMemo(
    () => ['2.5rem', '8rem', '3fr', '2fr', '8rem', isBinded ? '12rem' : '10.5rem', '10rem'],
    [isBinded]
  )

  return (
    <TableV2
      isLoading={isLoading}
      labels={labels}
      columnsWidths={columnsWidths}
      emptyMessage={`Nenhum item ${isBinded ? 'vinculado' : 'encontrado'}`}
      customCss={[tableCss, affiliateTableBreakpoint]}
      mobileBreakpoint={mobileBreakpoint}
    >
      {[
        tickets?.map((ticket, idx) => (
          <TableRow
            className={geTableRowClassName({
              ticket,
              isBinded,
              selectedIds
            })}
            onClick={() => !isBinded && handleOnSelectRow(ticket?.ticketId)}
            key={ticket.ticketId || idx}
          >
            {[
              <>
                {getTicketValueAction({
                  isEditing,
                  selectedIds,
                  ticketId: ticket.ticketId,
                  onClickCallBack: handleOnSelectRow,
                  isMobile
                })}
              </>,
              <>{getTicketSlot(ticket?.slot || ticket?.ticketSlot)}</>,
              <>{getTicketReservationCodeLabel(ticket)}</>,
              <>{getTicketName(ticket)}</>,
              <>{formatMoney(ticket.ticketPrice)}</>,
              <>{getComissionValue(ticket)}</>,
              <>{getTicketStatus(ticket.itemState || ticket.ticketState)}</>
            ]}
          </TableRow>
        ))
      ] || []}
    </TableV2>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import {
  Card,
  flex,
  flexColumnCentered,
  H3,
  HorizontalInputFormGroup,
  Input,
  InputMoneyMask,
  InputWithSuffix,
  P,
  Select,
  ToggleInputGroup
} from '@bonitour/components'
import { identity, unformatMoney } from '@bonitour/common-functions'
import { SwatchImage } from 'components/SwatchImage'
import { useEffect, useState } from 'react'
import {
  activeHeight,
  agePermissionTitle,
  ageValuesContainer,
  disabledHeight,
  feePaxTypeCard,
  finalValueGuide,
  guideContainer,
  guidePercentual,
  guideValue,
  horinzontalMargin10,
  inputLabel,
  inputStyle,
  marginBottom10,
  marginTop10,
  marginTop15,
  modificator,
  toolTipHeight,
  width80,
  guideAnimation,
  privateHeight,
  fontSize28,
  activeTooltipHeight
} from './FeePaxTypeRow.style'
import { guideTaxesDestinationOptions } from 'constants/guideTaxesDestination'
import { width, width100 } from 'assets/styles/global'

export const FeePaxTypeRow = ({
  feePrice = 0,
  feePaxTypes = [],
  feePaxTypeErrors = [],
  indexName = '',
  tooltip = '',
  onChange: emitChangeEvent = identity,
  onDelete: emitDeleteEvent = identity,
  onItemAddition: emitItemAdditionEvent = identity
}) => {
  const feePaxTypeIndex = feePaxTypes.findIndex(({ name }) => name === indexName)
  const feePaxType = feePaxTypes[feePaxTypeIndex]
  const isPrivate = indexName === 'Privativo'

  const { feeModificator = 100, minAge = 18, maxAge = 60, taxes = {}, capacity = 1 } = feePaxType || {}

  const isActive = Boolean(feePaxType)
  const feeDefault = { name: indexName, feeModificator, minAge, maxAge }

  const onClick = () => (isActive ? emitDeleteEvent(feePaxTypeIndex) : emitItemAdditionEvent(feeDefault))

  const { guide = 0, bearer = '' } = taxes || {}
  const { taxes: taxesErrors = {} } = feePaxTypeErrors[feePaxTypeIndex] || {}

  const [hasGuideTax, setHasGuideTax] = useState(parseInt(guide) !== 0)

  const [price, setPrice] = useState(0)
  const [guidePrice, setGuidePrice] = useState(0)
  const [calculatedPrice, setCalculatedPrice] = useState(0)

  const calcPercentage = (value) => ((unformatMoney(value) * 100) / feePrice).toFixed(3)

  const onGuidePriceChange = (value) => {
    setGuidePrice(value)
    emitChangeEvent(`feePaxTypes.${feePaxTypeIndex}.taxes.guide`)(calcPercentage(value))
    setCalculatedPrice(price + (hasGuideTax ? unformatMoney(value) : 0))
  }

  const onPriceChange = (value) => {
    setPrice(value)
    setCalculatedPrice(value)
    emitChangeEvent(`feePaxTypes.${feePaxTypeIndex}.feeModificator`)(calcPercentage(value))
  }

  const onFeeModificator = (value) => {
    emitChangeEvent(`feePaxTypes.${feePaxTypeIndex}.feeModificator`)(value)
    const priceCalculated = (feePrice * value) / 100
    setPrice(priceCalculated)
    setCalculatedPrice(priceCalculated + (hasGuideTax ? unformatMoney(guidePrice) : 0))
  }

  const onGuidePercentageChange = (value) => {
    emitChangeEvent(`feePaxTypes.${feePaxTypeIndex}.taxes.guide`)(value)
    const guidePriceCalculated = (feePrice * value) / 100
    setGuidePrice(guidePriceCalculated)
    setCalculatedPrice(price + (hasGuideTax ? guidePriceCalculated : 0))
  }

  const onHandleToggle = () => {
    setHasGuideTax(!hasGuideTax)
    emitChangeEvent(`feePaxTypes.${feePaxTypeIndex}.taxes.guide`)(0)
    setGuidePrice(0)
    setCalculatedPrice(price)
  }

  useEffect(() => {
    const guidePriceCalculated = (feePrice * guide) / 100
    setPrice((feePrice * feeModificator) / 100)
    setGuidePrice(guidePriceCalculated)
    setCalculatedPrice((feePrice * feeModificator) / 100 + guidePriceCalculated)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxes])

  useEffect(() => {
    setHasGuideTax(parseInt(guide) !== 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feePaxType])

  return (
    <>
      <Card
        css={[
          feePaxTypeCard,
          isActive && activeHeight,
          !isActive && disabledHeight,
          tooltip && toolTipHeight,
          isActive && tooltip && activeTooltipHeight,
          isPrivate && isActive && privateHeight
        ]}
      >
        <SwatchImage css={fontSize28} size={80} alternativeText={indexName} />
        <H3 css={marginTop10}>{indexName}</H3>
        {tooltip && <P>{tooltip}</P>}
        <div css={flex}>
          <ToggleInputGroup id={indexName.toLowerCase()} checked={isActive} onChange={identity} onClick={onClick} />
        </div>
        <div css={guideAnimation} className={isActive && 'open'}>
          {isPrivate && (
            <div css={flexColumnCentered}>
              <P css={[marginTop15, marginBottom10]}>Capacidade por ingresso</P>
              <HorizontalInputFormGroup css={[inputLabel, width80]} errorMessage={feePaxTypeErrors.capacity} label="">
                <InputWithSuffix
                  css={[inputStyle, width100]}
                  type="number"
                  min="0"
                  max="100"
                  value={capacity}
                  onChange={emitChangeEvent(`feePaxTypes.${feePaxTypeIndex}.capacity`)}
                >
                  <span>Pessoas</span>
                </InputWithSuffix>
              </HorizontalInputFormGroup>
            </div>
          )}
          <P css={agePermissionTitle}>Idade permitida</P>
          <div css={ageValuesContainer}>
            <HorizontalInputFormGroup css={inputLabel} errorMessage={feePaxTypeErrors.minAge} label="">
              <Input
                css={modificator}
                type="number"
                min={0}
                value={minAge}
                onChange={emitChangeEvent(`feePaxTypes.${feePaxTypeIndex}.minAge`)}
              />
            </HorizontalInputFormGroup>
            <p css={horinzontalMargin10}>Até</p>
            <HorizontalInputFormGroup css={inputLabel} errorMessage={feePaxTypeErrors.maxAge} label="">
              <Input
                css={modificator}
                type="number"
                min={0}
                value={maxAge}
                onChange={emitChangeEvent(`feePaxTypes.${feePaxTypeIndex}.maxAge`)}
              />
            </HorizontalInputFormGroup>
          </div>
          <div css={flexColumnCentered}>
            <P css={[marginTop15, marginBottom10]}>Percentual da tarifa base</P>
            <HorizontalInputFormGroup
              css={[inputLabel, width80]}
              errorMessage={feePaxTypeErrors.feeModificator}
              label=""
            >
              <InputWithSuffix
                css={[inputStyle, width100]}
                type="number"
                min="0"
                max="100"
                value={feeModificator}
                onChange={onFeeModificator}
              >
                <span>%</span>
              </InputWithSuffix>
            </HorizontalInputFormGroup>
          </div>
          <div css={flexColumnCentered}>
            <P css={[marginTop15, marginBottom10]}>Valor final</P>
            <HorizontalInputFormGroup css={[inputLabel, width80]} errorMessage={feePaxTypeErrors.finalPrice} label="">
              <InputMoneyMask value={calculatedPrice} onChange={onPriceChange} />
            </HorizontalInputFormGroup>
          </div>

          <div css={guideContainer}>
            <ToggleInputGroup
              id={indexName.toLowerCase()}
              checked={hasGuideTax}
              onChange={identity}
              onClick={onHandleToggle}
            />
            <P css={[marginTop15]}>Percentual - Guia</P>
          </div>
          <div css={guideAnimation} className={hasGuideTax && 'open'}>
            <HorizontalInputFormGroup css={[inputLabel, guidePercentual]} label="" errorMessage={taxesErrors.guide}>
              <InputWithSuffix
                css={[inputStyle, width100]}
                type="number"
                min="0"
                max="100"
                value={guide}
                onChange={onGuidePercentageChange}
              >
                <span>%</span>
              </InputWithSuffix>
            </HorizontalInputFormGroup>
            <P css={[marginTop15, marginBottom10, finalValueGuide]}>Valor final - Guia</P>
            <div css={[guideValue, marginTop10]}>
              <InputMoneyMask value={guidePrice} onChange={onGuidePriceChange} />
            </div>
            <div css={[flexColumnCentered]} className={hasGuideTax && 'open'}>
              <P css={[marginTop15, marginBottom10]}>Destino da taxa do guia</P>
              <HorizontalInputFormGroup label="" errorMessage={feePaxTypeErrors.bearer} css={[inputLabel, width(80)]}>
                <Select
                  placeholder=""
                  options={guideTaxesDestinationOptions}
                  value={bearer}
                  error={feePaxTypeErrors.bearer}
                  onChange={emitChangeEvent(`feePaxTypes.${feePaxTypeIndex}.taxes.bearer`)}
                />
              </HorizontalInputFormGroup>
            </div>
          </div>
        </div>
      </Card>
    </>
  )
}

import { keyframes } from '@emotion/core'

const slideTwoStepAnimation = (start = 0, end = 0) => keyframes`
0% {
    opacity: 0;
    transform: translateX(${start});
}

100% {
    opacity: 1;
    transform: translateX(${end});
}
`

export const slideRight = slideTwoStepAnimation('20px', 0)
export const slideLeft = slideTwoStepAnimation('-20px', 0)

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Card, H2, Row, H3, colors } from '@bonitour/components'
import { marginTop } from 'assets/styles/global'

const marginTop50 = marginTop(50)

const resultContainer = css`
  min-width: 180px;
  margin-right: 20px;
  margin-bottom: 10px;
  h3 {
    font-size: 18px;
  }
`

const resultValue = css`
  color: ${colors.primary};
  font-size: 22px;
  font-weight: bold;
`

const blurValue = css`
  filter: blur(0.5rem);
`

const ResultCard = ({ title, value, blur = false }) => {
  return (
    <Card css={resultContainer}>
      <H3>{title}</H3>
      <span css={[resultValue, blur && blurValue]}>{value}</span>
    </Card>
  )
}

export const RevenueResults = ({ totals = {}, isLoading = false }) => {
  const { totalReservationsAmount, totalSoldValue, totalRevenueValue, totalDeductionValue } = totals

  return (
    <div css={marginTop50}>
      <H2>Resultado</H2>
      <Row>
        <ResultCard title='Reservas' value={totalReservationsAmount} blur={isLoading} />
        <ResultCard title='Total vendido' value={totalSoldValue} blur={isLoading} />
        <ResultCard title='Total em receita' value={totalRevenueValue} blur={isLoading} />
        <ResultCard title='Total deduzido' value={totalDeductionValue} blur={isLoading} />
      </Row>
    </div>
  )
}

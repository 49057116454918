/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import {
  Image,
  CircleImage,
  CircleThumbnail,
  Column,
  flexHorizontallyCentered,
  flexColumnCentered,
  Label,
  Row,
  ImageIcon,
  LightWarningAlert
} from '@bonitour/components'
import {
  previewContainer,
  timeStyle,
  dateStyle,
  pushNotification,
  semiBold,
  appName,
  pushNotificationActivityName,
  pushNotificationMessageStyle,
  categoryTitleStyle,
  contentMessage,
  messageContainer,
  thumbImage,
  imageSize,
  logoContainer,
  logo,
  alertContainer,
  desktopOnly,
  stickyContainer
} from './NoticePreview.style'
import { Smartphone } from './Smartphone'
import { marginBottom, marginLeft } from 'assets/styles/global'
import LogoAlly from 'assets/svgs/logoAlly.svg'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'

const marginLeft10 = marginLeft(20)
const marginBottom5 = marginBottom(5)

export const NoticePreview = ({ notice = {}, activities = [], categories = [], activitiesWithVacancies }) => {
  const { serviceIds = [], pushNotificationMessage = '', message = '', category } = notice
  const time = useMemo(() => moment().format('HH:mm'), [])
  const date = useMemo(() => moment().format('dddd, D MMMM'), [])

  const [services, setServices] = useState([])

  const MapServices = useCallback(() => {
    const currentServices = []

    serviceIds.forEach((serviceId) => {
      const service = activities?.find(({ id }) => id === serviceId)

      if (activitiesWithVacancies.includes(serviceId) && notice.serviceIds.includes(serviceId)) {
        currentServices.push(service)
      }
    })

    setServices(currentServices)
  }, [activities, activitiesWithVacancies, notice.serviceIds, serviceIds])

  const categoryTitle = useMemo(
    () => categories?.find(({ value }) => value === category)?.label || '',
    [categories, category]
  )

  useEffect(() => {
    MapServices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activitiesWithVacancies, notice.serviceIds])

  return (
    <div css={previewContainer}>
      <div css={stickyContainer}>
        <Row css={desktopOnly}>
          <Column desktop={6} phone={12}>
            <div css={flexColumnCentered}>
              <Label>Notificação Push</Label>
              <Smartphone>
                <span css={timeStyle}>{time}</span>
                <span css={dateStyle}>{date}</span>
                {services?.map((service) => (
                  <div css={pushNotification} key={service?.id}>
                    <div css={[flexHorizontallyCentered, marginBottom5]}>
                      <div css={logoContainer}>
                        <Image css={logo} src={LogoAlly} />
                      </div>
                      <span css={appName}>Ally</span>
                    </div>
                    <p css={pushNotificationActivityName}>{service?.name}</p>
                    <p css={pushNotificationMessageStyle}>{pushNotificationMessage}</p>
                  </div>
                ))}
              </Smartphone>
            </div>
          </Column>

          <Column desktop={6} phone={12}>
            <div css={flexColumnCentered}>
              <Label>Notificação aberta</Label>
              <Smartphone className="smartphone-container">
                <span css={categoryTitleStyle}>{categoryTitle}</span>
                {categoryTitle &&
                  message &&
                  services?.map((service) => (
                    <div css={contentMessage} key={service?.id}>
                      <div css={[flexHorizontallyCentered, marginLeft10]}>
                        <CircleThumbnail css={thumbImage} size={40}>
                          {service?.image ? <CircleImage css={imageSize} src={service?.image} /> : <ImageIcon />}
                        </CircleThumbnail>
                        <span css={semiBold}>{service?.name}</span>
                      </div>
                      <div css={messageContainer}>
                        <p>{message}</p>
                      </div>
                    </div>
                  ))}
              </Smartphone>
            </div>
          </Column>
        </Row>

        <Row>
          <Column desktop={12} phone={12}>
            <LightWarningAlert css={alertContainer}>
              Os avisos serão enviados para o viajante através do aplicativo Ally e para os parceiros comerciais através
              do Orb. Atente-se para que o texto esteja claro e sem erros ortográficos.
            </LightWarningAlert>
          </Column>
        </Row>
      </div>
    </div>
  )
}

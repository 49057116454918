import { validateTime } from '@bonitour/common-functions'
import { string, number } from 'yup'

export const vacancyScheduleIntervalSchema = {
  initialTime: string()
    .required('O horário final é necessário')
    .test('test-validateTime', 'O horário inicial deve ser válido', validateTime),
  finalTime: string()
    .required('O horário final é necessário')
    .test('test-validateTime', 'O horário final deve ser válido', validateTime),
  interval: number()
    .typeError('Deve ser um número')
    .required('O intervalo é obrigatório').min(1, 'Valor deve ser maior que 0'),
  vacancySunday: number()
    .typeError('Deve ser um número')
    .required('As vagas são obrigatórias').min(0, 'Valor deve ser maior ou igual a 0'),
  vacancyMonday: number()
    .typeError('Deve ser um número')
    .required('As vagas são obrigatórias').min(0, 'Valor deve ser maior ou igual a 0'),
  vacancyTuesday: number()
    .typeError('Deve ser um número')
    .required('As vagas são obrigatórias').min(0, 'Valor deve ser maior ou igual a 0'),
  vacancyWednesday: number()
    .typeError('Deve ser um número')
    .required('As vagas são obrigatórias').min(0, 'Valor deve ser maior ou igual a 0'),
  vacancyThursday: number()
    .typeError('Deve ser um número')
    .required('As vagas são obrigatórias').min(0, 'Valor deve ser maior ou igual a 0'),
  vacancyFriday: number()
    .typeError('Deve ser um número')
    .required('As vagas são obrigatórias').min(0, 'Valor deve ser maior ou igual a 0'),
  vacancySaturday: number()
    .typeError('Deve ser um número')
    .required('As vagas são obrigatórias').min(0, 'Valor deve ser maior ou igual a 0')
}

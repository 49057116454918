export const labelList = ({ ally_communique_categories = [] } = {}) => {
  const labelDictionary = {
    alert: 'Alerta',
    service_confirmed: 'Experiência confirmada',
    service_canceled: 'Experiência cancelada',
    others: 'Outros'
  }

  return ally_communique_categories.map(({ id, value }) => ({
    id,
    value,
    label: labelDictionary[id]
  }))
}

import React, { useMemo } from 'react'
import { Button, Modal, Upload, useToast } from '@bonitour/components'
import { RECEIPTS_MIME_TYPES } from 'constants/paymentBatch'
import { UploadContent, uploadContentCss, uploadCss } from '../../InvoiceBatchGlobal.style'
import { continueButtonCss } from './InvoiceBatchPaymentReceipts.style'

const { REACT_APP_API_HOST } = process.env

export const InvoiceBatchPaymentReceipts = ({ isOpen, onClose = () => {}, unmountedReceipts = [] }) => {
  const { add: addToast } = useToast()

  const mountedReceipts = useMemo(
    () =>
      unmountedReceipts.map(({ id, url }, idx) => ({
        id,
        src: REACT_APP_API_HOST + url,
        name: `comprovante-${idx}`
      })),
    [unmountedReceipts]
  )

  return (
    <Modal title="Comprovantes de pagamento" isVisible={isOpen} onCloseClick={onClose}>
      <UploadContent css={uploadContentCss}>
        <Upload
          files={mountedReceipts}
          multiple={true}
          limitMbSize={15}
          emptyMessage="Arraste o arquivo ou clique para adicionar, máximo 15mb"
          accept={RECEIPTS_MIME_TYPES.join(', ')}
          onError={addToast}
          css={uploadCss}
          disabled={true}
          isIconHidden={true}
        />
      </UploadContent>
      <Button css={continueButtonCss} onClick={onClose}>
        Sair
      </Button>
    </Modal>
  )
}

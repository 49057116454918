import { ActivityParser } from 'core/parsers/Activity'
import { orbCore } from 'services/Orb/Service'

const { serviceDomain } = orbCore

export const ActivityService = {
  getDailyMonitoringReservations (serviceId = '', date) {
    return serviceDomain.getDailyMonitoringReservations(serviceId, date)
      .then(ActivityParser.dailyMonitoring)
  },
  getIsLockedService: (serviceId = '') => serviceDomain.getIsLockedService(serviceId).then(ActivityParser.getIsLockedService)
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { Button, PlusIcon, colors } from '@bonitour/components'
import { jsx, css } from '@emotion/core'

const button = css`
  background-color: transparent;
  display: flex;

  --btn-inner-clr: var(--addbtn-clr, ${colors.secondary});
`

const plusIcon = css`
  padding: 8px 10px;
  border-radius: var(--round-element-radius, 50%);
  background-color: var(--btn-inner-clr);
  margin-right: 10px;
`

const buttonLabel = css`
  color: var(--btn-inner-clr);
  margin: auto;
  font-weight: bold;
  font-size: 14px;
`

export const AddButton = ({ children, ...other }) => {
  return (
    <Button {...other} css={button}>
      <PlusIcon css={plusIcon} />
      <span css={buttonLabel}>
        {children}
      </span>
    </Button>
  )
}

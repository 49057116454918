/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import { useHistory } from 'react-router-dom'
import { HeaderPage } from '@bonitour/components'
import { useEditExternalProvider } from '../hooks/useEditExternalProvider'
import { ExternalProviderForm } from '../Form/ExternalProviderForm'

export const EditExternalProvider = () => {
  const { push } = useHistory()
  const { externalProvider, submit, loading } = useEditExternalProvider()

  const redirectToList = () => push('/external-providers')

  return (
    <>
      <HeaderPage onBack={redirectToList} title='Editar fornecedor externo' />
      <ExternalProviderForm externalProvider={externalProvider} submit={submit} loading={loading} />
    </>
  )
}

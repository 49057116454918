export const guideTaxesDestinationOptions = [
  {
    value: 'seller',
    label: 'Vendedor'
  },
  {
    value: 'provider',
    label: 'Fornecedor'
  }
]

import { PickupAndDropOffModel } from 'core/models/pickupAndDropOff'
import { PickupAndDropOffParser } from 'core/parsers/pickupAndDropOff'
import { orbCore } from 'services/Orb/Service'

const { pickUpAndDropOffDomain, pickUpAndDropOffMoovDomain } = orbCore

export const PickupAndDropOffService = {
  get (reservationId) {
    return pickUpAndDropOffDomain.get(reservationId).then(PickupAndDropOffParser.list)
  },
  update (pickUpDropOff) {
    const { pickupId } = pickUpDropOff
    return pickUpAndDropOffMoovDomain.update(pickupId, PickupAndDropOffModel.PickupAndDropOffUpdateModel(pickUpDropOff))
  },
  create (pickUpDropOff) {
    const { reservationId } = pickUpDropOff
    return pickUpAndDropOffDomain.create(reservationId, PickupAndDropOffModel.PickupAndDropOffCreateModel(pickUpDropOff))
  },
  remove (pickUpDropOffId) {
    return pickUpAndDropOffMoovDomain.remove(pickUpDropOffId)
  }
}

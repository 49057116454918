import * as Yup from 'yup'

export const cashierFiltersSchema = {
  initialDate: Yup.date().required('Data inicial é obrigatória')
    .max(Yup.ref('finalDate'), 'Data inicial deve ser menor que a data final'),
  finalDate: Yup.date().required('Data final é obrigatória')
    .min(Yup.ref('initialDate'), 'Data final deve ser maior que a data inicial'),
  user: Yup.string().nullable(),
  status: Yup.string().nullable(),
  cashierType: Yup.string().nullable()
}

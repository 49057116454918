import { colors } from '@bonitour/components'
import { marginRight, marginTop } from 'assets/styles/global'

const { css } = require('@emotion/core')

export const marginTop25 = marginTop(25)
export const marginTop50 = marginTop(50)
export const marginRight20 = marginRight(20)

export const dialogStyle = css`
  max-width: 470px;
`
export const bold = css`
  font-weight: bold;
`

export const dialogTextSize = css`
  font-size: 15px;
  line-height: 1.25rem;
`
export const buttonsContainer = css`
  display: flex;
  justify-content: flex-start;
`
export const externalicon = css`
  color: ${colors.primary};
  font-weight: bold;
  cursor: pointer;
`

import { css } from '@emotion/core'
import { colors, flexHorizontallyCentered } from '@bonitour/components'

export const cardStyle = css`
  margin: 10px 0;
  padding: 30px 20px;
`

export const selectStyle = (value = 100) => css`
  input {
    width: ${value}px;
  }
`

export const marginSide = css`
  margin: 0 10px;
`

export const grayColor = css`
  color: ${colors.gray3};
`

export const boldText = css`
  font-weight: bold;
`

export const horinzontalMargin10 = css`
  margin: 0 10px;
`

export const width110 = css`
  width: 110px;
`

export const line = css`
  ${flexHorizontallyCentered};
  flex-wrap: wrap;

  > span, div {
    margin-top: 5px;
    margin-bottom: 5px;
  }
`

export const radioGroup = css`
  line-height: 1.5;
`

/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useForm } from '@bonitour/app-functions'
import { formatMoney, identity } from '@bonitour/common-functions'
import { Card, colors, Column, flexColumn, GhostButton, H3, H4, Input, InputFormGroup, Modal, Row, scrollBar, Select, UploadInline, useToast, AsyncButton, BREAK_POINTS, DatePicker } from '@bonitour/components'
import { css, jsx } from '@emotion/core'
import { hidden, marginTop } from 'assets/styles/global'
import { refundPaymentTypesOptions, reservationPaymentProviderTypesOptions } from 'constants/reservationPaymentTypes'
import { useCallback, useEffect, useMemo } from 'react'
import { TicketList } from '../TicketList'
import { useTransactionFormAcquirers as useTransactionAcquirers } from './../hooks/useTransactionFormAcquirers'
import { useAcquirersOptions } from '../hooks/useAcquirersOptions'
import { useListBankAccountByPaymentMethod } from 'domains/BankAccounts/hooks/useListBankAccountByPaymentMethod'
import { paymentMethodsThatRequiredBankAccount } from 'constants/paymentBatch'
import { useCompany } from 'contexts/Company'
import { formatISOTime } from 'utils/time'

const marginTop10 = marginTop(10)
const marginTop15 = marginTop(15)
const marginTop20 = marginTop(20)

const limitContainer = css`
  max-height: calc(100vh - 450px);
  min-height: 250px;
  overflow-y: scroll;
  padding: 15px;
  ${scrollBar(5)}
`

const amountCard = css`
  min-width: 150px;
  display: inline-flex;
  margin-right: 20px;
  padding: 20px;
`

const amountLabel = css`
  font-size: 12px;
  color: ${colors.gray5};
  margin-bottom: 5px;
`

const amountStyle = css`
  font-size: 24px;
  color: ${colors.red1};
  font-weight: bold;
`
const fullInput = css`
  width: 100%;
`

const alignCenter = css`
  align-items: center;
`

const buttonsContainer = css`
  margin-top: 20px;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    display: flex;
    flex-direction: column;
  }
`

const cancelButton = css`
  margin-right: 20px;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    margin-bottom: 10px;
    margin-right: 0;
  }
`

const selectDrawerOpenToTop = css`
  width: 100%;

  .component-drawer-container {
    top: unset;
    bottom: 45px;

    ::after {
      top: unset;
      bottom: calc(0% - 9px);
      transform: rotate(180deg);
    }
  }
`

const selectVendor = css`
  .component-drawer-container {
    width: 18rem;
    white-space: normal;

    .component-drawer-item {
      line-height: 1.3;
    }
  }
`

const DEFAULT_PAYMENT_DATE = formatISOTime(new Date(), 'YYYY-MM-DD HH:mm:ss')

export const ProviderTransactionModal = ({
  partnerId = '',
  isVisible = false,
  loading = false,
  formBase = {},
  view = {},
  companiesOptions = [],
  tickets = [],
  onModalClose = identity,
  onModalSuccess = identity,
  onCompanyChange = identity
}) => {
  const { add: addToast } = useToast()

  const { id: vendorCompanyId } = useCompany()

  const { modalTitle, companyTitle, ticketTitle, hasTicketEdition, schema, isRefund = false } = view

  const { form, errors, onSubmit, setForm, utils: { onInputBlur, onInputChange } } = useForm(formBase, schema)

  const { companyId = '', ticketsIds, method = '', installments = '', upload = [], aut = '', doc = '', acquirer = '', originBankAccount, destinationBankAccount, paymentDate = DEFAULT_PAYMENT_DATE } = form

  const { acquirersList } = useAcquirersOptions(companyId)

  const isCredit = method === 'credit'
  const isDebit = method === 'debit'

  const clearInstallments = useCallback(() => {
    if (isDebit) {
      return onInputChange('installments')(1)
    }
    onInputChange('installments')('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDebit])

  const { acquirersOptions, installmentsOptions } = useTransactionAcquirers(form.method, acquirersList, form.acquirer, clearInstallments)

  const {
    loading: isLoadingBankAccounts,
    bankAccountOptionsForVendorOrSupplier,
    fillBankAccountOptionsForVendorOrSupplier
  } = useListBankAccountByPaymentMethod()

  const hasAcquirers = acquirersList?.length > 0

  const selectTicketLabel = companyId ? 'Não existem ingressos disponíveis' : 'Selecione a empresa para preencher os outros dados'

  const totalValue = useMemo(() => {
    const totalValue = tickets.reduce((acc, { id, value }) => {
      if (!ticketsIds || ticketsIds.map(ticket => ticket.id).indexOf(id) === -1) {
        return acc
      }
      return acc + value
    }, 0)
    return Math.abs(totalValue).toFixed(3)
  }, [tickets, ticketsIds])

  const methodOptions = useMemo(() => {
    if (isRefund) {
      return refundPaymentTypesOptions
    }

    return reservationPaymentProviderTypesOptions
  }, [isRefund, method])

  useEffect(() => {
    onCompanyChange(companyId)
  }, [companyId])

  const canShowBankAccountFields = useMemo(() => paymentMethodsThatRequiredBankAccount.includes(method), [method])

  const {
    hasOriginBankAccounts,
    hasDestinationBankAccounts
  } = useMemo(() => {
    const hasOriginBankAccounts = bankAccountOptionsForVendorOrSupplier.origin.length > 0
    const hasDestinationBankAccounts = bankAccountOptionsForVendorOrSupplier.destination.length > 0
    return { hasOriginBankAccounts, hasDestinationBankAccounts }
  }, [bankAccountOptionsForVendorOrSupplier.origin, bankAccountOptionsForVendorOrSupplier.destination])

  const resetNecessaryFields = useCallback(() => {
    setForm({
      ...form,
      destinationBankAccount: undefined,
      originBankAccount: undefined
    })
  }, [form])

  const onPaymentMethodChange = useCallback((value) => {
    resetNecessaryFields()
    const canFetchBankAccount = paymentMethodsThatRequiredBankAccount.includes(value)

    if (canFetchBankAccount) {
      fillBankAccountOptionsForVendorOrSupplier(value, vendorCompanyId, companyId)
    }

    onInputChange('method')(value)
  }, [onInputChange, fillBankAccountOptionsForVendorOrSupplier, resetNecessaryFields])

  useEffect(() => onInputChange('paymentDate')(DEFAULT_PAYMENT_DATE), [])

  const onSubmitClick = onSubmit((value) => onModalSuccess({ ...value, totalValue, refundCompanyId: partnerId }), () => addToast('Preencha o formulário corretamente'))
  return (
    <Modal isVisible={isVisible} title={modalTitle} onCloseClick={onModalClose}>
      <>
        {!isRefund && (
          <>
            <Column phone={12}>
              <H3>
                {companyTitle}
              </H3>
            </Column>
            <Column phone={12}>
              <Select
                css={selectVendor}
                value={companyId}
                error={errors.companyId}
                onChange={onInputChange('companyId')}
                onBlur={onInputBlur('companyId')}
                options={companiesOptions}
                placeholder='Selecionar empresa'
              />
            </Column>
            <Column phone={12} css={marginTop20}>
              <H3>
                {ticketTitle}
              </H3>
            </Column>
          </>
        )}

        {isRefund && (
          <Column phone={12}>
            <H3>
              {companyTitle}
            </H3>
          </Column>
        )}

        <Column phone={12} css={[limitContainer]}>
          {
            isRefund || companyId
              ? (
                <TicketList
                  isSelectable={hasTicketEdition}
                  tickets={tickets}
                  ticketsIds={ticketsIds}
                  isRefund={isRefund}
                  onTicketIdsChange={onInputChange('ticketsIds')}
                  loading={loading}
                  isProvider
                />
              )
              : <H4>{selectTicketLabel}</H4>
          }
        </Column>
        {
          isRefund || companyId
            ? (
              <>
                <Row css={alignCenter} phone={12}>
                  <Column css={marginTop15} phone={12} desktop={3}>
                    <Card css={amountCard}>
                      <div css={flexColumn}>
                        <p css={amountLabel}>Valor</p>
                        <span css={[amountStyle]}>{formatMoney(totalValue || 0)}</span>
                      </div>
                    </Card>
                  </Column>
                </Row>

                <Row>
                  <Column phone={12} desktop={3}>
                    <InputFormGroup label='Forma de pagamento' css={fullInput}>
                      <Select
                        options={methodOptions}
                        value={method}
                        css={selectDrawerOpenToTop}
                        error={errors.method}
                        onChange={onPaymentMethodChange}
                        onBlur={onInputBlur('method')}
                      />
                    </InputFormGroup>
                  </Column>

                  <Column phone={12} desktop={3}>
                    <InputFormGroup label='Data de pagamento' css={fullInput}>
                      <DatePicker
                        maxDate={new Date()}
                        error={errors.paymentDate}
                        customCss={[fullInput]}
                        onChange={onInputChange('paymentDate')}
                        onBlur={onInputBlur('paymentDate')}
                        value={paymentDate}
                      />
                    </InputFormGroup>
                  </Column>

                  <Column phone={12} desktop={3} css={[!canShowBankAccountFields && hidden]}>
                    <InputFormGroup label={'Conta bancária de origem'} css={fullInput} errorMessage={errors.originBankAccount}>
                      <Select
                        options={bankAccountOptionsForVendorOrSupplier.origin}
                        value={originBankAccount}
                        disabled={isLoadingBankAccounts || !hasOriginBankAccounts}
                        onChange={onInputChange('originBankAccount')}
                        onBlur={onInputBlur('originBankAccount')} />
                    </InputFormGroup>
                  </Column>

                  <Column phone={12} desktop={3} css={[!canShowBankAccountFields && hidden]}>
                    <InputFormGroup label={'Conta bancária de destino'} css={fullInput} errorMessage={errors.destinationBankAccount}>
                      <Select
                        options={bankAccountOptionsForVendorOrSupplier.destination}
                        value={destinationBankAccount}
                        disabled={isLoadingBankAccounts || !hasDestinationBankAccounts}
                        onChange={onInputChange('destinationBankAccount')}
                        onBlur={onInputBlur('destinationBankAccount')} />
                    </InputFormGroup>
                  </Column>

                  <Column phone={12} desktop={3} css={[!(isCredit || isDebit) && hidden]}>
                    <InputFormGroup label='Adquirente e bandeira' css={fullInput}>
                      <Select
                        options={acquirersOptions}
                        value={acquirer}
                        css={fullInput}
                        error={errors.acquirer}
                        onChange={onInputChange('acquirer')}
                        onBlur={onInputBlur('acquirer')}
                        disabled={!hasAcquirers}
                      />
                    </InputFormGroup>
                  </Column>

                  <Column phone={12} desktop={2} css={[!isCredit && hidden]}>
                    <InputFormGroup label='Parcelas'>
                      <Select
                        options={installmentsOptions}
                        value={installments}
                        css={fullInput}
                        error={errors.installments}
                        onChange={onInputChange('installments')}
                        onBlur={onInputBlur('installments')}
                        disabled={!acquirer}
                      />
                    </InputFormGroup>
                  </Column>

                  <Column desktop={3}>
                    <InputFormGroup label='Anexar comprovante (máximo 15mb)'>
                      <UploadInline
                        files={upload}
                        error={errors.upload}
                        onChange={onInputChange('upload')}
                        onBlur={onInputBlur('upload')}
                      />
                    </InputFormGroup>
                  </Column>
                </Row>

                <Row>
                  <Column phone={12} desktop={2} css={[!(isCredit || isDebit) && hidden]}>
                    <InputFormGroup label='Aut'>
                      <Input
                        value={aut}
                        css={fullInput}
                        error={errors.aut}
                        onChange={onInputChange('aut')}
                        onBlur={onInputBlur('aut')}
                      />
                    </InputFormGroup>
                  </Column>

                  <Column phone={12} desktop={2} css={[!(isCredit || isDebit) && hidden]}>
                    <InputFormGroup label='Doc'>
                      <Input
                        value={doc}
                        css={fullInput}
                        error={errors.doc}
                        onChange={onInputChange('doc')}
                        onBlur={onInputBlur('doc')}
                      />
                    </InputFormGroup>
                  </Column>
                </Row>
              </>
            )
            : ''
        }

        <Row css={[!(isCredit || isDebit) && hidden, hasAcquirers && hidden]}>
          <Column css={marginTop10}>
            O fornecedor deve adicionar um adquirente para realizar pagamentos no cartão.
          </Column>
        </Row>

        <div css={buttonsContainer}>
          <GhostButton css={cancelButton} onClick={onModalClose}>Voltar</GhostButton>
          <AsyncButton onClick={onSubmitClick} disabled={loading}>Registrar</AsyncButton>
        </div>
      </>
    </Modal>
  )
}

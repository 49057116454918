export const activityComplementaryInfoParser = (activity = {}) => {
  let {
    description,
    observation,
    payment_types: paymentsTypes,
    what_to_take: whatToTake,
    what_is_included: whatIsIncluded,
    ally_visible: isAllyVisible,
    selling_deadline: sellingDeadline
  } = activity

  description = JSON.parse(description) || {}

  return {
    description: description['pt-br'] || '',
    observation: observation || '',
    isAllyVisible,
    sellingDeadline,
    paymentsTypes: paymentsTypes || [],
    whatToTake: whatToTake || [],
    whatIsIncluded: whatIsIncluded || []
  }
}

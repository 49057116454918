/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx, css } from '@emotion/core'
import { PaginationContainer, H3, NotificationItem, colors, Card } from '@bonitour/components'
import { clickable } from 'assets/styles/global'
import { useHistory } from 'react-router-dom'
import { useNotification } from 'hooks/domains/useNotifications'
import { identity } from '@bonitour/common-functions'

const notificationTypeLabel = css`
  font-size: 18px;
  color: ${colors.gray3};
  font-weight: bold;
  padding: 5px;
  margin-bottom: 10px;
`

const reduceCardPadding = css` 
  padding: 5px 15px;
`

const NotificationList = ({ label = '', markAsRead = identity, notifications = [] }) => {
  const history = useHistory()

  const onNotificationClick = (notificationId, url) => () => {
    markAsRead(notificationId)
    if (url) {
      history.push(url)
    }
  }

  if (notifications.length === 0) return null

  return (
    <>
      {label && <div css={notificationTypeLabel}>{label}</div>}
      {notifications.map(item => (
        <NotificationItem
          isRead={item.isRead}
          key={item.id}
          css={clickable}
          title={item.title}
          subtitle={item.subtitle}
          initials={item.initials}
          onClick={onNotificationClick(item.id, item.url)}
        />
      ))}
    </>
  )
}

export const NotficationList = () => {
  const { meta, readNotifications, notReadNotifications, markAsRead, changePagination } = useNotification()

  return (
    <>
      <H3>Notificações</H3>
      <Card css={reduceCardPadding}>
        <PaginationContainer onPagination={changePagination} total={meta.total} pagesQuantity={meta.totalPages}>
          <NotificationList label='Notificações novas' markAsRead={markAsRead} notifications={notReadNotifications} />
          <NotificationList label='Notificações lidas' markAsRead={markAsRead} notifications={readNotifications} />
        </PaginationContainer>
      </Card>
    </>
  )
}

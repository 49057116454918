/** @jsxRuntime classic */
/** @jsx jsx */
import { identity } from '@bonitour/common-functions'
import { SortRightIcon, TableBodyCell } from '@bonitour/components'
import { jsx, css } from '@emotion/core'
import { arrowOpenIndicator, clickable } from 'assets/styles/global'

const selectionArrow = css`
  display: inline-block;
  transition: all 0.4s ease;
  font-size: 9px;
  padding: 8px;
`

const tableHourCell = css`
  width: 100px;
`

const tableHourCellExpanded = css`
  width: 132px;
  padding: 5px;
  vertical-align: top;
  padding-top: 20px;
`

export const TableBodyHourCell = ({
  rowHour,
  isExpanded = false,
  isExpandable = true,
  onClick: emitClickEvent = identity
}) => (
  <TableBodyCell css={[isExpandable && clickable, tableHourCell, isExpanded && tableHourCellExpanded]} onClick={isExpandable ? emitClickEvent : identity}>
    {isExpandable && <SortRightIcon css={[selectionArrow, isExpanded && arrowOpenIndicator]} />}
    {rowHour}
  </TableBodyCell>
)

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Card, PaginationContainer } from '@bonitour/components'
import { InvoiceTable } from './InvoiceTable/InvoiceTable'
import { InvoiceFilters } from './InvoiceFilters'
import { identity } from '@bonitour/common-functions'
import { InvoiceListTotals } from './InvoiceListTotals'

export const InvoiceList = ({
  invoiceList = [],
  totals = {},
  partnerOptions = [],
  invoiceMeta = {},
  initialFilters = {},
  onNavigation: emitNavigationEvent = identity,
  onPaginate: emitPaginationEvent = identity,
  onFilterSubmit = identity,
  loading = false
}) => {
  const { total, totalPages } = invoiceMeta

  return (
    <>
      <Card>
        <InvoiceFilters
          partnerOptions={partnerOptions}
          initialFilters={initialFilters}
          onFilterSubmit={onFilterSubmit}
        />

        {!loading && Boolean(invoiceList.length > 0) && <InvoiceListTotals totals={totals} />}

        <PaginationContainer total={total} pagesQuantity={totalPages} onPagination={emitPaginationEvent}>
          <InvoiceTable invoiceList={invoiceList} onNavigation={emitNavigationEvent} loading={loading} />
        </PaginationContainer>
      </Card>
    </>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { InputFormGroup, DatePicker, InputMoneyMask, Select, Upload, Column, Row, useToast, UploadFootnote, Textarea } from '@bonitour/components'
import { hidden, marginBottom } from 'assets/styles/global'
import { identity } from '@bonitour/common-functions'
import { invoicePaymentTypesOptions } from 'domains/Invoice/constants/InvoicePaymentStatus'
import { useEffect, useMemo, useState } from 'react'
import { useListBankAccountByPaymentMethod } from 'domains/BankAccounts/hooks/useListBankAccountByPaymentMethod'

const marginBottom20 = marginBottom(20)

export const width180 = css`
  width: 180px;
`

export const fullWidth = css`
  width: 100%;
`

export const EditPayment = ({
  vendorId = '',
  companyId = '',
  labels = 'pagamento',
  isDisabled = false,
  invoiceReceive = false,
  paymentInfo = {},
  paymentInfoErrors = {},
  onChange: emitChangeEvent = identity
}) => {
  const { add: addToast } = useToast()
  const [originBankAccountOptions, setOriginBankAccountOptions] = useState([])
  const [destinationBankAccountOptions, setDestinationBankAccountOptions] = useState([])
  const { value, date, type, observation, originBankAccount, destinationBankAccount, attachments = [], vendorApproval } = paymentInfo

  const { fetchBankAccountsOptions } = useListBankAccountByPaymentMethod()

  const isVendor = useMemo(() => vendorId === companyId, [vendorId, companyId])

  const isBankAccountShow = useMemo(() => {
    if (type === 'bank_billet' || type === 'bank_transfer' || type === 'pix') {
      return true
    }
    return false
  }, [type])

  const isPaymentApproved = useMemo(() => vendorApproval === 'approved', [vendorApproval])

  useEffect(() => {
    if (type && companyId) {
      fetchBankAccountsOptions({ paymentMethod: type, companyId }).then(setOriginBankAccountOptions)
    }

    if (type && vendorId) {
      fetchBankAccountsOptions({ paymentMethod: type, companyId: vendorId }).then(setDestinationBankAccountOptions)
    }
  }, [type, companyId, vendorId, fetchBankAccountsOptions, setOriginBankAccountOptions, setDestinationBankAccountOptions])

  return (
    <>
      <Row>
        <Column phone={12} desktop={4}>
          <InputFormGroup id='value' errorMessage={paymentInfoErrors.value} label={`Valor do ${labels}`}>
            <InputMoneyMask disabled={isDisabled} value={value} onChange={emitChangeEvent('value')} />
          </InputFormGroup>
        </Column>

        <Column phone={12} desktop={4}>
          <InputFormGroup id='date' errorMessage={paymentInfoErrors.date} label={`Data do ${labels}`}>
            <DatePicker disabled={isDisabled} css={fullWidth} value={date} onChange={emitChangeEvent('date')} />
          </InputFormGroup>
        </Column>

        <Column phone={12} desktop={4}>
          <InputFormGroup id='type' errorMessage={paymentInfoErrors.type} label={`Forma de ${labels}`}>
            <Select disabled={isDisabled} options={invoicePaymentTypesOptions} value={type} onChange={emitChangeEvent('type')} />
          </InputFormGroup>
        </Column>
      </Row>

      {isBankAccountShow &&
        <Row>
          {!isVendor &&
            <Column phone={12} desktop={6}>
              <InputFormGroup css={invoiceReceive && hidden} id='originBankAccount' errorMessage={paymentInfoErrors.originBankAccount} label='Conta bancária de origem'>
                <Select options={originBankAccountOptions} value={originBankAccount} onChange={emitChangeEvent('originBankAccount')} />
              </InputFormGroup>
            </Column>}

          <Column phone={12} desktop={6}>
            <InputFormGroup id='destinationBankAccount' errorMessage={paymentInfoErrors.destinationBankAccount} label='Conta bancária de destino'>
              <Select
                options={destinationBankAccountOptions}
                value={destinationBankAccount}
                onChange={emitChangeEvent('destinationBankAccount')}
                disabled={!isVendor && isPaymentApproved}
              />
            </InputFormGroup>
          </Column>
        </Row>}

      <Row>
        <Column phone={12} desktop={6}>
          <div css={fullWidth}>
            <InputFormGroup css={marginBottom20} label='Comprovante'>
              <Upload
                multiple
                disabled={isDisabled}
                files={attachments}
                onChange={emitChangeEvent('attachments')}
                limitMbSize={15}
                onError={addToast}
                emptyMessage='Adicione arquivos com no máximo 15Mb'
                accept='image/png, image/jpeg, application/pdf'
              />
            </InputFormGroup>
            <UploadFootnote css={[fullWidth, marginBottom20]} typeList={['.jpg', '.png', '.pdf']} />
          </div>
        </Column>

        <Column phone={12} desktop={6}>
          <InputFormGroup id='type' errorMessage={paymentInfoErrors.observation} label='Observação'>
            <Textarea disabled={isDisabled} value={observation} onChange={emitChangeEvent('observation')} />
          </InputFormGroup>
        </Column>
      </Row>
    </>
  )
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Button, PaginationContainer } from '@bonitour/components'
import { ProviderCard } from './Card'
import { useHistory } from 'react-router-dom'
import { PaginatedProvidersContext } from 'app/Providers/ProvidersContext'
import { memo, useCallback, useContext, useMemo } from 'react'
import { paginationContainer, secondaryButton, loadingListContainer } from './List.style'

const LoadingList = memo(
  ({ elementsCount }) => {
    return (
      <ul css={loadingListContainer}>
        {Array.from({ length: elementsCount }, (_, i) => i).map((key) => (
          <li key={key} />
        ))}
      </ul>
    )
  },
  (prevProps, nextProps) => prevProps.elementsCount === nextProps.elementsCount
)

export const ProvidersList = ({ invitesInfo = {} }) => {
  const history = useHistory()
  const redirectToInvite = (inviteId) => () => history.push(`/group/invitation/${inviteId}`)

  const {
    providersInfo,
    loadingProviders,
    pagination: { currentPage, pageLimit },
    handlePagination
  } = useContext(PaginatedProvidersContext)

  const paginationMiddleware = useCallback(
    (page, perPage) => {
      if ((currentPage === page && pageLimit === perPage) || loadingProviders) {
        return
      }
      handlePagination(page, perPage)
    },
    [handlePagination, currentPage, pageLimit, loadingProviders]
  )

  const totalEntries = useMemo(() => providersInfo.total, [providersInfo.total])
  const totalPages = useMemo(() => providersInfo.totalPages, [providersInfo.totalPages])
  const perPage = useMemo(() => pageLimit, [pageLimit])

  return (
    <PaginationContainer
      total={totalEntries}
      pagesQuantity={totalPages}
      onPagination={paginationMiddleware}
      childrenCustomCss={[paginationContainer]}
    >
      {loadingProviders
        ? (
          <LoadingList elementsCount={perPage} />
        )
        : (
          providersInfo.providers.map(({ id, name, image, active, group }) => (
            <ProviderCard group={group} name={name} image={image} active={active} inviteId={invitesInfo[id]} key={id}>
              {Boolean(invitesInfo[id]) && (
                <Button onClick={redirectToInvite(invitesInfo[id])} css={secondaryButton}>
                Visualizar Convite
                </Button>
              )}
            </ProviderCard>
          ))
        )}
    </PaginationContainer>
  )
}

import { addressModel } from './address'
import { metaModel } from './meta'

/* eslint-disable camelcase */
export const ExternalProviderModel = {
  single: (externalProvider = {}) => {
    const { name, document, addressInfo = {} } = externalProvider
    const address = addressModel(addressInfo)

    return {
      name,
      document,
      address
    }
  },
  list: ({ filters, pagination }) => {
    const params = [
      ExternalProviderParamsModel(filters),
      metaModel(pagination)
    ]

    return params
  }
}

export const ExternalProviderParamsModel = (filterParams = {}) => {
  const { name } = filterParams

  return {
    name
  }
}

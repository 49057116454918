import { colors, BREAK_POINTS } from '@bonitour/components'

import { css } from '@emotion/core'
import { margin, marginBottom, marginLeft, marginRight, marginTop } from 'assets/styles/global'

export const marginTop50 = marginTop(50)
export const marginBottom20 = marginBottom(20)
export const marginRight20 = marginRight(20)
export const marginRight10 = marginRight(10)
export const marginLeft10 = marginLeft(10)
export const marginVertical5 = margin(5, 0, 5, 0)

export const container = css`
  color: ${colors.gray3};
`

export const inputStyle = css`
  width: 120px;
  ${marginRight10};
  ${marginLeft10};

  @media (max-width: ${BREAK_POINTS.smallTablet}) {
    margin: 10px 0;
  }
`

export const checkboxContainer = css`
  display: grid;
  grid-template-columns: auto auto auto auto;

  @media (max-width: ${BREAK_POINTS.smallTablet}) {
    grid-template-columns: auto;
  }
`

export const mobileInputLayout = css`
  @media (max-width: ${BREAK_POINTS.smallTablet}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { H2, TableContainer, TableBody, Button, useToast, scrollBar } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { margin, marginBottom, backgroundPrimaryVariant } from 'assets/styles/global'
import { AddLabel } from 'components/AddLabel'
import { ActivityVacancySchedulesHeader } from 'containers/ActivityVacancySchedulesTable/ActivityVacancySchedulesHeader'
import { ActivityVacancySchedulesRow } from 'containers/ActivityVacancySchedulesTable/ActivityVacancySchedulesRow'
import { useState } from 'react'
import { CreateVacancyScheduleForm } from './CreateVacancyScheduleForm'
import { buildIntervalArray } from './ActivityVacancyFormSchedules.utils'

const tableContainer = css`
  overflow-x: auto;
  ${scrollBar(5)};
`

const emptySchedule = {
  time: '',
  vacancyQuantity: 0,
  sundayQuantity: 0,
  mondayQuantity: 0,
  tuesdayQuantity: 0,
  wednesdayQuantity: 0,
  thursdayQuantity: 0,
  fridayQuantity: 0,
  saturdayQuantity: 0
}

const onBlur = x => () => identity(x)

export const ActivityVacancyFormSchedules = ({
  schedules = [],
  errors = {},
  onBlur: emitBlurEvent = onBlur,
  onChange: emitChangeEvent = identity,
  onItemRemove: emitRemoveItemEvent = identity,
  onItemAdd: emitAddItemEvent = identity
}) => {
  const { add: addToast } = useToast()
  const [isModalOpened, setModalOpenState] = useState(false)
  const addDefaultVacancyPeriod = () => emitAddItemEvent(emptySchedule)
  const deleteVacancyPeriod = index => () => emitRemoveItemEvent(index)

  const toggleModal = () => setModalOpenState(prev => !prev)
  const onFormSuccess = (form) => {
    const newSchedule = buildIntervalArray(schedules, form)
    emitChangeEvent('scheduleList')(newSchedule)
    addToast('Vagas por horário adicionadas', 'success')
  }

  return (
    <>
      <div>
        <H2 css={marginBottom(20)}>Horários</H2>
        <Button css={backgroundPrimaryVariant} onClick={toggleModal}>Cadastrar vagas por horário</Button>
      </div>
      {schedules.length > 0 && (
        <div css={tableContainer}>
          <TableContainer css={margin(30, 0, 10, 0)}>
            <ActivityVacancySchedulesHeader />
            <TableBody>
              {schedules.map((schedule, index) => (
                <ActivityVacancySchedulesRow
                  key={index}
                  index={index}
                  vacancySchedule={schedule}
                  errors={errors[index]}
                  onBlur={emitBlurEvent}
                  onChange={emitChangeEvent}
                  onDelete={deleteVacancyPeriod(index)}
                />
              ))}
            </TableBody>
          </TableContainer>
        </div>
      )}

      <AddLabel onClick={addDefaultVacancyPeriod}>Novo horário</AddLabel>
      <CreateVacancyScheduleForm
        isVisible={isModalOpened}
        onCloseClick={toggleModal}
        onSuccess={onFormSuccess}
      />
    </>
  )
}

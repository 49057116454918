import { useCallback, useMemo, useState } from 'react'
import { ReportService } from 'core/services/Reports'
import { useToast } from '@bonitour/components'
import { exportCsv, exportXlsx } from '@bonitour/common-functions'
import { usePermissions } from 'hooks/usePermissions'
import { useQuery } from 'react-query'

const LIST_ATTRIBUTES_STALE_TIME = 1000 * 60 * 15 // 15 minutes
const DEFAULT_LIST_ATTRIBUTES_PAYLOAD = {
  lang: 'pt-br'
}
const PERMISSION_ERROR = 'Você não possui permissão de acesso a exportação de reembolso dos turistas.'

export const useTouristPaymentRefundExport = () => {
  const { add: addToast } = useToast()

  const { handlePermission } = usePermissions()

  const [isLoading, setIsLoading] = useState(false)

  const exportToFile = useCallback((data, format) => {
    const FILENAME = 'estornos-turista'
    if (format === 'xlsx') {
      exportXlsx(FILENAME, data)
    } else {
      exportCsv(FILENAME, data)
    }
  }, [])

  const onError = useCallback(
    (error) => {
      const hasPermission = handlePermission(error, PERMISSION_ERROR)
      hasPermission && addToast('Ocorreu um erro inesperado.')
    },
    [addToast, handlePermission]
  )

  const { data: refundAttributesData, isLoading: isLoadingRefundAttributesData } = useQuery(
    ['listRefundAttributes'],
    async () => ReportService.listRefundAttributesToExport(DEFAULT_LIST_ATTRIBUTES_PAYLOAD),
    {
      staleTime: LIST_ATTRIBUTES_STALE_TIME,
      retry: 2,
      onError
    }
  )

  const getRefundAttributes = useCallback(
    ({ format, wantedFields }, filters) => {
      setIsLoading(true)
      return ReportService.getRefundAttributesToExport({
        format,
        wantedFields,
        filters
      })
        .then((data) => {
          exportToFile(data, format)
        })
        .catch(() => addToast('Houve um erro inesperado na exportação.'))
        .finally(() => setIsLoading(false))
    },
    [addToast, exportToFile]
  )

  const refundColumns = useMemo(
    () =>
      Object.keys(refundAttributesData || {}).map((key) => ({
        label: refundAttributesData[key],
        value: key
      })),
    [refundAttributesData]
  )

  return {
    isLoading: isLoading || isLoadingRefundAttributesData,
    refundColumns,
    getRefundAttributes
  }
}

import { useCompany } from 'contexts/Company'
import { useUser } from 'contexts/User'
import { useCallback, useRef } from 'react'

const sanitize = keyPart => keyPart.replace(/[^a-zA-Z0-9-]/g, '-')

export const useCachedPromise = ({
  domain = 'cachedPromise',
  expirationMinutes = 10,
  checkUpdateAfterMinutes = 2
} = {}) => {
  const { id: companyId } = useCompany()
  const { authKey } = useUser()

  const pendingPromises = useRef({})
  const updatingKeys = useRef(new Set())

  const invalidateCache = useCallback(() => {
    const length = sessionStorage.length
    const keys = Array.from({ length }, (_, i) => sessionStorage.key(i))
    keys
      .filter(key => key.startsWith(`${sanitize(domain)}_`))
      .forEach(key => sessionStorage.removeItem(key))
    sessionStorage.setItem(`${sanitize(domain)}_sessionKey`, authKey)
  }, [authKey, domain])

  const resolvePromiseWithCache = useCallback(
    (promise, key, ...params) => new Promise(
      (resolve, reject) => {
        const cacheSessionKey = sessionStorage.getItem(`${sanitize(domain)}_sessionKey`)
        if (cacheSessionKey !== authKey) {
          invalidateCache()
        }
        const cacheKey = `${sanitize(domain)}_company${sanitize(companyId)}_key${sanitize(key)}_params${JSON.stringify(params)}`
        const executeAndUpdateCache = () => promise(...params)
          .then((data) => {
            sessionStorage.setItem(cacheKey, JSON.stringify(data))
            sessionStorage.setItem(`${cacheKey}_updatedAt`, Date.now())
            return data
          })
        const cacheAge = Date.now() - Number(sessionStorage.getItem(`${cacheKey}_updatedAt`) || undefined)
        if (cacheAge < (expirationMinutes * 60000)) {
          const cachedResult = sessionStorage.getItem(cacheKey)
          if (cachedResult) {
            try {
              resolve(JSON.parse(cachedResult))
              if (cacheAge > (checkUpdateAfterMinutes * 60000)) {
                if (updatingKeys.current.has(cacheKey)) {
                  return
                }
                updatingKeys.current.add(cacheKey)
                executeAndUpdateCache()
                  .then(() => {
                    updatingKeys.current.delete(cacheKey)
                  })
                  .catch(err => {
                    console.error('error updating cache', err)
                    updatingKeys.current.delete(cacheKey)
                  })
              }
              return
            } catch (err) {
              console.error('error resolving promise with cached value', err)
              // continue to fetch fresh data
            }
          }
        }
        if (pendingPromises.current[cacheKey]) {
          pendingPromises.current[cacheKey].push({ resolve, reject })
          return
        }
        pendingPromises.current[cacheKey] = [{ resolve, reject }]
        executeAndUpdateCache()
          .then(data => {
            pendingPromises.current[cacheKey].forEach(({ resolve }) => resolve(data))
            delete pendingPromises.current[cacheKey]
          })
          .catch(err => {
            pendingPromises.current[cacheKey].forEach(({ reject }) => reject(err))
            delete pendingPromises.current[cacheKey]
          })
      }

    ),
    [domain, authKey, companyId, expirationMinutes, invalidateCache, checkUpdateAfterMinutes]
  )

  return { resolvePromiseWithCache, invalidateCache }
}

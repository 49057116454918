export const cashierPaymentTypesMap = {
  debit: 'Débito',
  credit: 'Crédito',
  cash: 'Dinheiro',
  bank_billet: 'Boleto',
  bank_transfer: 'Transferência Bancária',
  pay_credit: 'Pay - Cartão de Crédito',
  pay_pix: 'Pay - Pix',
  pix: 'Pix',
  discount: 'Desconto',
  partnership_account_credit: 'Crédito de conta parceria',
  'N/D': 'N/D'
}

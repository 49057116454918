/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Card, Input, InputFormGroup, colors, Button, Select, useToast, Row, Column, DatePicker } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { useForm } from '@bonitour/app-functions'
import { string } from 'yup'
import { useCallback, useState } from 'react'
import { batchStatusOptions } from '../constants/batchStatus'
import { useKeyPress } from 'hooks/useKeyPress'
import { width100 } from 'assets/styles/global'
import { useValidateDateRange } from 'domains/CompanyReports/hooks/useValidadeDateRange'
import { SafeDate } from '@binamik/js-providers'

const button = css`
  height: 40px;
  width: 100%;
  margin-top: 20px;
`
const cardContrast = css`
  margin-bottom: 20px;
  padding: 15px;
  background-color: ${colors.gray13};
  border: none;
`

const BatchesFilterSchema = {
  reference: string(),
  expectedInitialPaymentDate: string(),
  expectedFinalPaymentDate: string(),
  status: string(),
  guideName: string(),
  guideDocument: string()
}

export const GeneratedBatchesFilter = ({
  initialFilters,
  onFilterSubmit: emitFilterSubmit = identity,
  isLoading = false
}) => {
  const { validateDateRange } = useValidateDateRange()
  const { add: addToast } = useToast()
  const { form, errors, onSubmit, utils: { onInputChange } } = useForm(initialFilters, BatchesFilterSchema)
  const [filterBatchStatusOptions] = useState([{ value: '', label: 'Todos' }, ...batchStatusOptions])
  const { reference, expectedInitialPaymentDate, expectedFinalPaymentDate, guideName = '', status = '', guideDocument = '' } = form
  const { onKeyDown } = useKeyPress()

  const onError = useCallback(() => addToast('Preencha o formulário corretamente'), [addToast])

  const validateFilters = useCallback((filters) => {
    const safeInitialDate = filters?.expectedInitialPaymentDate && new SafeDate(filters?.expectedInitialPaymentDate).jsDate
    const safeEndDate = filters?.expectedFinalPaymentDate && new SafeDate(filters?.expectedFinalPaymentDate).jsDate
    if (safeInitialDate && safeEndDate) {
      if (!validateDateRange(safeInitialDate, safeEndDate)) {
        return
      }
    }
    emitFilterSubmit(filters)
  }, [emitFilterSubmit, validateDateRange])

  const onClick = onSubmit(validateFilters, onError)

  return (
    <Card css={cardContrast}>
      <Row>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Data esperada do pagamento inicial' errorMessage={errors.expectedInitialPaymentDate}>
            <DatePicker
              value={expectedInitialPaymentDate}
              onChange={onInputChange('expectedInitialPaymentDate')}
              css={width100}
              allowsEmpty
              onKeyDown={event => onKeyDown(event, onClick)}
              preventHeaderIndex={true}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Data esperada do pagamento final' errorMessage={errors.expectedFinalPaymentDate}>
            <DatePicker
              value={expectedFinalPaymentDate}
              onChange={onInputChange('expectedFinalPaymentDate')}
              css={width100}
              allowsEmpty
              onKeyDown={event => onKeyDown(event, onClick)}
              preventHeaderIndex={true}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Referência do lote' errorMessage={errors.reference}>
            <Input
              value={reference}
              onChange={onInputChange('reference')}
              css={width100}
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Status' errorMessage={errors.status}>
            <Select
              placeholder='Todos'
              options={filterBatchStatusOptions}
              value={status}
              error={errors.status}
              onChange={onInputChange('status')}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={4}>
          <InputFormGroup label='Nome do guia responsável' errorMessage={errors.guideName}>
            <Input
              value={guideName}
              error={errors.guideName}
              onChange={onInputChange('guideName')}
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={4}>
          <InputFormGroup label='Documento do guia responsável' errorMessage={errors.guideDocument}>
            <Input
              value={guideDocument}
              error={errors.guideDocument}
              onChange={onInputChange('guideDocument')}
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={4}>
          <Button css={button} onClick={onClick} disabled={isLoading}>Pesquisar</Button>
        </Column>
      </Row>
    </Card>
  )
}

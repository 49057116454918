import { formatISOTime } from 'utils/time'
import { MetaParser } from './Meta'

export const CashierHandlingParser = {
  single: (cashierHandling) => {
    const {
      id,
      value,
      operator_name: operatorName,
      supervisor_name: supervisorName,
      observation: comment,
      date,
      cash_handling_type: cashHandlingType
    } = cashierHandling?.cashierHandling ?? cashierHandling ?? {}

    return {
      id,
      value,
      operatorName,
      supervisorName,
      comment,
      date: date ? formatISOTime(date, 'DD/MM/YY - HH:mm') : '',
      cashHandlingType
    }
  },
  list: ({ meta, cashier_handlings: cashiersHandlings = [] }) => {
    return {
      cashiersHandlings: cashiersHandlings.map(CashierHandlingParser.single),
      meta: MetaParser.meta(meta)
    }
  }

}

import { useCallback, useEffect, useState } from 'react'
import { useToast } from '@bonitour/components'
import { AffiliateService } from 'services/Affiliate/Service'

export const useAffiliateOptions = (hasNullOption = true, nullOptionLabel = 'Todos') => {
  const { add: addToast } = useToast()
  const [loading, setLoading] = useState(false)
  const [affiliatesOptions, setAffiliatesOptions] = useState([])

  const fetchAffiliatesOptions = useCallback(() => {
    setLoading(true)
    return AffiliateService.options()
      .then((data) => setAffiliatesOptions([
        ...data
      ]))
      .catch(() => {
        addToast('Ocorreu um erro ao realizar a busca')
      })
      .finally(() => setLoading(false))
  // eslint-disable-next-line
  }, [])

  useEffect(() => {
    fetchAffiliatesOptions()
    // eslint-disable-next-line
  }, [])

  return {
    affiliatesOptions,
    affiliatesOptionsWithAffiliateCodeAsValue: affiliatesOptions.map(({ affiliateCode, name, id }) => ({ value: affiliateCode, label: name, id })),
    affiliatesOptionsWithIdAsValue: [
      ...(hasNullOption ? [{ value: '', label: nullOptionLabel }] : []),
      ...affiliatesOptions.map(({ name, id }) => ({ value: id, label: name }))],
    loading
  }
}

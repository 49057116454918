/* eslint-disable camelcase */
/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { LoadingAnimation, TableContainer, TableHeader, TableHeaderRow, TableHeaderCell, TableBody, TableBodyRow, TableBodyCell, InfoSwatch, TruncateCell, flexHorizontallyCentered, TooltipOnHover, CircleThumbnail, CollapsableSection, ToggleInputGroup } from '@bonitour/components'
import { marginBottom, loadingContainer, circleColor } from 'assets/styles/global'
import { AddLabel } from 'components/AddLabel'
import { useFeatureFlag } from 'contexts/Feature'
import { useTransactions } from 'domains/Reservation/Transactions/hooks/useTransactions'
import { useCallback, useMemo, useState } from 'react'
import { ActionsBodyCell } from 'containers/Reservation/Payment/Actions'
import { formatMoney, formatDate } from '@bonitour/common-functions'
import { useFinanceActions } from 'domains/Reservation/Transactions/hooks/useFinanceActions'
import { collapsableCss, tableContainer, dateHourStyle, infoSwatchContainer, paddingRight5, textOverflow, paymentMethodStyle } from 'containers/Reservation/Payment/TableRow.style'
import { useCompany } from 'contexts/Company'
import { reservationPaymentStatusMap } from 'constants/reservationPaymentStatus'
import { simplifiedFilterBar } from './Links.style'
import { reservationPaymentTypesMap } from 'constants/reservationPaymentTypes'
import { providers } from 'constants/acquirers'

const noBottom = marginBottom(0)

const PaymentLinkRow = ({ paymentLink, card, hideExpired }) => {
  const {
    state,
    date,
    hour,
    expirationDate,
    expirationHour,
    liableCompanyName,
    liableUserName,
    value,
    id,
    liableCompanyId,
    customVendorName = 'Zézim das cove',
    installments,
    aut,
    doc,
    method,
    gateway,
    reservationPaymentId
  } = paymentLink

  const status = reservationPaymentStatusMap[state]
  const isSent = state === 'sent'

  const isPaidPaymentLink = useMemo(() => state === 'paid', [state])

  const hasExpired = useMemo(() => {
    return state === 'expired'
  }, [state])

  const tooltipGatewayMessage = `Gateway: ${providers[gateway]}`

  if (hideExpired && hasExpired) return null

  return (
    <TableBodyRow>
      <TableBodyCell data-label='Data e Hora' css={paddingRight5}>
        <span css={isSent && dateHourStyle}>
          {`${formatDate(date)} - ${hour}`}
          {isSent && <InfoSwatch css={infoSwatchContainer} tooltip={`Este link irá expirar em ${formatDate(expirationDate)} ás ${expirationHour}`} size={180} />}
        </span>
      </TableBodyCell>
      <TableBodyCell data-label='Valor'>{formatMoney(value)}</TableBodyCell>
      <TableBodyCell data-label='Forma de Pagamento'>
        <span css={paymentMethodStyle}>
          {reservationPaymentTypesMap(method, installments)}
          {customVendorName
            ? (
              <InfoSwatch css={infoSwatchContainer} tooltip={
                `${tooltipGatewayMessage} \n
                 Multiponto: ${customVendorName}`
              } size={180} />
            )
            : <InfoSwatch css={infoSwatchContainer} tooltip={tooltipGatewayMessage} size={180} />
          }
        </span>
      </TableBodyCell>
      <TableBodyCell data-label='Aut/Doc'>{aut || doc ? `${aut}/${doc}` : ''}</TableBodyCell>
      <TruncateCell data-label='Registrado por' css={paddingRight5} text={`${liableCompanyName} - ${liableUserName}`} size='220px' />
      <TableBodyCell data-label='Status' css={paddingRight5}>
        <div css={flexHorizontallyCentered}>
          {Boolean(status) &&
            <TooltipOnHover text={status.label}>
              <CircleThumbnail size={10} css={circleColor(status.color)} />
              <span css={textOverflow}>{status.label}</span>
            </TooltipOnHover>}
        </div>
      </TableBodyCell>
      <ActionsBodyCell
        id={id}
        payment={paymentLink}
        isSent={isSent}
        responsiblePaymentCompanyId={liableCompanyId}
        card={card}
        isPaidPaymentLink={isPaidPaymentLink}
        reservationPaymentId={reservationPaymentId}
      />
    </TableBodyRow>
  )
}

export const PaymentLinksTable = ({
  subordinatesLoading,
  isCommercialPartner,
  card = false
}) => {
  const [isMultipontoPaymentEnabled, multiPontoVariables] = useFeatureFlag('orb-multiponto-payment')
  const { paymentLinks, loading: transactionLoading, getPaymentLinkLoading, loadingLinks } = useTransactions()
  const loading = useMemo(() => transactionLoading || getPaymentLinkLoading || loadingLinks, [transactionLoading, getPaymentLinkLoading, loadingLinks])

  const { company } = useCompany()

  const [isPixPaymentLinkEnabled] = useFeatureFlag('orb-pix-payment-link')

  const {
    onLinkCreation,
    canCreateMultiponto,
    onLinkMultipontoCreation,
    onLinkPixCreation
  } = useFinanceActions()

  const [expanded, setExpanded] = useState(true)
  const [hideExpired, setHideExpired] = useState(true)
  const toggleCollapsable = useCallback(() => setExpanded(v => !v), [])

  return (
    <CollapsableSection
      title='Links de pagamento'
      isExpanded={expanded}
      onClick={toggleCollapsable}
      css={collapsableCss(expanded)}
    >
      <div css={tableContainer}>
        <div css={simplifiedFilterBar}>
          <ToggleInputGroup checked={hideExpired} onChange={() => setHideExpired(v => !v)}>
            Ocultar expirados
          </ToggleInputGroup>
        </div>
        <TableContainer card={card}>
          <TableHeader>
            <TableHeaderRow>
              <TableHeaderCell>Data e hora</TableHeaderCell>
              <TableHeaderCell>Valor</TableHeaderCell>
              <TableHeaderCell>Forma de Pagamento</TableHeaderCell>
              <TableHeaderCell>Aut/Doc</TableHeaderCell>
              <TableHeaderCell>Registrado por</TableHeaderCell>
              <TableHeaderCell>Status</TableHeaderCell>
              <TableHeaderCell fixed>Ação</TableHeaderCell>
            </TableHeaderRow>
          </TableHeader>
          <TableBody loading={loading}>
            {paymentLinks.map(paymentLink => (
              <PaymentLinkRow
                key={paymentLink.id}
                card={card}
                paymentLink={paymentLink}
                hideExpired={hideExpired}
              />
            ))}
          </TableBody>
        </TableContainer>
      </div>
      {
        subordinatesLoading
          ? <LoadingAnimation css={loadingContainer} />
          : isCommercialPartner
            ? (
              <>
                <AddLabel onClick={onLinkCreation} css={[noBottom]}>
                  Gerar link de pagamento
                </AddLabel> <br />
                {
                  (isMultipontoPaymentEnabled && canCreateMultiponto && multiPontoVariables?.allowedCompanies?.companies?.includes(company?.id))
                    ? (
                      <>
                        <AddLabel onClick={onLinkMultipontoCreation} css={[noBottom]}>
                      Gerar link de pagamento multiponto
                        </AddLabel> <br />
                      </>
                    )
                    : null
                }

                {isPixPaymentLinkEnabled
                  ? <>
                    <AddLabel onClick={onLinkPixCreation} css={[noBottom]}>
                      Gerar link de pagamento pix
                    </AddLabel> <br />
                  </>
                  : null
                }
              </>

            )
            : null
      }
    </CollapsableSection>
  )
}

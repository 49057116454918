export const DEBIT_CARD = 'debit'
export const CASH = 'cash'
export const BANK_BILLET = 'bank_billet'
export const BANK_TRANSFER = 'bank_transfer'
export const BANK_CHECK = 'bank_check'
export const PIX = 'pix'
export const CREDIT_CARD = 'credit'

export const affiliateBatchPaymentMethodOptions = [
  { label: 'Cartão de Débito', value: DEBIT_CARD },
  { label: 'Cartão de Crédito', value: CREDIT_CARD },
  { label: 'Dinheiro', value: CASH },
  { label: 'Transferência Bancária', value: BANK_TRANSFER },
  { label: 'PIX', value: PIX },
  { label: 'Boleto', value: BANK_BILLET }
]

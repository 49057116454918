import { BREAK_POINTS, colors } from '@bonitour/components'
import { css, keyframes } from '@emotion/core'

const gradientAnimation = keyframes`
  100% {
    transform: translateX(100%);
  }
`

export const paginationContainer = css`
  display: grid;
  gap: 10px;
  grid-template-columns: repeat( auto-fit, minmax(230px, 1fr) );


  @media (max-width: ${BREAK_POINTS.tableUp}){
    justify-items: center;
    gap: 20px;
  }
`

export const loadingListContainer = css`
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(230px, 1fr) );
  gap: 10px;
  width: 100%;

  &, & > li {
    box-sizing: border-box;
  }

  li {
    content: '';
    border: 1px solid ${colors.gray7};
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    background-color: ${colors.white};
    border-radius: 10px;
    width: 230px;
    height: 235px;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(240,240,240, 0) 0,
        rgba(240,240,240, 0.25) 15%,
        rgba(240,240,240, 0.75) 80%,
        rgba(240,240,240, 0)
      );
      animation: ${gradientAnimation} 2s infinite;
      content: '';
    }
  }

  @media (max-width: ${BREAK_POINTS.tableUp}){
    justify-items: center;
    gap: 20px;
  }
`

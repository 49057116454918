import { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import { useToast } from '@bonitour/components'
import { useParamsFilter } from 'hooks/useParamsFilter'
import { CashierHandlingService } from 'core/services/CashierHandling'
import { usePermissions } from 'hooks/usePermissions'

// TODO: create crud pageHooks
export const useListCashiersHandling = () => {
  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()
  const [cashiersHandlingData, setCashiersHandlingData] = useState({ cashiersHandlings: undefined, meta: undefined })
  const [loading, setLoading] = useState(false)
  const [filters, setFilters] = useParamsFilter()
  const [pagination, setPagination] = useState({ currentPage: 1, perPage: 10 })

  const [initialFilters] = useState({
    initialDate: filters?.initialDate && moment(filters?.initialDate, 'YYYY-MM-DD'),
    finalDate: filters?.finalDate && moment(filters?.finalDate, 'YYYY-MM-DD'),
    ...filters
  })

  const handleFiltersChange = useCallback((filters) => {
    const filterData = {}

    Object.keys(filters).forEach(filterKey => {
      if (filterKey === 'initialDate' || filterKey === 'finalDate') {
        filterData[filterKey] = filters[filterKey] && moment(filters[filterKey]).format('YYYY-MM-DD')
      } else {
        filterData[filterKey] = filters[filterKey]
      }
    })

    setFilters(filterData)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePagination = useCallback((currentPage, perPage) => {
    if (currentPage === pagination.currentPage && perPage === pagination.perPage) {
      return
    }

    setPagination({ currentPage, perPage })
  }, [pagination.currentPage, pagination.perPage])

  const fetchCashiersHandling = useCallback(({ filters, pagination }) => {
    setLoading(true)

    return CashierHandlingService.list({ filters, pagination }).then((data) => setCashiersHandlingData(data))
      .catch((error) => {
        const hasPermission = handlePermission(error, 'Você não possui permissão de acesso as movimentações de caixa')
        hasPermission && addToast('Ocorreu um erro ao realizar a busca')
      })
      .finally(() => setLoading(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchCashiersHandling({
      pagination,
      filters
    })
  }, [pagination, filters, fetchCashiersHandling])

  return {
    filters,
    initialFilters,
    setFilters: handleFiltersChange,
    loading,
    cashiersHandlings: cashiersHandlingData?.cashiersHandlings,
    handlePagination,
    meta: cashiersHandlingData?.meta
  }
}

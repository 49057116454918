import { getImageUrl } from 'services/Orb/Service'
import { activityBasicInfoParser } from 'core/parsers/activityBasicInfo'
import { offlineExperienceIdParser } from 'core/parsers/offlineExperienceId'
import { parseAddress } from 'core/parsers/address'
import { phoneParser } from 'services/Activity/Parser'
import { offlineExperienceAboutParser } from 'core/parsers/offlineExperienceAboutParser'
import { offlineExperienceRulesParser } from 'core/parsers/offlineExperienceRulesParser'
import { activityComplementaryInfoParser } from 'core/parsers/ActivityComplementaryInfo'

const extractInfo = ({
  title: name,
  id,
  image,
  is_partner: isPartner,
  company: { id: companyId, name: companyName } = {},
  restrictions,
  service_time: duration,
  type,
  default_value: defaultValue,
  default_net_value: defaultNetValue
}) => ({
  name,
  id,
  image: getImageUrl(image),
  isPartner,
  companyId,
  companyName,
  minAge: restrictions?.min_age,
  duration,
  type: type || '',
  defaultValue,
  defaultNetValue
})

const offlineExperienceParser = (offlineExperience = {}) => {
  const serviceRegistry = activityBasicInfoParser(offlineExperience)
  const serviceId = offlineExperienceIdParser(offlineExperience)
  const serviceAbout = offlineExperienceAboutParser(offlineExperience)
  const serviceRules = offlineExperienceRulesParser(offlineExperience)
  const serviceComplementaryInfo = activityComplementaryInfoParser(offlineExperience)
  const addressInfo = parseAddress(offlineExperience)
  const { emails, phones } = offlineExperience.contacts
  const telephones = phones.map(phone => phoneParser(phone))

  return {
    offlineExperienceBase: {
      serviceId,
      serviceRegistry,
      addressInfo,
      emails,
      phones: telephones
    },
    offlineExperienceAbout: {
      serviceAbout,
      serviceRules
    },
    offlineExperienceComplementaryInfo: serviceComplementaryInfo
  }
}

export const OfflineExperienceParser = {
  list ({ offline_experiences = [], meta }) {
    return {
      experiences: offline_experiences.map(extractInfo),
      meta: {
        totalEntries: meta.total_entries,
        currentPage: meta.current_page,
        totalPages: meta.total_pages
      }
    }
  },
  instance ({ offline_experience = {} }) {
    return offlineExperienceParser(offline_experience)
  }
}

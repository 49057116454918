/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { TableBody, TableBodyCell, TableBodyRow, TableContainer, TableHeader, TableHeaderCell, TableHeaderRow } from '@bonitour/components'
import { formatDate, formatMoney } from '@bonitour/common-functions'
import { tableContainer } from 'assets/styles/global'

export const VouchersBatchTable = ({ vouchers = [] }) => {
  return (
    <div css={tableContainer}>
      <TableContainer>
        <TableHeader>
          <TableHeaderRow>
            <TableHeaderCell>Código</TableHeaderCell>
            <TableHeaderCell>Data da experiência</TableHeaderCell>
            <TableHeaderCell>Nome da empresa</TableHeaderCell>
            <TableHeaderCell>Experiência</TableHeaderCell>
            <TableHeaderCell>Valor a repassar</TableHeaderCell>
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {vouchers.map(({ externalCode, activityDate, partnerName, activityName, value }, index) =>
            <TableBodyRow key={index}>
              <TableBodyCell>{externalCode}</TableBodyCell>
              <TableBodyCell>{formatDate(activityDate)}</TableBodyCell>
              <TableBodyCell>{partnerName}</TableBodyCell>
              <TableBodyCell>{activityName}</TableBodyCell>
              <TableBodyCell>{formatMoney(value)}</TableBodyCell>
            </TableBodyRow>
          )}
        </TableBody>
      </TableContainer>
    </div>
  )
}

import { useEffect, useCallback, useState } from 'react'
import { FeeService } from 'services/Fee/Service'
import { OFFLINE_EXPERIENCE_TYPE } from 'constants/activityTypes'

export const useBookingTickets = (
  activityCompanyId,
  activityId,
  date,
  hour,
  addToast,
  type = ''
) => {
  const [ticketsList, setTicketsList] = useState([])

  const updateBookingTicketList = useCallback(() => {
    FeeService.getPrices(activityCompanyId, activityId, date, hour).then(tickets => {
      if (tickets && tickets.length) {
        setTicketsList(tickets)
      } else {
        addToast('Não há tarifários cadastrados para esse período')
      }
    })
  }, [activityCompanyId, activityId, date, hour, addToast])

  useEffect(() => {
    if (type === OFFLINE_EXPERIENCE_TYPE) {
      return []
    }

    updateBookingTicketList()

    return () => {
      setTicketsList([])
    }
  }, [type, updateBookingTicketList])

  return [ticketsList]
}

import { css } from '@emotion/core'
import { colors } from '@bonitour/components'
import { clickable, marginRight } from 'assets/styles/global'

const marginRight10 = marginRight(10)

export const popoverPositionStyle = css`
  width: 300px;
  padding: 20px;
  top: -265px !important;
  left: -165px !important;
`

export const canceledTicketRow = css`
  ${clickable};
`

export const lineThrough = css`
  text-decoration: line-through;
`

export const detail = css`
  display: contents;
  border-radius: 10px;
  width: 100%;

  td {
    background-color: ${colors.white} !important;
    border: solid ${colors.gray6};
    border-width: 0;    
    border-radius: 0 !important;
    
    &:first-of-type {
      border-width: 0 0 0 1px;
    }

    &:last-of-type {
      border-width: 0 1px 0 0;
    }
  }

  tr:first-of-type {
    td {
      border-width: 1px 1px 0px 1px !important;
  
      &:first-child {
        border-radius: 10px 10px 0px 0px !important;
      }
    }
  }

  tr:last-of-type {
    td {
      border-width: 0px 0px 1px 0px !important;
  
      &:first-child {
        border-width: 0px 0px 1px 1px !important;
        border-radius: 0px 0 0 10px !important;
      }
  
      &:last-child {
        border-width: 0px 1px 1px 0px !important;
        border-radius: 0 0px 10px 0 !important;
      }
    }
  }
`

export const paymentTicketsBalanceLabel = css`
  padding: 20px 0 0 20px;
  font-size: 14px;
  font-weight: bold;
  color: ${colors.gray3};
`

export const collapsableIcon = css`
  ${marginRight10};
  transition: 0.2s transform ease-in;
`

export const collapsableIconOpen = css`
  ${marginRight10};
  transform: rotate(90deg);
`

export const paymentIconRow = css`
  ${marginRight10};
  position: relative;
  font-size: 20px;
  color: ${colors.gray3};

  &::after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 2px;
    height: 10px;
    border-radius: 1px;
    background-color: ${colors.gray3};
`

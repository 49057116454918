import { useEffect, useState } from 'react'
import { AcquirerService } from 'core/services/Acquirer'

export const usePaymentProviderOptions = () => {
  const [paymentProviderOptions, setPaymentProviderOptions] = useState([])
  const [loading, setLoading] = useState({})

  useEffect(() => {
    setLoading(true)

    AcquirerService.listProviders()
      .then((res = []) => {
        setPaymentProviderOptions(
          res.map(({ name, label, id }) => ({
            value: name,
            label,
            providerId: id
          }))
        )
      })
      .finally(() => setLoading(false))
  }, [])

  return {
    paymentProviderOptions,
    loadingPaymentProviders: loading
  }
}

import { css } from '@emotion/core'
import { BREAK_POINTS, colors, scrollBar } from '@bonitour/components'
import { clickable, marginBottom, marginRight } from 'assets/styles/global'

const marginBottom5 = marginBottom(5)

export const backButton = css`
  font-size: 28px;
  ${marginRight(10)};
  color: ${colors.gray1};
  ${clickable};
`

export const navStyle = css`
  max-width: 175px;
  min-width: 175px;
  margin: 0 20px;
  padding: 20px 0;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    padding: 10px 0;
  }
`

export const activeNav = css`  
  border-bottom: 3px solid ${colors.primary};

  p {
    color: ${colors.primary};
  }
`

export const navContainer = css`
  margin-top: 10px;
  padding: 0;
  overflow-x: auto;
  overflow-y: hidden;
  ${scrollBar(5)}
`

export const tabPane = css`
  padding: 0;
  background: none;
  border: none;
`

export const activityLabel = css`
  ${marginBottom5};
  font-size: 20px;
  font-weight: bold;
  color: ${colors.gray2};
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    font-size: 14px;
  }
`

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { P, colors, flex, BREAK_POINTS } from '@bonitour/components'
import { marginLeft } from 'assets/styles/global'
import { identity } from '@bonitour/common-functions'
import { FeePaxTypeRow } from './FeePaxTypeRow'

const marginLeft20 = css`
  ${marginLeft(20)}

  @media (max-width: ${BREAK_POINTS.tablet}) {
    ${marginLeft(0)}
  }

`

const feeCategory = css`
  color: ${colors.gray3};
  border-bottom: 2px solid ${colors.gray13};
  padding-bottom: 5px;
  font-weight: bold;
`

const activeFeeCategory = css`
  color: ${colors.primary};
  border-bottom: 2px solid ${colors.primary};
  padding-bottom: 5px;
  font-weight: bold;
`

const feeTypeRowContainer = css`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: ${BREAK_POINTS.tableUp}) {
    justify-content: center;
  }
`

export const FeePaxDetails = ({
  title = '',
  isActive = false,
  feePrice = 0,
  feeType = [],
  feePaxTypes = [],
  feePaxTypesErrors = [],
  onChange = identity,
  onInputBlur = identity,
  onItemAddition = identity,
  onItemRemoval = identity
}) => {
  return (
    <div>
      <P css={[marginLeft20, isActive ? activeFeeCategory : feeCategory]}>{title}</P>
      <div css={[flex, feeTypeRowContainer]}>
        {feeType.map(({ name, feeModificator, minAge, maxAge, tooltip }) => (
          <FeePaxTypeRow
            key={name}
            feePrice={feePrice}
            feePaxTypes={feePaxTypes}
            feePaxTypeErrors={feePaxTypesErrors}
            indexName={name}
            feeModificator={feeModificator}
            minAge={minAge}
            maxAge={maxAge}
            tooltip={tooltip}
            onChange={onChange}
            onInputBlur={onInputBlur}
            onDelete={onItemRemoval}
            onItemAddition={onItemAddition}
          />
        ))}
      </div>
    </div>
  )
}

import { SellersService } from 'services/Sellers/Service'
import { VendorsService } from 'services/Vendors/Service'
import { useToast } from '@bonitour/components'

import { signal, batch } from '@preact/signals-core'
import { useEffect } from 'react'

const parseData = (data) => {
  const parsedData = data.map((item) => {
    return {
      value: item.id,
      label: item.name
    }
  })

  return parsedData
}

export const sellers = signal([])
export const vendors = signal([])

const isLoadingSellers = signal(false)
const isLoadingVendors = signal(false)

export const useMyDay = () => {
  const { add: toast } = useToast()

  const getSellers = () => {
    batch(() => {
      isLoadingSellers.value = true
      SellersService.getList()
        .then((data) => (sellers.value = parseData(data)))
        .catch(() => toast('Erro inesperado ao listar os vendedores'))
        .finally(() => (isLoadingSellers.value = false))
    })
  }

  const getVendors = () => {
    batch(() => {
      isLoadingVendors.value = true
      VendorsService.getList()
        .then((data) => (vendors.value = parseData(data)))
        .catch(() => toast('Erro inesperado ao listar os fornecedores'))
        .finally(() => (isLoadingVendors.value = false))
    })
  }

  useEffect(() => {
    getSellers()
    getVendors()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { isLoadingSellers, isLoadingVendors }
}

import React, { useEffect, useMemo, useState } from 'react'
import { head, tail } from '@bonitour/common-functions'
import { TermsDialog, useToast } from '@bonitour/components'
import { useTerms } from 'hooks/useTerms'
import { UserStore } from 'services/User/Store'
import { useUser } from 'contexts/User'
import dayjs from 'dayjs'

import 'dayjs/locale/pt-br'

dayjs.locale('pt-BR')

export const TermsHandler = () => {
  const user = UserStore.user
  const [isConfirmActionVisible, setConfirmActionVisible] = useState(false)
  const [userTerms, setUserTerms] = useState([])
  const { term: companyTerm, acceptTerm } = useTerms('company_terms_of_use')
  const { term: privacyPolicy } = useTerms('privacy_policy')
  const { add: addToast } = useToast()
  const { updateUser } = useUser()

  const getCompanyAndPrivacyTerms = () => {
    const terms = Object.entries(user?.legal_agreements || {}).filter(legalAgreement =>
      (head(legalAgreement) === 'company_terms_of_use' && tail(legalAgreement) !== true) ||
      (head(legalAgreement) === 'privacy_policy' && tail(legalAgreement) !== true)
    )
    setUserTerms(terms)
  }

  const onSuccess = (isCompanyTerm, isPrivacyPolicy) => {
    Promise.all([isCompanyTerm && acceptTerm(companyTerm?.id), isPrivacyPolicy && acceptTerm(privacyPolicy?.id)])
      .then((userData) => {
        updateUser({ ...user, legalAgreements: head(userData)?.user?.legalAgreements })
        addToast('Termos aceitos com sucesso', 'success')
      }).catch((err) => {
        if (
          err?.data?.errors_msg.some(msg =>
            msg.includes('::legal_agreement_id::taken')
          )
        ) {
          // Terms already accepted but cookie is outdated
          updateUser({
            ...user,
            legal_agreements: {
              ...user.legal_agreements,
              company_terms_of_use: user.legal_agreements.company_terms_of_use || isCompanyTerm,
              privacy_policy: user.legal_agreements.privacy_policy || isPrivacyPolicy
            }
          })
          addToast('Termos aceitos com sucesso', 'success')
        } else {
          addToast('Ocorreu um erro ao aceitar os termos', 'error')
        }
      })
    setConfirmActionVisible(false)
  }

  useEffect(() => {
    if (
      user?.legal_agreements?.privacy_policy === false ||
      user?.legal_agreements?.company_terms_of_use === false
    ) {
      setConfirmActionVisible(true)
    }
    getCompanyAndPrivacyTerms()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const lastUpdate = useMemo(
    () => dayjs(companyTerm?.updatedAt).isAfter(dayjs(privacyPolicy?.updatedAt))
      ? companyTerm?.updatedAt
      : privacyPolicy?.updatedAt,
    [privacyPolicy?.updatedAt, companyTerm?.updatedAt]
  )

  return (
    <TermsDialog
      isVisible={isConfirmActionVisible}
      successCallback={onSuccess}
      lastUpdate={dayjs(lastUpdate).format('DD [de] MMMM [de] YYYY')}
      companyTermSource={`${process.env.REACT_APP_ACCOUNTS_URL}/termo-de-uso`}
      privacyPolicySource={`${process.env.REACT_APP_ACCOUNTS_URL}/politica-de-privacidade`}
      terms={userTerms}
    />
  )
}

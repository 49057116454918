/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import { Button, Column, ConfirmDialog, flexRow, GhostButton, H1, Row, useToast } from '@bonitour/components'
import { NoticeForm } from '../Form/NoticeForm'
import { NoticePreview } from '../Preview/NoticePreview'
import { margin, marginLeft, marginRight } from 'assets/styles/global'
import { noticeSchema } from '../Form/Notice.schema'
import { useForm } from '@bonitour/app-functions'
import { useState, useContext } from 'react'
import { ActivityContext } from 'contexts/Activity'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { CommuniquesNotificationsService } from 'services/Communiques/Notifications/Service'
import { useCategories } from '../hooks/useCategories'

const marginLeft10 = marginLeft(10)
const marginRight20 = marginRight(20)
const marginButtons = margin(30, 10, 30, 10)

export const CreateNotices = () => {
  const { add: addToast } = useToast()
  const { push } = useHistory()
  const [initialState] = useState({ date: moment().toDate() })

  const {
    form,
    errors,
    onSubmit,
    utils: { onInputChange, onInputBlur }
  } = useForm(initialState, noticeSchema)

  const history = useHistory()
  const { unparsedActivities, companiesOptions } = useContext(ActivityContext)
  const [isVisible, setVisibility] = useState(false)

  const toggleHidden = () => setVisibility(false)
  const toggleVisible = () => setVisibility(true)

  const onValidationError = () => addToast('Preencha corretamente o formulário')

  const onSuccess = () => {
    toggleHidden()
    CommuniquesNotificationsService.create(form)
      .then(() => {
        addToast('Comunicado enviado com sucesso', 'success')
        history.push('/notices')
      })
      .catch(() => {
        addToast('Erro ao enviar comunicado')
      })
  }

  const backToNotices = () => push('/notices')

  const { categories } = useCategories()

  const sendNotice = onSubmit(toggleVisible, onValidationError)

  const [activitiesWithVacancies, setActivitiesWithVacancies] = useState([])

  return (
    <>
      <H1 css={marginLeft10}>Novo comunicado</H1>

      <Row>
        <Column desktop={6} phone={12}>
          <NoticeForm
            notice={form}
            noticeErrors={errors}
            categories={categories}
            onChange={onInputChange}
            onBlur={onInputBlur}
            companiesOptions={companiesOptions}
            setActivitiesWithVacancies={setActivitiesWithVacancies}
          />
        </Column>
        <Column desktop={6} phone={12}>
          <NoticePreview
            notice={form}
            activities={unparsedActivities}
            categories={categories}
            activitiesWithVacancies={activitiesWithVacancies}
          />
        </Column>
      </Row>

      <div css={[marginButtons, flexRow]}>
        <GhostButton onClick={backToNotices} css={marginRight20}>
          Cancelar
        </GhostButton>
        <Button type="submit" onClick={sendNotice}>
          Enviar
        </Button>
      </div>

      <ConfirmDialog
        title="Confirmar envio"
        message="Ao confirmar o envio, todos os viajantes com ingressos confirmados nessa experiência selecionada serão notificados via aplicativo. Deseja continuar?"
        buttonLabelConfirm="Enviar"
        buttonLabelCancel="Cancelar"
        isVisible={isVisible}
        successCallback={onSuccess}
        cancelCallback={toggleHidden}
      />
    </>
  )
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { colors } from '@bonitour/components'
import { pulse } from 'assets/animations/pulse'
import { slideRight } from 'assets/animations/slide'
import { CSSTransition } from 'react-transition-group'

const card = css`
  background-color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 43px;
  height: 56px;
  margin: 5px;
  border-radius: 7px;
  box-shadow: 0px 1px 5px #00000014;
  padding: 0 10px;
  box-sizing: border-box;


  .card__value {
    font-size: 15px;
    font-weight: 500;
    color: ${colors.gray4};
    width: 100%;
    box-sizing: border-box;
    text-align: center;
  }
`

const disabledCard = css`
  opacity: 0.5;
  pointer-events: none;
  cursor: block;
`

const cardAnimation = css`
  animation: ${pulse} 0.2s linear;
`

const slideRightValue = css`
  animation: ${slideRight} 1s ease;
`

export const BookingMapCard = ({ children, disabled: isDisabled = false, ...other }) => {
  return (
    <div
      css={[card, cardAnimation, isDisabled && disabledCard]}
      {...other}
    >
      <CSSTransition key={children} timeout={0}>
        <div css={slideRightValue} className='card__value'>
          {children}
        </div>
      </CSSTransition>
    </div>
  )
}

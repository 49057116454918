import { css } from '@emotion/core'
import { BREAK_POINTS, colors, flexColumn } from '@bonitour/components'

export const floating = css`
  align-items: flex-end;
  margin-bottom: 30px;
  position: fixed;
  bottom: 40px;
  right: 60px;

  @media (max-width: ${BREAK_POINTS.tablet}){
    position: fixed;
    bottom: 0;
    right: 30px;
  }
`

export const buttonContainer = css`
  margin-bottom: 10px;
  margin-right: 5px;
`

export const fontSize20 = css`
  font-size: 20px;
`

export const tagStyle = css`
  background-color: ${colors.gray6};
  color: ${colors.white};
  padding: 8px;
  margin-right: 10px;
  font-size: 14px;
`

export const popoverContainer = css`
  ${flexColumn};
  align-items: flex-start;
  padding: 30px 20px;
  background-color: ${colors.white};
  overflow-y: auto;
`

export const invitePartnerContainer = css`
  width: 430px;
`

export const closeButton = css`
  background-color: ${colors.gray6};
  color: ${colors.white};
`

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Card, flexHorizontallyCentered, flexColumn, colors } from '@bonitour/components'
import { marginRight } from 'assets/styles/global'

const marginRight20 = marginRight(20)

const padding20 = css`
  padding: 20px;
`

const label = css`
  font-size: 12px;
  color: ${colors.gray5};
  margin-bottom: 5px;
`

const info = css`
  font-size: 24px;
  color: ${colors.gray4};
  font-weight: bold;
`

export const BankInfo = ({ bankInfo = {} }) => {
  const { bankName = '', agencyNumber = 0, accountNumber = '', registerNumber = '' } = bankInfo

  return (
    <Card css={[padding20, flexHorizontallyCentered]}>
      <div css={[flexColumn, marginRight20]}>
        <p css={label}>Banco</p>
        <span css={info}>{bankName}</span>
      </div>
      <div css={[flexColumn, marginRight20]}>
        <p css={label}>Agência</p>
        <span css={info}>{agencyNumber}</span>
      </div>
      <div css={[flexColumn, marginRight20]}>
        <p css={label}>Conta</p>
        <span css={info}>{accountNumber}</span>
      </div>
      <div css={flexColumn}>
        <p css={label}>CNPJ</p>
        <span css={info}>{registerNumber}</span>
      </div>
    </Card>
  )
}

import { identity } from '@bonitour/common-functions'
import { useCallback, useState } from 'react'
import { InvoiceService } from 'core/services/Invoice'
import { useToast } from '@bonitour/components'

export const useInvoicePayment = (invoiceId = '', updateInvoice = identity) => {
  const { add: addToast } = useToast()
  const [isPaymentVisible, setPaymentVisible] = useState(false)

  const openPayment = useCallback(() => setPaymentVisible(true), [])
  const closePayment = useCallback(() => setPaymentVisible(false), [])

  const [isInvoicePaymentVisible, setInvoicePaymentVisible] = useState(false)
  const [isInvoiceEditVisible, setInvoiceEditPaymentVisible] = useState(false)
  const [invoicePayment, setInvoicePayment] = useState(false)
  const closeInvoicePayment = useCallback(() => setInvoicePaymentVisible(false), [])
  const closeInvoiceEditPayment = useCallback(() => setInvoiceEditPaymentVisible(false), [])
  const [isLoading, setIsLoading] = useState(false)

  const executeModalFunction = useCallback(async (modalFunction = identity, successMessage = '', errorMessage = '', endingCallback = identity) => {
    try {
      setIsLoading(true)
      await modalFunction()
      addToast(successMessage, 'success')
      updateInvoice()
    } catch (error) {
      addToast(errorMessage)
      if (error.parsedErrors) {
        error.parsedErrors.map(errorMessage => addToast(errorMessage, 'error', 10000))
      }
    }
    setIsLoading(false)
    endingCallback()
  }, [addToast, updateInvoice])

  const payInvoice = useCallback(async (payload) => executeModalFunction(
    () => InvoiceService.payInvoice(invoiceId, payload),
    'Pagamento adicionado com sucesso',
    'Ocorreu um erro ao enviar a fatura',
    closePayment
  ), [invoiceId, executeModalFunction, closePayment])

  const approvePayment = useCallback(async (invoicePaymentId) => executeModalFunction(
    () => InvoiceService.approvePayment(invoiceId, invoicePaymentId),
    'Pagamento aprovado com sucesso',
    'Ocorreu um erro ao aprovar o pagamento',
    closeInvoicePayment
  ), [invoiceId, executeModalFunction, closeInvoicePayment])

  const rejectPayment = useCallback(async (invoicePaymentId) => executeModalFunction(
    () => InvoiceService.rejectPayment(invoiceId, invoicePaymentId),
    'Pagamento recusado com sucesso',
    'Ocorreu um erro ao recusar o pagamento',
    closeInvoicePayment
  ), [invoiceId, executeModalFunction, closeInvoicePayment])

  const editInvoicePayment = useCallback(async (invoicePaymentId, invoiceEditinfo) => executeModalFunction(
    () => InvoiceService.editPayment(invoiceId, invoicePaymentId, invoiceEditinfo),
    'Pagamento editado com sucesso',
    'Ocorreu um erro ao editar o pagamento',
    closeInvoiceEditPayment
  ), [executeModalFunction, closeInvoiceEditPayment, invoiceId])

  const openInvoicePay = useCallback((payment) => {
    setInvoicePaymentVisible(true)
    setInvoicePayment(payment)
  }, [])

  const openInvoiceEditPay = useCallback((payment) => {
    setInvoiceEditPaymentVisible(true)
    setInvoicePayment(payment)
  }, [])

  return {
    isPaymentVisible,
    isInvoicePaymentVisible,
    isInvoiceEditVisible,
    invoicePayment,
    openInvoicePay,
    openInvoiceEditPay,
    approvePayment,
    rejectPayment,
    editInvoicePayment,
    closeInvoicePayment,
    closeInvoiceEditPayment,
    openPayment,
    closePayment,
    payInvoice,
    isLoading
  }
}

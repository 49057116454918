import { head, tail } from '@bonitour/common-functions'
import { banks } from 'utils/banks'
import { MetaParser } from './Meta'
import { bankAccountPaymentMethods, apiPaymentMethods } from 'constants/paymentMethods'
import { ACCOUNT_TYPES } from '../../domains/BankAccounts/constants/bankAccountConstants'

export const BankAccountParser = {
  single: ({ bank_account: bankAccount }) => {
    const {
      id,
      bank_number: bankCode,
      bank_name: accountName,
      bank_agency_number: agencyNumber,
      bank_agency_vd: agencyDigit,
      bank_account_number: accountNumber,
      bank_account_vd: accountDigit,
      bank_account_type: accountType,
      enabled,
      payment_methods: paymentMethods = {}
    } = bankAccount || {}

    const { label } = banks.find(bank => Number(bank.value) === Number(bankCode)) || {}

    const fees = (function () {
      const isCompanyCashier = accountType === ACCOUNT_TYPES.COMPANY_CASHIER
      const entries = Object.entries(paymentMethods).map(item => {
        return {
          paymentMethod: head(item),
          value: tail(item)
        }
      })
      const allEntries = bankAccountPaymentMethods.map(paymentMethod => {
        const entry = entries.find(item => item.paymentMethod === paymentMethod)
        const isCash = paymentMethod === apiPaymentMethods.cash
        if (isCompanyCashier && !isCash) return undefined
        return entry || { paymentMethod, value: 0 }
      }).filter(Boolean)
      return allEntries
    }())

    return {
      id,
      accountName,
      bankCode,
      bankName: label,
      agencyNumber,
      agencyDigit,
      accountNumber,
      accountDigit,
      accountType,
      enabled,
      fees
    }
  },
  list: ({ meta, bank_accounts: bankAccounts = [] }) => {
    return {
      bankAccounts: bankAccounts.map(bankAccount =>
        BankAccountParser.single({ bank_account: bankAccount })
      ),
      meta: MetaParser.meta(meta)
    }
  },
  options: ({ bank_accounts: bankAccounts = [] }) => {
    return bankAccounts.map(({ id, bank_name: accountName }) => {
      return { value: id, label: accountName }
    })
  }
}

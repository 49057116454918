import { colors, BREAK_POINTS } from '@bonitour/components'

import { css } from '@emotion/core'

export const iconSize = css`
font-size: 23px;
`

export const nameInputWidth = css`
width: 180px;
`

export const mailInputWidth = css`
width: 350px;

@media (max-width: ${BREAK_POINTS.bigPhone}) {
  width: 100%;
}
`

export const sameLineInput = css`
margin: 0 10px;

@media (max-width: ${BREAK_POINTS.tablet}) {
  margin: 7px 0;
}
`

export const customCardPadding = css`
padding: 10px 20px;
`

export const stylishCard = css`
border: none;
color: ${colors.gray3};

&:nth-of-type(odd) {
  background-color: ${colors.gray11};
}

@media (max-width: ${BREAK_POINTS.tableUp}) {
  position: relative;
}
`

export const fieldSection = css`
display: flex;
align-items: center;
@media (max-width: ${BREAK_POINTS.tablet}) {
  flex-direction: column;
}
`

export const fieldContainer = css`
 @media (max-width: ${BREAK_POINTS.tablet}) {
  flex-direction: column;
}
`

import { useCallback, useEffect, useMemo, useState } from 'react'
import { AffiliateService } from 'services/Affiliate/Service'
import { useToast } from '@bonitour/components'
import { useDebounce } from '@bonitour/app-functions'

const { paginatedList } = AffiliateService

const DEFAULT_PAGINATION = {
  perPage: 25,
  page: 1,
  totalEntries: 0,
  totalPages: 0
}

const DEFAULT_FILTERS = { status: 'active' }

const INITIAL_LOADING_STATE = {
  isFirst: false,
  isMore: false
}

export const useAffiliateList = () => {
  const [isLoading, setIsLoading] = useState(INITIAL_LOADING_STATE)
  const [filters, setFilters] = useState(DEFAULT_FILTERS)
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION)
  const [affiliates, setAffiliates] = useState([])

  const { add: addToast } = useToast()

  const isFetchingData = useMemo(() => isLoading?.isFirst || isLoading?.isMore, [isLoading])

  const onErrorCallback = useCallback(() => {
    addToast('Houve um erro inesperado')
  }, [addToast])

  const onFinallyCallback = useCallback(() => {
    setIsLoading(INITIAL_LOADING_STATE)
  }, [])

  const onSuccessCallback = useCallback((canConcatResult = false) => ({ affiliates, meta }) => {
    setAffiliates(previous => [...(canConcatResult ? previous : []), ...affiliates])
    setPagination(previous => ({
      ...previous,
      page: meta.page,
      totalPages: meta.totalPages,
      totalEntries: meta.totalEntries
    }))
  }, [])

  const fetchMoreAffiliates = useCallback(() => {
    const noHaveMorePages = pagination.page >= pagination.totalPages
    if (noHaveMorePages || isFetchingData) return

    return paginatedList({
      filters,
      pagination: {
        ...pagination,
        page: pagination.page + 1
      }
    }).then(onSuccessCallback(true))
      .catch(onErrorCallback)
      .finally(onFinallyCallback)
  }, [pagination, isFetchingData, filters, onSuccessCallback, onErrorCallback, onFinallyCallback])

  const fetchAffiliates = useCallback((newFilters = null) => {
    if (isFetchingData) return
    setIsLoading({ isFirst: true, isMore: false })
    return paginatedList({
      filters: newFilters || filters, pagination: newFilters ? DEFAULT_PAGINATION : pagination
    }).then(onSuccessCallback())
      .catch(onErrorCallback)
      .finally(onFinallyCallback)
  }, [isFetchingData, filters, pagination, onSuccessCallback, onErrorCallback, onFinallyCallback])

  const handleFilters = useCallback((newFilters) => {
    setFilters(newFilters)
  }, [])

  const resetData = useCallback(() => {
    setPagination(DEFAULT_PAGINATION)
    setFilters(DEFAULT_FILTERS)
  }, [])

  const filtersWithDebounce = useDebounce(filters, 400)

  useEffect(() => {
    if (!filtersWithDebounce) return
    fetchAffiliates(filters)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersWithDebounce])

  return {
    isFirstLoad: isLoading.isFirst,
    isLoading: isFetchingData,
    fetchAffiliates,
    fetchMoreAffiliates,
    handleFilters,
    pagination,
    affiliates,
    currentFilters: filters,
    resetData
  }
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { H3, Label, Input, Upload, flexColumn, InputFormGroup, UploadFootnote, Select, Row, Column, InputMoneyMask, BREAK_POINTS } from '@bonitour/components'
import { marginBottom, marginTop } from 'assets/styles/global'
import { identity } from '@bonitour/common-functions'

import { offlineExperienceCategoriesOptions } from 'constants/offlineExperiences'
import { useExternalProviderOptions } from 'domains/ExternalProviders/hooks/useExternalProviderOptions'

const marginBottom20 = marginBottom(20)
const marginTop50 = marginTop(50)

const width360 = css`
  width: 360px;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    width: 100%;
  }
`
const rowContainer = css`
  > div:first-of-type {
    padding-left: 0;
  }

  @media (max-width: ${BREAK_POINTS.desktop}) {
    > div {
      padding-left: 0;
    }
  }
`

export const OfflineExperienceId = ({
  serviceId = {},
  serviceIdErrors = {},
  onInputBlur: emitInputBlur = identity,
  onChange: emitChangeEvent = identity,
  onError: emitErrorEvent = identity,
  isEditing = false
}) => {
  const { files = [], name = '', offlineProviderId = '', category = '', defaultValue = '', defaultNetValue = '' } = serviceId
  const { externalProvidersOptions } = useExternalProviderOptions()

  return (
    <>
      <H3 css={[marginBottom20]}>Identificação</H3>
      <Row css={rowContainer}>
        <Column phone={12} desktop={4}>
          <InputFormGroup
            css={marginBottom20}
            label='Nome da experiência offline'
            errorMessage={serviceIdErrors.name}
            id='serviceId.name'
            required
          >
            <Input
              css={width360}
              id='serviceId.name'
              maxLength='50'
              value={name}
              onChange={emitChangeEvent('serviceId.name')}
              onBlur={emitInputBlur('serviceId.name')}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={4}>
          <InputFormGroup css={marginBottom20} label='Categoria' errorMessage={serviceIdErrors.category}>
            <Select
              options={offlineExperienceCategoriesOptions}
              value={category}
              error={serviceIdErrors.category}
              onChange={emitChangeEvent('serviceId.category')}
              disabled={isEditing}
            />
          </InputFormGroup>
        </Column>

        <Column phone={12} desktop={4}>
          <InputFormGroup css={marginBottom20} label='Fornecedor externo' errorMessage={serviceIdErrors.offlineProviderId}>
            <Select
              options={externalProvidersOptions}
              value={offlineProviderId || ''}
              error={serviceIdErrors.offlineProviderId}
              onChange={emitChangeEvent('serviceId.offlineProviderId')}
              disabled={isEditing}
            />
          </InputFormGroup>
        </Column>
      </Row>

      <div css={flexColumn}>
        <Label>Imagem de perfil</Label>
        <Upload
          files={files}
          onChange={emitChangeEvent('serviceId.files')}
          onError={emitErrorEvent}
          limitMbSize={1}
          emptyMessage='Adicione uma foto com no máximo 1Mb'
          accept='image/png, image/jpeg'
        />
        <UploadFootnote
          typeList={['.jpg', '.png']}
          extraMessage='Proporção sugerida: 200x200 pixels'
        />
      </div>

      <H3 css={[marginBottom20, marginTop50]}>Valores</H3>
      <Row css={rowContainer}>
        <Column phone={12} desktop={4}>
          <InputFormGroup
            css={marginBottom20}
            label='Valor para o turista'
            errorMessage={serviceIdErrors.defaultValue}
            id='serviceId.defaultValue'
          >
            <InputMoneyMask
              id='serviceId.defaultValue'
              maxLength='50'
              value={defaultValue}
              onChange={emitChangeEvent('serviceId.defaultValue')}
              onBlur={emitInputBlur('serviceId.defaultValue')}
            />
          </InputFormGroup>
        </Column>

        <Column phone={12} desktop={4}>
          <InputFormGroup
            css={marginBottom20}
            label='Valor net'
            errorMessage={serviceIdErrors.defaultNetValue}
            id='serviceId.defaultNetValue'
          >
            <InputMoneyMask
              id='serviceId.defaultNetValue'
              maxLength='50'
              value={defaultNetValue}
              onChange={emitChangeEvent('serviceId.defaultNetValue')}
              onBlur={emitInputBlur('serviceId.defaultNetValue')}
            />
          </InputFormGroup>
        </Column>
      </Row>
    </>
  )
}

import { useState, useCallback, useEffect, useMemo } from 'react'
import { NotificationService } from 'core/services/Notification'
import { useInterval } from 'hooks/useInterval'

export const useNotification = () => {
  const [notifications, setNotifications] = useState([])
  const [meta, setMeta] = useState([])
  const [pagination, setPagination] = useState({ page: 1, perPage: 10 })

  const changePagination = useCallback((page, perPage) => {
    setPagination({ page, perPage })
    // eslint-disable-next-line
  }, [])

  const getNotifications = useCallback((page = 1, perPage = 10) => NotificationService.list(perPage, page).then(({ meta = {}, notifications = [] }) => {
    setMeta(meta)
    setNotifications(notifications)
  }), [])

  useEffect(() => {
    getNotifications(pagination.page, pagination.perPage)
  }, [getNotifications, pagination])

  const markAsRead = useCallback(async (notificationId) => {
    await NotificationService.markAsRead(notificationId)
    getNotifications(pagination.page, pagination.perPage)
  }, [getNotifications, pagination])

  const readNotifications = useMemo(() => notifications.filter(({ isRead }) => isRead), [notifications])
  const notReadNotifications = useMemo(() => notifications.filter(({ isRead }) => !isRead), [notifications])

  const getPagedNotifications = () => getNotifications(pagination.page, pagination.perPage)

  useInterval(getPagedNotifications, 120000)

  return {
    meta,
    readNotifications,
    notReadNotifications,
    notifications,
    getNotifications,
    markAsRead,
    changePagination
  }
}

import { AuthStore } from './Store'
import { orbCore } from 'services/Orb/Service'
const { REACT_APP_ACCOUNTS_URL } = process.env

export const AuthService = {
  async logout () {
    try {
      await orbCore.logoutDomain.logout()
      this.cleanup()
      this.redirectToLogin()
    } catch (error) {}
  },
  cleanup () {
    localStorage.clear()
    AuthStore.clean()
  },
  redirectToLogin () {
    window.location = `${REACT_APP_ACCOUNTS_URL}/auth/login?redirectUrl=${decodeURIComponent(window.location.origin)}`
  }
}

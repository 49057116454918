import { array, string } from 'yup'
import { guideSchema } from './Guide.schema'
import { PAYMENT_METHODS_REQUIRED_BANK_ACCOUNT } from 'constants/paymentBatch'

export const paymentBatchSchema = {
  reference: string().required('Campo obrigatório'),
  method: string().required('A forma de pagamento é obrigatória'),
  originBankAccountId: string().nullable().test('originBankAccountId', 'A conta de origem é obrigatória', function (value) {
    if (PAYMENT_METHODS_REQUIRED_BANK_ACCOUNT.includes(this.parent.method) && !value) {
      return false
    }
    return true
  }),
  observation: string().nullable(),
  attachments: array(),
  vouchers: array(),
  ...guideSchema
}

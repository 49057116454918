import { orbCore } from 'services/Orb/Service'
import { createVoucherModel } from 'core/models/voucherUnico'
import { convertToFormData, head } from '@bonitour/common-functions'

const { voucherTurisDomain, voucherTurisCancelDomain } = orbCore

export const VoucherTurisService = {
  createVoucher (ticketsIds) {
    const payload = createVoucherModel({ ticketsIds })
    return voucherTurisDomain.createVoucher(convertToFormData(payload))
  },
  cancelVoucher (ticketsIds) {
    const payload = head(ticketsIds)
    return voucherTurisCancelDomain.cancelVoucher(payload)
  }
}

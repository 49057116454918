/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import {
  Row,
  Input,
  InputFormGroup,
  Column,
  InputTimeMask
} from '@bonitour/components'
import { rowStyle } from '..//OfflineExperiencesTicketForm.style'
import { AgentInput, CountInputFormGroup, NetPriceInputFormGroup, ObservationRowInput, PriceInputFormGroup, SlotInputFormGroup, UnitNetPriceInputFormGroup, UnitTotalPriceInputFormGroup } from './CommonFormFields'

export const TransportForm = ({
  isEditing = false,
  form,
  errors,
  onInputChange = identity,
  onInputBlur = identity,
  disableTotalPrice = false,
  disabled = false
}) => {
  return (
    <>
      {!isEditing && !disabled
        ? <AgentInput errors={errors} form={form} onInputChange={onInputChange} onInputBlur={onInputBlur} />
        : null}

      <Row css={rowStyle}>
        <SlotInputFormGroup
          form={form}
          errors={errors}
          onInputChange={onInputChange}
          onInputBlur={onInputBlur}
          desktop={3}
          phone={12}
          disabled={disabled}
        />
        <Column desktop={3} phone={12}>
          <InputFormGroup
            errorMessage={errors.checkoutTime}
            label='Horário de chegada'
            className='time__input'
          >
            <InputTimeMask
              value={form.checkoutTime}
              onChange={onInputChange('checkoutTime')}
              onBlur={onInputBlur('checkoutTime')}
              disabled={disabled}/>
          </InputFormGroup>
        </Column>
        <Column desktop={3} phone={12}>
          <InputFormGroup
            errorMessage={errors.externalReservationCode}
            label='Código externo'
            className='text__input'
          >
            <Input
              onChange={onInputChange('externalReservationCode')}
              onBlur={onInputBlur('externalReservationCode')}
              value={form.externalReservationCode}
              placeholder='Digite o código externo'
              disabled={disabled}
            />
          </InputFormGroup>
        </Column>
      </Row>
      <Row css={rowStyle}>
        <Column desktop={6} phone={12}>
          <InputFormGroup
            errorMessage={errors.pickupAddress}
            label='Endereço de saída'
          >
            <Input
              value={form.pickupAddress}
              onChange={onInputChange('pickupAddress')}
              onBlur={onInputBlur('pickupAddress')}
              placeholder='Digite o endereço de saída'
              disabled={disabled}
            />
          </InputFormGroup>
        </Column>
        <Column desktop={6} phone={12}>
          <InputFormGroup
            errorMessage={errors.dropoffAddress}
            label='Endereço de chegada'
          >
            <Input
              value={form.dropoffAddress}
              onChange={onInputChange('dropoffAddress')}
              onBlur={onInputBlur('dropoffAddress')}
              placeholder='Digite o endereço de chegada'
              disabled={disabled}
            />
          </InputFormGroup>
        </Column>
      </Row>
      <Row css={rowStyle}>
        {!isEditing && (
          <Column desktop={4} phone={12}>
            <CountInputFormGroup
              form={form}
              errors={errors}
              onInputChange={onInputChange}
              onInputBlur={onInputBlur}
              disabled={disabled}
            />
          </Column>
        )}
        {!isEditing && !disabled && (
          <>
            <Column desktop={4} phone={12}>
              <UnitTotalPriceInputFormGroup
                form={form}
                onChange={onInputChange}
                errors={errors}
              />
            </Column>
            <Column desktop={4} phone={12}>
              <UnitNetPriceInputFormGroup
                form={form}
                onChange={onInputChange}
                errors={errors}
              />
            </Column>
          </>
        )}
        <Column desktop={4} phone={12}>
          <PriceInputFormGroup
            disabled={disableTotalPrice || disabled}
            errors={errors}
            form={form}
            onInputChange={onInputChange}
            onInputBlur={onInputBlur}
            isEditing={isEditing}
          />
        </Column>
        <Column desktop={4} phone={12}>
          <NetPriceInputFormGroup
            disabled={disabled}
            errors={errors}
            form={form}
            onInputChange={onInputChange}
            onInputBlur={onInputBlur}
            isEditing={isEditing}
          />
        </Column>
      </Row>
      <ObservationRowInput
        errors={errors}
        form={form}
        onInputChange={onInputChange}
        onInputBlur={onInputBlur}
        disabled={disabled}
      />
    </>)
}

/* eslint-disable camelcase */
/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { LoadingAnimation, TableContainer, TableHeader, TableHeaderRow, TableHeaderCell, TableBody, TableBodyRow, TableBodyCell, TruncateCell, flexHorizontallyCentered, TooltipOnHover, CircleThumbnail, CollapsableSection } from '@bonitour/components'
import { marginBottom, loadingContainer, circleColor, hidden } from 'assets/styles/global'
import { AddLabel } from 'components/AddLabel'
import { useTransactions } from 'domains/Reservation/Transactions/hooks/useTransactions'
import { useCallback, useMemo, useState } from 'react'
import { ActionsBodyCell } from 'containers/Reservation/Payment/Actions'
import { formatMoney, formatDate } from '@bonitour/common-functions'
import { useFinanceActions } from 'domains/Reservation/Transactions/hooks/useFinanceActions'
import { collapsableCss, tableContainer, paddingRight5, textOverflow } from 'containers/Reservation/Payment/TableRow.style'
import { reservationPaymentStatusMap } from 'constants/reservationPaymentStatus'
import { reservationPaymentTypesMap } from 'constants/reservationPaymentTypes'

const noBottom = marginBottom(0)

const RefundRow = ({ refund, card }) => {
  const {
    date,
    hour,
    aut,
    doc,
    liableUserName,
    value,
    id,
    liableCompanyId,
    method,
    installments
  } = refund

  const status = reservationPaymentStatusMap.refunded

  return (
    <TableBodyRow>
      <TableBodyCell data-label='Data e Hora' css={paddingRight5}>
        <span>
          {`${formatDate(date)} - ${hour}`}
        </span>
      </TableBodyCell>
      <TableBodyCell data-label='Valor'>{formatMoney(value)}</TableBodyCell>
      <TableBodyCell data-label='Forma de Pagamento'>
        {reservationPaymentTypesMap(method, installments)}
      </TableBodyCell>
      <TableBodyCell data-label='Aut/Doc' css={paddingRight5}>{aut}/{doc}</TableBodyCell>
      <TruncateCell data-label='Registrado por' css={paddingRight5} text={liableUserName} size='100px' />
      <TableBodyCell data-label='Status' css={paddingRight5}>
        <div css={flexHorizontallyCentered}>
          {Boolean(status) &&
            <TooltipOnHover text={status.label}>
              <CircleThumbnail size={10} css={circleColor(status.color)} />
              <span css={textOverflow}>{status.label}</span>
            </TooltipOnHover>}
        </div>
      </TableBodyCell>
      <ActionsBodyCell
        id={id}
        payment={refund}
        responsiblePaymentCompanyId={liableCompanyId}
        card={card}
        isRefund
        isPaidPaymentLink
      />
    </TableBodyRow>
  )
}

export const RefundsTable = ({
  subordinatesLoading,
  isCommercialPartner,
  card = false
}) => {
  const { refunds, loading: transactionLoading, getPaymentLinkLoading, loadingRefunds } = useTransactions()
  const loading = useMemo(() => transactionLoading || getPaymentLinkLoading || loadingRefunds, [transactionLoading, getPaymentLinkLoading, loadingRefunds])

  const {
    amount,
    onRefundCreation
  } = useFinanceActions()

  const [expanded, setExpanded] = useState(true)
  const toggleCollapsable = useCallback(() => setExpanded(v => !v), [])

  return (
    <CollapsableSection
      title='Reembolsos'
      isExpanded={expanded}
      onClick={toggleCollapsable}
      css={collapsableCss(expanded)}
    >
      <div css={tableContainer}>
        <TableContainer card={card}>
          <TableHeader>
            <TableHeaderRow>
              <TableHeaderCell>Data e hora</TableHeaderCell>
              <TableHeaderCell>Valor</TableHeaderCell>
              <TableHeaderCell>Forma de Pagamento</TableHeaderCell>
              <TableHeaderCell>Aut/Doc</TableHeaderCell>
              <TableHeaderCell>Registrado por</TableHeaderCell>
              <TableHeaderCell>Status</TableHeaderCell>
              <TableHeaderCell fixed>Ação</TableHeaderCell>
            </TableHeaderRow>
          </TableHeader>
          <TableBody loading={loading}>
            {refunds.map(refund => (
              <RefundRow
                key={refund.id}
                card={card}
                refund={refund}
              />
            ))}
          </TableBody>
        </TableContainer>
      </div>
      {subordinatesLoading
        ? <LoadingAnimation css={loadingContainer} />
        : (
          isCommercialPartner
            ? (
              <AddLabel onClick={onRefundCreation} negative css={[noBottom, !amount?.credit && hidden]}>
                Registrar reembolso
              </AddLabel>
            )
            : null
        )
      }
    </CollapsableSection>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { H1, Card, colors, flexCenteredSpaceBetweenRow, GhostButton, Button, Row, Column, LoadingAnimation, BREAK_POINTS } from '@bonitour/components'
import { InvoiceInfo } from './InvoiceInfo'
import { InvoiceAmounts } from './InvoiceAmounts'
import { InvoiceNote } from './InvoiceNote'
import { InvoiceDetail } from './InvoiceDetail'
import { loadingContainer, marginBottom, marginRight, width100 } from 'assets/styles/global'
import { identity } from '@bonitour/common-functions'
import { InvoicePayments } from 'domains/Invoice/Containers/InvoicePayments'

const marginBottom20 = marginBottom(20)
const marginRight20 = css`
  ${marginRight(20)}

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    margin-right: 0;
  }
`
const backButton = css`
  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    ${width100}
    flex-direction: column;
  }
`

const container = css`
  ${marginBottom(70)};
  color: ${colors.gray3};
`

const buttonStyle = css`
  padding: 12px 30px;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    ${width100}
    margin-top: 10px;
  }
`

const title = css`
  text-align: center;
`

const mobileHeader = css`
  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    flex-direction: column;
  }
`

export const InvoicePayForm = ({
  invoice = {},
  amountDue = 0,
  invoiceDetail = { items: [], meta: {} },
  isActivityLoading = false,
  onInvoicePaymentViewClick = identity,
  onBackClick: emitBackClickEvent = identity,
  onPaymentRegisterClick: emitPaymentRegisterClick = identity,
  onPaginate: emitPaginationEvent = identity,
  onExportSubmit: emitExportSubmit = identity,
  exportInvoiceColumns,
  exportLoading
}) => {
  const { info = {}, amount: invoiceAmount = {}, details = {}, payments = [] } = invoice
  const { code = '', status = '' } = info
  const isInvoicePending = status === 'pending'
  const isPaymentRegisterEnabled = isInvoicePending || status === 'partially_paid' || status === 'overdue'

  return (
    <>
      {isActivityLoading
        ? <LoadingAnimation css={loadingContainer} />
        : (
          <>
            <div css={[flexCenteredSpaceBetweenRow, marginBottom20, mobileHeader]}>
              <H1 css={title}>Fatura {code}</H1>
              <div css={[flexCenteredSpaceBetweenRow, backButton]}>
                <GhostButton onClick={emitBackClickEvent} css={[buttonStyle, marginRight20]}>Voltar</GhostButton>
                {isPaymentRegisterEnabled && <Button css={[buttonStyle]} onClick={emitPaymentRegisterClick}>Registrar pagamento</Button>}
              </div>
            </div>
            <Card css={container}>
              <InvoiceInfo invoiceInfo={info} isReadOnly disableInvoiceNumber />
              <Row>
                <Column phone={12} desktop={9}>
                  <InvoicePayments payments={payments} onInvoicePaymentViewClick={onInvoicePaymentViewClick} />
                </Column>
                <Column phone={12} desktop={3}>
                  <InvoiceAmounts invoiceAmounts={invoiceAmount} amountDue={amountDue} receivedValueDescription={info?.invoiceValueReceivedDescription} />
                </Column>
              </Row>
              <InvoiceDetail
                invoiceDetail={invoiceDetail}
                onPaginate={emitPaginationEvent}
                onExportSubmit={emitExportSubmit}
                exportInvoiceColumns={exportInvoiceColumns}
                exportLoading={exportLoading}
              />
              <InvoiceNote isReadOnly details={details} />
            </Card>
          </>
        )}
    </>
  )
}

import { useQuery } from 'react-query'
import { ActivityService } from 'core/services/Activity'

const LOCKED_INTERVAL_TIME = 1 * 1000 * 60 // 1 minute
const UNLOCKED_INTERVAL_TIME = 5 * 1000 * 60 // 5 minutes

export const useIsLockedService = (serviceId = '') => {
  const {
    data: { isLocked = false } = {},
    isLoading,
    isFetching,
    isRefetching,
    refetch: refetchGetIsLockedService
  } = useQuery({
    queryKey: ['isLockedService', serviceId],
    queryFn: () => ActivityService.getIsLockedService(serviceId),
    refetchInterval: data =>
      data?.isLocked ? LOCKED_INTERVAL_TIME : UNLOCKED_INTERVAL_TIME,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: false
  })

  return {
    refetchGetIsLockedService,
    isLocked,
    isLoading: isLoading || isFetching || isRefetching
  }
}

import { getImageUrl } from 'services/Orb/Service'

export const VendorsParser = {
  vendor ({ image, ...rest }) {
    return { ...rest, image: getImageUrl(image) }
  },
  list ({ vendors = [] }) {
    return [...vendors.map(VendorsParser.vendor)]
  }
}

import { orbCore } from 'services/Orb/Service'
import { ExceptionalDayModel } from 'core/models/exceptionalDay'
import { ExceptionalDayParser } from 'core/parsers/exceptionalDay'

const { exceptionalDayDomain } = orbCore

export const ExceptionalDayService = {
  create (exceptionalDay) {
    return exceptionalDayDomain.create(ExceptionalDayModel.single(exceptionalDay))
  },
  getById (exceptionalDayId) {
    return exceptionalDayDomain.getById(exceptionalDayId).then(ExceptionalDayParser.single)
  },
  list ({ params }) {
    return exceptionalDayDomain.list({ ...ExceptionalDayModel.list({ params }) }).then(ExceptionalDayParser.list)
  },
  update (exceptionalDay, exceptionalDayId) {
    return exceptionalDayDomain.update(ExceptionalDayModel.single(exceptionalDay), exceptionalDayId)
  },
  remove (exceptionalDayId) {
    return exceptionalDayDomain.remove(exceptionalDayId)
  }
}

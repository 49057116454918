/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { TableContainer, TableHeader, TableBody, TableHeaderRow, TableHeaderCell } from '@bonitour/components'
import { InvoiceTableRow } from './InvoiceTableRow'
import { identity } from '@bonitour/common-functions'

export const InvoiceTable = ({
  invoiceList,
  onNavigation: emitOnNavigation = identity,
  loading
}) => {
  return (
    <TableContainer>
      <TableHeader>
        <TableHeaderRow>
          <TableHeaderCell>Data de vencimento</TableHeaderCell>
          <TableHeaderCell>Período de competência</TableHeaderCell>
          <TableHeaderCell>Parceiro</TableHeaderCell>
          <TableHeaderCell>Nota fiscal</TableHeaderCell>
          <TableHeaderCell>Valor total</TableHeaderCell>
          <TableHeaderCell>Valor devido</TableHeaderCell>
          <TableHeaderCell>Status</TableHeaderCell>
        </TableHeaderRow>
      </TableHeader>
      <TableBody loading={loading}>
        {invoiceList.map((invoice) => (
          <InvoiceTableRow
            invoice={invoice}
            key={`invoice-${invoice.id}`}
            onNavigation={emitOnNavigation(invoice.id)}
          />
        ))}
      </TableBody>
    </TableContainer>
  )
}

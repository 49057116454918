/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import moment from 'moment'
import { formatMoney } from '@bonitour/common-functions'
import { TableBodyRow, TableBodyCell, flexHorizontallyCentered, TooltipOnHover, InfoIcon, TransferIcon, Dialog, P, TruncateCell, flex, InfoSwatch } from '@bonitour/components'
import { defaultRowHeight, defaultCellPadding, clickable, infoIconStyle, hidden, valueCell } from 'assets/styles/global'
import { NavLink } from 'react-router-dom'
import { useMemo, useState } from 'react'
import { paymentTypesMap } from 'constants/reservationPaymentTypes'
import {
  marginBottom10,
  marginTop15,
  marginLeft15,
  marginTop10,
  removeLinkProperties,
  transferStyle,
  paymentDetailsStyle,
  textOverflow,
  receivedValueColor,
  itemBalanceColor
} from './invoice.style'

const formatDate = date => date && moment(new Date(date)).format('DD/MM/YY')

const DetailInfo = ({ info, title }) => {
  return (
    <div>
      <P css={[marginBottom10, marginTop15]}>{title}</P>
      {info}
    </div>
  )
}

export function InvoiceDetailRow ({ invoiceDetail }) {
  const { date, serviceName, reservationId, reservation, clientName, value, receivedValue, externalCode, paymentMethod, aut, doc, ticketCode, additionalValues } = invoiceDetail
  const itemBalance = value - (receivedValue || 0)

  const formatValue = value => (value === 0 ? '-' : formatMoney(value))

  const [isModalOpened, setModalOpenState] = useState(false)
  const toggleModal = () => setModalOpenState(prev => !prev)

  const hasExtraPrice = useMemo(() => Boolean(additionalValues?.ticketAdditionalValue && additionalValues?.ticketAdditionalValue > 0), [additionalValues?.ticketAdditionalValue])

  return (
    <>
      <Dialog
        title='Detalhes do item'
        isVisible={isModalOpened}
        onClose={toggleModal}
      >
        <div>
          <DetailInfo info={reservation} title='Código da reserva' />
          {ticketCode && <DetailInfo info={ticketCode} title='Código do Ingresso' />}
          {externalCode && <DetailInfo info={externalCode} title='Código externo' />}
          {(aut || doc) &&
            <div css={paymentDetailsStyle}>
              <P css={[marginBottom10, marginTop15]}>Detalhe do pagamento</P>
              {aut && <span>AUT {aut}</span>}
              {doc && <span css={marginTop10}>DOC {doc}</span>}
            </div>}
        </div>

      </Dialog>
      <TableBodyRow css={defaultRowHeight}>
        <TableBodyCell css={defaultCellPadding}>{formatDate(date)}</TableBodyCell>
        <TruncateCell text={serviceName} size='100px' />
        <TableBodyCell css={defaultCellPadding}>
          {externalCode || ticketCode}
        </TableBodyCell>
        <TableBodyCell css={defaultCellPadding}>
          <span css={textOverflow}>{clientName}</span>
        </TableBodyCell>
        <TableBodyCell css={valueCell}>
          <span>
            {formatValue(value)}
          </span>
          {hasExtraPrice && (
            <InfoSwatch tooltip={`Valor extra: ${formatValue(additionalValues.ticketAdditionalValue)}`}/>
          )}
        </TableBodyCell>
        <TableBodyCell css={hidden}>
          {formatValue(additionalValues?.sellerCreditValue || 0)}
        </TableBodyCell>
        <TableBodyCell css={defaultCellPadding}>
          <div css={flexHorizontallyCentered}>
            <span css={receivedValueColor(receivedValue)}>{formatValue(receivedValue)}</span>
          </div>
        </TableBodyCell>
        <TableBodyCell css={defaultCellPadding}>
          <span css={itemBalanceColor(itemBalance)}>{formatMoney(itemBalance)}</span>
        </TableBodyCell>
        <TruncateCell text={paymentTypesMap[paymentMethod]} size='100px' />

        <TableBodyCell css={defaultCellPadding} fixed>
          <div css={flex}>
            <TooltipOnHover text={`Ir para reserva ${reservation}`}>
              <NavLink to={`/reservation/${reservationId}`} css={removeLinkProperties} target='_blank'>
                <TransferIcon css={transferStyle} />
              </NavLink>
            </TooltipOnHover>
            <TooltipOnHover text='Ver mais informações'>
              <InfoIcon css={[infoIconStyle, clickable, marginLeft15]} onClick={toggleModal} />
            </TooltipOnHover>
          </div>
        </TableBodyCell>
      </TableBodyRow>
    </>
  )
}

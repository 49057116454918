/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { TableBodyRow, TableBodyCell, colors, CircleThumbnail, TooltipOnHover, PencilIcon } from '@bonitour/components'
import { circleColor, clickable } from 'assets/styles/global'
import { NavLink } from 'react-router-dom'
import { reservationStatusDictionary } from 'constants/reservationsStatus'
import { memo } from 'react'

const shortenFields = css`
  overflow: hidden;
  text-overflow: ellipsis;
`

const bigFields = css`
  ${shortenFields}
  max-width: 120px;
`

const resetPosition = css`
  align-items:unset;
  justify-content:unset;
`

const smallFields = css`
  ${shortenFields}
  max-width: 95px;
`

const iconSize = css`
  font-size: 23px;
`

const resetLink = css`
  color: ${colors.gray3};
  text-decoration: none;
`

const ReservationStatus = memo(({ status }) => {
  const { label, color } = reservationStatusDictionary[status]
  return (
    <>
      <CircleThumbnail size={10} css={circleColor(color)} />
      {label}
    </>
  )
})

export const ReservationsTableRow = ({ reservation = {} }) => {
  const { id = '', code = '', email = '', operadora = '', name = '', status = '' } = reservation
  const reservationEditUrl = `/reservation/${id}`

  return (
    <TableBodyRow>
      <TruncateCell truncateStyle={bigFields} text={code} />
      <TruncateCell truncateStyle={bigFields} text={email} />
      <TruncateCell truncateStyle={smallFields} text={operadora} />
      <TruncateCell truncateStyle={bigFields} text={name} />
      <TableBodyCell>
        <div css={smallFields}>
          <ReservationStatus status={status} />
        </div>
      </TableBodyCell>
      <TableBodyCell fixed>
        <TooltipOnHover text='Editar reserva'>
          <NavLink to={reservationEditUrl} target='_blank' rel='noopener noreferrer' css={resetLink}>
            <PencilIcon css={[iconSize, clickable]} />
          </NavLink>
        </TooltipOnHover>
      </TableBodyCell>
    </TableBodyRow>
  )
}

const TruncateCell = ({ text, truncateStyle, children }) =>
  <TableBodyCell>
    <TooltipOnHover css={resetPosition} text={text}>
      <div css={truncateStyle}>{children || text}</div>
    </TooltipOnHover>
  </TableBodyCell>

import { CompanyParser } from 'core/parsers/Company'
import { extractData, orbCore } from 'services/Orb/Service'
import { CompanyStore } from 'core/store/Company'
import { metaModel } from 'core/models/meta'

const chartData = [
  {
    date: '2019-11-05',
    reservation: 200
  },
  {
    date: '2019-11-09',
    reservation: 200,
    partner: 100
  },
  {
    date: '2019-12-20',
    reservation: 600,
    partner: 180
  },

  {
    date: '2019-12-30',
    reservation: 500,
    partner: 40
  },
  {
    date: '2019-12-07',
    partner: 210
  },
  {
    date: '2019-11-10',
    reservation: 200,
    partner: 200
  },
  {
    date: '2019-12-01',
    reservation: 200,
    partner: 100
  },
  {
    date: '2020-01-13',
    reservation: 440,
    partner: 320
  },
  {
    date: '2020-01-16',
    partner: 400
  },
  {
    date: '2020-01-30',
    reservation: 500,
    partner: 400
  },
  {
    date: '2020-02-15',
    reservation: 650,
    partner: 420
  },
  {
    date: '2020-02-22',
    partner: 500
  },
  {
    date: '2020-03-10',
    reservation: 580
  },
  {
    date: '2020-03-12',
    partner: 530
  },
  {
    date: '2020-02-15',
    reservation: 650,
    partner: 420
  }
]

export const CompanyService = {
  get () {
    const companyId = CompanyStore.getId()
    return orbCore.companiesDomain.get(companyId).then(CompanyParser.company)
  },
  list ({ pagination }) {
    return orbCore.companiesDomain.list({ pagination: metaModel(pagination) }).then(CompanyParser.list)
  },
  chart () {
    return new Promise((resolve) => resolve(CompanyParser.chart(chartData)))
  },
  reservations (page, perPage) {
    return orbCore.reservationDomain.dashboardIndications(page, perPage)
      .then(extractData)
      .then(CompanyParser.reservations)
  },
  listUserCompanies (active) {
    return orbCore.companiesDomain.listUserCompanies(active).then(CompanyParser.listUserCompanies)
  }
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Card, Input, InputFormGroup, colors, Button, Select, useToast, Row, Column, DatePicker, MultiSelectDropdown } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { useForm } from '@bonitour/app-functions'
import { string, array } from 'yup'
import { useKeyPress } from 'hooks/useKeyPress'
import { useValidateDateRange } from 'domains/CompanyReports/hooks/useValidadeDateRange'
import moment from 'moment'
import { useCallback, useMemo } from 'react'
import { width100 } from 'assets/styles/global'
import { invoiceBatchStatus } from '../constants/InvoiceBatchStatus'

const button = css`
  height: 40px;
  width: 100%;
  margin-top: 24px;
`
const cardContrast = css`
  margin-bottom: 20px;
  padding: 15px;
  background-color: ${colors.gray13};
  border: none;
`

const BatchStatusSelector = ({ status, onInputChange, errors }) => {
  return <MultiSelectDropdown
    label='Status'
    placeholder='Todos'
    errorMessage={errors.status}
    selected={status}
    onChange={onInputChange('status')}
    options={invoiceBatchStatus}
  />
}

export const InvoicesBatchFilter = ({
  recipientOptions = [],
  initialFilters = {},
  onFilterSubmit: emitFilterSubmit = identity,
  recipientLabel = 'Beneficiário'
}) => {
  const { add: addToast } = useToast()

  const invoicesBatchFilterSchema = useMemo(() => ({
    beneficiaryId: string(),
    status: array().of(string()),
    referenceNumber: string()
  }), [])

  const { form, errors, onSubmit, utils: { onInputChange } } = useForm(initialFilters, invoicesBatchFilterSchema)

  const { validateDateRange } = useValidateDateRange(true)
  const { onKeyDown } = useKeyPress()
  const { beneficiaryId = '', status = '', referenceNumber = '', startDate = '', endDate = '' } = form

  const validateFilters = useCallback((filters) => {
    validateDateRange(startDate, endDate) && emitFilterSubmit(filters)
  }, [emitFilterSubmit, endDate, startDate, validateDateRange])

  const onError = useCallback(() => addToast('Preencha o formulário corretamente'), [addToast])
  const onClick = onSubmit(validateFilters, onError)

  return (
    <Card css={cardContrast}>
      <Row>
        <Column phone={12} desktop={4}>
          <InputFormGroup label={recipientLabel} errorMessage={errors.beneficiaryId}>
            <Select
              placeholder='Todos'
              options={recipientOptions}
              value={beneficiaryId}
              error={errors.beneficiaryId}
              onChange={onInputChange('beneficiaryId')}
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={4}>
          <InputFormGroup label='Número de referência' errorMessage={errors.referenceNumber}>
            <Input
              id='referenceNumber'
              value={referenceNumber}
              onChange={onInputChange('referenceNumber')}
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={4}>
          <BatchStatusSelector status={status} onInputChange={onInputChange} errors={errors}/>
        </Column>
      </Row>
      <Row>
        <Column phone={12} desktop={4}>
          <InputFormGroup label='Data de pagamento inicial' errorMessage={errors.startDate}>
            <DatePicker
              value={startDate && moment(startDate)}
              onChange={onInputChange('startDate')}
              css={width100}
              allowsEmpty
              onKeyDown={event => onKeyDown(event, onClick)}
              placeholder='__/__/____'
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={4}>
          <InputFormGroup label='Data de pagamento final' errorMessage={errors.endDate}>
            <DatePicker
              value={endDate && moment(endDate)}
              onChange={onInputChange('endDate')}
              css={width100}
              allowsEmpty
              onKeyDown={event => onKeyDown(event, onClick)}
              placeholder='__/__/____'
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={4}>
          <Button css={button} onClick={onClick}>Pesquisar</Button>
        </Column>
      </Row>
    </Card>
  )
}

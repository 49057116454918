import { orbCore } from 'services/Orb/Service'
import { InviteParser } from './Parser'

export const InvitesService = {
  get (inviteId) {
    return orbCore.invitationDomain.get(inviteId).then(InviteParser.groupInfo)
  },
  list () {
    return orbCore.invitationDomain.list().then(InviteParser.idRelatedDictionary)
  },
  respond (inviteId, accept = true) {
    const response = { accept }
    return orbCore.invitationDomain.respond(response, inviteId)
  }
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { Button, Dialog, GhostButton, RadioGroup, RadioInputGroup, Row, scrollBar, colors, InfoSwatch, flexHorizontallyCentered, LoadingAnimation, BREAK_POINTS } from '@bonitour/components'
import { clickable, loadingContainer, margin, marginTop } from 'assets/styles/global'
import { SearchPassengers } from './SearchPassengers'
import { useMemo, useState } from 'react'
import { PassengerInfo } from './PassengerInfo'

const marginTop20 = marginTop(20)
const marginVertical20 = margin(20, 0, 20, 0)

const reservationPassengerContainer = css`
  max-height: 145px;
  overflow: auto;
  margin: 15px 0;
  ${scrollBar(5)};
`

const dependentLink = css`
  display: inline-block;
  margin-right: 10px;
  font-size: 14px;
  color: ${colors.gray4};
  text-decoration: underline;
  ${clickable};
`

const dialog = css`
  max-width: 900px;
`

const badge = css`
  margin-left: 5px;
  padding: 2px 5px;
  border-radius: 5px;
  color: ${colors.white};
  background: ${colors.warning};
`

const buttonsContainer = css`
  margin-top: 30px;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    display: flex;
    flex-direction: column;
  }
`

const cancelButton = css`
  margin-right: 20px;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    margin-bottom: 10px;
    margin-right: 0;
  }
`

const getUniqueValues = arr => arr.filter((item, index, self) => self.find(({ id }) => id === item.id) === item)

const ReservationPassengers = ({
  payer = {},
  reservationPassengers = [],
  passengerSelected = '',
  loading = false,
  onPassengerSelected = identity
}) => {
  const { payerId, name: payerName, birthDate, number, identificationDocument } = payer

  const passengers = useMemo(() => (
    getUniqueValues([{ id: payerId, fullName: payerName, birthDate, phone: number, document: identificationDocument }, ...reservationPassengers])
  ), [payerId, payerName, birthDate, number, identificationDocument, reservationPassengers])

  const hasPassengers = passengers.length > 0

  if (loading) return <LoadingAnimation size={30} css={[loadingContainer, marginVertical20]} />

  return (
    <div css={reservationPassengerContainer}>
      {hasPassengers &&
        <RadioGroup name='reservation-passengers' value={passengerSelected} onChange={onPassengerSelected}>
          {passengers.map((passenger) => (
            <RadioInputGroup key={passenger?.id} id={passenger?.id} value={passenger?.id}>
              <>
                {PassengerInfo(passenger, false)}
                {payerId === passenger?.id && <span css={badge}>Responsável</span>}
              </>
            </RadioInputGroup>
          ))}
        </RadioGroup>}
    </div>
  )
}

export const LinkPassengerSelection = ({
  reservation = {},
  reservationPassengers = [],
  reservationPassengersLoading,
  passengersFound = [],
  isVisible,
  searchPassengerLoading = false,
  onClickNewPassenger = identity,
  onClickNewDependent = identity,
  onLinkPassenger = identity,
  onSearchPassengers = identity,
  onClose: emitClose = identity
}) => {
  const [passengerSelected, setPassengerSelected] = useState('')
  const [isSearchPassengerVisible, setSearchPassengerVisibility] = useState(false)

  const onCloseDialog = () => {
    setSearchPassengerVisibility(false)
    emitClose()
  }

  const onClickSubmit = () => {
    onCloseDialog()
    passengerSelected && onLinkPassenger(passengerSelected)
  }

  return (
    <Dialog customContainercss={[dialog]} title='Vincular viajante' isVisible={isVisible} onClose={onCloseDialog}>
      <>
        {!isSearchPassengerVisible &&
          <ReservationPassengers
            payer={reservation?.finance}
            reservationPassengers={reservationPassengers}
            passengerSelected={passengerSelected}
            onPassengerSelected={setPassengerSelected}
            loading={reservationPassengersLoading}
          />}

        <SearchPassengers
          passengersFound={passengersFound}
          onClickNewPassenger={onClickNewPassenger}
          isSearchPassengerVisible={isSearchPassengerVisible}
          onSearchPassengerVisible={setSearchPassengerVisibility}
          passengerSelected={passengerSelected}
          onPassengerSelected={setPassengerSelected}
          onSearchPassengersSubmit={onSearchPassengers}
          onLinkPassenger={onClickSubmit}
          searchPassengerLoading={searchPassengerLoading}
        />

        {!isSearchPassengerVisible &&
          <div css={[flexHorizontallyCentered, marginTop20]}>
            <span css={dependentLink} onClick={onClickNewDependent}>Cadastrar um viajante dependente</span>
            <InfoSwatch
              size={200}
              tooltip='O dependente é o viajante que não precisa acessar os seus ingressos individuais, e por isso será vinculado com o responsável da reserva'
            />
          </div>}

        {!isSearchPassengerVisible &&
          <Row css={buttonsContainer}>
            <GhostButton css={cancelButton} onClick={onCloseDialog}>Cancelar</GhostButton>
            <Button onClick={onClickSubmit} disabled={!passengerSelected}>Vincular</Button>
          </Row>}
      </>
    </Dialog>
  )
}

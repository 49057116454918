import { orbCore } from 'services/Orb/Service'
import { PartnershipAccountModel } from 'core/models/partnershipAccount'
import { PartnershipAccountParser } from 'core/parsers/partnershipAccount'
import { ExportColumnsParser } from 'core/parsers/exportColumns'
import { convertToFormData } from '@bonitour/common-functions'

const { partnershipAccountDomain } = orbCore

export const PartnershipAccountService = {
  list: ({ filters, pagination, companyId, type }) => {
    return partnershipAccountDomain
      .list(...PartnershipAccountModel.list({ filters, pagination }), companyId, type)
      .then(PartnershipAccountParser.list)
  },
  getById: (partnershipAccountId) =>
    partnershipAccountDomain.getById(partnershipAccountId).then(PartnershipAccountParser.single),
  createEntry: (entry) => partnershipAccountDomain.createEntry(convertToFormData(PartnershipAccountModel.entry(entry))),
  listTransactions: ({ partnershipAccountId, pagination, startDate, endDate, paymentType }) =>
    partnershipAccountDomain
      .listTransactions(
        partnershipAccountId,
        PartnershipAccountModel.listTransactions({ pagination, startDate, endDate, paymentType })
      )
      .then(PartnershipAccountParser.transactions),
  getTransactionById: ({ partnershipAccountId, partnershipTransactionId }) =>
    partnershipAccountDomain
      .getTransactionById(partnershipAccountId, partnershipTransactionId)
      .then(PartnershipAccountParser.getTransaction),
  updateTransaction: ({ partnershipAccountId, transactionId, partnershipTransactionInfo }) =>
    partnershipAccountDomain.updateTransaction(
      partnershipAccountId,
      transactionId,
      convertToFormData(PartnershipAccountModel.update(partnershipTransactionInfo))
    ),
  getTotalizers: ({ partnershipAccountId, startDate, endDate, paymentType }) =>
    partnershipAccountDomain
      .getTotalizers(partnershipAccountId, PartnershipAccountModel.getTotalizers({ startDate, endDate, paymentType }))
      .then(PartnershipAccountParser.totalizers),
  csvExport: ({ filters, pagination }, partnershipAccountId) =>
    partnershipAccountDomain.csvExport(
      PartnershipAccountModel.partnershipAccountExport({ filters, pagination }),
      partnershipAccountId
    ),
  xlsxExport: ({ filters, pagination }, partnershipAccountId) =>
    partnershipAccountDomain.xlsxExport(
      PartnershipAccountModel.partnershipAccountExport({ filters, pagination }),
      partnershipAccountId
    ),
  getColumnsToExport: () => partnershipAccountDomain.getColumnsToExport().then(ExportColumnsParser.columns),
  getPaymentsType: () => partnershipAccountDomain.getPaymentsType().then(PartnershipAccountParser.getPaymentsType)
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Card, PaginationContainer, Loading, HeaderPage } from '@bonitour/components'
import { useHistory } from 'react-router-dom'
import { SalesFilter } from './SalesFilter'
import { SalesTable } from './../Table/SalesTable'
import { useListSales } from 'domains/CompanyReports/hooks/useListSales'
import { useRef } from 'react'
import { SalesResults } from './SalesResults'
import { ExportFile } from 'components/ExportFile/ExportFile'
import { salesReportExportOptions } from '../constants/salesReportExportOptions'
import { salesReportFormatOptions } from './salesReportFormatOptions'

export const SalesList = () => {
  const { push } = useHistory()
  const backToReports = () => push('/reports')
  const headings = [{ title: 'Gerando relatório de vendas...' }]

  const {
    filters,
    onFilterSubmit,
    changePagination,
    sales,
    meta,
    totals = {},
    loading,
    exportSalesReport,
    exporting
  } = useListSales()

  const { current: initialFilters } = useRef(filters)

  return (
    <>
      {exporting && <Loading isLoading={exporting} headings={headings} />}

      <HeaderPage onBack={backToReports} title='Vendas' />

      <Card>
        <SalesFilter
          onFilterSubmit={onFilterSubmit}
          loading={loading}
          initialFilters={initialFilters}
        />

        {sales && <SalesResults totals={totals} loading={loading} />}

        {!exporting && sales &&
          <ExportFile
            onSubmitExport={exportSalesReport}
            loading={exporting}
            initialFilters={initialFilters}
            filters={filters}
            multiSelectOptions={salesReportExportOptions}
            formatOptions={salesReportFormatOptions}
          />}

        <PaginationContainer total={meta?.total} pagesQuantity={meta?.totalPages} onPagination={changePagination}>
          <SalesTable sales={sales} loading={loading} />
        </PaginationContainer>

      </Card>
    </>
  )
}

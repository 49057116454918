/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Loading } from '@bonitour/components'

const ticketHeadings = [
  {
    title: 'Acessando o voucher para impressão'
  }
]

export const TicketPrintLoading = ({ isLoading = false, backgroundStyle = '' }) => {
  return (
    <Loading isLoading={isLoading} headings={ticketHeadings} backgroundStyle={backgroundStyle} />
  )
}

import { useCallback, useEffect, useState } from 'react'
import { useToast } from '@bonitour/components'
import { PaymentBatchServiceV2 } from 'core/services/PaymentBatch'

export const useShowPaymentBatch = () => {
  const { add: addToast } = useToast()
  const [loading, setLoading] = useState(false)

  const [paymentBatch, setPaymentBatch] = useState({})

  const [pendingFetch, setPendingFetch] = useState(null)

  const fetchPaymentBatch = useCallback((paymentBatchId) => {
    setLoading(true)
    return PaymentBatchServiceV2
      .showPaymentBatch(paymentBatchId)
      .then((res) => {
        setPaymentBatch(res)
        return res
      })
      .catch(() => addToast('Ocorreu um erro ao realizar a busca'))
      .finally(() => setLoading(false))
  }, [addToast])

  useEffect(() => {
    if (pendingFetch) {
      fetchPaymentBatch(pendingFetch)
      setPendingFetch(null)
    }
  }, [pendingFetch, fetchPaymentBatch])

  const clearBatch = useCallback(() => setPaymentBatch({}), [])

  return {
    paymentBatch,
    fetchPaymentBatch,
    loading,
    clearBatch
  }
}

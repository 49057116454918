import { useState } from 'react'
import { PartnershipAccountService } from 'core/services/PartnershipAccount'
import { useToast } from '@bonitour/components'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

export const useEditPartnershipAccount = () => {
  const [loading, setLoading] = useState(false)
  const { add: addToast } = useToast()

  const { partnershipAccountId } = useParams()

  const updateTransaction = async (transactionInfo) => {
    const { transactionId, ...partnershipTransactionInfo } = transactionInfo
    setLoading(true)
    return PartnershipAccountService.updateTransaction({ partnershipAccountId, transactionId, partnershipTransactionInfo })
      .then(() => addToast('Edição realizada com sucesso', 'success'))
      .catch(() => addToast('Ocorreu um erro no lançamento'))
      .finally(() => setLoading(false))
  }

  return {
    updateTransaction,
    loading
  }
}

import { css } from '@emotion/core'
import { colors } from '@bonitour/components'

export const width240 = css`
  width: 240px;
`

export const padding20 = css`
  padding: 20px;
`

export const label = css`
  font-size: 12px;
  color: ${colors.gray5};
  margin-bottom: 5px;
`

export const infoCardStyle = css`
  font-size: 24px;
  color: ${colors.gray1};
  font-weight: bold;
`

export const closeIcon = css`
  font-size: 24px;
  color: ${colors.gray3};
  cursor: pointer;
`

import { string } from 'yup'

export const validationSchema = {
  operatorLogin: string().required('Informe o login do agente'),
  operatorPassword: string()
    .required('Preencha o campo de senha do agente')
    .min(6, 'A senha deve conter no mínimo 6 caracteres')
    .max(128, 'A senha deve conter no máximo 128 caracteres'),
  supervisorLogin: string().required('Informe o login do supervisor'),
  supervisorPassword: string()
    .required('Preencha o campo de senha do supervisor')
    .min(6, 'A senha deve conter no mínimo 6 caracteres')
    .max(128, 'A senha deve conter no máximo 128 caracteres')
}

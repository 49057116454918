/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'

const flexGridMarginFix = css`
  display: flex;
  flex-flow: row wrap;
  margin: 0 -30px;
`

const emptyStyle = css`
  align-items: center;
  justify-content: center;
`

export const FlexGrid = ({ empty, ...other }) => <div {...other} css={[flexGridMarginFix, empty && emptyStyle]} />

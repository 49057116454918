import React, { Fragment } from 'react'
import { BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom'
import { ToastProvider } from '@bonitour/components'
import { css } from '@emotion/core'

import { ErrorBoundary } from '@sentry/react'
import { SentrySetup } from 'core/external/Sentry'

import { Router } from 'Router'
import { ExternalIntegrations } from 'external/integrations'

// avoding bug https://github.com/yannickcr/eslint-plugin-react/issues/2156
global.React = {
  Fragment
}

const toastPosition = css`
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 99999;
`

SentrySetup()

ExternalIntegrations()

const app = (
  <ErrorBoundary>
    <BrowserRouter>
      <ToastProvider cssStyle={toastPosition}>
        <Router />
      </ToastProvider>
    </BrowserRouter>
  </ErrorBoundary>
)

ReactDOM.render(app, document.getElementById('root'))

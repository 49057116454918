/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { TableBodyRow, TableBodyCell, EyeIcon, TooltipOnHover, flex, CircleThumbnail, flexHorizontallyCentered, TruncateCell, InfoSwatch } from '@bonitour/components'
import { formatMoney, identity, formatDate } from '@bonitour/common-functions'
import { reservationPaymentStatusMap } from 'constants/reservationPaymentStatus'
import { reservationPaymentTypesMap } from 'constants/reservationPaymentTypes'
import { capitalize, circleColor, marginLeft } from 'assets/styles/global'

const marginLeft5 = marginLeft(5)

const iconClick = css`
  font-size: 23px;
  margin: 0 5px;
  cursor: pointer;
`

export const ReservationProviderTransactionRow = ({
  onPaymentShow: emitPaymentShowEvent = identity,
  isTransactionARefund,
  ...payment
}) => {
  const { id, type, value, method, installments, autDoc, state, hour, date, companyDestinationName, customVendorId, itemType } = payment
  const status = reservationPaymentStatusMap[state]
  const transactionType = isTransactionARefund(type)
  const label = transactionType ? 'estorno' : 'pagamento'
  const transactionData = { isRefund: transactionType, method, itemType }
  const isMultipontoPayment = Boolean(customVendorId)

  return (
    <TableBodyRow>
      <TableBodyCell data-label='Operação' css={capitalize}>{type}</TableBodyCell>
      <TruncateCell data-label='Destino' text={companyDestinationName} size='100px' />
      <TableBodyCell data-label='Aut/Doc'>{autDoc}</TableBodyCell>
      <TableBodyCell data-label='Data e Hora'>{`${formatDate(date)} - ${hour}`}</TableBodyCell>
      <TableBodyCell data-label='Valor'>{formatMoney(value)}</TableBodyCell>
      <TableBodyCell data-label='Forma de Pagamento'>
        <div css={flexHorizontallyCentered}>
          {reservationPaymentTypesMap(method, installments)}
          {isMultipontoPayment && <InfoSwatch css={marginLeft5} tooltip='Multiponto' />}
        </div>
      </TableBodyCell>
      <TableBodyCell data-label='Status'>
        <div css={flexHorizontallyCentered}>
          <CircleThumbnail size={10} css={Boolean(status) && circleColor(status.color)} />
          {Boolean(status) && status.label}
        </div>
      </TableBodyCell>
      <TableBodyCell data-label='Ação' fixed>
        <div css={flex}>
          <TooltipOnHover text={`Visualizar ${label}`}>
            <EyeIcon css={iconClick} onClick={() => emitPaymentShowEvent(id, transactionData)} />
          </TooltipOnHover>
        </div>
      </TableBodyCell>
    </TableBodyRow>
  )
}

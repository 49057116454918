/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import { Input, flex, Card, AddLabel, Button, useToast, flexHorizontallyCentered, MaskedInput, ConfirmDialog, TrashIcon, GhostButton } from '@bonitour/components'
import { line, timeInput, inputContainer, input, label, saveButton, card, trashWrapper, deleteButton, dailyLimitContainer } from './BookingMapConfigEdit.styles'
import { useForm } from '@bonitour/app-functions'
import { bookingMapConfigFormSchema } from './BookingMapConfigForm.schema'
import { identity, maskTime } from '@bonitour/common-functions'
import { useCreateExceptionalDay } from '../hooks/useCreateExceptionalDay'
import { useEditExceptionalDay } from '../hooks/useEditExceptionalDay'

import { useCallback, useState } from 'react'

export const BookingMapConfigForm = ({
  index,
  item = {},
  errors = [],
  onInputBlur: emitInputBlur = identity,
  onInputChange: emitInputChange = identity,
  onDeleteItem: emitDeleteItem = identity
}) => {
  const { time = '', vacancies = 0, reservations = 0, vacanciesLimit = 0, isTimeDisabled = false } = item || {}

  const onVacanciesChange = useCallback((value) => {
    const vacanciesLimit = Number(value) + Number(reservations)

    emitInputChange(`registries[${index}].vacancies`)(Number(value))
    emitInputChange(`registries[${index}].vacanciesLimit`)(vacanciesLimit)
  }, [emitInputChange, index, reservations])

  const onVacanciesBlur = useCallback((value) => {
    emitInputBlur(`registries[${index}].vacancies`)(Number(value))
    emitInputBlur(`registries[${index}].vacanciesLimit`)(vacanciesLimit)
  }, [emitInputBlur, index, vacanciesLimit])

  return (
    <div css={line}>
      <div css={flexHorizontallyCentered}>
        <div css={trashWrapper} onClick={emitDeleteItem(index)}>
          <TrashIcon />
        </div>
        <MaskedInput
          formatterFunction={maskTime}
          css={timeInput}
          value={time}
          error={errors.registries?.[index]?.time}
          onChange={emitInputChange(`registries[${index}].time`)}
          disabled={isTimeDisabled}
        />
      </div>
      <div css={flex}>
        <div css={inputContainer}>
          <Input
            css={input}
            type='number'
            min='0'
            value={vacancies}
            error={errors.registries?.[index]?.vacancies}
            onBlur={onVacanciesBlur}
            onChange={onVacanciesChange}
          />
          <span css={label}>Disponíveis</span>
        </div>
        <div css={inputContainer}>
          <Input css={input} value={reservations} disabled />
          <span css={label}>Reservadas</span>
        </div>
        <div css={inputContainer}>
          <Input
            css={input}
            type='number'
            min={reservations}
            value={vacanciesLimit}
            disabled
          />
          <span css={label}>Limite de vagas</span>
        </div>
      </div>
    </div>
  )
}

export const BookingMapConfigEdit = ({
  date,
  activityId,
  exceptionalDayId,
  registriesBase = {},
  onBack: emitBack = identity,
  onDelete: emitDelete = identity
}) => {
  const { add: addToast } = useToast()
  const { createExceptionalDay } = useCreateExceptionalDay()
  const { updateExceptionalDay } = useEditExceptionalDay()
  const { form, errors, onSubmit, utils: { onInputBlur, onInputChange, addItemOnArray, removeItemArray } } = useForm(registriesBase, bookingMapConfigFormSchema)
  const { dailyLimit, registries = [] } = form
  const [isConfirmActionVisible, setConfirmActionVisible] = useState(false)
  const toggleConfirmActionVisible = () => setConfirmActionVisible(true)
  const toggleConfirmActionHidden = () => setConfirmActionVisible(false)

  const onDeleteItem = index => () => removeItemArray('registries')(index)

  const onAddItem = () => {
    addItemOnArray('registries')({
      time: '00:00',
      vacancies: 0,
      reservations: 0,
      vacanciesLimit: 0,
      isTimeDisabled: false
    })
  }

  const onValidationError = () => {
    const { registries = [] } = errors || {}

    if (typeof registries === 'string') {
      return addToast(registries)
    } else {
      return addToast('Preencha corretamente o formulário')
    }
  }

  const onValidationSuccess = ({ dailyLimit, registries }) => {
    if (registries.length <= 0) {
      addToast('Informe ao menos um horário para o dia excepcional')
      return
    }

    const exceptionalDay = {
      name: 'Dia excepcional',
      serviceId: activityId,
      exceptionalDate: date,
      dailyLimit,
      registries
    }

    if (exceptionalDayId) {
      updateExceptionalDay(exceptionalDay, exceptionalDayId)
        .then(() => {
          addToast('Dia excepcional editado com sucesso', 'success')
          emitBack()
        })
        .catch(addToast)
    } else {
      createExceptionalDay(exceptionalDay)
        .then(() => {
          addToast('Dia excepcional criado com sucesso', 'success')
          emitBack()
        })
        .catch(addToast)
    }
  }

  const onSaveClick = onSubmit(onValidationSuccess, onValidationError)

  return (
    <>
      <Card css={card}>
        <div css={dailyLimitContainer}>
          <Input
            css={input}
            type='number'
            min='0'
            value={dailyLimit}
            error={errors.dailyLimit}
            onChange={onInputChange('dailyLimit')}
            onBlur={onInputBlur('dailyLimit')}
          />
          <span css={label}>Limite do dia</span>
        </div>
        {registries.map((item, index) => (
          <BookingMapConfigForm
            key={index}
            index={index}
            item={item}
            errors={errors}
            onInputChange={onInputChange}
            onInputBlur={onInputBlur}
            onDeleteItem={onDeleteItem}
          />
        ))}

        <AddLabel onClick={onAddItem}>Novo horário excepcional</AddLabel>
      </Card>
      <Button css={saveButton} onClick={onSaveClick}>Salvar alterações</Button>
      {exceptionalDayId && <GhostButton css={deleteButton} onClick={toggleConfirmActionVisible}>Excluir dia excepcional</GhostButton>}

      <ConfirmDialog
        title='Exclusão de dia excepcional'
        message='Esse dia excepcional será excluído permanentemente. Deseja continuar?'
        isVisible={isConfirmActionVisible}
        successCallback={emitDelete(exceptionalDayId)}
        cancelCallback={toggleConfirmActionHidden}
      />
    </>
  )
}

import { validateCpf, validatePhoneWithDDI } from '@bonitour/common-functions'
import moment from 'moment'
import { string } from 'yup'

const validateBirthDate = birthDate => {
  const eighteenYearsAgo = moment().subtract(18, 'year')
  return moment(birthDate).isSameOrBefore(eighteenYearsAgo, 'day')
}

export const reservationTypeSchema = (states = [], cities = []) => {
  const stateRules = states.length ? string().required('O estado é um campo obrigatório') : string()
  const cityRules = cities.length ? string().required('A cidade é um campo obrigatório') : string()

  return ({
    paymentState: string(),
    id: string(),
    name: string().required('O nome é obrigatório'),
    email: string().when('paymentState', { is: 'responsible', then: s => s.required('O email é obrigatório').email('O email deve ser válido') }),
    birthDate: string().when('paymentState', {
      is: 'responsible', then: s => {
        return s.test('test-birthDate', 'O responsável pela compra deve ter mais de 18 anos',
          (birthDate) => {
            if (!birthDate) {
              return true
            }
            return birthDate && validateBirthDate(birthDate)
          }
        )
      }
    }),
    identificationDocument: string().when('paymentState', {
      is: 'responsible', then: s => {
        return s.test('test-cpf', 'O CPF deve ser válido', (cpf) => {
          if (!cpf) {
            return true
          }
          return cpf && validateCpf(cpf)
        })
      }
    }),
    number: string().when('paymentState', {
      is: 'responsible', then: s => {
        return s.test('validate-phone', 'O número de telefone deve ser válido',
          (phone) => {
            if (!phone) {
              return true
            }
            return phone && validatePhoneWithDDI(phone)
          }
        )
      }
    }),
    emergencyName: string(),
    emergencyTelephone: string().test('validate-phone', 'O número do telefone deve ser válido', (emergencyTelephone) => {
      if (emergencyTelephone.length <= 4) {
        return true
      }
      return emergencyTelephone && validatePhoneWithDDI(emergencyTelephone)
    }),
    country: string(),
    weight: string(),
    height: string(),
    state: stateRules,
    city: cityRules,
    zipCode: string().nullable(),
    street: string().nullable(),
    district: string().nullable(),
    supplement: string().nullable()
  })
}

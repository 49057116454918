import { useEffect, useRef } from 'react'

export function useInterval (callback, delay) {
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  })

  useEffect(() => {
    function tick () {
      savedCallback.current()
    }

    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => {
        clearInterval(id)
      }
    }
  }, [delay])
}

export function useChangeableInterval (callback, delay, executeOnStart = false) {
  useEffect(() => {
    if (executeOnStart) {
      callback()
    }
  }, [callback, executeOnStart])

  useEffect(() => {
    if (delay !== null) {
      const id = setInterval(callback, delay)
      return () => {
        clearInterval(id)
      }
    }
  }, [delay, callback])
}

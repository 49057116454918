import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ExternalProviderService } from 'core/services/ExternalProvider'

export const useEditExternalProvider = () => {
  const { externalProviderId } = useParams()
  const [loading, setLoading] = useState(false)
  const [externalProvider, setExternalProvider] = useState()

  useEffect(() => {
    setLoading(true)
    ExternalProviderService.getById(externalProviderId).then(setExternalProvider).finally(() => setLoading(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const edit = async (externalProvider) => {
    setLoading(true)
    return ExternalProviderService.update(externalProvider, externalProviderId).finally(() => setLoading(false))
  }

  return {
    externalProvider,
    submit: edit,
    loading
  }
}

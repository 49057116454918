/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { CircleThumbnail, colors, Image } from '@bonitour/components'
import { getFirstChar } from 'utils/getFirstChar'

const imageContainer = (size, labelColor = colors.white, backgroundColor = colors.blue2) => css`
  min-width: ${size}px;
  min-height: ${size}px;  
  font-weight: bold;
  text-transform: uppercase;
  color: ${labelColor};
  background-color: ${backgroundColor};
`

const fullWidth = css`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const SwatchImage = ({ image, size = 30, alternativeText, ...others }) => {
  return (
    <CircleThumbnail css={imageContainer(size)} size={size} {...others}>
      {image ? <Image css={fullWidth} src={image} /> : getFirstChar(alternativeText)}
    </CircleThumbnail>
  )
}

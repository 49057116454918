import { colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const containerDialogStyle = css`
  max-width: 900px;
`

export const actionContainerStyle = css`
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
`

export const increaseFontSizeStyle = css`
  font-size: 1rem;
`

export const referenceStyle = css`
  position: relative;

  &:has(p) {
    top: 7px;
  }

  &.is-negative {
    &::before {
      content: '- ';
    }
  }
  &.is-positive {
    &::before {
      content: '+ ';
    }
  }
`

export const releaseStyle = css`
  position: absolute;
  top: -15px;
  color: ${colors.gray5};
  font-size: 14px;
`

export const labelStyle = css`
  font-size: 1.2rem;
  margin: 0 0.5rem 0 0.5rem;
`

export const scrolledContainerStyle = css`
  margin-top: 5px;
  max-height: 200px;
  overflow-y: auto;
`

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { CashierHandlingIcon, CashierIcon, CashierListIcon, Column, Label, Row } from '@bonitour/components'
import { jsx } from '@emotion/core'
import { resetLink } from 'assets/styles/global'
import { CardButton } from 'components/CardButton'
import { useMemo } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { linkHeight, activeLink, links, cardIcon, labelCardButton, container } from './Links.style'

const paths = [
  {
    to: '/cashiers/my_cashier',
    label: 'Meu caixa',
    icon: CashierIcon
  },
  {
    to: '/cashiers',
    label: 'Lista de caixas',
    icon: CashierListIcon
  },
  {
    to: '/cashier-handling',
    label: 'Movimentações de caixa',
    icon: CashierHandlingIcon
  }
]

export const CashierLinks = () => {
  const { pathname } = useLocation()
  const activePath = useMemo(() => paths.findIndex((path) => pathname.startsWith(path.to)), [pathname])
  return (
    <Row>
      <Column phone={12} css={links}>
        <div css={container}>
          {paths.map(({ to, label, icon }, index) => (
            <NavItem key={to} active={activePath === index} to={to} label={label} icon={icon} />
          ))}
        </div>
      </Column>
    </Row>
  )
}

const NavItem = ({ active, to, label, icon: Icon }) => (
  <NavLink to={to} css={resetLink}>
    <CardButton css={[linkHeight, active && activeLink]}>
      <Icon css={cardIcon} />
      <Label css={labelCardButton}>{label}</Label>
    </CardButton>
  </NavLink>
)

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { AngleDownIcon } from '@bonitour/components'
import { activityTitle, companyTitle, activitySelector, activityTitleIcon, activityPopoverWrapper } from './Selector.style'
import { useActivity } from 'contexts/Activity'
import { ExperienceSelectorModal } from 'containers/Reservations/ReservationList/ExperienceSelectorModal'
import { useMemo } from 'react'
import { OFFLINE_EXPERIENCE_TYPE } from 'constants/activityTypes'

export const ActivitySelector = ({ subdomain, disableOffline = false, ...other }) => {
  const { activity: { name: activityName, companyName } = {} } = useActivity()

  const disabledTypes = useMemo(() => {
    if (subdomain === 'bookings' || disableOffline) {
      return [OFFLINE_EXPERIENCE_TYPE]
    }
    return []
  }, [disableOffline, subdomain])

  return (
    <>
      <ExperienceSelectorModal subdomain={subdomain} disabledTypes={disabledTypes}>
        <div css={activitySelector} {...other}>
          <span css={companyTitle}>{companyName}</span>
          <span css={activityTitle}>
            {activityName}
            <div css={activityPopoverWrapper}>
              <AngleDownIcon css={activityTitleIcon} />
            </div>
          </span>
        </div>
      </ExperienceSelectorModal>
    </>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useState, useEffect, useCallback } from 'react'
import { Accordion, CollapsableSection, useToast, HeaderPage } from '@bonitour/components'
import { TradeAgreementForm } from 'containers/PartnershipGroup/TradeAgreement/TradeAgreementForm'
import { InvoicePolicyForm } from 'containers/PartnershipGroup/InvoicePolicy/InvoicePolicyForm'
import { PartnershipForm } from 'containers/PartnershipGroup/Partnership/PartnershipForm'
import { useQuery } from 'hooks/useQuery'
import { SellersGroupsService } from 'services/SellersGroups/Service'
import { useHistory, useParams } from 'react-router-dom'
import { PaginatedSellersProvider } from 'app/Sellers/SellersContext'

export const EditPartnershipGroup = () => {
  const [{ accordion = 1 }] = useQuery()
  const { groupId } = useParams()
  const [sellersGroup, setSellersGroup] = useState({})
  const { add: addToast } = useToast()
  const { push } = useHistory()

  const backToDashboard = () => push('/sellers')
  const onBackStep = () => setIndicatorActive(Number(accordion) - 1)
  const onNextStep = () => setIndicatorActive(Number(accordion) + 1)

  useEffect(() => {
    SellersGroupsService.getById(groupId).then(setSellersGroup)
    // eslint-disable-next-line
  }, [])

  const setIndicatorActive = useCallback(accordionValue => push({ search: `?accordion=${accordionValue}` }), [push])

  const updateSellersGroup = (section, data) => setSellersGroup(previousDefault => ({ ...previousDefault, [section]: data }))

  const onSuccessTradeAgreement = data => {
    updateSellersGroup('tradeAgreement', { ...data })
    onNextStep()
    addToast('As informações serão gravadas somente ao clicar em salvar na seção parceiros', 'warning')
  }

  const onSuccessInvoicePolicy = data => {
    updateSellersGroup('invoicePolicy', data)
    onNextStep()
  }

  const onSuccessComercialPartners = data => {
    const group = { ...sellersGroup, comercialPartners: data }
    setSellersGroup(group)

    onSuccess(group)
  }

  const onSuccess = async sellersGroup => {
    try {
      await SellersGroupsService.update(sellersGroup)
      backToDashboard()
      addToast('Grupo editado com sucesso', 'success')
      addToast('Aguarde confirmação dos seus parceiros', 'success')
    } catch (error) {
      addToast('Houve um erro na edição do grupo')
    }
  }

  const onValidationError = async () => {
    addToast('Preencha corretamente o formulário')
  }

  const onAccordionClick = value => (value !== 0 && value < accordion) && setIndicatorActive(value)
  const isGroupEditable = sellersGroup?.editable

  useEffect(() => {
    isGroupEditable ? setIndicatorActive(1) : setIndicatorActive(3)
  }, [isGroupEditable, setIndicatorActive])

  return (
    <>
      <HeaderPage onBack={backToDashboard} title='Grupo' />

      <Accordion value={Number(accordion)} onClick={onAccordionClick}>
        <CollapsableSection
          indicator={1}
          title='Acordo comercial'
          topics='Nome da categoria / Comissionamento'
        >
          <TradeAgreementForm
            tradeAgreement={sellersGroup.tradeAgreement}
            onSuccess={onSuccessTradeAgreement}
            onValidationError={onValidationError}
            isGroupEditable={isGroupEditable}
            savedCustomComissions={sellersGroup.customComissions}
            isLoading={sellersGroup?.tradeAgreement?.name === undefined}
          />
        </CollapsableSection>

        <CollapsableSection indicator={2} title='Cobrança' topics='Fatura / Cobrança antecipada'>
          <InvoicePolicyForm
            invoicePolicy={sellersGroup.invoicePolicy}
            onSuccess={onSuccessInvoicePolicy}
            onBackStep={onBackStep}
            onValidationError={onValidationError}
            isGroupEditable={isGroupEditable}
          />
        </CollapsableSection>

        <CollapsableSection indicator={3} title='Parceiros' topics='Parceiros'>
          <PaginatedSellersProvider>
            <PartnershipForm
              tradeAgreementName={sellersGroup?.tradeAgreement?.name}
              listComercialPartners={sellersGroup.comercialPartners}
              onBackStep={onBackStep}
              onSuccess={onSuccessComercialPartners}
            />
          </PaginatedSellersProvider>
        </CollapsableSection>
      </Accordion>
    </>
  )
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useEffect, useState } from 'react'
import { ActivityAboutForm } from 'containers/ActivityAboutForm/ActivityAboutForm'
import { useToast } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { ActivityService } from 'services/Activity/Service'
import { ActivityAdditionInformationService } from 'services/Activity/ActivityAdditionInformation/Service'

export const ActivityAbout = ({
  activityId = '',
  activityAbout = {},
  categories = {},
  onActivityUpdated = identity,
  onBackStep = identity,
  onNextStep = identity
}) => {
  const [infrastructureList, setInfrastructureList] = useState([])
  const { add } = useToast()

  const onValidationError = () => {
    add('Preencha corretamente o formulário')
  }

  const onSuccess = async data => {
    try {
      await ActivityService.updateAbout(data, activityId)
      onNextStep()
      onActivityUpdated()
    } catch (error) {
      add('Ocorreu um erro ao adicionar as informações')
    }
  }

  useEffect(() => {
    ActivityAdditionInformationService.list({ type: 'activity' }).then(({ infrastructureList }) => infrastructureList && setInfrastructureList(infrastructureList))
  }, [])

  return (
    <ActivityAboutForm
      categories={categories}
      activityAbout={activityAbout}
      infrastructureList={infrastructureList}
      onBackClick={onBackStep}
      onSuccess={onSuccess}
      onValidationError={onValidationError}
    />
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useHistory, useParams } from 'react-router-dom'
import { Modal, useToast } from '@bonitour/components'
import { InvoicePayForm } from 'containers/Invoice/InvoicePayForm'
import { useInvoice } from '../hooks/useInvoice'
import { useInvoicePayment } from '../hooks/useInvoicePayment'
import { useInvoiceDetailsExport } from '../hooks/useInvoiceDetailsExport'
import { InvoiceEditPaymentModal } from '../Payment/InvoiceEditPaymentModal'
import { useForm } from '@bonitour/app-functions'
import { invoicePaySchema } from 'core/schemas/invoicePay'
import { useEffect, useState } from 'react'
import { PaymentRegisterForm } from 'containers/Invoice/PaymentRegisterForm'
import { InvoicePaymentModal } from '../Payment/InvoicePaymentModal'

export const EditInvoicePay = () => {
  const history = useHistory()
  const { invoiceId } = useParams()
  const { add } = useToast()
  const [paymentInfo, setPaymentInfo] = useState({})
  const { invoice, invoiceDetails, amountDue, updateInvoice, paginateInvoiceDetails, loading, vendorId, companyId } = useInvoice(invoiceId, 'pay')
  const { isPaymentVisible, openPayment, closePayment, payInvoice, isInvoicePaymentVisible, isInvoiceEditVisible, invoicePayment, openInvoiceEditPay, openInvoicePay, closeInvoicePayment, closeInvoiceEditPayment, approvePayment, rejectPayment, editInvoicePayment, isLoading } = useInvoicePayment(invoiceId, updateInvoice)
  const { onExportSubmit, exportInvoiceColumns, exportLoading } = useInvoiceDetailsExport(invoiceId)
  const { form, onSubmit, utils: { onInputChange } } = useForm(paymentInfo, invoicePaySchema)

  const { originBankAccount, destinationBankAccount } = form
  const onActionClick = (payment, type) => {
    type === 'show' ? openInvoicePay(payment) : openInvoiceEditPay(payment)
  }

  const onSubmitInvoicePayEdition = () => {
    const paymentId = invoicePayment?.id
    const paymentInfo = {
      originBankAccount,
      ...(destinationBankAccount && { destinationBankAccount })
    }
    editInvoicePayment(paymentId, paymentInfo)
  }

  const onValidationError = () => add('Preencha corretamente o formulário')
  const onConfirm = onSubmit(onSubmitInvoicePayEdition, onValidationError)

  useEffect(() => {
    setPaymentInfo(invoicePayment)
  }, [invoicePayment])

  const returnToList = () => history.push('/invoices-pay')

  return (
    <>
      <InvoicePayForm
        invoice={invoice}
        invoiceAmounts={invoice.amount}
        amountDue={amountDue}
        invoiceDetail={invoiceDetails}
        isActivityLoading={loading}
        onBackClick={returnToList}
        onInvoicePaymentViewClick={onActionClick}
        onPaymentRegisterClick={openPayment}
        onPaginate={paginateInvoiceDetails}
        onExportSubmit={onExportSubmit}
        exportInvoiceColumns={exportInvoiceColumns}
        exportLoading={exportLoading}
      />
      <Modal isVisible={isPaymentVisible} title='Registrar pagamento' onCloseClick={closePayment}>
        <PaymentRegisterForm
          amountDue={amountDue}
          onCancel={closePayment}
          onSuccess={payInvoice}
          vendorId={vendorId}
          companyId={companyId}
          isLoading={isLoading}
        />
      </Modal>
      <InvoicePaymentModal
        vendorId={vendorId}
        companyId={companyId}
        isInvoicePaymentVisible={isInvoicePaymentVisible}
        invoicePayment={invoicePayment}
        onCloseClick={closeInvoicePayment}
        onRefusalClick={rejectPayment}
        onApprovalClick={approvePayment}
        isLoading={isLoading}
      />
      <InvoiceEditPaymentModal
        vendorId={vendorId}
        companyId={companyId}
        isInvoicePaymentVisible={isInvoiceEditVisible}
        invoicePayment={form}
        onCloseClick={closeInvoiceEditPayment}
        onRefusalClick={rejectPayment}
        onApprovalClick={approvePayment}
        onChange={onInputChange}
        onConfirm={onConfirm}
      />
    </>
  )
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { identity } from '@bonitour/common-functions'
import { jsx, css } from '@emotion/core'
import { TableBodyRow } from '@bonitour/components'
import { TableBodyHourCell } from '../BodyCell/TableBodyHourCell'
import { useMemo } from 'react'
import VacancyTableCell from './VacancyTableCell'

const cellIsNotSelected = css`
  td:not(:first-of-type) {
    opacity: 0.6;
    backdrop-filter: blur(21px);
  }
`

export const VacancyTableRow = ({
  rowHour = '',
  dateColumns = [],
  datesData = {},
  tableDetailedData = {},
  onExpand: emitExpandEvent = identity,
  VacancySummaryPopoverContent,
  isExpanded = false,
  isExpandable = true,
  tablePrices = {},
  ...other
}) => {
  const onExpand = () => emitExpandEvent(rowHour)
  const hasExpandedData = useMemo(() => Object.keys(tableDetailedData).length > 0, [tableDetailedData])

  return (
    <TableBodyRow css={[!isExpanded && hasExpandedData && cellIsNotSelected]} {...other}>
      <TableBodyHourCell
        isExpandable={isExpandable}
        rowHour={rowHour}
        isExpanded={isExpanded}
        onClick={onExpand}
      />

      {
        dateColumns.map(date =>
          <VacancyTableCell
            key={date}
            date={date}
            vacancies={datesData[date]?.vacancies || {}}
            rowHour={rowHour}
            hasExpandedData={hasExpandedData}
            detailedReservations={tableDetailedData[date]?.reservations || {}}
            reservations={datesData[date]?.reservations || {}}
            onExpand={onExpand}
            isExpanded={isExpanded}
            isExpandable={isExpandable}
            VacancySummaryPopoverContent={VacancySummaryPopoverContent}
            ticketTariff={tablePrices?.[date]}
          />)
      }
    </TableBodyRow>
  )
}

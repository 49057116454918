import { colors, scrollBar } from '@bonitour/components'
import { css } from '@emotion/core'

export const linkHeight = css`
  min-height: 84px;
`
export const activeLink = css`
  box-shadow: none;
  color: ${colors.gray3};
`

export const links = css`
  display: flex;
  margin-bottom: 20px;
`
export const cardIcon = css`
  font-size: 26px;
  font-weight: bold;
  padding: 4px 5px;
  border-radius: 50%;
  color: inherit;
`

export const labelCardButton = css`
  cursor: pointer;
  font-size: 15px;
  color: inherit;
  font-weight: bold;
  margin-top: 5px;
`

export const container = css`
  display: flex;
  padding: 5px 0;
  overflow-x: auto;
  ${scrollBar(5)};
`

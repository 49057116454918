import { useEffect } from 'react'
import { useUser } from 'contexts/User'

export const Logout = () => {
  const { logout } = useUser()

  useEffect(() => {
    logout()
  }, [logout])

  return null
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Card, H2, Row, H3, colors } from '@bonitour/components'
import { formatMoney } from '@bonitour/common-functions'
import { marginTop } from 'assets/styles/global'

const marginTop50 = marginTop(50)

const resultContainer = css`
  min-width: 215px;
  margin-right: 20px;
  margin-bottom: 10px;
`

const resultValue = css`
  color: ${colors.primary};
  font-size: 24px;
  font-weight: bold;
`

const blurValue = css`
  filter: blur(0.5rem);
`

const ResultCard = ({ title, value, blur = false }) => {
  return (
    <Card css={resultContainer}>
      <H3>{title}</H3>
      <span css={[resultValue, blur && blurValue]}>{value}</span>
    </Card>
  )
}

export const TouristPaymentsResults = ({ meta = {}, totals = {}, isLoading = false }) => {
  const { total: totalEntries } = meta
  const { paymentTotalValue: totalValue } = totals

  return (
    <div css={marginTop50}>
      <H2>Resultado</H2>
      <Row>
        <ResultCard title='Valor total' value={formatMoney(totalValue)} blur={isLoading} />
        <ResultCard title='Pagamentos' value={totalEntries} blur={isLoading} />
      </Row>
    </div>
  )
}

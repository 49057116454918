import { css } from '@emotion/core'
import { colors, scrollBar, flexRowCentered } from '@bonitour/components'

export const previewContainer = css`
  position: relative;
`

export const stickyContainer = css`
  position: sticky;
  top: 20px;
`

export const desktopOnly = css`
  @media screen and (max-width: 1199px) {
    display: none;
  }
`

export const timeStyle = css`
  font-size: 46px;
  color: ${colors.gray8};
  margin-bottom: 10px;
`

export const dateStyle = css`
  color: ${colors.gray8};
  text-transform: capitalize;
`

export const pushNotification = css`
  width: 95%;
  margin-top: 20px;
  padding: 10px;
  border-radius: 10px;
  background: transparent linear-gradient(270deg, #4d4dff0d 0%, #55a6ac0d 100%) 0% 0% no-repeat padding-box;
`

export const semiBold = css`
  font-weight: 600;
`

export const appName = css`
  font-size: 9px;
  ${semiBold};
  color: #8d8599;
  text-transform: uppercase;
`

export const pushNotificationActivityName = css`
  font-size: 10px;
  color: ${colors.gray3};
  font-weight: bold;
  line-height: 1.5;
`

export const pushNotificationMessageStyle = css`
  font-size: 10px;
  color: ${colors.gray3};
`

export const categoryTitleStyle = css`
  font-weight: bold;
  color: ${colors.gray3};
`

export const contentMessage = css`
  border-top: 1px solid ${colors.gray7};
  margin-top: 20px;
  padding: 10px 0;
  width: 100%;
  font-size: 14px;
  color: ${colors.gray3};
`

export const messageContainer = css`
  height: auto;
  max-height: 280px;
  margin-top: 10px;
  padding: 0 10px;
  overflow-y: auto;
  ${scrollBar(5)};
`

export const thumbImage = css`
  background-color: ${colors.white};
  background-repeat: no-repeat;
  margin-right: 10px;
  font-size: 22px;
  color: ${colors.gray4};
`

export const imageSize = css`
  width: 40px;
  height: 40px;
`

export const logoContainer = css`
  ${flexRowCentered};
  background-color: ${colors.white};
  border-radius: 2px;
  padding: 2px;
  margin-right: 5px;
`

export const logo = css`
  width: 10px;
`

export const alertContainer = css`
  max-width: 535px;
  font-size: 12px;
  line-height: 1.25;
`

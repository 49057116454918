import { parseAttribute, getImageUrl } from 'services/Orb/Service'
import { parseActivityAbout } from 'core/parsers/activityAbout'
import { parseActivityRules } from 'core/parsers/activityRules'
import { parseAddress } from 'core/parsers/address'
import { activityIdParser } from 'core/parsers/activityId'
import { activityBasicInfoParser } from 'core/parsers/activityBasicInfo'
import { activityComplementaryInfoParser } from 'core/parsers/ActivityComplementaryInfo'

const extractInfo = ({
  title: name,
  id,
  image,
  is_partner: isPartner,
  company: { id: companyId, name: companyName } = {},
  restrictions,
  service_time: duration
}) => ({
  name,
  id,
  image: getImageUrl(image),
  isPartner,
  companyId,
  companyName,
  minAge: restrictions?.min_age,
  duration
})

export const parseServiceList = (list = []) =>
  list.map(parseAttribute(extractInfo))

const parseTransportAddresses = (transport = {}) => {
  const transportInfo = transport.transport_info || {}

  const { transport_stretchs = [] } = transportInfo

  const {
    departure_address: departureAddress,
    arrival_address: arrivalAddress
  } = transport_stretchs[0] || {}

  return {
    originInfo: parseAddress({ address: departureAddress || {} }),
    destinationInfo: parseAddress({ address: arrivalAddress || {} })
  }
}

const transportParser = (transport = {}) => {
  const transportRegistry = activityBasicInfoParser(transport)
  const transportId = activityIdParser(transport)

  const {
    category,
    duration,
    workDuration,
    generalRules,
    infrastructure
  } = parseActivityAbout(transport)

  const transportRules = parseActivityRules(transport)
  const transportComplementaryInfo = activityComplementaryInfoParser(transport)

  const { originInfo, destinationInfo } = parseTransportAddresses(transport)

  const { contacts } = transport

  return {
    transportBase: {
      transportId,
      transportRegistry,
      originInfo,
      destinationInfo,
      ...contacts
    },
    transportAbout: {
      transportAbout: {
        category,
        duration,
        workDuration,
        infrastructure,
        generalRules
      },
      transportRules
    },
    transportComplementaryInfo
  }
}

export const parseTransport = transport => parseAttribute(transportParser)(transport)

const restrictionsParser = (transport = {}) => {
  const transportRules = parseActivityRules(transport)
  const { restrictions } = transportRules

  return {
    ...restrictions
  }
}

export const TransportParser = {
  instance ({ transport = {} }) {
    return transportParser(transport)
  },
  restrictions ({ service = {} }) {
    return restrictionsParser(service)
  },
  list ({ services = [] }) {
    return services.map(extractInfo)
  }
}

import { useCallback, useState, useMemo } from 'react'
import { useToast } from '@bonitour/components'
import { ObservationService } from 'core/services/Observation'

export const useObservation = (reservationTickets, fetchReservationTickets) => {
  const { add: addToast } = useToast()
  const [isObservatioModalVisible, setIsObservatioModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [ticketId, setTicketId] = useState()

  const onCloseObservationModal = useCallback(() => {
    setIsObservatioModalVisible(false)
  }, [])

  const onOpenObservationModal = useCallback((ticketId) => () => {
    setTicketId(ticketId)
    setIsObservatioModalVisible(true)
  }, [])

  const observation = useMemo(() =>
    reservationTickets
      .map(date => date[1]).flat()
      .map(({ tickets }) => tickets).flat()
      .find(({ id }) => id === ticketId)?.observation
  , [reservationTickets, ticketId])

  const updateObservation = useCallback((observation, id = ticketId) => {
    setLoading(ticketId)
    setIsObservatioModalVisible(false)
    ObservationService.update({ ticketId: id, observation }).then(() => {
      observation
        ? addToast('Observação salva com sucesso', 'success')
        : addToast('Observação removida com sucesso', 'success')
    }).catch(() => {
      observation
        ? addToast('Houve um problema ao salvar a observação')
        : addToast('Houve um problema ao remover a observação')
    }).finally(() => {
      fetchReservationTickets().finally(() => setLoading(false))
    })
  }, [addToast, fetchReservationTickets, ticketId])

  return {
    isObservatioModalVisible,
    onCloseObservationModal,
    onOpenObservationModal,
    updateObservation,
    loading,
    observation
  }
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import {
  Button,
  Card,
  Column,
  DatePicker,
  GhostButton,
  HeaderPage,
  Input,
  InputFormGroup,
  InputMoneyMask,
  InputWithSuffix,
  Row,
  Select,
  Textarea,
  useToast,
  Dialog,
  Label
} from '@bonitour/components'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import {
  invoiceBatchReverseTypeMap,
  invoiceBatchTypeMap,
  invoiceBatchTypeTitleMap
} from '../constants/InvoiceBatchType'
import { useQuery } from 'hooks/useQuery'
import { useForm } from '@bonitour/app-functions'
import { invoiceBatchSchema } from '../formSchema/InvoiceBatchForm.schema'
import {
  buttonsContainerCss,
  dateInputCss,
  observationInputCss,
  sendingStateCss,
  dialogLabelCss,
  dialogCardCss,
  dialogContainerCss
} from '../Create/CreateBatch.styles'
import { useCreatePaymentBatch } from '../hooks/useCreatePaymentBatch'
import { useShowPaymentBatch } from '../hooks/useShowPaymentBatch'
import { useEditPaymentBatch } from '../hooks/useEditPaymentBatch'
import { TicketSelector } from '../TicketSelector/TicketSelector'
import { PayBatchForm } from '../Pay/PayBatch'
import { RefundBatchButton } from './RefundBatchButton'
import { ChangeBatchStatusButton } from './ChangeBatchStatusButton'
import InvoiceBatchPayment from '../List/paymentTable/InvoiceBatchPayment'
import { textWrap } from './ShowEdit.styles'
import { unformatMoney, formatMoney } from '@bonitour/common-functions'
import { useTicketsToBatchList } from '../hooks/useTicketsToBatchList'
import { OPEN_STATUS, PAID_STATUS, TO_BE_PAID_STATUS } from 'constants/paymentBatch'

export const InvoicesBatchShowEdit = () => {
  const { type, id } = useParams()

  const {
    ticketsToBatch,
    meta: ticketsToBatchMeta,
    changePagination: emitPaginationEvent,
    loading: isLoadingTicketsToBatch,
    pagination: ticketsToBatchPagination,
    fetchTicketsToBatch
  } = useTicketsToBatchList(type)

  const [{ payment, edit, search = '' }, { removeQueryParam, changeQueryParam }] = useQuery()
  const { push } = useHistory()
  const { add: addToast } = useToast()

  const { loading, paymentBatch, fetchPaymentBatch } = useShowPaymentBatch()

  const { loading: isUpdateLoading, onEditPaymentBatch } = useEditPaymentBatch()

  const [isDialogEnabled, setIsDialogEnabled] = useState(false)

  const toggleTicketWarningDialog = useCallback(() => setIsDialogEnabled((curr) => !curr), [])

  const backToList = useCallback(() => push(`/invoices-batch/${type}${search}`), [push, search, type])
  const changeType = useCallback(
    (newType) => {
      const parsedType = invoiceBatchReverseTypeMap[newType] || null
      if (!parsedType) {
        console.error(`Invalid type ${newType}`)
        return push(`/invoices-batch/${type}${search}`)
      }
      push(`/invoices-batch/${parsedType}/${id}${search}`)
    },
    [id, search, push, type]
  )
  const cancelEdit = useCallback(() => removeQueryParam('edit'), [removeQueryParam])
  const startEdit = useCallback(() => changeQueryParam('edit', 'true'), [changeQueryParam])

  const startPayment = useCallback(() => changeQueryParam('payment', 'true'), [changeQueryParam])

  const { afiliateOptions, externalProvidersOptions } = useCreatePaymentBatch()

  const actionTitle = useMemo(
    () => (payment ? 'Registrar pagamento de' : edit ? 'Editar' : 'Visualizar'),
    [edit, payment]
  )

  const title = useMemo(() => `${actionTitle} fatura em lote`, [actionTitle])

  const defaultForm = useMemo(
    () => ({
      referenceNumber: paymentBatch?.referenceNumber,
      beneficiaryId: paymentBatch?.beneficiaryId,
      date: paymentBatch?.expectedPaymentDate,
      discount: paymentBatch?.discount,
      taxValue: paymentBatch?.taxValue,
      note: paymentBatch?.observation
    }),
    [
      paymentBatch?.beneficiaryId,
      paymentBatch?.discount,
      paymentBatch?.expectedPaymentDate,
      paymentBatch?.observation,
      paymentBatch?.referenceNumber,
      paymentBatch?.taxValue
    ]
  )

  const {
    form,
    errors: formErrors,
    onSubmit,
    utils: { onInputBlur, onInputChange }
  } = useForm(defaultForm, invoiceBatchSchema)

  useEffect(() => {
    if (!Object.keys(invoiceBatchTypeMap).includes(type)) {
      backToList()
    }
  }, [backToList, type])

  useEffect(() => {
    if (paymentBatch?.status !== OPEN_STATUS && edit === 'true') {
      cancelEdit()
    }
  }, [cancelEdit, edit, paymentBatch?.status])

  useEffect(() => {
    if (paymentBatch?.status === 'paid' && payment === 'true') {
      removeQueryParam('payment')
    }
  }, [payment, paymentBatch?.status, removeQueryParam])

  useEffect(() => {
    const expectedType = invoiceBatchTypeMap[type]
    const receivedType = paymentBatch?.batchType

    if (!receivedType) return

    if (expectedType !== receivedType) {
      changeType(receivedType)
    }
  }, [changeType, paymentBatch.batchType, type])

  const resetBatch = useCallback(() => {
    setTimeout(() => {
      if (id) {
        fetchPaymentBatch(id)
      }
    }, 500)
  }, [fetchPaymentBatch, id])

  useEffect(() => {
    resetBatch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, edit, payment])

  const handleSubmit = useCallback(() => {
    const hasPreviousTickets = paymentBatch?.paymentBatchItems?.length > 0
    const currentTickets = form.tickets || []

    if (!currentTickets.length && hasPreviousTickets) {
      return toggleTicketWarningDialog()
    }
    onEditPaymentBatch(id, {
      beneficiaryId: form.beneficiaryId,
      beneficiaryType: type,
      observation: form.note || '',
      referenceNumber: form.referenceNumber,
      expectedPaymentDate: form.date,
      discount: form.discount,
      tickets: form.tickets,
      taxValue: form.taxValue
    }).then(() => {
      addToast('Lote atualizado com sucesso', 'success')
      cancelEdit()
    })
  }, [paymentBatch, form.tickets, form.beneficiaryId, form.note, form.referenceNumber, form.date, form.discount, form.taxValue, onEditPaymentBatch, id, type, toggleTicketWarningDialog, addToast, cancelEdit])

  const submitFn = onSubmit(handleSubmit, () => addToast('Preencha os dados corretamente'))

  const isAllowedToEdit = useMemo(() => {
    const notAllowedStatuses = ['paid', 'partially_paid']
    return !notAllowedStatuses.includes(paymentBatch?.status)
  }, [paymentBatch?.status])

  const isAllowedToChangeBeneficiary = useMemo(() => {
    const notAllowedStatuses = ['paid', 'open', 'partially_paid']
    const notAllowedStatusesForExternalProviders = [...notAllowedStatuses, 'to_be_paid']
    return (
      !notAllowedStatuses.includes(paymentBatch?.status) &&
      (type === 'affiliate' || !notAllowedStatusesForExternalProviders.includes(paymentBatch?.status))
    )
  }, [paymentBatch?.status, type])

  const isAllowedToEditDate = useMemo(() => {
    const notAllowedStatuses = ['paid', 'partially_paid', 'to_be_paid']
    return !notAllowedStatuses.includes(paymentBatch?.status)
  }, [paymentBatch?.status])

  const isAllowedToEditReferenceNumber = useMemo(() => {
    const notAllowedStatuses = ['paid', 'partially_paid']
    return !notAllowedStatuses.includes(paymentBatch?.status)
  }, [paymentBatch?.status])

  const isAllowedToEditDiscount = useMemo(() => {
    const notAllowedStatuses = ['paid', 'partially_paid']
    return !notAllowedStatuses.includes(paymentBatch?.status)
  }, [paymentBatch?.status])

  const canEditForm = useMemo(() => {
    if (!edit && !isAllowedToEdit) return false
    if (edit !== 'true') return false
    if (payment === true || payment === 'true') return false
    if (loading) return false
    if (isUpdateLoading) return false
    return true
  }, [edit, loading, payment, isUpdateLoading, isAllowedToEdit])

  const isAllowedToPay = useMemo(() => {
    const allowedStatuses = ['to_be_paid', 'partially_paid']
    return allowedStatuses.includes(paymentBatch?.status)
  }, [paymentBatch?.status])

  const beneficiaryOptions = useMemo(
    () => (type === 'affiliate' ? afiliateOptions : externalProvidersOptions),
    [afiliateOptions, externalProvidersOptions, type]
  )

  const initiallySelectedTickets = useMemo(
    () =>
      paymentBatch?.paymentBatchItems?.map((ticket) => ({
        ...ticket,
        ticketId: ticket.id
      })),
    [paymentBatch?.paymentBatchItems]
  )

  const isEditing = useMemo(() => edit === 'true' && paymentBatch?.status === 'open', [edit, paymentBatch?.status])

  const selectedTickets = useMemo(() => {
    const ticketList = form?.tickets || []
    const batchTickets = ticketsToBatch || []
    const initialTickets = initiallySelectedTickets

    return ticketList.map((ticketId) => {
      const ticket = batchTickets.find((ticket) => ticket.ticketId === ticketId)

      if (ticket) return ticket

      return initialTickets.find((ticket) => ticket.ticketId === ticketId)
    })
  }, [form?.tickets, initiallySelectedTickets, ticketsToBatch])

  const ticketsValue = useMemo(() => {
    const totalValue = selectedTickets?.reduce((acc, ticket) => {
      return acc + (Number(ticket?.commissionValue || 0) || Number(ticket?.value || 0))
    }, 0)

    if (isEditing) return totalValue

    return paymentBatch?.value || totalValue
  }, [isEditing, paymentBatch?.value, selectedTickets])

  const totalAliquot = useMemo(() => {
    const taxValue = form?.taxValue || 0

    const ticketValue = ticketsValue || paymentBatch?.value || 0

    return (taxValue * ticketValue) / 100
  }, [form?.taxValue, paymentBatch?.value, ticketsValue])

  const isEnabledToViewPaid = useMemo(() => Boolean(paymentBatch.status !== OPEN_STATUS), [paymentBatch.status])

  const historyTotalPaid = useMemo(() => {
    const transactionsList = paymentBatch?.paymentBatchTransactions || []

    return transactionsList.reduce((acc, curr) => {
      if (curr?.status === PAID_STATUS) {
        return acc + Number(curr?.value)
      }
      return acc
    }, 0)
  }, [paymentBatch?.paymentBatchTransactions])

  const paymentsInfo = useMemo(() => {
    const discount = Number(unformatMoney(form?.discount || paymentBatch?.discount))

    const grossValue = ticketsValue

    const aliquot = ((form?.taxValue * grossValue) / 100)

    const liquidValue = (function () {
      const isOpen = paymentBatch?.status === OPEN_STATUS

      if (isOpen) return grossValue - aliquot - discount

      return Number(paymentBatch?.liquidValue)
    }())

    const totalPaid = isEnabledToViewPaid ? historyTotalPaid : 0

    const totalToPaid = liquidValue - historyTotalPaid

    return {
      grossValue,
      liquidValue,
      discount,
      aliquot: totalAliquot,
      totalPaid,
      totalToPaid
    }
  }, [
    form?.discount,
    form?.taxValue,
    historyTotalPaid,
    isEnabledToViewPaid,
    paymentBatch,
    ticketsValue,
    totalAliquot
  ])

  const handleOnChangeTaxValue = useCallback(
    (value) => {
      if (value <= 0) {
        onInputChange('taxValue')(Math.round(0))
      } else {
        onInputChange('taxValue')(Math.round((value || 0) * 100) / 100)
      }
    },
    [onInputChange]
  )

  const isAllowedToCloseBatch = useMemo(() => Boolean(
    paymentBatch?.status === OPEN_STATUS &&
      paymentBatch?.paymentBatchItems &&
      paymentBatch?.paymentBatchItems?.length > 0),
  [paymentBatch?.paymentBatchItems, paymentBatch?.status])

  const showReopenBatch = useMemo(() => Boolean(
    paymentBatch?.status === TO_BE_PAID_STATUS
  ), [paymentBatch?.status])

  const ChangeStatusButton = useMemo(() => (
    <ChangeBatchStatusButton
      backToList={backToList}
      paymentBatchId={paymentBatch?.id}
      batchStatus={paymentBatch?.status}
      disabled={loading || isUpdateLoading}
      hasTickets={Boolean(paymentBatch?.paymentBatchItems?.length)}
      fetchPaymentBatch={() => fetchPaymentBatch(id)}
    />
  ), [backToList, fetchPaymentBatch, id, loading, paymentBatch?.id, paymentBatch?.paymentBatchItems?.length, paymentBatch?.status, isUpdateLoading])

  return (
    <>
      <HeaderPage onBack={backToList} title={title} subtitle={invoiceBatchTypeTitleMap[type]} />

      <Card>
        <div>
          <form>
            <Row>
              <Column phone={12} desktop={3}>
                <InputFormGroup
                  label="Número de referência do lote"
                  css={textWrap}
                  errorMessage={formErrors.referenceNumber}
                >
                  <Input
                    value={form.referenceNumber}
                    onChange={onInputChange('referenceNumber')}
                    onBlur={onInputBlur('referenceNumber')}
                    disabled={!canEditForm || !isAllowedToEditReferenceNumber}
                  />
                </InputFormGroup>
              </Column>

              <Column phone={12} desktop={3}>
                <InputFormGroup
                  label="Data esperada para Pagamento"
                  errorMessage={formErrors.date}
                  css={[dateInputCss, textWrap]}
                >
                  <DatePicker
                    value={form.date}
                    css={dateInputCss}
                    onChange={onInputChange('date')}
                    onBlur={onInputBlur('date')}
                    allowsEmpty
                    disabled={!canEditForm || !isAllowedToEditDate}
                  />
                </InputFormGroup>
              </Column>

              <Column phone={12} desktop={2}>
                <InputFormGroup
                  label={'Alíquota'}
                  errorMessage={formErrors.taxValue}
                  css={textWrap}
                >
                  <InputWithSuffix
                    value={form.taxValue}
                    max={100}
                    min={0}
                    onChange={handleOnChangeTaxValue}
                    onBlur={onInputBlur('taxValue')}
                    disabled={!canEditForm}
                    type={'number'}
                  >
                    %&nbsp;({formatMoney(totalAliquot)})
                  </InputWithSuffix>
                </InputFormGroup>
              </Column>

              <Column phone={12} desktop={2}>
                <InputFormGroup label="Desconto" errorMessage={formErrors.discount} css={textWrap}>
                  <InputMoneyMask
                    value={form.discount}
                    onChange={onInputChange('discount')}
                    onBlur={onInputBlur('discount')}
                    disabled={!canEditForm || !isAllowedToEditDiscount}
                  />
                </InputFormGroup>
              </Column>

              <Column phone={12} desktop={2}>
                <InputFormGroup
                  label={invoiceBatchTypeTitleMap[type]}
                  errorMessage={formErrors.beneficiaryId}
                  css={textWrap}
                >
                  <Select
                    placeholder={`Selecione um ${invoiceBatchTypeTitleMap[type]?.toLowerCase()}`}
                    options={beneficiaryOptions}
                    value={form.beneficiaryId}
                    onChange={onInputChange('beneficiaryId')}
                    disabled={!canEditForm || !isAllowedToChangeBeneficiary}
                  />
                </InputFormGroup>
              </Column>

              <Column phone={12} desktop={12}>
                <InputFormGroup label="Observação" errorMessage={formErrors.note}>
                  <Textarea
                    value={form.note}
                    css={observationInputCss}
                    onChange={onInputChange('note')}
                    onBlur={onInputBlur('note')}
                    disabled={!canEditForm}
                  />
                </InputFormGroup>
              </Column>
            </Row>

            <InvoiceBatchPayment
              paymentBatchTransactions={paymentBatch.paymentBatchTransactions}
              paymentsInfo={paymentsInfo}
            />

            <TicketSelector
              form={form}
              setTickets={onInputChange('tickets')}
              setBeneficiaryId={onInputChange('beneficiaryId')}
              type={type}
              isEditing={isEditing}
              initiallySelectedTickets={initiallySelectedTickets}
              ticketManager={{
                ticketsToBatch,
                emitPaginationEvent,
                fetchTicketsToBatch,
                loading: isLoadingTicketsToBatch,
                ticketsToBatchMeta,
                ticketsToBatchPagination
              }}
            />
          </form>
        </div>

        <PayBatchForm
          paymentBatch={paymentBatch}
          isUnableToPay={!isAllowedToPay}
          totalToPaid={paymentsInfo.totalToPaid}
        />

        {edit === 'true'
          ? (
            <Row className="space_between" css={buttonsContainerCss}>
              <div>
                <GhostButton onClick={cancelEdit}>Cancelar</GhostButton>
                <Button
                  onClick={submitFn}
                  customCss={loading ? [sendingStateCss] : []}
                  disabled={loading || isUpdateLoading}
                >
                  {isUpdateLoading ? 'Salvando' : 'Salvar'}
                </Button>
              </div>

              <RefundBatchButton
                paymentBatchId={paymentBatch?.id}
                batchStatus={paymentBatch?.status}
                disabled={loading || isUpdateLoading}
                transactions={paymentBatch?.paymentBatchTransactions}
                fetchPaymentBatch={() => fetchPaymentBatch(id)}
              />
            </Row>
          )
          : payment !== 'true'
            ? (
              <Row className='space_between' css={buttonsContainerCss}>
                <div>
                  <RefundBatchButton
                    paymentBatchId={paymentBatch?.id}
                    batchStatus={paymentBatch?.status}
                    disabled={loading || isUpdateLoading}
                    transactions={paymentBatch?.paymentBatchTransactions}
                    fetchPaymentBatch={() => fetchPaymentBatch(id)}
                  />

                  {showReopenBatch && (
                    ChangeStatusButton
                  )}

                  {isAllowedToEdit && !showReopenBatch && (
                    <Button onClick={startEdit} customCss={loading ? [sendingStateCss] : []}>
                  Editar lote
                    </Button>
                  )}
                </div>

                {(isAllowedToEdit || isAllowedToPay) && (
                  <div>
                    {isAllowedToCloseBatch && (
                      ChangeStatusButton
                    )}
                    {isAllowedToPay && (
                      <Button onClick={startPayment} customCss={loading ? [sendingStateCss] : []}>
                    Pagar lote
                      </Button>
                    )}
                  </div>
                )}
              </Row>
            )
            : null}
      </Card>
      <Dialog
        onClose={toggleTicketWarningDialog}
        isVisible={isDialogEnabled}
        title="Atenção"
        customContainercss={dialogContainerCss}
      >
        <Card css={dialogCardCss}>
          <Label css={dialogLabelCss}>
            Um lote criado não pode ficar vazio novamente. <br/>
            Caso queira excluir todos os tickets, exclua o lote e crie outro.
          </Label>
        </Card>
      </Dialog>
    </>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Accordion, CollapsableSection, useToast, HeaderPage } from '@bonitour/components'
import { CreateOfflineExperienceInfo } from './CreateOfflineExperienceInfo'
import { useBack } from 'hooks/useBack'
import { useCallback } from 'react'

const PREVIOUS_PATH = '/activities'

export const CreateOfflineExperience = () => {
  const { add: addToast } = useToast()

  const { onSafeBack } = useBack()

  const backToHome = useCallback(() => onSafeBack(PREVIOUS_PATH), [onSafeBack])
  const onAccordionClick = value => value !== 0 && addToast('Preencha as informações básicas')

  return (
    <>
      <HeaderPage onBack={backToHome} title='Experiência Offline' />
      <Accordion value={1} onClick={onAccordionClick}>
        <CollapsableSection indicator={1} title='Informações básicas' topics='Registro / Contato / Endereço'>
          <CreateOfflineExperienceInfo />
        </CollapsableSection>

        <CollapsableSection
          indicator={2}
          title='Informações técnicas'
          topics='Tempo de duração / Tempo de atividade / Nível de dificuldade'
        />

        <CollapsableSection
          indicator={3}
          title='Informações Complementares'
          topics='Descrição / Observações / O que levar / Incluso no transporte / Pagamentos aceitos'
          tooltip='Essas informações serão usadas para informar os viajantes sobre o transpote em plataformas de vendas'
        />
      </Accordion>
    </>
  )
}

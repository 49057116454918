/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { OfflineExperienceComplementaryInfoForm } from 'containers/OfflineExperienceComplementaryInfoForm/OfflineExperienceComplementaryInfoForm'
import { useToast } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { ActivityAdditionInformationService } from 'services/Activity/ActivityAdditionInformation/Service'
import { useCallback, useEffect, useState } from 'react'
import { OfflineExperienceService } from 'services/OfflineExperience/Service'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

export const OfflineExperienceComplementaryInfo = ({
  serviceId = '',
  offlineExperienceComplementaryInfo = {},
  onOfflineExperienceUpdated = identity,
  onBackStep = identity
}) => {
  const [paymentsTypeList, setPaymentsTypeList] = useState([])
  const [whatToTakeList, setWhatToTakeList] = useState([])
  const [whatIsIncludedList, setWhatIsIncludedList] = useState([])
  const { add } = useToast()
  const { push } = useHistory()

  const redirectToOfflineExperienceList = useCallback(() => push('/activities?selectedNav=offlineExperiences'), [push])

  const onValidationError = useCallback(() => {
    add('Preencha corretamente o formulário')
  }, [add])

  const onSuccess = useCallback(async data => {
    try {
      await OfflineExperienceService.updateComplementaryInfo(serviceId, data)
      add('Experiência offline atualizada', 'success')
      onOfflineExperienceUpdated()
      redirectToOfflineExperienceList()
    } catch (error) {
      add('Ocorreu um erro ao adicionar as informações')
    }
  }, [add, onOfflineExperienceUpdated, redirectToOfflineExperienceList, serviceId])

  useEffect(() => {
    ActivityAdditionInformationService.list({ type: 'transport' }).then(({ whatToTakeList, whatIsIncludedList, paymentsTypeList }) => {
      whatToTakeList && setWhatToTakeList(whatToTakeList)
      whatIsIncludedList && setWhatIsIncludedList(whatIsIncludedList)
      paymentsTypeList && setPaymentsTypeList(paymentsTypeList)
    })
  }, [])

  return (
    <OfflineExperienceComplementaryInfoForm
      offlineExperienceComplementaryInfo={offlineExperienceComplementaryInfo}
      paymentsTypeList={paymentsTypeList}
      whatToTakeList={whatToTakeList}
      whatIsIncludedList={whatIsIncludedList}
      onBackClick={onBackStep}
      onSuccess={onSuccess}
      onValidationError={onValidationError}
    />
  )
}

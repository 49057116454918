/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { EditInfoIcon, TooltipOnHover, TrashIcon } from '@bonitour/components'
import { iconSize } from 'containers/Reservation/TicketRow.style'
import { clickable } from 'assets/styles/global'
import { identity } from '@bonitour/common-functions'
import { DELETE_TICKET } from 'containers/Reservation/dialog.states'
import { useMemo } from 'react'
import { ticketStatusDictionary } from 'constants/ticketStatus'

const actionsContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
`

export const ActionsCell = ({
  toggleDialogVisible = identity,
  toggleOfflineTicketEdition = identity,
  isCancelable,
  isOwner,
  isOfflineExperience,
  isCancelableOnlyAsProvider,
  ticketState
}) => {
  const showCancelBtn = useMemo(() => {
    if (ticketState === 'canceled') return false
    return isOfflineExperience || (isOwner && isCancelableOnlyAsProvider) || isCancelable
  }, [isCancelable, isCancelableOnlyAsProvider, isOfflineExperience, isOwner, ticketState])

  const isEditOfflineExperience = useMemo(
    () => [
      ticketStatusDictionary.confirmed.value,
      ticketStatusDictionary.checked_in.value,
      ticketStatusDictionary.reserved.value,
      ticketStatusDictionary.used.value,
      ticketStatusDictionary['no-show'].value,
      ticketStatusDictionary.canceled.value
    ].includes(ticketState) && isOfflineExperience
    ,
    [isOfflineExperience, ticketState]
  )

  const showEditBtn = useMemo(
    () => isEditOfflineExperience,
    [isEditOfflineExperience]
  )

  return (
    <div css={actionsContainer}>
      {showEditBtn && (
        <TooltipOnHover text="Editar ingresso">
          <EditInfoIcon css={[iconSize, clickable]} onClick={toggleOfflineTicketEdition} />
        </TooltipOnHover>
      )}
      {showCancelBtn && (
        <TooltipOnHover text="Cancelar ingresso">
          <TrashIcon css={[iconSize, clickable]} onClick={() => toggleDialogVisible(DELETE_TICKET)} />
        </TooltipOnHover>
      )}
    </div>
  )
}

import { head, tail } from '@bonitour/common-functions'
import { useCallback } from 'react'
import { useHistory } from 'react-router'
import { useQuery } from './useQuery'
import { SafeDate } from '@binamik/js-providers'

export const useParamsFilter = () => {
  const history = useHistory()
  const [params] = useQuery()

  const pushQueryParams = useCallback((filters, history) => {
    const queryParams = Object.entries(filters).reduce((acc, item) => {
      const key = head(item)
      const value = tail(item)

      if (!value) {
        return acc
      }

      if (Array.isArray(value)) {
        return acc.concat(
          value.reduce(
            (acc, item) => acc.concat(`${key}[]=${item}&`)
            , ''
          )
        )
      }

      if (value instanceof Date) {
        return acc.concat(`${key}=${new SafeDate(value).isoDate}&`)
      }

      return acc.concat(`${key}=${value}&`)
    }, '')

    history.push({ search: `?${queryParams.slice(0, -1)}` })
  }, [])

  const changeParams = (filters) => pushQueryParams(filters, history)

  return [
    params,
    changeParams
  ]
}

import { orbCore } from 'services/Orb/Service'
import { EmployeeModel } from '../models/employees'
import { EmployeesParser } from '../parsers/employees'

const { employeesDomain } = orbCore

export const EmployeesService = {
  listEmployeesbyCompany (companyId) {
    return employeesDomain
      .getEmployeeListByCompanyId(EmployeeModel.listAllByCompanyId(companyId))
      .then(EmployeesParser.list)
  }
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { identity } from '@bonitour/common-functions'
import { Button, Card, Column, DatePicker, DocumentInput, Email, GhostButton, InfoSwatch, Input, InputFormGroup, Label, PhoneSelector, Row, Select, useToast, InputWeightMask, InputHeightMask, BREAK_POINTS } from '@bonitour/components'
import { useForm } from '@bonitour/app-functions'
import { passengerSchema } from './PassengerForm.schema'
import { margin, marginTop, width } from 'assets/styles/global'
import { useAddressLocation } from 'containers/Address/Address.hooks'

const noMargin = margin(0, 0, 0, 0)
const marginTop40 = marginTop(40)
const fullWidth = width(100)

const inputStyle = css`
  width: 110px;
  height: 50px;
  margin-right: 10;
`

const padding20 = css`
  padding: 20px;
`

const buttonsContainer = css`
  margin-top: 30px;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    display: flex;
    flex-direction: column;
  }
`

const backButton = css`
  margin-right: 20px;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    margin-bottom: 10px;
    margin-right: 0;
  }
`

const availableDocuments = [
  {
    value: 'Cpf',
    label: 'CPF'
  },
  {
    value: 'Passport',
    label: 'Passaporte'
  },
  {
    value: 'Other',
    label: 'Outro'
  }
]

export const PassengerForm = ({
  passenger = {},
  isPersonalFieldsDisabled = false,
  onClose: emitClose = identity,
  onBack: emitClickBack = identity,
  onSubmit: emitSubmit = identity
}) => {
  const { add: addToast } = useToast()
  const { isDependent } = passenger
  const [countrySelected, setCountrySelected] = useState('')
  const [stateSelected, setStateSelected] = useState('')
  const { countries, states, cities, updateAvailableStates, updateAvailableCities } = useAddressLocation(addToast, countrySelected, stateSelected)
  const schema = useMemo(() => passengerSchema(states, cities), [states, cities])
  const { form, errors, onSubmit, utils: { onInputBlur, onInputChange } } = useForm(passenger, schema)
  const { fullName = '', emergencyName = '', email = '', birthDate = '', documentType, document, phone, emergencyTelephone, weight, height, country, state, city } = form

  const onCountryChange = useCallback((countryValue) => {
    const country = countries.find((country) => Object.values(country).includes(countryValue))
    if (country) {
      setCountrySelected(country.value)
      updateAvailableStates(country.value)
      onInputChange('country')(country.value)
    } else {
      setCountrySelected('')
      updateAvailableStates(null)
      onInputChange('country')('')
      onInputChange('state')('')
      onInputChange('city')('')
    }
  }, [countries, updateAvailableStates, onInputChange])

  const onStateChange = useCallback((stateValue) => {
    const state = states.find((state) => Object.values(state).includes(stateValue))
    if (state) {
      setStateSelected(state.value)
      updateAvailableCities(country, state.value)
      onInputChange('state')(state.value)
    } else {
      setStateSelected('')
      updateAvailableCities(null)
      onInputChange('state')('')
      onInputChange('city')('')
    }
  }, [states, updateAvailableCities, country, onInputChange])

  const onValidationError = () => addToast('Preencha corretamente o formulário')

  const onPassengerSuccess = passenger => {
    const { fullName, email } = passenger
    if (!fullName) addToast('O nome é obrigatório')
    if (!email) addToast('O e-mail é obrigatório')
    if (Boolean(fullName) && Boolean(email)) {
      emitSubmit(passenger)
      emitClose()
    }
  }

  const onClickSubmit = onSubmit(onPassengerSuccess, onValidationError)

  useEffect(() => {
    country && updateAvailableStates(country)
    country && state && updateAvailableCities(country, state)
  }, [country, state, updateAvailableStates, updateAvailableCities])

  return (
    <>
      <Row>
        <Column phone={12} desktop={6}>
          <InputFormGroup label='Nome completo' errorMessage={errors.fullName} required>
            <Input
              value={fullName}
              onChange={onInputChange('fullName')}
              onBlur={onInputBlur('fullName')}
              placeholder='Digite o nome completo'
              disabled={isPersonalFieldsDisabled}
              required
            />
          </InputFormGroup>
        </Column>

        <Column phone={12} desktop={6}>
          <InputFormGroup errorMessage={errors.email} label='E-mail' required={!isDependent}>
            <Email
              value={email}
              onChange={onInputChange('email')}
              onBlur={onInputBlur('email')}
              placeholder='Digite o e-mail'
              required={!isDependent}
              disabled={isPersonalFieldsDisabled || isDependent}
            />
          </InputFormGroup>
        </Column>
      </Row>

      <Row>
        <Column phone={12} desktop={4}>
          <InputFormGroup label='Data de nascimento' errorMessage={errors.birthDate}>
            <DatePicker
              css={fullWidth}
              value={birthDate}
              onChange={onInputChange('birthDate')}
              onBlur={onInputBlur('birthDate')}
              placeholder='Selecione ou digite uma data'
              disabled={isPersonalFieldsDisabled}
            />
          </InputFormGroup>
        </Column>

        <Column phone={12} desktop={4}>
          <InputFormGroup
            label='Documento' errorMessage={errors.document} id='document'
          >
            <DocumentInput
              css={fullWidth}
              documentType={documentType}
              documentValue={document}
              onDocumentChange={onInputChange('documentType')}
              onDocumentBlur={onInputBlur('documentType')}
              onDocumentValueChange={onInputChange('document')}
              onDocumentValueBlur={onInputBlur('document')}
              availableDocuments={availableDocuments}
              placeholder='Digite o documento'
              disabled={isPersonalFieldsDisabled}
            />
          </InputFormGroup>
        </Column>

        <Column phone={12} desktop={4}>
          <InputFormGroup label='Telefone' errorMessage={errors.phone}>
            <PhoneSelector
              customCss={[fullWidth]}
              value={phone}
              onChange={onInputChange('phone')}
              onBlur={onInputBlur('phone')}
              disabled={isPersonalFieldsDisabled}
            />
          </InputFormGroup>
        </Column>
      </Row>

      <Row>
        <Column phone={12} desktop={4}>
          <InputFormGroup label='Nome do contato de emergência' errorMessage={errors.emergencyName}>
            <Input
              value={emergencyName}
              onChange={onInputChange('emergencyName')}
              onBlur={onInputBlur('emergencyName')}
              placeholder='Digite o nome completo do contato de emergência'
              disabled={isPersonalFieldsDisabled}
              required
            />
          </InputFormGroup>
        </Column>

        <Column phone={12} desktop={4}>
          <InputFormGroup label='Telefone do contato de emergência' errorMessage={errors.emergencyTelephone}>
            <PhoneSelector
              customCss={[fullWidth]}
              value={emergencyTelephone}
              onChange={onInputChange('emergencyTelephone')}
              onBlur={onInputBlur('emergencyTelephone')}
              disabled={isPersonalFieldsDisabled}
            />
          </InputFormGroup>
        </Column>

        <Column phone={6} desktop={2}>
          <InputFormGroup label='Altura' errorMessage={errors.height}>
            <InputHeightMask value={height} css={[inputStyle, fullWidth]} onChange={onInputChange('height')} />
          </InputFormGroup>
        </Column>

        <Column phone={6} desktop={2}>
          <InputFormGroup label='Peso' errorMessage={errors.weight}>
            <InputWeightMask value={weight} css={[inputStyle, fullWidth]} onChange={onInputChange('weight')} />
          </InputFormGroup>
        </Column>
      </Row>

      <Card css={[marginTop40, padding20]}>
        <Row css={noMargin}>
          <Column phone={12} desktop={12}>
            <Label>Em qual local o viajante reside atualmente?</Label>
            <InfoSwatch
              size={300}
              tooltip='Essa informação pode ser necessária para que o canal de vendas informe aos meios de transporte (rodoviário, náutico, aéreo, etc) seu atual local de residência'
            />
          </Column>
        </Row>

        <Row>
          <Column phone={12} desktop={4}>
            <InputFormGroup label='Pais' errorMessage={errors.country}>
              <Select
                options={countries}
                value={country}
                onChange={onCountryChange}
                onBlur={onInputBlur('country')}
                placeholder='Selecione ou digite o país'
                disabled={isPersonalFieldsDisabled}
              />
            </InputFormGroup>
          </Column>

          <Column phone={12} desktop={4}>
            <InputFormGroup label='Estado' errorMessage={errors.state}>
              <Select
                options={states}
                value={state}
                onChange={onStateChange}
                onBlur={onInputBlur('state')}
                placeholder='Selecione ou digite o estado'
                disabled={isPersonalFieldsDisabled}
              />
            </InputFormGroup>
          </Column>
          <Column phone={12} desktop={4}>
            <InputFormGroup label='Cidade' errorMessage={errors.city}>
              <Select
                options={cities}
                value={city}
                onChange={onInputChange('city')}
                onBlur={onInputBlur('city')}
                placeholder='Selecione ou digite a cidade'
                disabled={isPersonalFieldsDisabled}
              />
            </InputFormGroup>
          </Column>
        </Row>
      </Card>

      <Row css={buttonsContainer}>
        <GhostButton css={backButton} onClick={emitClickBack}>Voltar</GhostButton>
        <Button onClick={onClickSubmit}>Salvar</Button>
      </Row>
    </>
  )
}

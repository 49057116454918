import { BREAK_POINTS } from '@bonitour/components'
import { css } from '@emotion/core'
import { marginTop } from 'assets/styles/global'

export const marginTop10 = marginTop(10)

export const iconSize = css`
  font-size: 23px;
`

export const sameLineInput = css`
  margin: 0 10px;
`

export const width150 = css`
  max-width: 150px;
`

export const width220 = css`
  max-width: 220px;
`

export const rowMargin = css`
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }
`

export const flexColumn = css`
  @media (max-width: ${BREAK_POINTS.tablet}) {
    flex-direction: column;
  }
`

/* eslint-disable camelcase */
import { replaceCommaToDot, unformatMoney } from '@bonitour/common-functions'
import { addressModel } from 'core/models/address'
import { contactsModel } from 'core/models/contact'
import { deepGetOrElse } from 'deep-getter'

export function OfflineExperienceModel (data, companyId) {
  const { serviceId = {}, addressInfo = {}, phones = [], emails = [], sellingDeadline = 0 } = data
  const address = addressModel(addressInfo)
  const contacts = contactsModel({ emails, phones })
  const image = deepGetOrElse(serviceId, 'files.0.file', null)
  const { name: title = '', offlineProviderId: offline_provider_id = '', category = '', defaultValue: default_value = 0, defaultNetValue: default_net_value = 0 } = serviceId

  const basePayload = {
    offline_experience: {
      title,
      selling_deadline: sellingDeadline,
      company_id: companyId,
      image,
      document_type: 'cnpj',
      contacts,
      enabled: true,
      ally_visible: false,
      xpert_visible: false
    },
    offline_experience_info: {
      offline_provider_id: offline_provider_id || undefined,
      category,
      default_value: unformatMoney(default_value),
      default_net_value: unformatMoney(default_net_value)
    },
    address
  }

  if (offline_provider_id !== '') {
    basePayload.offline_experience_info.offline_provider_id = offline_provider_id
  }

  return basePayload
}

export function OfflineExperienceAboutModel (offlineExperienceData) {
  const { serviceAbout = {}, serviceRules = {} } = offlineExperienceData

  const about = aboutModel(serviceAbout)
  const rules = rulesModel(serviceRules)

  return {
    offline_experience: {
      ...about,
      ...rules
    }
  }
}

export const aboutModel = (offlineExperienceAbout = {}) => {
  const {
    duration,
    workDuration,
    infrastructure,
    generalRules,
    category,
    difficultyLevel,
    travelerInfoRequired
  } = offlineExperienceAbout

  return {
    service_time: duration,
    employee_time: workDuration,
    infrastructure,
    category_id: category,
    difficulty_level: difficultyLevel,
    traveler_info_required: travelerInfoRequired,
    general_rules: generalRules
  }
}

const rulesModel = (offlineExperienceRules = {}) => {
  const { minAge, minHeight, maxWeight } = offlineExperienceRules.restrictions || {}
  const minHeightNormalized = replaceCommaToDot(String(minHeight))
  const maxWeightNormalized = replaceCommaToDot(String(maxWeight))

  return {
    restrictions: {
      min_age: Number(minAge),
      min_height: String(minHeightNormalized),
      max_weight: String(maxWeightNormalized)
    }
  }
}

export const offlineExperienceComplementaryInfoModel = (offlineExperienceComplementaryInfo = {}) => {
  const {
    description,
    paymentsTypes,
    whatToTake,
    whatIsIncluded,
    observation,
    isAllyVisible,
    sellingDeadline
  } = offlineExperienceComplementaryInfo

  return {
    description: JSON.stringify({
      'pt-br': description || ''
    }),
    observation,
    payment_types: paymentsTypes,
    what_to_take: whatToTake,
    what_is_included: whatIsIncluded,
    ally_visible: isAllyVisible,
    selling_deadline: sellingDeadline
  }
}

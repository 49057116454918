import { number, string } from 'yup'

export const validationSchema = {
  name: string(),
  activitiesNumber: number(),
  activityCost: string(),
  orbSellerTax: string(),
  orbVendorTax: string(),
  xpertSellerTax: string(),
  xpertVendorTax: string(),
  payVendorTax: string(),
  paySellerTax: string(),
  expirationDate: string(),
  hiringDate: string(),
  monthlyPrice: string()
}

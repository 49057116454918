/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { ReservationTimelineCard } from './ReservationTimelineCard'
import { ReservationTimelineChain } from './ReservationTimelineChain'
import { useCallback, useMemo } from 'react'
import { reservationTimelineActionTypes } from './ReservationTimeline.constant'

const { RESERVATION, TICKET } = reservationTimelineActionTypes

/**
  * @typedef {Object} timelineItem
* @property {string} type
* @property {string} ticketPaid
* @property {string} ticketCreated
* @property {string} paymentMethod
* @property {string} linkedTickets
* @property {number} value
* @property {number} valueForRefund
* @property {string} createdTickets
* @property {string} autDoc
* @property {string} reservationCode
* @property {string} originBankAccount
* @property {string} event
* @property {string[]} fieldsUpdated
* @property {string} id
* @property {string} reason
* @property {string} acquirerName
* @property {string} destinationBankAccount
* @property {boolean} isApproved
* @property {string} fromTicket
* @property {string} toTicket
* @property {string} paymentMean
* @property {string} brand
* @property {string} customVendorName
*/

/**
 * @type {React.FC<{timeline: timelineItem[], chainLength?: number}>}
 */
export const ReservationTimeline = ({ timeline = [], chainLength = 7 }) => {
  const filteredTimeline = useMemo(() => timeline
    .filter((timelineAction) => {
      // The QA has requested that the timeline not display the RESERVATION and TICKET actions for now
      return ![RESERVATION, TICKET].includes(timelineAction.type)
    }), [timeline])

  const hideChain = useCallback(
    (idx) => filteredTimeline.length - 1 === idx,
    [filteredTimeline]
  )

  return filteredTimeline.map((item, idx) => (
    <ReservationTimelineChain
      date={item.date}
      type={item.type}
      register={item.userName}
      key={`full-timeline-reservation-chain-${idx}`}
      chainLength={hideChain(idx) ? 0 : chainLength}
    >
      <ReservationTimelineCard cardInfo={item} />
    </ReservationTimelineChain>
  ))
}

import { orbCore } from 'services/Orb/Service'
import { ExternalProviderParser } from 'core/parsers/externalProvider'
import { ExternalProviderModel } from 'core/models/externalProvider'

const { externalProviderDomain } = orbCore

export const ExternalProviderService = {
  options () {
    return externalProviderDomain.list().then(ExternalProviderParser.options)
  },
  list ({ filters, pagination }) {
    return externalProviderDomain.list(...ExternalProviderModel.list({ filters, pagination })).then(ExternalProviderParser.list)
  },
  getById (externalProviderId) {
    return externalProviderDomain.get(externalProviderId).then(ExternalProviderParser.single)
  },
  create (externalProvider) {
    return externalProviderDomain.create(ExternalProviderModel.single(externalProvider))
  },
  update (externalProvider, externalProviderId) {
    return externalProviderDomain.update(ExternalProviderModel.single(externalProvider), externalProviderId)
  }
}

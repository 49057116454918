import { apiPaymentMethods } from 'constants/paymentMethods'
import { number, string, ref, date, array } from 'yup'

const validateAutDoc = (value, ctx) => {
  const { method, acquirer } = ctx.parent
  const isPixAndHasAcquirer = (method === apiPaymentMethods.pix) && Boolean(acquirer)

  if ([apiPaymentMethods.credit, apiPaymentMethods.debit].includes(method) || isPixAndHasAcquirer) {
    return Boolean(value)
  }

  return true
}

const bankAccountSchema = string()
  .nullable()
  .test(
    'is-transfer-bank-or-boleto',
    'Informe a conta bancária',
    function (value, ctx) {
      const { method } = ctx.parent

      const methodsRequiringBankAccount = [
        apiPaymentMethods?.bank_billet, apiPaymentMethods?.bank_transfer
      ]

      const isIncluded = methodsRequiringBankAccount.includes(method)

      if (isIncluded && !value) {
        return false
      }

      return true
    }
  )

export const paymentSchema = {
  aut: string().nullable().test('aut', 'Preencha o campo Aut', validateAutDoc),
  doc: string().nullable().test('doc', 'Preencha o campo Doc', validateAutDoc),
  method: string().required('Escolha uma forma de pagamento'),
  installments: number().nullable()
    .typeError('Deve ser um número')
    .test('installments', 'As parcelas devem estar presentes', function (installments) {
      if (this.resolve(ref('method')) === 'credit') {
        return Boolean(installments)
      }
      return true
    }),
  acquirer: string().nullable()
    .test('acquirer', 'Adquirente obrigatório', function (acquirer, ctx) {
      const { method: paymentMethod } = ctx.parent

      if (paymentMethod === apiPaymentMethods.debit) {
        return Boolean(acquirer)
      }

      return true
    }),
  bankAccount: bankAccountSchema,
  vendorId: string().nullable(),
  customVendorName: string().nullable(),
  day: date().nullable(),
  time: string().nullable()
}

export const refundSchema = {
  aut: string()
    .when('method', {
      is: apiPaymentMethods.credit,
      then: string().required('O aut é obrigatório.').typeError('O aut é obrigatório.'),
      otherwise: string().nullable()
    }),
  doc: string()
    .when('method', {
      is: apiPaymentMethods.credit,
      then: string().required('O doc é obrigatório.').typeError('O doc é obrigatório.'),
      otherwise: string().nullable()
    }),
  method: string().required(),
  acquirer: string().nullable()
    .test('acquirer', 'Um adquirente deve ser selecionado', function (acquirer) {
      if (
        this.resolve(ref('method')) === apiPaymentMethods.debit ||
        this.resolve(ref('method')) === apiPaymentMethods.credit) {
        return Boolean(acquirer)
      }
      return true
    }),
  bankAccount: bankAccountSchema,
  day: date().nullable(),
  time: string().nullable(),
  installments: string().nullable()
    .test('is-credit-and-has-acquirer', 'Selecione um adquirente e o parcelamento.', (value, ctx) => {
      const { method, acquirer } = ctx.parent

      const isCredit = method === apiPaymentMethods.credit
      const hasAcquirer = Boolean(acquirer)

      if (!isCredit) return true

      if (isCredit && hasAcquirer) {
        return Boolean(value)
      }

      return false
    }),
  upload: array()
}

export const linkSchema = {
  maxInstallments: number()
}

export const linkMultipontoSchema = {
  maxInstallments: number(),
  vendorId: string().required('Informe o fornecedor multiponto')
}

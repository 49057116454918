import moment from 'moment'
import { deepGetOrElse } from 'deep-getter'
import { formatISOTime } from 'utils/time'
import { head } from '@bonitour/common-functions'
import { parseDecimal } from 'utils/number'
import { apiPaymentMethods, bankAccountPaymentMethods } from 'constants/paymentMethods'

export const refundModel = (
  {
    value = '0',
    ticketsIds,
    upload,
    method,
    installments,
    id,
    day,
    time,
    bankAccount: origin_bank_account_id,
    aut,
    doc,
    acquirer
  },
  isEditing = false
) => {
  const isNonBankAccountMethod = method === apiPaymentMethods.cash || !bankAccountPaymentMethods.includes(method)
  const hasAcquirer = Boolean(acquirer)
  const isPixAndHaveAcquirer = method === apiPaymentMethods.pix && hasAcquirer

  if (isNonBankAccountMethod || isPixAndHaveAcquirer) {
    origin_bank_account_id = isEditing ? '' : undefined
    installments = 1
  }

  if (!hasAcquirer) {
    acquirer = isEditing ? '' : undefined
    aut = ''
    doc = ''
  }

  return {
    id,
    value: parseDecimal(value),
    refund_date: day
      ? `${formatISOTime(day, 'YYYY-MM-DD', false)}${time && ` ${time}`}`
      : formatISOTime(moment(), 'YYYY-MM-DD HH:mm:ss', false),
    tickets: ticketsIds.map((ticket) => ticketsToRefund(ticket)),
    receipt: deepGetOrElse(upload, '0.file', null),
    refund_method: method,
    installments,
    origin_bank_account_id,
    aut,
    doc,
    acquirer_id: acquirer
  }
}

export const refundPayModel = (companyId, userId, { ticketsIds, upload }) => ({
  company_id: companyId,
  user_id: userId,
  tickets_ids: ticketsIds.map((ticket) => ticket.id),
  receipt: deepGetOrElse(upload, '0.file', null)
})

export const ticketsToRefund = ({ id, value, companyId, serviceId, ticketPaymentBalance, amount }) => {
  const { alreadyPaidTicketValue } = head(ticketPaymentBalance) || {}

  return {
    ticket_id: id,
    price: value,
    company_id: companyId,
    service_id: serviceId,
    amount: parseDecimal(amount) ?? parseDecimal(alreadyPaidTicketValue)
  }
}

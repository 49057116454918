/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { AngleLeftIcon, AngleRightIcon, Card, CircleThumbnail, DatePicker, EyeIcon, flexCenteredSpaceBetweenRow, GhostCircleButton, HeaderPage, TableBody, TableBodyCell, TableBodyRow, TableContainer, TableHeader, TableHeaderCell, TableHeaderRow, TooltipOnHover, TruncateCell } from '@bonitour/components'
import { removeLinkProperties, countSwatch, dateSelectorContainer, circleButton, eyeIcon, alignCenter, selectorPadding, tableContainer } from './ActivityDailyMonitoring.style'
import { useDailyMonitoring } from './hooks/useDailyMonitoring'
import { reservationStatusDictionary } from 'constants/reservationsStatus'
import { identity } from '@bonitour/common-functions'
import { NavLink, useHistory } from 'react-router-dom'
import { capitalize, circleColor } from 'assets/styles/global'
import { useActivity } from 'contexts/Activity'
import { useCallback, useMemo } from 'react'
import { ActivitySelector } from 'containers/Activity/Selector/Selector'
import moment from 'moment'
import { OFFLINE_EXPERIENCE_TYPE, TRANSPORT_TYPE } from 'constants/activityTypes'

const ActivityDailyMonitoringTableRow = ({ data = {} }) => {
  const { hour, reservationId, reservationCode, client, partner, ticketCount, status } = data
  const { label: statusLabel = '', color: statusColor } = reservationStatusDictionary[status] || {}

  return (
    <TableBodyRow>
      <TableBodyCell>{hour}</TableBodyCell>
      <TableBodyCell>{reservationCode}</TableBodyCell>
      <TruncateCell text={client} size='200px' />
      <TruncateCell css={capitalize} text={partner} size='100px' />
      <TableBodyCell>{ticketCount}</TableBodyCell>
      <TableBodyCell>
        {Boolean(statusLabel) && <CircleThumbnail size={10} css={circleColor(statusColor)} />}
        {statusLabel}
      </TableBodyCell>
      <TableBodyCell fixed>
        <TooltipOnHover text='Visualizar reserva'>
          <NavLink to={`/reservation/${reservationId}`} css={removeLinkProperties}>
            <EyeIcon css={eyeIcon} />
          </NavLink>
        </TooltipOnHover>
      </TableBodyCell>
    </TableBodyRow>
  )
}

const ActivityDailyMonitoringTable = ({ tableData = [], loading }) => {
  const ticketsCount = tableData.reduce((total, data) => total + data.ticketCount, 0)

  return (
    <div css={tableContainer}>
      <TableContainer>
        <TableHeader>
          <TableHeaderRow>
            <TableHeaderCell>Horário</TableHeaderCell>
            <TableHeaderCell>Cod. da reserva</TableHeaderCell>
            <TableHeaderCell>Cliente</TableHeaderCell>
            <TableHeaderCell>Parceiro</TableHeaderCell>
            <TableHeaderCell css={flexCenteredSpaceBetweenRow}>
              <span>Viajantes</span>
              <span css={countSwatch}>{ticketsCount}</span>
            </TableHeaderCell>
            <TableHeaderCell>Status</TableHeaderCell>
            <TableHeaderCell css={alignCenter} fixed>Ações</TableHeaderCell>
          </TableHeaderRow>
        </TableHeader>
        <TableBody loading={loading}>
          {tableData.map(data => <ActivityDailyMonitoringTableRow key={data.reservationCode} data={data} />)}
        </TableBody>
      </TableContainer>
    </div>
  )
}

const DateSelector = ({ date, onDateChange = identity }) => {
  const onClickLeft = () => {
    onDateChange(moment(date).subtract(1, 'days'))
  }

  const onClickRight = () => {
    onDateChange(moment(date).add(1, 'days'))
  }

  return (
    <div css={dateSelectorContainer}>
      <GhostCircleButton css={circleButton} onClick={onClickLeft}>
        <AngleLeftIcon />
      </GhostCircleButton>

      <GhostCircleButton css={circleButton} onClick={onClickRight}>
        <AngleRightIcon />
      </GhostCircleButton>

      <DatePicker value={date} onChange={onDateChange} />
    </div>
  )
}

export const ActivityDailyMonitoring = () => {
  const { date, setDate, tableData, loading } = useDailyMonitoring()
  const { id: activityId, activity: { type } } = useActivity()
  const { push } = useHistory()

  const activityDashboardLink = useMemo(() => {
    if (type === TRANSPORT_TYPE) {
      return `/transport/${activityId}/dashboard`
    }

    if (type === OFFLINE_EXPERIENCE_TYPE) {
      return `/offline-experience/${activityId}/dashboard`
    }

    return `/activity/${activityId}/dashboard`
  }, [activityId, type])

  const redirectToDashboard = useCallback(() => push(activityDashboardLink), [activityDashboardLink, push])

  return (
    <>
      <HeaderPage onBack={redirectToDashboard} title='Acompanhamento Diário'>
        <ActivitySelector css={selectorPadding} subdomain='daily-monitoring' />
      </HeaderPage>

      <Card>
        <DateSelector date={date} onDateChange={setDate} />
        <ActivityDailyMonitoringTable tableData={tableData} loading={loading} />
      </Card>
    </>
  )
}

import { colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const moneyInput = css`
  width: 160px;
`

export const popoverPositionStyle = css`
  width: 300px;
  padding: 20px;
  top: -270px !important;
  left: -165px !important;
`

export const valueWithPercentage = css`
  position: relative;
  p {
    font-size: 11px;
    position: absolute;
    pointer-events: none;
    right: 55px;
    bottom: 23px;
    text-align: right;
    color: ${colors.gray5};
  }
`

export const overpayingDialogCss = css`
  max-width: 1100px;
  max-height: 95vh;
  color: ${colors.gray1};
  > div:nth-of-type(2) {
    overflow: auto;
    display: block;
    > span {
      min-width: 1000px;
      max-width: 1000px;
    }
    > div {
      justify-content: flex-start;
    }
  }
  p:not(table p) {
    margin: 10px 0;
  }
  td:last-of-type, th:last-of-type {
    color: ${colors.orange1};
    font-weight: 800;
  }
`

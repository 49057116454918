/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useToast } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'

import { TransportBaseForm } from 'containers/TransportBaseForm/TransportBaseForm'
import { TransportService } from 'services/Transport/Service'
import { useActivity } from 'contexts/Activity'
import { useTransportForm } from 'containers/TransportBaseForm/useTransportForm'

export const EditTransportInfo = ({ transportBase = {}, onTransportUpdated = identity, onNextStep = identity }) => {
  const { id: serviceId, updateList } = useActivity()
  const { add: addToast } = useToast()

  const onSuccess = async data => {
    try {
      await TransportService.updateMainTransport(serviceId, data)
      addToast('Transporte atualizado', 'success')
      onTransportUpdated()
      updateList(true)
      onNextStep()
    } catch (error) {
      addToast('Ocorreu um erro na atualização do seu transporte')
    }
  }

  const transportFormProps = useTransportForm({ transportBase, onSuccess, addToast })

  return (
    <TransportBaseForm
      {...transportFormProps}
    />
  )
}

import { useTransactionsProvider } from 'domains/Reservation/Transactions/hooks/useTransactionsProvider'
import { useCallback, useEffect, useState } from 'react'

export const useCheckIsRefund = (reservationId) => {
  const { getPartnershipTicketsToRefund } = useTransactionsProvider()
  const [isRefund, setIsRefund] = useState(false)

  const checkIsRefund = useCallback(async () => {
    const tickets = await getPartnershipTicketsToRefund(reservationId)
    setIsRefund(tickets?.length > 0)
  }, [getPartnershipTicketsToRefund, reservationId])

  useEffect(() => {
    checkIsRefund()
  }, [checkIsRefund])

  return {
    isRefund,
    checkIsRefund
  }
}

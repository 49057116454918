/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { TableBody, TableContainer } from '@bonitour/components'
import { ReservationVisualizedTableRow } from './ReservationVisualizedTableRow'
import { ReservationVisualizedTableHeader } from './ReservationVisualizedTableHeader'

/**
 * Renders a table to display visualized reservations.
 * @component
 * @param {Object[]} visualizedReservations - An array of reservation visualized reservations.
 * @param {boolean} isLoading - A boolean to indicate if the data is loading.
 * @returns {JSX.Element} - The rendered ReservationsVisualizedTable component.
 */
export const ReservationsVisualizedTable = ({
  visualizedReservations = [],
  isLoading = false
}) => {
  return (
    <TableContainer>
      <ReservationVisualizedTableHeader/>
      <TableBody loading={isLoading}>
        {visualizedReservations.map((reservation) => {
          return <ReservationVisualizedTableRow key={reservation.id} reservation={reservation} />
        })}
      </TableBody>
    </TableContainer>
  )
}

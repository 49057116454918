import { colors } from '@bonitour/components'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

export const iconStyle = css`
  font-size: 23px;
  color: ${colors.gray3};
  margin: 0 5px;
`

export const optionsIcon = css`
  font-size: 22px;
`

export const defaultCellPadding = css`
  width: 100px;
  padding: 0 0 0 20px;
`

export const ActionsContainer = styled.div`
  width: 100%;

  .actions__icon {
    display: flex;
    justify-content: center;
    width: 44px;
    height: 44px;

    :hover {
      cursor: pointer;
      color: ${colors.primary};
      background-color: rgba(77, 77, 255, 0.2);
      border-radius: 50%;
    }
  }
`

import { css } from '@emotion/core'
import { colors } from '@bonitour/components'
import { marginTop } from 'assets/styles/global'

export const marginTop10 = marginTop(10)

export const rowOffset = css`
  margin-top: 30px;
`
export const cardSpacing = css`
  margin-bottom: 20px;
  padding: 15px 30px 20px;
`
export const userFormSection = css`
  display: flex;
  background-color: ${colors.gray13};
  border-radius: 10px;
  padding: 0px 20px 20px;
  margin-top: 30px;
`
export const inputOffset = css`
  margin-right: 20px;
`
export const actionRow = css`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;

  button {
    padding-right: 30px;
    padding-left: 30px;
    margin: 10px;
  }
`

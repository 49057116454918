/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { AvatarCard, colors, Swatch, Button, BREAK_POINTS } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'

const card = css`
  width: 100%;
  padding: 20px;
  overflow: hidden;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    max-width: 155px;
  }
`

const swatchInline = css`
  display: inline-block;
  margin-right: 10px;
`

const buttonStyle = css`
  background: ${colors.primaryVariant};
  width: 100%;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  margin-top: auto;
`

const ActiveComponent = () => {
  return (
    <div>
      <Swatch size={10} color={colors.green3} css={swatchInline} />
      Ativo
    </div>
  )
}

export const SellerCard = ({ group = {}, name, image, active, onAddSeller = identity }) => {
  const { name: groupName } = group
  return (
    <AvatarCard css={card} image={image} title={name} description={groupName}>
      {active ? <ActiveComponent /> : <Button onClick={onAddSeller} css={buttonStyle}>Adicionar Vendedor</Button>}
    </AvatarCard>
  )
}

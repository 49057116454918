import { css } from '@emotion/core'
import { flexColumn, flexHorizontallyCentered } from '@bonitour/components'
import { clickable } from 'assets/styles/global'

export const selectorContainer = css`
  width: 150px;
`

export const selectorArrowContainer = css`
  div {
    top: 25px;
  }
`

export const selectorOptions = css`
  ${flexColumn};
  align-items: flex-start;
  padding: 10px;
`

export const selectorOption = css`
  ${flexHorizontallyCentered};
  margin: 5px 0;
  ${clickable};
`

export const optionLabel = css`
  margin-left: 10px;
`

export const searchIcon = css`
  font-size: 20px;
`

/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import { flexRowCentered, WiFiIcon } from '@bonitour/components'
import {
  smartphoneContainer,
  header,
  battery,
  wifiStyle,
  phoneSignal,
  phoneSignalBar,
  content
} from './Smartphone.style'

export const Smartphone = ({ children }) => {
  return (
    <div css={smartphoneContainer}>
      <div css={header}>
        <div css={phoneSignal}>
          <div css={phoneSignalBar(6)} />
          <div css={phoneSignalBar(8)} />
          <div css={phoneSignalBar(12)} />
          <div css={phoneSignalBar(14)} />
        </div>
        <div css={flexRowCentered}>
          <WiFiIcon css={wifiStyle} />
          <div css={battery} />
        </div>
      </div>
      <div css={[content]}>{children}</div>
    </div>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Modal } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { PartnershipAccountEditForm } from '../Form/PartnershipAccountEditForm'

export const PartnershipAccountEdit = ({
  sellers = [],
  transaction,
  sellerSelected,
  creditorId,
  isEditVisible,
  isSelledDisabled,
  canDebitType,
  balance,
  onEditHidden: emitEditHidden = identity,
  updateTransaction
}) => {
  const transactionBase = { ...transaction, seller: sellerSelected }

  return (
    <Modal title='Editar Transação' isVisible={isEditVisible} onCloseClick={emitEditHidden}>
      <PartnershipAccountEditForm
        sellers={sellers}
        transaction={transactionBase}
        entry={{ seller: sellerSelected, type: 'credit' }}
        creditorId={creditorId}
        isSelledDisabled={isSelledDisabled}
        canDebitType={canDebitType}
        balance={balance}
        onCloseClick={emitEditHidden}
        updateTransaction={updateTransaction}
      />
    </Modal>
  )
}

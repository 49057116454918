/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { InputFormGroup, Column, Row, Select, Input, flexColumn, colors, Card, Button, scrollBar, H3, ConfirmDialog } from '@bonitour/components'
import { reservationPaymentTypesVisualization } from 'constants/reservationPaymentTypes'
import { hidden, marginTop, marginBottom } from 'assets/styles/global'
import { TicketList } from 'domains/Reservation/Transactions/TicketList'
import { useMemo, useState } from 'react'
import { identity, formatMoney } from '@bonitour/common-functions'
import { useVendorsList } from 'hooks/domains/useVendorList'
import { formatAcquirer } from 'domains/Reservation/Transactions/hooks/useTransactionFormAcquirers'
import { providers } from 'constants/acquirers'
import { apiPaymentMethods } from 'constants/paymentMethods'

const marginTop30 = marginTop(30)
const marginBottom20 = marginBottom(20)
const marginBottom60 = marginBottom(60)

const fullInput = css`
  width: 100%;
`

const amountDueCard = css`
  min-width: 130px;
  display: inline-flex;
  padding: 20px;
`

const amountDueLabel = css`
  font-size: 12px;
  color: ${colors.gray5};
  margin-bottom: 5px;
`

const amountDueStyle = css`
  font-size: 21px;
  color: ${colors.primary};
  font-weight: bold;
`

const container = css`
  width: 100%;
  max-height: 350px;
  overflow: auto;
  ${scrollBar(5)};
`

const overflowHidden = css`
  overflow: hidden;
`

export const ReservationPaymentDetail = ({
  detail = {},
  isPayment = false,
  isPaymentLink = false,
  isPaymentShow = false,
  labels = 'pagamento',
  amountDueValueLabel = 'Valor pago',
  onExpireLink: emitExpireLink = identity,
  setPaymentVisibility: emitPaymentVisibility = identity
}) => {
  const { id, value = '', method, gateway, state, installments, aut, doc, receipt, tickets, acquirer, brand, maxInstallments, vendorId, destinationBankAccountName, customVendorId, paymentMean } = detail
  const isCredit = method === 'credit' || method === 'pay_credit'
  const openReceipt = () => window.open(receipt, '_blank', 'noopener noreferrer')
  const { isPayCreditPayment, isPix } = useMemo(() => ({
    isPayCreditPayment: method === apiPaymentMethods.pay_credit,
    isPix: method === apiPaymentMethods.pix
  }), [method])
  const isSent = state === 'sent'
  const isExpired = state === 'expired'

  const [isConfirmActionVisible, setConfirmActionVisible] = useState(false)
  const toggleConfirmActionVisible = () => setConfirmActionVisible(true)
  const toggleConfirmActionHidden = () => setConfirmActionVisible(false)

  const onExpire = () => {
    emitExpireLink(id)
    emitPaymentVisibility(false)
  }

  const ticketsLabel = isPayCreditPayment && (isSent || isExpired) ? 'Ingressos a pagar' : 'Ingressos pagos'

  const { vendorsOptions = [] } = useVendorsList(false)

  return (
    <>
      <ConfirmDialog
        title='Expirar link de pagamento'
        message='Esse link de pagamento será expirado permanentemente. Deseja continuar?'
        isVisible={isConfirmActionVisible}
        successCallback={onExpire}
        cancelCallback={toggleConfirmActionHidden}
      />

      <div css={marginBottom60}>
        <Row>
          <Column phone={12} desktop={2}>
            <Card css={amountDueCard}>
              <div css={flexColumn}>
                <p css={amountDueLabel}>{amountDueValueLabel}</p>
                <span css={amountDueStyle}>{formatMoney(value || 0)}</span>
              </div>
            </Card>
          </Column>
          <Column phone={12} desktop={3}>
            <InputFormGroup label={`Forma de ${labels}`} css={fullInput}>
              <Select
                options={reservationPaymentTypesVisualization}
                value={method}
                css={fullInput}
                disabled
              />
            </InputFormGroup>
          </Column>
          {isPaymentLink
            ? <Column phone={12} desktop={3}>
              <InputFormGroup label='Gateway do pagamento' css={fullInput}>
                <Input
                  disabled
                  value={providers[gateway]}
                  css={fullInput}
                />
              </InputFormGroup>
            </Column>
            : null}
          <Column phone={12} desktop={3} css={[!(isPix && !acquirer) && hidden, !destinationBankAccountName && hidden]}>
            <InputFormGroup label='Conta de destino' css={fullInput}>
              <Input value={destinationBankAccountName} css={fullInput} disabled />
            </InputFormGroup>
          </Column>

          {Boolean(acquirer) && (
            <Column phone={12} desktop={2}>
              <InputFormGroup label='Adquirente e bandeira'>
                <Input value={formatAcquirer({ name: acquirer, brand, paymentMean })} css={fullInput} disabled />
              </InputFormGroup>
            </Column>
          )}

          {Boolean(isCredit) && Boolean(installments) && (
            <Column phone={isCredit ? 6 : 12} desktop={1} css={!isCredit && hidden}>
              <InputFormGroup label='Parcelas'>
                <Input value={installments} css={fullInput} disabled />
              </InputFormGroup>
            </Column>
          )}

          {Boolean(aut) &&
            <Column phone={6} desktop={2}>
              <InputFormGroup label='Aut'>
                <Input value={aut} css={fullInput} disabled />
              </InputFormGroup>
            </Column>}

          {Boolean(doc) &&
            <Column phone={6} desktop={2}>
              <InputFormGroup label='Doc'>
                <Input value={doc} css={fullInput} disabled />
              </InputFormGroup>
            </Column>}

          {Boolean(receipt) &&
            <Column phone={12} desktop={2}>
              <InputFormGroup label='Comprovante'>
                <Button onClick={openReceipt}>Clique para abrir</Button>
              </InputFormGroup>
            </Column>}

          {isPayCreditPayment && (isSent || isExpired) &&
            <Column phone={12} desktop={2}>
              <InputFormGroup label='Limite max. de parcelas'>
                <Input value={maxInstallments} css={fullInput} disabled />
              </InputFormGroup>
            </Column>}

          {vendorId &&
            <Column phone={12} desktop={3}>
              <InputFormGroup label='Fornecedor multiponto' css={fullInput}>
                <Select options={vendorsOptions} value={isPayCreditPayment ? vendorId : customVendorId} css={fullInput} disabled />
              </InputFormGroup>
            </Column>}

          {isPayCreditPayment && isSent &&
            <Column phone={12} desktop={2}>
              <InputFormGroup label='Link'>
                <Button onClick={toggleConfirmActionVisible}>Cancelar link</Button>
              </InputFormGroup>
            </Column>}
        </Row>

        {Boolean(tickets?.length) &&
          <Row>
            <Column phone={12} desktop={12} css={marginBottom20}>
              <div css={[flexColumn, overflowHidden]}>
                <H3 css={marginTop30}>{ticketsLabel}</H3>
                <div css={container}>
                  <TicketList
                    tickets={tickets}
                    isSelectable={false}
                    isPayment={isPayment}
                    isPaymentLink={isPaymentLink}
                    isPaymentShow={isPaymentShow}
                  />
                </div>
              </div>
            </Column>
          </Row>}
      </div>
    </>
  )
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { TableContainer, TableHeader, TableHeaderRow, TableHeaderCell, TableBody, TableBodyRow, TableBodyCell, Card } from '@bonitour/components'
import { useMemo } from 'react'
import { formatMoney } from '@bonitour/common-functions'
import { paymentTypesMap } from 'constants/reservationPaymentTypes'
import { CashierValuesToCredit } from './CashierValuesToCredit'

const getCurrencyValue = (value) => {
  return value ? formatMoney(value) : ''
}

const cardCss = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
`

export const CashierPaymentMethods = ({ paymentMethods, loading, valuesToCredit, isCashierOpen }) => {
  const totalAmount = useMemo(() => {
    return paymentMethods?.reduce((sum, method) => sum + Number(method?.value), 0)
  }, [paymentMethods])

  return (
    <Card customCss={[cardCss]}>
      <TableContainer>
        <TableHeader>
          <TableHeaderRow>
            <TableHeaderCell>Resumo do Caixa</TableHeaderCell>
            <TableHeaderCell>Valor</TableHeaderCell>
          </TableHeaderRow>
        </TableHeader>
        <TableBody loading={loading}>
          {paymentMethods?.map(({ id, paymentMethod, value }) => (
            <TableBodyRow
              key={`method-${id}`}
            >
              <TableBodyCell>{paymentTypesMap[paymentMethod] || '-'}</TableBodyCell>
              <TableBodyCell>{getCurrencyValue(value)}</TableBodyCell>
            </TableBodyRow>
          ))}
          <TableBodyRow>
            <TableHeaderCell>Total</TableHeaderCell>
            <TableHeaderCell>{getCurrencyValue(totalAmount)}</TableHeaderCell>
          </TableBodyRow>
        </TableBody>
      </TableContainer>
      <CashierValuesToCredit loading={loading} valuesToCredit={valuesToCredit} isCashierOpen={isCashierOpen} />
    </Card>
  )
}

import moment from 'moment'
import { deepGetOrElse } from 'deep-getter'
import { formatISOTime } from 'utils/time'
import { apiPaymentMethods, bankAccountPaymentMethods } from 'constants/paymentMethods'
import { parseDecimal } from 'utils/number'

export const paymentModel = (
  {
    value = '0',
    upload,
    method,
    installments,
    ticketsIds,
    id,
    aut,
    doc,
    acquirer,
    bankAccount,
    vendorId,
    customVendorName,
    day,
    time
  },
  isEdition = false
) => {
  const isPix = method === apiPaymentMethods.pix

  const hasAcquirer = acquirer !== ''

  const {
    canResetAcquirer,
    canResetBankAccount
  } = (function () {
    return {
      canResetBankAccount: (isPix && hasAcquirer && !bankAccount) || (method === apiPaymentMethods.cash) || !bankAccountPaymentMethods.includes(method),
      canResetAcquirer: isPix && Boolean(bankAccount) && !hasAcquirer
    }
  }())

  const canSetInstallments = (function () {
    const isNull = value => value === null

    const isNullAcquirerOrInstallments = !hasAcquirer || isNull(installments)

    const hasAcquirerAndNoInstallments = acquirer && installments === 0

    return isPix && (isNullAcquirerOrInstallments || hasAcquirerAndNoInstallments)
  }())

  if (canSetInstallments) {
    installments = 1
  }

  const defaultResetValue = (canResetAcquirer || canResetBankAccount) ? isEdition ? '' : undefined : undefined

  let paymentProvider

  if (canResetAcquirer) {
    acquirer = defaultResetValue
    aut = defaultResetValue
    doc = defaultResetValue
    paymentProvider = defaultResetValue
  }

  if (canResetBankAccount) {
    bankAccount = defaultResetValue
  }

  return {
    id,
    value: Number(value).toFixed(2),
    state: 'paid',
    payment_date: day ? `${formatISOTime(day, 'YYYY-MM-DD', false)}${time && ` ${time}`}` : formatISOTime(moment(), 'YYYY-MM-DD HH:mm:ss', false),
    receipt: deepGetOrElse(upload, '0.file', null),
    payment_method: method,
    tickets: ticketsIds.map(ticket => ticketsToPay(ticket)),
    installments,
    aut,
    doc,
    acquirer_id: acquirer || defaultResetValue,
    destination_bank_account_id: bankAccount || defaultResetValue,
    custom_vendor_id: vendorId,
    custom_vendor_name: customVendorName,
    payment_provider: paymentProvider
  }
}

export const linkPaymentModel = ({ value = '0', maxInstallments, ticketsIds }) => ({
  value: Number(value).toFixed(2),
  max_installments: maxInstallments,
  tickets_ids: ticketsIds.map(({ id }) => id)
})

const _ticketsToPaymentLink = ({ id, value, companyId, serviceId, amount }) => {
  return {
    ticket_id: id,
    price: value,
    company_id: companyId,
    service_id: serviceId,
    amount: parseDecimal(amount)
  }
}

export const linkPaymentMultipontoModel = ({ value = '0', maxInstallments, vendorId, ticketsIds }) => ({
  value: Number(value).toFixed(2),
  max_installments: maxInstallments,
  custom_vendor_id: vendorId,
  tickets_ids: ticketsIds.map(({ id }) => id),
  tickets: ticketsIds.map(_ticketsToPaymentLink)
})

export const linkNonSplittedModel = ({ value = '0', maxInstallments, couponsCodes, gateway, ticketsIds }) => ({
  value: Number(value).toFixed(2),
  max_installments: maxInstallments,
  coupons_codes: couponsCodes,
  gateway,
  tickets: ticketsIds.map(_ticketsToPaymentLink)
})

export const ticketsToPay = ({ id, value, companyId, serviceId, amount }) => ({
  ticket_id: id,
  price: value,
  company_id: companyId,
  service_id: serviceId,
  amount: parseDecimal(amount)
})

export const paymentTransferModel = ({ canceledTickets, ticketsToPay, isFreelyTransferable, destinationCompanyId }) => {
  const partPaymentTicketsIds = []
  const ticketsId = ticketsToPay.map(({ id }) => id)

  canceledTickets.map(({ partPaymentTicketsIds }) => partPaymentTicketsIds).map((item) => partPaymentTicketsIds.push(...item))

  return {
    freely_transferable: isFreelyTransferable,
    destination_company_id: destinationCompanyId,
    part_payment_tickets_ids: partPaymentTicketsIds,
    tickets_ids: ticketsId
  }
}

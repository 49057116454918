/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useEffect, useState } from 'react'
import { jsx } from '@emotion/core'
import { useToast } from '@bonitour/components'

import { SellerFab } from 'containers/Sellers/Fab/Fab'
import { SellersPage } from 'containers/Sellers/Sellers'
import { SellersGroupsService } from 'services/SellersGroups/Service'
import { useHistory } from 'react-router-dom'
import { usePermissions } from 'hooks/usePermissions'
import { PaginatedSellersProvider } from './SellersContext'

const partnerInvitation = {}

export const Sellers = () => {
  const history = useHistory()
  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()
  const [groupList, setGroupList] = useState([])
  const [loadingGroup, setLoadingGroup] = useState(false)

  const onValidationError = () => addToast('Preencha corretamente o formulário')

  const onClickGroupCard = id => () => history.push(`/group/${id}/edit`)

  const inviteUser = async ({ email, group }) => {
    await SellersGroupsService.invite({ email }, group)
    addToast('Usuário convidado com sucesso', 'success')
  }

  useEffect(() => {
    setLoadingGroup(true)
    SellersGroupsService.listGroups().then(setGroupList)
      .catch((error) => {
        const hasPermission = handlePermission(error, 'Você não possui permissão de acesso aos grupos')
        hasPermission && addToast('Ocorreu um erro ao realizar a busca')
      })
      .finally(() => setLoadingGroup(false))
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <PaginatedSellersProvider>
        <SellersPage
          groups={groupList}
          isLoadingGroup={loadingGroup}
          onClickGroupCard={onClickGroupCard}
        />
      </PaginatedSellersProvider>

      <SellerFab
        partnerInvitation={partnerInvitation}
        groups={groupList}
        onInviteFormSubmission={inviteUser}
        onValidationError={onValidationError}
      />
    </>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { BREAK_POINTS, Card, H2, Row, H3, colors, flexCenteredSpaceBetweenRow, P } from '@bonitour/components'
import { formatMoney } from '@bonitour/common-functions'
import { marginLeft, marginTop } from 'assets/styles/global'

const marginTop50 = marginTop(50)
const marginLeft15 = marginLeft(15)

const resultContainer = css`
  min-width: 215px;
  margin-right: 20px;
  margin-bottom: 10px;
`

const resultValue = css`
  color: ${colors.primary};
  font-size: 24px;
  font-weight: bold;
`

const valueHighlight = css`
  color: ${colors.primary};
`

const bold = css`
  font-weight: bold;
`

const card = css`
  max-height: 60px;
  min-width: 170px;
  margin-right: 20px;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    width: 100%;
    max-height: initial;
    margin-right: 0;
  }
`

const cardDetails = css`
  ${flexCenteredSpaceBetweenRow};

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 0;
  }
`

const ResultCard = ({ title, value }) => {
  return (
    <Card css={resultContainer}>
      <H3>{title}</H3>
      <span css={resultValue}>{value}</span>
    </Card>
  )
}

const CardContent = ({ title, value }) => <P css={marginLeft15}>{title}: <span css={bold}>{value}</span></P>

export const VouchersResults = ({ totals }) => {
  const { vouchersAmount, priceAmount, paxTypes = {} } = totals

  return (
    <div css={marginTop50}>
      <H2>Resultado</H2>
      <Row>
        <ResultCard title='Valor total' value={formatMoney(priceAmount)} />
        <Card css={card}>
          <H3>Total de Vouchers</H3>
          <Row css={flexCenteredSpaceBetweenRow}>
            <H2 css={valueHighlight}>{vouchersAmount}</H2>
            <div css={cardDetails}>
              {Object.keys(paxTypes).map((paxType, index) => {
                return <CardContent key={index} title={paxType} value={paxTypes[paxType]} />
              })}
            </div>
          </Row>
        </Card>
      </Row>
    </div>
  )
}

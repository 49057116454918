/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { PaginationContainer } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { InvoicesBatchTable } from './Table/InvoicesBatchTable'
import { InvoicesBatchListContainer } from './InvoicesBatchList.style'
import { Fragment } from 'react'

export const InvoicesBatchList = ({
  loading,
  batchList = [],
  total = 0,
  pagesQuantity = 1,
  onPagination: emitPaginationEvent = identity,
  recipientLabel = 'Nome do beneficiário',
  ActionsComponent = Fragment
}) => {
  return (
    <InvoicesBatchListContainer>
      <PaginationContainer
        total={total}
        pagesQuantity={pagesQuantity}
        onPagination={emitPaginationEvent}
        isLoading={loading}
      >
        <InvoicesBatchTable
          invoicesBatch={batchList}
          recipientLabel={recipientLabel}
          loading={loading}
          ActionsComponent={ActionsComponent}
        />
      </PaginationContainer>
    </InvoicesBatchListContainer>
  )
}

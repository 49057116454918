import { colors } from '@bonitour/components'

export const reservationPaymentStatusMap = {
  canceled: {
    label: 'Cancelado',
    color: colors.danger
  },
  paid: {
    label: 'Pago',
    color: colors.success
  },
  approved: {
    label: 'Aprovado',
    color: colors.success
  },
  rejected: {
    label: 'Recusado',
    color: colors.danger
  },
  pending: {
    label: 'Pendente',
    color: colors.info
  },
  refunded: {
    label: 'Reembolsado',
    color: colors.danger
  },
  expired: {
    label: 'Expirado',
    color: colors.danger
  },
  sent: {
    label: 'Aguardando pagamento',
    color: colors.warning
  }
}

export const reservationPaymentStatusOptions = [
  {
    label: 'Cancelado',
    value: 'canceled'
  },
  {
    label: 'Pago',
    value: 'paid'
  }
]

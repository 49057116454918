import { validateCpf, validatePhoneWithDDI } from '@bonitour/common-functions'
import { date, string } from 'yup'

export const passengerSchema = (states = [], cities = []) => {
  const stateRules = states.length ? string().required('O estado é um campo obrigatório') : string()
  const cityRules = cities.length ? string().required('A cidade é um campo obrigatório') : string()

  return ({
    fullName: string().required('O nome é obrigatório'),
    email: string().required('O e-mail é obrigatório').email('O e-mail deve ser válido'),
    birthDate: date().nullable(),
    documentType: string(),
    document: string().when('documentType', {
      is: 'Cpf',
      then: string().test('test-cpf', 'O CPF deve ser válido', validateCpf),
      otherwise: string()
    }),
    phone: string().test('validate-phone', 'O número do telefone deve ser válido', (phone) => {
      if (phone.length <= 4) {
        return true
      }
      return phone && validatePhoneWithDDI(phone)
    }),
    emergencyName: string(),
    emergencyTelephone: string().test('validate-phone', 'O número do telefone deve ser válido', (emergencyTelephone) => {
      if (emergencyTelephone.length <= 4) {
        return true
      }
      return emergencyTelephone && validatePhoneWithDDI(emergencyTelephone)
    }),
    country: string(),
    weight: string(),
    height: string(),
    state: stateRules,
    city: cityRules
  })
}

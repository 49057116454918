import moment from 'moment'
import { BLANK_SPACE } from '@bonitour/components'

const sortByInitialDate = (a, b) => {
  return moment(a.initialDate).isBefore(b.initialDate) ? -1 : 1
}

function insertBlankSpaces (intervalList) {
  return intervalList.flatMap((period, index, array) => {
    let finalDate = null
    const nextInterval = array[index + 1]
    const initialDate = moment(period.finalDate).add(1, 'days')
    const periodList = [period]

    if (nextInterval) {
      finalDate = moment(nextInterval.initialDate).subtract(1, 'days')
    }

    if (finalDate && initialDate.isBefore(finalDate)) {
      periodList.push({
        name: BLANK_SPACE,
        initialDate: initialDate.toDate(),
        finalDate: finalDate.toDate()
      })
    }

    return periodList
  })
}

export function PeriodListParser (periodList = []) {
  const sortedIntervalList = periodList
    .flatMap(period => period.intervalList.map(interval => ({ ...interval, color: period.color })))
    .sort(sortByInitialDate)

  return insertBlankSpaces(sortedIntervalList)
}

import React, { createContext, useContext, useMemo, useCallback, useState } from 'react'
import { UserStore } from 'services/User/Store'
import { useSimpleStorage } from 'hooks/useSimpleStorage'
import { AuthStore } from 'services/Auth/Store'
import { AuthService } from 'services/Auth/Service'

export const UserContext = createContext({})

export const useUser = () => useContext(UserContext)

export const UserProvider = ({ children }) => {
  const [authKey, setAuthKey] = useState(() => AuthStore.authKey)
  const [user, setUser] = useSimpleStorage(UserStore.getUser, UserStore.setUser)
  const hasAffiliateProfile = useMemo(() => Boolean(user?.affiliates_info?.length), [user])

  const logout = useCallback(async () => {
    await AuthService.logout()
    setAuthKey()
  }, [])

  if (!authKey) {
    AuthService.redirectToLogin()
  }

  const contextData = useMemo(() => ({
    user, setUser, logout, hasAffiliateProfile, authKey
  }), [user, setUser, logout, hasAffiliateProfile, authKey])

  return (
    <UserContext.Provider value={contextData}>
      {children}
    </UserContext.Provider>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import {
  TableContainer,
  TableBody,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell,
  TableBodyRow,
  TableBodyCell,
  colors,
  iconHex,
  EditInfoIcon
} from '@bonitour/components'
import { formatMoney } from '@bonitour/common-functions'
import { Link } from 'react-router-dom'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { EditFiscalDuty } from '../List/EditFiscalDuty'
import { useCompany } from 'contexts/Company'

export const fiscalDutyCss = css`
  cursor: default;
  a, button {
    cursor: pointer;
  
  }
  button {
    background-color: transparent;
    border: none;
    padding: 10px 5px;
    margin: -8px 3px;
  }
`

export const table = css`
  margin-top: 30px;

  tr:not(thead tr) {
    td {
      vertical-align: top;
      line-height: 22px;
      color: ${colors.gray1};
    }
    transition: height 0.3s ease;
    height: 56px;
    overflow: hidden;
    font-size: 1rem;
    color: ${colors.gray1};
    b {
      font-weight: 700;
      line-height: 1.5;
    }
    & > * {
      cursor: pointer;
    }

    .reservation_code {
      cursor: default;
      a, button {
        cursor: pointer;
      }
    }

    .full_name button {
      cursor: pointer;
      border: none;
      background-color: transparent;
      display: flex;
      align-items: center;
      color: inherit;

      &::before {
        font-family: "bonifont";
        content: "\\${iconHex('angle-down')}";
        display: block;
        background-color: ${colors.white};
        box-shadow: 0px 0px 4px #00000033;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        text-align: center;
        line-height: 20px;
        margin-right: 6px;
        transition: transform 0.3s ease;
      } 

      &:hover::before {
        background-color: ${colors.gray14};
      }
    }

    .annex {
      display: none;
      cursor: default;
      &__row {
        display: flex;
        gap: 30px;
        margin-bottom: 5px;
        font-size: 0.875rem;
        b {
          font-weight: 600;
        }
      }
    }

    &.expanded {
      height: 162px;
      .annex {
        display: block;
      }
      .full_name button::before {
        transform: rotate(-180deg);
      }
    }
  }
`

const tableRowAnnex = css`
  border: 1px solid ${colors.gray7};
  background-color: ${colors.gray12};
  border-radius: 10px;
  padding: 10px;
  position: absolute;
  top: 54px;
  left: 10px;
  right: 10px;
`

export const FiscalDuty = ({ fiscalDuty, reservationId, isEditable, onUpdateFiscalDuty }) => {
  const [isOpened, setIsOpened] = useState(false)
  const openDialog = useCallback(() => setIsOpened(true), [])
  const closeDialog = useCallback(() => setIsOpened(false), [])

  return (
    <>
      <div css={fiscalDutyCss}>
        {fiscalDuty || '--'}
        {isEditable && (
          <button type='button' onClick={openDialog}>
            <EditInfoIcon />
          </button>
        )}
      </div>
      {isOpened && (
        <EditFiscalDuty
          reservationId={reservationId}
          fiscalDuty={fiscalDuty}
          onClose={closeDialog}
          onUpdate={onUpdateFiscalDuty}
        />
      )}
    </>
  )
}

const RevenueTableRow = ({ revenueItem = {}, onClick, expanded, onUpdateFiscalDuty }) => {
  const { id: companyId } = useCompany()
  const { reservationId = '', reservationCode = '', payer = {}, reservationValue, revenue, deduction, fiscalDuty, sellerId, checkoutDate } = revenueItem
  const { fullName = '', document = '', email = '', address = {} } = payer
  const { cityName, stateName, countryName, street, postCode, supplement, region } = address

  const stopPropagation = useCallback((e) => {
    e.stopPropagation()
  }, [])

  const isFiscalDutyEditable = useMemo(
    () => companyId === sellerId,
    [companyId, sellerId]
  )

  return (
    <>
      <TableBodyRow style={{ position: 'relative' }} className={expanded && 'expanded'} onClick={onClick}>
        <TableBodyCell className='reservation_code' onClick={stopPropagation}>
          <Link to={`/reservation/${reservationId}`} target='_blank'>{reservationCode}</Link>
        </TableBodyCell>
        <TableBodyCell className='full_name'>
          <button type='button'>
            {/* onClick will propagate to TableBodyRow */}
            {fullName}
          </button>
        </TableBodyCell>
        <TableBodyCell>{checkoutDate}</TableBodyCell>
        <TableBodyCell>{formatMoney(reservationValue)}</TableBodyCell>
        <TableBodyCell>{formatMoney(revenue)}</TableBodyCell>
        <TableBodyCell>{formatMoney(deduction)}</TableBodyCell>
        <TableBodyCell className='fiscal_duty' onClick={stopPropagation}>
          <FiscalDuty
            fiscalDuty={fiscalDuty}
            isEditable={isFiscalDutyEditable}
            reservationId={reservationId}
            onUpdateFiscalDuty={onUpdateFiscalDuty}
          />
        </TableBodyCell>

        <div css={tableRowAnnex} colSpan={3} className='annex' onClick={stopPropagation}>
          <b>Dados do responsável</b>
          <div className='annex__row'>
            <div>
              <b>Nome</b>
              <p>{fullName}</p>
            </div>
            <div>
              <b>Documento</b>
              <p>{document}</p>
            </div>
            <div>
              <b>E-mail</b>
              <p>{email}</p>
            </div>
            <div>
              <b>Endereço</b>
              <p>{street}{supplement && ', '}{supplement}{region && ', '}{region}{postCode && ' - '}{postCode}</p>
              <p>{cityName}{stateName && ' - '}{stateName}{countryName && ' - '}{countryName}</p>
            </div>
          </div>
        </div>
      </TableBodyRow>
    </>
  )
}

export const RevenueTable = ({ revenues = [], loading, onUpdate }) => {
  const [expandedIndexes, setExpandedIndexes] = useState([])
  const toggleExpanded = useCallback((index) => () => {
    setExpandedIndexes((expandedIndexes) => {
      if (expandedIndexes.includes(index)) {
        return expandedIndexes.filter((i) => i !== index)
      } else {
        return [...expandedIndexes, index]
      }
    })
  }, [])

  useEffect(
    () => { setExpandedIndexes([]) },
    [revenues]
  )

  return (
    <>
      <TableContainer css={table}>
        <TableHeader>
          <TableHeaderRow>
            <TableHeaderCell>Código da reserva</TableHeaderCell>
            <TableHeaderCell>Responsável</TableHeaderCell>
            <TableHeaderCell>Data de<br/>checkout</TableHeaderCell>
            <TableHeaderCell>Total da reserva</TableHeaderCell>
            <TableHeaderCell>Receita</TableHeaderCell>
            <TableHeaderCell>Dedução</TableHeaderCell>
            <TableHeaderCell>Número da nota fiscal</TableHeaderCell>
          </TableHeaderRow>
        </TableHeader>
        <TableBody loading={loading}>
          {
            revenues.map((item, index) => (
              <RevenueTableRow
                key={index}
                revenueItem={item}
                onClick={toggleExpanded(index)}
                expanded={expandedIndexes.includes(index)}
                onUpdateFiscalDuty={onUpdate}
              />
            ))
          }
        </TableBody>
      </TableContainer>
    </>
  )
}

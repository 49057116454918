import { useCallback, useEffect, useState } from 'react'
import { useToast } from '@bonitour/components'
import { useParamsFilter } from 'hooks/useParamsFilter'
import { BatchService } from 'core/services/Batch'
import { usePermissions } from 'hooks/usePermissions'

export const useListVouchers = () => {
  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()
  const [filters, setFilters] = useParamsFilter()
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({ currentPage: 1, perPage: 100 })

  const changePagination = useCallback((currentPage, perPage) => {
    if (currentPage === pagination.currentPage && perPage === pagination.perPage) {
      return
    }

    setPagination({ currentPage, perPage })
    // eslint-disable-next-line
  }, [])

  const [vouchersData, setVouchersData] = useState({ vouchers: undefined, meta: undefined })

  const fetchVouchers = useCallback(({ filters, pagination }) => {
    setLoading(true)

    return BatchService.listVouchers({ filters, pagination }).then((data) => setVouchersData(data))
      .catch((error) => {
        const hasPermission = handlePermission(error, 'Você não possui permissão de acesso aos vouchers')
        hasPermission && addToast('Ocorreu um erro ao realizar a busca')
      })
      .finally(() => setLoading(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchVouchers({ pagination, filters })
  }, [pagination, filters, fetchVouchers])

  return {
    filters,
    setFilters,
    pagination,
    changePagination,
    vouchers: vouchersData?.vouchers,
    vouchersNotFound: vouchersData?.vouchersNotFound,
    meta: vouchersData?.meta,
    fetchVouchers,
    loading
  }
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { NavLink } from 'react-router-dom'
import { Label, TicketIcon, flexHorizontallyCentered, AngleDownIcon, Manager, Reference, Popover, Row, HandshakeIcon, RedeemCodeIcon, MegaphoneIcon, ReportsIcon, ChartsIcon, BankCardIcon, ImageIcon, CashierIcon } from '@bonitour/components'
import { resetLink, marginBottom } from 'assets/styles/global'
import { CardButton } from 'components/CardButton'
import { cardIcon, labelCardButton, angleDownStyle, linkColor, cardItems, cardItemIcon, cardButton, labelSubCardItem, subCardItem, height100percent, dashboardShortcuts, itemRowLink } from './Dashboard.style'
import { useClickOutside } from '@bonitour/app-functions'
import { useRef } from 'react'
import { DashboardChart } from './Chart/DashboardChart'
import { useFeatureFlag } from 'contexts/Feature'
import { ActivityList } from './ActivityList'

const marginBottom20 = marginBottom(20)

const CardItemRow = ({ to = '', children }) => (
  <NavLink to={to} css={resetLink}>
    <Row css={linkColor}>
      <div css={itemRowLink}>{children}</div>
    </Row>
  </NavLink>
)

const CardItem = ({ title, icon: Icon = null, children, ...other }) => {
  const reference = useRef()
  const [isVisible, setVisibility] = useClickOutside(reference)
  const toggleVisibility = () => setVisibility(visibility => !visibility)

  return (
    <div ref={reference} {...other} css={height100percent}>
      <Manager>
        <Reference>
          {({ ref }) => (
            <div ref={ref} css={height100percent} onClick={toggleVisibility}>
              <CardButton css={cardButton}>
                <div css={[flexHorizontallyCentered, cardItemIcon]}>
                  {Icon && <Icon css={cardIcon} />}
                  <AngleDownIcon css={angleDownStyle} />
                </div>
                <Label css={labelCardButton}>{title}</Label>
              </CardButton>
            </div>
          )}
        </Reference>
        {isVisible && (
          <Popover position='bottom' css={cardItems}>
            {children}
          </Popover>
        )}
      </Manager>
    </div>
  )
}

const SubCardItem = ({ title, children, ...other }) => {
  const reference = useRef()
  const [isVisible, setVisibility] = useClickOutside(reference)
  const toggleVisibility = () => setVisibility(visibility => !visibility)

  return (
    <div ref={reference} css={subCardItem} {...other}>
      <Manager>
        <Reference>
          {({ ref }) => (
            <div ref={ref} onClick={toggleVisibility}>
              <Label css={labelSubCardItem}>{title}</Label>
            </div>
          )}
        </Reference>
        {isVisible && (
          <Popover position='right' css={cardItems}>
            {children}
          </Popover>
        )}
      </Manager>
    </div>
  )
}

export const CompanyDashboard = () => {
  const [isPartnershipAccountEnabled] = useFeatureFlag('orb-partnership-account')
  return (
    <div css={marginBottom20}>
      <div>
        <nav css={dashboardShortcuts}>
          <NavLink to='/my-day' css={resetLink}>
            <CardButton css={cardButton}>
              <ChartsIcon css={cardIcon} />
              <Label css={labelCardButton}>Meu dia</Label>
            </CardButton>
          </NavLink>

          <NavLink to='/reservations' css={resetLink}>
            <CardButton css={cardButton}>
              <TicketIcon css={cardIcon} />
              <Label css={labelCardButton}>Reservas</Label>
            </CardButton>
          </NavLink>

          <NavLink to='/activities' css={resetLink}>
            <CardButton css={cardButton}>
              <ImageIcon css={cardIcon} />
              <Label css={labelCardButton}>Experiências</Label>
            </CardButton>
          </NavLink>

          <NavLink to='/external-providers' css={resetLink}>
            <CardButton css={cardButton}>
              <CashierIcon css={cardIcon} />
              <Label css={labelCardButton}>Fornecedores&nbsp;Externos</Label>
            </CardButton>
          </NavLink>

          <CardItem title='Parceiros' icon={HandshakeIcon}>
            <CardItemRow to='/sellers'>Vendedores</CardItemRow>
            <CardItemRow to='/providers'>Fornecedores</CardItemRow>
          </CardItem>

          <CardItem title='Financeiro' icon={BankCardIcon}>
            <CardItemRow to='/bank-accounts'>Dados e taxas bancárias</CardItemRow>
            <SubCardItem title='Faturas'>
              <CardItemRow to='/invoices-pay'>A pagar</CardItemRow>
              <CardItemRow to='/invoices-receive'>A receber</CardItemRow>
            </SubCardItem>
            <CardItemRow to='/acquirers'>Adquirentes</CardItemRow>
            <CardItemRow to='/cashiers/my_cashier'>Caixas</CardItemRow>

            {isPartnershipAccountEnabled
              ? (
                <CardItemRow to='/partnership-accounts'>Conta de parceria</CardItemRow>
              )
              : null}
            <SubCardItem title='Faturas em lote'>
              <CardItemRow to='/invoices-batch/affiliate'>Afiliado</CardItemRow>
              <CardItemRow to='/tour-guide-payment'>Guia</CardItemRow>
              <CardItemRow to='/invoices-batch/offline'>Fornecedor externo</CardItemRow>
            </SubCardItem>
          </CardItem>

          <NavLink to='/reports' css={resetLink}>
            <CardButton css={cardButton}>
              <ReportsIcon css={cardIcon} />
              <Label css={labelCardButton}>Relatórios</Label>
            </CardButton>
          </NavLink>

          <NavLink to='/tickets/check-in' css={resetLink}>
            <CardButton css={cardButton}>
              <RedeemCodeIcon css={cardIcon} />
              <Label css={labelCardButton}>Utilizar ingresso</Label>
            </CardButton>
          </NavLink>

          <NavLink to='/notices' css={resetLink}>
            <CardButton css={cardButton}>
              <MegaphoneIcon css={cardIcon} />
              <Label css={labelCardButton}>Comunicados</Label>
            </CardButton>
          </NavLink>
        </nav>

        <ActivityList />
      </div>
      <DashboardChart />
    </div>
  )
}

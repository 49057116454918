/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { Modal } from '@bonitour/components'
import { PassengerForm } from './PassengerForm'

export const PassengerFormModal = ({
  passenger = {},
  isVisible,
  isPersonalFieldsDisabled,
  onClose: emitClose = identity,
  onBack: emitBack = identity,
  onSubmit: emitSubmit = identity
}) => {
  const { isDependent } = passenger
  const title = isDependent ? 'Cadastrar dependente' : 'Cadastrar viajante'

  return (
    <Modal title={title} isVisible={isVisible} onCloseClick={emitClose}>
      <PassengerForm
        passenger={passenger}
        isPersonalFieldsDisabled={isPersonalFieldsDisabled}
        onClose={emitClose}
        onBack={emitBack}
        onSubmit={emitSubmit}
      />
    </Modal>
  )
}

import { orbCore } from 'services/Orb/Service'
import { createVoucherModel, cancelVoucherModel } from 'core/models/voucherUnico'
import { convertToFormData } from '@bonitour/common-functions'

const { voucherUnicoDomain } = orbCore

export const VoucherUnicoService = {
  createVoucher (ticketsIds) {
    const payload = createVoucherModel({ ticketsIds })
    return voucherUnicoDomain.createVoucher(convertToFormData(payload))
  },
  cancelVoucher (ticketsIds) {
    const payload = cancelVoucherModel({ ticketsIds })
    return voucherUnicoDomain.cancelVoucher(convertToFormData(payload))
  }
}

import { useToast } from '@bonitour/components'

export const useValidateDateRange = (required = false) => {
  const { add: addToast } = useToast()

  const validateDateRange = (startDate, endDate) => {
    if (
      (startDate && !endDate) ||
      (endDate && !startDate) ||
      (startDate > endDate) ||
      (endDate < startDate)
    ) {
      addToast('A data inicial deve ser menor ou igual a data final')
      return false
    }
    if (required && (!startDate || !endDate)) {
      addToast('As datas inicial e final são obrigatórias')
      return false
    }

    return true
  }

  return {
    validateDateRange
  }
}

import { useState, useCallback, useMemo } from 'react'
import { ReservationService } from 'core/services/Reservation'
import { identity } from '@bonitour/common-functions'
import { useReservation } from 'hooks/context/Reservation'
import { StatusEnum } from 'constants/reservationsStatus'
import { ticketTypeEnum } from 'constants/ticketStatus'

export const useTickets = () => {
  const [tickets, setTickets] = useState([])
  const { reservationId } = useReservation()
  const [loading, setLoading] = useState(false)

  const clearTickets = useCallback(() => setTickets([]), [])

  const updateTickets = useCallback((ticketsGetter = identity, ...params) => {
    return async (extraTickets = []) => {
      setLoading(true)
      if (extraTickets.length) {
        setTickets(extraTickets)
        setLoading(false)
        return
      }
      if (!reservationId) {
        setLoading(false)
        return
      }
      const tickets = await ticketsGetter(reservationId, ...params)
      setTickets(tickets)
      setLoading(false)
    }
  }, [reservationId])

  const updatePaymentLinkTickets = useCallback((ticketsGetter = identity, ...params) => {
    return async (extraTickets = []) => {
      setLoading(true)
      if (extraTickets.length) {
        setTickets(extraTickets)
        setLoading(false)
        return
      }
      if (!reservationId) {
        setLoading(false)
        return
      }
      const tickets = await ticketsGetter(reservationId, ...params)

      setTickets(tickets)
      setLoading(false)
    }
  }, [reservationId])

  const updateRefoundTickets = useCallback(() => {
    const filterRefoundTicket = (tickets = []) => tickets.filter(
      (ticket) => ticket.status === StatusEnum.canceled || ticket.type === ticketTypeEnum.offline
    )

    return async (extraTickets = []) => {
      if (extraTickets.length) {
        setTickets(filterRefoundTicket(extraTickets))
        return
      }

      if (!reservationId) {
        return
      }

      setTickets(filterRefoundTicket(tickets))
    }
  }, [reservationId, tickets])

  const getTicketsToPay = useMemo(() => updateTickets(ReservationService.getTicketsToPay), [updateTickets])
  const getTicketsToPaymentLink = useMemo(() => updatePaymentLinkTickets(ReservationService.getTicketsToPay), [updatePaymentLinkTickets])
  const getTicketsToRefund = useMemo(() => updateRefoundTickets(ReservationService.getTicketsToRefund), [updateRefoundTickets])
  const getPartnershipTicketsToRefund = useMemo(() => updateRefoundTickets(ReservationService.getPartnershipTicketsToRefund), [updateRefoundTickets])
  const getTicketsToPayFromVendor = useCallback((vendorId) => vendorId && updateTickets(ReservationService.getTicketsToPayFromVendor, vendorId)(), [updateTickets])

  return {
    tickets,
    loading,
    clearTickets,
    getTicketsToPay,
    getTicketsToPaymentLink,
    getTicketsToRefund,
    getPartnershipTicketsToRefund,
    getTicketsToPayFromVendor
  }
}

import { SafeDate } from '@binamik/js-providers'

const orderActivitiesByTime = (activityA, activityB) => {
  if (!activityB) return -1
  const dateA = activityA?.slot || activityA?.date
  const dateB = activityB?.slot || activityB?.date
  const timeA = new SafeDate(dateA).jsDate.getTime()
  const timeB = new SafeDate(dateB).jsDate.getTime()
  return timeA - timeB
}

export default orderActivitiesByTime

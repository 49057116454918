import { MetaParser } from './Meta'

const sortByTime = (head, next) => (head.time > next.time ? 1 : -1)

export const MyDayParser = {
  single: (activity) => {
    const {
      id,
      ticket_code: ticketCode,
      passenger_name: passengerName,
      time,
      state,
      service_id: serviceId,
      service_name: serviceName,
      reservation_id: reservationId,
      commercial_partner_id: commercialPartnerId,
      commercial_partner_name: commercialPartnerName,
      pax_type: paxType,
      external_integrations: externalIntegrations
    } = activity

    return {
      id,
      ticketCode,
      passengerName,
      time,
      state,
      serviceId,
      serviceName,
      reservationId,
      commercialPartnerId,
      commercialPartnerName,
      paxType,
      externalIntegrations
    }
  },
  list: ({ meta, ticket_daily_monitorings: ticketDailyMonitorings = [] }) => {
    const sortedActivities = ticketDailyMonitorings.sort(sortByTime)
    return {
      activities: ticketDailyMonitorings ? sortedActivities.map(MyDayParser.single) : [],
      meta: MetaParser.meta(meta)
    }
  },
  totals: ({ ticket_daily_monitoring_totalizers: totalizers }) => {
    const { tickets_amount: activitiesAmount, tickets_group_by_pax_name: paxTypes } = totalizers

    return {
      activitiesAmount,
      paxTypes
    }
  }
}

import { formatISOTime } from 'utils/time'
import { SafeDate } from '@binamik/js-providers'
import { replaceCommaToDot } from '@bonitour/common-functions'

export function OfflineExperienceTicketModel (data) {
  const {
    serviceId,
    count,
    checkinDate,
    checkinTime = '00:00',
    checkoutDate,
    checkoutTime = '00:00',
    price,
    netPrice,
    payerParams,
    reservationId,
    airCompanyName,
    externalReservationCode,
    pickupAddress,
    dropoffAddress,
    touristName,
    companyName,
    driverLicense,
    driverLicenseDueDate,
    driverName,
    accommodationType,
    feeType,
    driverAddress,
    document,
    driverDocument,
    state,
    reservationHeader,
    affiliateCode,
    offlineProviderId,
    observation,
    activityName,
    transporteType
  } = data
  const {
    payerId,
    email: payerEmail,
    name: payerName,
    identificationDocument: payerIdentificationDocument,
    birthDate: payerBirthDate,
    number: payerNumber,
    country: payerCountry,
    state: payerState,
    city: payerCity,
    zipCode: payerZipCode,
    street: payerStreet,
    supplement: payerSupplement,
    district: payerDistrict,
    emergencyTelephone: payerEmergencyTelephone,
    emergencyName: payerEmergencyName,
    weight: payerWeight,
    height: payerHeight
  } = payerParams

  const [checkinHour, checkinMinute] = checkinTime ? checkinTime.split(':') : []
  const [checkoutHour, checkoutMinute] = checkoutTime ? checkoutTime.split(':') : []

  const checkoutDatetime = checkoutDate && new Date(checkoutDate?.getTime()).setHours(checkoutHour, checkoutMinute)
  const slot = checkinDate && new Date(checkinDate.getTime()).setHours(checkinHour, checkinMinute)

  const userId = reservationHeader?.agent || undefined

  return {
    service_id: serviceId,
    reservation_id: reservationId,
    count,
    affiliate_code: affiliateCode,
    slot: slot && formatISOTime(slot, 'YYYY/MM/DD HH:mm'),
    user_id: userId,
    offline_provider_id: offlineProviderId,
    comment: observation,
    ticket_info: {
      price,
      net_price: netPrice,
      air_company_name: airCompanyName,
      external_reservation_code: externalReservationCode,
      pickup_date: checkinDate && new Date(checkinDate).toISOString().split('T')[0],
      pickup_address: pickupAddress,
      pickup_hour: checkinTime,
      dropoff_date: checkoutDate && new Date(checkoutDate).toISOString().split('T')[0],
      dropoff_address: dropoffAddress,
      dropoff_hour: checkoutTime,
      tourist_name: touristName,
      company_name: companyName,
      driver_document: driverDocument,
      document,
      driver_license: driverLicense,
      driver_license_due_date: driverLicenseDueDate,
      driver_address: driverAddress,
      driver_name: driverName,
      checkout_date: checkoutDatetime && formatISOTime(checkoutDatetime, 'YYYY/MM/DD HH:mm'),
      accommodation_type: accommodationType,
      fee_type: feeType,
      state,
      activity_name: activityName,
      transporte_type: transporteType
    },
    payer_params: {
      id: payerId,
      email: payerEmail,
      full_name: payerName,
      document: payerIdentificationDocument,
      birth_date: payerBirthDate && new SafeDate(payerBirthDate).isoDate,
      telephone: payerNumber,
      address: {
        country_id: payerCountry || undefined,
        state_id: payerState || undefined,
        city_id: payerCity || undefined,
        post_code: payerZipCode,
        street: payerStreet,
        supplement: payerSupplement,
        region: payerDistrict
      },
      additional_information: {
        emergency_telephone: payerEmergencyTelephone?.length > 2 ? payerEmergencyTelephone : undefined,
        emergency_name: payerEmergencyName,
        weight: payerWeight && replaceCommaToDot(payerWeight),
        height: payerHeight && Number(payerHeight.replace(',', ''))
      }
    }
  }
}

export function OfflineTicketUpdateModel (data = {}) {
  const {
    checkinDate,
    checkinTime = '00:00',
    price,
    netPrice,
    observation,
    category,
    externalReservationCode,
    reservationHeader
  } = data
  const [checkinHour, checkinMinute] = checkinTime ? checkinTime.split(':') : []

  const userId = reservationHeader?.agent || undefined

  const slot = checkinDate && new Date(checkinDate.getTime()).setHours(checkinHour, checkinMinute)

  const commonMetaInfoFields = {
    price,
    net_price: netPrice,
    observation,
    external_reservation_code: externalReservationCode,
    user_id: userId
  }

  const accommodationTicketModel = (ticketData = {}) => {
    const { checkoutDate, checkoutTime = '00:00', accommodationType } = ticketData
    const [checkoutHour, checkoutMinute] = checkoutTime ? checkoutTime.split(':') : []

    const checkoutDatetime = checkoutDate && new Date(checkoutDate?.getTime()).setHours(checkoutHour, checkoutMinute)

    return {
      checkout_date: checkoutDatetime && formatISOTime(checkoutDatetime, 'YYYY/MM/DD HH:mm'),
      accommodation_type: accommodationType
    }
  }

  const airTransportFields = (ticketData = {}) => {
    const { airCompanyName } = ticketData
    return { air_company_name: airCompanyName }
  }

  const transportFields = (ticketData = {}) => {
    const { pickupAddress, dropoffAddress, checkinTime } = ticketData
    return { pickup_hour: checkinTime, pickup_address: pickupAddress, dropoff_address: dropoffAddress }
  }

  const activityFields = (ticketData = {}) => {
    const { feeType } = ticketData
    return { fee_type: feeType }
  }

  const carRentalFields = (ticketData = {}) => {
    const {
      companyName,
      driverLicense,
      driverLicenseDueDate,
      transporteType,
      driverAddress,
      touristName,
      state,
      checkinDate,
      checkinTime = '00:00',
      document,
      checkoutDate,
      checkoutTime = '00:00',
      dropoffAddress,
      pickupAddress
    } = ticketData
    return {
      company_name: companyName,
      transporte_type: transporteType,
      driver_license: driverLicense,
      driver_license_due_date: driverLicenseDueDate,
      driver_name: touristName,
      driver_address: driverAddress,
      driver_document: document,
      pickup_date: checkinDate,
      pickup_address: pickupAddress,
      pickup_hour: checkinTime,
      dropoff_address: dropoffAddress,
      dropoff_date: checkoutDate,
      dropoff_hour: checkoutTime,
      state
    }
  }

  const equipmentRental = (ticketData = {}) => {
    const {
      activityName,
      document,
      touristName,
      pickupAddress,
      companyName,
      state,
      observation
    } = ticketData
    return {
      activity_name: activityName,
      document,
      tourist_name: touristName,
      pickup_address: pickupAddress,
      company_name: companyName,
      state,
      observation
    }
  }

  const handleFieldsByCategory = ({ category = '', ticketData }) => {
    switch (category) {
    case 'accommodation':
      return accommodationTicketModel(ticketData)
    case 'air_transport':
      return airTransportFields(ticketData)
    case 'car_rental':
      return carRentalFields(ticketData)
    case 'transport':
      return transportFields(ticketData)
    case 'activity':
      return activityFields(ticketData)
    case 'equipment_rental':
      return equipmentRental(ticketData)
    default:
      return commonMetaInfoFields
    }
  }

  return {
    slot: formatISOTime(slot, 'YYYY/MM/DD HH:mm'),
    offline_ticket_info: {
      ticket_metainfo: {
        ...commonMetaInfoFields,
        ...handleFieldsByCategory({ category, ticketData: data })
      }
    }
  }
}

import { string as yupString, number as yupNumber, date as yupDate } from 'yup'
import { paymentMethodsThatRequiredBankAccount } from 'constants/paymentBatch'

const REQUIRED_PAYMENT_METHOD = 'O método de pagamento é obrigatório'
const REQUIRED_TOTAL_VALUE = 'O valor total é obrigatório'
const REQUIRED_SUB_TOTAL_VALUE = 'O subtotal é obrigatório'
const REQUIRED_BANK_ACCOUNT_ID = 'A conta bancária é obrigatória'

export const retrieveBatchPaymentSchema = ({ maxSubtotalValue = 0 }) => ({
  paymentMethod: yupString().typeError(REQUIRED_PAYMENT_METHOD).required(REQUIRED_PAYMENT_METHOD),
  transactionDate: yupDate().required('A data de pagamento é obrigatória').max(new Date(), 'A data de pagamento não pode ser futura'),
  originBankAccountId: yupString().nullable().when('paymentMethod', {
    is: (paymentMethod) => {
      return (paymentMethodsThatRequiredBankAccount.includes(paymentMethod))
    },
    then: yupString().required(REQUIRED_BANK_ACCOUNT_ID).typeError(REQUIRED_BANK_ACCOUNT_ID)
  }),
  subtotalValue: yupNumber()
    .required(REQUIRED_SUB_TOTAL_VALUE)
    .typeError('O subtotal deve ser um valor numérico.')
    .max(maxSubtotalValue, 'O valor do pagamento não pode ser maior que o valor total'),
  discount: yupString().nullable(),
  totalValue: yupString().required(REQUIRED_TOTAL_VALUE)
})

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { TableBodyRow, TableBodyCell, Swatch, TrashIcon, TooltipOnHover, PencilIcon, ConfirmDialog, flex } from '@bonitour/components'
import { defaultRowHeight, defaultCellPadding, clickable, marginRight } from 'assets/styles/global'
import { identity } from '@bonitour/common-functions'
import { useState } from 'react'

const iconSize = css`
  font-size: 23px;
`

export function ActivityFeeRow ({
  activityFee,
  onUpdate: emitUpdateEvent = identity,
  onDelete: emitDeleteEvent = identity,
  ...other
}) {
  const { color, name, price, periodQuantity } = activityFee

  const [isConfirmActionVisible, setConfirmActionVisible] = useState(false)
  const toggleConfirmActionVisible = () => setConfirmActionVisible(true)
  const toggleConfirmActionHidden = () => setConfirmActionVisible(false)

  const onDeleteFee = () => {
    emitDeleteEvent()
    toggleConfirmActionHidden()
  }

  return (
    <>
      <ConfirmDialog
        title='Exclusão de tarifário'
        message='Essa tarifa será excluída permanentemente. Deseja continuar?'
        isVisible={isConfirmActionVisible}
        successCallback={onDeleteFee}
        cancelCallback={toggleConfirmActionHidden}
      />

      <TableBodyRow css={defaultRowHeight} {...other}>
        <TableBodyCell css={defaultCellPadding}>
          <Swatch size={20} color={color} />
        </TableBodyCell>
        <TableBodyCell css={defaultCellPadding}>{name}</TableBodyCell>
        <TableBodyCell css={defaultCellPadding}>{price}</TableBodyCell>
        <TableBodyCell css={defaultCellPadding}>{periodQuantity}</TableBodyCell>
        <TableBodyCell css={defaultCellPadding} fixed>
          <div css={flex}>
            <TooltipOnHover text='Editar tarifário'>
              <PencilIcon css={[iconSize, clickable, marginRight(10)]} onClick={emitUpdateEvent} />
            </TooltipOnHover>
            <TooltipOnHover text='Excluir tarifário'>
              <TrashIcon css={[iconSize, clickable]} onClick={toggleConfirmActionVisible} />
            </TooltipOnHover>
          </div>
        </TableBodyCell>
      </TableBodyRow>
    </>
  )
}

/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx, css } from '@emotion/core'
import { useQuery } from 'hooks/useQuery'
import { useActivity } from 'contexts/Activity'
import { useTransport } from 'hooks/domains/useTransport'
import { Button, CogIcon, DatePicker, FlagIcon, flex, flexColumn, flexHorizontallyCentered, H1, LoadingContainer, MapPinIcon } from '@bonitour/components'
import { margin, marginTop, width } from 'assets/styles/global'
import { ActivitySelector } from 'containers/Activity/Selector/Selector'
import { useReadableAddress } from 'containers/Address/Address.hooks'
import VacancyTable from './Table/VacancyTable'
import { BookingMapButtons } from './Structure/BookingMapButtons'
import { BookingMapGuide } from './Structure/BookingMapGuide'
import { useOffsetHook } from './hooks/useOffsetHook'
import { useBookingMapDateTime } from './hooks/useBookingMapDateTime'
import { useDateColumns } from './hooks/useDateColumns'
import { useMapSubscriber } from './hooks/useMapSubscriber'
import { useMapDetailSubscriber } from './hooks/useMapDetailSubscriber'
import { useClickOutside, useDebounce } from '@bonitour/app-functions'
import { container, selectActivityContainer, vacancyTableContainer, selectorPadding, datePickerContainer, transportDetails, configIcon, relative, buttonCurrentDate } from './BookingMap.styles'
import { BookingMapOptions } from './BookingMapOptions'
import { useCallback, useMemo, useRef } from 'react'
import { useCompany } from 'contexts/Company'
import { useHistory } from 'react-router-dom'
import { SafeDate } from '@binamik/js-providers'
import { useRouteByType } from 'hooks/useRouteByType'
import { OFFLINE_EXPERIENCE_TYPE, TRANSPORT_TYPE } from 'constants/activityTypes'
import { BookingMapOperationProgress } from 'components/BookingMap/BookingMapOperationProgress'
import { useIsLockedService } from 'hooks/domains/useIsLockedService'

const noMargin = margin(0, 0, 0, 0)
const defaultFlexGrow = css`
  flex-grow: 0;
`

export const ReservationMap = () => {
  const { push } = useHistory()
  const [{ date, email }] = useQuery()
  const { activity } = useActivity()
  const { id: companyId } = useCompany()
  const [tableReference, offset] = useOffsetHook()
  const {
    selectedHour,
    referenceDate,
    setReferenceDate,
    changeHour,
    changeToPrevious,
    changeToNext
  } = useBookingMapDateTime(offset, date)
  const dateColumns = useDateColumns(referenceDate, offset)
  const debouncedDates = useDebounce(dateColumns, 500)
  const { registries, prices, loading } = useMapSubscriber(activity, debouncedDates)
  const tableDetailedData = useMapDetailSubscriber(activity, debouncedDates, selectedHour)

  const transport = useTransport(activity)
  const { longString: originAddress } = useReadableAddress(transport?.transportBase?.originInfo || {})
  const { longString: destinationAddress } = useReadableAddress(transport?.transportBase?.destinationInfo || {})

  const configReference = useRef()
  const [isConfigVisible, setConfigVisibility] = useClickOutside(configReference)
  const onConfigClick = () => setConfigVisibility(!isConfigVisible)
  const isActivityOwner = activity?.companyId === companyId

  const onExceptionalDayEditClick = date => () => {
    return push(`/${activity?.type === TRANSPORT_TYPE ? 'transport' : 'activity'}/${activity?.id}/bookings-day-config?date=${date}`)
  }

  const handleSetToCurrentDate = useCallback(() => {
    setReferenceDate(new SafeDate()?.jsDate)
  }, [setReferenceDate])

  const isCurrentDate = useMemo(
    () => new SafeDate()?.jsDate?.getDate() === referenceDate?.getDate(),
    [referenceDate]
  )

  const {
    isLoading: isLockedActivityLoading,
    isLocked: isLockedActivity
  } = useIsLockedService(activity?.id)

  useRouteByType(`${activity?.id}/bookings`)

  const {
    isTransport,
    isOfflineExperience
  } = useMemo(() => ({
    isTransport: activity?.type === TRANSPORT_TYPE,
    isOfflineExperience: activity?.type === OFFLINE_EXPERIENCE_TYPE
  }), [activity.type])

  if (!activity?.type || isOfflineExperience) {
    return <LoadingContainer />
  }

  return (
    <>
      <div css={container}>
        <div css={selectActivityContainer}>
          <H1 css={noMargin}>Mapa de Vagas</H1>
          <ActivitySelector css={selectorPadding} subdomain='bookings' email={email} />
        </div>
        <div css={flexHorizontallyCentered}>
          <Button
            type='button'
            onClick={handleSetToCurrentDate}
            css={buttonCurrentDate}
            disabled={isCurrentDate}
          >
            Hoje
          </Button>
          <DatePicker onChange={setReferenceDate} value={referenceDate} css={datePickerContainer} />
          {isActivityOwner &&
            <div ref={configReference} css={relative}>
              <BookingMapOptions
                enabled={isConfigVisible}
                onClose={onConfigClick}
                referenceDate={referenceDate}
              />
              <CogIcon css={configIcon} onClick={onConfigClick} />
            </div>}
        </div>
      </div>

      <div css={[flex, flexColumn, defaultFlexGrow]}>
        {transport && (
          <div css={transportDetails}>
            <div>
              <MapPinIcon />
              <b>Local de Saída:</b> {originAddress}
            </div>
            <div>
              <FlagIcon />
              <b>Local de Chegada:</b> {destinationAddress}
            </div>
          </div>
        )}
        <BookingMapOperationProgress
          isLoading={isLockedActivityLoading}
          isOperationInProgress={isLockedActivity}
          customCss={[!isTransport && marginTop(15), width(100)]}
          isTransport={isTransport}
        />
      </div>

      <div css={vacancyTableContainer} ref={tableReference}>
        <BookingMapButtons
          onClickPrevious={changeToPrevious}
          onNextClick={changeToNext}
          offset={offset}
        />
        <VacancyTable
          date={referenceDate}
          dateColumns={dateColumns}
          tableData={registries}
          tablePrices={prices}
          tableDetailedData={tableDetailedData}
          isLoading={loading}
          currentExpandedRowLabel={selectedHour}
          onExpand={changeHour}
          onExceptionalDayEditClick={onExceptionalDayEditClick}
          isActivityOwner={isActivityOwner}
        />
        <BookingMapGuide />
      </div>
    </>
  )
}

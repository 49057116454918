/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import {
  Button,
  Card,
  Column,
  DatePicker,
  InfoSwatch,
  InputFormGroup,
  InputWithSuffix,
  Input,
  Row,
  Select
} from '@bonitour/components'
import { button, cardContrast, width100, initialHeightSufix } from './TicketSelector.style'
import { useForm } from '@bonitour/app-functions'
import { useKeyPress } from 'hooks/useKeyPress'
import { affiliateType } from '../constants/InvoiceBatchType'
import { useCallback, useMemo } from 'react'
import { marginTop } from 'assets/styles/global'
import { BATCHES_ORDERING_COLUMNS_OPTIONS } from 'constants/paymentBatch'

export const TicketFilter = ({
  filters,
  setFilters,
  type,
  onFilterMiddleware = (fn) => {
    fn()
  }
}) => {
  const {
    form,
    errors,
    onSubmit,
    utils: { onInputChange }
  } = useForm(filters)
  const { onKeyDown } = useKeyPress()

  const onClick = onSubmit(() => onFilterMiddleware(() => setFilters(form)))

  const stopPropagation = useCallback((event) => event.stopPropagation(), [])

  const codeExamples = 'COD001, COD002'

  const infoSwatchTooltip = `Para pesquisas simultâneas, insira os códigos separados por vírgulas, ex: ${codeExamples}`

  const filterByCodePlaceholder = codeExamples

  const filterByNamePlaceholder = 'Digite o nome'

  const isAffiliate = useMemo(() => type === affiliateType, [type])

  const externalCodeByType = useMemo(
    () => (isAffiliate ? 'externalTicketIds' : 'externalReservationCode'),
    [isAffiliate]
  )

  return (
    <Card css={cardContrast} onClick={stopPropagation}>
      <Row>
        <Column phone={12} desktop={3.25} tabletup={3.25} customCss={[marginTop(10)]}>
          <InputFormGroup label={'Nome do comprador'} errorMessage={errors.payerName}>
            <Input
              value={form.payerName}
              onChange={onInputChange('payerName')}
              css={width100}
              allowsEmpty
              suffixCss={initialHeightSufix}
              placeholder={filterByNamePlaceholder}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={3.25} tabletup={3.25} customCss={[marginTop(10)]}>
          <InputFormGroup label="Data inicial" errorMessage={errors.startDate}>
            <DatePicker
              value={form.startDate}
              onChange={onInputChange('startDate')}
              css={width100}
              allowsEmpty
              onKeyDown={(event) => onKeyDown(event, onClick)}
              placeholder="__/__/____"
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={3.25} tabletup={3.25} customCss={[marginTop(10)]}>
          {' '}
          <InputFormGroup label="Data final" errorMessage={errors.endDate}>
            <DatePicker
              value={form.endDate}
              onChange={onInputChange('endDate')}
              css={width100}
              allowsEmpty
              onKeyDown={(event) => onKeyDown(event, onClick)}
              placeholder="__/__/____"
            />
          </InputFormGroup>
        </Column>
      </Row>
      <Row>
        <Column phone={12} desktop={3.25} tabletup={3.25} customCss={[marginTop(10)]}>
          <InputFormGroup label={isAffiliate ? 'Voucher' : 'Código externo'} errorMessage={errors[externalCodeByType]}>
            <InputWithSuffix
              value={form[externalCodeByType]}
              onChange={onInputChange(externalCodeByType)}
              css={width100}
              allowsEmpty
              suffixCss={initialHeightSufix}
              placeholder={filterByCodePlaceholder}
            >
              <InfoSwatch size={200} tooltip={infoSwatchTooltip} />
            </InputWithSuffix>
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={3.25} tabletup={3.25} customCss={[marginTop(10)]}>
          <InputFormGroup label="Código da reserva" errorMessage={errors.reservationCodes}>
            <InputWithSuffix
              value={form.reservationCodes}
              onChange={onInputChange('reservationCodes')}
              css={width100}
              suffixCss={initialHeightSufix}
              allowsEmpty
              placeholder={filterByCodePlaceholder}
            >
              <InfoSwatch size={200} tooltip={infoSwatchTooltip} />
            </InputWithSuffix>
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={3.25} tabletup={3.25} customCss={[marginTop(10)]}>
          <InputFormGroup label="Código do ticket" errorMessage={errors.ticketCodes}>
            <InputWithSuffix
              value={form.ticketCodes}
              onChange={onInputChange('ticketCodes')}
              css={width100}
              allowsEmpty
              suffixCss={initialHeightSufix}
              placeholder={filterByCodePlaceholder}
            >
              <InfoSwatch size={200} tooltip={infoSwatchTooltip} />
            </InputWithSuffix>
          </InputFormGroup>
        </Column>
      </Row>
      <Row>
        <Column phone={12} desktop={3.25} tabletup={3.25}>
          <InputFormGroup label="Ordenação" errorMessage={errors.orderBy}>
            <Select
              options={BATCHES_ORDERING_COLUMNS_OPTIONS}
              onChange={onInputChange('orderBy')}
              value={form.orderBy}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={3.25} tabletup={3.25}>
          <Button type="button" customCss={[button]} onClick={onClick}>
            Pesquisar
          </Button>
        </Column>
      </Row>
    </Card>
  )
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { TableContainer, TableHeader, TableHeaderRow, TableHeaderCell, TableBody, TableBodyRow, TableBodyCell } from '@bonitour/components'
import { useMemo } from 'react'
import { formatMoney } from '@bonitour/common-functions'

const getCurrencyValue = (value) => {
  return value ? formatMoney(value) : ''
}

export const CashierValuesToCredit = ({ loading, valuesToCredit, isDebit = false, hideTotal = false, isCashierOpen = true }) => {
  const totalCreditAmount = useMemo(() => {
    return valuesToCredit?.reduce((sum, entry) => sum + Number(entry?.value), 0)
  }, [valuesToCredit])

  return (
    <TableContainer>
      <TableHeader>
        <TableHeaderRow>
          <TableHeaderCell>Empresa parceira</TableHeaderCell>
          <TableHeaderCell>Valor {isDebit ? 'a debitar' : isCashierOpen ? 'a creditar' : 'creditado'}</TableHeaderCell>
        </TableHeaderRow>
      </TableHeader>
      <TableBody loading={loading}>
        {valuesToCredit?.map(({ companyName, value }, idx) => (
          <TableBodyRow
            key={`method-${idx}`}
          >
            <TableBodyCell>{companyName || '-'}</TableBodyCell>
            <TableBodyCell>{getCurrencyValue(value)}</TableBodyCell>
          </TableBodyRow>
        ))}
        {hideTotal
          ? null
          : (
            <TableBodyRow>
              <TableHeaderCell>Total</TableHeaderCell>
              <TableHeaderCell>{getCurrencyValue(totalCreditAmount)}</TableHeaderCell>
            </TableBodyRow>
          )
        }
        {!valuesToCredit?.length
          ? (
            <TableBodyRow>
              <TableBodyCell colSpan={2} style={{ textAlign: 'center' }}>
                Nenhum valor a {isDebit ? 'debitar' : 'creditar'}
              </TableBodyCell>
            </TableBodyRow>
          )
          : null
        }
      </TableBody>
    </TableContainer>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { jsx, css } from '@emotion/core'
import { useForm } from '@bonitour/app-functions'
import { identity, unformatMoney } from '@bonitour/common-functions'
import { Card, Button, H4, useToast, GhostPrimaryButton, BREAK_POINTS } from '@bonitour/components'
import { marginBottom } from 'assets/styles/global'
import { FormHeader } from 'components/FormHeader'
import { bookingSchema } from './BookingForm.schema'
import { ReservationHeader } from '../Form/ReservationHeader/ReservationHeader'
import { PaxSection } from '../Form/PaxSection/PaxSection'
import { ReservationType } from '../Form/ReservationType/ReservationType'
import { useAddressLocation, useZipCode } from 'containers/Address/Address.hooks'
import { usePassenger } from 'domains/Reservation/Passenger/hooks/usePassenger'
import { formHasTickets } from 'app/Booking/Booking.utils'
import { OfflineReservationHeader } from '../Form/ReservationHeader/OfflineReservationHeader'
import { Affiliate } from '../Form/Affiliate/Affiliate'
import { ConfirmationModal } from 'components/ConfirmationModal'

const marginBottom50 = marginBottom(50)

const buttonsContainer = css`
  margin-top: 50px;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    display: flex;
    flex-direction: column;
  }
`

const backButton = css`
  margin-right: 20px;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    margin-bottom: 10px;
    margin-right: 0;
  }
`

export const fillReservationFinancialForm = (form = {}, onInputChange = identity) => ({
  count = undefined,
  unitPrice = undefined,
  unitNetPrice = undefined
}) => {
  const updateField = (field, value) => onInputChange(field)(value)

  const fillData = () => {
    if (count !== undefined) {
      updateField('count', count)
    }

    if (unitPrice !== undefined) {
      updateField('unitPrice', unitPrice)
    }

    if (unitNetPrice !== undefined) {
      updateField('unitNetPrice', unitNetPrice)
    }
  }

  fillData()

  const parsedCount = Number(count || form?.count) || 0

  const parsedUnitPrice = unformatMoney(unitPrice || form.unitPrice) || 0

  const parsedUnitNetPrice = unformatMoney(unitNetPrice || form.unitNetPrice) || 0

  const calculedPrice = parsedCount * parsedUnitPrice

  const calculedNetPrice = parsedCount * parsedUnitNetPrice

  updateField('netPrice', calculedNetPrice)

  updateField('price', calculedPrice)
}

export const BookingForm = ({
  isPartner = false,
  reservationBase = {},
  ticketsList = [],
  partnerList = [],
  onBackClick: emitBackClickEvent = identity,
  onFinish: emitFinishEvent = identity,
  onContinue: emitContinueEvent = identity,
  offlineExperienceCategory = '',
  isRequiredConfirmation = false
}) => {
  const { add: addToast } = useToast()

  const [countrySelected, setCountrySelected] = useState('')
  const [stateSelected, setStateSelected] = useState('')
  const [mustReservationTypeValidation, setReservationTypeValidation] = useState(true)
  const [requestedZipCode, requestZipCodeInfo] = useZipCode(addToast)
  const { countries, states, cities, updateAvailableStates, updateAvailableCities } = useAddressLocation(addToast, countrySelected, stateSelected)

  const isOfflineExperience = useMemo(() => Boolean(offlineExperienceCategory), [offlineExperienceCategory])

  const schema = useMemo(() => bookingSchema(states, cities, mustReservationTypeValidation, offlineExperienceCategory), [states, cities, mustReservationTypeValidation, offlineExperienceCategory])
  const { form, errors, onSubmit, setForm, utils: { onInputBlur, onInputChange } } = useForm(reservationBase, schema)
  const { passengersFound, onSearchPassengers, searchPassengerLoading } = usePassenger()
  const { id, email } = form?.reservationType || {}

  const isFormValid = useMemo(() => ((form?.tickets && formHasTickets(form)) || isOfflineExperience) && (id || email), [form, isOfflineExperience, id, email])

  const onValidationError = () => (id || email) ? addToast('Preencha corretamente o formulário') : addToast('Informe o responsável pela reserva')

  useEffect(() => {
    if (ticketsList && ticketsList.length) {
      onInputChange('tickets')(ticketsList)
    }
    // eslint-disable-next-line
  }, [ticketsList])

  const [modalMetaData, setModalMetaData] = useState({
    onSubmit: () => {},
    isOpenedModal: false
  })

  const toggleModal = useCallback(() => setModalMetaData((curr) => ({
    ...curr,
    isOpenedModal: !curr.isOpenedModal
  })), [])

  const onSubmitMask = useCallback((emitEvent = () => {}) => () => {
    setModalMetaData((curr) => ({ ...curr, isOpenedModal: false }))
    emitEvent()
  }, [])

  const onFinish = useCallback((...args) => {
    if (!isRequiredConfirmation) {
      return emitFinishEvent(...args)
    }
    setModalMetaData(() => ({
      onSubmit: onSubmitMask(() => emitFinishEvent(...args)),
      isOpenedModal: true
    }))
  }, [emitFinishEvent, isRequiredConfirmation, onSubmitMask])

  const onContinue = useCallback((...args) => {
    if (!isRequiredConfirmation) {
      return emitContinueEvent(...args)
    }
    setModalMetaData(() => ({
      onSubmit: onSubmitMask(() => emitContinueEvent(...args)),
      isOpenedModal: true
    }))
  }, [emitContinueEvent, isRequiredConfirmation, onSubmitMask])

  const onFinishClick = onSubmit(onFinish, onValidationError)
  const onContinueClick = onSubmit(onContinue, onValidationError)

  const onCountryChange = useCallback(
    (countryValue) => {
      const country = countries.find((country) => Object.values(country).includes(countryValue))
      if (country) {
        updateAvailableStates(country.value)
        onInputChange('reservationType.country')(country.value)
      } else {
        updateAvailableStates(null)
        onInputChange('reservationType.country')('')
        onInputChange('reservationType.state')('')
        onInputChange('reservationType.city')('')
      }
    },
    [countries, updateAvailableStates, onInputChange]
  )

  const onStateChange = useCallback(
    (stateValue) => {
      const state = states.find((state) => Object.values(state).includes(stateValue))
      if (state) {
        updateAvailableCities(form.reservationType?.country, state.value)
        onInputChange('reservationType.state')(state.value)
      } else {
        updateAvailableCities(null)
        onInputChange('reservationType.state')('')
        onInputChange('reservationType.city')('')
      }
    },
    [states, updateAvailableCities, form.reservationType?.country, onInputChange]
  )

  const onCityChange = useCallback(
    (cityValue) => {
      const city = cities.find((city) => Object.values(city).includes(cityValue))
      if (city) {
        onInputChange('reservationType.city')(city.value)
      } else {
        onInputChange('reservationType.city')('')
      }
    },
    [cities, onInputChange]
  )

  const onZipCodeBlur = useCallback(() => {
    requestZipCodeInfo(form.reservationType?.zipCode)
    onInputBlur('reservationType.zipCode')()
  }, [form.reservationType?.zipCode, onInputBlur, requestZipCodeInfo])

  const updateAddress = useCallback(() => {
    if (Object.keys(requestedZipCode).length) {
      setForm(previousForm => ({
        ...previousForm,
        reservationType: {
          ...previousForm.reservationType,
          street: requestedZipCode.address,
          district: requestedZipCode.district,
          state: requestedZipCode.state,
          city: requestedZipCode.city
        }
      }))
      onCountryChange('BR')
    }
  }, [onCountryChange, requestedZipCode, setForm])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updateAddress, [requestedZipCode])

  const updateCountries = useCallback(() => {
    if (countries?.length) {
      onCountryChange(form.reservationType?.country)
    }
  }, [countries?.length, onCountryChange, form.reservationType?.country])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updateCountries, [countries])

  const updateStates = useCallback(() => {
    if (states?.length) {
      onStateChange(form.reservationType?.state)
    }
  }, [states?.length, onStateChange, form.reservationType?.state])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updateStates, [states])

  const updateCities = useCallback(() => {
    if (cities?.length) {
      onCityChange(form.reservationType?.city)
    }
  }, [cities?.length, onCityChange, form.reservationType?.city])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updateCities, [cities])

  const hasSeveralTickets = useMemo(() => form.count > 1, [form])

  const updateFinancialData = useCallback((form, onChange) => fillReservationFinancialForm(form, onChange), [])

  const onInputChangeMiddleware = useCallback((field) => {
    const onFinancialDataChange = updateFinancialData(form, onInputChange)
    switch (field) {
    case 'count':
      return (count) => onFinancialDataChange({ count })
    case 'unitNetPrice':
      return (unitNetPrice) => onFinancialDataChange({ unitNetPrice })
    case 'unitPrice':
      return (unitPrice) => onFinancialDataChange({ unitPrice })
    default:
      return onInputChange(field)
    }
  }, [updateFinancialData, form, onInputChange])

  return (
    <>
      <FormHeader title='Nova reserva' ghostClick={emitBackClickEvent} />
      <Card css={marginBottom50}>
        <ConfirmationModal
          isVisible={modalMetaData.isOpenedModal}
          onClose={toggleModal}
          handleAddTicket={modalMetaData.onSubmit}
          isAllowedRenderWithoutDialog={true}
          isPluralizedLabels={hasSeveralTickets}>
          {offlineExperienceCategory
            ? (
              <OfflineReservationHeader
                form={form}
                errors={errors}
                onInputChange={onInputChangeMiddleware}
                onInputBlur={onInputBlur}
                offlineExperienceCategory={offlineExperienceCategory}
                disabled={modalMetaData.isOpenedModal}
                isOpenConfirmationModal={modalMetaData.isOpenedModal}
              />
            )
            : (
              <ReservationHeader
                isPartner={isPartner}
                reservationHeader={form.reservationHeader}
                reservationHeaderErrors={errors.reservationHeader}
                tickets={form.tickets}
                comments={form.comments}
                onChange={onInputChangeMiddleware}
                onBlur={onInputBlur}
                partnerList={partnerList}
                css={marginBottom50}
                disabled={modalMetaData.isOpenedModal}
              />
            )}
        </ConfirmationModal>
        <H4>Vagas</H4>

        <PaxSection
          title='Tipificação base'
          isExpanded
          tickets={form.tickets}
          ticketsErrors={errors.tickets}
          onChange={onInputChangeMiddleware}
          onBlur={onInputBlur}
          isOfflineExperience={isOfflineExperience}
          offlineReservation={form}
          offlineReservationErrors={errors}
          disabled={modalMetaData.isOpenedModal}
        />

        <Affiliate
          affiliate={form.affiliateCode}
          affiliateErrors={errors.affiliateCode}
          onChange={onInputChangeMiddleware}
          onBlur={onInputBlur}
          isDisabled={modalMetaData.isOpenedModal}
        />

        <ReservationType
          reservationType={form.reservationType}
          reservationTypeErrors={errors.reservationType}
          countries={countries}
          states={states}
          cities={cities}
          updateAvailableStates={updateAvailableStates}
          updateAvailableCities={updateAvailableCities}
          onZipCodeBlur={onZipCodeBlur}
          setCountrySelected={setCountrySelected}
          setStateSelected={setStateSelected}
          onChange={onInputChangeMiddleware}
          onBlur={onInputBlur}
          onSearchPassenger={onSearchPassengers}
          passengersFound={passengersFound?.passengers}
          searchPassengerLoading={searchPassengerLoading}
          setReservationTypeValidation={setReservationTypeValidation}
          initialEmail={form.initialEmail}
          disabled={modalMetaData.isOpenedModal}
        />
        <div css={buttonsContainer}>
          <GhostPrimaryButton css={backButton} onClick={onContinueClick} disabled={!isFormValid}>
            Reservar e Continuar
          </GhostPrimaryButton>
          <Button onClick={onFinishClick} disabled={!isFormValid}>Finalizar Reserva</Button>
        </div>
      </Card>
    </>
  )
}

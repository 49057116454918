import { useCallback, useState } from 'react'
import { useToast } from '@bonitour/components'
import { apiPaymentMethods } from 'constants/paymentMethods'
import { ADVANCE_RESERVATION_PAYMENT } from 'constants/cashierTypeMap'

export const useTransactionProviderDetails = ({
  approvePaymentTransaction,
  approveProviderRefundTransaction,
  rejectPaymentTransaction,
  rejectProviderPaymentTransaction,
  getPaymentById,
  getRefundById,
  getAdvancedRefundById,
  getAdvancePaymentById
}) => {
  const { add: addToast } = useToast()

  const [isPaymentVisible, setPaymentVisibility] = useState(false)
  const [payment, setPayment] = useState({})
  const [paymentLabels, setPaymentLabels] = useState({ detailTitle: 'Visualizar pagamento', amountDueLabel: 'Valor pago' })
  const [loading, setLoading] = useState(false)

  const closePaymentDetail = () => {
    setPaymentVisibility(false)
    setPayment({})
  }

  const runAndClose = (asyncFunction, successMessage = '', errorMessage = '') => (...params) => {
    setLoading(true)
    asyncFunction(...params)
      .then(() => {
        addToast(successMessage, 'success')
        closePaymentDetail()
      })
      .catch(() => {
        addToast(errorMessage)
        closePaymentDetail()
      })
      .finally(() => setLoading(false))
  }

  const openPayment = useCallback(async (id, { isRefund, method, itemType }) => {
    setLoading(true)
    const isPay = method === apiPaymentMethods.pay_credit
    if (isRefund) {
      try {
        const paymentData = isPay ? await getRefundById(id) : await getAdvancedRefundById(id)
        setPayment({ ...paymentData, isRefund })
        setPaymentLabels({ detailTitle: 'Visualizar estorno', amountDueLabel: 'Valor pago' })
        setPaymentVisibility(true)
        setLoading(false)
      } catch (error) {
        addToast('Erro ao buscar detalhes do pagamento')
        setLoading(false)
      }
    } else {
      try {
        const paymentData = itemType === ADVANCE_RESERVATION_PAYMENT
          ? await getPaymentById(id)
          : await getAdvancePaymentById(id)

        setPayment({ ...paymentData, isRefund })

        setPaymentVisibility(true)
      } catch (_error) {
        addToast('Erro ao buscar detalhes do pagamento')
      } finally {
        setLoading(false)
      }
    }
  }, [addToast, getAdvancePaymentById, getAdvancedRefundById, getPaymentById, getRefundById])

  const aproveTransaction = runAndClose(approvePaymentTransaction, 'Transação aprovada com sucesso', 'Ocorreu um erro ao aprovar a transação')
  const aproveRefundTransaction = runAndClose(approveProviderRefundTransaction, 'Transação aprovada com sucesso', 'Ocorreu um erro ao aprovar a transação')
  const rejectTransaction = runAndClose(rejectPaymentTransaction, 'Transação recusada com sucesso', 'Ocorreu um erro ao recusar a transação')
  const rejectRefundTransaction = runAndClose(rejectProviderPaymentTransaction, 'Transação recusada com sucesso', 'Ocorreu um erro ao recusar a transação')

  return {
    isPaymentVisible,
    closePaymentDetail,
    payment,
    openPayment,
    aproveTransaction,
    aproveRefundTransaction,
    rejectTransaction,
    rejectRefundTransaction,
    paymentLabels,
    loading
  }
}

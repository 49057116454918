import { MetaParser } from './Meta'
import { cardBrands, providers } from '../../constants/acquirers'

export const AcquirerParser = {
  singleProvider: (provider) => {
    return {
      label: providers[provider.name] ?? provider.name,
      name: provider.name,
      id: provider.id
    }
  },
  listProviders: (data) => {
    const { payment_providers = [] } = data || {}

    return payment_providers.map(AcquirerParser.singleProvider)
  },
  single: (acquirer) => {
    const {
      payment_provider_name: name,
      brand_name,
      card_brand_name,
      transaction_type: transactionType,
      payment_mean: paymentMean,
      fees: feesObject,
      company_id: companyId,
      id,
      bank_account
    } = acquirer?.acquirer ?? acquirer ?? {}

    const fees = Object.keys(feesObject ?? {}).reduce((feesList, installments) => ([...feesList, { installments, feePercentage: Number(feesObject[installments]) * 1000 / 10 }]), [])

    return {
      name,
      brand: brand_name ?? card_brand_name,
      transactionType,
      fees,
      companyId,
      paymentMean,
      id,
      bankAccountId: bank_account?.id
    }
  },
  list: ({ meta, acquirers = [] }) => {
    return {
      acquirers: acquirers.map(AcquirerParser.single),
      meta: MetaParser.meta(meta)
    }
  },
  listAll: ({ acquirers = [] }) => {
    return acquirers.map(AcquirerParser.single)
  },
  listCardBrands: ({ card_brands = [] }) => {
    return card_brands.map((cardBrand) => ({
      name: cardBrand.brand_name,
      id: cardBrand.id,
      brand: cardBrands[cardBrand.brand_name] ?? cardBrand.brand_name.raplaceAll('_', ' ')
    }))
  }

}

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { useCallback, useMemo } from 'react'
import {
  colors,
  InfoSwatch,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TruncateCell
} from '@bonitour/components'
import { formatMoney } from '@bonitour/common-functions'
import { tableContainer, cellWithTooltip, cellWithBreakpoint } from './Cashier.style'
import { cashierPaymentTypesMap } from 'constants/cashierPaymentTypes'
import { originTypesMap, PAYMENT_MULTIPOINT, CASHIER_HANDLING_CASH_OUT_FLOW, RESERVATION_REFUND, ADVANCE_RESERVATION_PAYMENT } from 'constants/cashierTypeMap'
import { apiPaymentMethods } from 'constants/paymentMethods'
import { formatISOTime } from 'utils/time'
import { SafeDate } from '@binamik/js-providers'
import { providers, cardBrands } from 'constants/acquirers'

const operationTypeColor = {
  entry: colors.green2,
  reversal: colors.red3,
  outFlow: colors.red3
}

const isOutflow = (originType) => originType === CASHIER_HANDLING_CASH_OUT_FLOW

const isReversal = (originType) => [RESERVATION_REFUND, ADVANCE_RESERVATION_PAYMENT].includes(originType)

const cellStyle = ({ operationType, originType }) => css`
  color: ${operationTypeColor[isOutflow(originType) || isReversal(originType) ? 'outFlow' : operationType]};
`

const tooltipDescription = css`
  line-height: 1rem;
  > [class*="tooltip-on-hover"] {
    width: max-content;
    max-width: 300px;
  }
`

const resetAlign = css`
  width: min-content;
`

export const CashierOperationsTable = ({ operations, loading, isCashierOpen }) => {
  return (
    <div css={tableContainer}>
      <TableContainer style={{ marginTop: 16 }}>
        <TableHeader>
          <TableHeaderRow>
            <TableHeaderCell>Data</TableHeaderCell>
            <TableHeaderCell>Operação</TableHeaderCell>
            <TableHeaderCell>Forma de pagamento</TableHeaderCell>
            <TableHeaderCell>Parcelas</TableHeaderCell>
            <TableHeaderCell>Doc/Aut</TableHeaderCell>            <TableHeaderCell>Data Pgto</TableHeaderCell>
            <TableHeaderCell>Valor</TableHeaderCell>
          </TableHeaderRow>
        </TableHeader>
        <TableBody loading={loading}>
          {operations?.map((operation) => (
            <CashierOperationsRow operation={operation} key={`cashier-operation-${operation.id}`} isCashierOpen={isCashierOpen} />
          ))}
        </TableBody>
      </TableContainer>
    </div>
  )
}

export const CashierOperationsRow = ({
  operation: {
    value,
    polymorphicInfo,
    operationDate,
    operationType,
    paymentMethod,
    originType,
    installments,
    documentNumber,
    description
  },
  isCashierOpen
}) => {
  const joinStyles = useCallback((...styles) => css`${styles}`, [])

  const valueToCredit = useMemo(() => Number(polymorphicInfo?.payment?.value_to_credit || 0), [polymorphicInfo])

  const multipointName = useMemo(
    () => polymorphicInfo?.multiponto_vendor_name || '',
    [polymorphicInfo?.multiponto_vendor_name]
  )

  const currentCellStyle = useMemo(() => cellStyle({ operationType, originType }), [operationType, originType])

  const formattedValue = useMemo(() => formatMoney(value), [value])

  const formattedValueToCredit = useMemo(() => formatMoney(valueToCredit), [valueToCredit])

  const hasRedirectUri = useMemo(() => Object.keys(originTypesMap).includes(originType), [originType])

  const redirectUri = useMemo(() => {
    if (hasRedirectUri && polymorphicInfo?.reservation_id) {
      return originTypesMap[originType]?.redirectUri.replaceAll('$id', polymorphicInfo?.reservation_id)
    }

    return ''
  }, [hasRedirectUri, originType, polymorphicInfo?.reservation_id])

  const isPaymentMultiPoint = useMemo(() => originType === PAYMENT_MULTIPOINT, [originType])

  const parsedOriginType = useMemo(() => {
    if (isPaymentMultiPoint) {
      return originTypesMap[originType]?.label(multipointName)
    }

    return originTypesMap[originType].label
  }, [isPaymentMultiPoint, multipointName, originType])

  const paymentOrRefundDate = useMemo(() => {
    const paymentDate = polymorphicInfo?.reservation_payment_date || polymorphicInfo?.advance_reservation_payment_date
    if (paymentDate) return formatISOTime(paymentDate, 'DD/MM/YY')
    const refundDate = polymorphicInfo?.reservation_refund_date
    if (refundDate) return formatISOTime(refundDate, 'DD/MM/YY')
    return '##'
  }, [polymorphicInfo])

  const hasCredit = useMemo(() => valueToCredit > 0, [valueToCredit])

  const creditLabel = useMemo(() => {
    const creditedLabel = 'Valor creditado'
    const notCreditedLabel = 'Valor a creditar'
    const label = isCashierOpen ? notCreditedLabel : creditedLabel
    return `${label}: ${(valueToCredit && formattedValueToCredit) || formatMoney(0)}`
  }, [formattedValueToCredit, isCashierOpen, valueToCredit])

  const operationDateLabel = useMemo(() => {
    const dateSplitted = operationDate?.split('/')
    const day = Number(dateSplitted[0])
    const month = Number(dateSplitted[1]) - 1
    const year = Number(dateSplitted[2])
    const date = new Date().setFullYear(year, month, day)
    return new SafeDate(date).format('DD/MM/YY')
  }, [operationDate])

  const joinNonEmptyValues = useCallback((values = []) => {
    return values?.filter(value => value).join(' - ')
  }, [])

  const mountCardPaymentLabel = useCallback((
    acquirer_name,
    card_brand,
    defaultReturn = ''
  ) => {
    const multiPointAddon = isPaymentMultiPoint ? parsedOriginType : ''

    const acquirer = providers?.[acquirer_name]

    const brand = cardBrands?.[card_brand]

    return joinNonEmptyValues([defaultReturn, acquirer, brand, multiPointAddon])
  }, [isPaymentMultiPoint, joinNonEmptyValues, parsedOriginType])

  const mountCommonPaymentLabel = useCallback((
    acquirer_name = '',
    origin_bank_account_name = '',
    destination_bank_account_name = '',
    defaultReturn = ''
  ) => {
    const accountName = origin_bank_account_name || destination_bank_account_name

    if (paymentMethod === apiPaymentMethods.pix && Boolean(acquirer_name)) {
      return joinNonEmptyValues([defaultReturn, providers?.[acquirer_name] || acquirer_name])
    }

    return joinNonEmptyValues([defaultReturn, accountName])
  }, [joinNonEmptyValues, paymentMethod])

  const paymentMethodLabel = useMemo(() => {
    const {
      isPaymentWithCard,
      isCommonPayment,
      defaultReturn,
      acquirer_name,
      card_brand,
      origin_bank_account_name,
      destination_bank_account_name
    } = (function () {
      const isCommonPayment = [apiPaymentMethods.pix, apiPaymentMethods.bank_transfer, apiPaymentMethods.bank_billet].includes(paymentMethod)

      const isPaymentWithCard = [apiPaymentMethods.credit, apiPaymentMethods.debit].includes(paymentMethod)

      const defaultReturn = cashierPaymentTypesMap?.[paymentMethod]

      const { acquirer_name = '', card_brand = '' } = polymorphicInfo?.payment ?? polymorphicInfo?.refund ?? {}

      const { origin_bank_account_name, destination_bank_account_name } = polymorphicInfo ?? {}

      return {
        defaultReturn,
        acquirer_name,
        card_brand,
        origin_bank_account_name,
        destination_bank_account_name,
        isPaymentWithCard,
        isCommonPayment
      }
    }())

    if (isPaymentWithCard) {
      return mountCardPaymentLabel(
        acquirer_name,
        card_brand,
        defaultReturn
      )
    }

    if (isCommonPayment) {
      return mountCommonPaymentLabel(
        acquirer_name,
        origin_bank_account_name,
        destination_bank_account_name,
        defaultReturn
      )
    }

    return defaultReturn || '-'
  }, [mountCardPaymentLabel, mountCommonPaymentLabel, paymentMethod, polymorphicInfo])

  const operationLabel = useMemo(() => isPaymentMultiPoint ? 'Pagamento' : parsedOriginType, [isPaymentMultiPoint, parsedOriginType])

  return (
    <TableBodyRow>
      <TableBodyCell>
        {operationDateLabel}
      </TableBodyCell>
      <TruncateCell
        css={currentCellStyle}
        text={operationLabel}
        toolipText={description}
        tooltipStyle={[tooltipDescription, resetAlign]}
      />
      <TableBodyCell css={cellWithBreakpoint}>
        <a target="_blank" href={redirectUri} rel="noreferrer">
          {paymentMethodLabel}
        </a>
      </TableBodyCell>
      <TruncateCell text={installments} tooltipStyle={[resetAlign]} />
      <TruncateCell text={documentNumber} tooltipStyle={resetAlign} />
      <TruncateCell text={paymentOrRefundDate} tooltipStyle={[resetAlign]} />
      <TableBodyCell css={joinStyles(cellWithTooltip, currentCellStyle)}>
        {value && formattedValue}
        {hasCredit && <InfoSwatch tooltip={creditLabel} />}
      </TableBodyCell>
    </TableBodyRow>
  )
}

import { useCallback, useEffect, useState } from 'react'
import { useToast } from '@bonitour/components'
import { BankAccountService } from 'core/services/BankAccount'
import { usePermissions } from 'hooks/usePermissions'

export const useEditBankAccount = (bankAccountId = '') => {
  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()
  const [loading, setLoading] = useState(false)
  const [bankAccount, setBankAccount] = useState()

  const fetchBankAccount = useCallback(({ bankAccountId }) => {
    setLoading(true)
    return BankAccountService.getById(bankAccountId).then(setBankAccount)
      .catch((error) => {
        const hasPermission = handlePermission(error, 'Você não possui permissão de acesso a conta bancária')
        hasPermission && addToast('Ocorreu um erro ao realizar a busca')
      })
      .finally(() => setLoading(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    bankAccountId && fetchBankAccount({ bankAccountId })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankAccountId])

  const updateBankAccount = async (bankAccount) => {
    return BankAccountService.update(bankAccount, bankAccountId)
  }

  const toggleEnabledBankAccount = async (bankAccountId) => {
    return BankAccountService.toggleEnabled(bankAccountId)
  }

  return {
    loading,
    bankAccount,
    fetchBankAccount,
    updateBankAccount,
    toggleEnabledBankAccount
  }
}

/* eslint-disable camelcase */
import { MetaParser } from 'core/parsers/Meta'
import dayjs from 'dayjs'

export const AffiliateParser = {
  instance (affiliate = {}) {
    const {
      accept_date: acceptDate,
      affiliate_code: affiliateCode,
      affiliate_link: affiliateLink,
      company_id: companyId,
      percentage_by_service: percentageByService,
      services_info: servicesInfo,
      to_release_value: toReleaseValue,
      total_available_value: totalAvailableValue,
      total_commission_value: totalCommissionValue,
      total_sold_value: totalSoldValue,
      total_tickets_sold_amount: totalTicketsSoldAmount,
      total_tickets_sold_value: totalTicketsSoldValue,
      user_id: userId,
      email,
      id,
      name,
      percentage,
      status
    } = affiliate

    return {
      acceptDate,
      affiliateCode,
      affiliateLink,
      companyId,
      percentageByService,
      servicesInfo,
      toReleaseValue,
      totalAvailableValue,
      totalCommissionValue,
      totalSoldValue,
      totalTicketsSoldAmount,
      totalTicketsSoldValue,
      userId,
      email,
      id,
      name,
      percentage,
      status
    }
  },
  affiliateTicketToBatch: (affiliateTicketToBatch) => {
    const {
      affiliate_info: {
        affiliate_code: affiliateCode,
        affiliate_commission: affiliateCommission,
        percentage
      },
      id,
      price,
      slot
    } = affiliateTicketToBatch

    return {
      id,
      price,
      slot,
      affiliateCode,
      affiliateCommission,
      percentage
    }
  },
  affiliateTicketsToBatch: ({ meta, affiliate_tickets: affiliateTicketsToBatch = [] }) => {
    return {
      affiliates: affiliateTicketsToBatch.map(AffiliateParser.affiliateTicketToBatch),
      meta: MetaParser.meta(meta)
    }
  },
  options: ({ affiliates }) => {
    return affiliates
      .filter(({ name }) => name !== null)
      .map(({ affiliate_code: affiliateCode, name, id }) => ({ affiliateCode, name, id }))
  },
  affiliateInfo: ({ affiliateInfo }) => {
    const {
      affiliate_code: affiliateCode,
      affiliate_commission: affiliateCommission,
      percentage
    } = affiliateInfo

    return {
      affiliateCode,
      affiliateCommission,
      percentage
    }
  },
  paymentBatch: (paymentBatch) => {
    const {
      id,
      company_id: companyId,
      beneficiary_id: beneficiaryId,
      beneficiary_type: beneficiaryType,
      value,
      status,
      payment_method: paymentMethod,
      reference_number: referenceNumber,
      payment_date: paymentDate,
      expected_payment_date: expectedPaymentDate,
      batch_type: batchType,
      user_id: userId,
      observation,
      origin_bank_account_id: originBankAccountId,
      payment_batch_items: paymentBatchItems
    } = paymentBatch

    return {
      id,
      companyId,
      beneficiaryId,
      beneficiaryType,
      value,
      status,
      paymentMethod,
      referenceNumber,
      paymentDate: paymentDate ? dayjs(paymentDate.split('T')[0]).toDate() : undefined,
      expectedPaymentDate: expectedPaymentDate ? dayjs(expectedPaymentDate.split('T')[0]).toDate() : undefined,
      batchType,
      userId,
      observation,
      originBankAccountId,
      paymentBatchItems: paymentBatchItems ? paymentBatchItems.map(AffiliateParser.paymentBatchItems) : []
    }
  },
  paymentBatchItems: (paymentBatchItems) => {
    const {
      item_id,
      item_type: itemType,
      observation,
      payment_batch_id: paymentBatchId,
      value,
      ticket_code: ticketCode,
      passenger_name: passengerName,
      slot,
      external_code: externalCode,
      offline_external_reservation_code: offlineExternalReservationCode,
      offline_price: offlinePrice,
      offline_net_price: offlineNetPrice,
      affiliate_code: affiliateCode,
      affiliate_percentage: affiliatePercentage
    } = paymentBatchItems

    return {
      id: item_id,
      itemType,
      observation,
      paymentBatchId,
      value,
      ticketCode,
      passengerName,
      slot,
      externalCode,
      offlineExternalReservationCode,
      offlinePrice,
      offlineNetPrice,
      affiliateCode,
      affiliatePercentage
    }
  },
  paginatedList: ({ affiliates, meta }) => {
    const parsedAffiliates = affiliates.map(AffiliateParser.instance)
    const { total_entries: totalEntries, current_page: page, total_pages: totalPages } = meta

    return {
      affiliates: parsedAffiliates,
      meta: {
        totalEntries,
        page,
        totalPages
      }
    }
  }
}

import { unmaskPhone } from '@bonitour/common-functions'

export const PassengerParser = {
  single: ({ passenger = {} }) => {
    const {
      id,
      user_id: userId,
      full_name: fullName,
      email,
      birth_date: birthDate,
      document,
      telephone: number,
      address,
      additional_information: additionalInformation
    } = passenger

    const phone = unmaskPhone(number || '')
    const phoneNumberWithDDI = phone?.length <= 10 ? `55${phone}` : phone

    return {
      id,
      userId,
      fullName,
      email,
      birthDate,
      documentType: '',
      document,
      phone: phoneNumberWithDDI,
      emergencyName: additionalInformation?.emergency_name,
      emergencyTelephone: additionalInformation?.emergency_telephone,
      weight: additionalInformation?.weight,
      height: String(additionalInformation?.height),
      country: address?.country_id,
      state: address?.state_id,
      city: address?.city_id
    }
  },
  list: ({ passengers = [] }) => {
    return {
      passengers: passengers.map(passenger => PassengerParser.single({ passenger }))
    }
  }

}

import { string } from 'yup'

const validateDuration = duration => Boolean(duration.match(/^\d\d:\d\d$/))

export const activityAboutSchema = {
  duration: string()
    .required()
    .test('test-validateDuration', 'A duração deve ser válida', validateDuration),
  workDuration: string()
    .required()
    .test('test-validateDuration', 'A duração deve ser válida', validateDuration)
}

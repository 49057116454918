import { colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const backdrop = css`
  width: 100%;
  height: calc(100% + 60px);
  z-index: 10;
`

export const container = css`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${colors.white};
  z-index: 15;
`

export const content = css`
  display: flex;
  justify-content: space-between;
  margin: 30px 60px 30px 60px;
`

export const header = css`
  margin: 30px 60px;
`

export const closeIcon = css`
  font-size: 24px;
  color: ${colors.gray3};
  cursor: pointer;
  background-color: ${colors.primary};
  border-radius: var(--small-round-element-radius, 50%);
  height: 30px;
  width: 30px;
  color: ${colors.white};
  font-size: 17px;
`

export const transition = (className, duration) => css`
  transition: transform ${duration}ms linear;

  &.${className}-enter {
    transform: translateY(100%);
  }
  &.${className}-enter-active {
    transform: translateY(0);
  }
  &.${className}-exit-active {
    transform: translateY(100%);
  }
`

import React, { createContext, useCallback, useState, useEffect } from 'react'
import { SellersService } from 'services/Sellers/Service'
import { getImageUrl } from 'services/Orb/Service'
import { useToast } from '@bonitour/components'

export const PaginatedSellersContext = createContext()

const handleGetCompanies = async ({
  page,
  pageLimit,
  toSearch
}) => {
  const parseSellers = (sellersList) => {
    const sellersParsed = sellersList.map((seller) => {
      return { ...seller, ...(seller.image && { image: getImageUrl(seller.image) }) }
    })
    return sellersParsed
  }

  if (toSearch && toSearch.trim().length > 0) {
    const companySearchRes = await SellersService.searchCompany(
      toSearch, pageLimit, page
    ).then(res => res)

    return {
      sellers: parseSellers(companySearchRes.sellers),
      totalPages: companySearchRes.meta.total_pages,
      total: companySearchRes.meta.total_entries
    }
  } else {
    const allCompaniesRes = await SellersService.getPaginatedList(
      page, pageLimit
    ).then(res => res)

    return allCompaniesRes
  }
}

export const PaginatedSellersProvider = ({ children }) => {
  const [sellersInfo, setSellersInfo] = useState({ sellers: [] })
  const [loadingSellers, setLoadingSellers] = useState(false)
  const [toSearch, setToSearch] = useState('')
  const { add: addToast } = useToast()
  const [pagination, setPagination] = useState({ currentPage: 1, pageLimit: 10 })

  const getCompanies = useCallback(({
    page,
    pageLimit,
    searchParams = ''
  }) => {
    setLoadingSellers(true)
    handleGetCompanies({
      page,
      pageLimit,
      toSearch: searchParams
    })
      .then((data) => setSellersInfo(data))
      .catch(() => addToast('Ocorreu um erro ao realizar a busca'))
      .finally(() => setLoadingSellers(false))
  }, [addToast])

  const handlePagination = useCallback((currentPage, pageLimit) => {
    setPagination({ currentPage, pageLimit })
    getCompanies({
      page: currentPage,
      pageLimit,
      searchParams: toSearch
    })
  }, [toSearch, getCompanies])

  const handleSearch = useCallback((newSearch) => {
    setToSearch(newSearch)
    setPagination({ currentPage: 1, pageLimit: pagination.pageLimit })
    getCompanies({
      searchParams: newSearch,
      page: 1,
      pageLimit: pagination.pageLimit
    })
  }, [getCompanies, pagination])

  useEffect(() => {
    getCompanies({
      page: 1,
      pageLimit: pagination.pageLimit
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <PaginatedSellersContext.Provider value={{
    sellersInfo,
    loadingSellers,
    toSearch,
    pagination,
    handlePagination,
    handleSearch
  }}>
    {children}
  </PaginatedSellersContext.Provider>
}

import { css } from '@emotion/core'
import { colors, BREAK_POINTS } from '@bonitour/components'

export const offlineExperienceTicketFormContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
  max-height: min(550px, calc(100vh - 120px));
  overflow: show;

  &.with_overflow {
    overflow: auto;
  }

  .selectors__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
  }

  @media (max-width: ${BREAK_POINTS.smallTablet}) {
    .selectors__container {
      display: flex;
      flex-direction: column;
      grid-gap: 10px;
    }
  }
`

export const buttonsContainer = css`
  display: flex;
  flex-direction: row;
  gap: 10px;
`

export const rowStyle = css`
  .date__input > div > div {
    width: 100%;
  }

  .horizontal__card__container {
    border: 1px solid ${colors.gray8};
    border-radius: 10px;
    padding: 20px;
    margin-top: 5px;
  }

  .vertical__card__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid ${colors.gray8};
    border-radius: 10px;
    padding: 20px;
    gap: 10px;
    margin-top: 5px;
  }

  .conductor__container {
    display: flex;
    flex-direction: column;
    border: 1px solid ${colors.gray8};
    border-radius: 10px;
    gap: 10px;
    padding: 20px;
    margin-top: 5px;


    .conductor__row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .full_width {
    width: 100%;
  }

  @media (max-width: ${BREAK_POINTS.smallTablet}) {
    .horizontal__card__container {
      flex-direction: column;
    }
    .conductor__container {
      flex-direction: column;
      gap: 0;

      .conductor__row {
        flex-direction: column;
      }
    }
  }
`

export const label = css`
  margin: 5px 5px 0;
  outline: none;
  color: #666666;
  font-size: 14px;
  font-weight: bold;
`

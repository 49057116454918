/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { marginBottom } from 'assets/styles/global'
import { H3, ToggleInputGroup } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { AddLabel } from 'components/AddLabel'
import { FeeByDemandRow } from './FeeByDemandRow'

const marginBottom20 = marginBottom(20)

const emptyFeeByDemand = { limit: 0, valueModificator: 0, operator: 'increase' }

export const FeeByDemandForm = ({
  feePrice = 0,
  feesByDemand: feeByDemandList = [],
  feesByDemandErrors = [],
  onChange: emitChangeEvent = identity,
  onInputBlur: emitBlurEvent = identity,
  onItemRemoval: emitFeeByDemandRemove = identity,
  onItemAddition: emitFeeByDemandAdd = identity,
  onReset: emitResetEvent = identity
}) => {
  const useFeeByDemand = feeByDemandList.length > 0

  const addFeeByDemand = () => emitFeeByDemandAdd(emptyFeeByDemand)
  const toggleDemandFee = () => (useFeeByDemand ? emitResetEvent() : addFeeByDemand())
  const removeFeeByDemand = index => () => emitFeeByDemandRemove(index)

  return (
    <>
      <H3 css={marginBottom20}>Tarifário por demanda</H3>
      <ToggleInputGroup id='demand-fee' checked={useFeeByDemand} onChange={toggleDemandFee}>
        Utilizar tarifário por demanda
      </ToggleInputGroup>
      <div css={marginBottom20}>
        {useFeeByDemand && (
          <>
            {feeByDemandList.map((feeByDemandItem, index) => (
              <FeeByDemandRow
                key={`fee-by-demand-${index}`}
                index={index}
                feePrice={feePrice}
                feeByDemandItem={feeByDemandItem}
                errors={feesByDemandErrors[index]}
                onChange={emitChangeEvent}
                onBlur={emitBlurEvent}
                onDelete={removeFeeByDemand(index)}
              />
            ))}
            <AddLabel onClick={addFeeByDemand}>Nova tarifa</AddLabel>
          </>
        )}
      </div>
    </>
  )
}

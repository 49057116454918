import { BankAccountService } from 'core/services/BankAccount'
import { useCallback, useState } from 'react'

export const useListBankAccountByPaymentMethod = () => {
  const [loading, setLoading] = useState(false)
  const [bankAccountsOptions, setBankAccountsOptions] = useState([])

  const fetchBankAccountsOptions = useCallback(async ({ paymentMethod, companyId }) => {
    setLoading(true)
    return BankAccountService.getBankAccountByPaymentMethod({ paymentMethod, companyId }).finally(() => setLoading(false))
  }, [])

  const fetchBankAccountsByPaymentMethod = useCallback(({ paymentMethod, companyId, canSetBankAccountOptions = true }) => {
    setLoading(true)
    return fetchBankAccountsOptions({ paymentMethod, companyId }).then((value) => {
      if (canSetBankAccountOptions) {
        setBankAccountsOptions([
          { value: '', label: 'Nenhuma' },
          ...value
        ])
      } else {
        return value
      }
    })
      .finally(() => setLoading(false))
  }, [fetchBankAccountsOptions])

  const [bankAccountOptionsForVendorOrSupplier, setBankAccountOptionsForVendorOrSupplier] = useState({ origin: [], destination: [] })

  const fillBankAccountOptionsForVendorOrSupplier = useCallback((paymentMethod, vendorCompanyId, supplierCompanyId) => {
    if (loading) return

    fetchBankAccountsByPaymentMethod({ companyId: supplierCompanyId, paymentMethod, canSetBankAccountOptions: false })
      .then((data) => setBankAccountOptionsForVendorOrSupplier((prev) => ({ ...prev, destination: data })))

    fetchBankAccountsByPaymentMethod({ companyId: vendorCompanyId, paymentMethod, canSetBankAccountOptions: false })
      .then((data) => setBankAccountOptionsForVendorOrSupplier((prev) => ({ ...prev, origin: data })))
  }, [fetchBankAccountsByPaymentMethod, setBankAccountOptionsForVendorOrSupplier, loading])

  return {
    loading,
    bankAccountsOptions,
    fetchBankAccountsOptions,
    fetchBankAccountsByPaymentMethod,
    bankAccountOptionsForVendorOrSupplier,
    fillBankAccountOptionsForVendorOrSupplier
  }
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Row, Column, InputWithPrefix, SearchIcon } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'

export const searchIcon = css`
  font-size: 20px;
`

export const searchStyle = css`
  width: 100%;
`

export const PartnershipAccountsFilter = ({
  query = '',
  setQuery = identity
}) => {
  return (
    <>
      <Row>
        <Column phone={12} desktop={5}>
          <InputWithPrefix value={query} onChange={setQuery} placeholder='Buscar nome da empresa' css={searchStyle}>
            <SearchIcon css={searchIcon} />
          </InputWithPrefix>
        </Column>
      </Row>
    </>
  )
}

import { orbCore } from 'services/Orb/Service'
import { PaymentParser } from 'core/parsers/Payment'
import { linkNonSplittedModel, linkPaymentModel, linkPaymentMultipontoModel, paymentModel, paymentTransferModel } from 'core/models/Payment'
import { convertToFormData } from '@bonitour/common-functions'

const { reservationPaymentDomain, reservationPaymentDomainV2 } = orbCore

export const PaymentService = {
  createPayment (payment, reservationId) {
    const payload = paymentModel(payment)
    return reservationPaymentDomainV2.create(convertToFormData(payload), reservationId)
  },
  createLinkPayment (payment, reservationId) {
    const payload = linkPaymentModel(payment)
    return reservationPaymentDomain.createLink(convertToFormData(payload), reservationId)
  },
  createLinkPaymentMultiponto (payment, reservationId) {
    const payload = linkPaymentMultipontoModel(payment)
    return reservationPaymentDomain.createLinkMultiponto(convertToFormData(payload), reservationId)
  },
  createLinkNonSplitted (payment, reservationId) {
    const payload = linkNonSplittedModel(payment)
    return reservationPaymentDomain.createLinkNonSplitted(convertToFormData(payload), reservationId)
  },
  expirePaymentLink (reservationId, paymentId) {
    return reservationPaymentDomain.expireLink(reservationId, paymentId)
  },
  editPayment (payment, reservationId) {
    return reservationPaymentDomain.edit(convertToFormData(paymentModel(payment, true)), reservationId, payment.id)
  },
  getById (reservationId, paymentId) {
    return reservationPaymentDomainV2.getById(reservationId, paymentId).then(PaymentParser.singlePayment)
  },
  getPaymentLinkById (reservationId, paymentId) {
    return reservationPaymentDomain.getPaymentLinkById(reservationId, paymentId).then(PaymentParser.singlePaymentLink)
  },
  remove (reservationId, paymentId, param) {
    return reservationPaymentDomain.remove(reservationId, paymentId, PaymentParser.remove(param))
  },
  paymentTransfer (reservationId, transferData) {
    return reservationPaymentDomain.transfer(reservationId, convertToFormData(paymentTransferModel(transferData))).then(PaymentParser.listPaymentTransfer)
  },
  getAdvancePaymentById (reservationId, paymentId) {
    return reservationPaymentDomainV2.getAdvancePaymentById(reservationId, paymentId).then(PaymentParser.singlePayment)
  }
}

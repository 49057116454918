/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { CircleThumbnail, flexHorizontallyCentered } from '@bonitour/components'
import { ticketStatusDictionary } from 'constants/ticketStatus'
import { circleColor } from 'assets/styles/global'

export const TicketStatus = status => {
  const { label, color } = ticketStatusDictionary[status] || {}

  return (
    <div css={flexHorizontallyCentered} className='status-component'>
      <CircleThumbnail size={10} css={circleColor(color)} />
      <span>{label}</span>
    </div>
  )
}

import { parseAddress } from 'core/parsers/address'
import { getImageUrl } from 'services/Orb/Service'
import { MetaParser } from './Meta'
import { unmaskPhone } from '@bonitour/common-functions'

const parseReservation = (reservation = {}) => {
  const {
    id,
    reservation_code: reservationCode,
    commercial_partner_name: partnerName,
    payer_name: clientName,
    status
  } = reservation
  return {
    id,
    reservationCode: reservationCode || '',
    partnerName: partnerName || '',
    clientName: clientName || '',
    status
  }
}

const companyBasicInfoParser = (company = {}) => {
  const {
    document,
    name,
    trading_name: tradingName,
    state_registration: stateRegistrationCode,
    municipal_inscription: municipalRegistrationCode,
    cadastur
  } = company

  return {
    name: name || '',
    document: document || '',
    tradingName: tradingName || '',
    stateRegistrationCode: stateRegistrationCode || '',
    municipalRegistrationCode: municipalRegistrationCode || '',
    cadastur: cadastur || ''
  }
}

export const CompanyParser = {
  listUserCompanies ({ companies = [] }) {
    return companies.map(company => ({
      id: company.id,
      name: company.name,
      image: getImageUrl(company.image)
    }))
  },
  phone (phone = {}) {
    const { title, number } = phone
    const telephone = unmaskPhone(number || '')
    const phoneNumberWithDDI = telephone?.length <= 10 ? `55${telephone}` : telephone

    return {
      title,
      number: phoneNumberWithDDI
    }
  },
  company (company = {}) {
    const companyRegistry = companyBasicInfoParser(company)
    const addressInfo = parseAddress(company)
    const { contacts, image, id } = company
    const { contacts: { phones } } = company
    const telephones = phones.map(phone => CompanyParser.phone(phone))

    const files = image
      ? [{
        id: image,
        src: getImageUrl(image)
      }]
      : []

    return {
      id,
      image: getImageUrl(image),
      files,
      ...companyRegistry,
      addressInfo,
      ...contacts,
      phones: telephones
    }
  },
  list ({ companies, meta } = {}) {
    return {
      companies: companies.map(CompanyParser.listItem),
      meta: MetaParser.meta(meta)
    }
  },
  listItem (company) {
    const {
      id,
      name,
      address,
      cadastur,
      document,
      image,
      municipal_inscription: municipalInscription,
      state_registration: stateRegistration,
      trading_name: tradingName
    } = company?.company ?? company ?? {}

    return {
      id,
      name,
      address,
      cadastur,
      document,
      image,
      municipalInscription,
      stateRegistration,
      tradingName
    }
  },
  chart (data = []) {
    return data.sort(({ date: firstDate }, { date: secondDate }) => firstDate.localeCompare(secondDate))
  },
  reservations ({ meta = {}, reservations = [] }) {
    const { total_pages: totalPages = 0, total_entries: total = 0 } = meta
    const reservationsList = reservations.map(parseReservation)
    return { reservationsList, totalPages, total }
  }
}

export const refundTypesMap = {
  debit: 'Cartão de Débito',
  credit: 'Cartão de Crédito',
  cash: 'Dinheiro',
  bank_billet: 'Boleto',
  bank_transfer: 'Transferência Bancária',
  pay_credit: 'Pay - Cartão de Crédito',
  pay_pix: 'Pay - Pix',
  pix: 'Pix'
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Manager, Reference, Tooltip } from '@bonitour/components'
import { DateButtonLeft, DateButtonRight } from 'components/BookingMap/BookingMapButton/BookingMapButton'
import { identity } from '@bonitour/common-functions'
import { useState } from 'react'

export const BookingMapButtons = ({
  offset = 0,
  onClickPrevious: emitPreviousClick = identity,
  onNextClick: emitNextClick = identity
}) => {
  const [hasPreviousButtonTooltip, setHasPreviousButtonTooltip] = useState(false)
  const [tooltipNextButton, setNextButtonTooltip] = useState(false)

  const togglePreviousButtonTooltip = () => setHasPreviousButtonTooltip(!hasPreviousButtonTooltip)
  const toggleNextButtonTooltip = () => setNextButtonTooltip(!tooltipNextButton)

  return (
    <>
      <Manager>
        <Reference>
          {({ ref }) => (
            <DateButtonLeft
              ref={ref}
              onClick={emitPreviousClick}
              onMouseOver={togglePreviousButtonTooltip}
              onMouseOut={togglePreviousButtonTooltip}
            />
          )}
        </Reference>
        {hasPreviousButtonTooltip && <Tooltip position='top'>{`Exibir ${offset} dias anteriores`}</Tooltip>}
      </Manager>
      <Manager>
        <Reference>
          {({ ref }) => (
            <DateButtonRight
              onClick={emitNextClick}
              ref={ref}
              onMouseOver={toggleNextButtonTooltip}
              onMouseOut={toggleNextButtonTooltip}
            />
          )}
        </Reference>
        {tooltipNextButton && <Tooltip position='top'>{`Exibir próximos ${offset} dias`}</Tooltip>}
      </Manager>
    </>
  )
}

import { BREAK_POINTS, colors } from '@bonitour/components'
import { css } from '@emotion/core'

import { marginTop, margin, marginLeft } from 'assets/styles/global'

export const marginTop5 = marginTop(5)
export const marginHorizontal50 = margin(0, 50, 0, 50)

export const iconSize = css`
  font-size: 23px;
`

export const voucherIconSize = css`
  font-size: 20px;
`

export const cellFontSize = css`
  font-size: 12px;
`

export const activitySelector = css`
  user-select: none;
  display: inline-block;
`

export const externalCodeCellContainer = css`
  position: relative;
`

export const linkPassengerContainer = css`
  display: flex;
  align-items: center;
  border: 1px solid ${colors.gray7};
  padding: 5px 10px;
  background-color: ${colors.white};
  min-height: 30px;
  border-radius: 10px;
`

export const linkPassengerIcon = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: ${colors.purple1};
  border-radius: var(--small-round-element-radius, 100%);
  color: ${colors.white};
  margin-right: 5px;
`

export const linkPassengerLabel = css`
  text-transform: none;
  color: ${colors.purple1};
  cursor: pointer;
`
export const voucherPopoverContainerStyle = css`
  width: 178px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`

export const voucherPopoverItemStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const voucherPopoverTextStyle = css`
  margin-bottom: 0px;
  margin-left: 10px;
`

export const voucherContainerStyle = css`
  position: relative;
`

export const select = css`
  width: 60px;
  max-width: 60px;
  padding: 30px 10px;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    display: none !important;
  }
`

export const OfflineTicketEditionDialogContainer = css`
  max-height: 650px;
  max-width: 800px;
  overflow-y: auto;
`

export const externalCodeBtnsContainer = css`
  display: grid;
  grid-template-columns: 1fr;
  gap: 4px;

  button {
    width: 100%;
    padding: 4px 12px;
  }
`

export const marginLeft5 = marginLeft(5)

export const customDeadlineTooltip = css`
  width: 140px;
`

export const dateTimeCell = css`
  article {
    display: inline-flex;
    flex-direction: row;
    .start-end-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-right: 0.5rem;
    }
  }
`

export const nameDateTimeCell = css`
  max-width: 150px;
`
export const deadLineSummaryStyle = css`
  &.full-content {
    width: 230px;
    p:first-of-type {
      word-wrap: break-word;
    }
    p:last-of-type {
      margin-top: 0.35rem;
    }
  }
`

export const tooltipWithDeadlineStyle = css`
  [class*='tooltip-on-hover'] {
    padding: 0.5rem;
  }
`

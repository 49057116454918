/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import {
  Card,
  Column,
  DatePicker,
  InputFormGroup,
  Row,
  Select,
  CheckboxInputGroup,
  HelpIcon
} from '@bonitour/components'
import { container, customSelectStyle, fullWidth, smsHelp } from './NoticeForm.style'
import { identity } from '@bonitour/common-functions'
import { useMemo, useCallback, useEffect } from 'react'
import moment from 'moment'
import { useListIntegration } from 'domains/Integrations/hoks/useListIntegration'
import NoticeExperienceSelection from './NoticeExperienceSelection'
import NoticeMessageSelection from './NoticeMessageSelection'

const { REACT_APP_ACCOUNTS_URL } = process.env

export const NoticeForm = ({
  notice = {},
  noticeErrors = {},
  categories = [],
  onChange: emitChangeEvent = identity,
  onBlur: emitBlurEvent = identity,
  companiesOptions = [],
  setActivitiesWithVacancies
}) => {
  const {
    hours = {},
    date = '',
    category = '',
    pushNotificationMessage = '',
    message = '',
    sendEmail = false,
    sendSms = false,
    companyId = '',
    serviceIds = []
  } = notice

  const today = useMemo(() => moment().toDate(), [])

  const toggleSendEmail = useCallback(() => {
    emitChangeEvent('sendEmail')(!sendEmail)
  }, [emitChangeEvent, sendEmail])
  const toggleSendSms = useCallback(() => {
    emitChangeEvent('sendSms')(!sendSms)
  }, [emitChangeEvent, sendSms])

  const { integrations, loading: loadingIntegrations } = useListIntegration({ perPage: 99 })

  const hasTwilioIntegration = useMemo(
    () => !loadingIntegrations && integrations?.some(({ integrationName }) => integrationName === 'Twilio SMS'),
    [integrations, loadingIntegrations]
  )

  useEffect(() => {
    emitChangeEvent('sendSms')(hasTwilioIntegration)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasTwilioIntegration])
  useEffect(() => {
    emitChangeEvent('sendEmail')(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const safeChangeProvider = (value) => {
    emitChangeEvent('companyId')(value)
    emitChangeEvent('hours')({})
    emitChangeEvent('serviceIds')([])
  }

  return (
    <Card css={container}>
      <Row>
        <Column phone={12} desktop={7}>
          <InputFormGroup label="Data das experiências" errorMessage={noticeErrors.date}>
            <DatePicker
              css={fullWidth}
              minDate={today}
              value={date}
              onChange={emitChangeEvent('date')}
              onBlur={emitBlurEvent('date')}
            />
          </InputFormGroup>
        </Column>
      </Row>

      <Row>
        <Column phone={12} desktop={7}>
          <InputFormGroup errorMessage={noticeErrors.companyId} label="Fornecedor">
            <Select
              placeholder="Selecione o Fornecedor"
              options={companiesOptions}
              value={companyId}
              error={noticeErrors.companyId}
              onChange={safeChangeProvider}
              onBlur={emitBlurEvent('companyId')}
              customCss={[customSelectStyle]}
            />
          </InputFormGroup>
        </Column>
      </Row>

      <NoticeExperienceSelection
        noticeErrors={noticeErrors}
        emitChangeEvent={emitChangeEvent}
        emitBlurEvent={emitBlurEvent}
        date={date}
        hours={hours}
        serviceIds={serviceIds}
        selectedCompanyId={companyId}
        setActivitiesWithVacancies={setActivitiesWithVacancies}
      />

      <Row>
        <Column phone={12} desktop={7}>
          <InputFormGroup errorMessage={noticeErrors.category} label="Categoria do comunicado">
            <Select
              placeholder="Selecione uma categoria"
              options={categories}
              value={category}
              error={noticeErrors.category}
              onChange={emitChangeEvent('category')}
              onBlur={emitBlurEvent('category')}
            />
          </InputFormGroup>
        </Column>
      </Row>

      <NoticeMessageSelection
        category={category}
        message={message}
        noticeErrors={noticeErrors}
        onChange={emitChangeEvent}
        pushNotificationMessage={pushNotificationMessage}
        onBlur={emitBlurEvent}
      />

      <Row>
        <Column phone={12} desktop={12}>
          <CheckboxInputGroup id="sendEmail" onChange={toggleSendEmail} checked={sendEmail}>
              Enviar email ao responsável
          </CheckboxInputGroup>
        </Column>
      </Row>

      <>
        <Row>
          <Column phone={12} desktop={12}>
            <CheckboxInputGroup
              id="sendSms"
              onChange={toggleSendSms}
              checked={sendSms}
              disabled={!hasTwilioIntegration}
            >
                Enviar SMS ao responsável
            </CheckboxInputGroup>
          </Column>
        </Row>
        {!hasTwilioIntegration && !loadingIntegrations && (
          <Row css={smsHelp}>
            <p>
              <HelpIcon /> Para habilitar o envio de SMS, uma integração com o Twilio deve ser configurada{' '}
              <a href={`${REACT_APP_ACCOUNTS_URL}/app/integrations`} target="_blank" rel="noopener noreferrer">
                  nesta tela
              </a>
                .
            </p>
          </Row>
        )}
      </>
    </Card>
  )
}

import { useCallback, useState } from 'react'
import { ReservationHistoryService } from 'core/services/ReservationHistory'
import { useReservation } from 'hooks/context/Reservation'
import { useToast } from '@bonitour/components'

const DEFAULT_META = {
  totalEntries: 0,
  currentPage: 1,
  totalPages: 1
}

const DEFAULT_PAGINATION = {
  page: 1,
  perPage: 25
}

export const useReservationTimeline = () => {
  const { reservationId } = useReservation()
  const [timeline, setTimeline] = useState({})
  const [isLoading, setIsLoading] = useState({ isFirst: false, isMore: false })
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION)

  const { add: addToast } = useToast()

  const fetchReservationTimeline = useCallback((params, isForLoadMore = false) => {
    const isLoadingSomeThing = isForLoadMore ? isLoading.isMore : isLoading.isFirst

    const isLastPage = pagination.page >= (timeline?.meta?.totalPages || 0)

    if (
      (!reservationId || isLoadingSomeThing) || (isLastPage && isForLoadMore)
    ) return

    setIsLoading({
      isFirst: !isForLoadMore,
      isMore: isForLoadMore
    })

    return ReservationHistoryService
      .getV2(reservationId, {
        ...params,
        ...(
          isForLoadMore
            ? {
              ...pagination,
              page: pagination.page + 1
            }
            : DEFAULT_PAGINATION)
      })
      .then(value => {
        if (!isForLoadMore) {
          return setTimeline(value)
        }
        setPagination(prev => ({
          perPage: prev.perPage,
          page: prev.page + 1
        }))
        setTimeline(prev => ({
          meta: value.meta,
          historics: [...prev.historics, ...value.historics]
        }))
      })
      .catch(() => {
        setTimeline([])
        addToast('Ocorreu um problema ao listar o histórico')
      })
      .finally(() => {
        setIsLoading({
          isFirst: false,
          isMore: false
        })
        if (!isForLoadMore) {
          setPagination(DEFAULT_PAGINATION)
        }
      })
  }, [isLoading.isMore, isLoading.isFirst, pagination, timeline?.meta?.totalPages, reservationId, addToast])

  return {
    timeline,
    isFirstLoad: isLoading.isFirst,
    isLoadingMore: isLoading.isMore,
    fetchReservationTimeline,
    timelineData: timeline?.historics || [],
    meta: timeline?.meta || DEFAULT_META,
    pagination
  }
}

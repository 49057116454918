import { css } from '@emotion/core'
import { colors } from '@bonitour/components'
import { reservationTextColor } from './ReservationTimeline.constant'

export const cardStyle = css`
  border: 2px solid ${colors.gray4};
  padding-top: 4px;
  padding-bottom: 1rem;
  min-width: 300px;
  max-width: 600px;
  height: 110px;
  width: 100%;
  overflow-y: auto;
  padding-right: 0.65rem;

  .list-item {
    display: block;
    padding-left: 1rem;
    line-height: 1.25rem;
    &::before {
      content: '● ';
      margin-left: -1rem;
      color: ${colors.gray4};
    }
  }
`

export const cardItemStyle = css`
  padding-left: 6px;
  margin: 0;
  font-size: 1rem;
  color: ${reservationTextColor};
  font-weight: 500;
  line-height: 1.05rem;

  &.primary {
    font-weight: bolder;
    margin-bottom: 4px;
  }
`
export const refundLabelStyle = css`
  position: relative;
  > p {
    color: ${colors.green3};
    font-weight: 600;
    margin: 0;
    margin-top: 4px;
    font-size: 0.85rem;
    position: absolute;
  }
`

export const offlineExperienceAboutParser = (service = {}) => {
  const {
    service_time: duration,
    employee_time: workDuration,
    category: categoryObject,
    difficulty_level: difficultyLevel,
    infrastructure,
    general_rules: generalRules
  } = service

  const { id: category = '' } = categoryObject || {}
  return {
    duration: duration || '',
    workDuration: workDuration || '',
    difficultyLevel: difficultyLevel || '',
    category,
    infrastructure,
    generalRules: generalRules || ''
  }
}

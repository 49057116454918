import { head, tail } from '@bonitour/common-functions'

export const ExceptionalDayParser = {
  single: ({ exceptional_day: exceptionalDay }) => {
    const {
      id,
      name,
      exceptional_date: exceptionalDate,
      company_id: companyId,
      service_id: serviceId,
      daily_limit: dailyLimit,
      vacancies_by_schedule: vacanciesBySchedule = {}
    } = exceptionalDay || {}

    const registries = Object.entries(vacanciesBySchedule).map(item => {
      return { [head(item)]: tail(item) }
    })

    return {
      id,
      name,
      exceptionalDate,
      companyId,
      serviceId,
      dailyLimit,
      vacanciesBySchedule: registries
    }
  },
  list: ({ exceptional_days: exceptionalDays = [] }) => {
    return {
      exceptionalDays: exceptionalDays.map(exceptionalDay => ExceptionalDayParser.single({ exceptional_day: exceptionalDay }))
    }
  }
}

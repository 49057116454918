/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { CheckIcon, RefreshIcon, CustomAlert } from '@bonitour/components'
import { hidden } from 'assets/styles/global'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDebounce } from '@bonitour/app-functions'
import { iconAnimation, resetAccordionPadding } from './BookingMapOperationProgress.style'

export const BookingMapOperationProgress = ({
  isOperationInProgress = false,
  isLoading = false,
  isTransport = false,
  customCss = []
}) => {
  const [alertData, setAlertData] = useState({
    canHideAlert: false,
    isAlreadyOperationInProgress: false,
    isFirstLoading: true,
    isOperationCompleted: false
  })

  const {
    canHideAlert,
    isAlreadyOperationInProgress,
    isFirstLoading,
    isOperationCompleted
  } = useMemo(() => alertData, [alertData])

  const setCanHideAlert = useCallback((value) => setAlertData((prev) => ({ ...prev, canHideAlert: value })), [])

  const setIsAlreadyOperationInProgress = useCallback((value) => setAlertData((prev) => ({ ...prev, isAlreadyOperationInProgress: value })), [setAlertData])

  const setIsFirstLoading = useCallback((value) => setAlertData((prev) => ({ ...prev, isFirstLoading: value })), [])

  const setIsOperationCompleted = useCallback((value) => setAlertData((prev) => ({ ...prev, isOperationCompleted: value })), [])

  const timeout = useRef(null)

  const isInProgress = useDebounce(isOperationInProgress, 1200)

  const canUnmount = useMemo(() => (
    !isInProgress &&
    !isAlreadyOperationInProgress &&
    (isOperationCompleted || isFirstLoading)) ||
    (!isInProgress &&
    !isAlreadyOperationInProgress), [
    isAlreadyOperationInProgress, isFirstLoading,
    isInProgress, isOperationCompleted
  ])

  const canShowSuccessAlert = useMemo(() => isFirstLoading ? false : !isInProgress, [isFirstLoading, isInProgress])

  useEffect(() => {
    if (isLoading || isFirstLoading) return
    if (canShowSuccessAlert && !timeout.current) {
      timeout.current = setTimeout(() => {
        setCanHideAlert(true)
        clearTimeout(timeout.current)
        timeout.current = null
        setIsOperationCompleted(true)
        setIsAlreadyOperationInProgress(true)
      }, 5000)
    }
    if (!canShowSuccessAlert) {
      setIsAlreadyOperationInProgress(true)
      setCanHideAlert(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canShowSuccessAlert])

  useEffect(() => {
    if (isLoading && isFirstLoading) {
      setIsFirstLoading(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstLoading, isLoading])

  const customIcon = useMemo(() =>
    canShowSuccessAlert
      ? <CheckIcon/>
      : <RefreshIcon css={iconAnimation}/>
  , [canShowSuccessAlert])

  if (canUnmount) {
    return null
  }

  return <CustomAlert
    icon={customIcon}
    customCss={[resetAccordionPadding, canHideAlert && hidden, ...customCss]}
    isSuccess={canShowSuccessAlert}
  >
    <p>
      {canShowSuccessAlert
        ? 'O mapa de vagas foi recalculado com sucesso.'
        : `O mapa de vagas ${isTransport ? 'dessa rota de transporte' : 'dessa atividade'} está sendo recalculado.`}
    </p>
  </CustomAlert>
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { PaxSectionBody } from './PaxSectionBody/PaxSectionBody'
import { Card } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'

const wrapper = css`
  padding: 10px 20px 0;
`
export const PaxSection = ({
  tickets = [],
  ticketsErrors = [],
  offlineReservation = {},
  offlineReservationErrors = {},
  onChange: emitChangeEvent = identity,
  onBlur: emitBlurEvent = identity,
  isOfflineExperience = false,
  disabled = false,
  ...other
}) => {
  return (
    <Card css={wrapper} {...other}>
      <PaxSectionBody
        onChange={emitChangeEvent}
        onBlur={emitBlurEvent}
        tickets={tickets}
        ticketsErrors={ticketsErrors}
        isOfflineExperience={isOfflineExperience}
        offlineReservation={offlineReservation}
        offlineReservationErrors={offlineReservationErrors}
        disabled={disabled}
      />
    </Card>
  )
}

/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx, css } from '@emotion/core'
import { TableContainer, TableHeader, TableHeaderRow, TableHeaderCell, TableBody, TableBodyRow, TableBodyCell, PencilIcon, colors } from '@bonitour/components'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { clickable } from 'assets/styles/global'

const iconStyle = css`
  font-size: 23px;
  color: ${colors.gray3};
`

const ExternalProvidersTableHeader = () => (
  <TableHeader>
    <TableHeaderRow>
      <TableHeaderCell>Nome</TableHeaderCell>
      <TableHeaderCell>CNPJ</TableHeaderCell>
      <TableHeaderCell fixed>Ações</TableHeaderCell>
    </TableHeaderRow>
  </TableHeader>
)

const ExternalProvidersTableRow = ({ externalProvider = {} }) => {
  const { push } = useHistory()
  const { id, name, document } = externalProvider

  const onEditClick = externalProviderId => () => push(`/external-providers/${externalProviderId}/edit`)

  return (
    <>
      <TableBodyRow>
        <TableBodyCell>{name}</TableBodyCell>
        <TableBodyCell>{document}</TableBodyCell>
        <TableBodyCell fixed>
          <PencilIcon css={[iconStyle, clickable]} onClick={onEditClick(id)} />
        </TableBodyCell>
      </TableBodyRow>
    </>
  )
}

export const ListExternalProviders = ({ externalProviders = [], loading }) => {
  return (
    <TableContainer>
      <ExternalProvidersTableHeader />
      <TableBody loading={loading}>
        {externalProviders.map((externalProvider, index) => (
          <ExternalProvidersTableRow key={index} externalProvider={externalProvider} />
        ))}
      </TableBody>
    </TableContainer>
  )
}

import { colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const table = css`  
  th {
    padding-bottom: 10px;
    font-weight: bold;
    font-size: 14px;
    color: ${colors.gray4};

    &:first-child {
      padding-right: 40px;
    }
  }

  tr {
    td {
      padding: 5px 0;
      background-color: unset !important;
    }
  }
`

import { VoucherTurisService } from 'core/services/VoucherTuris'
import { VoucherUnicoService } from 'core/services/VoucherUnico'
import { useReservation } from 'hooks/context/Reservation'
import { useState } from 'react'

export const useCreateVoucher = () => {
  const [loading, setLoading] = useState(false)
  const { fetchReservationTickets } = useReservation()

  const createVoucherUnico = async (ticketsIds) => {
    setLoading(true)
    return VoucherUnicoService.createVoucher(ticketsIds).then(fetchReservationTickets).finally(() => setLoading(false))
  }

  const createVoucherTuris = async (ticketsIds) => {
    setLoading(true)
    return VoucherTurisService.createVoucher(ticketsIds).then(fetchReservationTickets).finally(() => setLoading(false))
  }

  return {
    createVoucherUnico,
    createVoucherTuris,
    loading
  }
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useToast } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'

import { OfflineExperienceBaseForm } from 'containers/OfflineExperienceBaseForm/OfflineExperienceBaseForm'
import { OfflineExperienceService } from 'services/OfflineExperience/Service'
import { useActivity } from 'contexts/Activity'
import { useOfflineExperienceForm } from 'containers/OfflineExperienceBaseForm/useOfflineExperienceForm'
import { useCallback } from 'react'

export const EditOfflineExperienceInfo = ({ offlineExperienceBase = {}, onServiceUpdated = identity, onNextStep = identity }) => {
  const { id: serviceId, updateList } = useActivity()
  const { add: addToast } = useToast()

  const onSuccess = useCallback(async data => {
    try {
      await OfflineExperienceService.updateMainService(serviceId, data)
      addToast('Experiência offline atualizada', 'success')
      onServiceUpdated()
      updateList(true)
      onNextStep()
    } catch (error) {
      addToast('Ocorreu um erro na atualização da sua experiência offline')
    }
  }, [addToast, onNextStep, onServiceUpdated, serviceId, updateList])

  const offlineExperienceFormProps = useOfflineExperienceForm({ offlineExperienceBase, onSuccess, addToast })

  return (
    <OfflineExperienceBaseForm
      isEditing
      {...offlineExperienceFormProps}
    />
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import {
  Card,
  H2,
  flexColumn
} from '@bonitour/components'
import { marginBottom } from 'assets/styles/global'
import { Tickets } from 'containers/Reservation/Tickets'
import {
  noMargin,
  baseInfoStyle
} from './TicketActivitySummary.style'
import { identity } from '@bonitour/common-functions'
import { useCallback, useMemo } from 'react'
import { activityHeader } from './TicketActivitySummary'
import orderActivitiesByTime from 'utils/orderActivitiesByTime'

const marginBottom20 = marginBottom(20)

export const AllTicketsSummary = ({
  serviceTypeFilters,
  reservation = [],
  experiencesData = [],
  pickupTickets = [],
  reservationId = '',
  deleteTicketLoading = false,
  ticketConfirmLoading = false,
  linkPassengerLoading = false,
  pickupLocalLoading = false,
  observationLoading = false,
  onEdit = identity,
  onTicketDelete = identity,
  onTicketPrint = identity,
  onTicketPrintVoucherTuris = identity,
  onTicketConfirm = identity,
  onLinkPassenger = identity,
  onUnlinkPassenger = identity,
  onEditPassenger = identity,
  onLinkPickup = identity,
  onUnlinkPickup = identity,
  onEditClickPickup = identity,
  onOpenObservationModal = identity,
  clearObservation = identity,
  onTicketUpdate = identity,
  companyId = ''
}) => {
  const isExperienceVisible = useCallback(
    experienceType => serviceTypeFilters.includes(experienceType) || !serviceTypeFilters.length,
    [serviceTypeFilters]
  )

  const filteredExperiencesData = useMemo(
    () => experiencesData.filter(experience => isExperienceVisible(experience.type)),
    [experiencesData, isExperienceVisible]
  )

  const hasFiscalDuty = useMemo(() => Boolean(reservation?.fiscalDuty), [reservation?.fiscalDuty])

  const allTickets = useMemo(
    () => filteredExperiencesData.reduce(
      (acc, experience) => [
        ...acc,
        ...experience.tickets.map((ticket) => ({
          ...{
            ...experience,
            serviceType: experience.type,
            type: undefined,
            tickets: undefined
          },
          ...ticket
        }))
      ],
      []
    ).sort(orderActivitiesByTime),
    [filteredExperiencesData]
  )

  return (
    <>
      <Card css={[marginBottom20]}>
        <div css={baseInfoStyle}>
          <div css={activityHeader}>
            <div css={flexColumn}>
              <H2 css={noMargin}>Todos os ingressos</H2>
            </div>
          </div>
        </div>

        <Tickets
          groupedByService={false}
          tickets={allTickets}
          pickupTickets={pickupTickets}
          reservationId={reservationId}
          onTicketEdit={onEdit}
          onTicketDelete={onTicketDelete}
          deleteTicketLoading={deleteTicketLoading}
          onTicketPrint={onTicketPrint}
          onTicketPrintVoucherTuris={onTicketPrintVoucherTuris}
          reservation={reservation}
          onTicketConfirm={onTicketConfirm}
          onLinkPassenger={onLinkPassenger}
          onUnlinkPassenger={onUnlinkPassenger}
          onEditPassenger={onEditPassenger}
          onLinkPickup={onLinkPickup}
          onUnlinkPickup={onUnlinkPickup}
          onEditClickPickup={onEditClickPickup}
          ticketConfirmLoading={ticketConfirmLoading}
          linkPassengerLoading={linkPassengerLoading}
          pickupLocalLoading={pickupLocalLoading}
          onOpenObservationModal={onOpenObservationModal}
          clearObservation={clearObservation}
          observationLoading={observationLoading}
          onTicketUpdate={onTicketUpdate}
          hasFiscalDuty={hasFiscalDuty}
          companyId={companyId}
        />
      </Card>
    </>
  )
}

import React, { useEffect } from 'react'
import { UploadReceiptsContainer as Container } from './UploadReceipts.style'
import { UploadContent, uploadContentCss, uploadCss } from '../InvoiceBatchGlobal.style'
import { Upload, useToast } from '@bonitour/components'
import { RECEIPTS_MIME_TYPES } from 'constants/paymentBatch'

export const UploadReceipts = ({ receipts = [], onChange = () => {}, onLabelChange = () => {}, isDisabled = false }) => {
  const { add: addToast } = useToast()

  useEffect(() => {
    let label
    if (receipts.length === 0) {
      label = 'Nenhum comprovante selecionado'
    } else {
      const pluralAddon = receipts.length > 1 ? 's' : ''

      label = `${receipts.length} comprovante${pluralAddon} adicionado${pluralAddon}`
    }

    onLabelChange(label)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receipts])

  return (
    <Container>
      <UploadContent css={uploadContentCss}>
        <Upload
          disabled={isDisabled}
          files={receipts || []}
          onChange={onChange}
          onError={(e) => addToast(e)}
          accept={RECEIPTS_MIME_TYPES.join(', ')}
          limitMbSize={15}
          emptyMessage="Arraste o arquivo ou clique para adicionar máximo 15mb"
          multiple={true}
          css={uploadCss}
        />
      </UploadContent>
    </Container>
  )
}

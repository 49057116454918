import { array, number, object, string, addMethod } from 'yup'
import { pixTransactionType } from 'constants/acquirers'

addMethod(array, 'uniqueFees', function (message) {
  return this.test('unique', message, function (list) {
    const mapper = x => x.installments
    const set = [...new Set(list.map(mapper))]
    const isUnique = list.length === set.length
    if (isUnique) {
      return true
    }

    const idx = list.findIndex((l, i) => mapper(l) !== set[i])
    return this.createError({
      path: `fees[${idx}].installments`,
      message
    })
  })
})

export const validationSchema = {
  name: string()
    .required('Preencha o campo de nome'),
  brand: string().test('test-method', 'Preencha o campo de bandeira', function (brand) {
    const { transactionType } = this.parent
    if (transactionType === pixTransactionType) return true
    return Boolean(brand)
  }).nullable(),
  transactionType: string().required('Preencha o campo de tipo de transação'),
  fees: array().of(object().shape({
    installments: number().typeError('Deve ser um número').min(1, 'A parcela deve ser maior ou igual a 1').max(100, 'A parcela deve ser menor que 100').required('Preencha o campo de parcelas'),
    feePercentage: number().typeError('Deve ser um número').min(0, 'A porcentagem deve ser maior ou igual a 0').max(100, 'A porcentagem deve ser menor que 100').required('Preencha o campo de taxa')
  })).uniqueFees('Valor de parcela já utilizada'),
  bankAccountId: string().required('Preencha o campo de conta bancária')
}

import { useCallback, useEffect, useState } from 'react'
import { useToast } from '@bonitour/components'
import { PaymentBatchService } from 'core/services/PaymentBatch'
import { invoiceBatchTypeMap } from '../constants/InvoiceBatchType'

export const useTicketsToBatchList = (type = 'affiliate') => {
  const { add: addToast } = useToast()
  const [filters, setFilters] = useState({})
  const [lastBeneficiaryId, setLastBeneficiaryId] = useState('')
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    currentPage: 1,
    perPage: 10
  })
  const changePagination = useCallback((currentPage, perPage) => {
    if (currentPage === pagination.currentPage && perPage === pagination.perPage) {
      return
    }

    setPagination({
      currentPage,
      perPage
    })
  }, [pagination.currentPage, pagination.perPage])

  const [ticketsToBatchData, setTicketsToBatchData] = useState({ ticketsToBatch: undefined, meta: undefined })

  const fetchTicketsToBatch = useCallback(({
    filters,
    pagination
  }, beneficiaryId = '') => {
    setFilters(filters)
    if (!beneficiaryId) {
      if (!lastBeneficiaryId) {
        return
      }
      beneficiaryId = lastBeneficiaryId
    } else {
      setLastBeneficiaryId(beneficiaryId)
    }
    setFilters(filters)
    setLoading(true)
    return PaymentBatchService.getTicketsToBatch({ filters, pagination }, beneficiaryId, invoiceBatchTypeMap[type])
      .then(setTicketsToBatchData)
      .catch(() => addToast('Ocorreu um erro ao realizar a busca'))
      .finally(() => setLoading(false))
  }, [addToast, lastBeneficiaryId, type])

  useEffect(() => {
    fetchTicketsToBatch({
      pagination,
      filters
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination])

  return {
    filters,
    setFilters,
    pagination,
    changePagination,
    ticketsToBatch: ticketsToBatchData?.ticketsToBatch,
    meta: ticketsToBatchData?.meta,
    fetchTicketsToBatch,
    loading
  }
}

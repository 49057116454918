import { useCallback, useEffect, useState } from 'react'
import { CashierService } from 'core/services/Cashier'
import { useToast } from '@bonitour/components'
import { usePermissions } from 'hooks/usePermissions'

export const useCashierDetails = ({ cashierId: id }) => {
  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()
  const [cashier, setCashier] = useState({})
  const [loading, setLoading] = useState(false)

  const fetchCashier = useCallback((cashierId) => {
    setLoading(true)
    CashierService.getById(cashierId)
      .then(setCashier)
      .catch((error) => {
        const hasPermission = handlePermission(error, 'Você não possui permissão de acesso ao caixa')
        if (hasPermission && id === 'my_cashier') {
          return addToast('Seu caixa não foi aberto, a sua primeira movimentação no dia o abrirá', 'info')
        }
        hasPermission && addToast('Caixa não encontrado')
      })
      .finally(() => setLoading(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchCashier(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return {
    cashier,
    setCashier,
    loading,
    fetchCashier
  }
}

import { useState } from 'react'
import { useCompany } from 'contexts/Company'
import { AcquirerService } from 'core/services/Acquirer'

export const useCreateAcquirer = () => {
  const { id: companyId } = useCompany()
  const [loading, setLoading] = useState(false)

  const createAcquirer = async (acquirerData) => {
    setLoading(true)
    return AcquirerService.createAcquirer({ ...acquirerData, companyId }).finally(() => setLoading(false))
  }

  return {
    submit: createAcquirer,
    loading
  }
}

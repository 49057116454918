/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { Row, Column, InputFormGroup, DatePicker, Button, Select } from '@bonitour/components'
import { useCallback, useEffect, useState } from 'react'
import { flexEnd, width100, height40, marginTop15, formContainer } from './PartnershipTransactions.styles'

const RequiredOptions = [
  {
    label: 'Todos',
    value: 'all'
  }
]

const DateInput = ({ value, label, error, onChange }) => {
  const safeDateHandler = useCallback(
    (val) => {
      const safeDate = new Date(val)

      if (safeDate.toString() === 'Invalid Date') {
        onChange(null)
      } else {
        onChange(safeDate.toISOString().slice(0, 10))
      }
    },
    [onChange]
  )

  return (
    <Column phone={12} tabletlandscape={4} desktop={4}>
      <InputFormGroup label={label} errorMessage={error}>
        <DatePicker value={value} onChange={safeDateHandler} css={width100} allowsEmpty={true} />
      </InputFormGroup>
    </Column>
  )
}

const PaymentTypeInput = ({ onChange, label, error, fetchPaymentsType }) => {
  const [paymentType, setPaymentType] = useState(RequiredOptions[0])
  const [paymentsType, setPaymentsType] = useState({
    collected_at: null,
    items: [{ label: 'Carregando', value: null }]
  })

  const handleChange = (value) => {
    if (value === 'all') {
      setPaymentType(RequiredOptions[0])
      onChange(null)
      setSafeTypes()
    } else {
      const item = paymentsType.items.find((item) => item.value === value)

      setPaymentType(item)
      onChange(item.value)
      setSafeTypes()
    }
  }

  const setTypes = useCallback(() => {
    fetchPaymentsType().then((data) => {
      setPaymentsType({
        ...data,
        items: [...RequiredOptions, ...data?.items]
      })
    })
  }, [fetchPaymentsType])

  const isGreaterFiveMinutes = useCallback(() => {
    return paymentsType.collected_at && new Date() - paymentsType.collected_at >= 300000
  }, [paymentsType.collected_at])

  const setSafeTypes = useCallback(() => {
    if (!paymentsType.collected_at) return setTypes()
    if (paymentsType.collected_at && isGreaterFiveMinutes()) return setTypes()
  }, [setTypes, isGreaterFiveMinutes, paymentsType.collected_at])

  useEffect(() => {
    const abortController = new AbortController()

    setSafeTypes()

    return () => {
      abortController.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Column phone={12} tabletlandscape={4} desktop={4}>
      <InputFormGroup label={label} errorMessage={error}>
        <Select
          value={paymentType.value ? paymentType.value : null}
          options={paymentsType.items}
          onChange={handleChange}
          css={width100}
        />
      </InputFormGroup>
    </Column>
  )
}

export const PartnershipTransactionsFilters = ({ onFilter = identity, partnershipForm = {}, fetchPaymentsType }) => {
  const {
    form,
    errors,
    utils: { onInputBlur, onInputChange },
    onSubmit: onFormSubmit
  } = partnershipForm

  const handleFormSubmit = useCallback(
    (data) => {
      onFilter(data.startDate || '', data.endDate || '', data.paymentType || null)
    },
    [onFilter]
  )

  return (
    <form css={formContainer} onSubmit={(e) => e.preventDefault()}>
      <Row>
        <DateInput
          value={form.startDate}
          label="Data inicial"
          error={errors?.startDate || ''}
          onChange={onInputChange('startDate')}
          onInputBlur={onInputBlur('startDate')}
        />
        <DateInput
          value={form.endDate}
          label="Data final"
          error={errors?.endDate || ''}
          onChange={onInputChange('endDate')}
          onInputBlur={onInputBlur('endDate')}
        />
        <PaymentTypeInput
          label="Meio de pagamento"
          error={errors.paymentType}
          onChange={onInputChange('paymentType')}
          fetchPaymentsType={fetchPaymentsType}
        />
        <Column phone={12} tabletlandscape={4} desktop={4} css={flexEnd}>
          <Button css={[width100, height40, marginTop15]} onClick={onFormSubmit(handleFormSubmit)}>
            Buscar
          </Button>
        </Column>
      </Row>
    </form>
  )
}

import { metaModel } from './meta'
import { pixTransactionType } from 'constants/acquirers'

/* eslint-disable camelcase */
export const AcquirerModel = {
  single: (acquirer = {}) => {
    const {
      name: acquirer_name,
      brand,
      transactionType: transaction_type,
      fees: feesList,
      companyId: company_id,
      bankAccountId: bank_account_id,
      cardBrandId: card_brand_id,
      paymentMean: payment_mean,
      providerId: payment_provider_id
    } = acquirer

    const fees = feesList?.reduce(
      (acc, fee) => {
        const parsedFeeValue = Number(fee.feePercentage) / 100
        return { ...acc, [fee.installments.toString()]: parsedFeeValue }
      }, {}
    )

    const isPix = transaction_type === pixTransactionType

    return {
      acquirer_name,
      transaction_type,
      fees,
      company_id,
      bank_account_id,
      payment_mean,
      payment_provider_id,
      card_brand_id: isPix ? '' : card_brand_id,
      brand: isPix ? '' : brand
    }
  },
  list: ({ filters, pagination, companyId }) => {
    const params = [
      AcquirerSearchParamsModel(filters),
      metaModel(pagination),
      companyId
    ]
    return params
  }
}

export const AcquirerSearchParamsModel = (filterParams = {}) => {
  const {
    provider = '',
    brand = '',
    transactionType = ''
  } = filterParams

  return {
    payment_provider_name: provider?.toLowerCase(),
    brand_name: brand,
    transaction_type: transactionType
  }
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import {
  Row,
  DatePicker,
  Input,
  InputFormGroup,
  Label,
  InputTimeMask,
  Column
} from '@bonitour/components'
import { rowStyle } from '../OfflineExperiencesTicketForm.style'
import { CountInputFormGroup, NetPriceInputFormGroup, ObservationRowInput, PriceInputFormGroup, SlotInputFormGroup, AgentInput, UnitTotalPriceInputFormGroup, UnitNetPriceInputFormGroup } from './CommonFormFields'

export const AccommodationForm = ({
  isEditing = false,
  form,
  errors,
  onInputChange = identity,
  onInputBlur = identity,
  disableTotalPrice = false,
  disabled = false
}) => {
  return (
    <>
      {!isEditing && !disabled
        ? <AgentInput errors={errors} form={form} onInputChange={onInputChange} onInputBlur={onInputBlur} />
        : null}

      <Row css={rowStyle}>
        <Column desktop={6} phone={12}>
          <div className='full_width'>
            <Label>Check-in</Label>
            <div className='vertical__card__container'>
              <Row css={rowStyle}>
                <SlotInputFormGroup
                  form={form}
                  errors={errors}
                  onInputChange={onInputChange}
                  onInputBlur={onInputBlur}
                  desktop={6}
                  phone={12}
                  disabled={disabled}
                />
              </Row>
            </div>
          </div>
        </Column>
        <Column desktop={6} phone={12}>
          <div className='full_width'>
            <Label>Check-out</Label>
            <div className='vertical__card__container'>
              <Row css={rowStyle}>
                <Column desktop={6} phone={12}>
                  <InputFormGroup
                    errorMessage={errors.checkoutDate}
                    label='Data'
                    className='date__input'
                  >
                    <DatePicker
                      value={form.checkoutDate}
                      onChange={onInputChange('checkoutDate')}
                      onBlur={onInputBlur('checkoutDate')}
                      disabled={disabled}
                    />
                  </InputFormGroup>
                </Column>
                <Column desktop={6} phone={12}>
                  <InputFormGroup
                    errorMessage={errors.checkoutTime}
                    label='Horário'
                    className='time__input'
                  >
                    <InputTimeMask
                      value={form.checkoutTime}
                      onChange={onInputChange('checkoutTime')}
                      onBlur={onInputBlur('checkoutTime')}
                      disabled={disabled}
                    />
                  </InputFormGroup>
                </Column>
              </Row>
            </div>
          </div>
        </Column>
      </Row>
      <Row css={rowStyle}>
        <Column desktop={3} phone={12}>
          <InputFormGroup
            errorMessage={errors.externalReservationCode}
            label='Código externo'
            className='text__input'
          >
            <Input
              onChange={onInputChange('externalReservationCode')}
              onBlur={onInputBlur('externalReservationCode')}
              value={form.externalReservationCode}
              placeholder='Digite o código externo'
              disabled={disabled}
            />
          </InputFormGroup>
        </Column>
        {!isEditing && (
          <Column desktop={3} phone={12}>
            <CountInputFormGroup
              form={form}
              errors={errors}
              onInputChange={onInputChange}
              onInputBlur={onInputBlur}
              disabled={disabled}
            />
          </Column>
        )}
        {!isEditing && !disabled && (
          <>
            <Column desktop={3} phone={12}>
              <UnitTotalPriceInputFormGroup
                form={form}
                onChange={onInputChange}
                errors={errors}
                disabled={disableTotalPrice}
              />
            </Column>
            <Column desktop={3} phone={12}>
              <UnitNetPriceInputFormGroup
                form={form}
                onChange={onInputChange}
                errors={errors}
              />
            </Column>
          </>
        )}
        <Column desktop={3} phone={12}>
          <PriceInputFormGroup
            disabled={disableTotalPrice || disabled}
            errors={errors}
            form={form}
            onInputChange={onInputChange}
            onInputBlur={onInputBlur}
            isEditing={isEditing}
          />
        </Column>
        <Column desktop={3} phone={12}>
          <NetPriceInputFormGroup
            disabled={disabled}
            errors={errors}
            form={form}
            onInputChange={onInputChange}
            onInputBlur={onInputBlur}
            isEditing={isEditing}
          />
        </Column>
      </Row>
      <Row css={rowStyle}>
        <Column desktop={9} phone={12}>
          <InputFormGroup
            errorMessage={errors.accommodationType}
            label='Configuração do quarto'
            className='text__input'
          >
            <Input
              value={form.accommodationType}
              onChange={onInputChange('accommodationType')}
              onBlur={onInputBlur('accommodationType')}
              placeholder='Digite a configuração do quarto'
              disabled={disabled}
            />
          </InputFormGroup>
        </Column>
      </Row>
      <ObservationRowInput
        errors={errors}
        form={form}
        onInputChange={onInputChange}
        onInputBlur={onInputBlur}
        disabled={disabled}
      />
    </>
  )
}

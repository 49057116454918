import { head, tail } from '@bonitour/common-functions'
import { MetaParser } from './Meta'
import { formatISOTime } from 'utils/time'

export const SaleParser = {
  single: (sale) => {
    const {
      id,
      sale_date: saleDate,
      commercial_partner_id: sellerId,
      commercial_partner_name: sellerName,
      company_id: companyId,
      company_name: companyName,
      activity_id: activityId,
      service_title: activityName,
      passenger_name: traveler,
      payer_name: payerName,
      payer_email: payerEmail,
      slot: tourDate,
      state: status,
      price: ticketValue,
      reservation_id: reservationId,
      user_name: userName
    } = sale

    return {
      id,
      saleDate: saleDate ? formatISOTime(saleDate) : '-',
      sellerId,
      sellerName,
      companyId,
      companyName,
      activityId,
      activityName,
      traveler: traveler || payerName || payerEmail || '-',
      tourDate: tourDate ? formatISOTime(tourDate) : '-',
      status,
      ticketValue,
      reservationId,
      userName
    }
  },
  list: ({ meta, sales_report: sales = [] }) => {
    const {
      tickets,
      total_reservations_amount: reservationsAmount,
      total_tickets_amount: salesAmount,
      total_tickets_price: priceAmount,
      total_amount_vendor: vendorAmount,
      total_amount_seller: sellerAmount,
      total_amount_guide: guideAmount,
      total_amount_transferred: transferredAmount
    } = sales

    const totals = {
      reservationsAmount,
      salesAmount,
      priceAmount,
      vendorAmount,
      sellerAmount,
      guideAmount,
      transferredAmount
    }

    return {
      sales: tickets.map(SaleParser.single),
      meta: MetaParser.meta(meta),
      totals
    }
  },
  exportColumns: (columns) => {
    return Object.entries(columns).map((item) => ({ value: head(item), label: tail(item) }))
  }

}

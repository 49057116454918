import { date, array, string, ref } from 'yup'

export const ReservationTimelineSchema = {
  initialDate: date()
    .max(ref('finalDate'), 'A data inicial deve ser menor ou igual a data final'),
  finalDate: date()
    .min(ref('initialDate'), 'A data final deve ser maior ou igual a data inicial'),
  itemTypes: array().of(string()).min(1, 'Selecione ao menos uma ação da reserva'),
  eventType: string()
}

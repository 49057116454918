/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { ReservationsVisualizedTable } from './ReservationsVisualizedTable'
import { ReservationVisualizedTableFilters } from './ReservationVisualizedTableFilters'
import { useListReservationsVisualized } from 'domains/CompanyReports/hooks/useListReservationsVisualized'
import { Card, HeaderPage, PaginationBar } from '@bonitour/components'

const PER_PAGE_OPTIONS = [10, 25, 50, 100]

/**
 * Renders a list of visualized reservations.
 * @component
 * @returns {JSX.Element} - The rendered ReservationsVisualizedList component.
**/
export const ReservationsVisualizedList = () => {
  const { visualizedReservations, isLoading, meta, onChangePagination, filters, onFilterChange } = useListReservationsVisualized()

  const { push } = useHistory()

  const onBack = useCallback(() => push('/reports'), [push])
  return (
    <>
      <HeaderPage title={'Check-in pendente'} onBack={onBack}/>
      <Card>
        <ReservationVisualizedTableFilters onFilterChange={onFilterChange} filters={filters}/>
        <ReservationsVisualizedTable visualizedReservations={visualizedReservations} isLoading={isLoading}/>
        <PaginationBar
          currentPage={meta.currentPage}
          onPageChange={(page) => onChangePagination({ currentPage: page })}
          onPerPageChange={(perPage) => onChangePagination({ perPage })}
          perPage={meta.perPage}
          totalPages={meta.totalPages}
          perPageOptions={PER_PAGE_OPTIONS}
          totalElements={meta.totalEntries}
          total={visualizedReservations?.length || 0}
        />
      </Card>
    </>
  )
}

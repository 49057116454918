/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { H1 } from '@bonitour/components'
import { ReservationTimeline, reservationTimelineActionTypes, ReservationTimelineFilters, reservationTimelineFiltersLabels } from 'components/ReservationTimeline'
import { useForm } from '@bonitour/app-functions'
import * as Yup from 'yup'
import { SafeDate } from '@binamik/js-providers'

const schema = {
  initialDate: Yup.date()
    .max(Yup.ref('finalDate'), 'A data inicial deve ser menor ou igual a data final'),
  finalDate: Yup.date()
    .min(Yup.ref('initialDate'), 'A data final deve ser maior ou igual a data inicial'),
  itemTypes: Yup.array().of(Yup.string()).nullable(),
  eventType: Yup.string()
}

const INITIAL_DATA = {
  eventType: '',
  initialDate: new SafeDate().toStartOf('day').subtract({ days: 1 }).jsDate,
  finalDate: new SafeDate().toStartOf('day').jsDate,
  itemTypes: Object.keys(reservationTimelineFiltersLabels)
}

const MOCKED_ERRORS = {
  finalDate: '',
  initialDate: '',
  eventType: ''
}

const MOCKED_FORM = {
  eventType: '',
  itemTypes: [],
  finalDate: new Date(),
  initialDate: new Date()
}

const doubleIdentity = () => () => { }

const {
  ADVANCE_PAYMENT,
  ADVANCE_REFUND,
  PAYMENT,
  REFUND,
  TICKET_BALANCE_TRANSFER
} = reservationTimelineActionTypes

const uniqueTimelineData = {
  date: '2024-05-31T18:27:06.810Z',
  type: PAYMENT,
  value: 100.00,
  paymentMethod: 'Cartão de crédito (1x)',
  autDoc: '123/456',
  userName: 'John doe'
}

const timelineData = [
  {
    date: '2024-05-31T18:29:06.810Z',
    type: ADVANCE_PAYMENT,
    value: 100.00,
    paymentMethod: 'PIX',
    userName: 'John doe'
  },
  {
    date: '2024-05-31T18:29:06.810Z',
    type: ADVANCE_REFUND,
    value: 100.00,
    paymentMethod: 'PIX',
    userName: 'John doe'
  },
  {
    date: '2024-05-31T18:29:06.810Z',
    type: TICKET_BALANCE_TRANSFER,
    value: 100.00,
    paymentMethod: 'PIX',
    ticketPaid: 'ASDD-1465',
    userName: 'John doe'
  },
  uniqueTimelineData,
  {
    date: '2024-05-31T18:27:06.810Z',
    type: REFUND,
    value: 140.00,
    paymentMethod: 'Cartão de crédito (1x)',
    autDoc: '123/456',
    valueForRefund: 100.00,
    userName: 'John doe'
  }
]

const containerStyle = css`
  h1 {
    margin-top: 1rem;
  }
`

export const ReservationTimelineTest = () => {
  const {
    form,
    errors,
    utils: {
      onInputBlur,
      onInputChange
    }
  } = useForm(INITIAL_DATA, schema)

  return <div css={containerStyle}>
    <div>
      <H1>Default</H1>
      <ReservationTimeline timeline={timelineData} />
    </div>
    <div>
      <H1>With custom chain length</H1>
      <ReservationTimeline timeline={timelineData} chainLength={7} />
    </div>
    <div>
      <H1>Unique item</H1>
      <ReservationTimeline timeline={[uniqueTimelineData]} />
    </div>
    <br />
    <br />
    <div>
      <H1>Timeline filters</H1>
      <ReservationTimelineFilters
        errors={errors}
        form={form}
        utils={{
          onBlur: onInputBlur,
          onChange: onInputChange
        }}
      />
    </div>
    <div>
      <H1>Timeline filters loading</H1>
      <ReservationTimelineFilters
        errors={MOCKED_ERRORS}
        form={MOCKED_FORM}
        isLoading={true}
        utils={{
          onBlur: doubleIdentity,
          onChange: doubleIdentity
        }}
      />
    </div>
  </div>
}

const parseSingleCommunique = (communique) => ({
  category: communique.category,
  companyId: communique.company_id,
  date: communique.date,
  hours: communique.hours,
  id: communique.id,
  message: communique.message,
  previewMessage: communique.preview_message,
  serviceId: communique.service_id,
  serviceTitle: communique.title
})

export const parseCommuniques = ({ meta, ally_communiques: notices = [] }) => {
  return {
    notices: (notices).map(parseSingleCommunique),
    meta: {
      totalEntries: meta.total_entries,
      currentPage: meta.current_page,
      totalPages: meta.total_pages
    }
  }
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { OfflineExperienceAbout } from './OfflineExperienceAbout'
import { Button, GhostButton, flexRow } from '@bonitour/components'
import { marginTop, marginRight, mobileButtonContainer, mobileBackButton, mobileContinueButton } from 'assets/styles/global'
import { identity } from '@bonitour/common-functions'
import { useForm } from '@bonitour/app-functions'
import { OfflineExperienceRules } from './OfflineExperienceRules'
import { offlineExperienceAboutSchema } from './offlineExperienceAboutSchema'
import { object } from 'yup'

const schema = { serviceAbout: object(offlineExperienceAboutSchema).required() }

const marginTop50 = marginTop(50)
const marginRight20 = marginRight(20)

export const OfflineExperienceAboutForm = ({
  serviceAbout = {},
  onBackClick: emitBackClickEvent = identity,
  onSuccess: emitSuccessEvent = identity,
  onValidationError: emitValidationError = identity,
  infrastructureList = {}
}) => {
  const {
    form,
    errors,
    onSubmit,
    utils: { onInputBlur, onInputChange }
  } = useForm(serviceAbout, schema)
  const onClick = onSubmit(emitSuccessEvent, emitValidationError)

  return (
    <>
      <OfflineExperienceAbout
        serviceAbout={form.serviceAbout}
        infrastructureList={infrastructureList}
        serviceAboutErrors={errors.serviceAbout}
        onChange={onInputChange}
        onInputBlur={onInputBlur}
      />
      <OfflineExperienceRules offlineExperienceRules={form.serviceRules} onChange={onInputChange} />
      <div css={[flexRow, marginTop50, mobileButtonContainer]}>
        <GhostButton css={[marginRight20, mobileBackButton]} onClick={emitBackClickEvent}>Voltar</GhostButton>
        <Button css={mobileContinueButton} onClick={onClick}>Salvar e continuar</Button>
      </div>
    </>
  )
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import moment from 'moment'
import {
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow
} from '@bonitour/components'

const tableCss = css`
  table-layout: fixed;
  width: 100%;
  min-width: 100%;
  td {
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: unset;
  }
`

const NoticesTable = ({ notices = [], loading = false }) => {
  return (
    <TableContainer css={tableCss}>
      <TableHeader>
        <TableHeaderRow>
          <TableHeaderCell colSpan='18%'>Experiência</TableHeaderCell>
          <TableHeaderCell colSpan='11%'>Data</TableHeaderCell>
          <TableHeaderCell colSpan='9%'>Horário</TableHeaderCell>
          <TableHeaderCell colSpan='11%'>Categoria</TableHeaderCell>
          <TableHeaderCell colSpan='18%'>Subtítulo</TableHeaderCell>
          <TableHeaderCell colSpan='33%'>Mensagem</TableHeaderCell>
        </TableHeaderRow>
      </TableHeader>
      <TableBody loading={loading}>
        {notices.map(({ id, serviceTitle, date, hours, categoryLabel, previewMessage, message }) => (
          <TableBodyRow key={`notice-${id}`}>
            <TableBodyCell colSpan='18%'>{serviceTitle}</TableBodyCell>
            <TableBodyCell colSpan='11%'>{moment(date).format('DD/MM/YYYY')}</TableBodyCell>
            <TableBodyCell colSpan='9%'>{hours.join(', ')}</TableBodyCell>
            <TableBodyCell colSpan='11%'>{categoryLabel}</TableBodyCell>
            <TableBodyCell colSpan='18%'>{previewMessage}</TableBodyCell>
            <TableBodyCell colSpan='33%'>{message}</TableBodyCell>
          </TableBodyRow>
        ))}
      </TableBody>
    </TableContainer>
  )
}

export default NoticesTable

import { css } from '@emotion/core'
import { BREAK_POINTS, colors, flexColumnCentered } from '@bonitour/components'
import { clickable } from 'assets/styles/global'

export const swatchImageStyle = css`
  margin-bottom: 10px;
  font-size: 32px;
`

export const companyNameStyle = margin => css`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: ${colors.gray3};
  margin-top: ${margin}px;
`

export const accountTypeStyle = margin => css`
  ${companyNameStyle(margin)};
  font-size: 12px;
  font-weight: 400;
  color: ${colors.gray4};
`

export const valueStyle = (marginBottom, color) => css`
  font-size: 18px;
  font-weight: bold;
  color: ${color};
  margin-top: 10px;
  margin-bottom: ${marginBottom}px;
`

export const valueLabelStyle = color => css`
  font-size: 14px;
  color: ${color};
  margin-bottom: 10px;
`

export const lastModificationStyle = color => css`
  font-size: 12px;
  line-height: 1.25;
  color: ${color};
`

export const container = css`
  @media (max-width: ${BREAK_POINTS.tableUp}) {
    width: 100%;
  }
`

export const card = css`
  width: 155px;
  max-width: 155px;
  min-height: 220px;  
  margin: 8px;
  ${flexColumnCentered};
  ${clickable};

  @media (max-width: ${BREAK_POINTS.tableUp}) {
    width: 95%;
    max-width: 95%;
    min-width: 200px;
    margin: 10px 0;
    padding: 10px 0;
  }
`

export const alignLeft = css`
  text-align: left;
`

import { useCallback, useEffect, useState, useMemo } from 'react'
import { useToast } from '@bonitour/components'
import { CompanyCommuniquesService } from 'services/Communiques/Company/Service'
import { useCategories } from './useCategories'

export const useNoticeList = () => {
  const [notices, setNotices] = useState([])
  const [filters, setFilters] = useState(undefined)
  const [isLoading, setIsLoading] = useState(true)
  const [meta, setMeta] = useState({})
  const [pagination, setPagination] = useState({ currentPage: 1, perPage: 10 })

  const { categories } = useCategories()

  const { add: addToast } = useToast()

  useEffect(() => {
    if (filters === undefined) {
      setIsLoading(false)
      return
    }

    setIsLoading(true)
    CompanyCommuniquesService.list({
      pagination,
      filters
    })
      .then(({ meta, notices }) => {
        setMeta(meta)
        setNotices(notices)
      })
      .catch(() => {
        addToast('Erro ao listar comunicados enviados')
      })
      .finally(() => setIsLoading(false))
  }, [addToast, filters, pagination, pagination.currentPage, pagination.perPage])

  const noticesWithCategoryLabels = useMemo(
    () =>
      notices.map((notice) => ({
        ...notice,
        categoryLabel: (categories || [])?.find(({ id }) => id === notice.category)?.label
      })),
    [categories, notices]
  )

  const handlePagination = useCallback((currentPage = 1, perPage = 10) => {
    setPagination({ currentPage, perPage })
  }, [])

  const handleFilters = useCallback(
    ({
      sentInitialDate = undefined,
      sentFinalDate = undefined,
      serviceInitialDate = undefined,
      serviceFinalDate = undefined
    }) => {
      setFilters({
        sentInitialDate,
        sentFinalDate,
        serviceInitialDate,
        serviceFinalDate
      })
    },
    []
  )

  return {
    notices: noticesWithCategoryLabels,
    meta,
    handlePagination,
    handleFilters,
    isLoading
  }
}

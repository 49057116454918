export const RESERVATION_PAYMENT = 'Financial::ReservationPayment'
export const PAYMENT_MULTIPOINT = 'Financial::ReservationPaymentMultiponto'
export const RESERVATION_REFUND = 'Financial::ReservationRefund'
export const INVOICE_PAYMENT = 'Financial::InvoicePayment'
export const ADVANCE_RESERVATION_PAYMENT = 'Financial::AdvanceReservationPayment'
export const ADVANCE_RESERVATION_REFUND = 'Financial::AdvanceReservationRefund'
export const CASHIER_HANDLING_CASH_IN_FLOW = 'Financial::CashierHandlingCashInflow'
export const CASHIER_HANDLING_CASH_OUT_FLOW = 'Financial::CashierHandlingCashOutflow'
export const CASHIER_CLOSED = 'Financial::CashierClosed'
export const CASHIER_OPENED = 'Financial::CashierOpened'

export const originTypesMap = {
  [RESERVATION_PAYMENT]: {
    label: 'Pagamento',
    redirectUri: '/reservation/$id?selectedNav=financeiro'
  },
  [PAYMENT_MULTIPOINT]: {
    label: (multipointName = '') => multipointName ? `Multiponto ${multipointName}` : 'Multiponto',
    redirectUri: '/reservation/$id?selectedNav=financeiro'
  },
  [RESERVATION_REFUND]: {
    label: 'Reembolso',
    redirectUri: '/reservation/$id?selectedNav=financeiro'
  },
  [INVOICE_PAYMENT]: {
    label: 'Pagamento de Fatura',
    redirectUri: ''
  },
  [ADVANCE_RESERVATION_PAYMENT]: {
    label: 'Pagamento Antecipado de Fornecedor',
    redirectUri: ''
  },
  [ADVANCE_RESERVATION_REFUND]: {
    label: 'Reembolso Antecipado de Fornecedor',
    redirectUri: ''
  },
  [CASHIER_HANDLING_CASH_IN_FLOW]: {
    label: 'Entrada de caixa',
    redirectUri: ''
  },
  [CASHIER_HANDLING_CASH_OUT_FLOW]: {
    label: 'Saída de caixa',
    redirectUri: ''
  },
  [CASHIER_CLOSED]: {
    label: 'Fechamento de Caixa',
    redirectUri: ''
  },
  [CASHIER_OPENED]: {
    label: 'Reabertura de caixa',
    redirectUri: ''
  }
}

import { useCallback, useState } from 'react'
import { useToast } from '@bonitour/components'
import { ReportService } from 'core/services/Reports'
import { usePermissions } from 'hooks/usePermissions'
import { useParamsFilter } from 'hooks/useParamsFilter'

export const useListRevenues = () => {
  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()
  const [filters, setFilters] = useParamsFilter()
  const [isLoadingTotals, setIsLoadingTotals] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [pagination, setPagination] = useState({ currentPage: 1, perPage: 10 })
  const [revenuesData, setRevenuesData] = useState({ revenues: undefined, meta: undefined })
  const [totals, setTotals] = useState({ totals: undefined })

  const fetchRevenues = useCallback(({ filters, pagination }) => {
    if (!filters?.initialDate || !filters?.finalDate) {
      return addToast('É necessário definir um intervalo de datas', 'warning')
    }
    setIsLoading(true)
    setIsLoadingTotals(true)
    ReportService.listRevenueTotals({ filters })
      .then(setTotals)
      .finally(() => setIsLoadingTotals(false))
    return ReportService.listRevenue({ filters, pagination }).then((data) => {
      setRevenuesData(data)
    }).catch((error) => {
      console.error(error)
      const hasPermission = handlePermission(error, 'Você não possui permissão de acesso ao relatório de receita')
      hasPermission && addToast('Ocorreu um erro ao realizar a busca')
    }).finally(() => setIsLoading(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFilterSubmit = filters => setFilters(filters)

  const changePagination = useCallback((currentPage, perPage) => {
    const params = { currentPage, perPage }
    setPagination(params)
    fetchRevenues({ filters, pagination: params })
    // eslint-disable-next-line
  }, [filters])

  const update = useCallback(() => {
    fetchRevenues({ filters, pagination })
  }, [fetchRevenues, filters, pagination])

  return {
    filters,
    setFilters,
    onFilterSubmit,
    pagination,
    changePagination,
    revenues: revenuesData?.revenues,
    meta: revenuesData?.meta,
    totals,
    isLoading,
    isLoadingTotals,
    update
  }
}

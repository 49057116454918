import { getImageUrl } from 'services/Orb/Service'

export const ProvidersParser = {
  provider ({ image, ...rest }) {
    return { ...rest, image: getImageUrl(image) }
  },
  cards ({ meta = {}, providers = [] }) {
    const { total_pages: totalPages = 0, total_entries: total = 0 } = meta
    return { providers: providers.map(ProvidersParser.provider), totalPages, total }
  },
  payProviders ({ providers: payProviders = [] }) {
    return payProviders.map(({ id, name }) => ({ id, name }))
  }
}

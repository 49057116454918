/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Modal } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { PartnershipAccountEntryForm } from '../Form/PartnershipAccountEntryForm'

export const PartnershipAccountEntry = ({
  sellers = [],
  sellerSelected,
  isEntryVisible,
  isSelledDisabled,
  canDebitType,
  canCreditType,
  balance,
  onEntryHidden: emitEntryHidden = identity,
  onSubmit: emitSubmit = identity
}) => {
  return (
    <Modal title='Novo lançamento' isVisible={isEntryVisible} onCloseClick={emitEntryHidden}>
      <PartnershipAccountEntryForm
        sellers={sellers}
        entry={{ seller: sellerSelected, type: 'credit' }}
        isSelledDisabled={isSelledDisabled}
        canDebitType={canDebitType}
        canCreditType={canCreditType}
        balance={balance}
        onCloseClick={emitEntryHidden}
        onSubmit={emitSubmit}
      />
    </Modal>
  )
}

/* eslint-disable camelcase */
import { removeEmptyAttribute, replaceCommaToDot } from '@bonitour/common-functions'
import moment from 'moment'

export const reservationSearchParamsModel = (filterParams = {}) => {
  const {
    code = '',
    email = '',
    cpf: document = '',
    name = '',
    initialDate,
    finalDate,
    status = '',
    responsible = '',
    sellingChannel: selling_channel
  } = filterParams
  return {
    reservation_code: code,
    commercial_partner_id: responsible,
    full_name: name,
    initial_date: initialDate && moment(initialDate).format('YYYY-MM-DD'),
    final_date: finalDate && moment(finalDate).format('YYYY-MM-DD'),
    email,
    document: document.replace(/\D/g, ''),
    status,
    selling_channel
  }
}

const buildPassengerArray = (tickets) => tickets.reduce((acc, item) => {
  const { price, fee_type_id: feeTypeId, fee_id: feeId, quantity } = item
  let tickets = []
  for (let index = 0; index < quantity; index++) {
    tickets = tickets.concat({
      state: 'reserved',
      price: Number(price),
      fee_type_id: feeTypeId,
      fee_id: feeId
    })
  }
  return acc.concat(tickets)
}, [])

const buildTickets = (tickets, companyId, serviceId, date, hour) => {
  const passengers = buildPassengerArray(tickets)
  return [
    {
      company_id: companyId,
      service_id: serviceId,
      slot: `${date} ${hour}`,
      passengers
    }
  ]
}

export const reservationInfoModel = ({ comments }) => {
  return {
    comment: comments
  }
}

export const payerInfoModel = ({ payerId, name, email, identificationDocument, birthDate, number, comments, country, state, city, zipCode, street, supplement, district, emergencyTelephone, emergencyName, weight, height }) => {
  if (payerId) return ({ id: payerId })

  const address = {
    country_id: country,
    state_id: state,
    city_id: city,
    post_code: zipCode,
    street,
    supplement,
    region: district
  }

  return {
    full_name: name,
    email,
    birth_date: birthDate && moment(birthDate).format('YYYY-MM-DD'),
    document: identificationDocument,
    telephone: number,
    comment: comments,
    address: country ? address : undefined,
    additional_information: {
      emergency_telephone: emergencyTelephone,
      emergency_name: emergencyName,
      weight: weight && replaceCommaToDot(weight),
      height: height && Number(height.replace(',', ''))
    }
  }
}

export const reservationCreationModel = (payload = {}, companyId, serviceId) => {
  const {
    reservationHeader: { hour, date, partner, agent } = {},
    tickets = [],
    reservationType: { id, name, email, identificationDocument, birthDate, number, country, state, city, zipCode, street, supplement, district } = {},
    comments = '',
    affiliateCode = ''
  } = payload

  const ticketsArray = buildTickets(tickets, companyId, serviceId, date, hour)
  const payerParams = payerInfoModel(payload.reservationType)
  const anyPayerParamsIsFilled = id || name || email || birthDate || identificationDocument || number || country || state || city || zipCode || street || supplement || district

  const parsedValues = {
    comment: comments,
    affiliate_code: affiliateCode,
    status: 'reserved',
    commercial_partner_id: partner,
    tickets: ticketsArray,
    payer_params: anyPayerParamsIsFilled ? payerParams : undefined,
    user_id: agent || undefined
  }

  return removeEmptyAttribute(parsedValues)
}

export const ReservationModel = {
  onlyTickets (payload = {}, companyId, serviceId) {
    const {
      reservationHeader: { hour, date, agent } = {},
      tickets = []
    } = payload

    const ticketsArray = buildTickets(tickets, companyId, serviceId, date, hour)

    return {
      user_id: agent || undefined,
      tickets: ticketsArray
    }
  }
}

export const reservationConfirmEmailModel = (emailData = {}) => {
  const {
    reservationId: reservation_id,
    payerName: payer_name,
    email
  } = emailData

  return {
    reservation_id,
    payer_name,
    email
  }
}

export const ekoLocatorModel = (ekoLocator = '') => ({
  extra_infos: { eko_locator: ekoLocator }
})

export const reservationsVisualizedModel = ({ initialDate, finalDate, page, perPage }) => ({
  initial_date: initialDate,
  final_date: finalDate,
  page,
  per_page: perPage
})

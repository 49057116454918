export const removeDateFromString = (parsedString = '') => {
  return parsedString.replace(/(0?[1-9]|[12][0-9]|3[01])[/\-.](0?[1-9]|1[012])[/\-.]\d{4}/g, '')
}

export const extractDateFromString = (parsedString = '') => {
  const extractedArray = parsedString.match('[0-9]{2}([-/ .])[0-9]{2}[-/ .][0-9]{4}')

  return extractedArray[0]
}

export const removeSpecialCharactersFromString = (parsedString = '') => parsedString.replace(/[^\w\s]/gi, '')

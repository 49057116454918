import moment from 'moment'
import { metaModel } from './meta'

/* eslint-disable camelcase */
export const TicketModel = {
  single: (ticket = {}) => {
    const {
      activityHour: activity_hour,
      ticketCode: ticket_code,
      activity,
      traveller,
      paxType: pax_type,
      reservationId: reservation_id,
      status
    } = ticket

    return {
      activity_hour,
      ticket_code,
      activity,
      traveller,
      pax_type,
      reservation_id,
      status
    }
  },
  list: ({ filters = {}, pagination = {} }) => {
    const {
      startDate: start_date,
      endDate: end_date,
      activity,
      client,
      ticketCode: ticket_code
    } = filters

    const meta = metaModel(pagination)

    const searchParams = {
      start_date: start_date ? moment(start_date).format('YYYY-MM-DD') : undefined,
      end_date: end_date ? moment(end_date).format('YYYY-MM-DD') : undefined,
      activity,
      client,
      ticket_code
    }

    return { searchParams, pagination: meta }
  },
  confirm: (confirmData = {}) => {
    const {
      reservationId: reservation_id,
      commercialPartnerId: commercial_partner_id
    } = confirmData

    return { reservation_id, commercial_partner_id }
  },
  externalIntegration: (voucher) => {
    const {
      integrationName: integration_name,
      externalTicketId: external_ticket_id
    } = voucher

    return {
      integration_name,
      external_ticket_id
    }
  },
  singleTicket: (ticket) => {
    const { ticketCode } = ticket

    return ticketCode
  },
  manyTickets: (codes) => {
    return codes.map(TicketModel.singleTicket)
  },
  changeState: (payload) => {
    const { tickets, state } = payload

    const ticketsParsed = tickets.map(({ ticketId, reservationId }) => {
      return {
        ticket_id: ticketId,
        reservation_id: reservationId
      }
    })

    return {
      tickets: ticketsParsed,
      state
    }
  },
  updateDeadlineRule: (payload) => {
    const { ticketsIds, deadlineDate, noShow } = payload

    return {
      ticket_ids: ticketsIds,
      deadline_date: new Date(deadlineDate)?.toISOString()?.split('T')[0],
      no_show: noShow
    }
  }
}

/**
 * @typedef {Object} Props
 * @property {number} vX
 * @property {number} vY
 * @property {number} height
 * @property {number} width
 * @property {[number]} roundness
 */

/**
 * @type {(props:Props) => string}
 */
export const createJaggedPaperPath = (props) => {
  const { vX, vY, height, width, roundness = 3 } = props

  const startX = vX / 2
  const startY = (vY - height) / 2

  const CUT_AVERAGE_WIDTH = 5
  const CUT_HEIGHT = 4

  const numberOfCuts = Math.floor(width / CUT_AVERAGE_WIDTH)
  const realCutWidth = width / numberOfCuts

  const jaggedLine = Array.from({ length: numberOfCuts * 2 }, (_, i) => {
    return `l${realCutWidth / 2} ${i % 2 === 0 ? -1 * CUT_HEIGHT : CUT_HEIGHT}`
  })

  const pathD = [
    `M${startX} ${startY}`,
    `h${-1 * (width / 2 - roundness)}`,
    `a${roundness} ${roundness} 0 0 0 -${roundness} ${roundness}`,
    `v${height - roundness * 2}`,
    jaggedLine.join(''),
    `v${-1 * (height - roundness * 2)}`,
    `a${roundness} ${roundness} 0 0 0 -${roundness} -${roundness}`,
    `h${-1 * (width / 2 - roundness)}`
  ]

  return pathD.join('')
}

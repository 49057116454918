import { TicketsService } from 'core/services/Tickets'
import { useState } from 'react'
import { useToast } from '@bonitour/components'
import { handleToastType } from 'utils/toastType'
import { brazilianDateFormat } from 'utils/date'
import { removeDateFromString, removeSpecialCharactersFromString } from 'utils/string'

export const useTicketPrint = () => {
  const [loading, setLoading] = useState(false)
  const { add: addToast } = useToast()

  const ticketPrint = async (ticketId) => {
    setLoading(true)
    return TicketsService.ticketPrint(ticketId).catch((error) => {
      const { data: { errors, errors_msg: errorMessage, extra_data: { message } } } = error
      const toastType = handleToastType(errors, errorMessage)

      if (errors && errorMessage) {
        addToast('Erro durante a impressão do ticket')
      }

      if (message === 'ticket_seller::integration_ticket::not_found') {
        addToast('Ticket não encontrado')
      }

      const messageWithoutDate = removeDateFromString(message)
      const messageWithoutSpecialCharacters = removeSpecialCharactersFromString(messageWithoutDate)
      const parsedMessage = `${messageWithoutSpecialCharacters} ${brazilianDateFormat(message)}`

      addToast(parsedMessage, toastType)
    }).finally(() => setLoading(false))
  }

  const ticketPrintVoucherTuris = async (ticketId) => {
    setLoading(true)
    return TicketsService.ticketPrintVoucherTuris(ticketId).catch((error) => {
      const { data: { errors, errors_msg: errorMessage, extra_data: { message } } } = error
      const toastType = handleToastType(errors, errorMessage)

      if (errors && errorMessage) {
        addToast('Erro durante a impressão do ticket')
      }

      if (message === 'ticket_seller::integration_ticket::not_found') {
        addToast('Ticket não encontrado')
      }

      const messageWithoutDate = removeDateFromString(message)
      const messageWithoutSpecialCharacters = removeSpecialCharactersFromString(messageWithoutDate)
      const parsedMessage = `${messageWithoutSpecialCharacters} ${brazilianDateFormat(message)}`

      addToast(parsedMessage, toastType)
    }).finally(() => setLoading(false))
  }

  return {
    ticketPrint,
    ticketPrintVoucherTuris,
    loading
  }
}

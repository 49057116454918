import { SafeDate } from '@binamik/js-providers'
import { useDebounce } from '@bonitour/app-functions'
import { useActivity } from 'contexts/Activity'
import { ActivityService } from 'core/services/Activity'
import { useEffect, useState } from 'react'

export const useDailyMonitoring = () => {
  const { id: serviceId } = useActivity()

  const [date, setDate] = useState(new SafeDate().format('YYYY-MM-DD'))
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(false)

  const debouncedDate = useDebounce(date, 600)
  useEffect(() => {
    setLoading(true)
    ActivityService.getDailyMonitoringReservations(serviceId, debouncedDate).then(setTableData).finally(() => setLoading(false))
  }, [serviceId, debouncedDate])

  return {
    date,
    setDate,
    tableData,
    loading
  }
}

import { deepGetOrElse } from 'deep-getter'
import { addressModel } from 'core/models/address'
import { contactsModel } from 'core/models/contact'
import { head, replaceCommaToDot } from '@bonitour/common-functions'

export function transportModel (data, companyId) {
  const {
    transportId = {},
    destinationInfo = {},
    originInfo = {},
    phones = [],
    emails = [],
    sellingDeadline = 0
  } = data

  const destinationData = addressModel(destinationInfo)
  const originData = addressModel(originInfo)
  const contacts = contactsModel({ emails, phones })
  const image = deepGetOrElse(transportId, 'files.0.file', null)

  const { name: title = '' } = transportId

  return {
    transport: {
      company_id: companyId,
      title,
      // description: '', /* Already implemented by the backend */
      contacts,
      enabled: true,
      image,
      selling_deadline: sellingDeadline
    },
    stretchs: [
      {
        departure_address: originData,
        arrival_address: destinationData
      }
    ]
  }
}

export function transportUpdateModel (data) {
  const {
    transportId = {},
    destinationInfo = {},
    originInfo = {},
    phones = [],
    emails = []
  } = data

  const destinationData = addressModel(destinationInfo)
  const originData = addressModel(originInfo)
  const contacts = contactsModel({ emails, phones })

  const { name: title = '' } = transportId

  const basePayload = {
    transport: {
      title,
      contacts
    },
    stretchs: [
      {
        departure_address: originData,
        arrival_address: destinationData
      }
    ]
  }

  const image = (head(transportId.files.filter(({ file }) => file)) || {}).file
  const hasImage = !!transportId?.files?.length
  if (image || hasImage) {
    basePayload.transport.image = image
  } else {
    basePayload.transport.image = ''
  }

  return basePayload
}

export const aboutModel = (transportAbout = {}) => {
  const {
    duration,
    workDuration,
    infrastructure,
    generalRules,
    category,
    difficultyLevel,
    travelerInfoRequired
  } = transportAbout

  return {
    service_time: duration,
    employee_time: workDuration,
    infrastructure,
    category_id: category,
    difficulty_level: difficultyLevel,
    traveler_info_required: travelerInfoRequired,
    general_rules: generalRules
  }
}

const rulesModel = (activityRules = {}) => {
  const { minAge, minHeight, maxWeight } = activityRules.restrictions || {}
  const minHeightNormalized = replaceCommaToDot(String(minHeight))
  const maxWeightNormalized = replaceCommaToDot(String(maxWeight))

  return {
    restrictions: {
      min_age: Number(minAge),
      min_height: String(minHeightNormalized),
      max_weight: String(maxWeightNormalized)
    }
  }
}

export const transportComplementaryInfoModel = (
  transportComplementaryInfo = {}
) => {
  const {
    description,
    paymentsTypes,
    whatToTake,
    whatIsIncluded,
    observation,
    isAllyVisible,
    sellingDeadline
  } = transportComplementaryInfo

  return {
    transport: {
      description: JSON.stringify({
        'pt-br': description || ''
      }),
      observation,
      payment_types: paymentsTypes,
      what_to_take: whatToTake,
      what_is_included: whatIsIncluded,
      ally_visible: isAllyVisible,
      selling_deadline: sellingDeadline
    }
  }
}

export function transportAboutModel (data) {
  const { transportAbout = {}, transportRules = {} } = data

  const about = aboutModel(transportAbout)
  const rules = rulesModel(transportRules)

  return {
    transport: {
      ...about,
      ...rules
    }
  }
}

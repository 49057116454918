import { getImageUrl } from 'services/Orb/Service'
import { OrbStore } from 'services/Orb/Store'

const ACCESS_ID = 'user'

export const UserStore = {
  getUser () {
    try {
      const user = JSON.parse(OrbStore.get(ACCESS_ID))
      user.avatar = getImageUrl(user.avatar)
      return user || {}
    } catch (error) {
      return {}
    }
  },
  setUser (user) {
    try {
      const infoString = JSON.stringify(user)
      return OrbStore.set(ACCESS_ID, infoString)
    } catch (error) {}
  },
  clean () {
    OrbStore.remove(ACCESS_ID)
  }
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { useToast } from '@bonitour/components'
import { jsx } from '@emotion/core'
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ReservationService } from 'services/Reservations/Service'

const isUUID = (string = '') => /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(string)

export const ReservationContext = createContext({})

export const useReservation = () => useContext(ReservationContext)

const totalTicketsValue = activities =>
  activities
    .map(({ tickets }) => tickets.reduce((total, ticket) => total + ((ticket.status !== 'canceled') ? Number(ticket.value || 0) : 0), 0))
    .reduce((total, activityTotal) => total + Number(activityTotal), 0)

export const ReservationProvider = ({ children }) => {
  const history = useHistory()
  const { add: addToast } = useToast()
  const { reservationIdentification } = useParams()
  const [reservationId, setReservationId] = useState()
  const [reservationCode, setReservationCode] = useState()
  const [subordinatesLoading, setSubordinatesLoading] = useState(false)
  const [reservationTicketLoading, setReservationTicketLoading] = useState(false)
  const [deleteTicketLoading, setDeleteTicketLoading] = useState(false)
  const [editStatusReservationLoading, setEditStatusReservationLoading] = useState(false)
  const [sendEmailLoading, setSendEmailLoading] = useState(false)
  const [reservation, setReservation] = useState({})
  const [reservationTickets, setReservationTickets] = useState([])
  const [reservationPassengers, setReservationPassengers] = useState([])
  const [reservationPassengersLoading, setReservationPassengersLoading] = useState(false)
  const [changePayerLoading, setChangePayerLoading] = useState(false)
  const [totalValue, setTotal] = useState(0)

  useEffect(() => {
    const { id, reservationCode } = reservation
    setReservationId(id)
    setReservationCode(reservationCode)
  }, [reservation])

  const updateReservation = useCallback(() => {
    if (reservationIdentification) {
      if (!isUUID(reservationIdentification)) {
        ReservationService.getByReservationCode(reservationIdentification)
          .then(setReservation)
          .catch(() => {
            addToast('Reserva não encontrada')
            history.goBack()
          })
      } else {
        ReservationService.getById(reservationIdentification)
          .then(setReservation)
          .catch(() => {
            addToast('Reserva não encontrada')
            history.goBack()
          })
      }
    }
    // eslint-disable-next-line
  }, [reservationIdentification])

  const fetchReservationTickets = useCallback(() => {
    setReservationTicketLoading(true)
    return ReservationService.getReservationTickets(reservationIdentification).then(setReservationTickets).catch(() => {
      addToast('Erro na busca dos ingressos')
      setReservationTicketLoading(false)
    }).finally(() => {
      setReservationTicketLoading(false)
    })
  }, [addToast, reservationIdentification])

  const changeReservation = useCallback(async info => {
    if (!reservationId) {
      return
    }
    try {
      await ReservationService.changeInfo(reservationId, info)
      updateReservation()
      addToast('Reserva atualizada com sucesso', 'success')
    } catch (error) {
      addToast('Ocorreu um erro na atualização da reserva')
    }
  }, [reservationId, updateReservation, addToast])

  const changeReservationStatus = useCallback(async status => {
    setEditStatusReservationLoading(true)
    if (!reservationId) {
      setEditStatusReservationLoading(false)
      return
    }
    try {
      await ReservationService.changeStatus(reservationId, status)
      setEditStatusReservationLoading(false)
      updateReservation()
      addToast('O estado da reserva foi alterado com sucesso', 'success')
    } catch (error) {
      addToast('Erro na atualização do status')
    }
  }, [reservationId, updateReservation, addToast])

  const deleteReservationTicket = useCallback((ticketId) => {
    setDeleteTicketLoading(true)
    if (!reservationId) {
      setDeleteTicketLoading(false)
      return
    }
    ReservationService.cancelTicket(reservationId, ticketId).then(() => {
      addToast('Ingresso cancelado com sucesso', 'success')
      fetchReservationTickets()
    }).catch((error) => {
      // backend returning generic error
      if (error?.data?.errors_msg.includes('uc_cancel_ticket::invalid_tickets')) {
        return addToast('Ingresso não pode ser cancelado pois já está dentro de uma fatura')
      }

      return addToast('Erro no cancelamento do ingresso')
    }).finally(() => {
      setDeleteTicketLoading(false)
    })
  }, [reservationId, fetchReservationTickets, addToast])

  const getSubordinates = useCallback(async (partnerCompanyId) => {
    setSubordinatesLoading(true)
    return ReservationService.getSubordinates({ partnerCompanyId })
      .then(() => true)
      .catch(() => false)
      .finally(() => setSubordinatesLoading(false))
  }, [])

  const sendReservationConfirmEmail = useCallback(async emailData => {
    setSendEmailLoading(true)
    try {
      await ReservationService.sendReservationConfirmEmail(emailData)
      setSendEmailLoading(false)
      addToast('E-mail enviado com sucesso', 'success')
    } catch (error) {
      setSendEmailLoading(false)
      const errorMessage = error?.data?.errors_msg
      const errorCode = error?.data?.errors

      const errorMessages = {
        'ticket_seller::reservation::not_found': `A reserva não foi encontrada. ${errorCode}`,
        default: `Houve um erro no envio do email. ${errorCode}`
      }

      addToast(errorMessages[errorMessage] ?? errorMessages.default)
    }
  }, [addToast])

  const fetchReservationPassengers = useCallback(async (reservationId) => {
    setReservationPassengersLoading(true)

    return ReservationService.getPassengers(reservationId)
      .then(({ passengers }) => setReservationPassengers(passengers))
      .finally(() => setReservationPassengersLoading(false))
  }, [])

  const changePayer = useCallback(async (reservationId, payerId) => {
    setChangePayerLoading(true)
    return ReservationService.changePayer(reservationId, payerId)
      .then(() => {
        addToast('Responsável pela reserva atualizado com sucesso', 'success')
        updateReservation()
      })
      .catch(() => addToast('Ocorreu um erro ao atualizar o responsável pela reserva'))
      .finally(() => setChangePayerLoading(false))
    // eslint-disable-next-line
  }, [addToast])

  const updateEkoLocator = useCallback(async (ekoLocator = '') => {
    if (!reservationId) {
      return
    }
    try {
      await ReservationService.updateEkoLocator(reservationId, ekoLocator)
      updateReservation()
      addToast('Eco Localizador atualizado com sucesso', 'success')
    } catch (error) {
      addToast('Ocorreu um erro na atualização do Eco Localizador')
    }
  }, [addToast, reservationId, updateReservation])

  useEffect(() => {
    updateReservation()
  }, [updateReservation])

  useEffect(() => {
    if (reservation.tickets) {
      setTotal(totalTicketsValue(reservation.tickets.activities))
    }
  }, [reservation.tickets])

  const providerMethods = useMemo(() => ({
    reservationId,
    reservationCode,
    reservation,
    reservationTickets,
    reservationPassengers,
    reservationPassengersLoading,
    reservationTicketLoading,
    fetchReservationPassengers,
    fetchReservationTickets,
    totalValue,
    updateReservation,
    getSubordinates,
    sendReservationConfirmEmail,
    subordinatesLoading,
    deleteTicketLoading,
    editStatusReservationLoading,
    sendEmailLoading,
    changeReservation,
    changeReservationStatus,
    changePayer,
    changePayerLoading,
    deleteReservationTicket,
    updateEkoLocator
  }), [
    reservationId,
    reservationCode,
    reservation,
    reservationTickets,
    reservationPassengers,
    reservationPassengersLoading,
    reservationTicketLoading,
    fetchReservationPassengers,
    fetchReservationTickets,
    totalValue,
    updateReservation,
    getSubordinates,
    sendReservationConfirmEmail,
    subordinatesLoading,
    deleteTicketLoading,
    editStatusReservationLoading,
    sendEmailLoading,
    changeReservation,
    changeReservationStatus,
    changePayer,
    changePayerLoading,
    deleteReservationTicket,
    updateEkoLocator
  ])

  return (
    <ReservationContext.Provider value={providerMethods}>
      {children}
    </ReservationContext.Provider>
  )
}

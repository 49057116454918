import { formatISOTime } from 'utils/time'

export const ReservationModel = {
  historyParams ({ itemType = '', startDate, endDate }) {
    return {
      item_type: itemType,
      start_date: formatISOTime(startDate.toISOString(), 'YYYY/MM/DD'),
      end_date: formatISOTime(endDate.toISOString(), 'YYYY/MM/DD')
    }
  },

  historyParamsV2 ({ itemTypes = [], initialDate, finalDate, eventType = '', page = 1, perPage = 10 }) {
    return {
      item_type: itemTypes,
      event: eventType || undefined,
      page,
      per_page: perPage,
      start_date: formatISOTime(initialDate, 'YYYY/MM/DD'),
      end_date: formatISOTime(finalDate, 'YYYY/MM/DD')
    }
  },

  changeTicket (payload = []) {
    const { activityId = '', hour = '', day = '', ticketsIds = [] } = payload

    return {
      tickets_ids: ticketsIds,
      service_id: activityId,
      slot: `${day}T${hour}:00.000Z`
    }
  }
}

import { useState } from 'react'
import { TicketsService } from 'core/services/Tickets'
import { useReservation } from 'hooks/context/Reservation'
import { useToast } from '@bonitour/components'

export const useCancelTicketsBatch = () => {
  const { add: addToast } = useToast()
  const [loading, setLoading] = useState(false)
  const { fetchReservationTickets } = useReservation()

  const onCanceledTickets = async (payload) => {
    setLoading(true)
    TicketsService.changeState(payload).then((res) => {
      if (res?.data?.some(ticket => ticket?.error?.errors_msg?.includes('uc_cancel_ticket::permission'))) {
        return addToast('O usuário não tem permissão para cancelar os ingressos.')
      }

      if (res?.data?.some(ticket => ticket?.error)) {
        return addToast('Houve um erro parcial ao cancelar os ingressos', 'warning')
      }

      fetchReservationTickets()
      addToast('Ingressos cancelados com sucesso', 'success')
    }).catch(() => {
      addToast('Ocorreu um erro ao cancelar os ingressos')
    }).finally(() => setLoading(false))
  }

  return { onCanceledTickets, loading }
}

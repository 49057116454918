/** @jsxRuntime classic */
/** @jsx jsx */
import { useForm } from '@bonitour/app-functions'
import { identity } from '@bonitour/common-functions'
import { BREAK_POINTS, Button, colors, Column, flexRowCentered, HorizontalInputFormGroup, Input, RedeemCodeIcon, Row, useToast } from '@bonitour/components'
import { css, jsx } from '@emotion/core'
import { useKeyPress } from 'hooks/useKeyPress'
import { string } from 'yup'

const container = css`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    flex-direction: column;
  }
`

const formStyle = css`
  margin: 15px auto;
  background: ${colors.white};
  padding: 5px;
  box-sizing: border-box;
  border-radius: var(--round-element-radius, 35px);
  box-shadow: 0px 0px 10px #00000019;
  width: 600px;
  position: relative;
  margin-right: 5px;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    width: 100%;
  }
`

const overwriteInput = css`
  width: 100%;
  padding: 5px 0.75rem;
  border-color: transparent !important;
`

const inputGroup = css`
  width: 100%;
  box-sizing: border-box;
  padding-right: 2rem;
`

const scanIcon = css`
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 15px;
`

const ticketSchema = { code: string().required('O código é obrigatório').min(4, 'O código deve ter no mínimo 4 caracteres') }

export const TicketSearch = ({
  formState = {},
  onSearch = identity,
  onScan = identity
}) => {
  const { add: addToast } = useToast()
  const { form, errors, onSubmit, utils: { onInputChange, onInputBlur } } = useForm(formState, ticketSchema)
  const { onKeyDown } = useKeyPress()

  const onClick = onSubmit(onSearch, () => addToast('Preencha o formulário corretamente'))

  return (
    <Row css={flexRowCentered}>
      <Column phone={12} desktop={5} css={container}>
        <div css={formStyle}>
          <HorizontalInputFormGroup errorMessage={errors.code} customCss={[inputGroup]}>
            <Input
              value={form.code}
              customCss={[overwriteInput]}
              onChange={onInputChange('code')}
              onBlur={onInputBlur('code')}
              placeholder='Código do ingresso'
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </HorizontalInputFormGroup>
          <RedeemCodeIcon css={scanIcon} onClick={onScan} />
        </div>
        <Button onClick={onClick}>Pesquisar</Button>
      </Column>
    </Row>
  )
}

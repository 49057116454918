/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import { Column, Row, MultiSelectDropdown, flexColumn, Label } from '@bonitour/components'
import { customSelectStyle, list } from './NoticeForm.style'
import { useCallback, useContext, memo, useMemo } from 'react'
import { ActivityContext } from 'contexts/Activity'
import { marginTop15 } from 'containers/ActivityFeeForm/FeePaxType/FeePaxTypeRow.style'
import NoticeHourlySelection from './NoticeHourlySelection'

const NoticeExperienceSelection = memo(
  ({
    date,
    emitChangeEvent,
    noticeErrors,
    emitBlurEvent,
    hours,
    selectedCompanyId,
    serviceIds,
    setActivitiesWithVacancies
  }) => {
    const { parseActivities } = useContext(ActivityContext)
    const filteredActivities = useMemo(() => parseActivities(selectedCompanyId), [selectedCompanyId, parseActivities])

    const selectedActivities = useMemo(() => {
      return filteredActivities.filter(({ value }) => serviceIds?.includes(value))
    }, [filteredActivities, serviceIds])

    const onSelectActivities = useCallback(
      (value) => {
        const experienceIds = Object.keys(hours)
        const itemsRemoved = experienceIds.filter((id) => !value.includes(id))

        itemsRemoved.forEach((id) => {
          delete hours[id]

          setActivitiesWithVacancies((previousValue) => {
            const activitiesWithVacancies = previousValue.filter((activityId) => activityId !== id)

            return [...activitiesWithVacancies]
          })
        })

        const itemsAdded = value.filter((item) => !serviceIds.includes(item))

        itemsAdded.forEach((id) => {
          hours[id] = []
        })

        emitChangeEvent('serviceIds')(value)
      },
      [emitChangeEvent, hours, serviceIds, setActivitiesWithVacancies]
    )

    const errorMsg = useMemo(() => {
      if (noticeErrors?.hours?.general) return noticeErrors.hours.general
      return noticeErrors.serviceIds
    }, [noticeErrors])

    return (
      <>
        <Row>
          <Column phone={12} desktop={7}>
            <MultiSelectDropdown
              label="Selecionar experiências"
              placeholder="Selecione uma atividade"
              errorMessage={errorMsg}
              onBlur={emitBlurEvent('serviceIds')}
              options={filteredActivities}
              customCss={[customSelectStyle]}
              onChange={onSelectActivities}
              selected={serviceIds}
            />
          </Column>
        </Row>

        {selectedActivities?.length > 0 && (
          <Row>
            <Column phone={12} desktop={12} css={[marginTop15, flexColumn]}>
              <Label>Horário das experiências</Label>
              <ul css={list}>
                {selectedActivities?.map((activity) => (
                  <li key={activity.value}>
                    <p>{activity.label}</p>
                    <Row>
                      <Column mobile={12} desktop={7}>
                        <NoticeHourlySelection
                          activityId={activity?.value}
                          date={date}
                          noticeErrors={noticeErrors}
                          hours={hours}
                          onChange={emitChangeEvent}
                          onBlur={emitBlurEvent}
                          setActivitiesWithVacancies={setActivitiesWithVacancies}
                          companyId={selectedCompanyId}
                        />
                      </Column>
                    </Row>
                  </li>
                ))}
              </ul>
            </Column>
          </Row>
        )}
      </>
    )
  }
)

export default NoticeExperienceSelection

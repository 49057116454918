import anyBase from 'any-base'

// Privider used for encoding tickets on Kiosk
// kiosk-thermal-printer project

class CodeCondenserProvider {
  static instance = null
  static asciiAlphabet = ' !"#$%&\'()*+,-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[\\]^_`abcdefghijklmnopqrstuvwxyz{|}~'
  static encodinAlphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@#$%^&*()_-+={[]}|\\:\'"<,.>/?'
  static codeAlphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  static codeSize = 15
  static splitChar = ';'

  constructor () {
    this.base36To8859 = anyBase(
      CodeCondenserProvider.codeAlphabet,
      CodeCondenserProvider.encodinAlphabet
    )

    this.base8859To36 = anyBase(
      CodeCondenserProvider.encodinAlphabet,
      CodeCondenserProvider.codeAlphabet
    )

    this.shrinkedSize = Math.ceil(
      Math.log(CodeCondenserProvider.codeAlphabet.length ** CodeCondenserProvider.codeSize) /
        Math.log(CodeCondenserProvider.encodinAlphabet.length)
    )
  }

  static getInstance () {
    if (!CodeCondenserProvider.instance) {
      CodeCondenserProvider.instance = new CodeCondenserProvider()
    }

    return CodeCondenserProvider.instance
  }

  checkIfCodeIsValid (code) {
    const lowerCasedCode = code.toLowerCase()
    const uppercasedCode = code.toUpperCase()

    const isCodeCaseInsensitive =
      code === lowerCasedCode || code === uppercasedCode

    if (!isCodeCaseInsensitive) {
      return false
    }

    const hasCharOutsideAlphabet = code
      .split('')
      .some(char => !CodeCondenserProvider.codeAlphabet.includes(char))

    if (hasCharOutsideAlphabet) {
      return false
    }

    return true
  }

  shrinkCode (code) {
    const shrinked = this.base36To8859(code)
    return shrinked.padStart(
      this.shrinkedSize,
      CodeCondenserProvider.encodinAlphabet[0]
    )
  }

  joinCodes (codes) {
    return codes.join(CodeCondenserProvider.splitChar)
  }

  splitCodes (codes) {
    return codes.split(CodeCondenserProvider.splitChar)
  }

  manuallyGroupCodes (
    shrinkedCodes, maxLength
  ) {
    const codesAmount = shrinkedCodes.length

    const safeDivisor = Math.ceil(codesAmount / maxLength)
    const groups = []

    for (let i = 0; i < safeDivisor; i++) {
      groups.push([])
    }

    const getSmallerGroupIdx = (groupsToCheck) =>
      groupsToCheck.reduce((acc, group, index) => {
        if (this.joinCodes(group).length < this.joinCodes(groups[acc]).length) {
          return index
        }

        return acc
      }, 0)

    for (let i = 1; i < codesAmount; i++) {
      const currCodeLength = shrinkedCodes[i].length

      const smallerGroupIdx = getSmallerGroupIdx(groups)
      const smallerGroupLegth = this.joinCodes(groups[smallerGroupIdx]).length

      if (smallerGroupLegth + currCodeLength <= maxLength) {
        groups[smallerGroupIdx].push(shrinkedCodes[i])
      } else {
        groups.push([shrinkedCodes[i]])
      }
    }

    return shrinkedCodes
  }

  condenseCodes (codes = [], maxLength = 133) {
    const allCodesAreValid = codes.every(code => this.checkIfCodeIsValid(code))
    if (!allCodesAreValid) {
      throw new Error('Invalid codes provided')
    }

    const shrinkedCodes = codes.map((code) => this.shrinkCode(code))

    const totalLength = this.joinCodes(shrinkedCodes).length

    if (totalLength <= maxLength) {
      return [this.joinCodes(shrinkedCodes)]
    }

    const safeDivisor = Math.ceil(totalLength / maxLength)

    const groupedCodes = shrinkedCodes.reduce(
      (acc, code, index) => {
        const groupIndex = index % safeDivisor

        if (!acc[groupIndex]) {
          acc[groupIndex] = []
        }

        acc[groupIndex].push(code)

        return acc
      }, []
    )

    const groupedAndJoinedCodes = groupedCodes.map(
      (codesArr) => this.joinCodes(codesArr)
    )

    const anyGroupIsTooLong = groupedAndJoinedCodes.some(
      group => group.length > maxLength
    )

    if (anyGroupIsTooLong) {
      return this.manuallyGroupCodes(shrinkedCodes, maxLength)
    } else {
      return groupedAndJoinedCodes
    }
  }

  expandCode (code) {
    return this.base8859To36(code)
      .padStart(
        CodeCondenserProvider.codeSize,
        CodeCondenserProvider.codeAlphabet[0]
      )
  }

  expandCodes (codes) {
    const splitedCodesGroups = codes.map(
      (codesGroup) => this.splitCodes(codesGroup)
    )
    const condensedCodes = splitedCodesGroups.flat()
    const expandedCodes = condensedCodes.map((code) => this.expandCode(code))
    return expandedCodes
  }

  matchCondensedCodesWithFees = (codes, condensedGroups) => {
    const feeMap = codes.reduce((acc, { code, feeName }) => {
      const shrinked = this.shrinkCode(code)
      acc[shrinked] = feeName
      return acc
    }, {})

    const shrinkedCodes = Object.keys(feeMap)

    const matchedCodes = condensedGroups.map((group) => {
      const everyMatchedCode = shrinkedCodes.filter(
        (shrinkedCode) => group.includes(shrinkedCode)
      )

      const fees = everyMatchedCode.map((code) => feeMap[code])

      return {
        data: group,
        fees
      }
    })

    return matchedCodes
  }
}

export const CodesCondenser = CodeCondenserProvider.getInstance()

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { TableBodyRow, TableBodyCell, Swatch, TrashIcon, TooltipOnHover, PencilIcon, ConfirmDialog, flex } from '@bonitour/components'
import { defaultRowHeight, defaultCellPadding, clickable, marginRight } from 'assets/styles/global'
import { identity } from '@bonitour/common-functions'
import { useCallback, useState } from 'react'

const iconSize = css`
  font-size: 23px;
`

const disabledActionStyle = css`
  cursor: default;
  opacity: 0.35 !important;
`

const disabledTooltipsStyle = css`
  .tooltip-on-hover {
    display: none !important;
  }
`

export function ActivityVacancyRow ({
  onUpdate: emitUpdateEvent = identity,
  onDelete: emitDeleteEvent = identity,
  onExceptionalDayUpdate: emitExceptionalDayUpdateEvent = identity,
  onExceptionalDayDelete: emitExceptionalDayDeleteEvent = identity,
  isDisabledControls = false,
  activityVacancy,
  ...other
}) {
  const { id, color, name, vacancyQuantity, dailyLimit, periodQuantity, schedulesQuantity, isExceptionalDay, exceptionalDate } = activityVacancy
  const [isConfirmActionVisible, setConfirmActionVisible] = useState(false)
  const onActionWrapper = useCallback((callback) => () => {
    if (isDisabledControls) return
    callback()
  }, [isDisabledControls])
  const toggleConfirmActionVisible = onActionWrapper(() => setConfirmActionVisible(true))
  const toggleConfirmActionHidden = onActionWrapper(() => setConfirmActionVisible(false))
  const onDeleteVacancy = onActionWrapper(() => {
    isExceptionalDay ? emitExceptionalDayDeleteEvent(id) : emitDeleteEvent(id)
    toggleConfirmActionHidden()
  })

  return (
    <>
      <ConfirmDialog
        title={`Exclusão de ${isExceptionalDay ? 'dia excepcional' : 'vagas'}`}
        message={`${isExceptionalDay ? 'Esse dia excepcional será excluído' : 'Essa vaga será excluída'} permanentemente. Deseja continuar?`}
        isVisible={isConfirmActionVisible}
        successCallback={onDeleteVacancy}
        cancelCallback={toggleConfirmActionHidden}
      />

      <TableBodyRow css={defaultRowHeight} {...other}>
        <TableBodyCell css={defaultCellPadding}>
          <Swatch size={20} color={color} />
        </TableBodyCell>
        <TableBodyCell css={defaultCellPadding}>{name}</TableBodyCell>
        <TableBodyCell css={defaultCellPadding}>{dailyLimit}</TableBodyCell>
        <TableBodyCell css={defaultCellPadding}>{vacancyQuantity}</TableBodyCell>
        <TableBodyCell css={defaultCellPadding}>{periodQuantity}</TableBodyCell>
        <TableBodyCell css={defaultCellPadding}>{schedulesQuantity}</TableBodyCell>
        <TableBodyCell css={defaultCellPadding} fixed>
          <div css={[flex, isDisabledControls && disabledTooltipsStyle]}>
            <TooltipOnHover text={`Editar ${isExceptionalDay ? 'dia excepcional' : 'vaga'}`}>
              <PencilIcon css={[iconSize, clickable, marginRight(10), isDisabledControls && disabledActionStyle]} onClick={isExceptionalDay ? onActionWrapper(emitExceptionalDayUpdateEvent(exceptionalDate)) : onActionWrapper(emitUpdateEvent(id))} />
            </TooltipOnHover>
            <TooltipOnHover text={`Excluir ${isExceptionalDay ? 'dia excepcional' : 'vaga'}`}>
              <TrashIcon css={[iconSize, clickable, isDisabledControls && disabledActionStyle]} onClick={toggleConfirmActionVisible} />
            </TooltipOnHover>
          </div>
        </TableBodyCell>
      </TableBodyRow>
    </>
  )
}

/* eslint-disable camelcase */
import { removeEmptyAttribute } from '@bonitour/common-functions'
import { metaModel } from 'core/models/meta'
import moment from 'moment'

export function AffiliateCreateModel (data) {
  const { email, percentage } = data
  return { email, percentage }
}

export function AffiliatePaginatedModel (data) {
  const { page, perPage, name, status } = data
  return {
    name,
    status,
    page,
    per_page: perPage
  }
}

export function AffiliateTicketsToBatchModel ({ filters, pagination }) {
  const searchParams = AffiliateTicketsToBatchSearchParamsModel(filters)
  const meta = metaModel(pagination)
  return { searchParams, pagination: meta }
}

export const AffiliateTicketsToBatchSearchParamsModel = (filterParams = {}) => {
  const {
    startDate: initial_date,
    endDate: final_date,
    affiliateCode: affiliate_code
  } = filterParams

  return {
    initial_date: initial_date && moment(initial_date).format('YYYY-MM-DD'),
    final_date: final_date && moment(final_date).format('YYYY-MM-DD'),
    affiliate_code
  }
}

export const AffiliateBatchModel = ({ batch }) => {
  const {
    beneficiaryId: beneficiary_id,
    observation,
    originBankAccountId: origin_bank_account_id,
    paymentMethod: payment_method,
    referenceNumber: reference_number,
    paymentDate: payment_date,
    expectedPaymentDate: expected_payment_date,
    ticketsToBatch: items_ids
  } = batch

  const parsedValues = {
    beneficiary_id,
    observation,
    origin_bank_account_id,
    payment_date: payment_date ? moment(payment_date).format('YYYY-MM-DD') : undefined,
    expected_payment_date: expected_payment_date ? moment(expected_payment_date).format('YYYY-MM-DD') : undefined,
    payment_method,
    reference_number,
    items_ids
  }

  return removeEmptyAttribute(parsedValues)
}

export const PaymentBatchesSearchParamsModel = (filterParams = {}) => {
  const {
    batchType = 'Account::Affiliate',
    beneficiaryId = '',
    expectedDate = '',
    status = '',
    referenceNumber = '',
    startDate: initial_date,
    endDate: final_date
  } = filterParams

  return {
    batch_type: batchType,
    beneficiary_id: beneficiaryId,
    expected_date: expectedDate,
    status,
    reference_number: referenceNumber,
    initial_expected_payment_date: initial_date && moment(initial_date).format('YYYY-MM-DD'),
    end_expected_payment_date: final_date && moment(final_date).format('YYYY-MM-DD')
  }
}

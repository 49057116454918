/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { BillIcon, RedeemCodeIcon, Label, HeaderPage, TicketsIcon, CashierIcon, CashIcon, RefoundIcon, TicketIcon } from '@bonitour/components'
import { useHistory } from 'react-router-dom'
import { CardButton } from 'components/CardButton'
import { labelCardButton, clickable, smallCardIcon, cardButton } from './CompanyReports.style'

const container = css`
  display: grid;
  margin: 0 0 25px 0;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 10px;
`

export const CompanyReports = () => {
  const { push } = useHistory()
  const backToDashboard = () => push('/app')
  const redirectOnClick = (link) => () => {
    push(link)
  }

  return (
    <>
      <HeaderPage onBack={backToDashboard} title='Relatórios' />

      <div css={container}>
        <CardButton onClick={redirectOnClick('/reports/sales')} css={[clickable, cardButton]}>
          <BillIcon css={smallCardIcon} />
          <Label css={labelCardButton}>Vendas</Label>
        </CardButton>

        <CardButton onClick={redirectOnClick('/reports/vouchers')} css={[clickable, cardButton]}>
          <RedeemCodeIcon css={smallCardIcon} />
          <Label css={labelCardButton}>Vouchers</Label>
        </CardButton>

        <CardButton onClick={redirectOnClick('/reports/pending-tickets')} css={[clickable, cardButton]}>
          <TicketsIcon css={smallCardIcon} />
          <Label css={labelCardButton}>Ingressos Pendentes</Label>
        </CardButton>

        <CardButton onClick={redirectOnClick('/reports/revenue')} css={[clickable, cardButton]}>
          <CashierIcon css={smallCardIcon} />
          <Label css={labelCardButton}>Receita</Label>
        </CardButton>

        <CardButton onClick={redirectOnClick('/reports/tourist-payments')} css={[clickable, cardButton]}>
          <CashIcon css={smallCardIcon} />
          <Label css={labelCardButton}>Pagamentos dos turistas</Label>
        </CardButton>

        <CardButton onClick={redirectOnClick('/reports/tourist-refunds')} css={[clickable, cardButton]}>
          <RefoundIcon css={smallCardIcon} />
          <Label css={labelCardButton}>Reembolsos dos turistas</Label>
        </CardButton>

        <CardButton onClick={redirectOnClick('/reports/reservations-visualized')} css={[clickable, cardButton]}>
          <TicketIcon css={smallCardIcon} />
          <Label css={labelCardButton}>Check-in pendente</Label>
        </CardButton>
      </div>
    </>
  )
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Card, InputFormGroup, colors, Button, DatePicker, useToast, Column, Row } from '@bonitour/components'
import { marginBottom, width } from 'assets/styles/global'
import { useEffect, useState } from 'react'
import { head, identity, tail } from '@bonitour/common-functions'
import { useForm } from '@bonitour/app-functions'
import { NoticesFilterSchema } from './NoticesFilter.schema'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'hooks/useQuery'
import moment from 'moment'
import { useKeyPress } from 'hooks/useKeyPress'

const marginBottom30 = marginBottom(30)
const width100 = width(100)

const card = css`
  padding: 20px;
  background-color: ${colors.gray8};
`

const button = css`
  height: 40px;
  width: 100%;
  margin-top: 20px;
`

const NoticesFilter = ({
  onFilterSubmit: emitFilterSubmit = identity
}) => {
  const history = useHistory()
  const { add: addToast } = useToast()
  const [params] = useQuery()

  const {
    sentInitialDate: sentInitialDateParam,
    sentFinalDate: sentFinalDateParam,
    serviceInitialDate: serviceInitialDateParam,
    serviceFinalDate: serviceFinalDateParam
  } = params

  const [initialFilter] = useState({
    sentInitialDate: sentInitialDateParam && moment(sentInitialDateParam, 'YYYY-MM-DD'),
    sentFinalDate: sentFinalDateParam && moment(sentFinalDateParam, 'YYYY-MM-DD'),
    serviceInitialDate: serviceInitialDateParam && moment(serviceInitialDateParam, 'YYYY-MM-DD'),
    serviceFinalDate: serviceFinalDateParam && moment(serviceFinalDateParam, 'YYYY-MM-DD')
  })

  const { form, errors, onSubmit, utils: { onInputChange } } = useForm(initialFilter, NoticesFilterSchema)
  const { onKeyDown } = useKeyPress()

  const pushQueryParams = () => {
    const queryParams = Object.entries(form).reduce((acc, item) => {
      const key = head(item)
      const value = tail(item)

      if (!value) {
        return acc
      }

      if (key === 'sentInitialDate' || key === 'sentFinalDate' || key === 'serviceInitialDate' || key === 'serviceFinalDate') {
        return acc.concat(`${key}=${moment(value).format('YYYY-MM-DD')}&`)
      }

      return acc.concat(`${key}=${tail(item)}&`)
    }, '')

    history.push({ search: `?${queryParams.slice(0, -1)}` })
  }

  const onError = () => addToast('Preencha o formulário corretamente')

  const emitSubmit = () => {
    pushQueryParams()
    onSubmit(emitFilterSubmit(form), onError)
  }

  const onClick = onSubmit(emitSubmit, onError)

  useEffect(() => {
    if (Object.keys(params).length > 0) {
      onSubmit(emitFilterSubmit(initialFilter), onError)
    }
    // eslint-disable-next-line
  }, [])

  const { sentInitialDate, sentFinalDate, serviceInitialDate, serviceFinalDate } = form

  return (
    <Card css={[card, marginBottom30]}>
      <Row>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Data de envio inicial' errorMessage={errors.sentInitialDate}>
            <DatePicker
              css={width100}
              id='sentInitialDate'
              value={sentInitialDate}
              onChange={onInputChange('sentInitialDate')}
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Data de envio final' errorMessage={errors.sentFinalDate}>
            <DatePicker
              css={width100}
              id='sentFinalDate'
              value={sentFinalDate}
              onChange={onInputChange('sentFinalDate')}
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Data da experiência inicial' errorMessage={errors.serviceInitialDate}>
            <DatePicker
              css={width100}
              id='serviceInitialDate'
              value={serviceInitialDate}
              onChange={onInputChange('serviceInitialDate')}
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Data da experiência final' errorMessage={errors.serviceFinalDate}>
            <DatePicker
              css={width100}
              id='serviceFinalDate'
              value={serviceFinalDate}
              onChange={onInputChange('serviceFinalDate')}
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <Button css={button} onClick={onClick}>Pesquisar</Button>
        </Column>
      </Row>
    </Card>
  )
}

export default NoticesFilter

export const CREATE_VOUCHER = 'createVoucher'
export const CREATE_VOUCHER_TURIS = 'createVoucherTuris'
export const CANCEL_TICKET = 'cancelVoucher'
export const CANCEL_TICKET_TURIS = 'cancelVoucherTuris'
export const DELETE_TICKET = 'deleteTicket'
export const CONFIRM_RESERVATION = 'confirmReservation'

export const dialogStates = {
  [CREATE_VOUCHER]: {
    title: 'Geração de voucher',
    message: 'Você está prestes a gerar um voucher para este ingresso, e essa ação pode levar alguns segundos. Caso tenha alguma dúvida ou problema, entre em contato com suporte@binamik.com.br',
    buttonLabelConfirm: 'Gerar voucher',
    buttonLabelCancel: 'Voltar'
  },
  [DELETE_TICKET]: {
    title: 'Cancelar ingresso',
    message: 'Você deseja cancelar o ingresso desta reserva?',
    buttonLabelConfirm: 'Cancelar',
    buttonLabelCancel: 'Voltar'
  },
  [CANCEL_TICKET]: {
    title: 'Cancelar Voucher',
    message: 'Você deseja cancelar o voucher deste ticket?',
    buttonLabelConfirm: 'Cancelar',
    buttonLabelCancel: 'Voltar'
  }
}

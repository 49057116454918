/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { Card, Column, Row, colors } from '@bonitour/components'
import { css, jsx } from '@emotion/core'
import { useParams } from 'react-router-dom'
import { formatMoney } from '@bonitour/common-functions'
import { usePaymentBatchList } from '../hooks/usePaymentBatchList'
import { invoiceBatchTypeMap } from '../constants/InvoiceBatchType'

const cardContrast = css`
  margin-bottom: 8px;
  padding: 10px;
  background-color: ${colors.gray15};
  border: 1px solid ${colors.gray10};
`

const totalizersBox = css`
  > div + div {
    border-left: 1px solid ${colors.gray6};
  }
  `

const totalizerItem = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  border-radius: 4px;
  padding: 10px 20px;

  .accent_clr {
    color: ${colors.primary};
  }

  b {
    font-size: 12px;
    font-weight: 600;
    color: ${colors.gray3};
    margin-bottom: 10px;
  }

  span {
    font-size: 18px;
    font-weight: 700;
    color: ${colors.gray2};
  }
`

const getCurrencyValue = (value) => {
  return value ? formatMoney(value) : ''
}

export const InvoiceBatchTotalizers = () => {
  const { type } = useParams()

  const { totalizers } = usePaymentBatchList(invoiceBatchTypeMap[type])

  return (
    <Card css={cardContrast}>
      <Row customCss={[totalizersBox]}>
        <Column phone={12} desktop={4}>
          <div css={totalizerItem}>
            <b>Número de lotes:</b>
            <span className='accent_clr'>
              {totalizers.count}
            </span>
          </div>
        </Column>

        <Column phone={12} desktop={4}>
          <div css={totalizerItem}>
            <b>Valor total:</b>
            <span>{getCurrencyValue(totalizers.totalValue)}</span>
          </div>
        </Column>

        <Column phone={12} desktop={4}>
          <div css={totalizerItem}>
            <b>Total liquido:</b>
            <span>{getCurrencyValue(totalizers.totalLiquidValue)}</span>
          </div>
        </Column>
      </Row>
    </Card>
  )
}

import moment from 'moment'

/* eslint-disable camelcase */
export const ExceptionalDayModel = {
  single: (exceptionalDay = {}) => {
    const {
      name,
      serviceId: service_id,
      exceptionalDate,
      dailyLimit,
      registries
    } = exceptionalDay

    const vacanciesBySchedule = registries.reduce((acc, { time, vacanciesLimit }) => ({ ...acc, [time]: vacanciesLimit }), {})

    return {
      name,
      service_id,
      daily_limit: Number(dailyLimit),
      exceptional_date: moment(exceptionalDate).format('YYYY-MM-DD'),
      vacancies_by_schedule: vacanciesBySchedule
    }
  },
  list: ({ params = {} }) => {
    const {
      serviceId: service_id,
      exceptionalDate: exceptional_date,
      exceptionalDateStart: exceptional_date_start,
      exceptionalDateEnd: exceptional_date_end
    } = params

    return {
      service_id,
      exceptional_date,
      exceptional_date_start,
      exceptional_date_end
    }
  }
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Card, InputFormGroup, colors, Button, Select, useToast, Row, Column, DatePicker } from '@bonitour/components'
import { useForm } from '@bonitour/app-functions'
import { CashierStatus, CashierTypes } from '../constants/cashier'
import { useKeyPress } from 'hooks/useKeyPress'
import { cashierFiltersSchema } from './CashierFilters.schema'
import { useCallback, useMemo } from 'react'

const button = css`
  height: 40px;
  width: 100%;
  margin-top: 20px;
`
const cardContrast = css`
  margin-bottom: 20px;
  padding: 15px;
  background-color: ${colors.gray13};
  border: none;
`

const filterStatusOptions = [
  { value: '', label: 'Todos' },
  { value: CashierStatus.open, label: 'Somente abertos' },
  { value: CashierStatus.closed, label: 'Somente fechados' }
]

const filterTypeOptions = [
  { value: '', label: 'Todos' },
  { value: CashierTypes.operator, label: 'Somente operadores' },
  { value: CashierTypes.supervisor, label: 'Somente supervisores' }
]

export const CashierFilters = ({
  loading,
  setFilters,
  employeesOptions,
  loadingEmployees,
  initialFilters
}) => {
  const { add: addToast } = useToast()
  const { onKeyDown } = useKeyPress()

  const {
    form,
    errors,
    onSubmit,
    utils: { onInputChange, onInputBlur }
  } = useForm(initialFilters, cashierFiltersSchema)

  const onError = useCallback(() => addToast('Preencha o formulário corretamente'), [addToast])
  const onClick = useMemo(() => onSubmit(() => setFilters(form), onError), [onSubmit, onError, setFilters, form])

  const { cashierType, status, user, initialDate, finalDate } = form

  return (
    <Card css={cardContrast}>
      <Row>
        <Column phone={12} desktop={4}>
          <InputFormGroup label='Data inicial' errorMessage={errors.initialDate}>
            <DatePicker
              id='initialDate'
              value={initialDate}
              onChange={onInputChange('initialDate')}
              onBlur={onInputBlur('initialDate')}
              disabled={loading}
              fullWidth
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={4}>
          <InputFormGroup label='Data final' errorMessage={errors.finalDate}>
            <DatePicker
              id='finalDate'
              value={finalDate}
              onChange={onInputChange('finalDate')}
              onBlur={onInputBlur('finalDate')}
              disabled={loading}
              fullWidth
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>

        <Column phone={12} desktop={4}>
          <InputFormGroup label='Usuário' errorMessage={errors.user}>
            <Select
              placeholder='Todos'
              options={employeesOptions}
              value={user}
              error={errors.user}
              onChange={onInputChange('user')}
              onBlur={onInputBlur('user')}
              disabled={loading || loadingEmployees}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={4}>
          <InputFormGroup label='Status do caixa' errorMessage={errors.status}>
            <Select
              placeholder='Todos'
              options={filterStatusOptions}
              value={status}
              error={errors.status}
              onChange={onInputChange('status')}
              onBlur={onInputBlur('status')}
              disabled={loading}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={4}>
          <InputFormGroup label='Tipo do usuário' errorMessage={errors.cashierType}>
            <Select
              placeholder='Todos'
              options={filterTypeOptions}
              value={cashierType}
              error={errors.cashierType}
              onChange={onInputChange('cashierType')}
              onBlur={onInputBlur('cashierType')}
              disabled={loading}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={4}>
          <Button css={button} onClick={() => onClick()}>Pesquisar</Button>
        </Column>
      </Row>
    </Card>
  )
}

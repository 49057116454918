import { css } from '@emotion/core'
import { colors } from '@bonitour/components'

export const tableCss = css`
  .delete__icon {
    cursor: pointer;
    font-size: 1.5rem;
    padding-left: 0.1rem;
    color: ${colors.red1};
  }
  [class*='TableRowWrapper'] {
    padding: 0;
  }
`

export const centralizeLoading = css`
  margin: 0 auto;
`

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { EmptyResult, Row } from '@bonitour/components'
import { FlexGrid } from 'components/FlexGrid'
import { GroupCard } from './Card'
import { margin } from 'assets/styles/global'

const cardMargin = margin(10, 10, 10, 10)

const EmptyResultComponent = () => (
  <EmptyResult
    css={cardMargin}
    size={258}
    title='Não há grupos cadastrados'
    subtitle='Cadastre um grupo comercial no botão no canto direito da tela'
  />
)

export const GroupsList = ({ groups = [], onClick: emitClickGroupCard }) => {
  if (groups.length === 0) {
    return (
      <FlexGrid empty>
        <EmptyResultComponent />
      </FlexGrid>
    )
  }
  return (
    <Row>
      {groups.map(({ id, tradeAgreement: { name = '' } }) => (
        <GroupCard key={id} name={name} onClick={emitClickGroupCard(id)} />
      ))}
    </Row>
  )
}

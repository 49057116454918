/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { TableContainer, TableBody, TableHeader, TableHeaderRow, TableHeaderCell, TableBodyRow, TableBodyCell, flexRow, CircleThumbnail, TruncateCell } from '@bonitour/components'
import { saleStatusMap } from './../constants/saleStatus'
import { formatMoney } from '@bonitour/common-functions'
import { clickable } from 'assets/styles/global'

export const salesTable = css`
  margin-top: 30px;
`

const SalesTableRow = ({ sale = [] }) => {
  const { saleDate = '', sellerName = '', companyName = '', activityName = '', traveler = '', tourDate = '', status = '', ticketValue = 0, reservationId, userName } = sale
  const [codeName, codeColor] = saleStatusMap[status] || saleStatusMap.open
  const redirectToReservation = () => reservationId && window.open(`/reservation/${reservationId}`, '_blank', 'noopener noreferrer')

  return (
    <TableBodyRow css={clickable} onClick={redirectToReservation}>
      <TableBodyCell>{saleDate}</TableBodyCell>
      <TruncateCell text={sellerName} size='115px' />
      <TruncateCell text={companyName} size='115px' />
      <TruncateCell text={activityName} size='115px' />
      <TruncateCell text={traveler} size='115px' />
      <TableBodyCell>{tourDate}</TableBodyCell>
      <TableBodyCell css={flexRow}>
        <CircleThumbnail size={10} style={{ backgroundColor: codeColor, marginRight: 10, marginTop: 3 }} />
        {codeName}
      </TableBodyCell>
      <TableBodyCell>{formatMoney(ticketValue)}</TableBodyCell>
      <TruncateCell text={userName || '-'} size='115px' />
    </TableBodyRow>
  )
}

export const SalesTable = ({ sales = [], loading }) => {
  return (
    <TableContainer css={salesTable}>
      <TableHeader>
        <TableHeaderRow>
          <TableHeaderCell>Data<br />da venda</TableHeaderCell>
          <TableHeaderCell>Vendedor</TableHeaderCell>
          <TableHeaderCell>Fornecedor</TableHeaderCell>
          <TableHeaderCell>Experiência</TableHeaderCell>
          <TableHeaderCell>Viajante</TableHeaderCell>
          <TableHeaderCell>Data da<br />experiência</TableHeaderCell>
          <TableHeaderCell>Status</TableHeaderCell>
          <TableHeaderCell>Valor<br />do ticket</TableHeaderCell>
          <TableHeaderCell>Agente<br />responsável</TableHeaderCell>
        </TableHeaderRow>
      </TableHeader>
      <TableBody loading={loading}>
        {
          sales.map((sale, index) => {
            return <SalesTableRow key={index} sale={sale} />
          })
        }
      </TableBody>
    </TableContainer>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Card, H2, Row, H3, colors, Column } from '@bonitour/components'
import { marginLeft, marginTop } from 'assets/styles/global'

const marginTop50 = marginTop(50)
const marginLeft10 = marginLeft(10)

const resultContainer = css`
  width: 100%;
  min-width: 215px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 20px;
`

const resultValue = css`
  color: ${colors.primary};
  font-size: 24px;
  font-weight: bold;
`

const ExcerptCard = ({ title, value }) => {
  return (
    <Column phone={12} tabletlandscape={3} desktop={3}>
      <Card css={resultContainer}>
        <H3>{title}</H3>
        <span css={resultValue}>{value}</span>
      </Card>
    </Column>
  )
}

export const PendingTicketsExcerpt = ({ totalPendingTickets }) => {
  return (
    <div css={marginTop50}>
      <H2 css={marginLeft10}>Resumo</H2>
      <Row>
        <ExcerptCard title='Ingressos Pendentes' value={totalPendingTickets} />
      </Row>
    </div>
  )
}

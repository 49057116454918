import { formatISOTime } from 'utils/time'
import { TransactionParser } from '../Transaction'
import { getImageUrl } from 'services/Orb/Service'
import { ReservationTicketParser } from './ReservationTicket'

export const ReservationAdvanceFinanceParser = {
  list ({
    transactions = [],
    total_amount: total,
    total_amount_by_company: totalAmountByCompany = [],
    total_amount_due: due,
    total_amount_due_by_company: totalAmountDueByCompany = [],
    total_amount_paid: paid,
    total_amount_paid_by_company: totalAmountPaidByCompany = [],
    total_cancellation_balance: credit
  }) {
    const totalByCompany = detail => detail.map(({ company_name: companyName, total }) => ({ companyName, total }))

    return {
      transactions: transactions.map(TransactionParser.listItem),
      amount: {
        total: Number(total),
        totalAmountByCompany: totalByCompany(totalAmountByCompany),
        due: Number(due),
        totalAmountDueByCompany: totalByCompany(totalAmountDueByCompany),
        paid: Number(paid),
        totalAmountPaidByCompany: totalByCompany(totalAmountPaidByCompany),
        credit: Number(credit)
      }
    }
  },
  listItem (transaction) {
    const {
      receipt = '',
      acquirer_name: acquirerName,
      aut,
      doc,
      id,
      installments = '',
      payment_date: isoDate,
      payment_method: method,
      state,
      value,
      destination_company_id: companyDestinationId,
      tickets,
      brand,
      destination_bank_account_id: destinationBankAccount,
      origin_bank_account_id: originBankAccount,
      payer_company_id: payerCompanyId
    } = transaction?.advance_reservation_payment || transaction?.reservation_payment || {}

    const date = formatISOTime(isoDate, 'YYYY-MM-DD')
    const hour = formatISOTime(isoDate, 'HH:mm')

    return {
      upload: (receipt && [{ id: receipt, src: getImageUrl(receipt) }]) || [],
      id,
      value,
      method,
      installments,
      aut,
      doc,
      state,
      hour,
      date,
      companyDestinationId,
      companyDestinationName: tickets[0].company_name,
      acquirerName,
      receipt: getImageUrl(receipt),
      tickets: (tickets || []).map(ReservationTicketParser.advanceTicket),
      brand,
      destinationBankAccount,
      originBankAccount,
      payerCompanyId
    }
  }
}

/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import { useCallback, useMemo } from 'react'
import { Card, Column, H4, Input, InputFormGroup, InputMoneyMask, Label, Row, Select } from '@bonitour/components'
import { width100, cardTaxesStyle, feeInput, feeLabel, feeValue } from './BankAccountForm.styles'
import { accountTypeOptions, ACCOUNT_TYPES, paymentMethodFees } from 'domains/BankAccounts/constants/bankAccountConstants'
import { hidden, marginBottom, marginTop } from 'assets/styles/global'
import { banks } from 'utils/banks'
import { apiPaymentMethods } from 'constants/paymentMethods'
import { formatMoney } from '@bonitour/common-functions'

const marginBottom60 = marginBottom(60)
const marginTop10 = marginTop(10)

export const BankAccountForm = ({ form, errors, onInputBlur, onInputChange }) => {
  const { accountName = '', bankCode, bankName, agencyNumber, agencyDigit, accountNumber, accountDigit, accountType = 'checking_account', fees = [] } = form

  const paymentMethodLabel = paymentMethod => {
    const { label = '' } = paymentMethodFees.find(item => item.value === paymentMethod) || {}
    return label
  }

  const isCompanyCashier = useMemo(() => form.accountType === ACCOUNT_TYPES.COMPANY_CASHIER, [form.accountType])

  const showValue = useCallback((value, isNumeric = false, canFormatNumber = true) => {
    if (!isCompanyCashier) return value
    if (isCompanyCashier && isNumeric) {
      if (canFormatNumber) return formatMoney(0)
      return 0
    }
    return ''
  }, [isCompanyCashier])

  const onBankCodeChange = useCallback((value) => {
    const { label } = banks.find(bank => Number(bank.value) === Number(value)) || {}
    onInputChange('bankName')(label || '')
    onInputChange('bankCode')(value)
  }, [onInputChange])

  return (
    <Card>
      <H4>Dados bancários</H4>
      <Row css={marginBottom60}>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Identificador da conta' errorMessage={errors.accountName}>
            <Input
              id='account-name'
              placeholder='Digite um nome para a conta'
              value={accountName}
              onChange={onInputChange('accountName')}
              onBlur={onInputBlur('accountName')}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={2} desktop={2}>
          <InputFormGroup label='Código do banco' errorMessage={errors.bankCode}>
            <Input
              id='bank-code'
              placeholder='Digite o código do banco'
              value={showValue(bankCode, true, false)}
              onChange={onBankCodeChange}
              onBlur={onInputBlur('bankCode')}
              disabled={isCompanyCashier}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Nome do banco' errorMessage={errors.bankName}>
            <Input
              id='bank-name'
              placeholder='Nome do banco'
              value={showValue(bankName)}
              onChange={onInputChange('bankName')}
              onBlur={onInputBlur('bankName')}
              disabled
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={2} desktop={2}>
          <InputFormGroup label='Agência' errorMessage={errors.agencyNumber}>
            <Input
              id='agency-number'
              placeholder='Digite o número da agência'
              value={showValue(agencyNumber, true, false)}
              onChange={onInputChange('agencyNumber')}
              onBlur={onInputBlur('agencyNumber')}
              disabled={isCompanyCashier}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={2} desktop={2}>
          <InputFormGroup label='Dígito da agência' errorMessage={errors.agencyDigit}>
            <Input
              id='agency-digit'
              placeholder='Insira o dígito da agência'
              value={showValue(agencyDigit, true, false)}
              onChange={onInputChange('agencyDigit')}
              onBlur={onInputBlur('agencyDigit')}
              disabled={isCompanyCashier}
            />
          </InputFormGroup>
        </Column>
        <Column css={marginTop10} phone={12} tabletlandscape={2} desktop={2}>
          <InputFormGroup label='Conta' errorMessage={errors.accountNumber}>
            <Input
              id='account-number'
              placeholder='Digite o número da conta'
              value={showValue(accountNumber, true, false)}
              onChange={onInputChange('accountNumber')}
              onBlur={onInputBlur('accountNumber')}
              disabled={isCompanyCashier}
            />
          </InputFormGroup>
        </Column>
        <Column css={marginTop10} phone={12} tabletlandscape={2} desktop={2}>
          <InputFormGroup label='Dígito da conta' errorMessage={errors.accountDigit}>
            <Input
              id='verifying-digit'
              placeholder='Insira o dígito verificador'
              value={showValue(accountDigit, true, false)}
              onChange={onInputChange('accountDigit')}
              onBlur={onInputBlur('accountDigit')}
              disabled={isCompanyCashier}
            />
          </InputFormGroup>
        </Column>
        <Column css={marginTop10} phone={12} tabletlandscape={2} desktop={3}>
          <InputFormGroup label='Tipo de conta' errorMessage={errors.accountType}>
            <Select
              id='accountType'
              placeholder='Selecione o tipo de conta'
              options={accountTypeOptions}
              value={accountType}
              onChange={onInputChange('accountType')}
            />
          </InputFormGroup>
        </Column>
      </Row>

      <H4>Taxas bancárias</H4>
      <Card css={cardTaxesStyle}>
        {fees.map(({ paymentMethod, value }, index) => (
          <Row key={paymentMethod} css={(paymentMethod !== apiPaymentMethods.cash && isCompanyCashier) && hidden}>
            <Column phone={12} desktop={6} tabletup={6}>
              <div css={feeInput}>
                <Label css={feeLabel}>Forma de pagamento</Label>
                <Input css={width100} value={paymentMethodLabel(paymentMethod)} disabled />
              </div>
            </Column>
            <Column phone={12} desktop={6} tabletup={6}>
              <div css={feeInput}>
                <Label css={feeLabel}>Taxa bancária</Label>
                <InputMoneyMask
                  value={value}
                  css={feeValue}
                  placeholder='Digite o valor da taxa bancária'
                  onChange={onInputChange(`fees[${index}].value`)}
                />
              </div>
            </Column>
          </Row>
        ))}
      </Card>
    </Card>
  )
}

import { orbCore } from 'services/Orb/Service'
import { PassengerParser } from 'core/parsers/passenger'
import { PassengerModel } from 'core/models/passenger'

const { passengerDomain } = orbCore

export const PassengerService = {
  list (filters) {
    return passengerDomain.list(...PassengerModel.list({ filters })).then(PassengerParser.list)
  },
  getById (passengerId) {
    return passengerDomain.get(passengerId).then(PassengerParser.single)
  },
  createPassenger (passenger) {
    return passengerDomain.create(PassengerModel.single(passenger))
  },
  editPassenger (passenger, passengerId) {
    return passengerDomain.update(PassengerModel.single(passenger), passengerId)
  }
}

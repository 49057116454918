/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { colors, flexHorizontallyCentered, LinkIcon } from '@bonitour/components'
import { jsx, css } from '@emotion/core'
import { Link } from 'react-router-dom'

const popoverItem = css`
  color: ${colors.gray4};
  padding: 15px 20px;
  font-size: 16px;
  border-radius: 10px;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  cursor: pointer;

  &:hover {
    background-color: ${colors.gray13};
  }
`

const iconStyle = css`
  margin-right: 10px;
  font-size: 23px;
`

export const BookingLinkageGuide = (activityId = '', code = '') => function BookingLinkageGuideComponent ({ hour = '', date = '' }) {
  return (
    <Link to={`/activity/${activityId}/bookings/link/${code}?hour=${hour}&date=${date}`} css={[flexHorizontallyCentered, popoverItem]}>
      <LinkIcon css={iconStyle} />
      Vincular
    </Link>
  )
}

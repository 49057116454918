import { orbCore } from 'services/Orb/Service'
import { CashierModel } from '../models/cashier'
import { CashierParser } from '../parsers/cashier'

const { cashierDomain } = orbCore

export const CashierService = {
  list ({ filters, pagination }) {
    return cashierDomain.list(CashierModel.list({ filters, pagination })).then(CashierParser.list)
  },
  getById (id) {
    return cashierDomain.get(id).then(CashierParser.getOne)
  },
  getMyCashier () {
    return cashierDomain.get('my_cashier').then(CashierParser.getOne)
  },
  listEmployees () {
    return cashierDomain.listEmployees().then(CashierParser.listEmployees)
  },
  closeCashier (payload) {
    return cashierDomain.closeCashier(CashierModel.closeCashier(payload)).then(CashierParser.getOne)
  },
  reopenCashier (payload) {
    return cashierDomain.reopenCashier(CashierModel.closeCashier(payload)).then(CashierParser.getOne)
  }
}

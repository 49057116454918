/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import {
  Row,
  Input,
  InputFormGroup,
  Column
} from '@bonitour/components'
import { AgentInput, CountInputFormGroup, NetPriceInputFormGroup, ObservationRowInput, PriceInputFormGroup, SlotInputFormGroup, UnitNetPriceInputFormGroup, UnitTotalPriceInputFormGroup } from './CommonFormFields'
import { identity } from '@bonitour/common-functions'
import { rowStyle } from '../OfflineExperiencesTicketForm.style'

export const AirTransportForm = ({
  isEditing = false,
  form,
  errors,
  onInputChange = identity,
  onInputBlur = identity,
  disablePricesEditing = false,
  disableTotalPrice = false,
  disabled = false,
  totalPriceDefault
}) => {
  return (
    <>
      {!isEditing && !disabled
        ? <AgentInput errors={errors} form={form} onInputChange={onInputChange} onInputBlur={onInputBlur} />
        : null}

      <Row css={rowStyle}>
        <SlotInputFormGroup
          form={form}
          errors={errors}
          onInputChange={onInputChange}
          onInputBlur={onInputBlur}
          desktop={3}
          phone={12}
          disabled={disabled}
        />
        <Column desktop={3} phone={12}>
          <InputFormGroup
            errorMessage={errors.airCompanyName}
            label='Cia Aérea'
            className='text__input'
          >
            <Input
              value={form.airCompanyName}
              onChange={onInputChange('airCompanyName')}
              onBlur={onInputBlur('airCompanyName')}
              placeholder='Nome da Cia Aérea'
              disabled={disabled}
            />
          </InputFormGroup>
        </Column>
        <Column desktop={3} phone={12}>
          <InputFormGroup
            errorMessage={errors.externalReservationCode}
            label='Código externo'
            className='text__input'
          >
            <Input
              value={form.externalReservationCode}
              onChange={onInputChange('externalReservationCode')}
              onBlur={onInputBlur('externalReservationCode')}
              placeholder='Digite o código externo'
              disabled={disabled}
            />
          </InputFormGroup>
        </Column>
      </Row>
      <Row css={rowStyle}>
        {!isEditing && !disabled && (
          <>
            <Column desktop={4} phone={12}>
              <UnitTotalPriceInputFormGroup
                form={form}
                onChange={onInputChange}
                errors={errors}
              />
            </Column>
            <Column desktop={4} phone={12}>
              <UnitNetPriceInputFormGroup
                form={form}
                onChange={onInputChange}
                errors={errors}
              />
            </Column>
          </>
        )}
        {!isEditing && (
          <Column desktop={4} phone={12}>
            <CountInputFormGroup
              form={form}
              errors={errors}
              onInputChange={onInputChange}
              onInputBlur={onInputBlur}
              disabled={disabled}
              totalPriceDefault={totalPriceDefault}
            />
          </Column>
        )}
        <Column desktop={4} phone={12}>
          <PriceInputFormGroup
            disabled={disablePricesEditing || disableTotalPrice || disabled}
            errors={errors}
            form={form}
            onInputChange={onInputChange}
            onInputBlur={onInputBlur}
            isEditing={isEditing}
            isEnableUnitPrice={isEditing}
          />
        </Column>
        <Column desktop={4} phone={12}>
          <NetPriceInputFormGroup
            disabled={disablePricesEditing || disabled}
            errors={errors}
            form={form}
            onInputChange={onInputChange}
            onInputBlur={onInputBlur}
            isEditing={isEditing}
            isEnableUnitPrice={isEditing}
          />
        </Column>
      </Row>
      <ObservationRowInput
        form={form}
        errors={errors}
        onInputChange={onInputChange}
        onInputBlur={onInputBlur}
        disabled={disabled}
      />
    </>
  )
}

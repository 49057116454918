import { formatDate, unmaskPhone } from '@bonitour/common-functions'
import { getArchiveUrl } from 'core/services/Orb'
import { MetaParser } from './Meta'
import moment from 'moment'

export const BatchParser = {
  single: (batch) => {
    const {
      id,
      reference_number: reference,
      guide_name: guideName,
      payment_date: paymentDate,
      payment_value: value,
      status
    } = batch

    return {
      id,
      reference,
      guideName,
      paymentDate: paymentDate ? formatDate(paymentDate) : '',
      value,
      status
    }
  },
  singleBatchPayment: ({ guide_payment_batch: batch }) => {
    const {
      id,
      reference_number: reference,
      expected_payment_date: expectedPaymentDate,
      guide_name: name,
      guide_document_number: document,
      guide_email: email,
      guide_phone: guidePhone,
      guide_address: address,
      payment_method: method,
      observation,
      receipts,
      status,
      tickets,
      tax_value,
      origin_bank_account_id,
      payment_date: paymentDate
    } = batch

    const phone = unmaskPhone(guidePhone || '')
    const phoneNumberWithDDI = phone?.length <= 10 ? `55${phone}` : phone

    const parsedPaymentDate = (function () {
      if (paymentDate) return moment(paymentDate.split('T')[0])
      return moment()
    }())

    return {
      id,
      reference,
      expectedPaymentDate: expectedPaymentDate.slice(0, 10),
      name,
      documentType: document.length === 11 ? 'Cpf' : 'Cnpj',
      document,
      email,
      phone: phoneNumberWithDDI,
      address,
      method: method || '',
      observation,
      status,
      attachments: (receipts || []).map(({ url }, index) => ({ id: index, src: getArchiveUrl(url), name: `Anexo-${index + 1}` })),
      vouchers: tickets.map(BatchParser.singleBatchPaymentVoucher),
      taxValue: tax_value,
      originBankAccountId: origin_bank_account_id,
      paymentDate: parsedPaymentDate
    }
  },
  singleBatchPaymentVoucher: (ticket) => {
    const {
      id: ticketId,
      company_name: partnerName,
      service_title: activityName,
      external_integrations: externalIntegrations,
      slot: activityDate,
      description
    } = ticket

    const { external_ticket_id: externalCode } = externalIntegrations.find(
      ({ integration_name: name }) => {
        const isVoucherUnico = name === 'Voucher Unico'
        const isVoucherTuris = name === 'Voucher Turis'

        return isVoucherUnico || isVoucherTuris
      }
    ) || { external_ticket_id: '' }

    return {
      ticketId,
      externalCode: externalCode || '',
      activityDate,
      activityName: activityName || '',
      partnerName: partnerName || '',
      value: Number(description?.fee?.taxes?.guide)
    }
  },
  singleVoucher: (ticket) => {
    const {
      id,
      external_ticket_id: externalCode,
      ticket_id: ticketId,
      activity_date: activityDate,
      activity_name: activityName,
      company_name: partnerName,
      guide_payment_batch_status: status,
      guide_fee: voucherValue,
      guide_payment_batch_reference_number: batch,
      tax_value
    } = ticket

    return {
      id,
      ticketId,
      externalCode,
      activityDate,
      activityName,
      partnerName,
      status,
      value: Number(voucherValue),
      batch,
      taxValue: (tax_value ?? 0)
    }
  },
  list: ({ meta, guide_payment_batches: batches = [] }) => {
    return {
      batches: batches.map(BatchParser.single),
      meta: MetaParser.meta(meta)
    }
  },
  listVouchers: ({ meta, integration_tickets: vouchers = [] }) => {
    return {
      vouchers: vouchers.map(BatchParser.singleVoucher),
      vouchersNotFound: meta?.extra_data?.external_codes_not_found,
      meta: MetaParser.meta(meta?.pagination)
    }
  }
}

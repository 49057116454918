import { css } from '@emotion/core'
import { scrollBar } from '@bonitour/components'
import { marginBottom, marginRight, marginTop } from 'assets/styles/global'

export const marginTop50 = marginTop(50)
export const marginRight20 = marginRight(20)
export const marginBottom20 = marginBottom(20)
export const marginTop30 = marginTop(30)

export const inputGroup = css`
  align-items: flex-start;
  width: 100%;
  ${marginRight(20)};
`

export const highlightLabel = css`
  font-weight: 700;
`

export const groupContainer = css`
  max-height: 145px;
  overflow-y: auto;
  ${scrollBar(5)}
`

export const alignLeft = css`
  text-align: left;
  line-height: 1.25;
`

export const radioStyle = css`
  min-width: 26px;
`

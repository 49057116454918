import { LocalitiesService } from './Service'
import { LocalityListParser } from './Parser'

function Api () {
  function list (countryId, stateId) {
    const stateListResource = `/v1/countries/${countryId}/states/${stateId}/cities`
    return LocalitiesService.get(stateListResource).then(({ data }) => LocalityListParser(data))
  }

  return { list }
}

export const CityApi = Api()

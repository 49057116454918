// Split array in chunks
export const chunkArray = (array = [], chunkLength = 2) => {
  const chunkCount = Math.ceil(array.length / chunkLength)
  return [...Array(chunkCount).keys()]
    .map((i) => array.slice(i * chunkLength, i * chunkLength + chunkLength))
}

export const uniqBy = (arr, predicate) => {
  const cb = typeof predicate === 'function' ? predicate : (o) => o[predicate]

  return [...arr.reduce((map, item) => {
    const key = (item === null || item === undefined)
      ? item
      : cb(item)

    map.has(key) || map.set(key, item)

    return map
  }, new Map()).values()]
}

import { formatMoney } from '@bonitour/common-functions'

const formattedTime = (date) => {
  const minutes = date.getMinutes()

  const minutesFormatted = minutes < 10 ? `0${minutes}` : minutes

  return `${date.getHours()}:${minutesFormatted}`
}

const commonFormBase = (ticketData = {}) => {
  const { service_id: serviceId, slot } = ticketData
  const { price, net_price: netPrice, observation } = ticketData?.description

  const checkinDate = new Date(slot.substring(0, slot.indexOf('Z')))

  return { serviceId, checkinDate, checkinTime: formattedTime(checkinDate), price: formatMoney(price), netPrice: formatMoney(netPrice), observation }
}

const accommodationFormBase = (ticketData = {}) => {
  const { checkout_date: checkoutSlot, accommodation_type: accommodationType, external_reservation_code: externalReservationCode } = ticketData?.description

  const checkoutDateISOString = new Date(checkoutSlot).toISOString()
  const checkoutDate = new Date(checkoutDateISOString.substring(-1, checkoutDateISOString.indexOf('Z')))

  return { ...commonFormBase(ticketData), checkoutDate, checkoutTime: formattedTime(checkoutDate), accommodationType, externalReservationCode }
}

const airTransportFormBase = (ticketData = {}) => {
  const { air_company_name: airCompanyName, external_reservation_code: externalReservationCode } = ticketData?.description
  return { ...commonFormBase(ticketData), airCompanyName, externalReservationCode }
}

const carRentalFormBase = (ticketData = {}) => {
  const { company_name: companyName, driver_document: driverDocument, driver_license: driverLicense, driver_license_due_date: driverLicenseDueDate, driver_address: driverAddress, driver_name: driverName, pickup_address: pickupAddress, pickup_hour: pickupHour, dropoff_address: dropoffAddress, dropoff_date: dropoffDate, dropoff_hour: dropoffHour, external_reservation_code: externalReservationCode, transporte_type: transporteType, state } = ticketData?.description

  return { ...commonFormBase(ticketData), checkinTime: pickupHour, checkoutDate: dropoffDate, checkoutTime: dropoffHour, companyName, driverDocument, driverLicense, driverLicenseDueDate, driverAddress, driverName, externalReservationCode, pickupAddress, dropoffAddress, transporteType, state }
}

const transportFormBase = (ticketData = {}) => {
  const {
    pickup_address: pickupAddress,
    pickup_hour: pickupHour,
    dropoff_address: dropoffAddress,
    dropoff_hour: checkoutTime,
    external_reservation_code: externalReservationCode
  } = ticketData?.description
  return {
    ...commonFormBase(ticketData),
    pickupAddress,
    checkinTime: pickupHour,
    dropoffAddress,
    checkoutTime,
    externalReservationCode
  }
}

const activityFormBase = (ticketData = {}) => {
  const { fee_type: feeType, external_reservation_code: externalReservationCode } = ticketData?.description
  return { ...commonFormBase(ticketData), feeType, externalReservationCode }
}

const gastronomyFormBase = (ticketData = {}) => {
  const { external_reservation_code: externalReservationCode } = ticketData?.description
  return { ...commonFormBase(ticketData), externalReservationCode }
}

const equipmentRentalFormBase = (ticketData = {}) => {
  const { tourist_name: touristName, company_name: companyName, activity_name: activityName, document, pickup_address: pickupAddress, state, external_reservation_code: externalReservationCode } = ticketData?.description
  return { ...commonFormBase(ticketData), touristName, pickupAddress, companyName, activityName, document, state, externalReservationCode }
}

export const handleOfflineTicketFormBase = ({ category = '', ticketData = {} }) => {
  switch (category) {
  case 'accommodation':
    return accommodationFormBase(ticketData)
  case 'air_transport':
    return airTransportFormBase(ticketData)
  case 'car_rental':
    return carRentalFormBase(ticketData)
  case 'transport':
    return transportFormBase(ticketData)
  case 'activity':
    return activityFormBase(ticketData)
  case 'equipment_rental':
    return equipmentRentalFormBase(ticketData)
  case 'gastronomy':
    return gastronomyFormBase(ticketData)
  default:
    return commonFormBase(ticketData)
  }
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import {
  Button,
  Card,
  Column,
  DatePicker,
  GhostButton,
  HeaderPage,
  Input,
  InputFormGroup,
  Row,
  Select,
  Textarea,
  useToast
} from '@bonitour/components'
import { useCallback, useEffect, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { invoiceBatchTypeMap, invoiceBatchTypeTitleMap } from '../constants/InvoiceBatchType'
import { useForm } from '@bonitour/app-functions'
import { useCreatePaymentBatch } from '../hooks/useCreatePaymentBatch'
import { buttonsContainerCss, dateInputCss, observationInputCss, sendingStateCss } from './CreateBatch.styles'
import { invoiceBatchSchema } from '../formSchema/InvoiceBatchForm.schema'

const emptyForm = {}

export const InvoicesBatchCreate = () => {
  const { type } = useParams()
  const { push } = useHistory()
  const {
    afiliateOptions,
    externalProvidersOptions,
    loading,
    onCreatePaymentBatch
  } = useCreatePaymentBatch()
  const backToList = useCallback(() => push(`/invoices-batch/${type}`), [push, type])
  const goEditingPage = useCallback((id) => push(`/invoices-batch/${type}/${id}`), [push, type])
  const { add: addToast } = useToast()

  const beneficiaryOptions = useMemo(
    () => type === 'affiliate' ? afiliateOptions : externalProvidersOptions,
    [afiliateOptions, externalProvidersOptions, type]
  )

  useEffect(() => {
    if (!Object.keys(invoiceBatchTypeMap).includes(type)) {
      backToList()
    }
  }, [backToList, type])

  const {
    form,
    errors: formErrors,
    onSubmit,
    utils: { onInputBlur, onInputChange }
  } = useForm(emptyForm, invoiceBatchSchema)

  const handleSubmit = useCallback(() => {
    onCreatePaymentBatch({
      beneficiaryId: form.beneficiaryId,
      beneficiaryType: type,
      observation: form.note,
      referenceNumber: form.referenceNumber,
      expectedPaymentDate: form.date,
      discount: form.discount,
      tickets: form.tickets
    }).then((paymentBatch) => {
      goEditingPage(paymentBatch.id)
      addToast('Lote criado com sucesso', 'success')
    })
  }, [onCreatePaymentBatch, form.beneficiaryId, form.note, form.referenceNumber, form.date, form.discount, form.tickets, type, addToast, goEditingPage])

  const submitFn = onSubmit(
    handleSubmit,
    () => addToast('Preencha os dados corretamente')
  )

  return (
    <>
      <HeaderPage onBack={backToList} title="Nova fatura em lote" subtitle={invoiceBatchTypeTitleMap[type]}/>
      <Card>
        <div>
          <form>
            <Row>

              <Column phone={12} desktop={4}>
                <InputFormGroup
                  label='Número de referência do lote'
                  errorMessage={formErrors.referenceNumber}
                >
                  <Input
                    value={form.referenceNumber}
                    onChange={onInputChange('referenceNumber')}
                    onBlur={onInputBlur('referenceNumber')}
                  />
                </InputFormGroup>
              </Column>

              <Column phone={12} desktop={4}>
                <InputFormGroup
                  label='Data esperada para Pagamento'
                  errorMessage={formErrors.date}
                  css={dateInputCss}
                >
                  <DatePicker
                    value={form.date}
                    css={dateInputCss}
                    onChange={onInputChange('date')}
                    onBlur={onInputBlur('date')}
                    allowsEmpty
                  />
                </InputFormGroup>
              </Column>

              <Column phone={12} desktop={4}>
                <InputFormGroup
                  label={invoiceBatchTypeTitleMap[type]}
                  errorMessage={formErrors.beneficiaryId}
                  id='beneficiary-select'
                >
                  <Select
                    placeholder={`Selecione um ${invoiceBatchTypeTitleMap[type].toLowerCase()}`}
                    options={beneficiaryOptions}
                    value={form.beneficiaryId}
                    onChange={onInputChange('beneficiaryId')}
                  />
                </InputFormGroup>
              </Column>

              <Column phone={12} desktop={12}>
                <InputFormGroup
                  label='Observação'
                  errorMessage={formErrors.note}
                >
                  <Textarea
                    value={form.note}
                    css={observationInputCss}
                    onChange={onInputChange('note')}
                    onBlur={onInputBlur('note')}
                  />
                </InputFormGroup>
              </Column>

            </Row>
          </form>
        </div>

        <Row css={buttonsContainerCss}>
          <GhostButton onClick={backToList}>Cancelar</GhostButton>
          <Button
            onClick={() => submitFn()}
            customCss={loading ? [sendingStateCss] : []}
          >
            Criar lote
          </Button>
        </Row>
      </Card>
    </>
  )
}

import { ReservationAdvanceFinanceModel, ReservationAdvanceRefundModel } from 'core/models/Reservation/ReservationAdvanceFinance'
import { ReservationAdvanceFinanceParser } from 'core/parsers/Reservation/ReservationAdvanceFinance'
import { orbCore } from 'services/Orb/Service'
import { convertToFormData } from '@bonitour/common-functions'

const { reservationDomain, reservationRefundDomain, providerRefundDomain } = orbCore

export const ReservationAdvanceTransaction = {
  getAdvanceTransactions (reservationId) {
    return reservationDomain.getAdvanceTransactions(reservationId).then(ReservationAdvanceFinanceParser.list)
  },
  getAdvanceTransactionsById (reservationId, paymentId) {
    return reservationDomain.getAdvanceTransactionsById(reservationId, paymentId).then(ReservationAdvanceFinanceParser.listItem)
  },
  createPayment (reservationId, payment) {
    const payload = ReservationAdvanceFinanceModel(payment)
    return reservationDomain.createPayment(reservationId, convertToFormData(payload))
  },
  createPartnership (reservationId, payment) {
    const payload = ReservationAdvanceRefundModel(payment)
    return reservationRefundDomain.createPartnership(convertToFormData(payload), reservationId)
  },
  approvePaymentTransaction (reservationId, paymentId, destinationBankAccount = null) {
    return reservationDomain.approvePaymentTransaction(reservationId, paymentId, {
      destination_bank_account_id: destinationBankAccount || undefined
    })
  },
  approveProviderRefundTransaction (reservationId, paymentId) {
    return providerRefundDomain.approveRefundTransaction(reservationId, paymentId)
  },
  rejectPaymentTransaction (reservationId, paymentId) {
    return reservationDomain.rejectPaymentTransaction(reservationId, paymentId)
  },
  rejectProviderRefundTransaction (reservationId, paymentId) {
    return providerRefundDomain.rejectRefundTransaction(reservationId, paymentId)
  }
}

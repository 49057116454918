/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Column, DocumentInput, Email, H4, Input, InputFormGroup, PhoneSelector, Row } from '@bonitour/components'
import { width } from 'assets/styles/global'
import { identity } from '@bonitour/common-functions'

const fullInput = width(100)

export const InfoGuideForm = ({
  guide = {},
  errors,
  disabled = false,
  onInputBlur = identity,
  onInputChange = identity
}) => {
  const { name, documentType, document, email, phone, address = '' } = guide

  return (
    <>
      <H4>Informações do guia responsável</H4>
      <Row>
        <Column tabletlandscape={4} tabletup={12} desktop={4}>
          <InputFormGroup label='Nome do guia' errorMessage={errors.name}>
            <Input
              value={name}
              css={fullInput}
              onChange={onInputChange('name')}
              onBlur={onInputBlur('name')}
              disabled={disabled}
            />
          </InputFormGroup>
        </Column>

        <Column tabletlandscape={4} tabletup={12} desktop={4}>
          <InputFormGroup label='Documento' errorMessage={errors.document}>
            <DocumentInput
              id='document'
              documentType={documentType}
              documentValue={document}
              onDocumentChange={onInputChange('documentType')}
              onDocumentBlur={onInputBlur('documentType')}
              onDocumentValueChange={onInputChange('document')}
              onDocumentValueBlur={onInputBlur('document')}
              disabled={disabled}
            />
          </InputFormGroup>
        </Column>

        <Column tabletlandscape={4} tabletup={12} desktop={4}>
          <InputFormGroup label='E-mail' errorMessage={errors.email}>
            <Email
              name='email'
              value={email}
              css={fullInput}
              onChange={onInputChange('email')}
              onBlur={onInputBlur('email')}
              disabled={disabled}
            />
          </InputFormGroup>
        </Column>
      </Row>

      <Row>
        <Column tabletlandscape={4} tabletup={12} desktop={4}>
          <InputFormGroup label='Telefone' errorMessage={errors.phone}>
            <PhoneSelector
              customCss={[fullInput]}
              value={phone}
              onChange={onInputChange('phone')}
              onBlur={onInputBlur('phone')}
              disabled={disabled}
            />
          </InputFormGroup>
        </Column>

        <Column tabletlandscape={4} tabletup={12} desktop={4}>
          <InputFormGroup label='Endereço' errorMessage={errors.address}>
            <Input
              value={address}
              css={fullInput}
              onChange={onInputChange('address')}
              onBlur={onInputBlur('address')}
              disabled={disabled}
            />
          </InputFormGroup>
        </Column>
      </Row>
    </>
  )
}

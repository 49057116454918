import { orbCore } from 'services/Orb/Service'
import { transportModel, transportUpdateModel, transportAboutModel, transportComplementaryInfoModel } from './Model'
import { TransportParser } from './Parser'
import { CompanyStore } from 'core/store/Company'
import { convertToFormData } from '@bonitour/common-functions'

const { activityDomain, transportDomain } = orbCore

export const TransportService = {
  list (enabled = false) {
    return activityDomain.sellableList(enabled).then(TransportParser.list)
  },
  create (data) {
    const companyId = CompanyStore.getId()
    const requestPayload = transportModel(data, companyId)
    return transportDomain.create(convertToFormData(requestPayload))
      .then(({ transport = {} }) => transport)
  },
  get (serviceId = '') {
    return transportDomain.get(serviceId)
      .then(TransportParser.instance)
  },
  getRestrictions (serviceId = '') {
    return transportDomain.get(serviceId)
      .then(TransportParser.restrictions)
  },
  updateMainTransport (transportId, data = {}) {
    const allowNull = true
    const requestPayload = transportUpdateModel(data)
    return transportDomain.updateAsFormData(convertToFormData(requestPayload, allowNull), transportId)
  },
  updateAbout (data = {}, transportId = '') {
    const requestPayload = transportAboutModel(data)
    return transportDomain.update(requestPayload, transportId)
  },
  updateTransportComplementaryInfo (data = {}, transportId = '') {
    const requestPayload = transportComplementaryInfoModel(data)
    return transportDomain.update(requestPayload, transportId)
  }
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import {
  H3,
  Label,
  Input,
  InputZipCodeMask,
  Select,
  flexColumn,
  flexCenteredSpaceBetweenRow,
  InputFormGroup,
  Row,
  Column,
  flexHorizontallyCentered,
  colors,
  Button
} from '@bonitour/components'
import { marginTop, marginBottom, required } from 'assets/styles/global'
import { identity } from '@bonitour/common-functions'

const marginTop50 = marginTop(50)
const marginBottom20 = marginBottom(20)

const width130 = css`
  width: 130px;
`

const latlngButton = css`
  height: 20px;
  padding: 5px 10px;
`

const buttonStyle = css`
  ${flexHorizontallyCentered}  
  margin-top: 28px;
  padding: 7px 10px;
  background-color: ${colors.primaryVariant};
  font-size: 14px;
  font-weight: 400;
  color: ${colors.white};
  border-radius: 5px;
  cursor: pointer;
`

const addressHeading = css`
  margin-bottom: 0;
  align-self: center;
`

const addressCopyButton = css`
  background-color: ${colors.primaryVariant};
  border-radius: 5px;
  @media screen and (min-width:769px){
    margin-left: auto;
  }
`

const getLocalityName = (options, key) =>
  options.filter((item) => item.value === key).reduce((acc, item) => acc + item.label, '')

export const Address = ({
  addressInfo = {},
  addressInfoErrors = {},
  onInputBlur: emitInputBlur = identity,
  onInputChange: emitChangeEvent = identity,
  onCountryChange: emitCountryChangeEvent = identity,
  onStateChange: emitStateChangeEvent = identity,
  onZipCodeBlur: emitZipCodeBlur = identity,
  onLatLongBlur: emitLatLongBlur = identity,
  onAddresCopyClick: emitAddresCopyClickEvent = identity,
  countries = [],
  states = [],
  cities = [],
  title = 'Endereço',
  hideCompanyAddressButton = false,
  providerAddressButton = false
}) => {
  const {
    zipCode = '',
    street = '',
    supplement = '',
    district = '',
    country = '',
    state = '',
    city = '',
    latLong = ''
  } = addressInfo ?? {}

  const getLatLng = () => {
    const countryName = getLocalityName(countries, country)
    const stateName = getLocalityName(states, state)
    const cityName = getLocalityName(cities, city)
    const addressParam = `${zipCode} ${street} ${supplement} ${district} ${countryName} ${stateName} ${cityName}`
    emitLatLongBlur(addressParam)
  }
  return (
    <>
      <Row css={[marginTop50, marginBottom20]}>
        <Column phone={12} desktop={6}>
          <H3 css={addressHeading}>
            {title}
            <span css={required}>(Obrigatório)</span>
          </H3>
        </Column>
        {!hideCompanyAddressButton && (
          <Column phone={12} desktop={6}>
            <Button onClick={emitAddresCopyClickEvent} css={[addressCopyButton, buttonStyle]}>
              Copiar endereço {providerAddressButton ? 'do fornecedor' : 'da sua empresa'}
            </Button>
          </Column>
        )}
      </Row>
      <Row css={[flexCenteredSpaceBetweenRow, marginBottom20]}>
        <Column phone={12} desktop={2}>
          <div css={[flexColumn, width130]}>
            <Label htmlFor='addressInfo.zipCode'>CEP</Label>
            <InputZipCodeMask
              id='addressInfo.zipCode'
              value={zipCode}
              error={addressInfoErrors.zipCode}
              onChange={emitChangeEvent('zipCode')}
              onBlur={emitZipCodeBlur}
            />
          </div>
        </Column>
        <Column phone={12} desktop={4}>
          <div css={[flexColumn]}>
            <Label htmlFor='addressInfo.street'>Endereço</Label>
            <Input
              id='addressInfo.street'
              value={street}
              error={addressInfoErrors.street}
              onChange={emitChangeEvent('street')}
              onBlur={emitInputBlur('street')}
            />
          </div>
        </Column>
        <Column phone={12} desktop={2}>
          <div css={[flexColumn, width130]}>
            <Label htmlFor='addressInfo.supplement'>Número</Label>
            <Input
              id='addressInfo.supplement'
              value={supplement}
              error={addressInfoErrors.supplement}
              onChange={emitChangeEvent('supplement')}
              onBlur={emitInputBlur('supplement')}
            />
          </div>
        </Column>
        <Column phone={12} desktop={4}>
          <div css={[flexColumn]}>
            <Label htmlFor='addressInfo.district'>Bairro</Label>
            <Input
              id='addressInfo.district'
              value={district}
              error={addressInfoErrors.district}
              onChange={emitChangeEvent('district')}
              onBlur={emitInputBlur('district')}
            />
          </div>
        </Column>
      </Row>

      <Row css={[flexCenteredSpaceBetweenRow, marginBottom20]}>
        <Column desktop={4}>
          <div css={flexColumn}>
            <Label>País</Label>
            <Select
              placeholder='Selecione um país'
              options={countries}
              value={country}
              error={addressInfoErrors.country}
              onChange={emitCountryChangeEvent}
              onBlur={emitInputBlur('country')}
            />
          </div>
        </Column>
        <Column desktop={4}>
          <div css={flexColumn}>
            <Label>Estado</Label>
            <Select
              value={state}
              placeholder='Selecione um estado'
              options={states}
              error={addressInfoErrors.state}
              onChange={emitStateChangeEvent}
              onBlur={emitInputBlur('state')}
            />
          </div>
        </Column>
        <Column desktop={4}>
          <div css={flexColumn}>
            <Label>Cidade</Label>
            <Select
              value={city}
              placeholder='Selecione uma cidade'
              options={cities}
              error={addressInfoErrors.city}
              onChange={emitChangeEvent('city')}
              onBlur={emitInputBlur('city')}
            />
          </div>
        </Column>
      </Row>
      <Row>
        <Column desktop={4}>
          <InputFormGroup
            label='Latitude e Longitude'
            errorMessage={addressInfoErrors.latLong}
            id='addressInfo.latLong'
          >
            <Input
              value={latLong}
              onChange={emitChangeEvent('latLong')}
              onBlur={emitInputBlur('latLong')}
              placeholder='Latitude , Longitude'
            />
          </InputFormGroup>
        </Column>
        <Column desktop={4}>
          <div css={[buttonStyle, latlngButton]} onClick={getLatLng}>Obter coordenadas</div>
        </Column>
      </Row>
    </>
  )
}

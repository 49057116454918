import moment from 'moment'
import { metaModel } from './meta'

/* eslint-disable camelcase */
export const VoucherReportModel = {
  list: ({ filters = {}, pagination = {} }) => {
    const {
      voucherStartDate: start_date,
      voucherEndDate: end_date,
      externalCode: external_ticket_id,
      paxType: pax_name,
      status: external_status,
      partner: partner_id,
      activity: service_id
    } = filters

    const meta = metaModel(pagination)

    const searchParams = {
      start_date: start_date ? moment(start_date).format('YYYY-MM-DD') : undefined,
      end_date: end_date ? moment(end_date).format('YYYY-MM-DD') : undefined,
      external_ticket_id,
      pax_name,
      external_status,
      partner_id,
      service_id
    }

    return { searchParams, pagination: meta }
  }
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { identity } from '@bonitour/common-functions'
import { ConfirmDialog } from '@bonitour/components'
import { jsx } from '@emotion/core'

export const Dialog = ({ dialogData = {}, isVisible = false }) => {
  const {
    successCallback = identity,
    cancelCallback = identity,
    title = '',
    message = '',
    buttonLabelConfirm = '',
    buttonLabelCancel = ''
  } = dialogData

  return (
    <ConfirmDialog
      title={title}
      message={message}
      isVisible={isVisible}
      successCallback={successCallback}
      cancelCallback={cancelCallback}
      buttonLabelConfirm={buttonLabelConfirm}
      buttonLabelCancel={buttonLabelCancel}
    />
  )
}

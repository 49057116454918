import { head, tail } from '@bonitour/common-functions'
import { useChangeableInterval } from 'hooks/useInterval'
import { useCallback, useState } from 'react'
import { ReservationMapService } from 'services/ReservationMap/Service'

const MAP_DETAIL_INTERVAL = 60000

export const useMapDetailSubscriber = (
  activity,
  dateColumns,
  hour
) => {
  const [detailedTableData, setDetailedTableData] = useState({})

  const updateTableData = useCallback(() => {
    const { id: serviceId, companyId } = activity
    if (hour && dateColumns.length) {
      ReservationMapService.getMapDetails({
        serviceId,
        companyId,
        startDate: head(dateColumns),
        endDate: tail(dateColumns),
        hour
      }).then(setDetailedTableData)
    } else {
      setDetailedTableData({})
    }
  }, [activity, dateColumns, hour])

  useChangeableInterval(updateTableData, MAP_DETAIL_INTERVAL, true)

  return detailedTableData
}

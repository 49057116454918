import React, { useMemo } from 'react'
import {
  PencilIcon,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TooltipOnHover
} from '@bonitour/components'
import { css } from '@emotion/core'
import { CashierTypes, CashierStatus } from '../constants/cashier'
import { Link } from 'react-router-dom'
import { formatMoney } from '@bonitour/common-functions'

const cashierStatusLabels = {
  [CashierStatus.open]: 'Aberto',
  [CashierStatus.closed]: 'Fechado'
}

const cashierTypeLabels = {
  [CashierTypes.operator]: 'Operador',
  [CashierTypes.supervisor]: 'Supervisor'
}

const iconButton = css`
  cursor: pointer;
  margin-right: 8px;
  font-size: 18px;
`

export const CashierTable = ({ cashiers, loading }) => {
  return (
    <TableContainer style={{ marginTop: 16 }}>
      <TableHeader>
        <TableHeaderRow>
          <TableHeaderCell>Data</TableHeaderCell>
          <TableHeaderCell>Usuário</TableHeaderCell>
          <TableHeaderCell>Valor total</TableHeaderCell>
          <TableHeaderCell>Fechado em</TableHeaderCell>
          <TableHeaderCell>Fechado por</TableHeaderCell>
          <TableHeaderCell>Tipo de caixa</TableHeaderCell>
          <TableHeaderCell>Status</TableHeaderCell>
          <TableHeaderCell fixed>Ações</TableHeaderCell>
        </TableHeaderRow>
      </TableHeader>
      <TableBody loading={loading}>
        {cashiers?.map((cashier) => (
          <CashierTableRow key={`cashier-${cashier.id}`} cashier={cashier} />
        ))}
      </TableBody>
    </TableContainer>
  )
}

const CashierTableRow = ({ cashier }) => {
  const {
    id,
    createdAt,
    operatorName,
    closedAt,
    supervisorName,
    cashierType,
    paymentMethodTotalizator,
    status
  } = cashier

  const totalAmount = useMemo(() => {
    return paymentMethodTotalizator?.reduce((sum, method) => sum + Number(method?.value), 0)
  }, [paymentMethodTotalizator])

  return (
    <TableBodyRow>
      <TableBodyCell>{createdAt}</TableBodyCell>
      <TableBodyCell>{operatorName}</TableBodyCell>
      <TableBodyCell>{totalAmount && formatMoney(totalAmount)}
      </TableBodyCell>
      <TableBodyCell>{closedAt}</TableBodyCell>
      <TableBodyCell>{supervisorName}</TableBodyCell>
      <TableBodyCell>{cashierTypeLabels[cashierType] ?? 'não mapeado'}</TableBodyCell>
      <TableBodyCell>{cashierStatusLabels[status] ?? 'não mapeado'}</TableBodyCell>
      <TableBodyCell fixed>
        <TooltipOnHover text='Detalhes'>
          <Link to={`/cashiers/${id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
            <PencilIcon css={iconButton} />
          </Link>
        </TooltipOnHover>
      </TableBodyCell>
    </TableBodyRow>
  )
}

import { useState } from 'react'
import { ExternalProviderService } from 'core/services/ExternalProvider'

export const useCreateExternalProvider = () => {
  const [loading, setLoading] = useState(false)

  const create = async (externalProvider) => {
    setLoading(true)
    return ExternalProviderService.create(externalProvider).finally(() => setLoading(false))
  }

  return {
    submit: create,
    loading
  }
}

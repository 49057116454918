/** @jsxRuntime classic */
/** @jsx jsx  */
import { jsx } from '@emotion/core'
import { useMemo } from 'react'
import { Card, Select, InputWithSuffix, InputMoneyMask, TrashIcon, flexHorizontallyCentered } from '@bonitour/components'
import { identity, unformatMoney } from '@bonitour/common-functions'
import { clickable } from 'assets/styles/global'
import {
  width170,
  marginTop20,
  relativeCard,
  inputMargin,
  modificatorSize,
  finalPriceSize,
  trashIcon,
  flexColumnCenter,
  marginTop10,
  vacancyFieldContainer
} from './FeeByDemandRow.style'

const feeByDemandOperatorOptions = [
  {
    label: 'acrescentar',
    value: 'increase'
  },
  {
    label: 'decrescer',
    value: 'decrease'
  }
]

const calcFinalValue = (feePrice, valueModificator, operator) => {
  const percentager = {
    increase: 0.01,
    decrease: -0.01
  }
  return feePrice * (1 + valueModificator * percentager[operator])
}

export const FeeByDemandRow = ({
  feePrice = 0,
  index = 0,
  errors = [],
  onBlur: emitBlurEvent = identity,
  onDelete: emitDeleteEvent = identity,
  onChange: emitChangeEvent = identity,
  feeByDemandItem
}) => {
  const { limit = 0, operator = 'increase', valueModificator = 0 } = feeByDemandItem
  const finalValue = useMemo(() => calcFinalValue(feePrice, valueModificator, operator), [feePrice, valueModificator, operator])

  const applyPercentageValueModificator = valueModificator => {
    if (valueModificator < 0) {
      return emitChangeEvent(`feesByDemand.${index}.valueModificator`)(0)
    }
    emitChangeEvent(`feesByDemand.${index}.valueModificator`)(valueModificator)
  }

  const applyPercentageOperator = operator => {
    emitChangeEvent(`feesByDemand.${index}.operator`)(operator)
  }

  const applyFinalValue = value => {
    const unformattedValue = unformatMoney(value)
    if (!feePrice) {
      return
    }
    const percentageValue = ((unformattedValue * 100) / feePrice) - 100
    emitChangeEvent(`feesByDemand.${index}.valueModificator`)(Math.abs(percentageValue))
    emitChangeEvent(`feesByDemand.${index}.operator`)(percentageValue >= 0 ? 'increase' : 'decrease')
  }

  return (
    <Card css={[marginTop20, relativeCard]}>
      <div css={[flexHorizontallyCentered, flexColumnCenter]}>
        <div css={[vacancyFieldContainer]}>
          <span>Após</span>
          <InputWithSuffix
            css={[inputMargin, modificatorSize, marginTop10]}
            type='number'
            min='0'
            max='100'
            value={limit}
            error={errors.limit}
            onChange={emitChangeEvent(`feesByDemand.${index}.limit`)}
            onBlur={emitBlurEvent(`feesByDemand.${index}.limit`)}
          >
            <span>%</span>
          </InputWithSuffix>
        </div>
        <span css={marginTop10}>das vagas</span>
        <Select
          css={[width170, inputMargin, marginTop10]}
          options={feeByDemandOperatorOptions}
          value={operator}
          error={errors.operator}
          onChange={applyPercentageOperator}
        />
        <span css={marginTop10}>a porcentagem de</span>
        <InputWithSuffix
          type='number'
          min='0'
          css={[inputMargin, modificatorSize, marginTop10]}
          value={valueModificator}
          error={errors.valueModificator}
          onChange={applyPercentageValueModificator}
          onBlur={applyPercentageValueModificator}
        >
          <span>%</span>
        </InputWithSuffix>
        <span css={marginTop10}>com o valor final de</span>
        <InputMoneyMask
          value={finalValue}
          onChange={applyFinalValue}
          css={[inputMargin, finalPriceSize, marginTop10]}
        />
      </div>
      <TrashIcon css={[trashIcon, clickable]} onClick={emitDeleteEvent} />
    </Card>
  )
}

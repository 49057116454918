/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import {
  H3,
  H4,
  InputDurationMask,
  flexHorizontallyCentered,
  flexColumn,
  colors,
  ToggleInputGroup,
  InputDurationMaskWithSuffix,
  InfoSwatch,
  CheckboxInputGroup,
  Textarea,
  flexRow
} from '@bonitour/components'
import { marginTop, marginBottom, marginRight, marginLeft, required } from 'assets/styles/global'
import { identity, head } from '@bonitour/common-functions'
import { useCallback, useEffect, useState } from 'react'
import { chunkArray } from 'utils/array'

const marginTop50 = marginTop(50)
const marginBottom20 = marginBottom(20)
const marginBottom0 = marginBottom(0)
const marginRight8 = marginRight(8)

const infoSwatch = css`
  -webkit-text-fill-color: currentColor;
`

const container = css`
  color: ${colors.gray3};
`

const inputStyle = css`
  width: 110px;
  ${marginRight(10)};
  ${marginLeft(10)};
`

export const TransportAbout = ({
  transportAbout = {},
  infrastructureList: infrastructures,
  transportAboutErrors = {},
  onInputBlur: emitInputBlur = identity,
  onChange: emitChangeEvent = identity,
  categories = []
}) => {
  const {
    duration = '',
    workDuration = '',
    difficultyLevel = '',
    generalRules = '',
    category = head(categories) ? head(categories).id : '',
    infrastructure = [],
    travelerInfoRequired = true
  } = transportAbout

  const [isWorkDurationActive, setWorkDurationActive] = useState(false)

  const haveInfrastructure = (value) => infrastructure.includes(value)

  const handleInfrastructure = (value) => () => {
    if (haveInfrastructure(value)) {
      emitChangeEvent('transportAbout.infrastructure')(infrastructure.filter((item) => item !== value))
    } else {
      emitChangeEvent('transportAbout.infrastructure')([...infrastructure, value])
    }
  }

  const handleWorkDuration = () => setWorkDurationActive(!isWorkDurationActive)

  useEffect(() => {
    if (!isWorkDurationActive) {
      emitChangeEvent('transportAbout.workDuration')(duration)
    }
    // eslint-disable-next-line
  }, [isWorkDurationActive]);

  useEffect(() => {
    setWorkDurationActive(workDuration !== duration)
  }, [duration, workDuration])

  useEffect( // Force transport category
    () => {
      const transportCategoryId = categories.find((item) => item.title === 'Transporte')?.id
      if (transportCategoryId && category !== transportCategoryId) {
        emitChangeEvent('transportAbout.category')(transportCategoryId)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [categories, transportAbout, category]
  )

  useEffect( // Force uninformed difficulty level
    () => {
      if (difficultyLevel !== 'uninformed') {
        emitChangeEvent('transportAbout.difficultyLevel')('uninformed')
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [difficultyLevel, transportAbout]
  )

  const handleTravelerInfoRequired = useCallback(
    () => emitChangeEvent('transportAbout.travelerInfoRequired')(!travelerInfoRequired),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [travelerInfoRequired]
  )

  return (
    <div css={container}>
      <H3 css={marginBottom20}>Tempo de duração</H3>
      <H4>
        Para o turista
        <span css={required}>(Obrigatório)</span>
      </H4>
      <div css={[flexHorizontallyCentered, marginBottom20]}>
        <p>Será de</p>
        <InputDurationMask
          css={inputStyle}
          value={duration || ''}
          error={transportAboutErrors.duration}
          onChange={emitChangeEvent('transportAbout.duration')}
          onBlur={emitInputBlur('transportAbout.duration')}
        />
        <p>horas</p>
      </div>

      <div css={[flexHorizontallyCentered, marginBottom20]}>
        <ToggleInputGroup id='toggleWorkDurationActive' checked={isWorkDurationActive} onClick={handleWorkDuration} readOnly>
          <H4 css={marginBottom0}>Para o colaborador</H4>
        </ToggleInputGroup>
      </div>
      <div css={flexHorizontallyCentered}>
        <p>Será de</p>
        <InputDurationMaskWithSuffix
          css={inputStyle}
          value={workDuration}
          error={transportAboutErrors.workDuration}
          onChange={emitChangeEvent('transportAbout.workDuration')}
          onBlur={emitInputBlur('transportAbout.workDuration')}
          disabled={!isWorkDurationActive}
        >
          <span css={infoSwatch}>
            <InfoSwatch
              css={marginRight8}
              tooltip='O tempo de duração de um transporte pode ser ou não o mesmo para o turista e para o colaborador/motorista'
            />
          </span>
        </InputDurationMaskWithSuffix>
        <p>horas</p>
      </div>

      <div css={flexColumn}>
        <H3 css={[marginTop50, marginBottom20]}>
          Dados do viajante
        </H3>
        <div>
          <ToggleInputGroup
            id='travelerInfoRequired'
            onChange={handleTravelerInfoRequired}
            checked={travelerInfoRequired}
          >
            Tornar obrigatório o preenchimento dos dados do viajante para essa rota
          </ToggleInputGroup>
        </div>
      </div>

      <div css={flexColumn}>
        <H3 css={[marginTop50, marginBottom20]}>Infraestrutura</H3>
        <div css={flexRow}>
          {chunkArray([...infrastructures], 5).map((column, index) => (
            <div css={flexColumn} key={index}>
              {column.map(option => (
                <div key={option[0]}>
                  <CheckboxInputGroup
                    id={option[0]}
                    onChange={handleInfrastructure(option[0])}
                    checked={haveInfrastructure(option[0])}
                  >
                    {option[1]}
                  </CheckboxInputGroup>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>

      <div css={flexColumn}>
        <H3 css={[marginTop50, marginBottom20]}>Normas</H3>
        <Textarea onChange={emitChangeEvent('transportAbout.generalRules')} value={generalRules} />
      </div>
    </div>
  )
}

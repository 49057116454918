import { useCallback, useEffect, useMemo, useState } from 'react'
import { EmployeesService } from 'core/services/Employees'

export const useListEmployeesFromCompany = (companyId, options = {}) => {
  const [employees, setEmployees] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchdEmployees = useCallback((companyToFetch) => {
    setLoading(true)
    EmployeesService
      .listEmployeesbyCompany(companyToFetch)
      .then(res => setEmployees(res.employees))
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    if (!companyId) return
    fetchdEmployees(companyId)
  }, [companyId, fetchdEmployees])

  const filteredEmployees = useMemo(() => {
    if (options.showInactive) return employees
    return employees.filter(employee => employee.active)
  }, [employees, options.showInactive])

  return {
    employees: filteredEmployees,
    loading
  }
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { identity } from '@bonitour/common-functions'
import { EditInfoIcon, Popover, Reference, Manager, Button } from '@bonitour/components'

import { jsx } from '@emotion/core'
import { hidePopover, insertedCodeContainer, popoverStyle, voucherActionIconSize } from './VoucherInsert.style'
import { ExternalCodeAction } from '../ExternalCodeAction/ExternalCodeAction'
import { ProcessingContainer } from '../ProcessingContainer/ProcessingContainer'
import { useClickOutside } from '@bonitour/app-functions'

export const VoucherInsert = ({
  toggleInsertVoucherDialogVisible = identity,
  id = '',
  status = '',
  reference,
  isVoucherInserted = false,
  externalCode
}) => {
  const [isButtonVisible, setButtonVisibility] = useClickOutside(reference)
  const toggleVisibility = () => setButtonVisibility(isButtonVisible => !isButtonVisible)

  const dialogType = isVoucherInserted ? 'edit' : 'create'
  const dialogTitle = isVoucherInserted ? 'Editar código' : 'Inserir código'

  const onClick = () => {
    toggleInsertVoucherDialogVisible(dialogType)
    toggleVisibility()
  }

  return (
    <div ref={reference} css={insertedCodeContainer}>
      <Manager>
        <Reference>
          {({ ref }) => (
            isVoucherInserted
              ? (
                <span ref={ref}>
                  <ProcessingContainer status={status}>{externalCode}</ProcessingContainer>
                </span>

              )
              : (
                <div ref={ref}>
                  <ProcessingContainer status={status}>
                    <Button onClick={toggleVisibility}>Vincular código</Button>
                  </ProcessingContainer>
                </div>

              )
          )}
        </Reference>
        <Popover position='top' css={[popoverStyle, !isButtonVisible && hidePopover]}>
          <ExternalCodeAction handleFunction={onClick} ticketId={id} title={dialogTitle}>
            <EditInfoIcon css={voucherActionIconSize} />
          </ExternalCodeAction>
        </Popover>
      </Manager>
    </div>
  )
}

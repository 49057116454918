import { useDebounce } from '@bonitour/app-functions'
import { PaginatedSellersContext } from 'app/Sellers/SellersContext'
import React, { useContext, useEffect, useMemo, useState } from 'react'

import { search as searchCss, searchIcon } from './PartnershipForm.style'
import { InputWithSuffix, SearchIcon } from '@bonitour/components'

export const PartnershipSearchComponent = () => {
  const [search, setSearch] = useState('')
  const { handleSearch, toSearch } = useContext(PaginatedSellersContext)

  const debouncedSearch = useDebounce(search, 1500)

  const memoizedSearch = useMemo(() => toSearch, [toSearch])

  useEffect(() => {
    if (debouncedSearch === memoizedSearch) return
    handleSearch(debouncedSearch)
  }, [debouncedSearch, handleSearch, memoizedSearch])

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'end'
      }}
    >
      <InputWithSuffix type="text" css={searchCss} onChange={setSearch} value={search} placeholder="Buscar parceiros">
        <SearchIcon css={searchIcon} />
      </InputWithSuffix>
    </div>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { TableBodyRow, TableBodyCell, CircleThumbnail, Checkbox, flexHorizontallyCentered } from '@bonitour/components'
import { voucherStatusMap } from '../constants/voucherStatus'
import { formatDate, formatMoney, identity } from '@bonitour/common-functions'

export const VoucherTableRow = ({
  voucher = {},
  isVoucherSelect,
  isVoucherSelectable = true,
  onVoucherClick: emitVoucherClick = identity
}) => {
  const { ticketId, activityDate = '', externalCode = '', partnerName = '', activityName = '', status = '', batch = '', value = 0 } = voucher
  const [codeName, codeColor] = voucherStatusMap[status] || '-'

  return (
    <TableBodyRow>
      <TableBodyCell>
        {isVoucherSelectable(voucher) && <Checkbox checked={isVoucherSelect(ticketId)} onChange={emitVoucherClick(voucher)} />}
      </TableBodyCell>
      <TableBodyCell>{externalCode}</TableBodyCell>
      <TableBodyCell>{formatDate(activityDate)}</TableBodyCell>
      <TableBodyCell>{partnerName}</TableBodyCell>
      <TableBodyCell>{activityName}</TableBodyCell>
      <TableBodyCell>
        <div css={flexHorizontallyCentered}>
          <CircleThumbnail size={10} style={{ backgroundColor: codeColor, marginRight: 5 }} />
          {codeName}
        </div>
      </TableBodyCell>
      <TableBodyCell>{batch}</TableBodyCell>
      <TableBodyCell>{formatMoney(value)}</TableBodyCell>
    </TableBodyRow>
  )
}

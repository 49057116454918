/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useCallback, useEffect, useState } from 'react'
import { useToast } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { OfflineExperienceService } from 'services/OfflineExperience/Service'
import { OfflineExperienceAboutForm } from 'containers/OfflineExperienceAboutForm/OfflineExperienceAboutForm'
import { ActivityAdditionInformationService } from 'services/Activity/ActivityAdditionInformation/Service'

export const OfflineExperienceTechnicalInfo = ({
  serviceId = '',
  offlineExperienceAbout = {},
  onOfflineExperienceUpdated = identity,
  onBackStep = identity,
  onNextStep = identity
}) => {
  const [infrastructureList, setInfrastructureList] = useState([])
  const { add } = useToast()

  const onValidationError = useCallback(() => {
    add('Preencha corretamente o formulário')
  }, [add])

  const onSuccess = useCallback(async (offlineExperienceData) => {
    try {
      await OfflineExperienceService.updateAbout(serviceId, offlineExperienceData)
      add('Experiência offline atualizada', 'success')
      onNextStep()
      onOfflineExperienceUpdated()
    } catch (error) {
      add('Ocorreu um erro ao adicionar as informações')
    }
  }, [add, onNextStep, onOfflineExperienceUpdated, serviceId])

  useEffect(() => {
    ActivityAdditionInformationService.list({ type: 'activity' }).then(({ additionalInformation }) => additionalInformation && setInfrastructureList(additionalInformation))
  }, [])

  return (
    <OfflineExperienceAboutForm
      serviceAbout={offlineExperienceAbout}
      infrastructureList={infrastructureList}
      onBackClick={onBackStep}
      onSuccess={onSuccess}
      onValidationError={onValidationError}
    />
  )
}

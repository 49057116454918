import React, { createContext, useContext, useMemo, useCallback, useState, useEffect } from 'react'
import { CompanyStore } from 'core/store/Company'
import { CompanyService } from 'core/services/Company'

export const CompanyContext = createContext({})

export const useCompany = () => useContext(CompanyContext)

export const CompanyProvider = ({ children }) => {
  const [company, setCompanyState] = useState(() => CompanyStore.getCompany())
  const [userCompanies, setUserCompanies] = useState([])

  useEffect(() => {
    if (company.id) {
      CompanyService.listUserCompanies(true)
        .then(setUserCompanies)
    }
  }, [company])

  const setCompany = useCallback(
    value => {
      setCompanyState(value)
      CompanyStore.setCompany(value)
    }, []
  )

  const contextData = useMemo(() => ({
    get id () {
      return company.id
    },
    company,
    setCompany,
    userCompanies
  }), [company, setCompany, userCompanies])

  return (
    <CompanyContext.Provider value={contextData}>
      {children}
    </CompanyContext.Provider>
  )
}

import { SafeDate } from '@binamik/js-providers'
import { string, date } from 'yup'

const FINAL_DATE_ERROR_MESSAGE = 'A data final deve ser maior ou igual a data inicial'
const INITIAL_DATE_ERROR_MESSAGE = 'A data inicial deve ser menor ou igual a data final'

const isDateRangeValid = (initialDate, finalDate) => {
  if (!initialDate && !finalDate) return true

  if (!initialDate && finalDate) return false

  if (initialDate && !finalDate) return false

  const initialSafeDate = new SafeDate(initialDate)

  return initialSafeDate.isBefore(finalDate) || initialSafeDate.isSame(finalDate)
}

export const touristRefundsFiltersSchema = {
  reservationCode: string().nullable(),
  refundMethod: string(),
  initialSlotDate: date().nullable().test('validateDateRange', INITIAL_DATE_ERROR_MESSAGE, function (value) {
    const { finalSlotDate } = this.parent

    if (value && !finalSlotDate) return true

    return isDateRangeValid(value, finalSlotDate)
  }),
  finalSlotDate: date().nullable().test('validateDateRange', FINAL_DATE_ERROR_MESSAGE, function (value) {
    const { initialSlotDate } = this.parent

    if (value && !initialSlotDate) return true

    return isDateRangeValid(initialSlotDate, value)
  }),
  initialRefundDate: date().nullable().test('validateDateRange', INITIAL_DATE_ERROR_MESSAGE, function (value) {
    const { finalRefundDate } = this.parent

    if (value && !finalRefundDate) return true

    return isDateRangeValid(value, finalRefundDate)
  }),
  finalRefundDate: date().nullable().test('validateDateRange', FINAL_DATE_ERROR_MESSAGE, function (value) {
    const { initialRefundDate } = this.parent

    if (value && !initialRefundDate) return true

    return isDateRangeValid(initialRefundDate, value)
  })
}

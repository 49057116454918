import { parseAddress } from './address'
import { MetaParser } from './Meta'

export const ExternalProviderParser = {
  single: ({ offline_provider: externalProvider = {} }) => {
    const { id, name, document } = externalProvider
    const addressInfo = parseAddress(externalProvider)

    return {
      id,
      name,
      addressInfo,
      document
    }
  },
  list: ({ meta, offline_providers: externalProviders = [] }) => {
    return {
      externalProviders: externalProviders.map(externalProvider => ExternalProviderParser.single({ offline_provider: externalProvider })),
      meta: MetaParser.meta(meta)
    }
  },
  options: ({ offline_providers: externalProviders = [] }) => {
    return externalProviders.map(({ id, name }) => ({ value: id, label: name }))
  }
}

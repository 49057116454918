import { MetaParser } from './Meta'
import { formatISOTime } from 'utils/time'
import { paymentTypesMap } from 'constants/reservationPaymentTypes'

export const PaidTicketsReportParser = {
  single: (paidTicket) => {
    const {
      reservation_id: reservationId,
      reservation_code: reservationCode,
      reservation_agent_name: reservationAgentName,
      payer_name: payerName,
      payment_date: paymentDate,
      payment_method: paymentMethod,
      custom_company_name: customCompanyName,
      acquirer_name: acquirerName,
      payment_value: paymentValue,
      aut,
      doc,
      payment_agent_name: paymentAgentName,
      installments,
      bank_name: bankName,
      start_date: startDate,
      end_date: endDate,
      brand
    } = paidTicket

    return {
      reservationId: reservationId || '-',
      reservationCode: reservationCode || '-',
      reservationAgentName: reservationAgentName || '-',
      payerName: payerName || '-',
      paymentDate: paymentDate ? formatISOTime(paymentDate, 'DD/MM/YY', false) : '-',
      paymentMethod: paymentMethod ? paymentTypesMap[paymentMethod].replace('Cartão de ', '') || paymentMethod : '-',
      customCompanyName,
      acquirerName,
      paymentValue,
      aut,
      doc,
      paymentAgentName: paymentAgentName || '-',
      installments,
      bankName,
      startDate: startDate ? formatISOTime(startDate, 'DD/MM/YY', false) : '-',
      endDate: endDate ? formatISOTime(endDate, 'DD/MM/YY', false) : '-',
      brand
    }
  },

  list: ({ meta, paid_tickets_reports: paidTickets = [] }) => {
    return {
      paidTickets: paidTickets.map(PaidTicketsReportParser.single),
      meta: MetaParser.meta(meta)
    }
  },

  totals: (totals = {}) => {
    const { payment_total_value: paymentTotalValue } = totals
    return {
      paymentTotalValue
    }
  }
}

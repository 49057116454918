import { getArchiveUrl } from 'core/services/Orb'
import { SafeDate } from '@binamik/js-providers'
import { formatISOTime } from 'utils/time'
import { MetaParser } from './Meta'

export const InvoiceParser = {
  attachment: ({ url, signed_id: signedId, id }) => ({ id, src: getArchiveUrl(url), signedId }),

  invoiceItem (invoice = {}) {
    const {
      invoice_code: invoiceCode,
      id: uuid,
      partner_name: partner,
      discharge_date: dischargedDate,
      invoice_total_value: value,
      status,
      start_competence: start,
      end_competence: end
    } = invoice

    const period = `${formatISOTime(start)} - ${formatISOTime(end)}`
    const createdAt = formatISOTime(end)
    const dueDate = dischargedDate ? formatISOTime(dischargedDate, 'DD/MM/YYYY', false) : '-'
    const document = (invoice.document_number || '-').toUpperCase()

    return {
      id: invoiceCode || '-',
      uuid,
      partner,
      period,
      createdAt,
      dueDate,
      document,
      value,
      status
    }
  },

  parseDebts (debts = {}) {
    const list = debts.invoices.map(InvoiceParser.invoiceItem)

    const meta = {
      totalEntries: debts.meta.total_entries || 0,
      currentPage: debts.meta.current_page || 1,
      totalPages: debts.meta.total_pages || 1,
      perPage: debts.meta.per_page || 10,
      paid: debts.invoices.filter(({ status }) => status === 'paid').length,
      pending: debts.invoices.filter(({ status }) => status !== 'paid').length
    }

    return { list, meta }
  },

  parseDate (date) {
    return date ? new SafeDate(date.split('T')[0]).value : new SafeDate().value
  },

  parseInvoice ({ invoice = {} }) {
    const generationDate = InvoiceParser.parseDate(invoice.start_competence)
    const generationHour = formatISOTime(generationDate, 'HH', false)
    const endDate = InvoiceParser.parseDate(invoice.end_competence)
    const endHour = formatISOTime(endDate, 'HH', false)
    const dueDate = InvoiceParser.parseDate(invoice.due_date)
    const totalValue = Number(invoice.invoice_total_value || 0)
    const discount = invoice.discount_percentage ? Number(invoice.discount_percentage) : null
    const taxPercentage = invoice.tax_percentage ? Number(invoice.tax_percentage) : null
    const payments = (invoice.invoice_payments || []).map(InvoiceParser.payment)

    const info = {
      id: invoice.id,
      code: invoice.invoice_code,
      vendorId: invoice.vendor_id,
      vendor: invoice.vendor_name,
      payerId: invoice.payer_id,
      partner: invoice.partner_name,
      periodRange: `${generationDate.format('DD/MM/YYYY')} - ${endDate.format('DD/MM/YYYY')}`,
      invoiceValueReceivedDescription: invoice.invoice_value_received_description,
      generationDate: generationDate.format('DD/MM/YYYY'),
      generationHour,
      endDate: endDate.format('DD/MM/YYYY'),
      endHour,
      status: invoice.status,
      dueDate,
      discountPercentage: discount && discount * 100,
      taxPercentage: taxPercentage && taxPercentage * 100,
      invoiceNumber: invoice.document_number
    }

    const details = {
      files: (invoice.receipts || []).map(({ url, signed_id: signedId, id }) => ({ id, src: getArchiveUrl(url), signedId })),
      note: invoice.observation || ''
    }

    const amount = {
      total: totalValue,
      tax: Number(invoice.tax_value || 0),
      discount: Number(invoice.discount_value || 0),
      received: Number(invoice.received_total_value || 0),
      paidValue: payments.reduce((acc, { value }) => acc + Number(value), 0),
      amountLeft: Number(invoice.amount_left || 0),
      credited: Number(invoice.invoice_value_received_description?.credited || 0)
    }

    return { amount, info, details, payments }
  },

  parseInvoiceDetails ({ invoice_items = [], meta = {} }) {
    const items = invoice_items.map((item = {}) => ({
      id: item.id,
      date: InvoiceParser.parseDate(item.date),
      serviceName: item.service_name || '',
      reservation: item.reservation_code,
      reservationId: item.reservation_id,
      clientName: item.client_name || '',
      value: item.item_total_value,
      receivedValue: item.item_received_value,
      externalCode: item.external_code,
      paymentMethod: item.item_received_value <= 0 ? null : item.payment_method,
      aut: item.aut,
      doc: item.doc,
      ticketCode: item.ticket_code,
      additionalValues: {
        sellerCreditValue: item.additional_values?.seller_credit_value,
        ticketAdditionalValue: item.additional_values?.ticket_additional_value
      }
    }))

    const invoiceMeta = {
      totalPages: meta.total_pages,
      totalEntries: meta.total_entries
    }

    return { items, meta: invoiceMeta }
  },

  payment ({
    id,
    payment_date: date,
    payment_method: method = '',
    payment_value: value,
    attachments = [],
    vendor_approval: vendorApproval,
    origin_bank_account_id: originBankAccount,
    destination_bank_account_id: destinationBankAccount,
    observation
  }) {
    return {
      id,
      date,
      type: method,
      value: Number(value),
      vendorApproval,
      attachments: (attachments || []).map(InvoiceParser.attachment),
      originBankAccount,
      destinationBankAccount,
      observation
    }
  },

  single: (invoice) => {
    const {
      id,
      due_date: dueDate,
      discharge_date: dischargeDate,
      document_number: documentNumber,
      end_competence: endCompetence,
      invoice_code: invoiceCode,
      invoice_total_value: invoiceTotalValue,
      partner_name: partnerName,
      start_competence: startCompetence,
      amount_left: amountLeft,
      status
    } = invoice

    return {
      id,
      dueDate: dueDate ? formatISOTime(dueDate, 'DD/MM/YYYY', false) : '-',
      documentNumber: documentNumber || '-',
      invoiceCode,
      invoiceTotalValue,
      partnerName: partnerName || '-',
      startCompetence: startCompetence ? formatISOTime(startCompetence, 'DD/MM/YYYY', false) : '-',
      endCompetence: endCompetence ? formatISOTime(endCompetence, 'DD/MM/YYYY', false) : '-',
      dischargeDate,
      amountLeft,
      status
    }
  },

  list: ({ meta, invoices = [] }) => {
    return {
      invoices: invoices ? invoices.map(InvoiceParser.single) : [],
      meta: MetaParser.meta(meta)
    }
  },

  totals: ({ invoice_totalizers: totalizers = {} }) => {
    const {
      total_invoice_due: totalInvoiceDue,
      total_invoice_value: totalInvoiceValue
    } = totalizers

    return {
      totalInvoiceDue,
      totalInvoiceValue
    }
  }
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Card, PaginationContainer, HeaderPage } from '@bonitour/components'
import { useHistory } from 'react-router-dom'
import { VouchersFilter } from './VouchersFilter'
import { VouchersTable } from '../Table/VouchersTable'
import { useListVouchers } from 'domains/CompanyReports/hooks/useListVouchers'
import { useRef } from 'react'
import { VouchersResults } from './VouchersResults'
import { usePartnerActivityOptions } from 'domains/CompanyReports/hooks/usePartnerActivityOptions'

export const VouchersList = () => {
  const { push } = useHistory()
  const backToReports = () => push('/reports')

  const {
    filters,
    onFilterSubmit,
    changePagination,
    vouchers,
    meta,
    totals = {},
    loading
  } = useListVouchers()

  const { activityOptions, getActivityOptions, partners } = usePartnerActivityOptions()
  const { current: initialFilters } = useRef(filters)

  return (
    <>
      <HeaderPage onBack={backToReports} title='Vouchers' />

      <Card>
        <VouchersFilter
          onFilterSubmit={onFilterSubmit}
          loading={loading}
          initialFilters={initialFilters}
          partnerOptions={partners}
          activityOptions={activityOptions}
          getActivityOptions={getActivityOptions}
        />

        {!loading && Boolean(totals?.vouchersAmount > 0) && <VouchersResults totals={totals} />}

        <PaginationContainer total={meta?.total} pagesQuantity={meta?.totalPages} onPagination={changePagination}>
          <VouchersTable vouchers={vouchers} loading={loading} />
        </PaginationContainer>

      </Card>
    </>
  )
}

import { css } from '@emotion/core'
import { flexColumn, colors, BREAK_POINTS, scrollBar } from '@bonitour/components'

export const container = css`
  border: 0;
  margin-top: 2rem;
`

export const containerOfTotalizer = css`
  ${flexColumn};
  justify-content: space-between;
  width: 15.625rem;
  min-height: 16.875rem;
  color: ${colors.gray3};
  border: none;
  margin-left: 1.25rem;
  margin-top: 1.5rem;

  @media (max-width: ${BREAK_POINTS.smallTablet}) {
    margin-top: 2.5rem;
  }
`

export const bold = css`
  font-weight: bold;
`

export const amountDueContainer = css`
  padding: 1.25rem;
`

export const amountDueLabel = css`
  font-size: 0.75rem;
  color: ${colors.gray5};
  margin-bottom: 0.313rem;
`

export const amountDueStyle = css`
  font-size: 1.5rem;
  color: ${colors.gray1};
`

export const containerOfTable = css`
  width: 100%;
  color: ${colors.gray3};
  border: none;
  padding: 1.6rem 0.625rem 0.625rem 0.625rem;
  max-height: 15rem;
  overflow-y: auto;
  ${scrollBar(5)};
`

export const reduceHeaderPadding = css`
  padding: 0.938rem;
`

export const alignCenter = css`
  text-align: center;
`

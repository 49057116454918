/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Column, DatePicker, Input, InputFormGroup, InputWithSuffix, Row, Select, UploadInline } from '@bonitour/components'
import { width } from 'assets/styles/global'
import { batchPaymentMethodOptions } from '../constants/batchPaymentMethodOptions'
import { useCallback, useMemo } from 'react'
import { useCompany } from 'contexts/Company'
import { PAYMENT_METHODS_REQUIRED_BANK_ACCOUNT } from 'constants/paymentBatch'
import { useListBankAccounts } from '../hooks/useListBankAccounts'
import { formatMoney, identity } from '@bonitour/common-functions'
import { SafeDate } from '@binamik/js-providers'

const fullInput = width(100)

export const PaymentInfoBatchForm = ({
  batch = {},
  errors,
  disabled = false,
  onInputBlur,
  onInputChange,
  totals = {},
  setDiscountTotalValue = identity
}) => {
  const { paymentDate = new Date(), reference, expectedPaymentDate, method = '', originBankAccountId, observation, attachments = [], taxValue = '' } = batch

  const { value } = totals

  const { id: companyId } = useCompany()
  const { bankAccounts } = useListBankAccounts({ paymentMethod: method, companyId })

  const isBankAccountOriginRequired = useMemo(() => PAYMENT_METHODS_REQUIRED_BANK_ACCOUNT.includes(method), [method])

  const totalAliquotLabel = useMemo(() => {
    const batchTotalValue = Number(value) || 0

    const parsedTotalCalculed = Number((taxValue * batchTotalValue) / 100).toFixed(2)

    setDiscountTotalValue(parsedTotalCalculed)

    return formatMoney(parsedTotalCalculed)
  }, [setDiscountTotalValue, taxValue, value])

  const onChangeAliquot = useCallback((value) => {
    if ((!value && value !== 0) || value < 0) return

    const onChange = (value) => onInputChange('taxValue')(value)

    try {
      const valueStringified = value.toString()

      const spplitedValue = valueStringified.split('.')

      const hasSeveralItems = spplitedValue.length > 1

      if (hasSeveralItems && spplitedValue[1].length > 2) {
        throw new Error('Invalid number')
      }

      onChange(value)
    } catch (_error) {
      onChange(taxValue)
    }
  }, [onInputChange, taxValue])

  return (
    <>
      <Row>
        <Column phone={12} desktop={12}>
          <InputFormGroup label='Data de provisionamento' errorMessage={errors.expectedPaymentDate}>
            <DatePicker
              value={expectedPaymentDate}
              css={fullInput}
              disabled
            />
          </InputFormGroup>
        </Column>
      </Row>

      <Row>
        <Column phone={12} desktop={6}>
          <InputFormGroup label='Data do pagamento' errorMessage={errors.paymentDate}>
            <DatePicker
              value={paymentDate}
              css={fullInput}
              onChange={onInputChange('paymentDate')}
              onBlur={onInputBlur('paymentDate')}
              disabled={disabled}
              maxDate={new SafeDate().jsDate}
            />
          </InputFormGroup>
        </Column>

        <Column phone={12} desktop={6}>
          <InputFormGroup label='Número da Nota Fiscal' errorMessage={errors.reference}>
            <Input
              value={reference}
              css={fullInput}
              onChange={onInputChange('reference')}
              onBlur={onInputBlur('reference')}
              disabled={disabled}
            />
          </InputFormGroup>
        </Column>
      </Row>

      <Row>
        <Column phone={12} desktop={12}>
          <InputFormGroup label='Forma de pagamento' errorMessage={errors.method}>
            <Select
              placeholder='Selecione uma forma de pagamento'
              options={batchPaymentMethodOptions}
              value={method}
              error={errors.method}
              onChange={onInputChange('method')}
              onBlur={onInputBlur('method')}
              disabled={disabled}
            />
          </InputFormGroup>
        </Column>
      </Row>

      {isBankAccountOriginRequired
        ? <Row>
          <Column
            phone={12}
            desktop={12}
          >
            <InputFormGroup label="Conta bancária de origem" errorMessage={errors.originBankAccountId}>
              <Select
                placeholder="Selecione a conta de origem"
                options={bankAccounts}
                value={originBankAccountId}
                error={errors.originBankAccountId}
                onChange={onInputChange('originBankAccountId')}
                onBlur={onInputBlur('originBankAccountId')}
                disabled={disabled}
              />
            </InputFormGroup>
          </Column>
        </Row>
        : null}

      <Row>
        <Column phone={12} desktop={12}>
          <InputFormGroup label='Observações' errorMessage={errors.observation}>
            <Input
              value={observation}
              css={fullInput}
              onChange={onInputChange('observation')}
              onBlur={onInputBlur('observation')}
              disabled={disabled}
            />
          </InputFormGroup>
        </Column>
      </Row>

      <Row>
        <Column tabletlandscape={4} desktop={12}>
          <InputFormGroup label={'Alíquota'}>
            <InputWithSuffix
              max={100}
              min={0}
              type={'number'}
              onChange={onChangeAliquot}
              value={taxValue}
              disabled={disabled}
            >
              %&nbsp;({totalAliquotLabel})
            </InputWithSuffix>
          </InputFormGroup>
        </Column>
      </Row>

      <Row>
        <Column phone={12} desktop={12}>
          <InputFormGroup label='Anexos' errorMessage={errors.attachments}>
            <UploadInline
              files={attachments}
              css={fullInput}
              onChange={onInputChange('attachments')}
              onBlur={onInputBlur('attachments')}
              disabled={disabled}
              multiple
            />
          </InputFormGroup>
        </Column>
      </Row>
    </>
  )
}

import { boolean, string, object, array } from 'yup'

export const experienceSchema = {
  id: string().required('A seleção de experiência é obrigatória'),
  isOptional: boolean().default(false)
}

export const combinedExperiencesSchema = object().shape({
  title: string()
    .min(1, 'O título deve ter no mínimo 1 caractere')
    .max(64, 'O título deve ter no máximo 64 caracteres')
    .trim()
    .required('O título é obrigatório'),
  subtitle: string()
    .min(1, 'O subtítulo deve ter no mínimo 1 caractere')
    .max(64, 'O subtítulo deve ter no máximo 64 caracteres')
    .trim()
    .required('O subtítulo é obrigatório'),
  description: string()
    .max(1000, 'A descrição deve ter no máximo 1000 caracteres')
    .trim()
    .optional(),
  experiences: array()
    .of(object().shape(experienceSchema))
    .min(1, 'Selecione ao menos uma experiência'),
  isEnabled: boolean().default(true),
  isSameDayServices: boolean().default(true)
})

import { css } from '@emotion/core'

export const externalCodeStyle = css`
  text-decoration: underline;
`
export const hidePopover = css`
  display: none;
`
export const popoverStyle = css`
  display: flex;
  flex-direction: column;
  height: 50px;
  width: 150px;
  align-items: center;
  justify-content: space-evenly;
  top: -100px !important;
  left: -25px !important;
  transform: none !important;
`
export const voucherActionIconSize = css`
  font-size: 18px;
  margin-right: 7px;
`
export const insertedCodeContainer = css`
  position: relative;
`

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Checkbox, AvatarCard, Button, flexRow, GhostButton, PaginationContainer } from '@bonitour/components'
import { marginTop, marginRight, clickable } from 'assets/styles/global'
import { identity } from '@bonitour/common-functions'
import { PaginatedSellersContext } from 'app/Sellers/SellersContext'
import { partnetCard, partnerCheckbox, paginationContainer, loadingListContainer } from './PartnershipForm.style'
import { PartnershipSearchComponent } from './PartnershipSearch'

const marginTop30 = marginTop(30)
const marginRight20 = marginRight(20)

const LoadingList = memo(
  ({ elementsCount }) => {
    return (
      <ul css={loadingListContainer}>
        {Array.from({ length: elementsCount }, (_, i) => i).map((key) => (
          <li key={key} />
        ))}
      </ul>
    )
  },
  (prevProps, nextProps) => prevProps.elementsCount === nextProps.elementsCount
)

export const PartnershipForm = ({
  tradeAgreementName = '',
  listComercialPartners,
  onSuccess: emitSuccessEvent,
  onBackStep = identity
}) => {
  const [partnershipList, setPartnershipList] = useState(listComercialPartners || [])
  useEffect(() => {
    setPartnershipList(listComercialPartners || [])
  }, [listComercialPartners])

  const havePartnership = (partnerId) => partnershipList.includes(partnerId)

  const handlePartner = (partnerId) => () => {
    if (havePartnership(partnerId)) {
      setPartnershipList(partnershipList.filter((item) => item !== partnerId))
    } else {
      setPartnershipList(partnershipList.concat(partnerId))
    }
  }

  const onClick = () => {
    emitSuccessEvent(partnershipList)
  }

  const isNoCommissionGroup = !(tradeAgreementName === 'no_commission_group' || tradeAgreementName === 'Meu grupo')

  const { sellersInfo, loadingSellers, pagination, handlePagination } = useContext(PaginatedSellersContext)

  const paginationMiddleware = useCallback(
    (...props) => {
      if ((pagination.currentPage === props[0] && pagination.pageLimit === props[1]) || loadingSellers) {
        return
      }
      handlePagination(...props)
    },
    [handlePagination, pagination.currentPage, pagination.pageLimit, loadingSellers]
  )

  const totalEntries = useMemo(() => sellersInfo.total, [sellersInfo.total])
  const totalPages = useMemo(() => sellersInfo.totalPages, [sellersInfo.totalPages])
  const perPage = useMemo(() => pagination.pageLimit, [pagination.pageLimit])

  return (
    <>
      <PartnershipSearchComponent />
      <PaginationContainer
        total={totalEntries}
        pagesQuantity={totalPages}
        onPagination={paginationMiddleware}
        childrenCustomCss={[paginationContainer]}
      >
        {loadingSellers
          ? (
            <LoadingList elementsCount={perPage} />
          )
          : (
            sellersInfo.sellers.map(({ id, name, image }) => (
              <AvatarCard
                css={[partnetCard, isNoCommissionGroup && clickable]}
                key={id}
                image={image}
                title={name}
                onClick={isNoCommissionGroup && handlePartner(id)}
              >
                <Checkbox
                  css={partnerCheckbox}
                  checked={havePartnership(id)}
                  onChange={isNoCommissionGroup && handlePartner(id)}
                  disabled={!isNoCommissionGroup}
                />
              </AvatarCard>
            ))
          )}
      </PaginationContainer>

      <div css={[flexRow, marginTop30]}>
        <GhostButton css={marginRight20} onClick={onBackStep}>
          Voltar
        </GhostButton>
        <Button onClick={onClick}>Continuar</Button>
      </div>
    </>
  )
}

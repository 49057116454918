/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { CheckboxInputGroup, H3, Label } from '@bonitour/components'
import { serviceListStyle, checkboxStyle } from './CombinedExperienceForm.styles'
import { CombinedExperienceFormServiceCard, AddServiceButton } from './CombinedExperienceFormServiceCard'

const EMPTY_SERVICE_CARD = 'empty-service-card'

export const CombinedExperienceFormServiceList = ({
  onExperienceEdit = identity,
  onExperienceRemove = identity,
  onAddService = identity,
  getServiceData = identity,
  toggleSameDayServices = identity,
  servicesAdded = [],
  isCheckedSameDayServices = true,
  isLoadingServiceOptions = true,
  isEmptyServicesAdded = false,
  isEmptyExperiencesOptions = false,
  isLoading = false
}) => {
  return <>
    <H3>Serviços inclusos</H3>
    <div css={serviceListStyle} className={isEmptyServicesAdded && 'empty-list'}>

      {isEmptyServicesAdded && (
        <Label>Nenhum serviço foi incluso</Label>
      )}
      {servicesAdded.map(({ id, currentError, isOptional }) => {
        const serviceData = {
          id,
          isOptional,
          ...getServiceData(id)
        }

        return (
          <CombinedExperienceFormServiceCard
            key={id || EMPTY_SERVICE_CARD}
            formValues={serviceData}
            onExperienceSubmit={(data) => onExperienceEdit({ ...data, lastId: id })}
            onExperienceRemove={onExperienceRemove}
            isLoadingServiceOptions={isLoadingServiceOptions}
            isEmptyCard={!id}
            isLoading={isLoading}
            error={currentError}
            isSkeleton={isLoadingServiceOptions}
          />
        )
      })}

      {!isEmptyServicesAdded && !isLoadingServiceOptions && (
        <AddServiceButton
          onAddService={onAddService}
          isEnabledOnBottom={true}
          isEmptyExperiencesOptions={isEmptyExperiencesOptions}
          isLoading={isLoading}
          isLoadingServiceOptions={isLoadingServiceOptions}
        />
      )}
    </div>
    {isEmptyServicesAdded && (
      <AddServiceButton
        onAddService={onAddService}
        isRow={true}
        isEmptyExperiencesOptions={isEmptyExperiencesOptions}
        isLoading={isLoading}
        isLoadingServiceOptions={isLoadingServiceOptions}
      />
    )}
    {!isEmptyServicesAdded && (
      <CheckboxInputGroup
        disabled
        // TODO disabled={isLoading || isLoadingServiceOptions}
        checked={isCheckedSameDayServices}
        onChange={toggleSameDayServices}
        css={checkboxStyle}
      >
        Os serviços devem ocorrer no mesmo dia
      </CheckboxInputGroup>
    )}
  </>
}

import { colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const quantityStyle = css`
  background-color: ${colors.indigo3};
  color: ${colors.primary};
  padding: 5px;
  border-radius: 50%;
  font-size: 22px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ticketPhraseStyle = css`
  margin-left: 10px;
  font-size: 22px;
  color: #6A6A6A;
`

export const modalText = css`
  display: flex;
  align-items: center;
`

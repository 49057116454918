import { css } from '@emotion/core'
import { colors } from '@bonitour/components'

export const button = css`
  height: 40px;
  width: 100%;
  margin-top: 20px;
`
export const cardContrast = css`
  margin-bottom: 20px;
  padding: 15px;
  background-color: ${colors.gray13};
  border: none;
`

export const width150 = css`
  width: 150px;
  width: 100%;
`

export const submitButton = css`
  justify-content: flex-end;
`

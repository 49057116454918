/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { useCallback, useMemo } from 'react'
import { Column, DatePicker, Input, InputFormGroup, Row, BREAK_POINTS, InputWithSuffix } from '@bonitour/components'
import { formatMoney, identity } from '@bonitour/common-functions'
import { width } from 'assets/styles/global'
import moment from 'moment'

const fullInput = width(100)

const mobileLabel = css`
  @media (max-width: ${BREAK_POINTS.desktop}) {
    font-size: 12px;
  }
`

export const InfoBatchForm = ({
  batch = {},
  errors,
  onInputBlur,
  onInputChange,
  totals = {},
  setDiscountTotalValue = identity
}) => {
  const { reference, expectedPaymentDate, observation, taxValue = 0 } = batch

  const { value = 0 } = totals

  const today = useMemo(() => moment().toDate(), [])

  const totalAliquotLabel = useMemo(() => {
    const batchTotalValue = value || 0

    const parsedTotalCalculed = Number((taxValue * batchTotalValue) / 100).toFixed(2)

    setDiscountTotalValue(parsedTotalCalculed)

    return formatMoney(parsedTotalCalculed)
  }, [setDiscountTotalValue, taxValue, value])

  const onChangeAliquot = useCallback((value) => {
    if ((!value && value !== 0) || value < 0) return

    const onChange = (value) => onInputChange('taxValue')(value)

    try {
      const valueStringified = value.toString()

      const spplitedValue = valueStringified.split('.')

      const hasSeveralItems = spplitedValue.length > 1

      if (hasSeveralItems && spplitedValue[1].length > 2) {
        throw new Error('Invalid number')
      }

      onChange(value)
    } catch (_error) {
      onChange(taxValue)
    }
  }, [onInputChange, taxValue])

  return (
    <>
      <Row>
        <Column tabletlandscape={4} desktop={12}>
          <InputFormGroup label='Nome de referência do lote' errorMessage={errors.reference}>
            <Input
              value={reference}
              css={fullInput}
              onChange={onInputChange('reference')}
              onBlur={onInputBlur('reference')}
            />
          </InputFormGroup>
        </Column>
        <Column tabletlandscape={4} desktop={12}>
          <InputFormGroup customLabelCss={[mobileLabel]} label='Data esperada para pagamento do lote' errorMessage={errors.expectedPaymentDate}>
            <DatePicker
              value={expectedPaymentDate}
              minDate={today}
              css={fullInput}
              onChange={onInputChange('expectedPaymentDate')}
              onBlur={onInputBlur('expectedPaymentDate')}
            />
          </InputFormGroup>
        </Column>
        <Column tabletlandscape={4} desktop={12}>
          <InputFormGroup label='Observações' errorMessage={errors.observation}>
            <Input
              value={observation}
              css={fullInput}
              onChange={onInputChange('observation')}
              onBlur={onInputBlur('observation')}
            />
          </InputFormGroup>
        </Column>
        <Column tabletlandscape={4} desktop={12}>
          <InputFormGroup label={'Alíquota'}>
            <InputWithSuffix
              max={100}
              min={0}
              type={'number'}
              onChange={onChangeAliquot}
              value={taxValue}
            >
              %&nbsp;({totalAliquotLabel})
            </InputWithSuffix>
          </InputFormGroup>
        </Column>
      </Row>
    </>
  )
}

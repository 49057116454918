import { useCallback, useState } from 'react'
import { ReservationHistoryService } from 'core/services/ReservationHistory'
import { useToast } from '@bonitour/components'
import { useReservation } from 'hooks/context/Reservation'

const sortByDateTime = (head, next) => {
  return head.datetime < next.datetime ? 1 : -1
}

export const useReservationHistory = () => {
  const { reservationId } = useReservation()
  const [history, setHistory] = useState([])
  const [loading, setLoading] = useState(false)
  const { add: addToast } = useToast()

  const getHistory = useCallback(async (payload = {}) => {
    setLoading(true)
    try {
      if (payload.itemType === 'Financial::ReservationPayment') {
        const ticketHistory = await ReservationHistoryService.get(reservationId, payload)

        const chargebacks = await ReservationHistoryService.get(reservationId, { ...payload, itemType: 'Financial::ReservationRefund' })

        const advancePayments = await ReservationHistoryService.get(reservationId, { ...payload, itemType: 'Financial::AdvanceReservationPayment' })

        const mergedHistories = [...ticketHistory, ...chargebacks, ...advancePayments]
        const sortedHistories = mergedHistories.sort(sortByDateTime)

        if (!mergedHistories.length) {
          addToast('Nenhum histórico para esta busca', 'info', 10000)
        }

        setHistory(sortedHistories)
        setLoading(false)
      } else {
        const ticketHistory = await ReservationHistoryService.get(reservationId, payload)

        const payerChangedHistory = ticketHistory.filter(ticket => ticket.type === 'payer_id')

        if (!ticketHistory.length) {
          addToast('Nenhum histórico para esta busca', 'info', 10000)
        }

        setHistory(payload.itemType === 'TicketSeller::Payer' ? payerChangedHistory : ticketHistory)
        setLoading(false)
      }
    } catch (error) {
      addToast('Erro ao obter a reserva')
      setLoading(false)
    }
  }, [reservationId, addToast])

  return {
    history,
    getHistory,
    loading
  }
}

/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import { useHistory } from 'react-router-dom'
import { HeaderPage } from '@bonitour/components'
import { ExternalProviderForm } from '../Form/ExternalProviderForm'
import { useCreateExternalProvider } from '../hooks/useCreateExternalProvider'

export const CreateExternalProvider = () => {
  const { push } = useHistory()
  const { submit, loading } = useCreateExternalProvider()

  const redirectToList = () => push('/external-providers')

  return (
    <>
      <HeaderPage onBack={redirectToList} title='Cadastrar fornecedor externo' />
      <ExternalProviderForm submit={submit} loading={loading} />
    </>
  )
}

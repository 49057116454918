/** @jsxRuntime classic */
/** @jsx jsx */
import { useForm } from '@bonitour/app-functions'
import { identity } from '@bonitour/common-functions'
import { Button, Dialog, GhostButton, Input, InputFormGroup, useToast } from '@bonitour/components'
import { jsx, css } from '@emotion/core'
import { useEffect } from 'react'
import { string } from 'yup'

const buttonContainer = css`
  margin-top: 25px;
`

const inputStyle = css`
  width: 100%;
`

const saveButton = css`
  margin-left: 25px;
`

const buttonWidth = css`
  width: 120px;
`

const titleStyle = css`
  margin-bottom: 5px;
  margin-top: 15px;
`

const schema = {
  voucherCode: string().max(12, 'O código deve ter no máximo 12 caracteres').required('É necessário digitar um código')
}

export const InsertCodeDialog = ({
  isVisible = false,
  onClose: emitCloseEvent = identity,
  id = '',
  externalIntegrationTicketId = '',
  integrationName = '',
  insertCodeDialogType = '',
  setExternalId: emitSetExternalIdEvent = identity,
  voucher = {}
}) => {
  const { form, errors, onSubmit, utils: { onInputChange } } = useForm(voucher, schema)
  const { add: addToast } = useToast()

  const { voucherCode = '' } = form

  const onSuccess = () => {
    const integrationData = {
      integrationName,
      externalTicketId: voucherCode
    }

    emitSetExternalIdEvent(id, integrationData)
    emitCloseEvent()
  }

  const onValidationError = () => {
    addToast('Preencha corretamente o formulário')
  }

  const onClickEvent = onSubmit(onSuccess, onValidationError)

  useEffect(() => {
    if (insertCodeDialogType === 'edit') onInputChange('voucherCode')(externalIntegrationTicketId)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insertCodeDialogType])

  const insertCodeTitle = insertCodeDialogType === 'edit' ? 'Editar' : 'Inserir'

  return (
    <Dialog
      isVisible={isVisible}
      onClose={emitCloseEvent}
      title={`${insertCodeTitle} código externo`}
    >
      <div>
        <InputFormGroup errorMessage={errors.voucherCode} label='Código externo' css={titleStyle}>
          <Input
            onChange={onInputChange('voucherCode')}
            value={voucherCode}
            error={errors.voucherCode}
            css={inputStyle}
          />
        </InputFormGroup>
        <div css={buttonContainer}>
          <GhostButton onClick={emitCloseEvent} css={buttonWidth}>Cancelar</GhostButton>
          <Button css={[saveButton, buttonWidth]} onClick={onClickEvent}>Salvar</Button>
        </div>
      </div>
    </Dialog>
  )
}

export const stateOptions = [
  {
    label: 'Não retirado',
    value: 'did_not_withdraw'
  },
  {
    label: 'Retirado',
    value: 'withdrawn'
  },
  {
    label: 'Devolvido',
    value: 'returned'
  }
]

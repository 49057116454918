import { useState, useCallback } from 'react'
import { useToast } from '@bonitour/components'
import { ReportService } from 'core/services/Reports'
import { usePermissions } from 'hooks/usePermissions'
import { useParamsFilter } from 'hooks/useParamsFilter'
import { useQuery } from 'react-query'

const STALE_TIME = 1000 * 60 * 10 // 10 minutes

const DEFAULT_PAGINATION = {
  currentPage: 1,
  perPage: 25
}

export const useListRefundedTickets = () => {
  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()
  const [filters, setFilters] = useParamsFilter()
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION)

  const onError = useCallback((error) => {
    const hasPermission = handlePermission(
      error,
      'Você não possui permissão de acesso ao relatório de reembolso dos turistas'
    )
    hasPermission && addToast('Ocorreu um erro ao realizar a busca')
  }, [addToast, handlePermission])

  const {
    data: refundsData,
    isLoading,
    isFetching
  } = useQuery(
    [
      'refundedTicketsList',
      JSON.stringify(filters),
      JSON.stringify(pagination)
    ],
    async () => ReportService.listRefundedTickets({ filters, pagination }),
    {
      staleTime: STALE_TIME,
      onError,
      retry: 2
    }
  )

  const {
    data: totalizerData,
    isLoading: isTotalizersLoading,
    isFetching: isTotalizersFetching
  } = useQuery(
    [
      'refundedTicketsTotalizer',
      JSON.stringify(filters)
    ],
    async () => ReportService.totalRefundedTickets({ filters }),
    {
      staleTime: STALE_TIME,
      onError,
      retry: 2
    }
  )

  const changePagination = useCallback(
    (newPagination) => setPagination(currPagination => ({ ...currPagination, ...newPagination })
    ), [])

  const onFilterSubmit = useCallback(filters => {
    setPagination(DEFAULT_PAGINATION)
    setFilters(filters)
  }, [setFilters])

  return {
    filters,
    onFilterSubmit,
    changePagination,
    pagination,
    refundedTickets: refundsData?.refundedTickets,
    meta: refundsData?.meta,
    totalRefundedValue: totalizerData?.totalRefundedValue,
    isLoading: isLoading || isFetching,
    isTotalizerLoading: isTotalizersLoading || isTotalizersFetching
  }
}

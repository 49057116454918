/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { PlusIcon, Label, UnderlineNavContainer, UnderlineNavItem, TabContainer, UnderlineTabPane, HeaderPage } from '@bonitour/components'
import { cardButton, cardIcon, labelCardButton, marginBottom } from 'assets/styles/global'
import { useHistory } from 'react-router-dom'
import { CardButton } from 'components/CardButton'
import { useSellersList } from '../../../hooks/domains/useSellersList'
import { PartnershipAccountEntry } from '../Create/PartnershipAccountEntry'
import { useCallback, useEffect, useRef, useState } from 'react'
import { head } from '@bonitour/common-functions'
import { SellersSelector } from './SellersSelector'
import { usePartnershipAccountCreateEntry } from '../hooks/usePartnershipAccountCreateEntry'
import { useListPartnershipAccounts } from '../hooks/useListPartnershipAccounts'
import { useQuery } from 'hooks/useQuery'
import { PartnershipAccountCredit } from './PartnershipAccountCredit'

export const marginBottom0 = marginBottom(0)
export const marginBottom20 = marginBottom(20)
export const marginBottom40 = marginBottom(40)

export const PartnershipAccounts = () => {
  const { push } = useHistory()
  const goToDashboard = () => push('/dashboard')
  const { sellersCompanyFiltered, sellersOptionsCompanyFiltered } = useSellersList()
  const [isEntryVisible, setEntryVisibility] = useState(false)
  const [sellerSelected, setSellerSelected] = useState(head(sellersCompanyFiltered)?.id)
  const onEntryHidden = () => setEntryVisibility(false)
  const { submit, loading: loadingCreateEntry } = usePartnershipAccountCreateEntry()
  const {
    filters,
    setFilters,
    pagination,
    changePagination,
    partnershipAccounts,
    meta,
    fetchPartnershipAccounts,
    loading
  } = useListPartnershipAccounts()
  const { current: initialFilters } = useRef(filters)
  const [{ selectedNav = 'creditor' }] = useQuery()
  const [openingNav, setOpeningNav] = useState(false)
  const setSelectedNav = useCallback((value) => {
    setOpeningNav(true)
    push(`?selectedNav=${value}`)
    changePagination(1, 10)
    setTimeout(() => setOpeningNav(false))
  }, [changePagination, push])
  const debtor = 'debtor'
  const creditor = 'creditor'

  const onOptionSelected = id => {
    setSellerSelected(id)
    setEntryVisibility(true)
  }

  useEffect(() => {
    if (!openingNav) {
      selectedNav === 'debtor'
        ? fetchPartnershipAccounts({ pagination, filters }, debtor)
        : fetchPartnershipAccounts({ pagination, filters }, creditor)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNav, loadingCreateEntry, pagination, openingNav, filters])

  return (
    <>
      <HeaderPage onBack={goToDashboard} title='Conta de parceria' />

      <SellersSelector options={sellersCompanyFiltered} onOptionSelected={onOptionSelected}>
        <CardButton css={cardButton}>
          <PlusIcon css={cardIcon} />
          <Label css={labelCardButton}>Novo lançamento de crédito</Label>
        </CardButton>
      </SellersSelector>

      <UnderlineNavContainer selectedNav={selectedNav} onChange={setSelectedNav}>
        <UnderlineNavItem navId='creditor'>Créditos concedidos</UnderlineNavItem>
        <UnderlineNavItem navId='debtor'>Créditos recebidos</UnderlineNavItem>
      </UnderlineNavContainer>
      <TabContainer activeTab={selectedNav}>
        <UnderlineTabPane tabId='creditor'>
          {selectedNav === 'creditor' && (
            <PartnershipAccountCredit
              partners={sellersOptionsCompanyFiltered}
              onFilterSubmit={setFilters}
              initialFilters={initialFilters}
              partnershipAccounts={partnershipAccounts}
              meta={meta}
              loading={loading}
              changePagination={changePagination}
            />
          )}
        </UnderlineTabPane>
        <UnderlineTabPane tabId='debtor'>
          {selectedNav === 'debtor' && (
            <PartnershipAccountCredit
              partners={sellersOptionsCompanyFiltered}
              onFilterSubmit={setFilters}
              initialFilters={initialFilters}
              partnershipAccounts={partnershipAccounts}
              meta={meta}
              loading={loading}
              changePagination={changePagination}
              isDebtor
            />
          )}
        </UnderlineTabPane>
      </TabContainer>

      <PartnershipAccountEntry
        sellers={sellersOptionsCompanyFiltered}
        sellerSelected={sellerSelected}
        isEntryVisible={isEntryVisible}
        canDebitType={false}
        canCreditType={true}
        onEntryHidden={onEntryHidden}
        onSubmit={submit}
      />
    </>
  )
}

import {
  string as yupString,
  number as yupNumber
} from 'yup'

export const invoiceBatchSchema = {
  referenceNumber: yupString().required('O número de referência é obrigatório'),
  date: yupString().required('A data é obrigatória'),
  beneficiaryId: yupString().required('O beneficiário é obrigatório'),
  discount: yupString().nullable(),
  taxValue: yupNumber().min(0, 'A alíquota não pode ser negativa').max(100, 'A alíquota não pode ser maior que 100').nullable(),
  note: yupString().nullable()
}

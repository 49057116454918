/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useState, useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { Logo, Header as HeaderContainer, Hamburguer, Sidebar, flexRowCentered } from '@bonitour/components'
import { SideNavbar } from '../SideNavbar/SideNavbar'
import { horizontalyCenteredRow, logoContainer, dashboardLink, envStyle, hideInfo } from './Header.style'
import logoOrb from 'assets/svgs/logoOrb.svg'
import { clickable } from 'assets/styles/global'
import { UserGuide } from '../User/User'
import { useCompany } from 'contexts/Company'
import { useActivity } from 'contexts/Activity'
import { NotificationSection } from '../Notification'
import { ProductsSection } from '../ProductsSection'
import { APP_ENVIRONMENT } from 'shared/config/env'
import { HelpSection } from '../Help'
import { OFFLINE_EXPERIENCE_TYPE, TRANSPORT_TYPE } from 'constants/activityTypes'

export const Header = () => {
  const { id: companyId } = useCompany()
  const { id: activityId, activity: { type } } = useActivity()
  const [isSidebarVisible, setSidebarVisible] = useState(false)

  const openSidebar = () => setSidebarVisible(true)
  const closeSidebar = () => setSidebarVisible(false)

  const hasCompany = Boolean(companyId)
  const hasActivity = Boolean(activityId)

  const companyDashboardLink = '/dashboard'

  const activityDashboardLink = useMemo(() => {
    if (type === TRANSPORT_TYPE) {
      return `/transport/${activityId}/dashboard`
    }

    if (type === OFFLINE_EXPERIENCE_TYPE) {
      return ''
    }

    return `/activity/${activityId}/dashboard`
  }, [activityId, type])

  return (
    <>
      <HeaderContainer>
        <div css={horizontalyCenteredRow}>
          {hasCompany && <Hamburguer onClick={openSidebar} />}
          <NavLink to={companyDashboardLink}>
            <Logo css={[logoContainer, clickable]} src={logoOrb} />
          </NavLink>

          <span css={hideInfo}>
            {APP_ENVIRONMENT !== 'production' && <span css={envStyle}>{APP_ENVIRONMENT}</span>}
          </span>
        </div>

        <div css={[horizontalyCenteredRow, hideInfo]}>
          {hasCompany && (
            <NavLink css={dashboardLink} to={companyDashboardLink}>
              Empresa
            </NavLink>
          )}
          {hasActivity && (
            <NavLink css={dashboardLink} to={activityDashboardLink}>
              Experiência
            </NavLink>
          )}
        </div>
        <div css={flexRowCentered}>
          <HelpSection />
          <NotificationSection />
          <span css={hideInfo}><ProductsSection /></span>
          <UserGuide />
        </div>
      </HeaderContainer>

      <Sidebar isOpen={isSidebarVisible} onSidebarClose={closeSidebar}>
        <SideNavbar onNavigation={closeSidebar} />
      </Sidebar>
    </>
  )
}

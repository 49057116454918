import { periodModel } from 'core/models/period'
import { uniqBy } from 'utils/array'

const applyPercentageValueModificator = (valueModificator = 100, operator = 'increase') => {
  const percentager = {
    increase: 0.01,
    decrease: -0.01
  }
  return 1 + valueModificator * percentager[operator]
}

const paxTypeModel = ({ name = '', feeModificator = 100, minAge = 18, maxAge = 60, capacity = 1, taxes = {} }) => {
  const percentMainPrice = feeModificator / 100
  const { guide, bearer } = taxes
  return {
    name,
    description: {
      min_age: Number(minAge),
      max_age: Number(maxAge),
      capacity
    },
    taxes: {
      guide: guide / 100,
      bearer
    },
    percent_of_main_price: percentMainPrice
  }
}

const unformatPrice = price => Number(price.replace(/[^\d,]/g, '').replace(',', '.'))

const paxDemandModel = ({ limit = 1, operator = 'increase', valueModificator = 0 }) => {
  const percentMainPrice = applyPercentageValueModificator(valueModificator, operator)
  const salesTrigger = limit / 100
  return {
    name: 'Taxa por demanda',
    percent_of_main_price: percentMainPrice,
    sales_percent_trigger: salesTrigger
  }
}

export const feeModel = (serviceId = '', companyId = '', data = {}) => {
  const { feeBasic = {}, periodList = [], feesByDemand = [], feePaxTypes = [] } = data
  const { name = '', price = '' } = feeBasic

  const nonDuplicatedFeeTypes = uniqBy(feePaxTypes, 'name') // to avoid to call API sending duplicated fees

  const dateRanges = periodList.map(period => periodModel(period, companyId, 'fee'))
  const feeTypes = nonDuplicatedFeeTypes.map(paxTypeModel)
  const demands = feesByDemand.map(paxDemandModel)

  return {
    fee: {
      name,
      main_price: unformatPrice(price),
      active: 'true',
      service_id: serviceId,
      company_id: companyId
    },
    demands,
    date_ranges_params: dateRanges,
    fee_types: feeTypes
  }
}

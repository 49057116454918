/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'

import { Button, Dialog, ExternalLinkIcon, GhostButton, P } from '@bonitour/components'
import { bold, buttonsContainer, dialogStyle, dialogTextSize, externalicon, marginRight20, marginTop25, marginTop50 } from './SelectedTicketsDialog.style'
import { identity } from '@bonitour/common-functions'
import { link } from 'assets/styles/global'

export const SelectedTicketsDialog = ({
  isVisible = false,
  quantity = 0,
  onCloseDialog = identity,
  onSubmitValidation = identity
}) => {
  const ticketWord = quantity > 1 ? 'ingressos' : 'ingresso'
  return (
    <Dialog
      isVisible={isVisible}
      title='Confirmação de validação'
      customContainercss={dialogStyle}
      onClose={onCloseDialog}

    >
      <P css={[dialogTextSize, marginTop25]}>Você está prestes a utilizar <span css={bold}>{`${quantity}`}</span> {`${ticketWord}`}, <span css={bold}>e essa ação não poderá ser alterada depois.</span> Gostaria de prosseguir com a utilização dos ingressos?</P>

      <P css={[dialogTextSize]}>Para mais informações acesse nossa
        <a
          href='https://ajuda.binamik.com.br/'
          target='_blank'
          rel='noopener noreferrer' css={link}
        > Central de Ajuda
        </a>
        {'\u00A0'}
        <ExternalLinkIcon css={externalicon} />
      </P>

      <div css={[buttonsContainer, marginTop50]}>
        <GhostButton css={marginRight20} onClick={onCloseDialog}>Cancelar</GhostButton>
        <Button onClick={onSubmitValidation}>Confirmar validação</Button>
      </div>
    </Dialog>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Accordion, CollapsableSection, HeaderPage } from '@bonitour/components'
import { useHistory } from 'react-router-dom'
import { ActivityAbout } from './ActivityAbout'
import { EditActivityInfo } from './EditActivityInfo'
import { ActivityComplementaryInfo } from './ActivityComplementaryInfo'
import { useQuery } from 'hooks/useQuery'
import { useRouteByType } from 'hooks/useRouteByType'
import { ListActivityVacancy, ListActivityFee } from 'app'
import { useState, useEffect, useCallback, useMemo } from 'react'
import { ActivityService } from 'services/Activity/Service'
import { ActivityCategoryService } from 'services/Activity/ActivityCategory/Service'
import { useActivity } from 'contexts/Activity'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { OFFLINE_EXPERIENCES_CATEGORIES_MAP } from 'constants/offlineExperiences'
import { useIsLockedService } from 'hooks/domains/useIsLockedService'

export const EditActivity = () => {
  const { push } = useHistory()
  const { search } = useLocation()
  const { id: activityId } = useActivity()
  const [{ accordion = 1 }] = useQuery()
  const [activity, setActivity] = useState({})

  useRouteByType(`${activityId}/edit`)

  const updateActivity = useCallback(() => ActivityService.get(activityId).then(setActivity), [activityId])
  const setIndicatorActive = accordionValue => push({ search: `?accordion=${accordionValue}` })

  useEffect(() => {
    updateActivity()
  }, [updateActivity])

  const backToDashboard = () => push(`/activity/${activityId}/dashboard`)
  const onBackStep = () => setIndicatorActive(Number(accordion) - 1)
  const onNextStep = () => setIndicatorActive(Number(accordion) + 1)

  const [categories, setCategories] = useState([])

  useEffect(() => {
    ActivityCategoryService.list().then(setCategories)
  }, [])

  const categoryTitle = useMemo(() => {
    const categoryId = activity?.activityAbout?.activityAbout?.category
    return categories.find(category => category.id === categoryId)?.title
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity?.activityAbout?.activityAbout?.category, categories])

  useEffect(() => {
    if (categoryTitle === OFFLINE_EXPERIENCES_CATEGORIES_MAP.TRANSPORTE) {
      push(`/transport/${activityId}/edit${search}`)
    }
  }, [activityId, categoryTitle, push, search])

  const allowedCategories = useMemo(
    () => categories.filter(category => category.title !== OFFLINE_EXPERIENCES_CATEGORIES_MAP.TRANSPORTE),
    [categories]
  )

  const {
    isLocked: isActivityLocked,
    isLoading: isLoadingActivityLocked,
    refetchGetIsLockedService
  } = useIsLockedService(activityId)

  return (
    <>
      <HeaderPage onBack={backToDashboard} title='Atividade' />
      <Accordion value={Number(accordion)} onClick={setIndicatorActive}>
        <CollapsableSection
          indicator={1}
          title='Informações básicas'
          topics='Registro / Contato / Endereço'
        >
          <EditActivityInfo
            activityBase={activity.activityBase}
            onActivityUpdated={updateActivity}
            onNextStep={onNextStep}
          />
        </CollapsableSection>

        <CollapsableSection
          indicator={2}
          title='Informações técnicas'
          topics='Tempo de duração / Tempo de atividade / Nível de dificuldade'
        >
          <ActivityAbout
            activityId={activityId}
            categories={allowedCategories}
            activityAbout={activity.activityAbout}
            onActivityUpdated={updateActivity}
            onBackStep={onBackStep}
            onNextStep={onNextStep}
          />
        </CollapsableSection>

        <CollapsableSection
          indicator={3}
          title='Informações Complementares'
          topics='Descrição / Observações / O que levar / Incluso na atividade / Pagamentos aceitos'
          tooltip='Essas informações serão usadas para informar os viajantes sobre a atividade em plataformas de vendas'
        >
          <ActivityComplementaryInfo
            activityId={activityId}
            activityComplementaryInfo={activity.activityComplementaryInfo}
            onActivityUpdated={updateActivity}
            onBackStep={onBackStep}
            onNextStep={onNextStep}
          />
        </CollapsableSection>

        <CollapsableSection indicator={4} title='Vagas' topics='Limite de vagas / Horários'>
          <ListActivityVacancy
            onBackStep={onBackStep}
            onNextStep={onNextStep}
            isActivityLocked={isActivityLocked}
            isLoadingActivityLocked={isLoadingActivityLocked}
            onActionCallback={refetchGetIsLockedService}/>
        </CollapsableSection>

        <CollapsableSection
          indicator={5}
          title='Tarifário'
          topics='Tarifário padrão / Tipificação de PAX / Tarifário por demanda'
        >
          <ListActivityFee onBackStep={onBackStep} onNextStep={backToDashboard} />
        </CollapsableSection>
      </Accordion>
    </>
  )
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { TableHeaderCell, TableHeaderRow, TableHeader } from '@bonitour/components'

export const InvoicesBatchTableHeader = ({
  recipientLabel = 'Nome do beneficiário'
}) => {
  return (
    <TableHeader>
      <TableHeaderRow>
        <TableHeaderCell>Data esperada <br/>do pagamento</TableHeaderCell>
        <TableHeaderCell>{recipientLabel}</TableHeaderCell>
        <TableHeaderCell>Número de referência</TableHeaderCell>
        <TableHeaderCell>Valor</TableHeaderCell>
        <TableHeaderCell>Status</TableHeaderCell>
        <TableHeaderCell>Ações</TableHeaderCell>
      </TableHeaderRow>
    </TableHeader>
  )
}

import { TicketModel } from 'core/models/Ticket'
import { ExportColumnsParser } from 'core/parsers/exportColumns'
import { TicketParser } from 'core/parsers/Ticket'
import { orbCore } from 'services/Orb/Service'

export const TicketsService = {
  getByPartialCode (code = '') {
    return orbCore.ticketDomain.getByPartialCode(code).then(TicketParser.ticketList)
  },
  validateTicket (code = '') {
    return orbCore.ticketDomain.useTicket(code)
  },
  validateManyTickets (codes = '') {
    return orbCore.ticketDomainV2.useTickets(TicketModel.manyTickets(codes))
  },
  ticketPrint (ticketId = '') {
    return orbCore.ticketDomain.ticketPrint(ticketId).then(TicketParser.ticketPrint)
  },
  ticketPrintVoucherTuris (ticketId = '') {
    return orbCore.ticketDomain.ticketPrintVoucherTuris(ticketId).then(TicketParser.ticketPrintVoucherTuris)
  },
  create (ticketId = []) {
    return orbCore.ticketDomain.createVoucher(ticketId)
  },
  list ({ filters, pagination }) {
    return orbCore.ticketDomain(TicketModel.list({ filters, pagination })).then(TicketParser.list)
  },
  vouchersTotals ({ filters }) {
    return orbCore.ticketTotalsDomain.totals(TicketModel.list({ filters })).then(TicketParser.totals)
  },
  ticketConfirm (ticketId, confirmData) {
    return orbCore.ticketDomain.ticketConfirm(ticketId, TicketModel.confirm(confirmData))
  },
  linkPassenger (ticketId, passengerId) {
    return orbCore.ticketDomain.linkPassenger(ticketId, passengerId)
  },
  setExternalId (ticketId, integrationData) {
    return orbCore.ticketIntegrationDomain.setExternalId(ticketId, TicketModel.externalIntegration(integrationData))
  },
  unlinkPassenger (ticketId) {
    return orbCore.ticketDomain.unlinkPassenger(ticketId)
  },
  getItemsExportColumn () {
    return orbCore.ticketDomain.getItemsExportColumn().then(ExportColumnsParser.columns)
  },
  changeState (payload) {
    return orbCore.ticketDomain.changeState(TicketModel.changeState(payload))
  },
  updateDeadlineRule (payload) {
    return orbCore.ticketDomain.updateDeadlineRule(TicketModel.updateDeadlineRule(payload))
  }
}

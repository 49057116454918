/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useHistory } from 'react-router-dom'
import { jsx } from '@emotion/core'
import { HeaderPage, Row, TabContainer, UnderlineNavContainer, UnderlineNavItem, UnderlineTabPane } from '@bonitour/components'
import { useQuery } from 'hooks/useQuery'
import { container, underlineNavContainer, tabPaneContainer } from './ActivitiesPage.style'
import NewExperienceButton from './NewExperienceButton'
import { ExperienceListSelector } from 'containers/Activity/ListSelector/ListSelector'
import { ACTIVITY_TYPE, COMBINED_EXPERIENCE_TYPE, OFFLINE_EXPERIENCE_TYPE, TRANSPORT_TYPE } from 'constants/activityTypes'

const ActivitiesPage = () => {
  const { push } = useHistory()
  const backToDashboard = () => push('/app')

  const [{
    selectedNav = 'myExperiences'
  }] = useQuery()

  const setSelectedNav = (value) => push(`?selectedNav=${value}`)

  return (
    <>
      <HeaderPage onBack={backToDashboard} title='Experiências' />

      <div css={container}>
        <NewExperienceButton />
      </div>
      <Row>
        <UnderlineNavContainer css={underlineNavContainer} selectedNav={selectedNav} onChange={setSelectedNav}>
          {[
            <UnderlineNavItem navId='myExperiences' key='myExperiences'>Minhas experiências</UnderlineNavItem>,
            <UnderlineNavItem navId='partnerExperiences' key='partnerExperiences'>Experiências de parceiros</UnderlineNavItem>,
            <UnderlineNavItem navId='offlineExperiences' key='offlineExperiences'>Experiências offline</UnderlineNavItem>
          ]}
        </UnderlineNavContainer>

        <TabContainer activeTab={selectedNav}>
          <UnderlineTabPane css={[tabPaneContainer]} tabId='myExperiences'>
            <ExperienceListSelector
              subdomain='dashboard'
              disabledTypes={[OFFLINE_EXPERIENCE_TYPE]}
              disableScroll
              showActionLabel
              fromCurrentCompany={true}
            />
          </UnderlineTabPane>
          <UnderlineTabPane css={[tabPaneContainer]} tabId='partnerExperiences'>
            <ExperienceListSelector
              subdomain='dashboard'
              disabledTypes={[OFFLINE_EXPERIENCE_TYPE]}
              disableScroll
              showActionLabel
              fromCurrentCompany={false}
            />
          </UnderlineTabPane>
          <UnderlineTabPane css={[tabPaneContainer]} tabId='offlineExperiences'>
            <ExperienceListSelector
              subdomain='dashboard'
              disabledTypes={[ACTIVITY_TYPE, TRANSPORT_TYPE, COMBINED_EXPERIENCE_TYPE]}
              disableScroll
              showActionLabel
              fromCurrentCompany={false}
            />
          </UnderlineTabPane>
        </TabContainer>
      </Row>
    </>
  )
}

export default ActivitiesPage

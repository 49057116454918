/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Card, H2, Loading, Row } from '@bonitour/components'
import { marginTop } from 'assets/styles/global'
import { ReservationBalanceTransferTable } from './ReservationBalanceTransferTable'

const marginTop40 = marginTop(40)

const headings = [
  {
    title: 'Realizando transferência'
  }
]

export const ReservationBalanceTransferDetails = ({ transferDetail = {}, isTransferLoading = false }) => {
  if (isTransferLoading) {
    return (
      <Loading isLoading={isTransferLoading} headings={headings} />
    )
  }

  return (
    <>
      <Row css={marginTop40}>
        <H2>Detalhes da transferência</H2>
      </Row>
      <Card>
        <ReservationBalanceTransferTable transferDetail={transferDetail} />
      </Card>
    </>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useState } from 'react'
import { Accordion, CollapsableSection, useToast, HeaderPage } from '@bonitour/components'
import { TradeAgreementForm } from 'containers/PartnershipGroup/TradeAgreement/TradeAgreementForm'
import { InvoicePolicyForm } from 'containers/PartnershipGroup/InvoicePolicy/InvoicePolicyForm'
import { PartnershipForm } from 'containers/PartnershipGroup/Partnership/PartnershipForm'
import { useQuery } from 'hooks/useQuery'
import { SellersGroupsService } from 'services/SellersGroups/Service'
import { useHistory } from 'react-router-dom'
import { usePermissions } from 'hooks/usePermissions'
import { PaginatedSellersProvider } from 'app/Sellers/SellersContext'

const sellersGroupBase = {
  tradeAgreement: {},
  invoicePolicy: {
    invoiced: false,
    invoice: {}
  },
  commercialPartners: []
}

export const CreatePartnershipGroup = () => {
  const [{ accordion = 1, name = '' }] = useQuery()
  const [sellersGroup, setSellersGroup] = useState({ ...sellersGroupBase })

  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()
  const { push } = useHistory()

  const backToDashboard = () => push('/sellers')
  const onBackStep = () => setIndicatorActive(Number(accordion) - 1)
  const onNextStep = () => setIndicatorActive(Number(accordion) + 1)

  const setIndicatorActive = (accordionValue) => push({ search: `?name=${name}&accordion=${accordionValue}` })

  const updateSellersGroup = (section, data) => {
    return setSellersGroup((previousDefault) => ({ ...previousDefault, [section]: data }))
  }

  const onSuccessTradeAgreement = (data) => {
    updateSellersGroup('tradeAgreement', data)
    onNextStep()
    addToast('As informações serão gravadas somente ao clicar em salvar na seção parceiros', 'warning')
  }

  const onSuccessInvoicePolicy = (data) => {
    updateSellersGroup('invoicePolicy', data)
    onNextStep()
  }

  const onSuccessComercialPartners = (data) => {
    const group = { ...sellersGroup, comercialPartners: data }
    setSellersGroup(group)
    onSuccess(group)
  }

  const onSuccess = async (newSellersGroup) => {
    try {
      const { partnership_group: partnershipGroup } = await SellersGroupsService.create(newSellersGroup)
      if (partnershipGroup) {
        backToDashboard()
        addToast('Grupo criado com sucesso', 'success')
        addToast('Aguarde confirmação dos seus parceiros', 'success')
      }
    } catch (error) {
      const hasPermission = handlePermission(error, 'Você não possui permissão de criação de grupos')
      hasPermission && addToast('Houve um erro na criação do grupo')
    }
  }

  const onValidationError = async () => {
    addToast('Preencha corretamente o formulário')
  }

  const onAccordionClick = (value) => value !== 0 && value < accordion && setIndicatorActive(value)

  return (
    <>
      <HeaderPage onBack={backToDashboard} title="Grupo" />

      <Accordion value={Number(accordion)} onClick={onAccordionClick}>
        <CollapsableSection indicator={1} title="Acordo comercial" topics="Nome da categoria / Comissionamento">
          <TradeAgreementForm
            tradeAgreement={sellersGroup.tradeAgreement}
            onSuccess={onSuccessTradeAgreement}
            onValidationError={onValidationError}
            isGroupEditable
          />
        </CollapsableSection>

        <CollapsableSection indicator={2} title="Cobrança" topics="Fatura / Cobrança antecipada">
          <InvoicePolicyForm
            invoicePolicy={sellersGroup.invoicePolicy}
            onSuccess={onSuccessInvoicePolicy}
            onBackStep={onBackStep}
            onValidationError={onValidationError}
            isGroupEditable
          />
        </CollapsableSection>

        <CollapsableSection indicator={3} title="Parceiros" topics="Parceiros">
          <PaginatedSellersProvider>
            <PartnershipForm onBackStep={onBackStep} onSuccess={onSuccessComercialPartners} />
          </PaginatedSellersProvider>
        </CollapsableSection>
      </Accordion>
    </>
  )
}

import { useCallback, useEffect, useState } from 'react'
import { ReservationService } from 'core/services/Reservation'
import { useToast } from '@bonitour/components'
import { PaymentService } from 'core/services/Payment'

export const useReservationBalance = (reservationId) => {
  const { add: addToast } = useToast()
  const [balance, setBalance] = useState({})
  const [ticketsToPay, setTicketsToPay] = useState([])
  const [transferDetail, setTransferDetail] = useState({})
  const [isLoading, setLoading] = useState(false)
  const [isPaymentTransferLoading, setIsPaymentTransferLoading] = useState(false)
  const [isFreelyTransferable, setIsFreelyTransferable] = useState(false)

  const fetchBalance = useCallback((reservationId) => {
    setLoading(true)
    return ReservationService.getBalance(reservationId).then((balance) => {
      setBalance(balance)
    }).catch(() => {
      addToast('Ocorreu um erro ao buscar o saldo da reserva')
    }).finally(() => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchTicketsToPay = useCallback((reservationId) => {
    return ReservationService.getTicketsToPay(reservationId).then((tickets) => {
      setTicketsToPay(tickets)
    }).catch(() => {
      addToast('Ocorreu um erro ao buscar os tickets a pagar')
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const paymentTransfer = useCallback((reservationId, transferData) => {
    setIsPaymentTransferLoading(true)
    return new Promise((resolve, reject) => {
      return PaymentService.paymentTransfer(reservationId, transferData).then(data => {
        setTransferDetail(data)
        addToast('Transferência realizada com sucesso', 'success')
        resolve(data)
      }).catch(error => {
        const errorCode = error?.data?.errors
        const errorsMap = {
          '040::041': 'Só é possível transferir valores maior ou igual ao saldo do ticket cancelado quando é split.',
          '': `Ocorreu um erro na criação da experiência. ${errorCode}`
        }
        addToast(errorsMap?.[errorCode] || errorsMap?.[''])
        reject()
      }).finally(() => setIsPaymentTransferLoading(false))
    })
  }, [addToast])

  useEffect(() => {
    fetchBalance(reservationId)
  }, [reservationId, fetchBalance])

  return {
    balance,
    fetchBalance,
    fetchTicketsToPay,
    ticketsToPay,
    isLoading,
    isPaymentTransferLoading,
    paymentTransfer,
    setIsFreelyTransferable,
    isFreelyTransferable,
    transferDetail
  }
}

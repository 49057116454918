import { head } from '@bonitour/common-functions'
import { formatISOTime } from 'utils/time'

/* eslint-disable camelcase */
export const ReservationTicketParser = {
  ticket (ticket = {}) {
    const {
      description: { fee = {} } = {},
      id,
      state,
      price,
      value,
      ticket_code: code,
      company_name: company,
      company_id: companyId,
      service_id: serviceId,
      service_name: serviceName,
      service_title: serviceTitle,
      passenger_name: passagerName,
      payer_name: payerName,
      slot,
      ticket_payment_balance: ticketPaymentBalance,
      transferred_tickets: transferredTickets,
      ticket_typification: type
    } = ticket
    const { name = '' } = fee

    return {
      id,
      name,
      code,
      company,
      companyId,
      serviceId,
      activity: serviceName || serviceTitle,
      value: Number(value || price),
      status: state,
      passagerName,
      payerName,
      type: name || type,
      date: formatISOTime(slot, 'DD/MM/YYYY - HH:mm', false),
      ticketPaymentBalance: (ticketPaymentBalance || []).map(ReservationTicketParser.ticketPaymentBalance),
      transferredTickets: (transferredTickets || []).map(ReservationTicketParser.transferredTickets)
    }
  },
  ticketToRefund (ticket = {}) {
    const {
      description: { fee = {} } = {},
      id,
      price,
      value,
      state,
      ticket_code: code,
      company_name: company,
      company_id: companyId,
      service_id: serviceId,
      service_name: serviceName,
      service_title: serviceTitle,
      passenger_name: passagerName,
      payer_name: payerName,
      slot,
      ticket_payment_balance: ticketPaymentBalance,
      transferred_tickets: transferredTickets,
      ticket_typification: type
    } = ticket
    const { name = '' } = fee

    return {
      id,
      name,
      code,
      company,
      companyId,
      serviceId,
      activity: serviceName || serviceTitle,
      value: Number(price || value),
      status: state,
      passagerName,
      payerName,
      type: name || type,
      date: formatISOTime(slot, 'DD/MM/YYYY - HH:mm', false),
      ticketPaymentBalance: (ticketPaymentBalance || []).map(ReservationTicketParser.ticketPaymentBalanceToRefund),
      transferredTickets: (transferredTickets || []).map(ReservationTicketParser.transferredTickets)
    }
  },
  advanceTicket (ticket = {}) {
    const {
      description: { fee = {} } = {},
      id,
      state,
      price,
      value,
      advance_paid_value: advancePaidValue,
      ticket_code: code,
      company_name: company,
      company_id: companyId,
      service_id: serviceId,
      service_name: serviceName,
      service_title: serviceTitle,
      passenger_name: passagerName,
      payer_name: payerName,
      slot,
      ticket_payment_balance: ticketPaymentBalance,
      transferred_tickets: transferredTickets,
      ticket_typification: type
    } = ticket
    const { name = '' } = fee

    return {
      id,
      name,
      code,
      company,
      companyId,
      serviceId,
      activity: serviceName || serviceTitle,
      value: Number(advancePaidValue || price || value),
      status: state,
      passagerName,
      payerName,
      type: name || type,
      date: formatISOTime(slot, 'DD/MM/YYYY - HH:mm', false),
      ticketPaymentBalance: (ticketPaymentBalance || []).map(ReservationTicketParser.ticketPaymentBalance),
      transferredTickets: (transferredTickets || []).map(ReservationTicketParser.transferredTickets)
    }
  },
  paymentTicketDetail (ticket = {}) {
    const {
      id,
      ticket_typification: type,
      ticket_code: code,
      company_name: company,
      service_title: activity,
      passenger_name: passagerName,
      payer_name: payerName,
      state,
      slot,
      historic,
      transfers,
      paid_value: paidValue
    } = ticket

    return {
      id,
      type,
      code,
      company,
      activity,
      value: paidValue || Number(historic?.ticket_price),
      status: state,
      passagerName,
      payerName,
      date: formatISOTime(slot, 'DD/MM/YYYY - HH:mm', false),
      ticketPaymentBalance: (Array(historic) || []).map(ReservationTicketParser.ticketPaymentBalance),
      transferredTickets: (transfers || []).map(ReservationTicketParser.transferredTickets)
    }
  },
  refundTicketDetail (ticket = {}) {
    const {
      id,
      ticket_typification: type,
      ticket_code: code,
      company_name: company,
      service_title: activity,
      passenger_name: passagerName,
      payer_name: payerName,
      state,
      slot,
      historic,
      paid_value: paidValue
    } = ticket

    return {
      id,
      type,
      code,
      company,
      activity,
      value: paidValue || Number(historic?.ticket_price),
      status: state,
      passagerName,
      payerName,
      date: formatISOTime(slot, 'DD/MM/YYYY - HH:mm', false)
    }
  },
  transferredTickets (transferredTicket) {
    const {
      id,
      ticket_code: code,
      ticket_typification: type,
      passenger_name: passagerName,
      payer_name: payerName,
      company_name: company,
      service_title: activity,
      paid_value: value,
      state: status
    } = transferredTicket

    return {
      id,
      code,
      type,
      passagerName,
      payerName,
      company,
      activity,
      value,
      status
    }
  },
  ticketPaymentBalance (ticketBalance) {
    const {
      already_paid_ticket_value: alreadyPaidTicketValue,
      refundable_ticket_value: refundableTicketValue,
      historic,
      remaining_ticket_value: remainingTicketValue,
      ticket_id: ticketId,
      ticket_price: ticketPrice
    } = ticketBalance

    return {
      alreadyPaidTicketValue: Number(alreadyPaidTicketValue),
      refundableTicketValue: Number(refundableTicketValue),
      historic,
      remainingTicketValue: Number(remainingTicketValue),
      ticketId,
      ticketPrice: Number(ticketPrice)
    }
  },
  ticketPaymentBalanceToRefund (ticketBalance) {
    const {
      already_paid_ticket_value: alreadyPaidTicketValue,
      refundable_ticket_value: refundableTicketValue,
      historic,
      remaining_ticket_value: remainingTicketValue,
      ticket_id: ticketId,
      ticket_price: ticketPrice
    } = ticketBalance

    return {
      alreadyPaidTicketValue: Number(alreadyPaidTicketValue) || Number(refundableTicketValue),
      refundableTicketValue: Number(refundableTicketValue),
      historic,
      remainingTicketValue: Number(remainingTicketValue),
      ticketId,
      ticketPrice: Number(ticketPrice)
    }
  },
  ticketToPayFromVendor (ticket = {}) {
    const {
      description: { fee = {} } = {},
      id,
      state,
      ticket_code: code,
      company_name: company,
      service_name: serviceName,
      service_title: serviceTitle,
      value_to_vendor: valueToVendor,
      payer_name: passagerName
    } = ticket
    const { name = '' } = fee

    return {
      id,
      name,
      code,
      company,
      activity: serviceName || serviceTitle,
      value: Number(valueToVendor),
      status: state,
      passagerName,
      type: name
    }
  },
  partnershipTickets (ticket = {}) {
    const {
      description: {
        fee:
        {
          name: type,
          taxes: { guide }
        },
        commercial_partner_taxes
      } = {},
      id,
      price,
      state,
      ticket_code: code,
      company_name: company,
      company_id: companyId,
      service_name: serviceName,
      service_title: serviceTitle,
      passenger_name: passagerName,
      payer_name: payerName,
      commission_value: commissionValue,
      ticket_payment_balance: ticketPaymentBalance
    } = ticket

    const { advance_already_paid_ticket_value: advanceAlreadyPaidTicketValue } = head(ticketPaymentBalance)

    return {
      id,
      code,
      company,
      companyId,
      activity: serviceName || serviceTitle,
      value: Number(advanceAlreadyPaidTicketValue || price),
      status: state,
      passagerName,
      payerName,
      type,
      commissionValue,
      commercialPartnerTaxes: commercial_partner_taxes,
      ticketTaxes: guide,
      isRefund: true
    }
  }
}

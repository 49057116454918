/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Card, Pills, Pill, colors, BREAK_POINTS, Row, Column, DatePicker, InputFormGroup, Select } from '@bonitour/components'
import { reservationTimelineFiltersLabels, reservationTimelineFiltersEventTypes } from './ReservationTimeline.constant'
import { ReservationTimelineIcon } from './ReservationTimelineIcon'
import { useCallback, useMemo } from 'react'
import { SafeDate } from '@binamik/js-providers'

const customActiveCss = css`
  background-color: ${colors.indigo2};
  color: ${colors.white};
`

const customPillsCss = css`
  user-select: none;
  overflow-x: hidden;
  width: auto;

  &.disallowed > div {
    cursor: default;
    background-color: ${colors.gray10};
  }

  @media screen and (max-width: ${BREAK_POINTS.desktop}) {
    flex-direction: column;
    width: 100%;
  }
`

const customPillCss = css`
  width: auto;
  padding: 10px 20px;
  @media screen and (max-width: ${BREAK_POINTS.desktop}) {
    width: 100%;
    padding-left: 2rem;
    place-content: flex-start;
  }
`

const MAX_DATE = new SafeDate().jsDate

export const ReservationTimelineFilters = ({
  form,
  errors,
  isLoading = false,
  utils: {
    onBlur,
    onChange
  }
}) => {
  const reservationTimelineItemTypesOpts = useMemo(() => Object.keys(reservationTimelineFiltersLabels).map(key => ({
    name: reservationTimelineFiltersLabels[key],
    mode: key
  })), [])

  const eventTypesOption = useMemo(() => [
    { value: '', label: 'Todos' },
    ...Object.keys(reservationTimelineFiltersEventTypes).map((key) => ({
      value: key,
      label: reservationTimelineFiltersEventTypes[key]
    }))
  ], [])

  const {
    finalDate,
    initialDate,
    itemTypes,
    eventType
  } = form

  const onChangeItemTypes = useCallback((itemType) => {
    if (isLoading) return
    const isAllItemTypesSelected = itemTypes?.length === reservationTimelineItemTypesOpts.length

    if (isAllItemTypesSelected) {
      return onChange('itemTypes')([itemType])
    }

    const hasInsideOfItemTypes = itemTypes?.includes(itemType)
    if (hasInsideOfItemTypes) {
      const filteredItemTypes = itemTypes?.filter((item) => item !== itemType)
      if (!filteredItemTypes.length) return
      return onChange('itemTypes')(filteredItemTypes)
    }
    onChange('itemTypes')([...(itemTypes || []), itemType])
  }, [isLoading, itemTypes, onChange, reservationTimelineItemTypesOpts.length])

  const minDateFinalDate = useMemo(() => new SafeDate(initialDate).jsDate, [initialDate])

  const maxDateInitalDate = useMemo(() => new SafeDate(finalDate).jsDate, [finalDate])

  return <div>
    <Card>
      <Row>
        <Pills customCss={[customPillsCss]} className={isLoading && 'disallowed'}>
          {reservationTimelineItemTypesOpts.map((action, index) => (
            <Pill
              label={action.name}
              active={itemTypes?.includes(action.mode) && !isLoading}
              onClick={() => onChangeItemTypes(action.mode)}
              icon={<ReservationTimelineIcon mode={action.mode} />}
              key={`reservation-timeline-filter-${index}`}
              customActiveCss={customActiveCss}
              customCss={[customPillCss]}
            />
          ))}
        </Pills>
      </Row>
      <Row>
        <Column phone={12} desktop={4}>
          <InputFormGroup label='Data inicial' errorMessage={errors.initialDate}>
            <DatePicker
              id='initialDate'
              value={initialDate}
              onChange={onChange('initialDate')}
              onBlur={onBlur('initialDate')}
              disabled={isLoading}
              maxDate={maxDateInitalDate}
              fullWidth
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={4}>
          <InputFormGroup label='Data final' errorMessage={errors.finalDate}>
            <DatePicker
              id='finalDate'
              value={finalDate}
              onChange={onChange('finalDate')}
              onBlur={onBlur('finalDate')}
              disabled={isLoading}
              minDate={minDateFinalDate}
              maxDate={MAX_DATE}
              fullWidth
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={4}>
          <InputFormGroup label="Evento" errorMessage={errors.eventType}>
            <Select
              options={eventTypesOption}
              onBlur={onBlur('eventType')}
              onChange={onChange('eventType')}
              value={eventType}
              disabled={isLoading}
            />
          </InputFormGroup>
        </Column>
      </Row>
    </Card>
  </div >
}

import { scrollBar, colors } from '@bonitour/components'
const { css } = require('@emotion/core')

export const limitContainer = css`
  max-height: calc(100vh - 450px);
  min-height: 250px;
  overflow-y: scroll;
  position: relative;
  ${scrollBar(5)}
`

export const amountCard = css`
  min-width: 150px;
  display: inline-flex;
  margin-right: 20px;
  padding: 20px;
`

export const amountLabel = css`
  font-size: 12px;
  color: ${colors.gray5};
  margin-bottom: 5px;
`

export const amountStyle = css`
  font-size: 24px;
  color: ${colors.red1};
  font-weight: bold;
`

export const reduceAllTicketsDialog = css`
  color: ${colors.gray1};
  p {
    margin: 3px 0;
  }
  label {
    margin: 10px 0;
    font-weight: 500;
    & ~ div {
      width: 130px;
      margin-bottom: 20px;
    }
  }
  button {
    margin-right: 20px;
  }
`

export const reduceAllTicketsButton = css`
  height: auto;
  margin: 10px 20px auto auto;
  &:not([disabled]) {
    color: ${colors.primary};
    border-color: ${colors.primary};
  }
`

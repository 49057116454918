/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { BREAK_POINTS, Button, Card, colors, flexHorizontallyCentered, InfoIcon, scrollBar, TableBody, TableBodyCell, TableBodyRow, TableContainer, TableHeader, TableHeaderCell, TableHeaderRow } from '@bonitour/components'
import { marginBottom, marginTop } from 'assets/styles/global'
import { formatMoney, head, identity } from '@bonitour/common-functions'

const { REACT_APP_HELP_URL } = process.env

const marginTop20 = marginTop(20)
const marginBottom30 = marginBottom(30)

const infoLabel = css`
  color: ${colors.gray3};

  a {
    color: ${colors.primary};
  }

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    font-size: 12px;
  }
`

const infoIconStyle = css`
  width: 10px;
  min-width: 10px;
  height: 10px;
  margin-left: 5px;
  margin-right: 10px;
  padding: 3px;
  border: 1px solid black;
  border-radius: 50%;
  color: ${colors.gray3};
`

const tableContainer = css`
  overflow: auto;
  max-height: 330px;
  ${scrollBar(5)};
`

const valueStyle = css`
  color: ${colors.green1};
`

export const ReservationBalanceCanceledTickets = ({
  companyId,
  tickets = [],
  isTicketsFree = false,
  onBalanceTransferClick: emitBalanceTransferClick = identity
}) => {
  const label = isTicketsFree
    ? 'Estes são os ingressos com saldo que podem ser transferidos para qualquer outro novo ingresso. Qualquer dúvida, acesse a nossa '
    : `Estes são os ingressos com saldo que podem ser transferidos para novos ingressos da empresa ${head(tickets)?.company}. Qualquer dúvida, acesse a nossa `

  return (
    <>
      <Card>
        <div css={[flexHorizontallyCentered, marginBottom30]}>
          <InfoIcon css={infoIconStyle} />
          <span css={infoLabel}>{label}<a href={REACT_APP_HELP_URL} target='_blank' rel='noopener noreferrer'>FAQ.</a></span>
        </div>
        <div css={tableContainer}>
          <TableContainer>
            <TableHeader>
              <TableHeaderRow>
                <TableHeaderCell>Ingresso</TableHeaderCell>
                <TableHeaderCell>Empresa</TableHeaderCell>
                <TableHeaderCell>Experiência</TableHeaderCell>
                <TableHeaderCell>Data da experiência</TableHeaderCell>
                <TableHeaderCell>Valor</TableHeaderCell>
              </TableHeaderRow>
            </TableHeader>
            <TableBody>
              {tickets.map(({ id, ticketCode, company, activity, date, value }) => (
                <TableBodyRow key={id}>
                  <TableBodyCell>{ticketCode}</TableBodyCell>
                  <TableBodyCell>{company}</TableBodyCell>
                  <TableBodyCell>{activity}</TableBodyCell>
                  <TableBodyCell>{date}</TableBodyCell>
                  <TableBodyCell css={valueStyle}>{formatMoney(value)}</TableBodyCell>
                </TableBodyRow>
              ))}
            </TableBody>
          </TableContainer>
        </div>
      </Card>

      <Button css={marginTop20} onClick={emitBalanceTransferClick(companyId, tickets)}>Transferir saldo</Button>
    </>
  )
}

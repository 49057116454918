export const reservationHistoryItemTypeOptions = [
  {
    label: 'Atualizações de pagamentos do ingresso',
    value: 'Financial::ReservationPayment'
  },
  {
    label: 'Atualizações de reserva',
    value: 'TicketSeller::Reservation'
  },
  {
    label: 'Atualizações de ingresso',
    value: 'TicketSeller::Ticket'
  },
  {
    label: 'Atualizações de responsável da reserva',
    value: 'TicketSeller::Payer'
  }
]

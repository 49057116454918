import { useCallback, useState } from 'react'
import { useCompany } from 'contexts/Company'
import { CashierHandlingService } from 'core/services/CashierHandling'

export const useCreateCashierHandling = () => {
  const { id: companyId } = useCompany()
  const [loading, setLoading] = useState(false)

  const createCashierHandling = useCallback((formValues) => {
    setLoading(true)

    return CashierHandlingService.create({
      ...formValues,
      companyId
    }).finally(() => setLoading(false))
  }, [companyId])

  return {
    submit: createCashierHandling,
    loading
  }
}

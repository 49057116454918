import { apiPaymentMethods } from 'constants/paymentMethods'

export const bankAccountBase = {
  accountName: '',
  bankCode: undefined,
  bankName: '',
  agencyNumber: undefined,
  agencyDigit: undefined,
  accountNumber: undefined,
  accountDigit: undefined,
  accountType: 'checking_account',
  enabled: true,
  fees: [
    { paymentMethod: apiPaymentMethods.bank_billet, value: 0 },
    { paymentMethod: apiPaymentMethods.bank_transfer, value: 0 },
    { paymentMethod: apiPaymentMethods.pix, value: 0 },
    { paymentMethod: apiPaymentMethods.cash, value: 0 }
  ]
}

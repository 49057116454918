import { colorSequence } from 'utils/color-sequence'
import { parseAttribute } from 'services/Orb/Service'
import { parsePeriods } from 'core/parsers/periods'
import { convertDate } from 'utils/convertDate'
import { getUniqueValues } from '@bonitour/common-functions'

function IntervalListParser (intervalList = []) {
  return intervalList.map(({ label: name, initial_date: initialDate, final_date: finalDate }) => ({
    name,
    initialDate: convertDate(initialDate),
    finalDate: convertDate(finalDate)
  }))
}

function getVacancyTotal (vacancies = {}) {
  const total = Object.values(vacancies)
    .reduce((acc, curr) => ((acc + Object.values(curr)
      .reduce((acc, curr) => ((acc + Number(curr))), 0)
    )), 0)
  return total || 0
}

const scheduleHourMerge = (vacancies = {}) => {
  const hours = Object.values(vacancies)
    .reduce((acc, curr) => ((acc.concat(Object.keys(curr))
      .reduce((acc, curr) => ((acc.concat(curr))), [])
    )), [])

  return getUniqueValues(hours)
}

export function VacancyListItemParser (vacancyItem = {}, index) {
  const { date_ranges: dateRanges, daily_limit: dailyLimit, id, name, vacancies = {} } = vacancyItem
  return {
    id,
    color: colorSequence[index],
    name,
    dailyLimit,
    intervalList: IntervalListParser(dateRanges),
    vacancyQuantity: getVacancyTotal(vacancies),
    periodQuantity: dateRanges.length || 0,
    schedulesQuantity: (scheduleHourMerge(vacancies) || {}).length
  }
}

export const parseVacancyList = vacancyList => vacancyList.map(parseAttribute(VacancyListItemParser))

const VacancyParser = (vacancy = {}) => {
  const { name, daily_limit: dailyLimit = 0, date_ranges: dateRanges = [], vacancies = {} } = vacancy

  const dayKeys = ['sundayQuantity', 'mondayQuantity', 'tuesdayQuantity', 'wednesdayQuantity', 'thursdayQuantity', 'fridayQuantity', 'saturdayQuantity']

  const VacancyScheduleDaysParser = hour => {
    return dayKeys.reduce(
      (acc, day, index) => {
        return { ...acc, [day]: vacancies[index] ? (vacancies[index][hour] || 0) : 0 }
      },
      {
        time: hour,
        vacancyQuantity: 0
      }
    )
  }

  const hours = scheduleHourMerge(vacancies)
  const scheduleList = hours.map(VacancyScheduleDaysParser)

  const periodList = (dateRanges || []).map(parsePeriods)
  return {
    activityVacancy: {
      vacancyName: name,
      dailyLimit: Number(dailyLimit)
    },
    scheduleList,
    periodList
  }
}

export function parseVacancy (payload) {
  return parseAttribute(VacancyParser)(payload)
}

import { css } from '@emotion/core'
import { marginBottom, marginTop, width } from 'assets/styles/global'

export const marginTop15 = marginTop(15)
export const marginTop10 = marginTop(10)
export const marginBottom10 = marginBottom(10)
export const width80 = width(70)

export const feePaxTypeCard = css`
  width: 150px;
  max-height: 650px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;

  transition: all 300ms;
`

export const inputLabel = css`
  label {
    font-size: initial;
    font-weight: initial;
  }
  justify-content: center;
  flex-grow: 0;
`

export const modificator = css`
  width: 80px;
  text-align: center;
  padding: 10px 5px;
  width: 60px;
`

export const inputStyle = css`
  width: 120px;
`

export const agePermissionTitle = css`
  text-align: center;
  margin: 10px 0 5px 0;
`

export const ageValuesContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const horinzontalMargin10 = css`
  margin: 0 10px;
`

export const guideContainer = css`
  display: flex;
  justify-content: center;
`

export const guidePercentual = css`
  justify-content: center;
  display: flex;
  width: 80%;
  margin: 0 auto;
`

export const guideValue = css`
  width: 80%;
  display: flex;
  margin: 0 auto;
`

export const finalValueGuide = css`
  text-align: center;
`

export const toolTipHeight = css`
  max-height: 265px;
`

export const activeTooltipHeight = css`
  max-height: 770px;
`

export const activeHeight = css`
  height: min-content;
`

export const disabledHeight = css`
  max-height: 150px;
`
export const guideAnimation = css`
  max-height: 0;
  transition: all 300ms;
  overflow: hidden;

  &.open {
    max-height: 650px;
    overflow: visible;
  }
`

export const privateHeight = css`
  max-height: 740px;
`
export const fontSize28 = css`
  font-size: 28px;
`

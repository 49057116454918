import { LocalitiesService } from './Service'
import { LocalityListParser } from './Parser'

function Api () {
  function list (countryId) {
    const stateListResource = `/v1/countries/${countryId}/states`
    return LocalitiesService.get(stateListResource).then(({ data }) => LocalityListParser(data))
  }

  return { list }
}

export const StateApi = Api()

import { keyframes } from '@emotion/core'

export const pulse = keyframes`
    0%  {
        transform: scale(0.1);
    }
    50%  {
        transform: scale(0.7);
    }
    100%  {
        transform: scale(1);
    }
`

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { BREAK_POINTS, colors, H3, HorizontalInputFormGroup, Input, InputWithSuffix, ToggleInputGroup } from '@bonitour/components'
import { marginBottom, marginLeft, marginTop } from 'assets/styles/global'
import { identity } from '@bonitour/common-functions'
import { useCallback, useEffect } from 'react'
import { useFeatureFlag } from 'contexts/Feature'
import { CustomComissions } from './CustomComissions'

const marginTop10 = marginTop(10)
const marginTop20 = marginTop(20)
const marginLeft10 = marginLeft(10)
const marginBottom10 = marginBottom(10)
const marginBottom20 = marginBottom(20)

const grayColor = css`
  color: ${colors.gray3};
`

const width110 = css`
  width: 110px;
`

const sellViaAllyContainer = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 320px;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    width: 100%;
  }
`

export const TradeAgreement = ({
  tradeAgreement = {},
  errors = {},
  savedCustomComissions = [],
  onUpdateCustomComissions = () => identity,
  onInputChange = () => identity,
  onInputBlur = () => identity,
  isLoading = false,
  isGroupEditable = false,
  isFormDisabled = false
}) => {
  const [isNetValueEnabled] = useFeatureFlag('orb-net-value')
  const [isSaleViaAllayEnabled] = useFeatureFlag('orb-sell-via-ally')

  const {
    name = '',
    commissioning = '',
    sellViaAlly = true,
    netFeeEnabled = false
  } = tradeAgreement

  const toggleAlly = useCallback(() => onInputChange('sellViaAlly')(!sellViaAlly), [onInputChange, sellViaAlly])
  const toggleNetFee = useCallback(() => onInputChange('netFeeEnabled')(!netFeeEnabled), [onInputChange, netFeeEnabled])

  const isNoCommissionGroup = (name === 'no_commission_group' || name === 'Meu grupo')

  useEffect(() => {
    if (isNoCommissionGroup) onInputChange('name')('Meu grupo')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name])

  return (
    <div>
      <>
        <H3 css={marginBottom20}>Nome do grupo</H3>
        <HorizontalInputFormGroup errorMessage={errors.name}>
          <Input
            id='name'
            value={name}
            disabled={(!isGroupEditable || isFormDisabled)}
            onChange={onInputChange('name')}
            onBlur={onInputBlur('name')}
          />
        </HorizontalInputFormGroup>
      </>

      <H3 css={[marginTop20, marginBottom10]}>Comissionamento padrão</H3>

      {isNetValueEnabled
        ? (
          <div>
            <HorizontalInputFormGroup errorMessage={errors.netFeeEnabled}>
              <ToggleInputGroup
                disabled={isFormDisabled}
                onChange={toggleNetFee}
                checked={netFeeEnabled}
              />
            </HorizontalInputFormGroup>
            <span css={grayColor}>Tarifa Net</span>
          </div>
        )
        : null}

      <span css={grayColor}>A porcentagem será de</span>
      <HorizontalInputFormGroup errorMessage={errors.commissioning}>
        <InputWithSuffix
          min='0'
          type='number'
          disabled={(!isGroupEditable || isFormDisabled)}
          value={commissioning}
          css={[marginTop10, marginLeft10, width110]}
          onChange={onInputChange('commissioning')}
          onBlur={onInputBlur('commissioning')}
        >%
        </InputWithSuffix>
      </HorizontalInputFormGroup>

      {!isLoading
        ? (
          <CustomComissions
            initialData={savedCustomComissions}
            onChange={onUpdateCustomComissions}
            baseComission={commissioning}
            isNetFeeDefault={netFeeEnabled}
            isDisabled={isFormDisabled}
            isGroupEditable={isGroupEditable}
          />
        )
        : null}

      {
        isSaleViaAllayEnabled
          ? (
            <>
              <H3 css={[marginTop20, marginBottom20]}>Venda no aplicativo Ally</H3>
              <div css={sellViaAllyContainer}>
                <HorizontalInputFormGroup errorMessage={errors.sellViaAlly}>
                  <ToggleInputGroup disabled={isFormDisabled} onChange={toggleAlly} checked={sellViaAlly} />
                </HorizontalInputFormGroup>
                <span css={grayColor}>Permitir a venda no aplicativo Ally</span>
              </div>
            </>
          )
          : null}
    </div>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { reservationStatusDictionary, reservationStatusOptions, StatusEnum } from 'constants/reservationsStatus'
import { useMemo, useState, memo, useEffect, useCallback } from 'react'
import { InputFormGroup, DatePicker, Input, Select, Card, Row, Column, H4, flexHorizontallyCentered, Button, MaskedInputWithPrefix, CircleThumbnail, ConfirmDialog, Textarea, InputMoneyMask, InputWithPrefix, MaskedInputWithSuffix, LoadingAnimation, GhostButton, Label, Email, flexColumn, useToast, RadioInputGroup, RadioGroup, PhoneSelector, InputHeightMask, InputWeightMask, InputZipCodeMask } from '@bonitour/components'
import { head, identity, maskCpf } from '@bonitour/common-functions'
import { capitalize, loadingContainer, width100 } from 'assets/styles/global'
import { cardContrast, documentLabel, buttonsContainer, marginTop20, marginTop40, marginBottom20, marginRight10, observations, fullWidth, ghostButtonPrimary, sendEmailLink, inputButton, label, reservationPassengerContainer, addPayerLabel, marginHorizontal15, marginLeft30, inputStyle, fiscalDutyCss } from './EditReservation.style'
import { EDIT_RESERVATION, SEND_EMAIL, editStates } from './edit.states'
import { useKeyPress } from 'hooks/useKeyPress'
import { useForm } from '@bonitour/app-functions'
import { string } from 'yup'
import { useCompany } from 'contexts/Company'
import moment from 'moment'
import { PassengerInfo } from 'domains/Reservation/Passenger/PassengerInfo'
import { useZipCode } from 'containers/Address/Address.hooks'
import { FiscalDuty } from 'domains/CompanyReports/Revenue/Table/RevenueTable'

const ReservationStatus = memo(({ status }) => {
  const { label = '', color } = reservationStatusDictionary[status] || {}

  return (
    <InputWithPrefix readOnly value={label}>
      <CircleThumbnail size={10} style={{ backgroundColor: color }} />
    </InputWithPrefix>
  )
})

const Dialog = ({ dialogData = {}, isVisible = false }) => {
  const {
    successCallback = identity,
    cancelCallback = identity,
    title = '',
    message = '',
    buttonLabelConfirm = '',
    buttonLabelCancel = ''
  } = dialogData

  return (
    <ConfirmDialog
      title={title}
      message={message}
      isVisible={isVisible}
      successCallback={successCallback}
      cancelCallback={cancelCallback}
      buttonLabelConfirm={buttonLabelConfirm}
      buttonLabelCancel={buttonLabelCancel}
    />
  )
}

const passengerSearchInitial = {}

const passengerSearchSchema = { emailSearch: string().email('O e-mail deve ser válido') }

const EditReservationButtons = ({
  isFormDisabled,
  isSendEmail = false,
  isSendEmailLoading = false,
  isEditButtonDisabled = false,
  isSaveButtonDisabled = false,
  onClickEditForm = identity,
  onClickCancelForm = identity,
  onClickSaveForm = identity,
  onClickSendEmail = identity
}) => {
  return (
    <div css={buttonsContainer}>
      {isFormDisabled && !isEditButtonDisabled && <GhostButton css={[ghostButtonPrimary(), marginRight10]} onClick={onClickEditForm}>Editar</GhostButton>}
      {!isFormDisabled && <GhostButton css={marginRight10} onClick={onClickCancelForm}>Cancelar</GhostButton>}
      {!isFormDisabled && <Button onClick={onClickSaveForm} disabled={isSaveButtonDisabled}>Salvar</Button>}
      {isSendEmail && isFormDisabled && !isSendEmailLoading && <Label css={sendEmailLink} onClick={onClickSendEmail}>Enviar reserva por e-mail</Label>}
      {isSendEmail && isFormDisabled && isSendEmailLoading && <LoadingAnimation size={25} css={marginLeft30} />}
    </div>
  )
}

export const EditReservation = ({
  onStatusEdition: emitStatusEditionEvent = identity,
  editStatusReservationLoading = false,
  reservationInfo = {},
  reservationId = '',
  reservationTotalPrice = '',
  passengersFound = [],
  sendEmailLoading,
  searchPassengerLoading,
  changePayerLoading,
  reservationInfoErrors: errors,
  countries,
  states,
  cities,
  updateAvailableStates,
  updateAvailableCities,
  onChange: emitChangeEvent = identity,
  onBlur: emitBlurEvent = identity,
  setForm: emitSetForm = identity,
  onPayerInfoEdition: emitPayerInfoEditionEvent = identity,
  sendReservationConfirmEmail: emitSendReservationConfirmEmail = identity,
  onSearchPassengers: emitSearchPassengerSubmit = identity,
  updateReservation: emitUpdateReservation = identity,
  changePayer: emitChangePayer = identity,
  onCreateLinkPayer: emitCreateLinkPayer = identity,
  onEditLinkPayer: emitEditLinkPayer = identity
}) => {
  const {
    payerId = '',
    payerUserId,
    accountableId,
    createdAt,
    sellingChannel = '',
    name = '',
    email = '',
    status = '',
    identificationDocument = '',
    number = '',
    birthDate,
    comments = '',
    country,
    state,
    city,
    emergencyName,
    emergencyTelephone,
    height,
    weight,
    zipCode,
    street,
    supplement,
    district,
    fiscalDuty = ''
  } = reservationInfo
  const { onKeyDown } = useKeyPress()
  const { add: addToast } = useToast()
  const { id: companyId } = useCompany()
  const [statusSelected, setStatusSelected] = useState('')
  const [isConfirmActionVisible, setConfirmActionVisible] = useState(false)
  const [dialogType, setDialogType] = useState('')

  const [isSearchClicked, setSearchClick] = useState(false)
  const [passengerSelected, setPassengerSelected] = useState('')
  const { form, errors: passengerSearchErrors, onSubmit, utils: { onInputBlur, onInputChange } } = useForm(passengerSearchInitial, passengerSearchSchema)
  const { emailSearch = '' } = form

  const [isPayerFormDisabled, setPayerFormDisabled] = useState(true)
  const [canChangePayerForm, setChangePayerForm] = useState(false)
  const [isPayerSaveButtonDisabled, setPayerSaveButtonDisabled] = useState(true)

  const onClickEditPayerForm = () => setPayerFormDisabled(false)

  const onClickCancelPayerForm = () => {
    emitUpdateReservation()
    setSearchClick(false)
    setPassengerSelected('')
    setPayerFormDisabled(true)
    setChangePayerForm(false)
    onInputChange('emailSearch')(email)
  }

  const [isFormDisabled, setFormDisabled] = useState(true)
  const onClickEditForm = () => setFormDisabled(false)
  const onClickCancelForm = () => setFormDisabled(true)

  const passengersFoundLength = passengersFound.length
  const isPassengersFound = passengersFoundLength > 0

  const onValidationError = () => addToast('Preencha corretamente o formulário')

  const onPassengerSearchSuccess = ({ emailSearch }) => {
    if (!emailSearch) return addToast('Informe um e-mail')
    setSearchClick(true)
    cleanReservationTypeForm()
    emitSearchPassengerSubmit({ email: emailSearch })
  }

  const onSearchPassenger = onSubmit(onPassengerSearchSuccess, onValidationError)

  const cleanReservationTypeForm = () => {
    emitChangeEvent('payerId')('')
    emitChangeEvent('payerUserId')('')
    emitChangeEvent('name')('')
    emitChangeEvent('email')('')
    emitChangeEvent('birthDate')('')
    emitChangeEvent('identificationDocument')('')
    emitChangeEvent('number')('')
    emitChangeEvent('country')('')
    emitChangeEvent('state')('')
    emitChangeEvent('city')('')
    emitChangeEvent('emergencyName')('')
    emitChangeEvent('emergencyTelephone')('')
    emitChangeEvent('height')('')
    emitChangeEvent('weight')('')
  }

  const mergeSelectedPassengerWithReservationPayer = passengerId => {
    const passenger = passengersFound.filter(({ id }) => id === passengerId)
    const { id: payerId, userId: payerUserId, fullName, email, birthDate, document, phone, country, state, city, emergencyName, emergencyTelephone, height, weight } = head(passenger) || {}

    cleanReservationTypeForm()
    payerId && emitChangeEvent('payerId')(payerId)
    payerUserId && emitChangeEvent('payerUserId')(payerUserId)
    fullName && emitChangeEvent('name')(fullName)
    email && emitChangeEvent('email')(email)
    birthDate && emitChangeEvent('birthDate')(moment(birthDate))
    document && emitChangeEvent('identificationDocument')(document)
    phone && emitChangeEvent('number')(phone)
    country && emitChangeEvent('country')(country)
    state && emitChangeEvent('state')(state)
    city && emitChangeEvent('city')(city)
    emergencyName && emitChangeEvent('emergencyName')(emergencyName)
    emergencyTelephone && emitChangeEvent('emergencyTelephone')(emergencyTelephone)
    height && emitChangeEvent('height')(height)
    weight && emitChangeEvent('weight')(weight)
  }

  const onPassengerSelected = passengerId => {
    setPassengerSelected(passengerId)
    mergeSelectedPassengerWithReservationPayer(passengerId)
  }

  const onClickChangePayer = () => {
    if (canChangePayerForm) {
      const hasErrors = Boolean(Object.values(errors).find((item) => item !== ''))

      if (!name) return addToast('Informe o nome do responsável')
      if (hasErrors) return onValidationError(errors)

      payerId
        ? (!payerUserId && emitEditLinkPayer(reservationInfo, reservationId, emitChangePayer))
        : emitCreateLinkPayer(reservationInfo, reservationId, emitChangePayer)
    } else {
      emitChangePayer(reservationId, payerId)
    }

    setSearchClick(false)
    setPassengerSelected('')
    setPayerFormDisabled(true)
    setChangePayerForm(false)
  }

  const onSelectChange = status => {
    setStatusSelected(status)
    toggleConfirmActionVisible(EDIT_RESERVATION)
  }

  const onStatusChanged = () => {
    emitStatusEditionEvent(statusSelected)
    toggleConfirmActionHidden()
  }

  const onSendEmail = () => {
    const emailSendData = {
      reservationId,
      payerName: reservationInfo.name,
      email: reservationInfo.email
    }

    emitSendReservationConfirmEmail(emailSendData)
    toggleConfirmActionHidden()
  }

  const onClickEditReservationInfo = () => {
    emitPayerInfoEditionEvent()
    onClickCancelForm()
  }

  const toggleConfirmActionVisible = (type) => {
    setDialogType(type)
    setConfirmActionVisible(true)
  }
  const toggleConfirmActionHidden = () => setConfirmActionVisible(false)

  const confirmDialogInfo = useMemo(() => {
    if (statusSelected === StatusEnum.canceled) {
      return {
        message: 'Deseja cancelar a sua reserva? Todos os ingressos serão cancelados. Deseja continuar?',
        buttonLabelConfirm: 'Cancelar',
        buttonLabelCancel: 'Voltar'

      }
    }

    return {
      message: 'Você realmente deseja alterar o estado da sua reserva?',
      buttonLabelConfirm: 'Alterar',
      buttonLabelCancel: 'Voltar'
    }
  }, [statusSelected])

  const dialogEditStates = {
    [EDIT_RESERVATION]: {
      title: 'Alteração do estado da reserva',
      message: confirmDialogInfo.message,
      buttonLabelConfirm: confirmDialogInfo.buttonLabelConfirm,
      buttonLabelCancel: confirmDialogInfo.buttonLabelCancel,
      successCallback: () => onStatusChanged(),
      cancelCallback: toggleConfirmActionHidden
    },
    [SEND_EMAIL]: {
      ...editStates[SEND_EMAIL],
      successCallback: () => onSendEmail(),
      cancelCallback: toggleConfirmActionHidden
    }
  }

  const isConfirmed = status === 'confirmed'
  const isCanceled = status === 'canceled'
  const isSendButtonActive = !isCanceled
  const isCompanyOwner = accountableId === companyId

  const onCountryChange = useCallback((countryValue) => {
    const country = countries.find((country) => Object.values(country).includes(countryValue))
    if (country) {
      updateAvailableStates(country.value)
      emitChangeEvent('country')(country.value)
    } else {
      updateAvailableStates(null)
      emitChangeEvent('country')('')
      emitChangeEvent('state')('')
      emitChangeEvent('city')('')
    }
  }, [countries, updateAvailableStates, emitChangeEvent])

  const onStateChange = useCallback((stateValue) => {
    const state = states.find((state) => Object.values(state).includes(stateValue))
    if (state) {
      updateAvailableCities(country, state.value)
      emitChangeEvent('state')(state.value)
    } else {
      updateAvailableCities(null)
      emitChangeEvent('state')('')
      emitChangeEvent('city')('')
    }
  }, [states, updateAvailableCities, country, emitChangeEvent])

  useEffect(() => {
    email && onInputChange('emailSearch')(email)
    // eslint-disable-next-line
  }, [email])

  useEffect(() => {
    if (isSearchClicked && !searchPassengerLoading && !isPassengersFound) {
      cleanReservationTypeForm()
      emitChangeEvent('email')(emailSearch)
      setChangePayerForm(true)
    } else {
      setChangePayerForm(false)
    }
    // eslint-disable-next-line
  }, [isSearchClicked, searchPassengerLoading, isPassengersFound])

  useEffect(() => {
    !isPayerFormDisabled && (payerUserId ? setChangePayerForm(false) : setChangePayerForm(true))
    // eslint-disable-next-line
  }, [isPayerFormDisabled, passengerSelected, payerUserId])

  useEffect(() => {
    if (emailSearch !== email) return setPayerSaveButtonDisabled(true)
    setPayerSaveButtonDisabled(false)
    // eslint-disable-next-line
  }, [emailSearch, payerId, name, email, birthDate, identificationDocument, number, country, state, city])

  const onCityChange = useCallback(
    (cityValue) => {
      const city = cities.find((city) => Object.values(city).includes(cityValue))
      if (city) {
        emitChangeEvent('city')(city.value)
      } else {
        emitChangeEvent('city')('')
      }
    },
    [cities, emitChangeEvent]
  )

  const [requestedZipCode, requestZipCodeInfo] = useZipCode(addToast)

  const onZipCodeBlur = () => {
    requestZipCodeInfo(zipCode)
    emitBlurEvent('zipCode')()
  }

  const updateAddress = useCallback(() => {
    if (Object.keys(requestedZipCode).length) {
      emitSetForm(previousForm => ({
        ...previousForm,
        street: requestedZipCode.address,
        district: requestedZipCode.district,
        state: requestedZipCode.state,
        city: requestedZipCode.city
      }))
      onCountryChange('BR')
    }
  }, [onCountryChange, requestedZipCode, emitSetForm])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updateAddress, [requestedZipCode])

  const updateCountries = useCallback(() => {
    if (countries?.length) {
      onCountryChange(country)
    }
  }, [countries?.length, onCountryChange, country])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updateCountries, [countries])

  const updateStates = useCallback(() => {
    if (states?.length) {
      onStateChange(state)
    }
  }, [states?.length, onStateChange, state])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updateStates, [states])

  const updateCities = useCallback(() => {
    if (cities?.length) {
      onCityChange(city)
    }
  }, [cities?.length, onCityChange, city])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updateCities, [cities])

  const isFiscalDutyEditable = useMemo(
    () => companyId === accountableId,
    [companyId, accountableId]
  )

  return (
    <>
      <Dialog
        dialogData={dialogEditStates[dialogType]}
        isVisible={isConfirmActionVisible}
      />

      <div css={[flexHorizontallyCentered, marginBottom20]}>
        <H4 css={marginRight10}>Reserva</H4>
      </div>

      <Card css={cardContrast}>
        {editStatusReservationLoading
          ? <LoadingAnimation size={25} css={loadingContainer} />
          : (
            <>
              <Row>
                <Column desktop={2.4}>
                  <InputFormGroup label='Data de criação'>
                    <DatePicker css={width100} readOnly value={createdAt} />
                  </InputFormGroup>
                </Column>
                <Column desktop={2.4}>
                  <InputFormGroup label='Valor total'>
                    <InputMoneyMask readOnly value={reservationTotalPrice} />
                  </InputFormGroup>
                </Column>
                <Column desktop={2.4}>
                  <InputFormGroup label='Origem da venda'>
                    <Input readOnly css={capitalize} value={sellingChannel} />
                  </InputFormGroup>
                </Column>
                <Column desktop={2.4}>
                  <InputFormGroup label='Estado'>
                    {isFormDisabled || isConfirmed || isCanceled
                      ? <ReservationStatus status={status} />
                      : <Select onChange={onSelectChange} value={status} options={reservationStatusOptions} />}
                  </InputFormGroup>
                </Column>
                <Column desktop={2.4}>
                  <InputFormGroup label='Nota fiscal'>
                    <div css={fiscalDutyCss}>
                      <FiscalDuty
                        fiscalDuty={fiscalDuty}
                        isEditable={fiscalDuty && isFiscalDutyEditable}
                        reservationId={reservationId}
                        onUpdateFiscalDuty={emitUpdateReservation}
                      />
                    </div>
                  </InputFormGroup>
                </Column>
                <Column desktop={12}>
                  <InputFormGroup css={observations} errorMessage={errors.comments} label='Observações'>
                    <Textarea
                      value={comments}
                      onChange={emitChangeEvent('comments')}
                      onBlur={emitBlurEvent('comments')}
                      disabled={isFormDisabled}
                    />
                  </InputFormGroup>
                </Column>
              </Row>

              <EditReservationButtons
                isFormDisabled={isFormDisabled}
                onClickEditForm={onClickEditForm}
                onClickCancelForm={onClickCancelForm}
                onClickSaveForm={onClickEditReservationInfo}
              />
            </>
          )}
      </Card>

      <div css={[flexHorizontallyCentered, marginTop40, marginBottom20]}>
        <H4 css={marginRight10}>Cliente</H4>
      </div>

      <Card css={cardContrast}>
        {changePayerLoading
          ? <LoadingAnimation size={25} css={loadingContainer} />
          : (
            <>
              <Row>
                <Column desktop={6}>
                  {isPayerFormDisabled &&
                  <InputFormGroup errorMessage={passengerSearchErrors.emailSearch} label='E-mail'>
                    <Email value={emailSearch} disabled />
                  </InputFormGroup>}

                  {!isPayerFormDisabled &&
                  <InputFormGroup errorMessage={passengerSearchErrors.emailSearch} label='E-mail'>
                    <MaskedInputWithSuffix
                      type='emailSearch'
                      value={emailSearch}
                      onChange={onInputChange('emailSearch')}
                      onBlur={onInputBlur('emailSearch')}
                      placeholder='E-mail'
                      onKeyDown={event => onKeyDown(event, onSearchPassenger)}
                    >
                      {searchPassengerLoading
                        ? <LoadingAnimation size={23} css={[loadingContainer, marginHorizontal15]} />
                        : <GhostButton css={inputButton} onClick={onSearchPassenger}>Buscar</GhostButton>}
                    </MaskedInputWithSuffix>
                  </InputFormGroup>}
                </Column>

                {isSearchClicked && !searchPassengerLoading && isPassengersFound &&
                <Column phone={12} desktop={12}>
                  <div css={[flexColumn, marginTop20]}>
                    <p css={label}>{`${passengersFoundLength} viajante(s) vinculado(s) com este e-mail, selecione o responsável pela reserva:`}</p>
                    <div css={reservationPassengerContainer}>
                      <RadioGroup name='reservation-passengers-found' value={passengerSelected} onChange={onPassengerSelected}>
                        {passengersFound.map((passenger) => (
                          <RadioInputGroup key={passenger?.id} id={passenger?.id} value={passenger?.id}>{PassengerInfo(passenger)}</RadioInputGroup>
                        ))}
                      </RadioGroup>
                    </div>
                  </div>
                </Column>}

                {isSearchClicked && !searchPassengerLoading && !isPassengersFound &&
                <Column phone={12} desktop={12}>
                  <div css={[flexColumn, marginTop20]}>
                    <p css={label}>Nenhum viajante encontrado com este e-mail</p>
                    <p css={addPayerLabel}>Cadastrar um responsável</p>
                  </div>
                </Column>}

                <Column desktop={6}>
                  <InputFormGroup css={marginBottom20} errorMessage={errors.name} label='Nome'>
                    <Input
                      value={name}
                      onChange={emitChangeEvent('name')}
                      onBlur={emitBlurEvent('name')}
                      disabled={!canChangePayerForm}
                    />
                  </InputFormGroup>
                </Column>

                {isSearchClicked && !searchPassengerLoading &&
                <Column desktop={6}>
                  <InputFormGroup css={marginBottom20} errorMessage={errors.email} label='E-mail'>
                    <Email
                      type='email'
                      value={email}
                      onChange={emitChangeEvent('email')}
                      onBlur={emitBlurEvent('email')}
                      disabled={!canChangePayerForm}
                    />
                  </InputFormGroup>
                </Column>}

                <Column desktop={4}>
                  <InputFormGroup css={marginBottom20} errorMessage={errors.birthDate} label='Data de nascimento'>
                    <DatePicker
                      value={birthDate}
                      css={width100}
                      onChange={emitChangeEvent('birthDate')}
                      onBlur={emitBlurEvent('birthDate')}
                      allowsEmpty
                      disabled={!canChangePayerForm}
                    />
                  </InputFormGroup>
                </Column>
                <Column desktop={4}>
                  <InputFormGroup css={marginBottom20} errorMessage={errors.identificationDocument} label='Documento'>
                    <MaskedInputWithPrefix
                      value={identificationDocument}
                      error={errors.identificationDocument}
                      formatterFunction={maskCpf}
                      onChange={emitChangeEvent('identificationDocument')}
                      onBlur={emitBlurEvent('identificationDocument')}
                      disabled={!canChangePayerForm}
                    >
                      <div css={documentLabel}>CPF</div>
                    </MaskedInputWithPrefix>
                  </InputFormGroup>
                </Column>
                <Column desktop={4}>
                  <InputFormGroup css={marginBottom20} errorMessage={errors.number} label='Telefone'>
                    <PhoneSelector
                      customCss={[fullWidth]}
                      value={number}
                      error={errors.number}
                      onBlur={emitBlurEvent('number')}
                      onChange={emitChangeEvent('number')}
                      disabled={!canChangePayerForm}
                    />
                  </InputFormGroup>
                </Column>

                <Column phone={12} desktop={2}>
                  <InputFormGroup errorMessage={errors.zipCode} label='CEP'>
                    <InputZipCodeMask
                      id='zipCode'
                      value={zipCode}
                      error={errors.zipCode}
                      onChange={emitChangeEvent('zipCode')}
                      onBlur={onZipCodeBlur}
                      disabled={!canChangePayerForm}
                    />
                  </InputFormGroup>
                </Column>
                <Column phone={12} desktop={4}>
                  <InputFormGroup errorMessage={errors.street} label='Endereço'>
                    <Input
                      id='street'
                      value={street}
                      error={errors.street}
                      onChange={emitChangeEvent('street')}
                      onBlur={emitBlurEvent('street')}
                      disabled={!canChangePayerForm}
                    />
                  </InputFormGroup>
                </Column>
                <Column phone={12} desktop={2}>
                  <InputFormGroup errorMessage={errors.supplement} label='Número'>
                    <Input
                      id='supplement'
                      value={supplement}
                      error={errors.supplement}
                      onChange={emitChangeEvent('supplement')}
                      onBlur={emitBlurEvent('supplement')}
                      disabled={!canChangePayerForm}
                    />
                  </InputFormGroup>
                </Column>
                <Column phone={12} desktop={4}>
                  <InputFormGroup errorMessage={errors.district} label='Bairro'>
                    <Input
                      id='district'
                      value={district}
                      error={errors.district}
                      onChange={emitChangeEvent('district')}
                      onBlur={emitBlurEvent('district')}
                      disabled={!canChangePayerForm}
                    />
                  </InputFormGroup>
                </Column>

                <Column phone={12} desktop={4}>
                  <InputFormGroup label='Pais' errorMessage={errors.country}>
                    <Select
                      options={countries}
                      value={country}
                      onChange={onCountryChange}
                      onBlur={emitBlurEvent('country')}
                      placeholder='Selecione ou digite o país'
                      disabled={!canChangePayerForm}
                    />
                  </InputFormGroup>
                </Column>
                <Column phone={12} desktop={4}>
                  <InputFormGroup label='Estado' errorMessage={errors.state}>
                    <Select
                      options={states}
                      value={state}
                      onChange={onStateChange}
                      onBlur={emitBlurEvent('state')}
                      placeholder='Selecione ou digite o estado'
                      disabled={!canChangePayerForm}
                    />
                  </InputFormGroup>
                </Column>
                <Column phone={12} desktop={4}>
                  <InputFormGroup label='Cidade' errorMessage={errors.city}>
                    <Select
                      options={cities}
                      value={city}
                      onChange={emitChangeEvent('city')}
                      onBlur={emitBlurEvent('city')}
                      placeholder='Selecione ou digite a cidade'
                      disabled={!canChangePayerForm}
                    />
                  </InputFormGroup>
                </Column>
              </Row>
              <Row>
                <Column phone={12} desktop={4}>
                  <InputFormGroup label='Nome do contato de emergência' errorMessage={errors.emergencyName}>
                    <Input
                      value={emergencyName}
                      placeholder='Digite o nome completo do contato de emergência'
                      disabled={!canChangePayerForm}
                      onChange={emitChangeEvent('emergencyName')}
                      onBlur={emitBlurEvent('emergencyName')}
                    />
                  </InputFormGroup>
                </Column>
                <Column phone={12} desktop={4}>
                  <InputFormGroup label='Telefone do contato de emergência' errorMessage={errors.emergencyTelephone}>
                    <PhoneSelector
                      customCss={[fullWidth]}
                      value={emergencyTelephone}
                      disabled={!canChangePayerForm}
                      onChange={emitChangeEvent('emergencyTelephone')}
                      onBlur={emitBlurEvent('emergencyTelephone')}
                    />
                  </InputFormGroup>
                </Column>
                <Column phone={6} desktop={2}>
                  <InputFormGroup label='Altura' errorMessage={errors?.height}>
                    <InputHeightMask
                      value={height}
                      css={[inputStyle, fullWidth]}
                      disabled={!canChangePayerForm}
                      onChange={emitChangeEvent('height')}
                      onBlur={emitBlurEvent('height')}
                    />
                  </InputFormGroup>
                </Column>
                <Column phone={6} desktop={2}>
                  <InputFormGroup label='Peso' errorMessage={errors.weight}>
                    <InputWeightMask
                      value={weight}
                      css={[inputStyle, fullWidth]}
                      disabled={!canChangePayerForm}
                      onChange={emitChangeEvent('weight')}
                      onBlur={emitBlurEvent('weight')}
                    />
                  </InputFormGroup>
                </Column>
              </Row>

              <EditReservationButtons
                isFormDisabled={isPayerFormDisabled}
                isSendEmail={isSendButtonActive}
                isSendEmailLoading={sendEmailLoading}
                isSaveButtonDisabled={isPayerSaveButtonDisabled}
                isEditButtonDisabled={!isCompanyOwner}
                onClickEditForm={onClickEditPayerForm}
                onClickCancelForm={onClickCancelPayerForm}
                onClickSaveForm={onClickChangePayer}
                onClickSendEmail={() => toggleConfirmActionVisible(SEND_EMAIL)}
              />
            </>
          )}
      </Card>
    </>
  )
}

import { orbCore } from 'services/Orb/Service'
import { TermParser } from './Parsers'

const { termDomain } = orbCore

export const Termservice = {
  getByType (type) {
    return termDomain.getByType(type).then(TermParser.get)
  },
  acceptTerm (termId) {
    return termDomain.acceptTerm(termId)
  }
}

import { marginTop, marginBottom, marginLeft } from 'assets/styles/global'
import { css } from '@emotion/core'
import { colors } from '@bonitour/components'

export const marginTop50 = marginTop(50)
export const marginBottom20 = marginBottom(20)
export const marginBottom50 = marginBottom(50)
export const flatBottom = marginBottom(0)

export const sortIcon = css`
  color: ${colors.gray3};
  font-size: 10px;
  margin-right: 10px;
  transition: all 0.2s ease;
`

export const marginLeft15 = marginLeft(15)
export const marginBottom10 = marginBottom(10)
export const marginTop15 = marginTop(15)
export const marginTop10 = marginTop(10)

export const removeLinkProperties = css`
  text-decoration: none;
  color: initial;
`
export const transferStyle = css`
  font-size: 18px;
`

export const paymentDetailsStyle = css`
  display: flex;
  flex-direction: column;
`

export const textOverflow = css`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  white-space: nowrap;
`

export const receivedValueColor = (value) => css`
  color: ${value > 0 ? colors.green3 : colors.gray7};
`

export const itemBalanceColor = (value) => css`
  color: ${value !== 0 ? colors.red3 : colors.gray7};
`

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { H4, Modal, TableOption } from '@bonitour/components'
import { hidden } from 'assets/styles/global'
import { ReservationAmountDue } from '../ReservationAmountDue'
import { ProviderTransactionModal } from 'domains/Reservation/Transactions/ProviderTransaction/ProviderTransactionModal'
import { useTransactionProviderForm } from 'domains/Reservation/Transactions/hooks/useTransactionProviderForm'
import { CREATE_PROVIDER_PAYMENT_STATE, CREATE_PROVIDER_REFUND_STATE } from 'domains/Reservation/Transactions/ProviderTransaction/ProviderTransaction.states'
import { useTransactionsProvider } from 'domains/Reservation/Transactions/hooks/useTransactionsProvider'
import { ProviderTransactionDetail } from 'domains/Reservation/Transactions/ProviderTransaction/ProviderTransactionDetail'
import { useTransactionProviderDetails } from 'domains/Reservation/Transactions/hooks/useTransactionProviderDetails'
import { AddLabel } from 'components/AddLabel'
import { ReservationProviderTransactionTable } from '../Payment/ProviderTable'
import { useState } from 'react'
import { useTransactions } from 'domains/Reservation/Transactions/hooks/useTransactions'

export const ReservationFinanceProvider = ({
  reservationId,
  partnerId,
  isCompanyAccountable = false,
  isRefund = false
}) => {
  const { transactions: shouldUpdate, getRefundById, loading } = useTransactions()

  const {
    transactions,
    amount,
    approvePaymentTransaction,
    approveProviderRefundTransaction,
    rejectPaymentTransaction,
    rejectProviderPaymentTransaction,
    getPaymentById,
    getAdvancedRefundById,
    getPartnershipTicketsToRefund,
    createPayment,
    createRefund,
    getAdvancePaymentById
  } = useTransactionsProvider(shouldUpdate)

  const {
    isVisible: isFormVisible,
    view,
    vendors,
    tickets,
    ticketsGetter,
    transactionFormData,
    openTransactionForm,
    closeTransactionForm,
    onTransactionSubmit,
    providerTransactionFormLoading
  } = useTransactionProviderForm(reservationId, createPayment, createRefund)

  const {
    isPaymentVisible,
    closePaymentDetail,
    payment,
    openPayment,
    aproveTransaction,
    aproveRefundTransaction,
    rejectTransaction,
    rejectRefundTransaction,
    paymentLabels,
    loading: providerDetailLoading
  } = useTransactionProviderDetails(
    {
      approvePaymentTransaction,
      approveProviderRefundTransaction,
      rejectPaymentTransaction,
      rejectProviderPaymentTransaction,
      getPaymentById,
      getRefundById,
      getAdvancedRefundById,
      getAdvancePaymentById
    }
  )
  const isTotallyPaid = !amount.due || amount.due <= 0

  const onTicketsRefundCreation = async (transactionState) => {
    const tickets = await getPartnershipTicketsToRefund(reservationId)
    const data = {
      tickets
    }
    openTransactionForm(transactionState, data)
  }

  const onPaymentCreation = () => openTransactionForm(CREATE_PROVIDER_PAYMENT_STATE)
  const onRefundCreation = () => onTicketsRefundCreation(CREATE_PROVIDER_REFUND_STATE)

  const label = isCompanyAccountable ? 'Pagamentos antecipados aos fornecedores' : 'Pagamentos do vendedor'

  const isTransactionARefund = (type) => type === 'Reembolso'

  const [tableCardSelected, setTableCard] = useState(false)
  const onClickTableCardOption = () => setTableCard(true)
  const onClickTableListOption = () => setTableCard(false)

  return (
    <>
      <TableOption
        title='Pagamentos ao fornecedor'
        cardSelected={tableCardSelected}
        onClickTableCardOption={onClickTableCardOption}
        onClickTableListOption={onClickTableListOption}
      />

      <H4>{label}</H4>

      <ReservationAmountDue
        amounts={amount}
        isDetailsHidden={false}
        isCreditHidden
      />

      <ReservationProviderTransactionTable
        card={tableCardSelected}
        transactions={transactions}
        onPaymentShow={openPayment}
        loading={loading}
        isTransactionARefund={isTransactionARefund}
      />

      <Modal
        isVisible={isPaymentVisible}
        title={paymentLabels?.detailTitle}
        onCloseClick={closePaymentDetail}
      >
        <ProviderTransactionDetail
          labels='pagamento'
          detail={payment}
          amountDueValueLabel={paymentLabels?.amountDueLabel}
          onClose={closePaymentDetail}
          onApproval={aproveTransaction}
          onRefundApproval={aproveRefundTransaction}
          onRejection={rejectTransaction}
          onRefundRejection={rejectRefundTransaction}
          loading={providerDetailLoading}
        />
      </Modal>

      {isCompanyAccountable &&
        <AddLabel onClick={onPaymentCreation} css={isTotallyPaid && hidden}>
          Registrar pagamento
        </AddLabel>}
      {isRefund &&
        <AddLabel onClick={onRefundCreation} negative css={!isRefund && hidden}>
        Estornar pagamento
        </AddLabel>
      }

      <ProviderTransactionModal
        isVisible={isFormVisible}
        formBase={transactionFormData}
        view={view}
        companiesOptions={vendors}
        tickets={tickets}
        partnerId={partnerId}
        onModalClose={closeTransactionForm}
        onCompanyChange={ticketsGetter}
        onModalSuccess={onTransactionSubmit}
        loading={providerTransactionFormLoading}
      />
    </>
  )
}

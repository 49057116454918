const partnerListItemModel = ({ id, name }) => ({ value: id, label: name })

export const partnerListModel = partnerList => {
  return partnerList.reduce(
    (acc, partner) => {
      return acc.concat(partnerListItemModel(partner))
    },
    [{ value: '', label: 'Todos' }]
  )
}

import { orbCore } from 'services/Orb/Service'
import { NotificationParser } from 'core/parsers/Notifications'

const { notificationDomain } = orbCore

export const NotificationService = {
  list (perPage = 10, page = 1) {
    return notificationDomain.list(perPage, page).then(NotificationParser.list)
  },
  markAsRead (notificationId) {
    return notificationDomain.markAsRead(notificationId)
  }
}

import { colors } from '@bonitour/components'
import { css } from '@emotion/core'
import { marginBottom, marginRight } from 'assets/styles/global'

export const fullWidth = css`
  width: 100%;
`

export const trashWrapper = css`
  font-size: 22px;
  color: ${colors.gray3};
  align-self: center;
  margin-left: auto;
  padding: 8px;
  cursor: pointer;
`

export const marginBottom20 = marginBottom(20)
export const marginBottom50 = marginBottom(50)
export const marginRight20 = marginRight(20)

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { H4, flexHorizontallyCentered } from '@bonitour/components'
import { margin, marginBottom, marginTop } from 'assets/styles/global'
import { ReservationTable } from '../ReservationTable/ReservationTable'
import { useTransactions } from 'domains/Reservation/Transactions/hooks/useTransactions'
import { useMemo } from 'react'

const marginTop40 = marginTop(40)
const marginBottom20 = marginBottom(20)
const marginRight10 = margin(0, 10, 0, 0)

export const PaymentsList = () => {
  const { transactions, loading } = useTransactions()
  const paidTransactions = useMemo(() => transactions.filter(transaction => transaction.state === 'paid'), [transactions])

  return (
    <>
      <div css={[flexHorizontallyCentered, marginTop40, marginBottom20]}>
        <H4 css={marginRight10}>Pagamentos</H4>
      </div>
      <ReservationTable transactions={paidTransactions} loading={loading} />
    </>
  )
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Reference, Manager, colors } from '@bonitour/components'

import { useHover } from 'hooks/useHover'
import { NavLink } from 'react-router-dom'
import { BookingCard } from 'domains/Booking/Map/Card/BookingCard'
import { ReservationDetails } from '../Guides/ReservationDetails'

const bookingCardExpanded = css`
  width: 96px;
  text-align: center;
`

const bookingCardCompany = css`
  font-size: 11px;
  color: ${colors.white};
  overflow: hidden;
  text-overflow: ellipsis;
`

const removeDecoration = css`
  text-decoration: none;
`

export const BookingCardExpanded = ({ id = '', bookingValue = 0, code, payer, commercialPartner }) => {
  const [isVisible, showDetails, hideDetails] = useHover(300)

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <div
            ref={ref}
            onMouseOver={showDetails}
            onMouseOut={hideDetails}
          >
            <NavLink to={`/reservation/${id}`} css={removeDecoration}>
              <BookingCard css={bookingCardExpanded}>
                <div>{bookingValue}</div>
                <div css={bookingCardCompany}>{commercialPartner}</div>
              </BookingCard>
            </NavLink>

            {isVisible && (
              <ReservationDetails
                code={code}
                payer={payer}
                commercialPartner={commercialPartner}
              />)}
          </div>
        )}
      </Reference>
    </Manager>
  )
}

/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import { H4, Label, PlusIcon, PaginationContainer, Card, HeaderPage, Row } from '@bonitour/components'
import { resetLink } from 'assets/styles/global'
import { CardButton } from 'components/CardButton'
import { NavLink } from 'react-router-dom'
import NoticesFilter from './NoticesFilter'
import NoticesTable from './NoticesTable'
import { cardButton, cardIcon, labelCardButton, marginTop30 } from './NoticeList.style'
import { useNoticeList } from '../hooks/useNoticeList'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

export const NoticesList = () => {
  const { notices, meta, handlePagination, handleFilters, isLoading } = useNoticeList()
  const { totalEntries = 1, currentPage = 1, totalPages = 1 } = meta

  const { push } = useHistory()
  const backToDashboard = () => push('/dashboard')

  return (
    <>
      <HeaderPage onBack={backToDashboard} title="Comunicados" />

      <Row>
        <NavLink to="/notices/create" css={resetLink}>
          <CardButton css={cardButton}>
            <PlusIcon css={cardIcon} />
            <Label css={labelCardButton}>Novo comunicado</Label>
          </CardButton>
        </NavLink>
      </Row>

      <H4 css={marginTop30}>Comunicados enviados</H4>
      <Card>
        <NoticesFilter onFilterSubmit={handleFilters} />
        <PaginationContainer
          total={totalEntries}
          currentPage={currentPage}
          pagesQuantity={totalPages}
          onPagination={handlePagination}
          title="Não há comunicados"
          subtitle="Você não tem comunicados enviados com o filtro selecionado"
        >
          <NoticesTable notices={notices} loading={isLoading} />
        </PaginationContainer>
      </Card>
    </>
  )
}

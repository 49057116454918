import React, { useMemo } from 'react'
import { Row, Textarea, InputFormGroup, InputMoneyMask, DatePicker, InputTimeMask, Input, Column, Select } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { useListEmployeesFromCompany } from 'domains/Cashier/hooks/useListEmployeesFromCompany'
import { useUser } from 'contexts/User'

export const ObservationRowInput = ({
  form,
  errors,
  onInputChange = identity,
  onInputBlur = identity,
  disabled = false
}) => {
  return (
    <Row>
      <Column desktop={12} phone={12}>
        <InputFormGroup
          errorMessage={errors.observation}
          label='Observação'
        >
          <Textarea
            placeholder='Deixe uma observação aqui'
            value={form.observation}
            onChange={onInputChange('observation')}
            onBlur={onInputBlur('observation')}
            disabled={disabled}
          />
        </InputFormGroup>
      </Column>
    </Row>
  )
}

export const AgentInput = ({
  form = {},
  errors = {},
  onInputChange = identity,
  onInputBlur = identity
}) => {
  const { user } = useUser()

  const employeesFromCompany = useListEmployeesFromCompany(user?.company?.id)

  const employeesOptions = useMemo(() => {
    return employeesFromCompany.employees.map(({ userId, userName }) => ({
      value: userId,
      label: userName
    }))
  }, [employeesFromCompany.employees])

  return (
    <Row>
      <Column desktop={12} phone={12}>
        <InputFormGroup
          errorMessage={errors.employee}
          label='Agente Responsável'
          className='agent_input'
        >
          <Select
            placeholder='Selecione o agente responsável'
            onChange={onInputChange('reservationHeader.agent')}
            onBlur={onInputBlur('reservationHeader.agent')}
            error={errors.reservationHeader?.agent}
            value={form.reservationHeader?.agent || user.userId}
            options={employeesOptions}
            id='agent'
          />
        </InputFormGroup>
      </Column>
    </Row>
  )
}

export const PriceInputFormGroup = ({
  form,
  errors,
  onInputChange = identity,
  onInputBlur = identity,
  isEditing = false,
  disabled
}) => {
  return (
    <InputFormGroup
      errorMessage={errors.price}
      label={`Valor ${isEditing ? 'unitário' : 'total'}`}
      className='price__input'
    >
      <InputMoneyMask
        value={form.price}
        onChange={onInputChange('price')}
        onBlur={onInputBlur('price')}
        disabled={disabled || !isEditing}
      />
    </InputFormGroup>)
}

export const NetPriceInputFormGroup = ({
  form,
  errors,
  onInputChange,
  onInputBlur,
  isEditing = false
}) => {
  return (
    <InputFormGroup
      errorMessage={errors.netPrice}
      label={`Valor net ${isEditing ? 'unitário' : ''}`}
      className='price__input'
    >
      <InputMoneyMask
        value={form.netPrice}
        onChange={onInputChange('netPrice')}
        onBlur={onInputBlur('netPrice')}
        disabled={!isEditing}
      />
    </InputFormGroup>
  )
}

export const SlotInputFormGroup = ({ form, errors, onInputChange, onInputBlur, desktop, phone, disabled = false }) => {
  return (
    <>
      <Column desktop={desktop} phone={phone}>
        <InputFormGroup
          errorMessage={errors.checkinDate}
          label='Data'
          className='date__input'
        >
          <DatePicker
            value={form.checkinDate}
            onChange={onInputChange('checkinDate')}
            onBlur={onInputBlur('checkinDate')}
            disabled={disabled}
          />
        </InputFormGroup>
      </Column>
      <Column desktop={desktop} phone={phone}>
        <InputFormGroup
          errorMessage={errors.checkinTime}
          label='Horário'
          className='time__input'
        >
          <InputTimeMask
            value={form.checkinTime}
            onChange={onInputChange('checkinTime')}
            onBlur={onInputBlur('checkinTime')}
            disabled={disabled}
          />
        </InputFormGroup>
      </Column>
    </>
  )
}

export const CountInputFormGroup = ({ form, errors, onInputChange, onInputBlur, disabled = false }) => {
  return (
    <InputFormGroup
      errorMessage={errors.count}
      label='Quantidade'
      className='quantity__input'
    >
      <Input
        type='number'
        step='1'
        min='0'
        value={form.count}
        onChange={onInputChange('count')}
        onBlur={onInputBlur('count')}
        disabled={disabled}
      />
    </InputFormGroup>
  )
}

export const UnitNetPriceInputFormGroup = ({ form, onChange, errors, disabled = false }) => {
  return (
    <InputFormGroup
      label='Valor net unitário'
      errorMessage={errors?.unitNetPrice}
    >
      <InputMoneyMask
        value={form?.unitNetPrice}
        onChange={onChange('unitNetPrice')}
        disabled={disabled}
      />
    </InputFormGroup>
  )
}

export const UnitTotalPriceInputFormGroup = ({ form, onChange, errors, disabled = false }) => {
  return (
    <InputFormGroup
      label='Valor unitário'
      errorMessage={errors?.unitPrice }
    >
      <InputMoneyMask
        value={form?.unitPrice}
        onChange={onChange('unitPrice')}
        disabled={disabled}
      />
    </InputFormGroup>
  )
}

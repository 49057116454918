import { useCallback, useState } from 'react'
import { useToast } from '@bonitour/components'
import { PaymentBatchService } from 'core/services/PaymentBatch'
import { useAffiliateOptions } from 'domains/AffiliatesBatch/hooks/useAffiliateOptions'
import { useExternalProviderOptions } from 'domains/ExternalProviders/hooks/useExternalProviderOptions'

export const useCreatePaymentBatch = () => {
  const { add: addToast } = useToast()
  const [loading, setLoading] = useState(false)

  const { affiliatesOptionsWithIdAsValue } = useAffiliateOptions(false)
  const { externalProvidersOptions } = useExternalProviderOptions(false)

  const onCreatePaymentBatch = useCallback((batch) => {
    setLoading(true)
    return PaymentBatchService.createPaymentBatch(batch)
      .catch(e => {
        console.error(e)
        addToast('Ocorreu um erro na criação')
        setLoading(false)
        throw new Error('Ocorreu um erro na criação')
      }).finally(() => setLoading(false))
  }, [addToast])

  return {
    onCreatePaymentBatch,
    loading,
    afiliateOptions: affiliatesOptionsWithIdAsValue,
    externalProvidersOptions
  }
}

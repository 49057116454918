import React, { useCallback, useState } from 'react'
import { SafeDate } from '@binamik/js-providers'
import { formatMoney } from '@bonitour/common-functions'
import { ConfirmDialog, LoadingAnimation, Modal, TableRow, TableV2, RefoundIcon, useToast } from '@bonitour/components'
import { batchStatusMap, PAID_STATUS, paymentMethodsMap } from 'constants/paymentBatch'
import { PaymentBatchService } from 'core/services/PaymentBatch'
import { centralizeLoading, tableCss } from './RefundTransactionsModal.style'

export const RefundTransactionsModal = ({ fetchPaymentBatch, paymentBatchId, isVisible, transactions, onCloseModal }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [transactionId, setTransactionId] = useState(null)
  const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useState(false)

  const { add: addToast } = useToast()

  const labels = [
    'Data',
    'Método de pagamento',
    'Valor',
    'Status',
    'Ações'
  ]

  const columnsWidths = ['1fr', '1fr', '1fr', '1fr', '4.5rem']

  const onRefundBatch = useCallback(() => {
    setIsLoading(true)

    PaymentBatchService.refundTransaction({
      id: paymentBatchId,
      transactionId
    }).then(() => {
      addToast('Transação estornada com sucesso', 'success')
      fetchPaymentBatch()
    }).catch((err) => {
      addToast('Houve um erro ao estornar a transação', 'error')
      console.error(err)
      throw new Error('Request error')
    }).finally(() => {
      setIsLoading(false)
      setTransactionId(null)
    })
  }, [paymentBatchId, transactionId, addToast, fetchPaymentBatch])

  const onRefundTransaction = useCallback(() => {
    onRefundBatch(transactionId)
    setIsConfirmDialogVisible(false)
  }, [onRefundBatch, transactionId])

  const onToggleConfirmDialog = useCallback(() => {
    setIsConfirmDialogVisible(!isConfirmDialogVisible)
  }, [isConfirmDialogVisible])

  const onDeleteTransaction = useCallback((id) => {
    setTransactionId(id)
    onToggleConfirmDialog()
  }, [onToggleConfirmDialog])

  return (
    <>
      <ConfirmDialog
        title='Estornar transação'
        message='Tem certeza que deseja estornar essa transação?'
        onClose={onToggleConfirmDialog}
        cancelCallback={onToggleConfirmDialog}
        successCallback={onRefundTransaction}
        isVisible={isConfirmDialogVisible}
      />
      <Modal
        title='Estornar transações'
        onCloseClick={onCloseModal}
        isVisible={isVisible}
      >
        {isLoading
          ? <LoadingAnimation customCss={[centralizeLoading]} />
          : (
            <TableV2
              labels={labels}
              columnsWidths={columnsWidths}
              emptyMessage='Nenhuma transação encontrada'
              customCss={tableCss}
              mobileBreakpoint={800}
            >
              {transactions.map((transaction) => transaction.status === PAID_STATUS && <TableRow key={transaction.id}>
                {[
                  new SafeDate(transaction.transactionDate).format('DD/MM/YYYY'),
                  paymentMethodsMap[transaction.paymentMethod],
                  formatMoney(transaction.value),
                  (batchStatusMap[transaction.status] || ['--'])[0],
                  <RefoundIcon
                    className='delete__icon'
                    key={transaction.id}
                    onClick={() => onDeleteTransaction(transaction.id)}
                  />
                ]}
              </TableRow>
              )}
            </TableV2>)
        }
      </Modal>
    </>
  )
}

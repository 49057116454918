import { colors } from '@bonitour/components'

export const voucherStatusMap = {
  paid: ['Pago', colors.green1],
  not_paid: ['Não pago', colors.danger]
}

export const voucherStatusOptions = [
  { value: 'paid', label: 'Pago' },
  { value: 'not_paid', label: 'Não pago' }
]

import { SellersGroupsParser } from 'services/SellersGroups/Parser'

export const InviteParser = {
  idRelatedDictionary ({ invites = [] }) {
    return invites.reduce((acc, { id, group: { company_id: comercialPartner } = {} }) => {
      return { ...acc, [comercialPartner]: id }
    }, {})
  },
  groupInfo ({ invite = {} }) {
    const { group = {}, answered_at: answered } = invite
    return { ...SellersGroupsParser.group(group), answered: Boolean(answered) }
  }
}

import { SafeDate } from '@binamik/js-providers'
import { metaModel } from './meta'

/* eslint-disable camelcase */
export const RevenueReportModel = {
  list: ({ filters = {}, pagination = {} }) => {
    const {
      initialDate: initial_date,
      finalDate: final_date,
      fiscalDuty: fiscal_duty,
      fiscalDutyPresent: fiscal_duty_present,
      reservationCode: reservation_code,
      payerName: payer_name
    } = filters

    const meta = metaModel(pagination)

    const searchParams = {
      initial_date: initial_date ? new SafeDate(initial_date).isoDate : undefined,
      final_date: final_date ? new SafeDate(final_date).isoDate : undefined,
      fiscal_duty,
      fiscal_duty_present,
      reservation_code,
      payer_name
    }

    return { searchParams, pagination: meta }
  },
  totals: ({ filters = {} }) => {
    const {
      initialDate: initial_date,
      finalDate: final_date,
      fiscalDuty: fiscal_duty,
      fiscalDutyPresent: fiscal_duty_present,
      reservationCode: reservation_code,
      payerName: payer_name
    } = filters

    const searchParams = {
      initial_date: initial_date ? new SafeDate(initial_date).isoDate : undefined,
      final_date: final_date ? new SafeDate(final_date).isoDate : undefined,
      fiscal_duty,
      fiscal_duty_present,
      reservation_code,
      payer_name
    }

    return { searchParams }
  }
}

import { orbCore } from 'services/Orb/Service'
import { PlanParser } from 'core/parsers/plan'
import { PlanModel } from 'core/models/plan'

const { planDomain } = orbCore

export const PlanService = {
  getById (companyId) {
    return planDomain.get(companyId).then(PlanParser)
  },
  create (companyId, plan) {
    return planDomain.create(companyId, PlanModel(plan)).then(PlanParser)
  }
}

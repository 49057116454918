export const pixTransactionType = 'pix'
export const creditCardTransactionType = 'credit_card'
export const debitCardTransactionType = 'debit_card'

export const transactionTypes = {
  [debitCardTransactionType]: 'Débito',
  [creditCardTransactionType]: 'Crédito',
  [pixTransactionType]: 'Pix'
}

export const transactionTypesOptions = Object.entries(transactionTypes)
  .map(([value, label]) => ({ value, label }))

export const cardBrands = {
  mastercard: 'Mastercard',
  visa: 'Visa',
  elo: 'Elo',
  american_express: 'American Express',
  hipercard: 'Hipercard',
  diners: 'Diners',
  discover: 'Discover'
}

export const brandOptions = Object.entries(cardBrands)
  .map(([value, label]) => ({ value, label }))

export const providers = {
  cielo: 'Cielo',
  rede: 'Rede',
  pag_seguro: 'Pag Seguro',
  braspag: 'Braspag',
  paypal: 'PayPal',
  stone: 'Stone',
  sumup: 'SumUp',
  sipag: 'Sipag',
  getnet: 'Getnet',
  safra_pay: 'SafraPay',
  pagar_me: 'Pagar.me',
  mercado_pago: 'Mercado Pago'
}

export const providerOptions = Object.entries(providers)
  .map(([value, label]) => ({ value, label }))

export const paymentMeans = {
  link: 'Link de pagamento',
  pos: 'Máquina de cartão'
}

export const paymentMeansOptions = Object.entries(paymentMeans)
  .map(([value, label]) => ({ value, label }))

export const paymentMeansShort = {
  link: 'Link',
  pos: 'POS'
}

export const GATEWAYS_MAP = {
  pagar_me: 'pagar_me'
}

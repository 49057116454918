/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { labelDialogStyle, dialogMarginBottom, dialogContainerStyle } from './CombinedExperienceForm.styles'
import { marginTop } from 'assets/styles/global'
import { Button, Dialog, flexCenteredSpaceBetweenRow, GhostPrimaryButton, Label, Row } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'

export const CombinedExperienceFormConfirmationDialog = ({
  onSuccess = identity,
  onClose = identity,
  isVisible = false,
  title = '',
  children
}) => {
  return <Dialog
    isVisible={isVisible}
    title={title}
    customHeadingCss={[dialogMarginBottom]}
    customButtonCss={[dialogMarginBottom]}
    customContainercss={dialogContainerStyle}
    onClose={onClose}>
    <Label css={labelDialogStyle}>
      {children}
    </Label>
    <Row css={[flexCenteredSpaceBetweenRow, marginTop(20)]}>
      <GhostPrimaryButton onClick={onClose}>Cancelar</GhostPrimaryButton>
      <Button onClick={onSuccess}>Confirmar</Button>
    </Row>
  </Dialog>
}

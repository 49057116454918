import { date, string, ref } from 'yup'
import { validateCpf } from '@bonitour/common-functions'

export const ReservationFilterSchema = {
  code: string(),
  email: string(),
  cpf: string()
    .nullable()
    .test('test-cpf', 'O CPF deve ser válido', (cpf) => {
      if (cpf.replace(/\D/g, '')) {
        return validateCpf(cpf)
      }
      return true
    }),
  name: string(),
  status: string(),
  sellingChannel: string(),
  responsible: string(),
  initialDate: date()
    .max(ref('finalDate'), 'A data inicial deve ser menor ou igual a data final'),
  finalDate: date()
    .min(ref('initialDate'), 'A data final deve ser maior ou igual a data inicial')
}

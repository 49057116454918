/* eslint-disable camelcase */
/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useMemo, useState } from 'react'
import { head, identity, formatMoney } from '@bonitour/common-functions'
import { H4, TableOption, TableContainer, TableHeader, TableHeaderRow, TableHeaderCell, TableBody, TableBodyRow, TableBodyCell } from '@bonitour/components'
import { marginBottom } from 'assets/styles/global'
import { ReservationAmountDue } from '../ReservationAmountDue'
import { useHistory } from 'react-router'
import { TicketDetail } from '../TicketDetail/TicketDetail'
import { popoverPositionStyle } from '../Payment/DetailTicketList.style'
import { PaymentLinksTable } from './PaymentTables/Links'
import { FinanceActionsProvider } from 'domains/Reservation/Transactions/hooks/useFinanceActions'
import { PaymentsTable } from './PaymentTables/Payments'
import { RefundsTable } from './PaymentTables/Refunds'
import { usePaymentsTotalizers } from 'domains/Reservation/Transactions/hooks/usePaymentsTotalizers'

const marginBottom50 = marginBottom(50)

export const OverpaymentDetails = ({ overpayingTickets = [] }) => (
  <TableContainer>
    <TableHeader>
      <TableHeaderRow>
        <TableHeaderCell>Código</TableHeaderCell>
        <TableHeaderCell>Tipo</TableHeaderCell>
        <TableHeaderCell>Empresa</TableHeaderCell>
        <TableHeaderCell>Experiência</TableHeaderCell>
        <TableHeaderCell>Valor do<br/>ingresso</TableHeaderCell>
        <TableHeaderCell>Valor do novo<br/>pagamento</TableHeaderCell>
        <TableHeaderCell>Valor<br/>excedente</TableHeaderCell>
      </TableHeaderRow>
    </TableHeader>
    <TableBody>
      {overpayingTickets.map((ticket, index) => (
        <TableBodyRow key={index}>
          <TableBodyCell>{ticket.code}</TableBodyCell>
          <TableBodyCell>{ticket.type}</TableBodyCell>
          <TableBodyCell>{ticket.company}</TableBodyCell>
          <TableBodyCell>{ticket.activity}</TableBodyCell>
          <TableBodyCell>
            {head(ticket.ticketPaymentBalance)?.historic?.length > 0
              ? (
                <TicketDetail
                  value={ticket.value}
                  ticketPrice={head(ticket.ticketPaymentBalance)?.ticketPrice}
                  alreadyPaidTicketValue={head(ticket.ticketPaymentBalance)?.alreadyPaidTicketValue}
                  remainingTicketValue={head(ticket.ticketPaymentBalance)?.remainingTicketValue}
                  historic={head(ticket.ticketPaymentBalance)?.historic}
                  popoverPositionStyle={popoverPositionStyle}
                  isRefund={false}
                />
              )
              : formatMoney(head(ticket.ticketPaymentBalance)?.ticketPrice)
            }
          </TableBodyCell>
          <TableBodyCell>{formatMoney(ticket.amount)}</TableBodyCell>
          <TableBodyCell>{formatMoney(ticket.amount - head(ticket.ticketPaymentBalance)?.remainingTicketValue)}</TableBodyCell>
        </TableBodyRow>
      ))}
    </TableBody>
  </TableContainer>
)

export const ReservationFinance = ({
  reservationId = '',
  isCommercialPartner = false,
  getSubordinates,
  subordinatesLoading = false,
  redirectToPay = identity,
  hasFiscalDuty = false
}) => {
  const { push } = useHistory()

  const { totalizers: paymentsTotalizers } = usePaymentsTotalizers()

  const onReservationBalanceClick = () => push(`/reservation-balance/${reservationId}`)
  const [tableCardSelected, setTableCard] = useState(false)
  const onClickTableCardOption = () => setTableCard(true)
  const onClickTableListOption = () => setTableCard(false)

  const totalizers = useMemo(() => ({
    total: paymentsTotalizers?.totalAmount,
    due: paymentsTotalizers?.totalAmountDue,
    paid: paymentsTotalizers?.totalAmountPaid,
    credit: paymentsTotalizers?.totalCancellationBalance
  }), [paymentsTotalizers?.totalAmount, paymentsTotalizers?.totalAmountDue, paymentsTotalizers?.totalAmountPaid, paymentsTotalizers?.totalCancellationBalance])

  return (
    <div css={[marginBottom50]}>
      <FinanceActionsProvider
        getSubordinates={getSubordinates}
        hasFiscalDuty={hasFiscalDuty}
      >
        <TableOption
          title='Pagamentos do turista'
          cardSelected={tableCardSelected}
          onClickTableCardOption={onClickTableCardOption}
          onClickTableListOption={onClickTableListOption}
        />

        <H4>Pagamentos do viajante</H4>

        <ReservationAmountDue
          amounts={totalizers}
          onClick={redirectToPay}
          isCommercialPartner={isCommercialPartner}
          onReservationBalanceClick={onReservationBalanceClick}
        />

        <PaymentLinksTable
          card={tableCardSelected}
          subordinatesLoading={subordinatesLoading}
          isCommercialPartner={isCommercialPartner}
        />
        <PaymentsTable
          card={tableCardSelected}
          subordinatesLoading={subordinatesLoading}
          isCommercialPartner={isCommercialPartner}
        />
        <RefundsTable
          card={tableCardSelected}
          subordinatesLoading={subordinatesLoading}
          isCommercialPartner={isCommercialPartner}
        />

      </FinanceActionsProvider>
    </div>
  )
}

import { orbCore } from 'services/Orb/Service'
import { AcquirerParser } from 'core/parsers/acquirer'
import { AcquirerModel } from 'core/models/acquirer'

const { acquirerDomain, cardBrandsDomain, acquirerDomainV2, paymentProvidersDomain } = orbCore

export const AcquirerService = {
  list ({ filters, pagination, companyId }) {
    return acquirerDomain.list(...AcquirerModel.list({ filters, pagination, companyId })).then(AcquirerParser.list)
  },
  listAll ({ companyId, transactionType }) {
    return acquirerDomain.listAll(companyId, transactionType).then(AcquirerParser.listAll)
  },
  getById (acquirerId) {
    return acquirerDomain.get(acquirerId).then(AcquirerParser.single)
  },
  createAcquirer (acquirer) {
    return acquirerDomainV2.create(AcquirerModel.single(acquirer))
  },
  editAcquirer (acquirer, acquirerId) {
    return acquirerDomainV2.update(AcquirerModel.single(acquirer), acquirerId)
  },
  listProviders () {
    return paymentProvidersDomain.list().then(AcquirerParser.listProviders)
  },
  remove (acquirerId) {
    return acquirerDomain.remove(acquirerId)
  },
  listCardBrands () {
    return cardBrandsDomain.list().then(AcquirerParser.listCardBrands)
  },

  __deprecated_createAcquirer (acquirer) {
    return acquirerDomain.create(AcquirerModel.single(acquirer))
  },

  __deprecated_editAcquirer (acquirer, acquirerId) {
    return acquirerDomain.update(AcquirerModel.single(acquirer), acquirerId)
  }
}

export const partnershipPaymentTypesOptions = [
  { label: 'Cartão de Débito', value: 'debit' },
  { label: 'Cartão de Crédito', value: 'credit' },
  { label: 'Dinheiro', value: 'cash' },
  { label: 'Boleto', value: 'bank_billet' },
  { label: 'Transferência Bancária', value: 'bank_transfer' },
  { label: 'PIX', value: 'pix' }
]

export const partnershipCreditPaymentTypeOptions = [
  { label: 'Crédito de parceria', value: 'partnership_credit' }
]

export const partnershipDebitPaymentTypeOptions = [
  { label: 'Débito de parceria', value: 'partnership_debit' }
]

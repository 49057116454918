import { useCallback, useState } from 'react'
import { useToast } from '@bonitour/components'
import { CombinedExperienceService } from 'services/combinedExperience/Service'
import { useActivity } from 'contexts/Activity'
import { useBack } from 'hooks/useBack'
import { usePermissions } from 'hooks/usePermissions'
import { COMBINED_EXPERIENCE_TYPE } from 'constants/activityTypes'

const INITIAL_REQUESTS_DATA = {
  isLoading: {
    creation: false,
    getForEdition: false,
    edit: false
  },
  responseData: {
    creation: null,
    getForEdition: null
  }
}

const errorList = {
  'experiences::experience::title::taken': 'Já existe uma experiência combinada com o mesmo título.',
  'experiences::experience::not_found': 'Experiência combinada não encontrada'
}

export const useCombinedExperience = () => {
  const {
    add: addToast
  } = useToast()

  const {
    handlePermission
  } = usePermissions()

  const {
    onSafeBack,
    goTo
  } = useBack()

  const {
    setId,
    updateList,
    setActivity,
    companyActivities: experienceList = []
  } = useActivity()

  const [requestsData, setRequestsData] = useState(INITIAL_REQUESTS_DATA)

  const changeIsLoading = useCallback((field = '', value = false) => {
    if (!field) return

    setRequestsData(prev => ({
      ...prev,
      isLoading: {
        ...prev.isLoading,
        [field]: value
      }
    }))
  }, [])

  const fillResponse = useCallback((field = '') => (value = null) => {
    if (!field) return

    setRequestsData(prev => ({
      ...prev,
      responseData: {
        ...prev.responseData,
        [field]: value
      }
    }))
  }, [])

  /**
   * @param {string} action; Label of the action that is being performed
   * @param {string} indefiniteArticle; Indefinite article of the action that is being performed
   */
  const showError = useCallback((action = '', indefiniteArticle = '') => (error) => {
    const hasPermission = handlePermission(error)
    if (!hasPermission) {
      return addToast(`Você não tem permissão para ${action} ${indefiniteArticle} experiência combinada`)
    }

    const { data: { errors_msg: errorsMsg = [] } } = error

    const hasErrorInErrorList = errorsMsg.some(error => errorList[error])

    errorsMsg.forEach(error => {
      if (errorList[error]) {
        addToast(errorList[error], 'error')
      }
    })

    if (hasErrorInErrorList) return

    addToast(`Houve um erro na ${action} ${indefiniteArticle} experiência combinada`, 'error')
  }, [addToast, handlePermission])

  /**
   * @param {string} previousPath; The route path for back when is success
   */
  const create = useCallback((previousPath = '') => (payload = {}) => {
    if (requestsData.isLoading.creation) return
    changeIsLoading('creation', true)
    return CombinedExperienceService.create(payload)
      .then(data => {
        setId(data.id)
        updateList(true)
        return data
      })
      .then(fillResponse('creation'))
      .then(() => onSafeBack(previousPath))
      .then(() => addToast('Experiência combinada criada com sucesso', 'success'))
      .catch(showError('criação', 'de uma'))
      .finally(() => changeIsLoading('creation', false))
  }, [requestsData.isLoading.creation, changeIsLoading, fillResponse, showError, setId, updateList, onSafeBack, addToast])

  /**
  * @param {string} combinedExperienceId; The id of the combined experience
  * @param {string} previousPath; The route path for back when is fail
   */
  const getForEdition = useCallback((combinedExperienceId = '', previousPath = '') => {
    if (requestsData.isLoading.get) return
    changeIsLoading('getForEdition', true)
    return CombinedExperienceService.get(combinedExperienceId, true)
      .then(fillResponse('getForEdition'))
      .catch((error) => {
        goTo(previousPath)
        return Promise.reject(error)
      })
      .catch(showError('busca', 'de uma'))
      .finally(() => changeIsLoading('getForEdition', false))
  }, [changeIsLoading, fillResponse, requestsData.isLoading.get, showError, goTo])

  /**
  * @param {string} dashboardPath; The route path
  * @returns {function} edit function that receives payload as parameter
   */
  const edit = useCallback((dashboardPath = '') => (payload = {}) => {
    if (requestsData.isLoading.edit) return
    changeIsLoading('edit', true)
    return CombinedExperienceService.update(requestsData.responseData.getForEdition.id, payload)
      .then(() => {
        const activityIds = payload.experiences.map(({ id }) => id)
        const filteredActivities = experienceList
          .filter(({ id }) => activityIds.includes(id))
          .map(({ id, image, name }) => ({
            id,
            serviceTitle: name,
            serviceImage: image
          }))
        setActivity({
          ...payload,
          title: undefined,
          name: payload.title,
          type: COMBINED_EXPERIENCE_TYPE,
          experiences: filteredActivities
        })
        updateList(true)
        goTo(dashboardPath)
        addToast('Experiência combinada editada com sucesso', 'success')
      })
      .catch(showError('edição', 'de uma'))
      .finally(() => changeIsLoading('edit', false))
  }, [changeIsLoading, requestsData.isLoading.edit, requestsData.responseData.getForEdition, showError, addToast, goTo, updateList, setActivity, experienceList])

  return {
    isLoadingCreation: requestsData.isLoading.creation,
    isSuccessCreation: Boolean(requestsData.responseData.creation),
    isLoadingEdition: requestsData.isLoading.edit,
    isLoadingGetForEdition: requestsData.isLoading.getForEdition,
    create,
    getForEdition,
    edit,
    getCombinedExperienceForEditionResponse: requestsData.responseData.getForEdition
  }
}

/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import { Card, TableHeader, TableContainer, TableHeaderCell, TableHeaderRow, TableBody } from '@bonitour/components'
import { bold, containerOfTable, reduceHeaderPadding, alignCenter } from './InvoiceBatchPayment.style'
import InvoiceBatchPaymentRow from './InvoiceBatchPaymentRow'

const InvoiceBatchPaymentTable = ({ paymentList }) => {
  return (
    <Card css={containerOfTable}>
      <TableContainer>
        <TableHeader>
          <TableHeaderRow>
            <TableHeaderCell css={[reduceHeaderPadding, bold, alignCenter]}>Data</TableHeaderCell>
            <TableHeaderCell css={[reduceHeaderPadding, bold, alignCenter]}>Status</TableHeaderCell>
            <TableHeaderCell css={[reduceHeaderPadding, bold, alignCenter]}>Método</TableHeaderCell>
            <TableHeaderCell css={[reduceHeaderPadding, bold, alignCenter]}>Valor</TableHeaderCell>
            <TableHeaderCell css={[reduceHeaderPadding, bold, alignCenter]}>Ações</TableHeaderCell>
          </TableHeaderRow>
        </TableHeader>
        <TableBody fallbackMessage="Sem pagamentos registrados">
          {paymentList.map((payment) => (
            <InvoiceBatchPaymentRow payment={payment} key={payment.id} />
          ))}
        </TableBody>
      </TableContainer>
    </Card>
  )
}

export default InvoiceBatchPaymentTable

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import {
  colors,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TooltipOnHover
} from '@bonitour/components'
import { CashHandlingTypes } from '../constants/cashier'
import { formatMoney } from '@bonitour/common-functions'

const positiveValue = css`
  color: ${colors.success};
`

const negativeValue = css`
  color: ${colors.danger};
`

const resetPosition = css`
  align-items:unset;
  justify-content:unset;
`

const shortenFields = css`
  overflow: hidden;
  text-overflow: ellipsis;
`

const smallFields = css`
  ${shortenFields}
  max-width: 95px;
`

const cashHandlingTypeLabels = {
  [CashHandlingTypes.in]: 'Entrada de caixa',
  [CashHandlingTypes.out]: 'Saída de caixa'
}

export const CashierHandlingTable = ({ cashiers, loading }) => {
  return (
    <TableContainer style={{ marginTop: 16 }}>
      <TableHeader>
        <TableHeaderRow>
          <TableHeaderCell>Data</TableHeaderCell>
          <TableHeaderCell>Tipo de operação</TableHeaderCell>
          <TableHeaderCell>Operador</TableHeaderCell>
          <TableHeaderCell>Supervisor</TableHeaderCell>
          <TableHeaderCell>Observações</TableHeaderCell>
          <TableHeaderCell>Valor</TableHeaderCell>
        </TableHeaderRow>
      </TableHeader>
      <TableBody loading={loading}>
        {cashiers?.map(({
          id,
          date,
          cashHandlingType,
          operatorName,
          supervisorName,
          comment,
          value
        }) => (
          <TableBodyRow key={`cashier-handling-${id}`}>
            <TableBodyCell>{date}</TableBodyCell>
            <TableBodyCell>{cashHandlingTypeLabels[cashHandlingType] ?? 'não mapeado'}</TableBodyCell>
            <TableBodyCell>{operatorName}</TableBodyCell>
            <TableBodyCell>{supervisorName}</TableBodyCell>
            <TruncateCell truncateStyle={smallFields} text={comment} />
            <TableBodyCell css={Number(value) > 0 ? positiveValue : negativeValue}>
              {formatMoney(value)}
            </TableBodyCell>
          </TableBodyRow>
        ))}
      </TableBody>
    </TableContainer>
  )
}

const TruncateCell = ({ text, truncateStyle, children }) =>
  <TableBodyCell>
    <TooltipOnHover css={resetPosition} text={text}>
      <div css={truncateStyle}>{children || text}</div>
    </TooltipOnHover>
  </TableBodyCell>

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { H3, Input, HorizontalInputFormGroup, flexHorizontallyCentered, BREAK_POINTS } from '@bonitour/components'
import { marginBottom } from 'assets/styles/global'
import { identity } from '@bonitour/common-functions'

const sameLineInput = css`
  margin-left: 10px;

  @media (max-width: ${BREAK_POINTS.tableUp}) {
    margin: 10px 0 0 0;
  }
`

const smallInput = css`
  width: 100px;
`

const marginBottom20 = marginBottom(20)

const flexColumnStart = css`
  @media (max-width: ${BREAK_POINTS.tableUp}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const ActivityVacancyName = ({
  activityVacancy = {},
  activityVacancyErrors = {},
  onChange: emitChangeEvent = identity,
  onInputBlur: emitInputBlur = identity
}) => {
  const { vacancyName = '', dailyLimit = 0 } = activityVacancy

  return (
    <>
      <H3 css={marginBottom20}>Configuração da vaga</H3>
      <div css={[flexHorizontallyCentered, marginBottom20, flexColumnStart]}>
        <p>O nome da vaga será</p>
        <HorizontalInputFormGroup errorMessage={activityVacancyErrors.vacancyName}>
          <Input
            css={sameLineInput}
            value={vacancyName}
            onChange={emitChangeEvent('activityVacancy.vacancyName')}
            onBlur={emitInputBlur('activityVacancy.vacancyName')}
            placeholder='Ex. Rafting'
          />
        </HorizontalInputFormGroup>
      </div>

      <H3 css={marginBottom20}>Limite Diário</H3>
      <div css={[flexHorizontallyCentered, marginBottom20, flexColumnStart]}>
        <p>O número de vagas é</p>
        <HorizontalInputFormGroup errorMessage={activityVacancyErrors.dailyLimit}>
          <Input
            type='number'
            min='0'
            css={[sameLineInput, smallInput]}
            value={dailyLimit}
            onChange={emitChangeEvent('activityVacancy.dailyLimit')}
            onBlur={emitInputBlur('activityVacancy.dailyLimit')}
          />
        </HorizontalInputFormGroup>
      </div>
    </>
  )
}

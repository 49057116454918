import { string, date } from 'yup'

export const invoicePaySchema = {
  date: date().required('Preencha a data de pagamento'),
  value: string().required('O Valor do pagamento é obrigatório'),
  type: string().required('A forma de pagamento é obrigatória'),
  originBankAccount: string().nullable(),
  destinationBankAccount: string().nullable(),
  observation: string().nullable()
}

import { colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const label = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 0.625rem;

  i {
    width: 2rem;
  }
  
  :hover {
    cursor: pointer;
    color: ${colors.gray5};
  }
`

export const popoverCloseButton = css`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  border: none;
  z-index: 1;
`

import { orbCore } from 'services/Orb/Service'

const { ticketDomain } = orbCore

export const ObservationService = {
  update (dataObservation) {
    const { ticketId } = dataObservation
    return ticketDomain.updateObservation(ticketId, dataObservation.observation)
  }
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useHistory, useParams } from 'react-router-dom'
import { useCallback, useEffect, useMemo } from 'react'
import { Card, HeaderPage, Label, PlusIcon } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import {
  invoiceBatchTypeMap,
  invoiceBatchTypeOldTitleMap,
  invoiceBatchTypeTitleMap
} from './constants/InvoiceBatchType'
import { CardButton } from 'components/CardButton'
import { cardButton, cardIcon, labelCardButton, marginBottom } from 'assets/styles/global'
import { usePaymentBatchList } from './hooks/usePaymentBatchList'
import { InvoicesBatchFilter } from './List/InvoicesBatchFilter'
import { useAffiliateOptions } from 'domains/AffiliatesBatch/hooks/useAffiliateOptions'
import { useExternalProviderOptions } from 'domains/ExternalProviders/hooks/useExternalProviderOptions'
import { InvoicesBatchList } from './List/InvoicesBatchList'
import { InvoicesBatchActionsBuilder } from './List/Table/InvoicesBatchActions'
import { InvoiceBatchTotalizers } from './Totalizers/InvoicesBatchTotalizers'

const marginBottom30 = marginBottom(30)

export const InvoicesBatch = () => {
  const { type } = useParams()
  const { push } = useHistory()
  const backToDashboard = useCallback(() => push('/dashboard'), [push])

  useEffect(() => {
    if (!Object.keys(invoiceBatchTypeMap).includes(type)) {
      backToDashboard()
    }
  }, [backToDashboard, type])

  const title = useMemo(() => `Faturas em lote - ${invoiceBatchTypeTitleMap[type]}`, [type])
  const subtitle = useMemo(() => `${invoiceBatchTypeOldTitleMap[type]} - Lote`, [type])
  const onCreateBatch = useCallback(() => push(`/invoices-batch/${type}/create`), [push, type])

  const {
    batchList,
    setFilters,
    filters,
    pagination,
    loading,
    meta,
    changePagination,
    fetchBatchListList
  } = usePaymentBatchList(invoiceBatchTypeMap[type])

  const useOptions = useMemo(() => {
    switch (type) {
    case 'affiliate':
      return useAffiliateOptions
    case 'offline':
      return useExternalProviderOptions
    default:
      return () => identity
    }
  }, [type])

  const { affiliatesOptionsWithIdAsValue, externalProvidersOptions } = useOptions(true)
  const recipientOptions = useMemo(
    () => affiliatesOptionsWithIdAsValue || externalProvidersOptions,
    [affiliatesOptionsWithIdAsValue, externalProvidersOptions]
  )

  const refetchTicketList = useCallback(
    () => fetchBatchListList({ filters, pagination }),
    [fetchBatchListList, filters, pagination]
  )

  const InvoicesBatchActions = useMemo(
    () => InvoicesBatchActionsBuilder({
      fetchTicketList: refetchTicketList
    }),
    [refetchTicketList]
  )

  return (
    <>
      <HeaderPage onBack={backToDashboard} title={title} subtitle={subtitle} />

      <CardButton css={[cardButton, marginBottom30]} onClick={onCreateBatch}>
        <PlusIcon css={cardIcon} />
        <Label css={labelCardButton}>Criar novo lote</Label>
      </CardButton>

      <Card>
        <InvoicesBatchFilter
          onFilterSubmit={setFilters}
          initialFilters={filters}
          recipientOptions={recipientOptions}
          recipientLabel={invoiceBatchTypeTitleMap[type]}
        />

        <InvoiceBatchTotalizers />

        <InvoicesBatchList
          loading={loading}
          batchList={batchList}
          pagesQuantity={meta?.totalPages}
          total={meta?.total}
          onPagination={changePagination}
          recipientLabel={invoiceBatchTypeTitleMap[type]}
          ActionsComponent={InvoicesBatchActions}
        />
      </Card>
    </>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Card, H4, flexHorizontallyCentered, CircleThumbnail, flex, ResponsiveImage, flexColumnCentered } from '@bonitour/components'
import { activitiesCard, activitiesCardContainer, baseCard, defautMargin, grayBorder, iconGreyBackground, marginBottom20, marginRight10, nonImageFirstLetterSize, titleStyle } from './ActivitiesList.style'
import { getFirstChar } from 'utils/getFirstChar'
import { capitalize, marginTop } from 'assets/styles/global'

const marginTop40 = marginTop(40)

export const ActivitiesList = ({
  activeActivities = []
}) => {
  return (
    <>
      <div css={[flexHorizontallyCentered, marginBottom20, marginTop40]}>
        <H4 css={marginRight10}>Experiências</H4>
      </div>
      <div css={[flex, activitiesCardContainer]}>
        {activeActivities.map(({ activityName, companyName, activityImage, activityId }) => (
          <Card key={activityId} css={[baseCard, flexColumnCentered, activitiesCard]}>
            <CircleThumbnail css={[defautMargin, nonImageFirstLetterSize, activityImage ? grayBorder : iconGreyBackground]} size={83}>
              {activityImage ? <ResponsiveImage src={activityImage} /> : getFirstChar(activityName)}
            </CircleThumbnail>
            <p css={[defautMargin, titleStyle]}>{activityName}</p>
            <p css={[defautMargin, capitalize]}>{companyName}</p>
          </Card>
        ))}
      </div>
    </>
  )
}

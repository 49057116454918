export const apiPaymentMethods = {
  debit: 'debit',
  credit: 'credit',
  cash: 'cash',
  bank_billet: 'bank_billet',
  bank_transfer: 'bank_transfer',
  pix: 'pix',
  pay_credit: 'pay_credit'
}

export const bankAccountPaymentMethods = [
  apiPaymentMethods.bank_billet,
  apiPaymentMethods.bank_transfer,
  apiPaymentMethods.pix,
  apiPaymentMethods.cash
]

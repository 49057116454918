/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { InputFormGroup, Row, Column, Select, useToast, DatePicker, Button, colors, Card } from '@bonitour/components'
import { reservationHistoryItemTypeOptions } from './constants/reservationHistoryParams'
import { string, date, ref } from 'yup'
import { useForm } from '@bonitour/app-functions'
import { identity } from '@bonitour/common-functions'
import { useMemo } from 'react'

const schema = {
  itemType: string().required('O tipo de atualização deve ser obrigatória'),
  startDate: date()
    .max(ref('endDate'), 'A data inicial deve ser menor ou igual a data final')
    .required('A data inicial é obrigatória'),
  endDate: date()
    .min(ref('startDate'), 'A data final deve ser maior ou igual a data inicial')
    .required('A data final é obrigatória')
}

const button = css`
  height: 40px;
  width: 100%;
  margin-top: auto;

  @media screen and (max-width: 1200px) {
    margin-top: 20px;
  }
`

const fullWidth = css`
  width: 100%;
`

const cardContrast = css`
  margin-bottom: 20px;
  padding: 15px;
  background-color: ${colors.gray13};
  border: none;
`

export const ReservationHistorySearch = ({ onReservationSearch = identity, createdAt }) => {
  const formBase = useMemo(() => {
    return {
      startDate: new Date(createdAt),
      endDate: new Date(),
      itemType: 'Financial::ReservationPayment'
    }
  }, [createdAt])
  const { add: addToast } = useToast()
  const { form, errors, onSubmit, utils: { onInputBlur, onInputChange } } = useForm(formBase, schema)
  const onValidationError = () => addToast('Preencha o formulário corretamente')
  const onClick = onSubmit(onReservationSearch, onValidationError)

  return (
    <Card css={cardContrast}>
      <Row>
        <Column phone={12} desktop={3}>
          <InputFormGroup label='Tipo de Atualização' errorMessage={errors.itemType}>
            <Select
              options={reservationHistoryItemTypeOptions}
              value={form.itemType}
              onChange={onInputChange('itemType')}
              onBlur={onInputBlur('itemType')}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={3}>
          <InputFormGroup label='Data de Início' errorMessage={errors.startDate}>
            <DatePicker
              css={fullWidth}
              value={form.startDate}
              onChange={onInputChange('startDate')}
              onBlur={onInputBlur('startDate')}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={3}>
          <InputFormGroup label='Data de Fim' errorMessage={errors.endDate}>
            <DatePicker
              css={fullWidth}
              value={form.endDate}
              onChange={onInputChange('endDate')}
              onBlur={onInputBlur('endDate')}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={3}>
          <Button onClick={onClick} css={button}>
            Buscar
          </Button>
        </Column>
      </Row>
    </Card>
  )
}

import { useRef, useEffect, useCallback } from 'react'

export function InfiniteScroll ({ loading, nextPage, scrollElement = window, distance = 300 }) {
  const ref = useRef(null)
  const handleScroll = useCallback(() => {
    if (
      !loading &&
      (scrollElement.innerHeight ?? scrollElement.clientHeight) + (scrollElement.pageYOffset ?? scrollElement.scrollTop) + distance >=
      (scrollElement.scrollHeight ?? document.body.offsetHeight)
    ) {
      clearTimeout(ref.current)
      ref.current = setTimeout(nextPage, 250)
    }
  }, [loading, scrollElement.innerHeight, scrollElement.clientHeight, scrollElement.pageYOffset, scrollElement.scrollTop, scrollElement.scrollHeight, distance, nextPage])

  useEffect(() => {
    if (scrollElement !== window && scrollElement?.childNodes?.[0]) {
      const resizeObserver = new ResizeObserver(() => handleScroll())
      resizeObserver.observe(scrollElement?.childNodes?.[0])
      return () => resizeObserver.disconnect
    }
  }, [handleScroll, scrollElement])

  useEffect(() => {
    handleScroll()
    scrollElement.addEventListener('resize', handleScroll)
    scrollElement.addEventListener('scroll', handleScroll)
    return () => {
      clearTimeout(ref.current)
      scrollElement.removeEventListener('scroll', handleScroll)
      scrollElement.removeEventListener('resize', handleScroll)
    }
  }, [handleScroll, scrollElement])

  return null
}

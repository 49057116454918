import { useCallback, useEffect, useState } from 'react'
import { useToast } from '@bonitour/components'
import { exportCsv } from '@bonitour/common-functions'
import { InvoiceService } from 'core/services/Invoice'

export const useInvoiceDetailsExport = (invoiceId) => {
  const { add: addToast } = useToast()
  const [exportInvoiceColumns, setExportInvoiceColumns] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchExportInvoiceColumns = useCallback(() => {
    InvoiceService.getInvoiceDetailsExportColumn().then(setExportInvoiceColumns)
  }, [])

  const onExportSubmit = useCallback(({ wantedFields }) => {
    setLoading(true)

    return InvoiceService.getInvoiceDetailsExportCSV(invoiceId, { wantedFields }).then((data) => {
      exportCsv('itens-fatura', data)
    }).catch(() => {
      addToast('Erro inesperado')
    }).finally(() => {
      setLoading(false)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceId])

  useEffect(() => {
    fetchExportInvoiceColumns()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    onExportSubmit,
    exportInvoiceColumns,
    exportLoading: loading
  }
}
